// eslint-disable-next-line jira/restricted/react-component-props
import type { ComponentProps, FC } from 'react';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { EstimationStatisticFieldId } from '../../../model/work/work-types.tsx';
import {
	getBoardCardCount,
	type BoardCardCount,
} from '../../../services/performance/board-data-collector.tsx';
import { cardDeleteOptimistic } from '../../../state/actions/card/index.tsx';
import { closeIssueModal, resetExternalAction } from '../../../state/actions/issue/modal/index.tsx';
import {
	issueBentoUpdate,
	issueUpdateRequest,
} from '../../../state/actions/issue/update/index.tsx';
import {
	closeInsightsPanel,
	closeViewSettingsPanel,
} from '../../../state/actions/panels/index.tsx';
import {
	sessionIdSelector,
	getIsCMPBoard,
} from '../../../state/selectors/software/software-selectors.tsx';
import {
	getEstimationStatistic,
	getFilteredIssueKeysAsRapidViewMap,
} from '../../../state/selectors/work/work-selectors.tsx';
import type { Dispatch, State } from '../../../state/types.tsx';
import type { DispatchProps } from './types.tsx';
import IssueView from './view.tsx';

type IssueViewConfig = JSX.LibraryManagedAttributes<
	typeof IssueView,
	ComponentProps<typeof IssueView>
>;

type OwnProps = {
	rapidViewId: IssueViewConfig['rapidViewId'];
	externalAction: IssueViewConfig['externalAction'];
};

type StateProps = {
	boardSessionId: string;
	boardCardCount: BoardCardCount;
	estimationStatistic: EstimationStatisticFieldId;
	rapidViewMap?: string[][];
	isCMPBoard: boolean;
};

const getBoardCardCountMemoized = () => createSelector(getBoardCardCount, (res) => res);
const mapStateToPropsFactory = () => {
	const getBoardCardCountInstance = getBoardCardCountMemoized();
	return (state: State): StateProps => ({
		boardSessionId: sessionIdSelector(state),
		boardCardCount: getBoardCardCountInstance(state),
		estimationStatistic: getEstimationStatistic(state),
		rapidViewMap: getFilteredIssueKeysAsRapidViewMap(state),
		isCMPBoard: getIsCMPBoard(state),
	});
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
	bindActionCreators(
		{
			onClose: closeIssueModal,
			onChange: issueBentoUpdate,
			onDelete: cardDeleteOptimistic,
			onUpdateRequest: issueUpdateRequest,
			onSwitchToSidebarAction: closeInsightsPanel,
			onResetExternalAction: resetExternalAction,
			onCloseViewSettingsPanel: closeViewSettingsPanel,
		},
		dispatch,
	);

export default connect(
	mapStateToPropsFactory,
	mapDispatchToProps,
)(
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	IssueView as FC<OwnProps & StateProps & DispatchProps>,
);
