import React, { useState, useCallback } from 'react';
import type { EntryPointProps } from 'react-relay';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import ViewWorkflowContextService from '@atlassian/jira-issue-field-status/src/services/view-workflow-service/index.tsx';
import StatusView from '@atlassian/jira-issue-field-status/src/ui/status-view/index.tsx';
import ViewWorkflow from '@atlassian/jira-issue-field-status/src/ui/view-workflow/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import WorkflowModal from '@atlassian/jira-issue-field-status/src/ui/workflow-modal/index.tsx';
import {
	SCOPE_GLOBAL,
	SCOPE_PROJECT,
} from '@atlassian/jira-project-configuration-commons/src/model/constants.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { StatusTransition } from '../common/types.tsx';
import { PACKAGE_NAME, ERROR_REPORTING_TEAM } from '../constant.tsx';
import { transitionsProvider } from './transition-provider.tsx';
import type { OwnProps, Props } from './types.tsx';

export const StatusField = ({
	issue,
	onChange,
	issueKey,
	value,
	projectId,
	transitions,
	loading,
	fetchTransitions,
	isMenuDefaultOpen,
	onOpen,
	onClose,
}: Props) => {
	const [isWorkflowModalOpen, setIsWorkflowModalOpen] = useState(false);
	const [isWorkflowModalMounted, setIsWorkflowModalMounted] = useState(false);
	const tenantContext = useTenantContext();
	const handleChange = useCallback(
		(transition: StatusTransition) => {
			onChange(transition);
		},
		[onChange],
	);

	const onOpenModal = useCallback(() => {
		setIsWorkflowModalOpen(true);
	}, []);

	const onCloseModal = useCallback(() => {
		setIsWorkflowModalOpen(false);
	}, []);

	const onModalMounted = useCallback(() => {
		setIsWorkflowModalMounted(true);
	}, []);

	return (
		<JSErrorBoundary
			id="jira-plan.issue-status"
			packageName={PACKAGE_NAME}
			teamName={ERROR_REPORTING_TEAM}
			fallback="flag"
		>
			<ViewWorkflowContextService baseUrl={tenantContext.baseUrl} issueKey={issueKey}>
				{(workflowServiceApi) => (
					<>
						<StatusView
							appearance="lozenge"
							{...(isMenuDefaultOpen === true ? { defaultOpen: true } : {})}
							value={value}
							transitions={transitions}
							onChange={handleChange}
							isLoading={loading}
							onOpen={() => {
								fetchTransitions();
								const hasFetchedWorkflowService =
									workflowServiceApi.data && workflowServiceApi.data.hasPermission !== undefined;
								if (!hasFetchedWorkflowService) {
									workflowServiceApi.fetch();
								}
								if (onOpen && fg('render_program_board_card_in_popup')) {
									onOpen();
								}
							}}
							onClose={() => {
								if (onClose && fg('render_program_board_card_in_popup')) {
									onClose();
								}
							}}
							footer={
								<ViewWorkflow
									baseUrl={tenantContext.baseUrl}
									issueKey={issueKey}
									workflowServiceApi={workflowServiceApi}
									onOpenModal={onOpenModal}
									isModalMounted={isWorkflowModalMounted}
								/>
							}
							popperProps={{
								strategy: 'absolute',
								modifiers: [
									{
										name: 'zIndex',
										enabled: true,
										phase: 'write',
										fn: ({ state }) => {
											// eslint-disable-next-line no-param-reassign
											state.styles.popper.zIndex = `${layers.modal - 1}`;
										},
									},
								],
							}}
						/>
						<WorkflowModal
							onModalMounted={onModalMounted}
							issueKey={issueKey}
							issueTypeId={`${issue.type}`}
							baseUrl={tenantContext.baseUrl}
							locale={tenantContext.locale}
							cloudId={tenantContext.cloudId}
							projectId={`${projectId}`}
							isOpen={isWorkflowModalOpen}
							onClose={onCloseModal}
							scope={workflowServiceApi.data?.isSimplified === true ? SCOPE_PROJECT : SCOPE_GLOBAL}
						/>
					</>
				)}
			</ViewWorkflowContextService>
		</JSErrorBoundary>
	);
};

export default transitionsProvider(StatusField);

export const AsyncPlanIssueStatus = ({ props }: EntryPointProps<{}, {}, OwnProps, {}>) =>
	transitionsProvider(StatusField)(props);
