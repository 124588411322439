export const FILTER_PREFIX = 'assignee';

export const Z_INDEX = {
	SELECTED: 1,
	ADD: 2,
	MENU: 3,
	HOVER: 4,
} as const;

export const OVERLAP_PIXELS = 10;
export const ADDBUTTON_HEIGHT = 36;

export const UNASSIGNED_ID = 'unassigned';
