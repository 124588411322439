import type { MiddlewareAPI } from 'redux';
import { type Epic, ActionsObservable, combineEpics } from 'redux-observable';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { isInAddPeopleButtonOpenTMPControlCohort } from '../feature-flags.tsx';
import type { State, Action, BoardDependencies } from '../state/types.tsx';
import backlogEnableEpic from './board/backlog-enable-epic.tsx';
import backlogIssueMoveEpic from './board/backlog-issue-move-epic.tsx';
import { boardDeferredDataLoadEpic } from './board/board-deferred-data-load-epic.tsx';
import { boardFavouriteEpic } from './board/board-favourite-epic.tsx';
import boardLoadEpic from './board/board-load-epic.tsx';
import { displayFlagForIssuesFilteredOnServerEpic } from './board/board-load-issues-filtered-on-server-epic.tsx';
import cardMediaToggleEpic from './board/board-menu-toggle-epic.tsx';
import boardTitleEpic from './board/board-title-epic.tsx';
import { bulkIssueRankTransitionRequestEpic } from './bulk-action/rank-transition-epic.tsx';
import { getCanUserInviteEpic } from './can-user-invite/get-can-user-invite-epic.tsx';
import { getCardCoversEpic } from './card-covers/get-card-covers-epic.tsx';
import cardFetchIssueTransitionEpic from './card-fetch-issue-transitions/index.tsx';
import { cardAssignToMeEpic, cardAssignToMeThrottledEpic } from './card/card-assign-to-me-epic.tsx';
import {
	cardAutoScrollOnLoadEpic,
	cardAutoScrollOnFilterEpic,
} from './card/card-auto-scroll-epic.tsx';
import { cardChangeTeamEpic } from './card/card-change-team-epic.tsx';
import cardClearUndoEpic from './card/card-clear-undo-epic.tsx';
import cardClickEpic from './card/card-click-epic.tsx';
import cardColumnNavigationEpic from './card/card-column-navigation-epic.tsx';
import cardDeleteEpicGraphql from './card/card-delete-epic-graphql.tsx';
import cardDeleteEpic from './card/card-delete-epic.tsx';
import { cardDragStartEpic } from './card/card-drag-epic.tsx';
import { cardsMoveEpic } from './card/card-move-epic.tsx';
import cardRefreshEpic from './card/card-refresh-epic.tsx';
import cardRowNavigationEpic from './card/card-row-navigation-epic.tsx';
import cardTransitionEpic from './card/card-transition-epic.tsx';
import { checkGlobalIssueCreateEpic } from './check-global-issue-create/index.tsx';
import columnCreateGqlEpic from './column-graphql/column-create-epic.tsx';
import columnDeleteGQLEpic from './column-graphql/column-delete-epic.tsx';
import columnRankGQLEpic from './column-graphql/column-rank-epic.tsx';
import columnRenameGQLEpic from './column-graphql/column-rename-epic.tsx';
import columnSetLimitGQLEpic from './column-graphql/column-set-limits-epic.tsx';
import { detailViewCloseEpic } from './detail-view/detail-view-close.tsx';
import { detailViewOpenDebouncedEpic } from './detail-view/detail-view-open-debounced.tsx';
import { detailViewUpdateEpic } from './detail-view/detail-view-update.tsx';
import boardExtensionsEpic from './extension/extension-load-epic.tsx';
import filterValidValuesEpic from './filter/filter-valid-values-epic.tsx';
import { filteredCardsEpic } from './filtered-cards/index.tsx';
import { initiateIccInUnscheduledColumnEpic } from './inline-card-create/initiate-icc-in-unscheduled-column-epic/index.tsx';
import { getIssueLinksPermissionsEpic } from './issue-links-permissions/get-issue-links-permissions-epic.tsx';
import { getIsIssueLinksStatsLoadingEpic } from './issue-links-stats/get-is-issue-links-stats-loading-epic.tsx';
import { getIssueLinksStatsEpic } from './issue-links-stats/get-issue-links-stats-epic.tsx';
import { devStatusLoadEpic } from './issue/dev-status/index.tsx';
import { issueBentoUpdateEpic } from './issue/issue-bento-update-epic.tsx';
import { issueBulkUpdateEpic } from './issue/issue-bulk-update-epic/index.tsx';
import { issueChildrenInFinalColumnEpic } from './issue/issue-children-in-final-column-epic.tsx';
import issueCreateEpic from './issue/issue-create-epic.tsx';
import { issueFlagWithCommentEpic } from './issue/issue-flag-with-comment-epic.tsx';
import { issueIncrementPlanningUpdateEpic } from './issue/issue-increment-planning-update-epic/index.tsx';
import { issueLinkEpic, issueLinkUpdateEpic } from './issue/issue-link-epic/index.tsx';
import issueMediaUpdateEpic from './issue/issue-media-update-epic.tsx';
import { issueMoveToBacklogEpic } from './issue/issue-move-to-backlog-epic.tsx';
import issuePostCreateEpic from './issue/issue-post-create-epic.tsx';
import { issueRankTeamDateEpic } from './issue/issue-rank-team-date-epic/index.tsx';
import { issueRankTransitionEpic } from './issue/issue-rank-transition-epic/index.tsx';
import issueTopBottomContainerEpic from './issue/issue-top-bottom-container-epic.tsx';
import { issueUnlinkEpic } from './issue/issue-unlink-epic/index.tsx';
import issueUpdateChildrenEpic from './issue/issue-update-children-epic.tsx';
import { missingParentResolveEpic } from './issue/missing-parent-resolve-epic/index.tsx';
import workLoadEpic, { workLoadOnInitialLoadEpic } from './load-work-data/index.tsx';
import realtimeEpic from './realtime/realtime-event-epic.tsx';
import issueSetParentEpic from './set-issue-parent/index.tsx';
import softwareOnReadyEpic from './software/software-on-ready-epic.tsx';
import { addToSprintEpic } from './sprint/add-to-sprint/index.tsx';
import { removeFromSprintEpic } from './sprint/remove-from-sprint/index.tsx';
import { updatePlannedCapacityEpic } from './sprint/update-planned-capacity/index.tsx';
import statusCategoryLoadEpic from './status-category/status-category-load-epic.tsx';
import { jqlSwimlanesEpic } from './swimlane/jql-swimlanes-epic.tsx';
import swimlaneToggleEpic from './swimlane/swimlane-toggle-epic.tsx';
import swimlanesToggleEpic from './swimlane/swimlanes-toggle-epic.tsx';
import { updateIterationCapacityEpic } from './team/update-iteration-capacity-epic.tsx';
import swimlaneModeChangeEpic from './user-swimlane-strategy-graphql/user-swimlane-strategy-change-epic.tsx';
import { versionGetEpic } from './version/version-get-epic.tsx';
import workGetCurrentUserEpic from './work/work-get-current-user-epic.tsx';
import workRefreshDataEpic from './work/work-refresh-data-epic.tsx';
import { filterUpdateFieldValuesEpic } from './filter/filter-update-field-values-epic.tsx';
import cardArchiveEpic from './card/card-archive-epic.tsx';
import { boardUpdateOldDoneIssuesCutoffEpic } from './board/board-update-old-done-issues-cutoff-epic.tsx';

const epicWithKillSwitch =
	(predicate: () => boolean) =>
	(epic: Epic<Action, State, BoardDependencies>) =>
	(
		action$: ActionsObservable<Action>,
		store: MiddlewareAPI<State>,
		dependencies?: BoardDependencies,
	) =>
		epic(new ActionsObservable(action$.filter(() => !predicate())), store, dependencies);

const withAddPeopleButtonOpenTMPExperimentKillSwitch = epicWithKillSwitch(
	isInAddPeopleButtonOpenTMPControlCohort,
);

const getArchiveEpicWithKillSwitch = epicWithKillSwitch(
	() => !expVal('issue_deletion_a_two_step_process_ga', 'isDeleteTwoStepGaEnabled', false),
);

const getBoardUpdateOldDoneIssuesCutoffEpicWithKillSwitch = epicWithKillSwitch(
	() => !fg('horizn_490_consolidate_old_issues_in_board'),
);

const getFilterFieldValuesEpicWithKillSwitch = epicWithKillSwitch(
	() => !expVal('filter_refinement_in_tmp_board', 'isEnabled', false),
);

const getRootEpic = () =>
	combineEpics<Action, State>(
		backlogEnableEpic,
		backlogIssueMoveEpic,
		statusCategoryLoadEpic,
		columnRankGQLEpic,
		columnCreateGqlEpic,
		columnDeleteGQLEpic,
		columnRenameGQLEpic,
		columnSetLimitGQLEpic,
		issueCreateEpic,
		issueSetParentEpic,
		workRefreshDataEpic(),
		workLoadEpic,
		workLoadOnInitialLoadEpic,
		workGetCurrentUserEpic,
		issueFlagWithCommentEpic,
		cardFetchIssueTransitionEpic,
		softwareOnReadyEpic,
		issuePostCreateEpic,
		filterValidValuesEpic,
		devStatusLoadEpic,
		boardDeferredDataLoadEpic,
		swimlaneModeChangeEpic,
		swimlaneToggleEpic,
		swimlanesToggleEpic,
		cardRefreshEpic,
		cardMediaToggleEpic,
		boardLoadEpic,
		issueMediaUpdateEpic,
		boardTitleEpic,
		cardDeleteEpicGraphql,
		getArchiveEpicWithKillSwitch(cardArchiveEpic),
		cardClickEpic,
		cardAssignToMeEpic,
		cardAssignToMeThrottledEpic,
		cardDeleteEpic,
		cardClearUndoEpic,
		cardRowNavigationEpic,
		cardColumnNavigationEpic,
		cardTransitionEpic,
		cardChangeTeamEpic,
		issueMoveToBacklogEpic,
		filteredCardsEpic,
		boardExtensionsEpic,
		realtimeEpic,
		detailViewUpdateEpic,
		detailViewCloseEpic,
		detailViewOpenDebouncedEpic,
		issueUpdateChildrenEpic,
		issueBentoUpdateEpic,
		issueIncrementPlanningUpdateEpic,
		issueRankTransitionEpic,
		issueRankTeamDateEpic,
		issueBulkUpdateEpic,
		issueLinkEpic,
		issueLinkUpdateEpic,
		issueUnlinkEpic,
		initiateIccInUnscheduledColumnEpic,
		checkGlobalIssueCreateEpic,
		issueTopBottomContainerEpic,
		removeFromSprintEpic,
		updatePlannedCapacityEpic,
		addToSprintEpic,
		versionGetEpic,
		issueChildrenInFinalColumnEpic,
		cardsMoveEpic,
		bulkIssueRankTransitionRequestEpic,
		cardDragStartEpic,
		cardAutoScrollOnLoadEpic,
		cardAutoScrollOnFilterEpic,
		getIssueLinksStatsEpic,
		getIsIssueLinksStatsLoadingEpic,
		missingParentResolveEpic,
		getIssueLinksPermissionsEpic,
		withAddPeopleButtonOpenTMPExperimentKillSwitch(getCanUserInviteEpic),
		displayFlagForIssuesFilteredOnServerEpic,
		jqlSwimlanesEpic,
		updateIterationCapacityEpic,
		boardFavouriteEpic,
		getCardCoversEpic,
		getBoardUpdateOldDoneIssuesCutoffEpicWithKillSwitch(boardUpdateOldDoneIssuesCutoffEpic),
		getFilterFieldValuesEpicWithKillSwitch(filterUpdateFieldValuesEpic),
	);

export default getRootEpic;
