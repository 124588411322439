import type { ReactNode } from 'react';
import { styled as styled2 } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { Tokens } from '@atlassian/jira-custom-theme-constants/src/constants.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	layout,
	columnFixedWidth,
	collapsedColumnFixedWidth,
	columnMinWidth,
} from '../../constants/styles/index.tsx';
import { ColumnTheme } from '../../types.tsx';

export type ColumnThemeType = {
	backgroundNew: string; // value to be changed to background on cleanup - jira_theming_milestone_1
	background: string;
	headerHover: string;
	btnHover: string;
	btnActive: string;
	btnIconHover: string;
	btnIconActive: string;
	titleEditHover: string;
};

export const columnThemes: { [key: string]: ColumnThemeType } = {
	[ColumnTheme.Default]: {
		backgroundNew: Tokens.COLOR_ELEVATION_SURFACE_SUNKEN,
		background: token('elevation.surface.sunken'),
		headerHover: token('color.background.neutral.hovered'),
		btnHover: token('color.background.neutral.hovered'),
		btnActive: token('color.background.neutral.pressed'),
		btnIconHover: token('color.background.neutral.hovered'),
		btnIconActive: token('color.text.inverse'),
		titleEditHover: token('color.background.neutral.subtle.hovered'),
	},
	[ColumnTheme.Warning]: {
		backgroundNew: token('color.background.accent.yellow.subtlest'),
		background: token('color.background.accent.yellow.subtlest'),
		headerHover: token('color.background.neutral.hovered'),
		btnHover: token('color.background.warning.hovered'),
		btnActive: token('color.background.warning.pressed'),
		btnIconHover: token('color.background.neutral.bold'),
		btnIconActive: token('color.text.inverse'),
		titleEditHover: token('color.background.warning.hovered'),
	},
	[ColumnTheme.Danger]: {
		backgroundNew: token('color.background.accent.red.subtlest'),
		background: token('color.background.accent.red.subtlest'),
		headerHover: token('color.background.neutral.hovered'),
		btnHover: token('color.background.warning.hovered'),
		btnActive: token('color.background.warning.pressed'),
		btnIconHover: token('color.background.neutral.bold'),
		btnIconActive: token('color.text.inverse'),
		titleEditHover: token('color.background.warning.hovered'),
	},
} as const;

export type ColumnWrapperProps = {
	isCollapsed?: boolean;
	isFlexible?: boolean;
	children: ReactNode;
	appearance: ColumnTheme;
};

const getBackgroundColor = (): string => {
	if (fg('jira_theming_milestone_1_fg')) {
		return columnThemes[ColumnTheme.Default]?.backgroundNew
			? columnThemes[ColumnTheme.Default]?.backgroundNew
			: token('elevation.surface.sunken');
	}
	return columnThemes[ColumnTheme.Default]?.background
		? columnThemes[ColumnTheme.Default]?.background
		: token('elevation.surface.sunken');
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ColumnWrapper = styled2.div<ColumnWrapperProps>(
	{
		// Column background will always be default (unlike column header)
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		backgroundColor: getBackgroundColor(),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		borderRadius: `${layout.cardListRadius}px`,
		/* needed to contain the drop zone */
		/* position: relative for footer */
		position: 'relative',
		alignSelf: 'stretch',
		flex: '1 1 auto',
		transition: 'opacity 0.2s, min-width 300ms, max-width 300ms ease-in',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
		maxWidth: `${columnFixedWidth}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
		minWidth: `${columnFixedWidth}px`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isCollapsed, isFlexible }: ColumnWrapperProps) => ({
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		...(isCollapsed && {
			backgroundColor: 'transparent',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			minWidth: `${collapsedColumnFixedWidth}px`,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			maxWidth: `${collapsedColumnFixedWidth}px`,
		}),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		...(isFlexible && {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			minWidth: `${columnMinWidth}px`,
			maxWidth: 'none',
		}),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		...(isCollapsed &&
			isFlexible && {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				minWidth: `${collapsedColumnFixedWidth}px`,
				maxWidth: 'none',
			}),
	}),
);
