import React from 'react';
import Button from '@atlaskit/button';
import FeedbackIconNew from '@atlaskit/icon/core/feedback';
import FeedbackIconOld from '@atlaskit/icon/glyph/feedback';
import { Show } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import {
	ENTRYPOINT_ID_JIRA_PLATFORM,
	EntryKeys,
} from '@atlassian/jira-feedback-collector/src/constants.tsx';
import { useFeedbackCollectorActions } from '@atlassian/jira-feedback-collector/src/controllers/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { useBoardSelector } from '../../../state/index.tsx';
import {
	getBoardTypeForAnalytics,
	projectTypeSelector,
	rapidViewIdSelector,
} from '../../../state/selectors/software/software-selectors.tsx';
import { JSM_FEEDBACK_GROUP_ID, JSW_BOARD_FEEDBACK_GROUP_ID } from './constants.tsx';
import messages from './messages.tsx';

type AdditionalEntries = {
	isAdmin: boolean;
	isSiteAdmin: boolean;
	projectType: string;
	boardType: string;
	environment: string;
	cloudId: string;
	boardId: string;
	appEdition: string;
};

const getAdditionalFeedbackEntries = ({
	isAdmin,
	isSiteAdmin,
	projectType,
	boardType,
	environment,
	cloudId,
	boardId,
	appEdition,
}: AdditionalEntries) => [
	{
		id: EntryKeys.IS_ADMIN,
		value: isAdmin.toString(),
	},
	{
		id: EntryKeys.IS_SITE_ADMIN,
		value: isSiteAdmin.toString(),
	},
	{
		id: EntryKeys.PROJECT_TYPE,
		value: projectType,
	},
	{
		id: EntryKeys.BOARD_TYPE,
		value: boardType,
	},
	{
		id: 'ENV',
		value: environment,
	},
	{
		id: 'CLOUD_ID',
		value: cloudId,
	},
	{
		id: 'BOARD_ID',
		value: boardId,
	},
	{
		id: 'JIRA_SOFTWARE_EDITION',
		value: appEdition,
	},
];

const FeedbackButton = () => {
	const { formatMessage } = useIntl();

	const projectType = useBoardSelector(projectTypeSelector);
	const isServiceDeskProject = projectType === SERVICE_DESK_PROJECT;

	// Details needed by JSW feedback collector
	const { isAdmin, isSiteAdmin, environment, cloudId, appEditions } = useTenantContext();
	const boardType = useBoardSelector(getBoardTypeForAnalytics);
	const boardId = useBoardSelector(rapidViewIdSelector);

	const { openFeedbackCollector } = useFeedbackCollectorActions();

	const onClick = () =>
		openFeedbackCollector({
			entrypointId: ENTRYPOINT_ID_JIRA_PLATFORM,
			feedbackGroupId: isServiceDeskProject ? JSM_FEEDBACK_GROUP_ID : JSW_BOARD_FEEDBACK_GROUP_ID,
			feedbackContext: !isServiceDeskProject
				? getAdditionalFeedbackEntries({
						isAdmin,
						isSiteAdmin,
						projectType,
						boardType,
						environment: environment ?? 'UNKNOWN',
						cloudId,
						boardId,
						appEdition: appEditions.software,
					})
				: [],
		});

	const compactFeedbackButton = (
		<Tooltip content={formatMessage(messages.feedbackButtonLabel)} position="top">
			<Button
				appearance="subtle"
				onClick={onClick}
				iconBefore={
					<FeedbackIconNew
						label={formatMessage(messages.feedbackButtonLabel)}
						LEGACY_fallbackIcon={FeedbackIconOld}
						LEGACY_primaryColor={token('color.icon')}
						color={token('color.icon')}
					/>
				}
				testId="software-board.header.feedback-collector-button.feedback-button"
			/>
		</Tooltip>
	);

	const fullscreenFeedbackButton = (
		<Button
			appearance="subtle"
			onClick={onClick}
			iconBefore={
				<FeedbackIconNew
					label=""
					LEGACY_fallbackIcon={FeedbackIconOld}
					LEGACY_primaryColor={token('color.icon')}
					color={token('color.icon')}
				/>
			}
			testId="software-board.header.feedback-collector-button.feedback-button"
		>
			{formatMessage(messages.feedbackButtonLabel)}
		</Button>
	);

	if (isServiceDeskProject) {
		return compactFeedbackButton;
	}

	return (
		<>
			<Show below="lg">{compactFeedbackButton}</Show>
			<Show above="lg">{fullscreenFeedbackButton}</Show>
		</>
	);
};

export default FeedbackButton;
