import React, { forwardRef, type MutableRefObject } from 'react';
import { ButtonItem } from '@atlaskit/menu';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

type Props = {
	onClick: () => void;
};

export const EditSprintMenuItem = forwardRef(
	(
		props: Props,
		ref: MutableRefObject<HTMLElement | null> | ((instance: HTMLElement | null) => void) | null,
	) => {
		const { formatMessage } = useIntl();

		return (
			<ButtonItem ref={ref} onClick={props.onClick}>
				{formatMessage(messages.displayText)}
			</ButtonItem>
		);
	},
);
