/**
 * Feature flags convention: go/frontend-feature-flags
 */
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';

export const isSyntheticTenant = () => ff('jsw.pollinator.tenants');

// eslint-disable-next-line jira/ff/inline-usage
export const isTailoredViewExperimentEnabled = () =>
	expValEquals('jsw_additional_card_fields_in_view_settings', 'cohort', 'variation');

// <tjoy>
// eslint-disable-next-line jira/ff/inline-usage
export function isInAddPeopleButtonOpenTMPControlCohort(): boolean {
	return expValEquals('open_invite_for_open_tmp_projects', 'cohort', 'control');
}
// </tjoy>

// eslint-disable-next-line jira/ff/inline-usage
export const isViewSettingAsPanelExpEnabledWithNoExposure = () =>
	fg('jira_view_settings_additional_fields');
