import React, { Component } from 'react';
import find from 'lodash/find';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import ErrorIconNew from '@atlaskit/icon/core/error';
import InfoIconNew from '@atlaskit/icon/core/information';
import SuccessIconNew from '@atlaskit/icon/core/success';
import WarningIconNew from '@atlaskit/icon/core/warning';
import SuccessIconOld from '@atlaskit/icon/glyph/check-circle';
import ErrorIconOld from '@atlaskit/icon/glyph/error';
import InfoIconOld from '@atlaskit/icon/glyph/info';
import WarningIconOld from '@atlaskit/icon/glyph/warning';
import Link from '@atlaskit/link';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import AutoDismissFlag from '@atlassian/jira-flags/src/common/ui/components/ak-auto-dismiss-flag'; // ignore-for-ENGHEALTH-17759
import FlagGroup from '@atlassian/jira-flags/src/common/ui/components/group-flag'; // ignore-for-ENGHEALTH-17759
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import { ErrorHashAndTraceId } from '@atlassian/jira-errors-handling/src/ui/error-hash-and-trace-id/ErrorHashAndTraceId.tsx';
import type { MaybeChoreographedComponentProps } from '@atlassian/jira-choreographer-services/src/types.tsx';
import {
	FLAG_TYPE_WARNING,
	FLAG_TYPE_SUCCESS,
	FLAG_TYPE_ERROR,
	type FlagData,
	FLAG_TYPE_UNDO,
	FLAG_TYPE_INFO,
} from '../../model/flags/flag-types.tsx';
import type { IssueKey } from '../../model/issue/issue-types.tsx';
import {
	type DismissFlagAction,
	FLAG_CREATED_ISSUE_IS_FILTERED,
} from '../../state/actions/flags/index.tsx';
import type { OpenIssueModalAction } from '../../state/actions/issue/modal/index.tsx';
import { BOARD_LOAD_FAILURE_NO_COLUMN, LOG_BACK_IN_SHOW } from '../../state/actions/work/index.tsx';
import getIssueIsFilteredFlag from './issue-is-filtered/view.tsx';
import messages from './messages.tsx';
import ShowColumnRequiredFlag from './show-column-required/index.tsx';
import { UndoFlag } from './undo-flag/index.tsx';

type NoopType = () => void;

type ConnectedProps = {
	flags: FlagData[];
};

type DefaultProps = {
	// eslint-disable-next-line jira/react/handler-naming
	openIssueModal: ((issueKey: IssueKey) => OpenIssueModalAction) | NoopType;
	createAnalyticsEvent:
		| ((arg1: { action: string; actionSubject: string }) => UIAnalyticsEvent)
		| NoopType;
};

type Props = DefaultProps &
	ConnectedProps &
	(Intl & {
		dismissFlag: ((flagData: FlagData) => DismissFlagAction) | NoopType;
	}) &
	Partial<MaybeChoreographedComponentProps>;

// eslint-disable-next-line jira/react/no-class-components
export class Flags extends Component<Props> {
	static defaultProps = {
		openIssueModal: noop,
		createAnalyticsEvent: noop,
	};

	getIconForFlag(flag: FlagData) {
		switch (flag.type) {
			case FLAG_TYPE_WARNING:
				return (
					<WarningIconNew
						color={token('color.icon.warning')}
						spacing="spacious"
						LEGACY_fallbackIcon={WarningIconOld}
						LEGACY_primaryColor={token('color.icon.warning')}
						label={this.props.intl.formatMessage(messages.warningLabel)}
					/>
				);
			case FLAG_TYPE_SUCCESS:
				return (
					<SuccessIconNew
						label=""
						color={token('color.icon.success')}
						LEGACY_fallbackIcon={SuccessIconOld}
						LEGACY_primaryColor={token('color.icon.success')}
					/>
				);
			case FLAG_TYPE_UNDO:
				return (
					<InfoIconNew label="" LEGACY_fallbackIcon={InfoIconOld} color={token('color.icon')} />
				);
			case FLAG_TYPE_ERROR:
				return (
					<ErrorIconNew
						label={this.props.intl.formatMessage(messages.errorLabel)}
						color={token('color.icon.danger')}
						spacing="spacious"
						LEGACY_fallbackIcon={ErrorIconOld}
						LEGACY_primaryColor={token('color.icon.danger')}
					/>
				);
			case FLAG_TYPE_INFO:
				return <InfoIconNew spacing="spacious" label="" color={token('color.icon.information')} />;

			default:
				return <span>{/* Should be unreachable */}</span>;
		}
	}

	// render the error messages flag description as un-ordered list
	buildErrorMessagesNode(errorMessages: string[]): React.ReactNode {
		return (
			<ul>
				{errorMessages.map((message) => (
					<li key={message}>{message}</li>
				))}
			</ul>
		);
	}

	getDescriptionForFlag(flag: FlagData) {
		// for the flag, the description is optional, so it can be undefined,
		if (isNil(flag.descriptionMessage)) {
			return undefined;
		}

		if (flag.type === FLAG_TYPE_ERROR && flag.errorMessages && flag.errorMessages.length > 0) {
			return this.buildErrorMessagesNode(flag.errorMessages);
		}

		const { formatMessage } = this.props.intl;
		return flag.id === LOG_BACK_IN_SHOW ? (
			<>
				{/* Special render case for "Log back in" flag to pass a React element */}
				{formatMessage(flag.descriptionMessage)}
				{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
				<p>
					<Link href={flag.context && flag.context.url}>
						{formatMessage(messages.logBackInAnchorText)}
					</Link>
				</p>
			</>
		) : (
			formatMessage(flag.descriptionMessage, flag.context)
		);
	}

	getTraceInfoForFlag(flag: FlagData): React.ReactNode {
		if (flag.context && flag.context.error && flag.context.traceId) {
			return (
				<div data-testid="software-board.flags.error-hash-and-traceid">
					<ErrorHashAndTraceId error={flag.context.error} traceId={flag.context.traceId} />
				</div>
			);
		}
		return <></>;
	}

	getDescriptionWithTraceForFlag(flag: FlagData) {
		return (
			<>
				{this.getDescriptionForFlag(flag)}
				{this.getTraceInfoForFlag(flag)}
			</>
		);
	}

	dismissFlag = (flagId: string | number) => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const flag = find(this.props.flags, { id: flagId }) as FlagData | undefined;
		if (flag) {
			this.props.dismissFlag(flag);
		}
	};

	renderFlag = (flag: FlagData) => {
		const { formatMessage } = this.props.intl;

		if (flag.type === FLAG_TYPE_UNDO) {
			return (
				<UndoFlag
					key={flag.id}
					flag={flag}
					icon={this.getIconForFlag(flag)}
					onDismissFlag={this.dismissFlag}
				/>
			);
		}

		switch (flag.id) {
			// Special render case for "Created issue is filtered" flag
			case FLAG_CREATED_ISSUE_IS_FILTERED: {
				const issueKey = get(flag, ['context', 'issueKey']);
				return getIssueIsFilteredFlag({
					id: flag.id,
					issueKey,
					intl: this.props.intl,
					onPrimaryActionClick: () => {
						this.dismissFlag(flag.id);

						const analyticsEvent = this.props.createAnalyticsEvent({
							action: 'issueOpened',
							actionSubject: 'flag',
						});
						const issueId = get(flag, ['context', 'issueId']);

						// @ts-expect-error - Argument of type 'void | UIAnalyticsEvent' is not assignable to parameter of type 'UIAnalyticsEvent'.
						fireUIAnalytics(analyticsEvent, 'newIssueFiltered', {
							issueId,
						});

						this.props.openIssueModal(issueKey);
					},
				});
			}
			case BOARD_LOAD_FAILURE_NO_COLUMN: {
				const canConfigureBoard = get(flag, ['context', 'canConfigureBoard']);
				return (
					<ShowColumnRequiredFlag
						key={flag.id}
						id={flag.id}
						canUserConfigureBoard={canConfigureBoard}
						onDismissed={this.dismissFlag}
					/>
				);
			}
			default:
				return (
					<AutoDismissFlag
						messageId={
							this.props.messageId ?? `software-board.view.flags.auto-dismiss-flag.${flag.id}`
						}
						messageType={this.props.messageType ?? 'transactional'}
						id={flag.id}
						key={flag.id}
						title={formatMessage(flag.titleMessage, flag.context)}
						description={
							flag.rawDescriptionMessage ??
							(fg('obsrve-2239-traceid-errorflag-issue-transition')
								? this.getDescriptionWithTraceForFlag(flag)
								: this.getDescriptionForFlag(flag))
						}
						icon={this.getIconForFlag(flag)}
					/>
				);
		}
	};

	render() {
		return (
			<FlagGroup onDismissed={this.dismissFlag}>{this.props.flags.map(this.renderFlag)}</FlagGroup>
		);
	}
}

export default injectIntl(Flags);
