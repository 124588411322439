import React, { useCallback, useEffect, useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import {
	graphql,
	type PreloadedQuery,
	useRefetchableFragment,
	usePreloadedQuery,
} from 'react-relay';
import { Inline } from '@atlaskit/primitives';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import FilterRefinement from '@atlassian/jira-filter-refinement/src/ui/index.tsx';
import {
	fireOperationalAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import type { boardFilterRefinementQuery } from '@atlassian/jira-relay/src/__generated__/boardFilterRefinementQuery.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useSuspenselessRefetch } from '@atlassian/jira-issue-hooks/src/services/use-suspenseless-refetch/index.tsx';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { CLEAR } from '@atlassian/jira-software-filters/src/common/constants.tsx';
import CustomFilterRelay from '@atlassian/jira-software-filters/src/ui/custom-filter/index.tsx';
import type { CustomFilterId } from '@atlassian/jira-filters/src/common/types.tsx';
import ClearButton from '@atlassian/jira-filters/src/ui/filters/clear-button/index.tsx';
import boardFilterRefinementRefetchQuery, {
	type boardFilterRefinementRefetchQuery as boardFilterRefinementRefetchQueryType,
} from '@atlassian/jira-relay/src/__generated__/boardFilterRefinementRefetchQuery.graphql';
import type { boardFilterRefinement_softwareBoard$key } from '@atlassian/jira-relay/src/__generated__/boardFilterRefinement_softwareBoard.graphql';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/index.tsx';
import { getErrorHash } from '@atlassian/jira-errors-handling/src/utils/error-hash.tsx';
import { useBoardDispatch, useBoardSelector } from '../../../../state/index.tsx';
import { setAllFilters } from '../../../../state/actions/filter/index.tsx';
import { isCustomFiltersActive } from '../../../../state/selectors/filter/custom-filter-selectors.tsx';
import {
	getJqlFilter,
	getSelectedCustomFilters,
	getWorkFilters,
	isBoardConfigLoaded,
	isJqlFilterActive,
	orderedWorkAssigneesByAccountIdSelector,
} from '../../../../state/selectors/work/work-selectors.tsx';
import {
	getCanInviteOthersToProject,
	projectIdSelector,
	projectKeySelector,
	projectNameSelector,
	rapidViewIdSelector,
} from '../../../../state/selectors/software/software-selectors.tsx';
import { CUSTOM_FILTER, JQL, type Filters } from '../../../../model/filter/filter-types.tsx';
import { getSwimlaneMode } from '../../../../state/selectors/swimlane/swimlane-mode-selectors.tsx';
import { swimlaneModeToStrategiesFilterRefinement } from '../../../../services/swimlane/swimlane-data-transformer.tsx';
import { getPermissionsSelector } from '../../../../state/selectors/board/board-permissions-selectors.tsx';
import { CAN_EDIT_BOARD } from '../../../../model/permission/permission-types.tsx';
import messages from './messages.tsx';

type Props = {
	boardFilterRefinementQueryRef: PreloadedQuery<boardFilterRefinementQuery>;
};

const CHANGEBOARDING_MESSAGE_ID = 'filter-refinement-for-tmp-boards';

const isVisualRefreshDisabled = () => {
	return !(isVisualRefreshEnabled() && fg('visual-refresh_drop_4'));
};

const useAddPeopleButtonProps = () => {
	const canInviteOthersToProject = useBoardSelector(getCanInviteOthersToProject);
	const permissions = useBoardSelector(getPermissionsSelector);
	const projectId = useBoardSelector(projectIdSelector);
	const projectKey = useBoardSelector(projectKeySelector);
	const projectName = useBoardSelector(projectNameSelector);

	return useMemo(() => {
		const isAddPeopleButtonEnabled =
			(permissions[CAN_EDIT_BOARD] ||
				(canInviteOthersToProject &&
					expValEquals('open_invite_for_open_tmp_projects', 'cohort', 'experiment'))) &&
			isVisualRefreshDisabled();

		if (isAddPeopleButtonEnabled) {
			return {
				projectId,
				projectKey,
				projectName,
			};
		}

		return {};
	}, [canInviteOthersToProject, permissions, projectId, projectKey, projectName]);
};

export const BoardFilterRefinement = ({ boardFilterRefinementQueryRef }: Props) => {
	const { formatMessage } = useIntl();

	const cloudId = useCloudId();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const dispatch = useBoardDispatch();

	const assignees = useBoardSelector(orderedWorkAssigneesByAccountIdSelector);
	const isBoardLoaded = useBoardSelector(isBoardConfigLoaded);
	const boardId = useBoardSelector(rapidViewIdSelector);
	const jql = useBoardSelector(getJqlFilter);

	const jqlFilterActive = useBoardSelector(isJqlFilterActive);
	const selectedCustomFilters = useBoardSelector(getSelectedCustomFilters);
	const customFiltersActive = useBoardSelector(isCustomFiltersActive);
	const swimlaneMode = useBoardSelector(getSwimlaneMode);
	const workFilters = useBoardSelector(getWorkFilters);

	const boardFilterRefinementQueryData = usePreloadedQuery(
		graphql`
			query boardFilterRefinementQuery(
				$cloudId: ID!
				$boardId: ID!
				$filterRefinementScope: JiraJqlScopeInput!
			) @preloadable {
				...boardFilterRefinement_softwareBoard
					@arguments(cloudId: $cloudId, scope: $filterRefinementScope)
				...customFilter_softwareFilters_CustomFilterRelay @arguments(boardId: $boardId)
			}
		`,
		boardFilterRefinementQueryRef,
	);

	const [filterRefinementFragment, refetch] = useRefetchableFragment<
		boardFilterRefinementRefetchQueryType,
		boardFilterRefinement_softwareBoard$key
	>(
		graphql`
			fragment boardFilterRefinement_softwareBoard on Query
			@argumentDefinitions(cloudId: { type: "ID!" }, scope: { type: "JiraJqlScopeInput" })
			@refetchable(queryName: "boardFilterRefinementRefetchQuery") {
				...ui_filterRefinement_FilterRefinement @arguments(cloudId: $cloudId, scope: $scope)
			}
		`,
		boardFilterRefinementQueryData,
	);

	const scope = useMemo(
		() => ({
			board: {
				boardId: Number(boardId),
				swimlaneStrategy: swimlaneMode && swimlaneModeToStrategiesFilterRefinement[swimlaneMode],
			},
		}),
		[boardId, swimlaneMode],
	);

	const [suspenselessRefetch] = useSuspenselessRefetch(boardFilterRefinementRefetchQuery, refetch, {
		fetchPolicy: 'network-only',
	});

	const previousAssignees = usePrevious(assignees);

	// When we detect a change in board issue assignees after initial load, we perform a refetch to get the latest values
	useEffect(() => {
		if (isBoardLoaded && !isEqual(assignees, previousAssignees)) {
			suspenselessRefetch({ cloudId, scope });
		}
	}, [assignees, cloudId, isBoardLoaded, previousAssignees, scope, suspenselessRefetch]);

	const fireFilterBarChangedEvent = useCallback(
		(filterType: string) => {
			const analyticsEvent = createAnalyticsEvent({
				action: 'changed',
				actionSubject: 'filterBar',
			});

			fireUIAnalytics(analyticsEvent, 'changeFilterBar', {
				filterType,
			});
		},
		[createAnalyticsEvent],
	);

	const onSearch = useCallback(
		(newJql: string) => {
			const newFilters = {
				...workFilters.values,
				[CUSTOM_FILTER]: selectedCustomFilters,
				[JQL]: newJql,
			};

			dispatch(setAllFilters(newFilters, JQL));

			fireFilterBarChangedEvent(JQL);
		},
		[dispatch, fireFilterBarChangedEvent, selectedCustomFilters, workFilters.values],
	);

	const onCustomFilterChange = useCallback(
		(customFilters: CustomFilterId[]) => {
			const newFilters: Filters = {
				...workFilters.values,
				[CUSTOM_FILTER]: customFilters,
				[JQL]: jql,
			};

			dispatch(setAllFilters(newFilters, CUSTOM_FILTER));

			fireFilterBarChangedEvent(CUSTOM_FILTER);
		},
		[workFilters, dispatch, fireFilterBarChangedEvent, jql],
	);

	const onClear = useCallback(() => {
		dispatch(setAllFilters({}, JQL));

		fireFilterBarChangedEvent(CLEAR);
	}, [dispatch, fireFilterBarChangedEvent]);

	const hasFiltersApplied = jqlFilterActive || customFiltersActive;

	const addPeopleButtonProps = useAddPeopleButtonProps();

	const onError = (_: string, error: Error) => {
		fireOperationalAnalytics(createAnalyticsEvent({}), 'ui taskFail', {
			screenName: 'board',
			task: 'loadCustomFilters',
			isNetworkError: isClientFetchError(error),
			hash: getErrorHash(error) ?? '',
			errorMessage: error.message,
		});
	};

	return (
		<Inline alignBlock="center" space="space.100" shouldWrap>
			<FilterRefinement
				debounce={0} // board already applies a 500ms debounce on filter changes (SET_ALL_FILTERS action)
				defaultJql={jql || ''}
				onSearch={onSearch}
				fragmentRef={filterRefinementFragment}
				searchFieldPlaceholder={formatMessage(messages.filterRefinementSearchFieldPlaceholder)}
				scope={scope}
				changeboardingMessageId={CHANGEBOARDING_MESSAGE_ID}
				{...addPeopleButtonProps}
			/>
			<JSErrorBoundary
				id="custom-filter-dropdown"
				packageName="software-filters"
				teamName="a4t-tanuki"
				fallback="flag"
				extraEventData={{ screenName: 'board' }}
				onError={onError}
			>
				<CustomFilterRelay
					screenName="board"
					onChange={onCustomFilterChange}
					selectedFilters={selectedCustomFilters}
					customFiltersFragmentRef={boardFilterRefinementQueryData}
					removeOuterPadding
				/>
			</JSErrorBoundary>
			{hasFiltersApplied && <ClearButton onClick={onClear} />}
		</Inline>
	);
};
