// eslint-disable-next-line jira/restricted/react-component-props
import React, { useCallback, useEffect, type ComponentProps } from 'react';
// TODO: JFP-2824 | Suppressed to enable upgrade to ESLint v9 - please fix this if you can!
// eslint-disable-next-line jira/restricted/@atlaskit+analytics-next
import { AnalyticsContext } from '@atlaskit/analytics-next';
import { SIMPLE_SOFTWARE_BOARD } from '@atlassian/jira-common-constants/src/analytics-sources.tsx';
import { AGILITY_BOARD } from '@atlassian/jira-common-constants/src/board-types.tsx';
import { getLongTasksMetrics } from '@atlassian/jira-common-long-task-metrics/src/index.tsx';
import { bentoReporter } from '@atlassian/jira-common-long-task-metrics/src/reporters/software-bento.tsx';
import { setMark } from '@atlassian/jira-common-performance/src/marks.tsx';
import { useIssueContextActions } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { simpleSoftwareBoardConfiguration } from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import type { ExternalAction } from '@atlassian/jira-issue-view-store/src/actions/external-actions.tsx';
import type ModalIssueAppType from '@atlassian/jira-issue-view/src/views/issue-details/modal-issue-app.tsx';
import { ModalBoundary } from '@atlassian/jira-modal/src/ui/modal-boundary/index.tsx';
import { SCREEN } from '@atlassian/jira-product-analytics-bridge';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useStorageEnforcement } from '@atlassian/jira-software-enforce-storage-limits/src/controllers/storage-enforcement/index.tsx';
import { useQueryParam } from '@atlassian/jira-software-router-utils/src/services/query-param/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { isViewSettingAsPanelExpEnabledWithNoExposure } from '../../../feature-flags.tsx';
import { BENTO } from '../../../model/issue/issue-update-origin.tsx';
import type { BoardCardCount } from '../../../services/performance/board-data-collector.tsx';
import {
	setIssueViewOpenFeedbackMark,
	setIssueViewOpenFinishMark,
} from '../../../services/performance/issue-view-metrics.tsx';
import { SOFTWARE_ISSUE_SKELETON_MOUNTED_MARK } from '../../../services/performance/performance-marks.tsx';
import { PreIssueViewMountFeedbackMark } from '../../metrics/issue-view/index.tsx';
import type { DispatchProps } from './types.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const IssueModal = lazyForPaint<typeof ModalIssueAppType>(
	() =>
		import(
			/* webpackChunkName: "async-modal-issue-app" */ '@atlassian/jira-issue-view/src/views/issue-details/modal-issue-app'
		),
	{ ssr: false },
);

IssueModal.preload();

type ModalIssueAppConfig = JSX.LibraryManagedAttributes<
	typeof ModalIssueAppType,
	ComponentProps<typeof ModalIssueAppType>
>;

type Props = Flow.Diff<
	ModalIssueAppConfig,
	{
		analyticsSource: ModalIssueAppConfig['analyticsSource'];
		issueKey: ModalIssueAppConfig['issueKey'];
	}
> & {
	externalAction: ExternalAction | undefined;
	boardSessionId: string;
	boardCardCount: BoardCardCount;
	isCMPBoard: boolean;
} & DispatchProps;

const IssueWrapper = (props: Props) => {
	const {
		onChange,
		onClose,
		onDelete,
		onUpdateRequest,
		rapidViewId,
		estimationStatistic,
		externalAction,
		boardSessionId,
		boardCardCount,
		onSwitchToSidebarAction,
		onCloseViewSettingsPanel,
		onResetExternalAction,
		rapidViewMap,
		isCMPBoard,
	} = props;
	const configWithAppKey = simpleSoftwareBoardConfiguration();
	const { applicationKey, ...configWithoutAppKey } = configWithAppKey;
	const [issueKey, setIssueKey] = useQueryParam('selectedIssue');
	const [, { setDetailViewAsSidebar }] = useIssueContextActions();
	const { showEnforcementIfPendingToShow } = useStorageEnforcement();

	const onIssueKeyChange = useCallback(
		({ toIssueKey: nextIssueKey }: { toIssueKey: string }) => setIssueKey(nextIssueKey),
		[setIssueKey],
	);

	const onIssueDelete = useCallback(
		({ issueKey: key, issueId: id }: { issueKey: string; issueId: string }) => {
			const issueId = Number(id);
			onDelete(issueId);

			if (issueId && issueKey !== key) {
				onUpdateRequest([issueId], BENTO);
			}
		},
		[issueKey, onDelete, onUpdateRequest],
	);

	// If there's an external action to be fired, dispatch it as soon as possible
	const onSetDispatchExternalActionRef = useCallback(
		(dispatchExternalActionRef: ((arg1: ExternalAction) => void) | null) => {
			if (dispatchExternalActionRef && externalAction) {
				// If a keyboard shortcut was the trigger for this external action,
				// the key may propagate into a field that is focused in the
				// issue view. setTimeout prevents this.
				setTimeout(() => {
					dispatchExternalActionRef(externalAction);
					// remove optional checking when cleaning up DEE-930
					onResetExternalAction && onResetExternalAction();
				});
			}
		},
		[externalAction, onResetExternalAction],
	);

	useEffect(() => {
		if (!issueKey) {
			return;
		}

		setMark(SOFTWARE_ISSUE_SKELETON_MOUNTED_MARK);

		setIssueViewOpenFeedbackMark();
	}, [issueKey]);

	if (!issueKey) {
		return null;
	}
	const onSwitchToSidebar = (willSave?: boolean) => {
		setDetailViewAsSidebar(willSave);
		onSwitchToSidebarAction();
		isViewSettingAsPanelExpEnabledWithNoExposure() && onCloseViewSettingsPanel();
	};

	const onCloseWithStorageLimitEnforcement = () => {
		onClose();
		showEnforcementIfPendingToShow();
	};

	const issueModal = (
		<IssueModal
			{...configWithoutAppKey}
			analyticsSource={SIMPLE_SOFTWARE_BOARD}
			issueKey={toIssueKey(issueKey)}
			onClose={onCloseWithStorageLimitEnforcement}
			onChange={onChange}
			onIssueDeleteSuccess={onIssueDelete}
			rapidViewId={rapidViewId}
			rapidViewMap={rapidViewMap}
			boardType={AGILITY_BOARD}
			boardId={rapidViewId}
			viewModeOptions={{
				viewMode: 'MODAL',
				viewModeSwitchEnabled: true,
				onSwitchToSidebar,
			}}
			metrics={{
				onReadyForUser: () => {
					getLongTasksMetrics('bento').stop(bentoReporter, {
						type: 'modal',
						boardSessionId,
					});
				},
				onInteractive: () => {
					setIssueViewOpenFinishMark(false, boardCardCount, isCMPBoard);
				},
			}}
			onIssueKeyChange={onIssueKeyChange}
			history={null}
			dispatchExternalActionRef={onSetDispatchExternalActionRef}
			estimationStatistic={estimationStatistic}
		/>
	);

	const issueViewApp = (
		<>
			<PreIssueViewMountFeedbackMark issueKey={issueKey} />
			<ModalBoundary packageName="software-board" id="board-issue-modal">
				{issueModal}
			</ModalBoundary>
		</>
	);

	return (
		<AnalyticsContext
			data={{
				source: `boardIssueView${SCREEN}`,
				attributes: {
					viewMode: 'MODAL',
				},
			}}
		>
			{issueViewApp}
		</AnalyticsContext>
	);
};

export default IssueWrapper;
