import { FetchError, getTraceId } from '@atlassian/jira-early-script-utils/src/index.tsx';
import type { StatusCategoryKeys } from '@atlassian/jira-common-constants/src/status-categories.tsx';
import type { TimeFormat, TimeUnit } from '@atlassian/jira-time-tracking-formatter/src/types.tsx';
import { extractErrorMessage } from '../utils/index.tsx';

type BacklogStrategy = 'NONE' | 'SPRINT' | 'ISSUE_LIST' | 'KANBAN_BACKLOG';

export type BoardConfigResponse = {
	name: string;
	backlogStrategy: BacklogStrategy;
	isBoardCrossProject: boolean;
	parallelSprints: boolean;
	showEpicAsPanel: boolean;
	isMediaOnCardsEnabled: boolean;
	isInlineCardCreateEnabled: boolean;
	estimationStatisticConfig: {
		currentEstimationStatistic: {
			fieldId: string;
			id: string;
			name: string;
			isEnabled: boolean;
			typeId?: string;
			isValid?: boolean;
			renderer?: string;
		};
	};
	trackingStatisticConfig: {
		currentTrackingStatistic: {
			typeId: string;
			fieldId?: string;
			id: string;
			name: string;
			isValid: boolean;
			isEnabled: boolean;
			renderer: string;
		};
	};
	rapidListConfig: {
		mappedColumns: {
			id: number;
			name: string;
			min: string;
			max: string;
			mappedStatuses: {
				id: string;
				name: string;
				iconUrl: string;
				isInitial: boolean;
				isResolutionDone: boolean;
				isPresentInWorkflow?: boolean;
				statusCategory: {
					id: string;
					key: StatusCategoryKeys;
					colorName: string;
				};
			}[];
			isKanPlanColumn?: boolean;
		}[];
	};
	quickFilterConfig: {
		rapidViewId?: number;
		canEdit?: boolean;
		quickFilters: {
			id: number;
			name: string;
			query: string;
			description?: string;
		}[];
	};
	filterConfig: {
		isOrderedByRank: boolean;
		query: string;
	};
};

export type GlobalConfigResponse = {
	epicConfig: {
		colorFieldId: string;
		epicIssueTypeId: string;
		epicIssueTypeName: string;
		epicIssueTypeIconUrl: string;
		epicLinkFieldId: string;
		epicLinkFieldName: string;
	};
	sprintConfig: {
		sprintFieldId: string;
	};
	timeTrackingConfig: {
		daysPerWeek: number;
		hoursPerDay: number;
		defaultUnit: TimeUnit;
		timeFormat: TimeFormat;
	};
};

let configData: Promise<BoardConfigResponse> | null = null;
let globalConfigData: Promise<GlobalConfigResponse> | null = null;

const takeEarlyScriptConfigResponse = (): Promise<BoardConfigResponse> | null => {
	const response = configData;
	configData = null;
	return response;
};

const takeEarlyScriptGlobalConfigResponse = (): Promise<GlobalConfigResponse> | null => {
	const response = globalConfigData;
	globalConfigData = null;
	return response;
};

export function fetchConfigData(boardId: string): Promise<BoardConfigResponse> {
	const earlyScriptResponse = takeEarlyScriptConfigResponse();
	if (earlyScriptResponse) {
		return earlyScriptResponse;
	}

	const promise = fetch(
		`/rest/greenhopper/3/xboard/config?boardId=${boardId}&operation=ConfigData`,
	).then(async (res) => {
		const json = await res.json().catch(() => null);

		if (!res.ok) {
			const traceId = getTraceId(res);
			const message = extractErrorMessage(json);
			throw new FetchError(res.status, message, traceId);
		}
		return json;
	});
	const response = promise;
	configData = response;
	return response;
}

export function fetchGlobalConfigData(): Promise<GlobalConfigResponse> {
	const earlyScriptResponse = takeEarlyScriptGlobalConfigResponse();
	if (earlyScriptResponse) {
		return earlyScriptResponse;
	}

	const promise = fetch('/rest/greenhopper/3/globalconfig?operation=GlobalConfigData').then(
		async (res) => {
			const json = await res.json().catch(() => null);

			if (!res.ok) {
				const traceId = getTraceId(res);
				const message = extractErrorMessage(json);
				throw new FetchError(res.status, message, traceId);
			}
			return json;
		},
	);
	const response = promise;
	globalConfigData = response;
	return response;
}
