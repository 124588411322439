export const View = {
	BACKLOG: 'jsw-backlog',
	BOARD: 'jsw-board',
	INCREMENT_PLANNING_BOARD: 'increment-planning-board',
	JSM_BOARD: 'jsm-board',
};

export const KeyboardShortcut = {
	ISSUE_PARENT_PANEL_SHORTCUT: 'E',
	VERSION_PANEL_SHORTCUT: 'V',
};

export const ExpandableState = {
	EXPANDED: 'expanded',
	COLLAPSED: 'collapsed',
};

export const ViewLocation = {
	SIDEBAR: 'sidebar',
	MODAL: 'modal',
};

export const ViewAs = {
	PANEL: 'Panel',
	POPUP: 'Popup',
};

export const VERSION_PANEL = 'VERSION_PANEL';
export const EPIC_PANEL = 'EPIC_PANEL';
export const QUICK_FILTERS = 'QUICK_FILTERS';
export const COMPACT_MODE = 'COMPACT_MODE';
export const SHOW_EPICS = 'SHOW_EPICS';
export const SHOW_STATUSES = 'SHOW_STATUSES';
export const SHOW_VERSIONS = 'SHOW_VERSIONS';
export const SHOW_ISSUE_LINKS_STATS = 'SHOW_ISSUE_LINKS_STATS';
export const SHOW_UNSCHEDULED_COLUMN = 'SHOW_UNSCHEDULED_COLUMN';
export const SHOW_ISSUE_KEY = 'SHOW_ISSUE_KEY';
export const SHOW_ISSUE_TYPE = 'SHOW_ISSUE_TYPE';
export const SHOW_ESTIMATE = 'SHOW_ESTIMATE';
export const SHOW_PRIORITY = 'SHOW_PRIORITY';
export const SHOW_DEVELOPMENT = 'SHOW_DEVELOPMENT';
export const SHOW_ASSIGNEE = 'SHOW_ASSIGNEE';
export const SHOW_DAYS_IN_COLUMN = 'SHOW_DAYS_IN_COLUMN';
export const SHOW_DUE_DATE = 'SHOW_DUE_DATE';
export const SHOW_LABELS = 'SHOW_LABELS';
export const SHOW_CARD_COVERS = 'SHOW_CARD_COVERS';
export const SHOW_OFFTRACK_DEPENDENCY_LINES = 'SHOW_OFFTRACK_DEPENDENCY_LINES';
export const ISSUE_IDS_TO_SHOW_DEPENDENCIES = 'ISSUE_IDS_TO_SHOW_DEPENDENCIES';
export const SHOW_BOARD_NAME = 'SHOW_BOARD_NAME';

export const Panels = {
	VERSION_PANEL,
	EPIC_PANEL,
} as const;

export const IncrementPlanningSettings = {
	SHOW_UNSCHEDULED_COLUMN,
	SHOW_OFFTRACK_DEPENDENCY_LINES,
	ISSUE_IDS_TO_SHOW_DEPENDENCIES,
} as const;
