import React, { useMemo, useCallback, memo, useRef, useEffect } from 'react';
import { JiraIssueAri } from '@atlassian/ari/jira/issue';
import { cardCoverManagerEntryPoint } from '@atlassian/jira-card-cover-manager/entrypoint.tsx';
import type { CoverMedia } from '@atlassian/jira-card-cover-manager/src/common/cover-media.types.tsx';
import { CardCoverSkeleton } from '@atlassian/jira-card-cover-popover/src/ui/CardCoverSkeleton.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge/src/server.tsx';
import {
	type MenuItemNodeArgs,
	type MenuNode,
	MenuNodeType,
} from '@atlassian/jira-software-context-menu/src/common/types.tsx';
import { useContextMenuActions } from '@atlassian/jira-software-context-menu/src/controllers/context-menu/index.tsx';
import { View } from '@atlassian/jira-software-view-settings/src/common/types/constant.tsx';
import { useViewSettings } from '@atlassian/jira-software-view-settings/src/controllers/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { PACKAGE_NAME } from '../../../../../../../../model/constants.tsx';
import { fetchCardCovers } from '../../../../../../../../state/actions/card-covers/index.tsx';
import { cardMediaDataSet } from '../../../../../../../../state/actions/card/index.tsx';
import { useBoardSelector, useBoardActionCreator } from '../../../../../../../../state/index.tsx';
import { rapidViewIdSelector } from '../../../../../../../../state/selectors/software/software-selectors.tsx';
import { EDIT_COVER_MENU_ID } from '../../constants.tsx';
import { transformCardCoverPopoverData } from './edit-cover-transformer.tsx';
import messages from './messages.tsx';
import type { Props, ContentProps, CardCoverPopoverContentProps } from './types.tsx';

export const CARD_COVER_MEDIA_READ_TOKEN_DURATION = 900;
export const CARD_COVER_MEDIA_READ_TOKEN_MAX_LENGTH = 65534;
export const CARD_COVER_POPOVER_WIDTH = 364;

export const CardCoverPopoverContent = memo(
	({
		entryPointReferenceSubject,
		scope,
		onUpdated,
		onAttachmentSuccess,
	}: CardCoverPopoverContentProps) => {
		const { closeSubMenu } = useContextMenuActions();

		const onClose = useCallback(() => closeSubMenu(scope), [closeSubMenu, scope]);

		const runtimeProps = useMemo(
			() => ({
				durationInSeconds: CARD_COVER_MEDIA_READ_TOKEN_DURATION,
				maxTokenLength: CARD_COVER_MEDIA_READ_TOKEN_MAX_LENGTH,
				onClose,
				onUpdated,
				onAttachmentSuccess,
			}),
			[onClose, onUpdated, onAttachmentSuccess],
		);

		return (
			<JiraEntryPointContainer
				id="jira.board.context-menu.card-covers.popover"
				packageName={PACKAGE_NAME}
				entryPointReferenceSubject={entryPointReferenceSubject}
				fallback={<CardCoverSkeleton />}
				errorFallback="flag"
				runtimeProps={runtimeProps}
			/>
		);
	},
);

export const useEditCoverContent = ({
	entryPointReferenceSubject,
	entryPointActions,
	onUpdated,
	onAttachmentSuccess,
}: ContentProps): MenuNode[] => {
	const { formatMessage } = useIntl();
	return [
		{
			type: MenuNodeType.Item,
			id: EDIT_COVER_MENU_ID,
			label: formatMessage(messages.editCover),
			submenuWidth: CARD_COVER_POPOVER_WIDTH,
			onMouseEnter: entryPointActions.preload,
			children: [
				{
					type: MenuNodeType.Node,
					id: `${EDIT_COVER_MENU_ID}/popover`,
					node: ({ scope }: MenuItemNodeArgs) => {
						entryPointActions.load();

						return (
							<CardCoverPopoverContent
								entryPointReferenceSubject={entryPointReferenceSubject}
								scope={scope}
								onUpdated={onUpdated}
								onAttachmentSuccess={onAttachmentSuccess}
							/>
						);
					},
				},
			],
			analytics: {
				attributes: {
					selectedOption: EDIT_COVER_MENU_ID,
				},
			},
		},
	];
};

export const useEditCover = ({ issueId }: Props) => {
	const cloudId = useCloudId();

	const entryPointParams = useMemo(
		() => ({
			cloudId,
			durationInSeconds: CARD_COVER_MEDIA_READ_TOKEN_DURATION,
			maxTokenLength: CARD_COVER_MEDIA_READ_TOKEN_MAX_LENGTH,
			issueId: JiraIssueAri.create({
				issueId: String(issueId),
				siteId: cloudId,
			}).toString(),
		}),
		[cloudId, issueId],
	);

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		cardCoverManagerEntryPoint,
		entryPointParams,
	);

	const [state, actions] = useViewSettings();
	const { showCardCovers } = state;
	const { setViewSettingsShowCardCovers } = actions;
	const boardId = useBoardSelector(rapidViewIdSelector);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const setCardCoverAction = useBoardActionCreator(cardMediaDataSet);
	const fetchCardCoversAction = useBoardActionCreator(() =>
		fetchCardCovers([], { analyticsEvent: createAnalyticsEvent({}) }),
	);

	const showCardCoversRef = useRef<boolean>(showCardCovers);
	useEffect(() => {
		if (!showCardCoversRef.current && showCardCovers) {
			// start fetching card cover data if the user turned on toggle
			fetchCardCoversAction();
		}
		showCardCoversRef.current = showCardCovers;
	}, [showCardCovers, fetchCardCoversAction]);

	const onUpdated = (coverMedia: CoverMedia) => {
		// update the stored card cover data for the issue that has been updated
		setCardCoverAction(transformCardCoverPopoverData(coverMedia, String(issueId)));
	};

	const onAttachmentSuccess = () => {
		setViewSettingsShowCardCovers({
			boardId: Number(boardId),
			view: View.BOARD,
			showCardCovers: true,
		});
	};

	return useEditCoverContent({
		entryPointReferenceSubject,
		entryPointActions,
		onUpdated,
		onAttachmentSuccess,
	});
};
