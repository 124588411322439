import React from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import type { CardId } from '../../../../model/card/card-types.tsx';
import { CAN_EDIT_ISSUE } from '../../../../model/permission/permission-types.tsx';
import { useBoardSelector } from '../../../../state/index.tsx';
import {
	getPermissionsSelector,
	isIncrementPlanningReadOnly,
} from '../../../../state/selectors/board/board-permissions-selectors.tsx';
import {
	getCardEditSummary,
	getCardSelection,
} from '../../../../state/selectors/card/card-selectors.tsx';
import { getIsIssueDoneInClosedSprint } from '../../../../state/selectors/issue/issue-selectors.tsx';
import { getIsIncrementPlanningBoard } from '../../../../state/selectors/software/software-selectors.tsx';
import { isActiveCardSelector } from '../../../../state/selectors/work/work-selectors.tsx';
import CardLite from './card-lite/index.tsx';
import { CardWithIcc } from './card-with-icc/index.tsx';
import CardWithRich from './card-with-rich/index.tsx';
import CardCover from './card/cover/index.tsx';
import Card from './card/index.tsx';
import { useShouldRenderCardLite } from './utils/index.tsx';

export type Props = {
	isColumnIccEnabled: boolean;
	isLastSwimlane: boolean;
	isScrolling: boolean;
	canShowIccNudge: boolean;
	id: CardId;
	index: number;
	columnId: number;
	swimlaneId: string | null | undefined;
	userCanViewMenu: boolean;
	onIssueChange: () => void;
	isDraggable?: boolean;
	groupParentId?: CardId;
	headerRef: React.RefObject<HTMLDivElement>;
};

const CardPreview = ({ cardId }: { cardId: CardId }) => {
	const selectedCards = useBoardSelector(getCardSelection);
	// Check that the card is in the selected cards in case there are some cards selected
	// and the user drags a different card.
	if (selectedCards.length > 1 && selectedCards.includes(cardId)) {
		return (
			<CardPreviewContainer data-count={selectedCards.length}>
				<CardLite id={cardId} />
			</CardPreviewContainer>
		);
	}
	return <CardLite id={cardId} />;
};

const CardContainer = ({
	id,
	index,
	columnId,
	swimlaneId,
	userCanViewMenu,
	isColumnIccEnabled,
	isLastSwimlane,
	onIssueChange,
	isScrolling,
	canShowIccNudge,
	isDraggable = true,
	groupParentId,
	headerRef,
}: Props) => {
	const shouldRenderCardLite = useShouldRenderCardLite(isScrolling);
	const canEditIssue = useBoardSelector(getPermissionsSelector)[CAN_EDIT_ISSUE];
	const isActiveCard = useBoardSelector((state) => isActiveCardSelector(state, { issueId: id }));
	const isSummaryEditing = useBoardSelector((state) => getCardEditSummary(state)(id));
	const isIPBoard = useBoardSelector(getIsIncrementPlanningBoard);
	const isIncrementPlanningCardDraggable = useBoardSelector(
		(state) => !getIsIssueDoneInClosedSprint(state, id),
	);
	const isIPBoardReadOnly = useBoardSelector(isIncrementPlanningReadOnly);

	let cardIsDraggable = isDraggable && !isSummaryEditing;

	if (isIPBoard) {
		cardIsDraggable = cardIsDraggable && isIncrementPlanningCardDraggable && !isIPBoardReadOnly;
	}

	if (shouldRenderCardLite) {
		return <CardLite id={id} />;
	}

	const issueCard = (
		<Card
			key={`card-${id}`}
			id={id}
			isLoading={
				// @ts-expect-error ([Part of upgrade 4.9-5.4]) - Operator '<=' cannot be applied to types 'CardId' and 'number'.
				id <= 0
			}
			draggableIndex={index}
			userCanViewMenu={userCanViewMenu}
			Cover={CardCover}
			onIssueChange={onIssueChange ?? noop}
			isDraggable={cardIsDraggable}
			columnId={columnId}
			swimlaneId={swimlaneId}
			CardPreview={CardPreview}
			isActive={isActiveCard}
		/>
	);

	const cardWithRich = (
		<CardWithRich
			key={`card-with-rich-${id}`}
			id={id}
			cardIndex={index}
			userCanViewMenu={userCanViewMenu}
			onIssueChange={onIssueChange}
			isDraggable={cardIsDraggable}
			columnId={columnId}
			swimlaneId={swimlaneId}
			CardPreview={CardPreview}
		/>
	);

	return (
		<CardWithIcc
			key={`card-with-icc-${id}`}
			isColumnIccEnabled={isColumnIccEnabled}
			id={id}
			cardIndex={index}
			columnId={columnId}
			swimlaneId={swimlaneId}
			isLastSwimlane={isLastSwimlane}
			onIssueChange={onIssueChange ?? noop}
			canShowIccNudge={canShowIccNudge}
			forceIccRender={canShowIccNudge && index === 1}
			groupParentId={groupParentId}
			headerRef={headerRef}
			{...(fg('render_program_board_card_in_popup') ? { isScrolling } : {})}
		>
			{canEditIssue ? cardWithRich : issueCard}
		</CardWithIcc>
	);
};

export default CardContainer;

// No real need to have the data attribute here, it's just for type safety
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardPreviewContainer = styled.div<{ 'data-count': number }>({
	position: 'relative',
	// Margin on bottom to make sure shadow doesn't get cut off
	marginBottom: token('space.300'),

	'&::before': {
		content: '""',
		position: 'absolute',
		display: 'block',
		inset: 0,
		backgroundColor: token('color.background.neutral.hovered'),
		height: '100%',
		marginInline: token('space.100'),
		transform: 'translateY(5px)',
		// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage -- The token value "8px" and fallback "3px" do not match and can't be replaced automatically.
		borderRadius: token('border.radius.200', '3px'),
		boxShadow: `
          0 22px 5px -1rem ${token('color.background.neutral.hovered')},
          0 1px 5px 0 #091e4240`,
	},
	'&::after': {
		position: 'absolute',
		display: 'inline',
		top: 0,
		right: 0,
		content: "' ' attr(data-count)",
		transform: 'translate(50%, -50%)',
		backgroundColor: token('color.background.accent.blue.bolder'),
		paddingTop: token('space.025'),
		paddingRight: token('space.075'),
		paddingBottom: token('space.025'),
		paddingLeft: token('space.075'),
		font: token('font.body.UNSAFE_small'),
		color: token('color.text.inverse'),
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
		lineHeight: 1,
		borderRadius: '2rem',
	},
});
