import { combineReducers } from 'redux';
import type { Action } from '../../../types.tsx';
import { boardColumnsReducer } from './columns/reducer.tsx';
import { boardConfigReducer } from './config/reducer.tsx';
import { boardFavouriteReducer } from './favourite/reducer.tsx';
import { hasFilteredIssuesReducer } from './has-filtered-issues/reducer.tsx';
import { isConfigLoadedReducer } from './is-config-loaded/reducer.tsx';
import { isCurrentUserLoadedReducer } from './is-current-user-loaded/reducer.tsx';
import { boardIssueChildrenReducer } from './issue-children/reducer.tsx';
import { boardIssueParentReducer } from './issue-parents/reducer.tsx';
import { boardIssuesReducer } from './issues/reducer.tsx';
import { permissionsReducer } from './permissions/reducer.tsx';
import { selectedSprintsReducer } from './selected-sprint/reducer.tsx';
import type { BoardState } from './types.tsx';

export default function boardReducer() {
	return combineReducers<BoardState, Action>({
		columns: boardColumnsReducer,
		config: boardConfigReducer,
		hasFilteredIssues: hasFilteredIssuesReducer,
		issues: boardIssuesReducer,
		selectedSprints: selectedSprintsReducer,
		isConfigLoaded: isConfigLoadedReducer,
		isCurrentUserLoaded: isCurrentUserLoadedReducer,
		permissions: permissionsReducer,
		issueChildren: boardIssueChildrenReducer,
		issueParentOrder: boardIssueParentReducer,
		boardFavourite: boardFavouriteReducer,
	});
}
