import type { ProjectType } from '@atlassian/jira-common-constants/src/index.tsx';
import {
	SOFTWARE_PROJECT,
	SERVICE_DESK_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';

export const Capability = {
	/**
	 * Can be removed when the new navigation is enabled for all users
	 * and the old navigation is removed. And when jsm_views_of_work_phase_1
	 * is cleaned up.
	 */
	IS_TAB_NAVIGATION: 'IS_TAB_NAVIGATION',
	RENDER_UIF_OPT_IN_FLAG: 'RENDER_UIF_OPT_IN_FLAG',
	REMOVE_FROM_SPRINT: 'REMOVE_FROM_SPRINT',
	// CMP header menu items
	RENDER_BOARD_MORE_MENU_ITEM: 'RENDER_BOARD_MORE_MENU_ITEM',
	RENDER_BOARD_SETTINGS_MENU_ITEM: 'RENDER_BOARD_SETTINGS_MENU_ITEM',
	RENDER_CREATE_BOARD_MENU_ITEM: 'RENDER_CREATE_BOARD_MENU_ITEM',
	/**
	 * When enabled, columns will expand to fit the display width. The board
	 * will be scrollable when columns are under a minimum width.
	 */
	FLEXIBLE_BOARD_COLUMNS: 'FLEXIBLE_BOARD_COLUMNS',
	INLINE_COLUMN_EDIT: 'INLINE_COLUMN_EDIT',
	// TMP header menu items
	RENDER_EDIT_SPRINT_MENU_ITEM: 'RENDER_EDIT_SPRINT_MENU_ITEM',
	RENDER_MANAGE_WORKFLOW_MENU_ITEM: 'RENDER_MANAGE_WORKFLOW_MENU_ITEM',
	RENDER_MOVE_TO_BACKLOG_CONTEXT_MENU_ITEM: 'RENDER_MOVE_TO_BACKLOG_CONTEXT_MENU_ITEM',
	// header filters
	RENDER_STATUS_CATEGORIES_FILTER: 'RENDER_STATUS_CATEGORIES_FILTER',
	RENDER_VERSIONS_FILTER: 'RENDER_VERSIONS_FILTER',
	RENDER_ISSUE_PARENTS_FILTER: 'RENDER_ISSUE_PARENTS_FILTER',
	RENDER_LABELS_FILTER: 'RENDER_LABELS_FILTER',
	RENDER_ISSUE_PROJECTS_FILTER: 'RENDER_ISSUE_PROJECTS_FILTER',
	RENDER_ISSUE_TYPES_FILTER: 'RENDER_ISSUE_TYPES_FILTER',
	RENDER_COMPONENTS_FILTER: 'RENDER_COMPONENTS_FILTER',
	RENDER_SPRINTS_FILTER: 'RENDER_SPRINTS_FILTER',
	RENDER_REQUEST_TYPES_FILTER: 'RENDER_REQUEST_TYPES_FILTER',
	RENDER_STATUSES_FILTER: 'RENDER_STATUSES_FILTER',
	RENDER_DEPENDENCIES_FILTER: 'RENDER_DEPENDENCIES_FILTER',
	// Transition zone label
	HIDE_COLUMN_HEADER_OVERLAY: 'HIDE_COLUMN_HEADER_OVERLAY',
	DISABLE_TRANSITION_IN_SAME_COLUMN: 'DISABLE_TRANSITION_IN_SAME_COLUMN',

	// Card Menu Items
	RENDER_ADD_FLAG_CARD_MENU_ITEM: 'RENDER_ADD_FLAG_CARD_MENU_ITEM',
	RENDER_ADD_LABEL_CARD_MENU_ITEM: 'RENDER_ADD_LABEL_CARD_MENU_ITEM',
	RENDER_EDIT_COVER: 'RENDER_EDIT_COVER',
	RENDER_CLONE_ISSUE: 'RENDER_CLONE_ISSUE',

	// Request type icon position for service management cards.
	RENDER_COMPACT_ISSUE_TYPE_ICON_ON_CARD: 'RENDER_COMPACT_ISSUE_TYPE_ICON_ON_CARD',

	// Board Header Buttons
	RENDER_BOARD_AUTOMATION_HEADER_BUTTON: 'RENDER_BOARD_AUTOMATION_HEADER_BUTTON',
	RENDER_BOARD_FAVOURITE_HEADER_BUTTON: 'RENDER_BOARD_FAVOURITE_HEADER_BUTTON',
	RENDER_BOARD_RELEASE_MENU_BUTON: 'RENDER_BOARD_RELEASE_MENU_BUTON',
	RENDER_BOARD_INSIGHT_BUTTON: 'RENDER_BOARD_INSIGHT_BUTTON',
	RENDER_JSM_BOARD_INSIGHT_BUTTON: 'RENDER_JSM_BOARD_INSIGHT_BUTTON',
	RENDER_BOARD_FEEDBACK_COLLECTOR_BUTTON: 'RENDER_BOARD_FEEDBACK_COLLECTOR_BUTTON',
	RENDER_BOARD_FULLSCREEN_BUTTON: 'RENDER_BOARD_FULLSCREEN_BUTTON',
	RENDER_BOARD_FULLSCREEN_MENU_ITEM: 'RENDER_BOARD_FULLSCREEN_MENU_ITEM',

	// View Settings
	RENDER_VIEW_SETTINGS_CARD_DETAILS_OPTION: 'RENDER_VIEW_SETTINGS_CARD_DETAILS_OPTION',

	// Card devops status
	ENABLE_DEVOPS_ON_BOARD: 'ENABLE_DEVOPS_ON_BOARD',

	// Card multiselection
	MULTISELECT_CARD_ACROSS_SWIMLANES: 'MULTISELECT_CARD_ACROSS_SWIMLANES,',

	// Card context menu display
	RENDER_INCREMENT_PLANNING_CONTEXT_MENU: 'RENDER_INCREMENT_PLANNING_CONTEXT_MENU',

	RENDER_REQUEST_TYPE_SWIMLANE_OPTION: 'RENDER_REQUEST_TYPE_SWIMLANE_OPTION',
	RENDER_EPIC_SWIMLANE_OPTION: 'RENDER_EPIC_SWIMLANE_OPTION',
	RENDER_QUERIES_SWIMLANE_OPTION: 'RENDER_QUERIES_SWIMLANE_OPTION',
	RENDER_PROJECTS_SWIMLANE_OPTION: 'RENDER_PROJECTS_SWIMLANE_OPTTION',
	RENDER_STORIES_SWIMLANE_OPTION: 'RENDER_STORIES_SWIMLANE_OPTION',

	// Show flags for issue limits
	SHOW_ISSUE_LIMIT_FLAGS: 'SHOW_ISSUE_LIMIT_FLAGS',
} as const;

export type CapabilityKey = (typeof Capability)[keyof typeof Capability];

/**
 * This is duplicated from FeatureGQL to prevent cycles.
 */
type FeatureType = { key: 'SPRINTS' | string; status: string };

export function hasCapability(
	key: CapabilityKey,
	isCMPBoard: boolean,
	isIncrementPlanningBoard: boolean,
	allFeatures: FeatureType[] | null,
	projectType: ProjectType = SOFTWARE_PROJECT,
	isUserBoard = false,
): boolean {
	const hasSprintFeatureEnabled =
		allFeatures?.some((feature) => feature.key === 'SPRINTS' && feature.status === 'ENABLED') ??
		false;
	switch (key) {
		case Capability.IS_TAB_NAVIGATION:
			return (
				(getWillShowNav4() && projectType === SOFTWARE_PROJECT) ||
				(projectType === SERVICE_DESK_PROJECT && fg('jsm_views_of_work_phase_1'))
			);
		case Capability.REMOVE_FROM_SPRINT:
			return isCMPBoard && hasSprintFeatureEnabled;
		case Capability.RENDER_UIF_OPT_IN_FLAG:
		case Capability.RENDER_CREATE_BOARD_MENU_ITEM:
			if (getWillShowNav4()) {
				return isUserBoard;
			}

			return projectType !== SERVICE_DESK_PROJECT && isCMPBoard;
		case Capability.RENDER_BOARD_MORE_MENU_ITEM:
			// Increment planning/program board doesn't have more menu item
			if (isIncrementPlanningBoard && getWillShowNav4()) {
				return false;
			}
			return !fg('jsm_views_of_work_phase_1') || projectType !== SERVICE_DESK_PROJECT;
		// can clean up this capability when jsm_views_of_work_phase_1 and getWillShowNav4() is cleaned up
		case Capability.RENDER_BOARD_SETTINGS_MENU_ITEM:
			if (fg('jsm_views_of_work_phase_1')) {
				if (getWillShowNav4()) {
					return isUserBoard;
				}
				return isCMPBoard && projectType !== SERVICE_DESK_PROJECT;
			}

			if (getWillShowNav4()) {
				return (isCMPBoard && projectType === SERVICE_DESK_PROJECT) || isUserBoard;
			}

			return isCMPBoard;
		case Capability.RENDER_BOARD_FULLSCREEN_BUTTON:
			// Increment planning/program board have fullscreen button
			if (isIncrementPlanningBoard && getWillShowNav4()) {
				return true;
			}

			if (getWillShowNav4() && fg('blu-5747-fullscreen-in-nav-header')) {
				return projectType === SERVICE_DESK_PROJECT;
			}

			if (
				!getWillShowNav4() &&
				projectType === SERVICE_DESK_PROJECT &&
				fg('jsm_nav3_full_screen_board_')
			) {
				return true;
			}

			return (
				!fg('jsm_views_of_work_phase_1') ||
				getWillShowNav4() ||
				projectType !== SERVICE_DESK_PROJECT
			);
		case Capability.RENDER_BOARD_FULLSCREEN_MENU_ITEM:
			// Increment planning/program board doesn't have fullscreen menu item
			if (isIncrementPlanningBoard && getWillShowNav4()) {
				return false;
			}

			return (
				getWillShowNav4() &&
				!fg('blu-5747-fullscreen-in-nav-header') &&
				projectType === SOFTWARE_PROJECT
			);
		case Capability.HIDE_COLUMN_HEADER_OVERLAY:
			return isCMPBoard;
		case Capability.FLEXIBLE_BOARD_COLUMNS:
			return isCMPBoard || isIncrementPlanningBoard;
		case Capability.INLINE_COLUMN_EDIT:
			if (isIncrementPlanningBoard || projectType !== SOFTWARE_PROJECT) {
				return false;
			}
			return true;
		case Capability.DISABLE_TRANSITION_IN_SAME_COLUMN:
			// Only disable the transitions if the project is NOT service desk.
			// This should only ever be disabled for CMP software projects
			return isCMPBoard && projectType !== SERVICE_DESK_PROJECT;
		case Capability.MULTISELECT_CARD_ACROSS_SWIMLANES:
			return !isIncrementPlanningBoard;
		case Capability.RENDER_INCREMENT_PLANNING_CONTEXT_MENU:
			return isIncrementPlanningBoard;
		case Capability.RENDER_EDIT_SPRINT_MENU_ITEM:
			if (isIncrementPlanningBoard) {
				return false;
			}
			return hasSprintFeatureEnabled;
		case Capability.RENDER_MANAGE_WORKFLOW_MENU_ITEM:
		case Capability.RENDER_MOVE_TO_BACKLOG_CONTEXT_MENU_ITEM:
			if (isIncrementPlanningBoard) {
				return false;
			}
			return !isCMPBoard;
		case Capability.RENDER_ISSUE_PROJECTS_FILTER:
			return isIncrementPlanningBoard;
		case Capability.RENDER_DEPENDENCIES_FILTER:
			return isIncrementPlanningBoard;
		case Capability.RENDER_LABELS_FILTER:
			if (isIncrementPlanningBoard) {
				return false;
			}

			return projectType !== SERVICE_DESK_PROJECT;
		case Capability.RENDER_ISSUE_PARENTS_FILTER:
		case Capability.RENDER_ISSUE_TYPES_FILTER:
			return projectType !== SERVICE_DESK_PROJECT || isIncrementPlanningBoard;
		case Capability.RENDER_STATUS_CATEGORIES_FILTER:
		case Capability.RENDER_SPRINTS_FILTER:
		case Capability.RENDER_BOARD_AUTOMATION_HEADER_BUTTON: // Board Header Buttons
		case Capability.RENDER_BOARD_FAVOURITE_HEADER_BUTTON:
		case Capability.RENDER_BOARD_RELEASE_MENU_BUTON:
		case Capability.RENDER_BOARD_INSIGHT_BUTTON:
		case Capability.RENDER_ADD_FLAG_CARD_MENU_ITEM:
			if (isIncrementPlanningBoard) {
				return false;
			}
			return projectType !== SERVICE_DESK_PROJECT;
		case Capability.RENDER_ADD_LABEL_CARD_MENU_ITEM: // Card Menu Items
			if (isIncrementPlanningBoard) {
				return true;
			}
			return projectType !== SERVICE_DESK_PROJECT;
		case Capability.RENDER_EDIT_COVER:
			return projectType === SOFTWARE_PROJECT && isCMPBoard;
		case Capability.RENDER_CLONE_ISSUE:
			return (
				projectType === SOFTWARE_PROJECT && expVal('cloning_tasks_entrypoints', 'isEnabled', false)
			);
		case Capability.ENABLE_DEVOPS_ON_BOARD:
			if (isIncrementPlanningBoard) {
				return false;
			}
			return projectType !== SERVICE_DESK_PROJECT;
		case Capability.RENDER_JSM_BOARD_INSIGHT_BUTTON:
			if (isIncrementPlanningBoard) {
				return false;
			}
			return projectType === SERVICE_DESK_PROJECT;
		case Capability.RENDER_COMPACT_ISSUE_TYPE_ICON_ON_CARD:
			return projectType === SERVICE_DESK_PROJECT;
		case Capability.RENDER_REQUEST_TYPES_FILTER:
		case Capability.RENDER_STATUSES_FILTER:
			return projectType === SERVICE_DESK_PROJECT;
		case Capability.RENDER_BOARD_FEEDBACK_COLLECTOR_BUTTON:
			return projectType === SERVICE_DESK_PROJECT && !fg('jsm_views_of_work_phase_1');
		case Capability.RENDER_VIEW_SETTINGS_CARD_DETAILS_OPTION:
			if (isIncrementPlanningBoard) {
				return false;
			}
			return !(projectType === SERVICE_DESK_PROJECT);
		case Capability.RENDER_PROJECTS_SWIMLANE_OPTION:
		case Capability.RENDER_STORIES_SWIMLANE_OPTION:
		case Capability.RENDER_EPIC_SWIMLANE_OPTION:
			if (projectType === SERVICE_DESK_PROJECT) {
				return false;
			}
			return true;
		case Capability.RENDER_QUERIES_SWIMLANE_OPTION: {
			return true;
		}
		case Capability.RENDER_REQUEST_TYPE_SWIMLANE_OPTION:
			if (projectType === SERVICE_DESK_PROJECT) {
				return true;
			}
			return false;
		case Capability.SHOW_ISSUE_LIMIT_FLAGS:
			return projectType === SOFTWARE_PROJECT;
		case Capability.RENDER_VERSIONS_FILTER:
		case Capability.RENDER_COMPONENTS_FILTER:
		default:
			return false;
	}
}
