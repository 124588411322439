/*!
 * Adding too many scroll listeners adds overhead to both the initial
 * "bootstrap" phase, when the listeners are added, and all scroll events.
 *
 * Some virtualisation use-cases such as boards may have a large number of
 * virtual lists instances. Sharing a scroll element through context avoids
 * attaching one listener per list.
 */

import React, {
	useEffect,
	useRef,
	useState,
	createContext,
	type ReactNode,
	useContext,
	type RefObject,
} from 'react';

/**
 * Height, scroll position and scrolling state of an element.
 */
export interface ScrollState {
	height: number;
	width: number;
	scrollHeight: number;
	scrollWidth: number;
	scrollTop: number;
	scrollLeft: number;
	isScrolling: boolean;
	scrollElement: HTMLElement | null;
}

/**
 * Time in milliseconds between scroll events for `isScrolling` to reset to false.
 */
export const SCROLLING_TIMEOUT = 200;

const DEFAULT_SCROLL_STATE: ScrollState = {
	height: 0,
	width: 0,
	scrollHeight: 0,
	scrollWidth: 0,
	scrollTop: 0,
	scrollLeft: 0,
	isScrolling: false,
	scrollElement: null,
};

const ScrollStateContext = createContext<ScrollState>(DEFAULT_SCROLL_STATE);

/**
 * Returns the `ScrollState` of the closest scroll container tracked by
 * `ScrollStateContextProvider`.
 */
export const useScrollState = (): ScrollState => useContext(ScrollStateContext);

export interface Props {
	scrollRef: RefObject<HTMLElement>;
	children: ReactNode;
	shouldSkipFirstResize?: boolean;
	initialScrollState?: ScrollState;
}

const attachResizeObserver = (callback: () => void, targetEl: HTMLElement | Window | null) => {
	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	if (!targetEl) return () => {};

	let resizeObserver: ResizeObserver | null = null;
	// SSR fix. Do not instantiate for SSR
	if (typeof ResizeObserver !== 'undefined') {
		resizeObserver = new ResizeObserver(() => callback());
	}

	// When mounting our listener, if its the Window object, treat it as such.
	// This can happen in Storybook but not in any other scenario
	if (targetEl instanceof Window) {
		targetEl.addEventListener('resize', callback);
	} else {
		resizeObserver?.observe(targetEl);
	}
	return () => {
		// Cleanup the resize callback we have attached
		if (targetEl instanceof Window) {
			targetEl.removeEventListener('resize', callback);
		} else {
			resizeObserver?.disconnect();
		}
	};
};

/**
 * Listens for scroll events on the `HTMLElement` `scrollRef.current` and updates a state
 * variable with the scroll state, which is returned.
 */
const useElementScrollState = (
	scrollRef: { current: HTMLElement | null },
	shouldSkipFirstResize?: boolean,
	initialScrollState?: ScrollState,
): ScrollState => {
	const [scrollState, setScrollState] = useState(initialScrollState ?? DEFAULT_SCROLL_STATE);

	const isFirstResize = useRef(true);
	useEffect(() => {
		const targetEl = scrollRef.current;
		if (!targetEl) {
			return undefined;
		}

		let clearScrollingTimeoutId: ReturnType<typeof setTimeout>;
		const onScroll = () => {
			setScrollState((state) => ({
				...state,
				scrollTop: targetEl.scrollTop,
				scrollLeft: targetEl.scrollLeft,
				isScrolling: true,
			}));

			clearTimeout(clearScrollingTimeoutId);
			clearScrollingTimeoutId = setTimeout(() => {
				setScrollState((state) => ({
					...state,
					scrollTop: targetEl.scrollTop,
					scrollLeft: targetEl.scrollLeft,
					isScrolling: false,
				}));
			}, SCROLLING_TIMEOUT);
		};

		const onResize = () => {
			if (shouldSkipFirstResize && isFirstResize.current) {
				isFirstResize.current = false;
				return;
			}
			setScrollState((state) => ({
				...state,
				scrollTop: targetEl.scrollTop,
				scrollLeft: targetEl.scrollLeft,
				height: targetEl.clientHeight,
				width: targetEl.clientWidth,
				scrollHeight: targetEl.scrollHeight,
				scrollWidth: targetEl.scrollWidth,
				isScrolling: true,
			}));

			clearTimeout(clearScrollingTimeoutId);
			clearScrollingTimeoutId = setTimeout(() => {
				setScrollState((state) => ({
					...state,
					scrollTop: targetEl.scrollTop,
					scrollLeft: targetEl.scrollLeft,
					isScrolling: false,
				}));
			}, SCROLLING_TIMEOUT);
		};

		// We want to know when the scroll element/container itself resizes as this impacts
		// the number of rows we show
		const cleanupResizeObserver = attachResizeObserver(onResize, targetEl);
		targetEl.addEventListener('scroll', onScroll);

		setScrollState({
			height: targetEl.clientHeight,
			width: targetEl.clientWidth,
			scrollHeight: targetEl.scrollHeight,
			scrollWidth: targetEl.scrollWidth,
			scrollTop: targetEl.scrollTop,
			scrollLeft: targetEl.scrollLeft,
			isScrolling: false,
			scrollElement: targetEl,
		});

		return () => {
			targetEl.removeEventListener('scroll', onScroll);

			cleanupResizeObserver();

			clearTimeout(clearScrollingTimeoutId);
		};
	}, [scrollRef, shouldSkipFirstResize]);

	return scrollState;
};

/**
 * Provides a scroll element via context to this sub-tree. This is required by
 * `useVirtual`.
 */
export const ScrollStateContextProvider = ({
	scrollRef,
	shouldSkipFirstResize,
	children,
	initialScrollState,
}: Props) => {
	const scrollState = useElementScrollState(scrollRef, shouldSkipFirstResize, initialScrollState);

	return <ScrollStateContext.Provider value={scrollState}>{children}</ScrollStateContext.Provider>;
};
