// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createStore,
	createContainer,
	createHook,
	createActionsHook,
	defaultRegistry,
} from '@atlassian/react-sweet-state';
import { type Actions, actions } from './actions.tsx';
import type { State } from './types.tsx';

const initialState: State = {
	activeViewSettingsSpotlight: null,
	hasViewedViewSettingsSpotlight: true,
	openViewSettings: null,
	viewSettingsTogglePulses: false,
} as const;

const Store = createStore<State, Actions>({
	initialState,
	actions,
	name: 'jsw.board.work-suggestions-spotlight-store',
});

export const resetBoardWorkSuggestionsSpotlightStore = () => defaultRegistry.deleteStore(Store);

export const BoardWorkSuggestionsSpotlightContainer = createContainer<State, Actions>(Store);

export const useBoardWorkSuggestionsSpotlightContainer = createHook(Store);

export const useBoardWorkSuggestionsSpotlightActions = createActionsHook(Store);
