import React, { useCallback } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { IconButton } from '@atlaskit/button/new';
import Close from '@atlaskit/icon/core/migration/close--cross';
import { Box } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

type Props = {
	onClear: () => void;
};

export const ClearButton = ({ onClear }: Props) => {
	const { formatMessage } = useIntl();

	const handleClick = useCallback(
		(_: React.MouseEvent<HTMLButtonElement, MouseEvent>, analyticsEvent: UIAnalyticsEvent) => {
			onClear();
			fireUIAnalytics(analyticsEvent, 'clearSearch');
		},
		[onClear],
	);

	return (
		<Box paddingInlineEnd="space.025">
			<IconButton
				icon={Close}
				appearance="subtle"
				spacing="compact"
				label={formatMessage(messages.clearButton)}
				onClick={handleClick}
				isTooltipDisabled={false}
			/>
		</Box>
	);
};
