import type { State } from './types/state.tsx';

export const VIEW_SETTINGS = 'view-settings';
export const VIEW_SETTINGS_LOCAL_STORAGE = 'jira-software-view-settings';

export const initialState: State = {
	view: null,
	isFullscreen: false,
	showEpicPanel: false,
	showVersionPanel: false,
	showQuickFilters: false,
	showEpics: true,
	showStatuses: true,
	showVersions: true,
	showCompactMode: false,
	showUnscheduledColumn: true,
	showIssueLinksStats: false,
	showIssueKey: true,
	showIssueType: true,
	showEstimate: true,
	showAssignee: true,
	showPriority: true,
	showDevelopment: true,
	showDaysInColumn: true,
	showDueDate: true,
	showLabels: true,
	showCardCovers: true,
	showOfftrackDependencyLines: true,
	showBoardName: true,
	issueIdsToShowDependencies: [],
	cardExtraFields: {},
};

export const boardViewTestIds = {
	viewSettingsBoardMenu: 'software-view-settings.ui.view-settings-menu.menu-board',
	openIssuesInSidebarToggle:
		'software-view-settings.ui.view-settings-menu.views.board.open-issues-in-sidebar',
	showQuickFiltersToggle:
		'software-view-settings.ui.view-settings-menu.views.board.show-quick-filters-toggle',
	showWorkSuggestionsToggle:
		'software-view-settings.ui.view-settings-menu.views.board.work-suggestions-toggle',
	swimlaneExpandAll: 'software-view-settings.ui.view-settings-menu.views.board.expand-all',
	swimlaneCollapseAll: 'software-view-settings.ui.view-settings-menu.views.board.collapse-all',
	cardDetailsSection: 'software-view-settings.ui.view-settings-menu.views.board.card-details-board',
	showIssueTypeToggle:
		'software-view-settings.ui.view-settings-menu.views.board.show-issue-type-toggle-board',
	showIssueKeyToggle:
		'software-view-settings.ui.view-settings-menu.views.board.show-issue-key-toggle-board',
	showEpicsToggle:
		'software-view-settings.ui.view-settings-menu.views.board.show-epics-toggle-board',
	showDueDateToggle:
		'software-view-settings.ui.view-settings-menu.views.board.due-date-toggle-board',
	showLabelsToggle:
		'software-view-settings.ui.view-settings-menu.views.board.show-labels-toggle-board',
	showEstimateToggle:
		'software-view-settings.ui.view-settings-menu.views.board.show-estimate-toggle-board',
	showDaysInColumnToggle:
		'software-view-settings.ui.view-settings-menu.views.board.days-in-column-toggle-board',
	showLinkedIssueStatsToggle:
		'software-view-settings.ui.view-settings-menu.views.board.show-linked-issue-stats-toggle-board',
	showCardCoversToggle:
		'software-view-settings.ui.view-settings-menu.views.board.show-card-covers-toggle-board',
	showPriorityToggle:
		'software-view-settings.ui.view-settings-menu.views.board.show-priority-toggle-board',
	showDevelopmentToggle:
		'software-view-settings.ui.view-settings-menu.views.board.show-development-toggle-board',
	showAssigneeToggle:
		'software-view-settings.ui.view-settings-menu.views.board.show-assignee-toggle-board',
	customFieldsSection:
		'software-view-settings.ui.view-settings-menu.views.board.custom-fields-board',
} as const;

export const backlogViewTestIds = {
	showVersionPanelToggle:
		'software-view-settings.ui.view-settings-menu.views.backlog.show-version-panel-toggle',
	showEpicPanelToggle:
		'software-view-settings.ui.view-settings-menu.views.backlog.show-epic-panel-toggle',
	showQuickFiltersToggle:
		'software-view-settings.ui.view-settings-menu.views.backlog.show-quick-filters-toggle',
	cardLayoutSection: 'software-view-settings.view-settings-menu.card-layout',
	cardLayoutRadioGroup: 'software-view-settings.ui.view-settings-menu.views.backlog.card-layout',
	showCompactModeToggle:
		'software-view-settings.ui.view-settings-menu.views.backlog.show-compact-mode-toggle',
	subtaskSection: 'software-view-settings.view-settings-menu.subtasks',
	subtaskExpandAll:
		'software-view-settings.ui.view-settings-menu.views.backlog.expand-all-subtasks',
	subtaskCollapseAll:
		'software-view-settings.ui.view-settings-menu.views.backlog.collapse-all-subtasks',
	cardDetailsSection:
		'software-view-settings.ui.view-settings-menu.views.backlog.card-details-backlog',
	showIssueTypeToggle:
		'software-view-settings.ui.view-settings-menu.views.backlog.show-issue-type-toggle-backlog',
	showIssueKeyToggle:
		'software-view-settings.ui.view-settings-menu.views.backlog.show-issue-key-toggle-backlog',
	showVersionsToggle:
		'software-view-settings.ui.view-settings-menu.views.backlog.show-versions-toggle-backlog',
	showEpicsToggle:
		'software-view-settings.ui.view-settings-menu.views.backlog.show-epics-toggle-backlog',
	showDueDateToggle:
		'software-view-settings.ui.view-settings-menu.views.backlog.due-date-toggle-backlog',
	showStatusToggle:
		'software-view-settings.ui.view-settings-menu.views.backlog.show-statuses-toggle-backlog',
	showEstimateToggle:
		'software-view-settings.ui.view-settings-menu.views.backlog.show-estimate-toggle-backlog',
	showPriorityToggle:
		'software-view-settings.ui.view-settings-menu.views.backlog.show-priority-toggle-backlog',
	showAssigneeToggle:
		'software-view-settings.ui.view-settings-menu.views.backlog.show-assignee-toggle-backlog',
	customFieldsSection:
		'software-view-settings.ui.view-settings-menu.views.backlog.custom-fields-backlog',
	showBoardNameToggle:
		'software-view-settings.ui.view-settings-menu.views.backlog.show-board-name-toggle-backlog',
} as const;

export const VIEW_SETTINGS_PANEL_CLOSE_BUTTON_TEST_ID =
	'view-settings.ui.panel.header.close-button';
export const VIEW_SETTINGS_BUTTON_LG = 'view-settings-show-button.ui.view-settings-button-large';
export const VIEW_SETTINGS_BUTTON_SM = 'view-settings-show-button.ui.view-settings-button-small';
export const VIEW_SETTINGS_BUTTON_RESPONSIVE =
	'view-settings-show-button.ui.view-settings-button-responsive';

export const QUERY_ENABLED_VALUE = 'on';
export const QUERY_DISABLED_VALUE = 'off';
