// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createContainer,
	createStore,
	createActionsHook,
	createStateHook,
} from '@atlassian/react-sweet-state';
import type { State } from './types.tsx';

export const initialState: State = {
	selectedField: undefined,
	fetchKey: 0,
};

export const SelectedFieldContainer = createContainer();

const Store = createStore({
	containedBy: SelectedFieldContainer,
	initialState,
	actions: {
		update:
			(field) =>
			({ setState }) => {
				setState({
					selectedField: field,
				});
			},
		increaseKey:
			() =>
			({ setState, getState }) => {
				setState({
					fetchKey: getState().fetchKey + 1,
				});
			},
	},
});

export const useSelectedFieldActions = createActionsHook(Store);

export const useSelectedFieldState = createStateHook(Store);
