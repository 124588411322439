import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import memoize from 'lodash/memoize';
import { UNSCHEDULED_COLUMN_ID } from '@atlassian/jira-common-constants/src/column-types.tsx';
import { truncateUnscheduledWorkSwimlaneId } from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/utils.tsx';
import type { ColumnId } from '../../../model/column/column-types.tsx';
import { FULL, PARTIAL } from '../../../model/inline-create/inline-card-create-types.tsx';
import type { SwimlaneId } from '../../../model/issue/issue-types.tsx';
import type { CardTypeKey } from '../../../model/software/software-types.tsx';
import { SWIMLANE_BY_JQL } from '../../../model/swimlane/swimlane-modes.tsx';
import type { InlineCreateState } from '../../reducers/entities/inline-create/types.tsx';
import type { State } from '../../reducers/types.tsx';
import { getJQLSwimlanesData } from '../board/board-selectors.tsx';
import { getBoardConfig, getEntities, getIsCMPBoard } from '../software/software-selectors.tsx';
import { getSwimlaneMode } from '../swimlane/swimlane-mode-selectors.tsx';
import { workColumnsSelector } from '../work/work-selectors.tsx';
import { isFullOrPartialMode, isIccEnabledForBoard, isIccEnabledForColumn } from './utils.tsx';

export type ColumnIdToIccEnabledMap = Record<ColumnId, boolean>;

export const hasInlineCardCreateConfig = (state: State) =>
	!isEmpty(getInlineCardCreateConfig(state));

export const getInlineCardCreateConfig = (state: State) => getBoardConfig(state).inlineCardCreate;

// If the board has a custom filter, then requireJqlContext will be true in the ICC config
export const getIsCustomBoardWithIcc = createSelector(
	[getInlineCardCreateConfig],
	(config) => config?.requireJqlContext === true || false,
);

export const getInlineCreate = (state: State): InlineCreateState => getEntities(state).inlineCreate;

export const getInlineCreateSessionId = createSelector(
	[getInlineCreate],
	(inlineCreateState) => inlineCreateState?.sessionId,
);

export const hasInlineCreateSessionId = createSelector(
	[getInlineCreateSessionId],
	(sessionId) => sessionId !== null && sessionId !== undefined,
);

export const hasAnyPartialIcc = createSelector(
	[getInlineCardCreateConfig],
	(config) =>
		config?.mode === PARTIAL ||
		Object.values(config?.columns ?? {}).some(
			(column) =>
				column?.mode === PARTIAL ||
				Object.values(column?.issueTypes ?? {}).some((issueType) => issueType === PARTIAL),
		),
);

export const createIsIccEnabledForCardType = createSelector(
	[getInlineCardCreateConfig],
	(config) => {
		const getConfigForCardType = (columnId: ColumnId, issueTypeId: CardTypeKey): boolean =>
			isIccEnabledForBoard(config) ||
			isIccEnabledForColumn(config?.columns?.[columnId], false) ||
			isFullOrPartialMode(config?.columns?.[columnId]?.issueTypes?.[issueTypeId]);

		return memoize(getConfigForCardType, (columnId, issueTypeId) => `${columnId}-${issueTypeId}`);
	},
);

export const getColumnIdToIccEnabledMap = (state: State) => mapColumnIdToIsIccEnabled(state);

export const isIccEnabledForColumnOrSwimlane = (
	state: State,
	swimlaneId: SwimlaneId | null,
	columnId: ColumnId,
) =>
	(swimlaneId && isIccEnabledForColumnInSwimlane(state, swimlaneId, columnId)) ||
	mapColumnIdToIsIccEnabled(state)[columnId];

const mapColumnIdToIsIccEnabled = createSelector(
	[workColumnsSelector, getInlineCardCreateConfig],
	(columns, inlineCardCreateConfig) => {
		const isIccEnabledInAllColumns = isIccEnabledForBoard(inlineCardCreateConfig);
		return columns.reduce((acc: ColumnIdToIccEnabledMap, column) => {
			acc[column.id] =
				isIccEnabledInAllColumns ||
				isIccEnabledForColumn(inlineCardCreateConfig?.columns?.[column.id]);
			return acc;
		}, {});
	},
);

const isIccEnabledForColumnInSwimlane = (
	state: State,
	swimlaneId: SwimlaneId,
	columnId: ColumnId,
) => {
	const inlineCardCreate = getInlineCardCreateConfig(state);

	if (inlineCardCreate === undefined) {
		return false;
	}

	// Unscheduled work column swimlanes should have ICC enabled if their
	// regular swimlane is also allowed.
	const targetSwimlaneId = truncateUnscheduledWorkSwimlaneId(
		swimlaneId,
		columnId === UNSCHEDULED_COLUMN_ID,
	);
	const config = inlineCardCreate?.swimlanes?.[targetSwimlaneId];

	if (config === undefined) {
		return false;
	}

	if (typeof config === 'string') {
		return config === FULL;
	}

	if (typeof config === 'object') {
		return config?.[columnId]?.mode === FULL;
	}
};

export const getShouldCreateWithSwimlaneContext = createSelector(
	[getIsCMPBoard, getSwimlaneMode, getJQLSwimlanesData],
	(isCMPBoard, swimlaneMode, jqlSwimlanesData) => (swimlaneId: SwimlaneId | null | undefined) => {
		if (!swimlaneId || !isCMPBoard || swimlaneMode !== SWIMLANE_BY_JQL.id) {
			return false;
		}

		const currentSwimlaneQuery =
			jqlSwimlanesData?.find((jqlSwimlane) => jqlSwimlane.id === swimlaneId)?.query || '';

		return currentSwimlaneQuery !== '';
	},
);
