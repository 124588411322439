import React, { useCallback, useRef, useState, type SyntheticEvent } from 'react';

import Heading from '@atlaskit/heading';
import { Flex, Box, xcss } from '@atlaskit/primitives';
import { IconButton } from '@atlaskit/button/new';
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu';
import ChevronLeftIcon from '@atlaskit/icon/utility/migration/chevron-left';
import ChevronRightIcon from '@atlaskit/icon/utility/migration/chevron-right';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import ColumnClearAllMenuItem from '../item-clear-all/index.tsx';
import ColumnDeleteMenuItem from '../item-delete/index.tsx';
import ColumnLimitsMenuItem from '../item-limits/index.tsx';
import ColumnMoveMenuItem from '../item-move/index.tsx';
import { ItemOldDoneCutoffSelect } from '../item-old-done-cutoff-select/index.tsx';
import { useBoardSelector } from '../../../../../../../state/index.tsx';
import { getOldDoneIssuesCutoffForTMPKanbanBoard } from '../../../../../../../state/selectors/software/software-selectors.tsx';
import messages from './messages.tsx';

export type ColumnHeaderMenuGroupProps = {
	columnId: number;
	isDisabled?: boolean;
	onClick: () => void;
	onMenuItemClick: () => void;
};

export const ColumnHeaderMenuGroup = ({
	columnId,
	isDisabled = false,
	onClick: handleClick,
	onMenuItemClick: handleMenuItemClick,
}: ColumnHeaderMenuGroupProps) => {
	const oldDoneIssuesCutoff = useBoardSelector(getOldDoneIssuesCutoffForTMPKanbanBoard);

	const { formatMessage } = useIntl();
	const stopEventPropagation = useCallback((event: SyntheticEvent) => event.stopPropagation(), []);
	const removeDoneItemButtonRef = useRef<HTMLElement>(null);

	const iconAfterMenuGroup = <ChevronRightIcon label="" LEGACY_size="medium" spacing="spacious" />;

	const [isRemoveDoneItemOpen, setIsRemoveDoneItemOpen] = useState(false);
	const handleOpenRemoveDoneItemClick = useCallback(() => {
		setIsRemoveDoneItemOpen(true);
	}, []);

	const handleCloseRemoveDoneItemClick = useCallback(() => {
		setIsRemoveDoneItemOpen(false);
		setTimeout(() => {
			removeDoneItemButtonRef.current?.focus();
		}, 100);
	}, []);

	if (isRemoveDoneItemOpen) {
		return (
			<MenuGroup onClick={stopEventPropagation}>
				<Flex
					gap="space.0"
					justifyContent="start"
					alignItems="center"
					xcss={RemoveDoneItemHeaderStyle}
				>
					<Heading as="h3" size="xxsmall">
						<IconButton
							icon={ChevronLeftIcon}
							onClick={handleCloseRemoveDoneItemClick}
							spacing="compact"
							appearance="subtle"
							label={formatMessage(messages.removeDoneItemsBackButton)}
						/>
						<Box as="span" role="presentation" xcss={TextSpacingStyle}>
							{formatMessage(messages.headingRemoveDoneItemsAfter)}
						</Box>
					</Heading>
				</Flex>
				<Section isList>
					{oldDoneIssuesCutoff?.options.map((option) => (
						<ItemOldDoneCutoffSelect
							key={option.value}
							option={option}
							selectedValue={oldDoneIssuesCutoff.oldDoneIssuesCutoff}
							onClick={handleCloseRemoveDoneItemClick}
						/>
					))}

					<ColumnClearAllMenuItem
						columnId={columnId}
						isDisabled={isDisabled}
						onClick={handleClick}
					/>
				</Section>
			</MenuGroup>
		);
	}

	const selectedLabel = oldDoneIssuesCutoff?.options.find(
		(option) => option.value === oldDoneIssuesCutoff.oldDoneIssuesCutoff,
	)?.label;

	return (
		<MenuGroup onClick={stopEventPropagation} role="menu">
			<Section>
				{oldDoneIssuesCutoff && !isDisabled && (
					<ButtonItem
						iconAfter={iconAfterMenuGroup}
						onClick={handleOpenRemoveDoneItemClick}
						role="menuitem"
						ref={removeDoneItemButtonRef}
						testId="software-board.board-container.board.column.header.menu.menu-group.update-old-done-issues-cutoff"
					>
						{formatMessage(messages.removeDoneItemsAfter, {
							days: selectedLabel,
						})}
					</ButtonItem>
				)}
				{fg('column_reordering_using_keyboard') && (
					<>
						<ColumnMoveMenuItem
							columnId={columnId}
							direction="left"
							isDisabled={isDisabled}
							onClick={handleClick}
						/>
						<ColumnMoveMenuItem
							columnId={columnId}
							direction="right"
							isDisabled={isDisabled}
							onClick={handleClick}
						/>
					</>
				)}
				<ColumnLimitsMenuItem
					columnId={columnId}
					isDisabled={isDisabled}
					onClick={handleMenuItemClick}
				/>
				<ColumnDeleteMenuItem
					columnId={columnId}
					isDisabled={isDisabled}
					onClick={handleMenuItemClick}
				/>
			</Section>
		</MenuGroup>
	);
};

const RemoveDoneItemHeaderStyle = xcss({
	paddingBlock: 'space.150',
	paddingLeft: 'space.200',
	paddingRight: 'space.600',
});

const TextSpacingStyle = xcss({
	marginLeft: 'space.100',
});
