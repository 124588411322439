import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import CardIcon from '../icon/main.tsx';
import { FLAG_ICON } from '../icon/types.tsx';
import messages from './messages.tsx';

export const CardFlag = () => {
	const { formatMessage } = useIntl();
	const message = formatMessage(messages.done);

	return (
		<Container>
			<Tooltip content={message}>
				<CardIcon type={FLAG_ICON} label={message} primaryColor={token('color.icon.danger')} />
			</Tooltip>
		</Container>
	);
};

export default CardFlag;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: 1,
});
