import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	undoMessageTitle: {
		id: 'software.main.ops.sprint.remove-from-sprint.undo-message-title',
		defaultMessage: '{issueKey} removed from sprint',
		description: 'Shown on an undo flag when an issue is removed from a sprint',
	},
	undoMessageDescription: {
		id: 'software.main.ops.sprint.remove-from-sprint.undo-message-description',
		defaultMessage: 'Sprint scope will be affected by this action',
		description:
			'Shown on an undo flag to notify the user the sprint scope is being changed by an issue being removed from a sprint',
	},
	undoMessageViewIssue: {
		id: 'software.main.ops.sprint.remove-from-sprint.undo-message-view-issue',
		defaultMessage: 'View issue',
		description:
			'Shown on the remove from sprint undo flag to allow the user to view the issue on a new tab',
	},
	undoMessageViewIssueIssueTermRefresh: {
		id: 'software.main.ops.sprint.remove-from-sprint.undo-message-view-issue-issue-term-refresh',
		defaultMessage: 'View',
		description:
			'Shown on the remove from sprint undo flag to allow the user to view the issue on a new tab',
	},
});
