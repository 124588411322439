import { getIn, setIn } from 'icepick';
import { createSelector } from 'reselect';
import isNil from 'lodash/isNil';
import keyBy from 'lodash/keyBy';
import {
	categoryIdForStatusCategory,
	UNDEFINED,
	type StatusCategory,
} from '@atlassian/jira-common-constants/src/status-categories.tsx';
import type { IssueLink } from '@atlassian/jira-issue-links-common/src/types.tsx';
import {
	INWARD_LINK_DIRECTION,
	OUTWARD_LINK_DIRECTION,
	type IssueLinkDirection,
} from '@atlassian/jira-issue-shared-types/src/common/types/linked-issue-type.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import type { Issue } from '../../../model/issue/issue-types.tsx';
import type { IssueLinkTypesState } from '../../reducers/entities/issue-link-types/types.tsx';
import type { IssuesState } from '../../reducers/entities/issues/types.tsx';
import type { StatusesState } from '../../reducers/entities/statuses/types.tsx';
import type { State } from '../../reducers/types.tsx';
import { getStatuses, getIssues, getIssueLinkTypes } from '../software/software-selectors.tsx';

export const prepareIssueLink = (
	issue: Issue,
	issueLinkId: string,
	{ name, category }: { name: string; category?: StatusCategory },
	offtrackLabel: string | undefined,
): IssueLink => ({
	key: issue.key,
	issueTypeName: issue.typeName,
	issueTypeIconUrl: issue.typeUrl,
	text: issue.summary,
	statusCategoryId: `${categoryIdForStatusCategory(category)}`,
	statusName: name,
	issueLinkId,
	offtrackLabel,
});

/**
 * On the Increment Planning board, we need to differentiate between the issue links where the
 * destination issue is included in the issue sources for the board (internal), and those where
 * the destination issue is not included in the issue sources for the board (external).
 *
 * We currently only show full details for internal issue links, and a count of the external issue links.
 * This will change in the future, but for now, we need to differentiate between the two.
 */
export const getIssueLinksForDependenciesFlyout = createSelector(
	[getStatuses, getIssueLinkTypes, getIssues],
	(statuses: StatusesState, issueLinkTypes: IssueLinkTypesState, issues: IssuesState) =>
		(issueId: IssueId, offtrackLabel?: string) => {
			// todo - currently showing basic count for external issues (not in the board and not in the plan),
			// need to decided to how to handle the external issues https://hello.atlassian.net/wiki/spaces/AG/pages/3299676769/2024-01-30+IP+Weekly+Sync+Meeting+Notes
			const issue: Issue = issues[issueId];
			const issueLinks = issue?.issueLinks || [];
			const issueLinkTypesById = keyBy(issueLinkTypes, 'id');
			const internalAndExternalIssueLinks = issueLinks.reduce(
				(
					acc: {
						internal: {
							[relationName: string]: IssueLink[];
						};
						external: number;
					},
					issueLink,
				) => {
					const { id, sourceId, destinationId, linkTypeId, isOfftrack } = issueLink;
					// filter out the external issue
					if (isNil(issues[sourceId]) || isNil(issues[destinationId])) {
						acc.external += 1;
						return acc;
					}

					const direction = `${destinationId}` === `${issueId}` ? 'inward' : 'outward';

					const resultIssue = direction === 'inward' ? issues[sourceId] : issues[destinationId];
					const resultIssueStatus =
						isNil(resultIssue.statusId) || isNil(statuses[resultIssue.statusId])
							? { name: '', category: UNDEFINED }
							: statuses[resultIssue.statusId];

					const resultIssueLink = prepareIssueLink(
						resultIssue,
						id,
						resultIssueStatus,
						isOfftrack ? offtrackLabel : undefined,
					);

					const group = issueLinkTypesById[`${linkTypeId}`][direction];

					if (isNil(acc.internal[group])) {
						acc.internal[group] = [resultIssueLink];
					} else {
						acc.internal[group].push(resultIssueLink);
					}
					return acc;
				},
				{ internal: {}, external: 0 },
			);
			return internalAndExternalIssueLinks;
		},
);

/**
 * A map of issue ids to issue link types and directions to check for duplicate in the issue link modal
 */
export const getIssueLinksForDependenciesModal = createSelector(
	[getIssueLinkTypes, getIssues],
	(issueLinkTypes: IssueLinkTypesState, issues: IssuesState) =>
		(issueId: IssueId): Record<number, Record<IssueLinkDirection, String[]>> => {
			const issue: Issue = issues[issueId];
			const issueLinks = issue?.issueLinks || [];
			const issueLinkTypesById = keyBy(issueLinkTypes, 'id');

			const issueLinksByTypeAndDirection = issueLinks
				.filter(
					({ sourceId, destinationId }) =>
						!isNil(issues[sourceId]) && !isNil(issues[destinationId]),
				)
				.reduce((acc, issueLink) => {
					const { sourceId, destinationId, linkTypeId } = issueLink;
					const { isOutward } = issueLinkTypesById[linkTypeId] ?? {};
					const direction =
						(isOutward && sourceId === issueId) || (!isOutward && destinationId === issueId)
							? OUTWARD_LINK_DIRECTION
							: INWARD_LINK_DIRECTION;
					const dependencyId = sourceId === issueId ? destinationId : sourceId;
					const updatedAcc = setIn(
						acc,
						[linkTypeId, direction],
						[...(getIn(acc, [linkTypeId, direction]) ?? []), dependencyId],
					);
					return updatedAcc;
				}, {});
			return issueLinksByTypeAndDirection;
		},
);

export const getShowOfftrackDependencyLines = (state: State) =>
	state.ui.board.displayOptions.showOfftrackDependencyLines;

export const getIssueIdsToShowDependencies = (state: State) =>
	state.ui.board.displayOptions.issueIdsToShowDependencies;

export const isDependencyVisualisationVisible = (state: State) => {
	return getShowOfftrackDependencyLines(state) || getIssueIdsToShowDependencies(state).length > 0;
};
