import React, { Component, type ComponentPropsWithoutRef, type ReactElement } from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';
import Heading from '@atlaskit/heading';
import WarningIcon from '@atlaskit/icon/core/warning';
import WarningIconOld from '@atlaskit/icon/glyph/warning';
import AkInlineDialog from '@atlaskit/inline-dialog';
import type { InlineEditProps } from '@atlaskit/inline-edit';
import { Box, xcss } from '@atlaskit/primitives';
import Textfield from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types.tsx';
import legacyMessages from '@atlassian/jira-common-components-inline-edit/src/messages.tsx';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type {
	IntlShapeV2 as IntlShape,
	MessageDescriptorV2 as MessageDescriptor,
} from '@atlassian/jira-intl/src/v2/types.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { layout } from '../../../constants/styles/index.tsx';
import type { ColumnId, ValidateColumnCallback } from '../../../types.tsx';
import type ColumnTitleLozenges from './column-title/lozanges/index.tsx';
import ColumnTitle from './column-title/main.tsx';
import DoneColumnTick from './done-column-tick/index.tsx';
import InlineEdit from './inline-edit/index.tsx';

const CANCEL_BUTTON_CLICKED = 'button-clicked';
const CANCEL_CLICKED_OUT = 'clicked-out';
type CANCEL_TYPE = 'button-clicked' | 'clicked-out';

const isBlank = (value: string): boolean => value.length === 0;
const hasNotChanged = (current: string, previous: string): boolean => current === previous;
const isNewColumn = (columnId: ColumnId): boolean => Number(columnId) < 0;
const getCleanColumnName = (name: string): string => name.replace(/\s{2,}/g, ' ').trim();

export const columnNameMaxLength = 30;
export const COLUMN_VALIDATION_DELAY = 200;

export type Props = {
	isEditing: boolean;
	isDone: boolean;
	isDisabled: boolean;
	columnId: ColumnId;
	text: string;
	lozenges?: ReactElement<ComponentPropsWithoutRef<typeof ColumnTitleLozenges>> | null;
	visibleCount: number;
	showTotalCount: boolean;
	totalCount: number;
	validateColumn: ValidateColumnCallback;
	onCancel: (columnId: ColumnId, type: CANCEL_TYPE) => void;
	onConfirm: (columnId: ColumnId, value: string, analyticsEvent: AnalyticsEvent) => void;
	onEditChange: (isEditingTitle: boolean) => void;
	intl: IntlShape;
	isCMPBoard: boolean;
	shouldRemoveSpaceForMenu: boolean;
};

type ComponentState = {
	isEditing: boolean;
	isValid: boolean;
	errorMessage:
		| {
				title: string | MessageDescriptor;
				description: string | MessageDescriptor;
		  }
		| null
		| undefined;
	hasInteracted: boolean;
};

// eslint-disable-next-line jira/react/no-class-components
export class ColumnEditableTitle extends Component<Props, ComponentState> {
	static defaultProps = {
		isDisabled: false,
		isDone: false,
		isEditing: false,
		totalCount: 0,
		visibleCount: 0,
		showTotalCount: false,
		validateColumn: () => ({
			isValid: true,
			message: null,
		}),
		onEditChange: noop,
		onCancel: noop,
		onConfirm: noop,
		isCMPBoard: false,
		shouldRemoveSpaceForMenu: false,
	};

	state: ComponentState = {
		isEditing: this.props.isEditing || false,
		isValid: true,
		errorMessage: null,
		hasInteracted: false,
	};

	container: HTMLElement | null | undefined;

	onTitleOuterClick = (event: MouseEvent) => {
		if (!(event.target instanceof Node) || !this.container?.contains(event.target)) {
			// cancel inline edit on outer click
			this.handleCancel(CANCEL_CLICKED_OUT);
		}
	};

	onEditChange = (isEditing: boolean) => {
		this.props.onEditChange(isEditing);
		this.setState({
			isEditing,
		});
	};

	setContainer = (container?: HTMLElement | null) => {
		this.container = container;
	};

	validateColumn = (name: string) => {
		const { isValid, message = null } = this.props.validateColumn(
			this.props.columnId,
			getCleanColumnName(name),
		);

		this.setState({
			isValid,
			errorMessage: message,
		});
	};

	delayValidateColumn = debounce((name: string) => {
		this.validateColumn(name);
	}, COLUMN_VALIDATION_DELAY);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	formatMessage = (message: string | MessageDescriptor, values?: Record<any, any>) => {
		if (typeof message === 'string') {
			return message;
		}

		const result = this.props.intl.formatMessage(message, values);
		return result;
	};

	handleEditChange = () => {
		this.onEditChange(true);
	};

	handleConfirm = (value: string, analyticsEvent: AnalyticsEvent) => {
		const { columnId, text } = this.props;
		const newValue = getCleanColumnName(value);

		this.delayValidateColumn.cancel();

		const { isValid } = this.props.validateColumn(columnId, newValue);
		if (!isValid) {
			return;
		}

		if (isBlank(newValue) || hasNotChanged(newValue, text)) {
			this.handleCancel(CANCEL_CLICKED_OUT);
		} else {
			fireUIAnalytics(analyticsEvent, 'column');
			this.props.onConfirm(columnId, newValue, analyticsEvent);

			this.onEditChange(false);
			this.setState({
				isEditing: false,
			});
		}
	};

	handleCancel = (type: CANCEL_TYPE) => {
		const { columnId } = this.props;

		if (!isNewColumn(columnId)) {
			this.onEditChange(false);
		}

		this.setState({
			isValid: true,
			errorMessage: null,
		});

		this.props.onCancel(columnId, type);
	};

	renderReadOnly = () => <Box xcss={readOnlyStyles}>{this.renderReadView()}</Box>;

	renderReadView = () => {
		const content = (
			<>
				<ColumnTitle
					text={this.props.text}
					isEditing={this.state.isEditing}
					showTotalCount={this.props.showTotalCount}
					visibleCount={this.props.visibleCount}
					totalCount={this.props.totalCount}
					isDisabled={this.props.isDisabled}
					isCMPBoard={this.props.isCMPBoard}
				/>
				{this.props.lozenges}
				{this.props.isDone && !this.props.isCMPBoard ? <DoneColumnTick /> : null}
			</>
		);

		return <Reading>{content}</Reading>;
	};

	renderEditView: InlineEditProps<string>['editView'] = ({ errorMessage, ...fieldProps }) => {
		const { isValid } = this.state;
		// we want to exclude 'aria-labelledby' from the restProps, but we don't need its value so am using an _.
		// We can't use just 'aria-labelledby' without an assignment as it results in a syntax error.
		const { 'aria-labelledby': _, ...restProps } = fieldProps;

		return (
			<AkInlineDialog
				isOpen={!isValid}
				content={this.renderValidation(fieldProps.value)}
				placement="bottom"
			>
				<Textfield
					{...restProps}
					testId="platform-board-kit.common.ui.column-header.editable-title.textfield"
					elemAfterInput={
						!isValid && (
							<WarningIconWrapper>
								<WarningIcon
									label="error"
									color={token('color.icon.warning')}
									LEGACY_fallbackIcon={WarningIconOld}
									LEGACY_primaryColor={token('color.icon.warning')}
								/>
							</WarningIconWrapper>
						)
					}
					maxLength={columnNameMaxLength}
					autoFocus
					autoComplete="off"
					aria-label={this.props.intl.formatMessage(legacyMessages.columnTitleAriaLabel)}
				/>
			</AkInlineDialog>
		);
	};

	formatErrorMessage = (
		message: string | MessageDescriptor,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		values?: Record<any, any>,
	): string => {
		if (typeof message === 'string') {
			return message;
		}
		return this.props.intl.formatMessage(message, values);
	};

	renderValidation = (name: string) => {
		const { isValid, errorMessage } = this.state;

		if (isValid || !errorMessage) {
			return null;
		}

		return (
			<>
				<InlineDialogHeader>
					{this.formatErrorMessage(errorMessage.title, { name: name.toUpperCase() })}
				</InlineDialogHeader>
				<Box xcss={inlineDialogBodyStyles}>{this.formatErrorMessage(errorMessage.description)}</Box>
			</>
		);
	};

	renderInlineEditPart = () => {
		const { isEditing, isValid } = this.state;
		const { isDisabled, intl, text, columnId } = this.props;

		// the readonly view will be rendered if the column is:
		// 1. disabled
		// 2. a new column (id < 0) and is no longer being edited
		// 3. an existing column and has not been interacted with
		const shouldRenderReadOnly =
			isDisabled ||
			(!isNewColumn(columnId) && !this.state.hasInteracted) ||
			(isNewColumn(columnId) && !isEditing);

		return shouldRenderReadOnly ? (
			this.renderReadOnly()
		) : (
			<InlineEdit
				defaultValue={text}
				isEditing={isEditing}
				editView={this.renderEditView}
				validate={this.delayValidateColumn}
				readView={this.renderReadView}
				onConfirm={this.handleConfirm}
				onCancel={() => this.handleCancel(CANCEL_BUTTON_CLICKED)}
				onEdit={this.handleEditChange}
				onOuterClick={this.onTitleOuterClick}
				hideActionButtons={!isValid}
				readViewFitContainerWidth
				editButtonLabel={text}
				confirmButtonLabel={intl.formatMessage(legacyMessages.confirmButtonLabel, {
					fieldName: '',
				})}
				cancelButtonLabel={intl.formatMessage(legacyMessages.cancelButtonLabel, {
					fieldName: '',
				})}
			/>
		);
	};

	render() {
		const { isEditing } = this.state;
		const { isDisabled } = this.props;

		let interactionProps = {};

		if (!fg('program_board_a11y_fixes_part_2')) {
			interactionProps = {
				onFocus: () => this.setState({ hasInteracted: true }),
				onMouseEnter: () => this.setState({ hasInteracted: true }),
				tabIndex: this.state.hasInteracted ? -1 : 0,
			};
		} else {
			interactionProps = !isDisabled
				? {
						onFocus: () => this.setState({ hasInteracted: true }),
						onMouseEnter: () => this.setState({ hasInteracted: true }),
						tabIndex: this.state.hasInteracted ? -1 : 0,
					}
				: {};
		}

		return (
			<Box xcss={headerRowStyles} ref={this.setContainer}>
				{isVisualRefreshEnabled() && fg('visual-refresh_drop_3') ? (
					<Box
						testId="platform-board-kit.common.ui.column-header.editable-title.inline-edit-part-wrapper"
						xcss={[
							isEditing
								? inlineEditPartWrapperEditingStyles
								: inlineEditPartWrapperNotEditingStyles,
						]}
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...interactionProps}
					>
						{this.renderInlineEditPart()}
					</Box>
				) : (
					<InlineEditPartWrapper
						data-testid="platform-board-kit.common.ui.column-header.editable-title.inline-edit-part-wrapper"
						isEditing={isEditing}
						shouldRemoveSpaceForMenu={this.props.shouldRemoveSpaceForMenu}
						{...interactionProps}
					>
						{this.renderInlineEditPart()}
					</InlineEditPartWrapper>
				)}
			</Box>
		);
	}
}

// @ts-expect-error - Argument of type 'typeof ColumnEditableTitle' is not assignable to parameter of type 'ComponentType<WithIntlProvided<Props>> & typeof ColumnEditableTitle'.
export default injectIntl(ColumnEditableTitle);

const InlineDialogHeader = (props: ComponentPropsWithoutRef<typeof InlineDialogHeaderInternal>) => (
	<Heading size="xsmall">
		<InlineDialogHeaderInternal {...props} />
	</Heading>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InlineDialogHeaderInternalOld = styled2.span({
	font: token('font.body'),
	marginTop: token('space.0'),
	marginBottom: token('space.150'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InlineDialogHeaderInternalNew = styled2.span({
	font: token('font.body'),
	marginTop: token('space.0'),
	marginBottom: token('space.150'),
});

const InlineDialogHeaderInternal = styledComponentWithCondition(
	() => true,
	InlineDialogHeaderInternalNew,
	InlineDialogHeaderInternalOld,
);

const inlineDialogBodyStyles = xcss({
	font: 'font.body',
	color: 'color.text',
});

const headerRowStyles = xcss({
	paddingLeft: 'space.075',
	paddingRight: 'space.075',
	maxWidth: '100%',
	boxSizing: 'border-box',
});

// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InlineEditPartWrapperControl = styled.div<{
	isEditing: boolean;
	shouldRemoveSpaceForMenu: boolean;
}>`
	/* leaving space for meatball menu for TMP, removing space for CMP */
	${({ shouldRemoveSpaceForMenu }) =>
		shouldRemoveSpaceForMenu
			? 'max-width: auto;'
			: `max-width: calc(100% - ${32 + gridSize + layout.smallGutter}px);`};
	/* when title edited make textbox expanded to full length */
	${({ isEditing }) =>
		isEditing
			? `
        flex: 1;
        max-width: 100%;
        `
			: `
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    `};
`;

const inlineEditPartWrapperEditingStyles = xcss({
	flex: 1,
	maxWidth: '100%',
});

const inlineEditPartWrapperNotEditingStyles = xcss({
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InlineEditPartWrapperExperiment = styled2.div<{
	isEditing: boolean;
	shouldRemoveSpaceForMenu: boolean;
}>(
	{
		// leaving space for meatball menu for TMP, removing space for CMP
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		maxWidth: ({ shouldRemoveSpaceForMenu }) =>
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			shouldRemoveSpaceForMenu ? 'auto' : `calc(100% - ${32 + gridSize + layout.smallGutter}px)`,
		// when title edited make textbox expanded to full length
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isEditing }) =>
		isEditing ? inlineEditPartWrapperEditingStyles : inlineEditPartWrapperNotEditingStyles,
);

const InlineEditPartWrapper = styledComponentWithCondition(
	() => fg('jsw_compiled_migration_tanuki'),
	InlineEditPartWrapperExperiment,
	InlineEditPartWrapperControl,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Reading = styled2.div({
	minWidth: 0,
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WarningIconWrapper = styled2.div({
	paddingRight: token('space.075'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: '100%',
});

const readOnlyStyles = xcss({
	margin: 'space.025',
});
