import { Observable } from 'rxjs/Observable';
import { async } from 'rxjs/scheduler/async';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/concat';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/throttleTime';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { CUSTOM_FILTER, JQL } from '../../../model/filter/filter-types.tsx';
import { SWIMLANE_BY_JQL } from '../../../model/swimlane/swimlane-modes.tsx';
import { fetchFilteredCardIds } from '../../../services/filtered-cards/index.tsx';
import { makeServiceContext } from '../../../services/service-context.tsx';
import { cardAutoScrollRequestOnFilter } from '../../../state/actions/card/card-auto-scroll/index.tsx';
import {
	filteredCardsSuccess,
	filteredCardsFailed,
	clearFilteredCards,
	type Action as FilteredCardsAction,
} from '../../../state/actions/card/filtered-cards/index.tsx';
import {
	SET_ALL_FILTERS,
	setAllFilters,
	type SetAllFiltersAction,
	type Action as FiltersAction,
	customFilterForceRefreshStart,
} from '../../../state/actions/filter/index.tsx';
import { swimlaneJqlSetDataRequest } from '../../../state/actions/swimlane/index.tsx';
import { boardOrderedIssueIdsSelector } from '../../../state/selectors/issue/board-issue-selectors.tsx';
import { getSwimlaneMode } from '../../../state/selectors/swimlane/swimlane-mode-selectors.tsx';
import type { ActionsObservable, MiddlewareAPI, Action } from '../../../state/types.tsx';
import { getCustomFiltersDebounceTime } from '../utils.tsx';

const handleFilterCards = (
	action: SetAllFiltersAction,
	store: MiddlewareAPI,
): Observable<Action> => {
	const state = store.getState();
	const ctx = makeServiceContext(state);
	const swimlaneMode = getSwimlaneMode(state);

	// If you have JQL swimlanes, you ALWAYS fetch the data even when clearing the filters
	const mustForciblyRefreshSwimlanesOnClear = ctx.isCMPBoard && swimlaneMode === SWIMLANE_BY_JQL.id;

	if (
		!mustForciblyRefreshSwimlanesOnClear &&
		action.meta.filterType !== CUSTOM_FILTER &&
		action.meta.filterType !== JQL
	) {
		return Observable.of(cardAutoScrollRequestOnFilter());
	}

	const customFilterIds = action.payload?.filters[CUSTOM_FILTER] || [];
	const hasCustomFilters = customFilterIds && customFilterIds.length;
	const jql = expVal('filter_refinement_in_tmp_board', 'isEnabled', false)
		? action.payload?.filters[JQL] ?? null
		: null;
	const hasJql = expVal('filter_refinement_in_tmp_board', 'isEnabled', false)
		? jql && jql.length > 0
		: false;

	if (!mustForciblyRefreshSwimlanesOnClear && !hasCustomFilters && !hasJql) {
		return Observable.from([clearFilteredCards(), cardAutoScrollRequestOnFilter()]);
	}

	const issueIds = boardOrderedIssueIdsSelector(state);
	const { analyticsEvent } = action.meta;

	const setLoading$: Observable<Action> = mustForciblyRefreshSwimlanesOnClear
		? Observable.of(customFilterForceRefreshStart())
		: Observable.empty<Action>();

	const request$: Observable<Action> = fetchFilteredCardIds(ctx, { issueIds, customFilterIds, jql })
		.mergeMap(({ cardIds, jqlSwimlanes }) => {
			// Always fire the action for success or the Loading animation will always play
			const actions: Action[] = [
				filteredCardsSuccess(
					cardIds.map((id) => `${id}`), // Always force string
					customFilterIds.length,
					issueIds.length,
					analyticsEvent,
				),
			];

			if (!mustForciblyRefreshSwimlanesOnClear) {
				actions.push(cardAutoScrollRequestOnFilter());
			}

			if (ctx.isCMPBoard) {
				actions.push(swimlaneJqlSetDataRequest(jqlSwimlanes ?? []));
			}

			return Observable.from(actions);
		})
		.catch((error) => {
			log.safeErrorWithoutCustomerData(
				'board.filtered-cards.failure',
				'Fail to filter cards with custom filters on board',
				error,
			);

			return Observable.of<FilteredCardsAction | FiltersAction>(
				filteredCardsFailed(error, analyticsEvent),
				setAllFilters({}),
			);
		});

	return Observable.concat(setLoading$, request$);
};

export const filteredCardsRequestEpic = (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$
		.ofType(SET_ALL_FILTERS)
		.throttleTime(getCustomFiltersDebounceTime(), async, {
			leading: true,
			trailing: true,
		})
		.switchMap((action: SetAllFiltersAction) => handleFilterCards(action, store));
