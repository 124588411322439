import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorMessage: {
		id: 'filter-refinement.filter-popup.error.error-message',
		defaultMessage: 'Something went wrong. <link>Refresh the page</link> and try again.',
		description:
			"This text is displayed in a section message when we are unable to load the user's search criteria. The <link> and </link> tags enclose a link that will trigger a page refresh, and these tags should be kept in the translated message surrounding the corresponding translated text.",
	},
});
