// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentProps } from 'react';
import { EngagementSpotlight } from '@atlassiansox/engagekit-ts';
import { useRegisterNudgeTarget } from '@atlassiansox/nudge-tooltip';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { fg } from '@atlassian/jira-feature-gating';
import BoardKitColumnHeader from '@atlassian/jira-platform-board-kit/src/common/ui/column-header/main.tsx';
import {
	TailorBoardColumnsAsync,
	TailorBoardColumnsAsyncChildless,
} from '@atlassian/jira-software-onboarding-nudges--next/src/ui/jwm-board-view/async.tsx';
import { ConditionalNudgeWrapper } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/conditional-nudge-wrapper/index.tsx';
import { isTailoredOnboardingEnabled } from '@atlassian/jira-tailored-onboarding/src/controllers/index.tsx';
import { TAILOR_BOARD_COLUMN_NUDGE_ID } from '@atlassian/jira-onboarding-core/src/constants.tsx';
import { Capability } from '../../../../../common/capability/index.tsx';
import { useBoardSelector } from '../../../../../state/index.tsx';
import {
	getIsCMPBoard,
	getIsIncrementPlanningBoard,
} from '../../../../../state/selectors/software/software-selectors.tsx';
import { useCapability } from '../../../../../state/state-hooks/capabilities/index.tsx';
import { useHasGroupingStyle } from '../../../../../common/hooks/use-has-grouping-style/useHasGroupingStyle.tsx';

type BoardKitColumnHeaderProps = JSX.LibraryManagedAttributes<
	typeof BoardKitColumnHeader,
	ComponentProps<typeof BoardKitColumnHeader>
>;

type Props = {
	isDone: boolean;
	isLastColumn: boolean;
} & BoardKitColumnHeaderProps;

const ColumnHeaderView = (props: Props) => {
	const { isDone, isLastColumn, ...otherProps } = props;
	const isCMPBoard = useBoardSelector(getIsCMPBoard);
	const isIncrementPlanningBoard = useBoardSelector(getIsIncrementPlanningBoard);
	const isFlexibleColumns = useCapability(Capability.FLEXIBLE_BOARD_COLUMNS);
	const shouldRemoveSpaceForMenu = isCMPBoard || isIncrementPlanningBoard;
	const hasGrouping = useHasGroupingStyle();

	const { ref: tailorBoardColumnsNudgeRef } = useRegisterNudgeTarget<HTMLDivElement>(
		TAILOR_BOARD_COLUMN_NUDGE_ID,
		fg('board_nudge_bugfix') && isLastColumn,
	);

	const renderBoardKitColumnHeader = (
		columnHeaderWrapperRef?: React.MutableRefObject<HTMLDivElement | null>,
	) => (
		<BoardKitColumnHeader
			columnHeaderWrapperRef={columnHeaderWrapperRef}
			isDone={isDone}
			{...otherProps}
			isCMPBoard={isCMPBoard}
			shouldRemoveSpaceForMenu={shouldRemoveSpaceForMenu}
			isFlexibleColumns={isFlexibleColumns}
			isMatrixLayout={hasGrouping}
		/>
	);

	if (isLastColumn) {
		if (fg('board_nudge_bugfix')) {
			return (
				<>
					<SpotlightTarget name="DoneNotLastColumnMessage">
						{renderBoardKitColumnHeader(tailorBoardColumnsNudgeRef)}
					</SpotlightTarget>
					<EngagementSpotlight engagementId="DoneNotLastColumnMessage" />
					<TailorBoardColumnsAsyncChildless />
				</>
			);
		}
		return (
			<>
				<ConditionalNudgeWrapper
					conditionsToApplyWrapper={!isCMPBoard && isTailoredOnboardingEnabled()}
					Wrapper={TailorBoardColumnsAsync}
				>
					<SpotlightTarget name="DoneNotLastColumnMessage">
						{renderBoardKitColumnHeader()}
					</SpotlightTarget>
				</ConditionalNudgeWrapper>
				<EngagementSpotlight engagementId="DoneNotLastColumnMessage" />
			</>
		);
	}

	if (isDone) {
		return (
			<>
				<SpotlightTarget name="DoneColumnMessage">{renderBoardKitColumnHeader()}</SpotlightTarget>
				<EngagementSpotlight engagementId="DoneColumnMessage" />
			</>
		);
	}

	return renderBoardKitColumnHeader();
};

export default ColumnHeaderView;
