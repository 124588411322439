import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';

type ShortcutLabelProps = {
	text: string;
};

export const ShortcutLabel = ({ text }: ShortcutLabelProps) => (
	// For A11y add `aria-keyshortcuts={yourShortcut}` to the element you are adding this label to
	// Added `aria-hidden` for the screen readers to ignore the label element
	<Box aria-hidden xcss={ShortcutLabelComponentStyles}>
		{text}
	</Box>
);

const ShortcutLabelComponentStyles = xcss({
	display: 'inline-block',
	backgroundColor: 'color.background.neutral',
	borderRadius: 'border.radius.100',
	font: 'font.body.UNSAFE_small',
	paddingTop: 'space.050',
	paddingBottom: 'space.050',
	paddingLeft: 'space.100',
	paddingRight: 'space.100',
});
