import React, { useCallback, type MouseEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { AnalyticsEventToProps } from '@atlassian/jira-product-analytics-bridge';
import { getOrderedColumnIds } from '../../../../../../../state/selectors/software/software-selectors.tsx';
import { workColumnsSelector } from '../../../../../../../state/selectors/work/work-selectors.tsx';

import { columnRankRequest } from '../../../../../../../state/actions/column/rank/index.tsx';
import { useIsIncrementPlanningBoard } from '../../../../../../../state/state-hooks/capabilities/index.tsx';
import { useBoardDispatch, useBoardSelector } from '../../../../../../../state/index.tsx';
import ItemMoveView from './view.tsx';
import type { ItemMoveProps } from './types.tsx';

const ItemMoveWithAnalytics = AnalyticsEventToProps('dropdownMenuItem', {
	onClick: 'clicked',
})(ItemMoveView);

const ItemMove = (props: ItemMoveProps) => {
	const { columnId, direction, isDisabled: _isDisabled, onClick: _onClick } = props;

	const isIPBoard = useIsIncrementPlanningBoard();
	const orderedColumnIds = useBoardSelector(getOrderedColumnIds);
	const sourceColumnIndex = orderedColumnIds?.indexOf(columnId);
	const numColumnsOnBoard = useBoardSelector(workColumnsSelector).length;
	const dispatch = useBoardDispatch();

	const isOutOfBound = () => {
		if (direction === 'left') {
			return sourceColumnIndex === 0;
		}
		if (direction === 'right') {
			return sourceColumnIndex === numColumnsOnBoard - 1;
		}
		return false;
	};
	const isDisabled = _isDisabled || isOutOfBound() || !!isIPBoard;

	const destinationColumnIndex =
		direction === 'left' ? sourceColumnIndex - 1 : sourceColumnIndex + 1;

	const onClick = useCallback(
		(event: MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
			if (!isIPBoard) {
				dispatch(
					columnRankRequest(
						Number(columnId),
						orderedColumnIds[destinationColumnIndex],
						sourceColumnIndex,
						analyticsEvent,
					),
				);
			}

			_onClick(event, analyticsEvent);
		},
		[
			columnId,
			destinationColumnIndex,
			dispatch,
			isIPBoard,
			_onClick,
			orderedColumnIds,
			sourceColumnIndex,
		],
	);

	return <ItemMoveWithAnalytics isDisabled={isDisabled} direction={direction} onClick={onClick} />;
};

export default ItemMove;
