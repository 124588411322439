import { setIn } from 'icepick';
import { FILTERS_SET, SET_ALL_FILTERS, FILTERS_JQL_SET } from '../../../actions/filter/index.tsx';
import type { Action } from '../../../types.tsx';
import type { FilterState } from './types.tsx';

const initialState = {
	values: {},
} as const;

export const workFiltersReducer = (
	state: FilterState = initialState,
	action: Action,
): FilterState => {
	if (action.type === FILTERS_JQL_SET) {
		return setIn(state, ['values'], {
			...state.values,
			...action.payload.filters,
		});
	}
	if (action.type === FILTERS_SET) {
		return setIn(state, ['values', action.payload.filterType], action.payload.values);
	}
	if (action.type === SET_ALL_FILTERS) {
		return setIn(state, ['values'], action.payload.filters);
	}

	return state;
};
