import React, { type ChangeEvent, type ComponentType, useCallback } from 'react';
import Icon, { type CustomGlyphProps } from '@atlaskit/icon';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import { Radio } from '@atlaskit/radio';
import type { OptionPropType } from '@atlaskit/radio/types';
import { token } from '@atlaskit/tokens';
import { ViewAs } from '../../types/constant.tsx';

type Props = {
	onSelect: (value: string) => void;
	testId?: string;
	options: (OptionPropType & { icon?: ComponentType<CustomGlyphProps> })[];
	value: string;
	viewAs?: (typeof ViewAs)[keyof typeof ViewAs];
	ariaLabel: string;
};

export const ViewSettingsRadioGroup = ({
	options,
	value,
	onSelect,
	testId,
	viewAs = ViewAs.POPUP,
	ariaLabel,
}: Props) => {
	const onChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			onSelect(e.target.value);
		},
		[onSelect],
	);

	return (
		<Box
			xcss={viewAs === ViewAs.PANEL ? panelContainerStyles : popupContainerStyles}
			testId={testId}
			role="radiogroup"
			aria-label={ariaLabel}
		>
			{options.map((option) => (
				<Flex xcss={radioOptionContainerStyles} key={option.value}>
					{option.value && (
						<Radio
							isChecked={option.value === value}
							onChange={onChange}
							name={option.name}
							label={option.label}
							value={option.value}
						/>
					)}
					{option.icon && (
						<Box xcss={iconContainerStyles}>
							<Icon glyph={option.icon} label="" primaryColor={token('color.icon.subtle')} />
						</Box>
					)}
				</Flex>
			))}
		</Box>
	);
};

const popupContainerStyles = xcss({
	marginBlock: '0',
	marginInline: 'space.200',
	minWidth: '200px',
});

const panelContainerStyles = xcss({
	marginBlock: 'space.100',
	marginInline: 'auto',
	minWidth: '200px',
});

const radioOptionContainerStyles = xcss({
	paddingBlock: 'space.075',
	alignItems: 'center',
	justifyContent: 'space-between',
});

const iconContainerStyles = xcss({
	opacity: 0.3,
});
