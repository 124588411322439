import { getUserProperty } from '@atlassian/jira-common-rest/src/api/latest/user-properties/index.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { INSIGHTS_PACKAGE } from '@atlassian/jira-insights-common-constants/src/common/constants/package.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State, PropertyValue } from '../../types.tsx';

export const loadProperty =
	<T,>(propertyKey: string, isRefetch = false): Action<State, Props> =>
	async ({ setState, getState }, { accountId }) => {
		if (!accountId) {
			return;
		}

		const property = getState().properties[propertyKey];
		const isLoading: boolean = property?.isLoading === true;
		const hasLoadedSuccessfully: boolean = property?.isLoading === false && property?.error == null;

		// skip, if already loaded or loading
		if (isLoading || (hasLoadedSuccessfully && !isRefetch)) return;

		setState({
			properties: {
				...getState().properties,
				[propertyKey]: {
					isLoading: true,
					error: null,
					value: null,
				},
			},
		});

		try {
			const value = await getUserProperty<PropertyValue<T>>(accountId, propertyKey);

			setState({
				properties: {
					...getState().properties,
					[propertyKey]: {
						isLoading: false,
						error: null,
						value,
					},
				},
			});
		} catch (error: unknown) {
			if (error instanceof FetchError) {
				setState({
					properties: {
						...getState().properties,
						[propertyKey]: {
							isLoading: false,
							error,
							value: null,
						},
					},
				});

				if (error.statusCode >= 500 && error.statusCode <= 599) {
					log.safeErrorWithoutCustomerData(
						`${INSIGHTS_PACKAGE}.user-properties-error`,
						`Failed to load user property with key: ${propertyKey}`,
						error,
					);
				}
			} else {
				throw error;
			}
		}
	};
