import React, { useCallback, useMemo } from 'react';
import AsyncSprintAssociationDialog from '@atlassian/jira-portfolio-3-plan-increment-common/src/ui/sprint-association-dialog/async.tsx';
import { DEFAULT_ITERATION_LENGTH } from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/constants.tsx';
import { closeTeamAssociationModal } from '../../../../state/actions/sprints/sprint-association-modal/index.tsx';
import { useBoardSelector, useBoardDispatch } from '../../../../state/index.tsx';
import { getColumns } from '../../../../state/selectors/column/column-selectors.tsx';
import {
	getPlanId,
	getScenarioId,
	rapidViewIdSelector,
	getIncrementConfig,
} from '../../../../state/selectors/software/software-selectors.tsx';
import { getTeamById } from '../../../../state/selectors/team/team-selectors.tsx';

const SprintAssociation = () => {
	const dispatch = useBoardDispatch();
	const sprintAssociationModal = useBoardSelector((state) => state.ui.sprintAssociationModal);
	const isOpen = sprintAssociationModal?.isOpen;
	const teamId: string = isOpen ? sprintAssociationModal?.teamId || '' : '';
	const team = useBoardSelector(getTeamById)(teamId);
	const planId = useBoardSelector(getPlanId);
	const scenarioId = useBoardSelector(getScenarioId);
	const incrementId = useBoardSelector(rapidViewIdSelector);
	const columns = useBoardSelector(getColumns);
	const onModalCloseHandler = useCallback(() => {
		dispatch(closeTeamAssociationModal());
	}, [dispatch]);
	const iterations = useMemo(
		() =>
			Object.values(columns || {})
				.filter((column) => column.id !== 0)
				.map((column) => {
					if ('dateRange' in column) {
						return {
							id: column.id,
							startDate: column.dateRange.start,
							endDate: column.dateRange.end,
							name: column.name,
						};
					}
					return {
						id: column.id,
						name: column.name,
						startDate: 0,
						endDate: 0,
					};
				}),
		[columns],
	);
	const incrementConfig = useBoardSelector(getIncrementConfig);

	if (!isOpen) return null;

	return (
		<AsyncSprintAssociationDialog
			isOpen
			teamId={teamId}
			planId={planId}
			scenarioId={scenarioId}
			teamAvatarUrl={team.avatarUrl || ''}
			teamName={team.name}
			incrementId={incrementId}
			sprintByIterationId={team.sprintByIterationId}
			iterations={iterations}
			onModalclose={onModalCloseHandler}
			iterationLength={incrementConfig?.iterationLength ?? DEFAULT_ITERATION_LENGTH}
		/>
	);
};

export default SprintAssociation;
