/**
 * @generated SignedSource<<a9ccfec6988f79abe5e58838fee25119>>
 * @relayHash 1df190b3de2cd775733c9f02ce9d4bce
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 9f7933cf223046e0e75ddad0db0ddea25aecf83e31b6dfa9b2709b73e8b3907c

import type { ConcreteRequest, Query } from 'relay-runtime';
export type hydrateJqlQuery$variables = {
  cloudId: string;
  jql: string;
};
export type hydrateJqlQuery$data = {
  readonly jira: {
    readonly jqlBuilder: {
      readonly hydrateJqlQuery: {
        readonly fields?: ReadonlyArray<{
          readonly field?: {
            readonly displayName: string | null | undefined;
            readonly fieldId: string | null | undefined;
            readonly jqlTerm: string;
            readonly type: string | null | undefined;
          };
          readonly values?: ReadonlyArray<{
            readonly values?: ReadonlyArray<{
              readonly __typename: string;
              readonly displayName: string;
              readonly issue?: {
                readonly issueId: string;
              };
              readonly issueTypes?: ReadonlyArray<{
                readonly issueTypeId: string | null | undefined;
              }>;
              readonly jqlTerm: string;
              readonly sprint?: {
                readonly sprintId: string;
              };
              readonly version?: {
                readonly versionId: string;
              } | null | undefined;
            } | null | undefined>;
          } | null | undefined>;
        }>;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type hydrateJqlQuery = {
  response: hydrateJqlQuery$data;
  variables: hydrateJqlQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "jql"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "jql"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "jqlTerm"
},
v4 = {
  "kind": "ScalarField",
  "name": "displayName"
},
v5 = {
  "concreteType": "JiraJqlField",
  "kind": "LinkedField",
  "name": "field",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    }
  ]
},
v6 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v7 = {
  "kind": "ScalarField",
  "name": "issueId"
},
v8 = {
  "kind": "ScalarField",
  "name": "issueTypeId"
},
v9 = {
  "kind": "ScalarField",
  "name": "sprintId"
},
v10 = {
  "kind": "ScalarField",
  "name": "versionId"
},
v11 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "hydrateJqlQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraJqlBuilder",
            "kind": "LinkedField",
            "name": "jqlBuilder",
            "plural": false,
            "selections": [
              {
                "args": (v2/*: any*/),
                "kind": "LinkedField",
                "name": "hydrateJqlQuery",
                "plural": false,
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "fields",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v5/*: any*/),
                              {
                                "kind": "LinkedField",
                                "name": "values",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "name": "values",
                                        "plural": true,
                                        "selections": [
                                          (v4/*: any*/),
                                          (v3/*: any*/),
                                          (v6/*: any*/),
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "concreteType": "JiraIssue",
                                                "kind": "LinkedField",
                                                "name": "issue",
                                                "plural": false,
                                                "selections": [
                                                  (v7/*: any*/)
                                                ]
                                              }
                                            ],
                                            "type": "JiraJqlIssueFieldValue"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "concreteType": "JiraIssueType",
                                                "kind": "LinkedField",
                                                "name": "issueTypes",
                                                "plural": true,
                                                "selections": [
                                                  (v8/*: any*/)
                                                ]
                                              }
                                            ],
                                            "type": "JiraJqlIssueTypeFieldValue"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "concreteType": "JiraSprint",
                                                "kind": "LinkedField",
                                                "name": "sprint",
                                                "plural": false,
                                                "selections": [
                                                  (v9/*: any*/)
                                                ]
                                              }
                                            ],
                                            "type": "JiraJqlSprintFieldValue"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "concreteType": "JiraVersion",
                                                "kind": "LinkedField",
                                                "name": "version",
                                                "plural": false,
                                                "selections": [
                                                  (v10/*: any*/)
                                                ]
                                              }
                                            ],
                                            "type": "JiraJqlVersionFieldValue"
                                          }
                                        ]
                                      }
                                    ],
                                    "type": "JiraJqlQueryHydratedValue"
                                  }
                                ]
                              }
                            ],
                            "type": "JiraJqlQueryHydratedField"
                          }
                        ]
                      }
                    ],
                    "type": "JiraJqlHydratedQuery"
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "hydrateJqlQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraJqlBuilder",
            "kind": "LinkedField",
            "name": "jqlBuilder",
            "plural": false,
            "selections": [
              {
                "args": (v2/*: any*/),
                "kind": "LinkedField",
                "name": "hydrateJqlQuery",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "fields",
                        "plural": true,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v5/*: any*/),
                              {
                                "kind": "LinkedField",
                                "name": "values",
                                "plural": true,
                                "selections": [
                                  (v6/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "name": "values",
                                        "plural": true,
                                        "selections": [
                                          (v4/*: any*/),
                                          (v3/*: any*/),
                                          (v6/*: any*/),
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "concreteType": "JiraIssue",
                                                "kind": "LinkedField",
                                                "name": "issue",
                                                "plural": false,
                                                "selections": [
                                                  (v7/*: any*/),
                                                  (v11/*: any*/)
                                                ]
                                              }
                                            ],
                                            "type": "JiraJqlIssueFieldValue"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "concreteType": "JiraIssueType",
                                                "kind": "LinkedField",
                                                "name": "issueTypes",
                                                "plural": true,
                                                "selections": [
                                                  (v8/*: any*/),
                                                  (v11/*: any*/)
                                                ]
                                              }
                                            ],
                                            "type": "JiraJqlIssueTypeFieldValue"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "concreteType": "JiraSprint",
                                                "kind": "LinkedField",
                                                "name": "sprint",
                                                "plural": false,
                                                "selections": [
                                                  (v9/*: any*/),
                                                  (v11/*: any*/)
                                                ]
                                              }
                                            ],
                                            "type": "JiraJqlSprintFieldValue"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "concreteType": "JiraVersion",
                                                "kind": "LinkedField",
                                                "name": "version",
                                                "plural": false,
                                                "selections": [
                                                  (v10/*: any*/),
                                                  (v11/*: any*/)
                                                ]
                                              }
                                            ],
                                            "type": "JiraJqlVersionFieldValue"
                                          }
                                        ]
                                      }
                                    ],
                                    "type": "JiraJqlQueryHydratedValue"
                                  }
                                ]
                              }
                            ],
                            "type": "JiraJqlQueryHydratedField"
                          }
                        ]
                      }
                    ],
                    "type": "JiraJqlHydratedQuery"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "9f7933cf223046e0e75ddad0db0ddea25aecf83e31b6dfa9b2709b73e8b3907c",
    "metadata": {},
    "name": "hydrateJqlQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "e0ea44cfdbf7f7f2b9f2cd672a8eee79";

export default node;
