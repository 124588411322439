import React from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import { token } from '@atlaskit/tokens';
import OperationButton from './ecosystem-operation/view.tsx';
import type { Operation } from './types.tsx';

export type Props = {
	operations: Operation[];
	onModalClose: () => void;
	isTabNavigation?: boolean;
};

export default function BoardOperations(props: Props) {
	if (props.isTabNavigation) {
		if (props.operations.length === 0) {
			return null;
		}
		return (
			<>
				{props.operations.map((op) => (
					<OperationButton
						key={op.url}
						operation={op}
						onModalClose={props.onModalClose}
						isTabNavigation
					/>
				))}
			</>
		);
	}
	return (
		<>
			{props.operations.map((op) => (
				<OperationWrapper key={op.url}>
					<OperationButton operation={op} onModalClose={props.onModalClose} />
				</OperationWrapper>
			))}
		</>
	);
}

BoardOperations.defaultProps = {
	operations: [],
	onModalClose: noop,
	isTabNavigation: false,
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OperationWrapper = styled.div({
	marginRight: token('space.100'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:last-child': {
		marginRight: '0',
	},
});
