import mergeWith from 'lodash/mergeWith';
import {
	AbstractJastVisitor,
	COMPOUND_OPERATOR_AND,
	COMPOUND_OPERATOR_OR,
	OPERATOR_EQUALS,
	OPERATOR_IN,
	type CompoundClause,
	type TerminalClause,
} from '@atlaskit/jql-ast';
import { TEXT_FIELDS } from '../../../common/constants.tsx';
import type { TextSearchInputClauseType } from '../types.tsx';
import { UnsupportedRefinementError } from '../unsupported-refinement-error/index.tsx';

// Map of field keys to their respective clauses in the Jast
export type ClauseMap = {
	[x: string]: (TerminalClause | CompoundClause)[];
};

export type Result = {
	clauseMap: ClauseMap;
	textSearchInputClause?: TextSearchInputClauseType;
};

export class JqlClauseCollectingVisitor extends AbstractJastVisitor<Result> {
	visitNotClause(): Result {
		throw new UnsupportedRefinementError('Unsupported NOT clause');
	}

	visitCompoundClause = (compoundClause: CompoundClause): Result => {
		const operator = compoundClause.operator.value;

		if (operator === COMPOUND_OPERATOR_AND) {
			return compoundClause.clauses.reduce<Result>(
				(result, clause) => this.aggregateResult(clause.accept(this), result),
				{ clauseMap: {} },
			);
		}

		if (operator === COMPOUND_OPERATOR_OR) {
			// The only supported OR clause is for the text search refinement. Downstream validators are responsible for
			// checking if the compound clause is valid for text search.
			// If future refinements emerge that also use OR clauses then this logic would need to change to perform some
			// type of shape matching against the clause to find the correct refinement key.
			return {
				clauseMap: {},
				textSearchInputClause: compoundClause,
			};
		}

		throw new UnsupportedRefinementError(`Unsupported operator '${operator}' in compound clause`);
	};

	visitTerminalClause = (terminalClause: TerminalClause): Result => {
		const fieldName = terminalClause.field.value.toLowerCase();

		if (fieldName === TEXT_FIELDS) {
			return {
				clauseMap: {},
				textSearchInputClause: terminalClause,
			};
		}

		if (
			terminalClause.operator?.value !== OPERATOR_EQUALS &&
			terminalClause.operator?.value !== OPERATOR_IN
		) {
			throw new UnsupportedRefinementError(
				`Unsupported operator '${terminalClause.operator}' in terminal clause`,
			);
		}

		return {
			clauseMap: {
				[terminalClause.field.value.toLowerCase()]: [terminalClause],
			},
		};
	};

	protected defaultResult(): Result {
		return { clauseMap: {} };
	}

	/**
	 * @param aggregate new ClauseMap to add into nextResult
	 * @param nextResult ClauseMap with previous results
	 * @returns ClauseMap with the merged results adding the aggregate at the end
	 */
	protected aggregateResult(aggregate: Result, nextResult: Result): Result {
		if (nextResult.textSearchInputClause && aggregate.textSearchInputClause) {
			throw new UnsupportedRefinementError('Too many text search input clauses');
		}

		// First parameter is the object where we want to add the values and the second the new values to merge into the first object.
		return {
			clauseMap: mergeWith(nextResult.clauseMap, aggregate.clauseMap, (destValue, srcValue) =>
				srcValue.concat(destValue ?? []),
			),
			textSearchInputClause:
				aggregate.textSearchInputClause === undefined
					? nextResult.textSearchInputClause
					: aggregate.textSearchInputClause,
		};
	}
}
