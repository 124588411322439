import React, { memo, useCallback, type RefObject } from 'react';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueLinkedStatsActions } from '@atlassian/jira-issue-links-stats/src/controllers/main.tsx';
import {
	AsyncMeatballMenu,
	AsyncMenuRenderer,
} from '@atlassian/jira-software-context-menu/src/async.tsx';
import { MeatballMenu } from '@atlassian/jira-software-context-menu/src/ui/MeatballMenu.tsx';
import {
	CONTEXT_MENU_APPEARANCE,
	MEATBALL_MENU_APPEARANCE,
} from '@atlassian/jira-software-context-menu/src/common/constants.tsx';
import type { MenuNode } from '@atlassian/jira-software-context-menu/src/common/types.tsx';
import { SoftwareContextMenu } from '@atlassian/jira-software-context-menu/src/index.tsx';
import { Capability } from '../../../../../../common/capability/index.tsx';
import { useBoardSelector, useBoardDispatch } from '../../../../../../state/index.tsx';
import { getVisibleCardSelection } from '../../../../../../state/selectors/card/card-selectors.tsx';
import { getIsIssueDoneInClosedSprint } from '../../../../../../state/selectors/issue/issue-selectors.tsx';
import {
	getCanRankByIssue,
	projectTypeSelector,
} from '../../../../../../state/selectors/software/software-selectors.tsx';
import { useCapability } from '../../../../../../state/state-hooks/capabilities/index.tsx';
import { setInlineEditing } from '../../../../../../state/actions/card/index.tsx';
import { ANALYTICS_MAPPING_TABLE } from './constants.tsx';
import { useArchiveIssue } from './menu-items/archive-issue/index.tsx';
import { useBulkChange } from './menu-items/bulk-change/index.tsx';
import { useChangeDateRange } from './menu-items/change-date-range/index.tsx';
import { useChangeParent } from './menu-items/change-parent/index.tsx';
import { useChangeTeam } from './menu-items/change-team/index.tsx';
import { useCloneIssue } from './menu-items/clone-issue/index.tsx';
import { useCopyIssueKey } from './menu-items/copy-issue-key/index.tsx';
import { useCopyIssueLink } from './menu-items/copy-issue-link/index.tsx';
import { useDeleteIssue } from './menu-items/delete-issue/index.tsx';
import { useEditCover } from './menu-items/edit-cover/index.tsx';
import { useFlag } from './menu-items/flag/index.tsx';
import { useHeader } from './menu-items/header/index.tsx';
import { useAddLabel } from './menu-items/label/index.tsx';
import { useLinkIssue, useLinkIssueIPBoard } from './menu-items/link-issue/index.tsx';
import { useMoveTo } from './menu-items/move-to/index.tsx';
import { useRemoveFromSprint } from './menu-items/remove-from-sprint/index.tsx';
import { createSeparator } from './menu-items/separator/index.tsx';
import { useViewIssueSearch } from './menu-items/view-in-issue-search/index.tsx';
import messages from './messages.tsx';
import type { MenuProps, MenuRendererWithMenuItemsProps, ContextMenuItemsProps } from './types.tsx';
import { useChangeStatus } from './menu-items/change-status/index.tsx';

export const useContextMenuItems = ({
	issueId,
	issueKey,
	selectedCardIds,
	returnFocusRef,
}: ContextMenuItemsProps): MenuNode[] => {
	const cardIds = selectedCardIds.includes(issueId) ? selectedCardIds : [issueId];
	const isMultiSelectMode = cardIds.length > 1;

	const projectType = useBoardSelector((state) => projectTypeSelector(state));
	const isSoftwareProject = projectType === SOFTWARE_PROJECT;

	const header = useHeader({ selectedCards: cardIds });

	const shouldRenderMoveTo = useBoardSelector((state) => getCanRankByIssue(state)(issueId));
	const moveTo = useMoveTo({ issueId, selectedCardIds: cardIds, isMultiSelectMode });

	const shouldRenderAddLabelCardMenu = useCapability(Capability.RENDER_ADD_LABEL_CARD_MENU_ITEM);
	const shouldRenderAddFlagCardMenu = useCapability(Capability.RENDER_ADD_FLAG_CARD_MENU_ITEM);
	const shouldRenderEditCoverCardMenu = useCapability(Capability.RENDER_EDIT_COVER);
	const shouldRenderCloneCardMenu = useCapability(Capability.RENDER_CLONE_ISSUE);

	const label = useAddLabel({ issueId, selectedCardIds: cardIds });
	const flag = useFlag({
		issueId,
		...(fg('one_event_rules_them_all_fg') ? { selectedCardIds: cardIds } : {}),
	});
	const linkIssue = useLinkIssue({
		issueId,
		issueKey,
		...(fg('jfp_a11y_fix_jsw_board') ? { returnFocusRef } : {}),
	});

	const changeParent = useChangeParent({
		issueId,
		selectedCardIds: cardIds,
		isMultiSelectMode,
	});
	const changeStatus = useChangeStatus({
		issueId,
	});
	const bulkChange = useBulkChange({ isMultiSelectMode });
	const editCover = useEditCover({ issueId });

	const copyIssueLink = useCopyIssueLink({ issueKey, isMultiSelectMode });
	const copyIssueKey = useCopyIssueKey({ issueKey, isMultiSelectMode });
	const viewInIssueSearch = useViewIssueSearch({ isMultiSelectMode });

	const removeFromSprint = useRemoveFromSprint({
		issueId,
		...(fg('one_event_rules_them_all_fg') ? { selectedCardIds: cardIds } : {}),
	});
	const cloneIssue = useCloneIssue({ issueKey, issueId });
	const archiveIssue = useArchiveIssue({ issueKey, issueId, isMultiSelectMode });
	const deleteIssue = useDeleteIssue({ issueId, isMultiSelectMode });

	const cardActions: MenuNode[] = [
		...(shouldRenderAddFlagCardMenu ? flag : []),
		...(shouldRenderAddLabelCardMenu ? label : []),
		...(isSoftwareProject && !isMultiSelectMode ? linkIssue : []),
		...changeParent,
		...bulkChange,
		...(shouldRenderEditCoverCardMenu && !isMultiSelectMode ? editCover : []),
	];
	const otherActions: MenuNode[] = [...copyIssueLink, ...copyIssueKey, ...viewInIssueSearch];
	const deletionActions: MenuNode[] = [
		...removeFromSprint,
		...(shouldRenderCloneCardMenu && !isMultiSelectMode ? cloneIssue : []),
		...(archiveIssue || []),
		...deleteIssue,
	];

	return [
		...header,
		...(shouldRenderMoveTo ? moveTo : []),
		...(!isMultiSelectMode && changeStatus.length > 0 && fg('card_menu_item_change_status')
			? changeStatus
			: []),
		...((shouldRenderMoveTo && moveTo.length > 0) ||
		(changeStatus.length > 0 && fg('card_menu_item_change_status'))
			? [createSeparator('move-actions')]
			: []),
		...otherActions,
		...(otherActions.length > 0 && cardActions.length > 0
			? [createSeparator('other-actions')]
			: []),
		...cardActions,
		...(deletionActions.length > 0 ? [createSeparator('delete-actions')] : []),
		...deletionActions,
	];
};

export const useIncrementPlanningContextMenuItems = ({
	issueId,
	issueKey,
	selectedCardIds,
	returnFocusRef,
}: ContextMenuItemsProps): MenuNode[] => {
	const cardIds = selectedCardIds.includes(issueId) ? selectedCardIds : [issueId];
	const isMultiSelectMode = cardIds.length > 1;
	const header = useHeader({ selectedCards: cardIds });
	const changeDateRange = useChangeDateRange({
		issueId,
		selectedCardIds: cardIds,
		isMultiSelectMode,
	});

	const isIssueDoneInClosedSprint = useBoardSelector((state) =>
		getIsIssueDoneInClosedSprint(state, issueId),
	);

	const linkIssueOld = useLinkIssueIPBoard({ issueId, issueKey });
	const linkIssueNew = useLinkIssueIPBoard({ issueId, issueKey, returnFocusRef });

	const linkIssue = fg('increment_board_a11y_fix') ? linkIssueNew : linkIssueOld;

	const changeParent = useChangeParent({
		issueId,
		selectedCardIds: cardIds,
		isMultiSelectMode,
	});
	const changeTeam = useChangeTeam({
		issueId,
		selectedCardIds: cardIds,
		isMultiSelectMode,
	});
	return [
		...header,
		...(!isIssueDoneInClosedSprint ? changeDateRange : []),
		...(!isIssueDoneInClosedSprint && changeDateRange.length > 0
			? [createSeparator('move-actions')]
			: []),
		...(!isMultiSelectMode ? linkIssue : []),
		...changeParent,
		...(!isIssueDoneInClosedSprint ? changeTeam : []),
	];
};

const MenuRendererWithMenuItems = memo(
	({ issueId, issueKey, scope, triggerRef }: MenuRendererWithMenuItemsProps) => {
		const selectedCardIdFromState = useBoardSelector(getVisibleCardSelection);
		const shouldUseIncrementPlanningContextMenu = useCapability(
			Capability.RENDER_INCREMENT_PLANNING_CONTEXT_MENU,
		);
		const useContextMenuItemsFn = shouldUseIncrementPlanningContextMenu
			? useIncrementPlanningContextMenuItems
			: useContextMenuItems;
		const menuItems = useContextMenuItemsFn({
			issueId,
			issueKey,
			selectedCardIds: selectedCardIdFromState,
			returnFocusRef: triggerRef,
		});

		return (
			<AsyncMenuRenderer
				scope={scope}
				menuItems={menuItems}
				triggerRef={triggerRef}
				{...(shouldUseIncrementPlanningContextMenu ? { shouldRenderToParent: false } : {})}
			/>
		);
	},
);

export const ContextMenu = memo((props: MenuProps) => {
	const { issueId, issueKey, selectedCardIds, appearance } = props;

	const { formatMessage } = useIntl();
	const { handleOnClose: handleOnIssueLinkedStatsClose } = useIssueLinkedStatsActions();
	const dispatch = useBoardDispatch();

	const renderMenu = useCallback(
		(scope: string, menuTriggerRef?: RefObject<HTMLButtonElement>) => {
			handleOnIssueLinkedStatsClose();

			if (appearance === MEATBALL_MENU_APPEARANCE) {
				const { triggerRef } = props;
				if (triggerRef && menuTriggerRef?.current) {
					triggerRef.current = menuTriggerRef.current;
				}
			}

			return (
				<MenuRendererWithMenuItems
					scope={scope}
					issueId={issueId}
					issueKey={issueKey}
					triggerRef={menuTriggerRef}
				/>
			);
		},
		[handleOnIssueLinkedStatsClose, appearance, issueId, issueKey, props],
	);

	const onOpenChange = appearance === MEATBALL_MENU_APPEARANCE ? props.onOpenChange : null;
	const handleOnOpenChange = useCallback(
		(isOpen: boolean) => {
			if (fg('render_program_board_card_in_popup')) {
				if (isOpen) {
					dispatch(setInlineEditing('contextMenu', issueId, true));
				} else {
					dispatch(setInlineEditing('contextMenu', issueId, false));
				}
			}
			if (onOpenChange) {
				onOpenChange(isOpen);
			}
		},
		[onOpenChange, dispatch, issueId],
	);

	if (appearance === CONTEXT_MENU_APPEARANCE) {
		const { children } = props;
		return (
			<SoftwareContextMenu
				scope={`${issueId}`}
				renderMenu={renderMenu}
				selectedCardIds={selectedCardIds}
				analyticsMapping={ANALYTICS_MAPPING_TABLE}
				cardId={issueId}
				jsErrorBoundaryProps={{
					id: 'boardCardContextMenu',
					packageName: 'software-board',
					teamName: 'a4t-tanuki',
				}}
			>
				{children}
			</SoftwareContextMenu>
		);
	}

	if (appearance === MEATBALL_MENU_APPEARANCE) {
		const { summary } = props;
		const menuTriggerMoreCardsMessage = expVal(
			'issue-terminology-refresh-m2-replace',
			'isEnabled',
			false,
		)
			? messages.menuTriggerMoreCardsIssueTermRefresh
			: messages.menuTriggerMoreCards;

		const menuTriggerOneCardMessage = expVal(
			'issue-terminology-refresh-m2-replace',
			'isEnabled',
			false,
		)
			? messages.menuTriggerOneCardIssueTermRefresh
			: messages.menuTriggerOneCard;
		const ariaLabelMessage = formatMessage(
			selectedCardIds.length > 1 ? menuTriggerMoreCardsMessage : menuTriggerOneCardMessage,
			{
				summary,
				issueKey,
			},
		);

		return fg('board_meatball_menu_deferred_load') ? (
			<MeatballMenu
				scope={`${issueId}`}
				renderMenu={renderMenu}
				selectedCardIds={selectedCardIds}
				cardId={issueId}
				ariaLabel={ariaLabelMessage}
				analyticsMapping={ANALYTICS_MAPPING_TABLE}
				onOpenChange={handleOnOpenChange}
				jsErrorBoundaryProps={{
					id: 'boardCardMeatballMenu',
					packageName: 'software-board',
					teamName: 'a4t-tanuki',
				}}
			/>
		) : (
			<AsyncMeatballMenu
				scope={`${issueId}`}
				renderMenu={renderMenu}
				selectedCardIds={selectedCardIds}
				cardId={issueId}
				ariaLabel={ariaLabelMessage}
				analyticsMapping={ANALYTICS_MAPPING_TABLE}
				onOpenChange={handleOnOpenChange}
				jsErrorBoundaryProps={{
					id: 'boardCardMeatballMenu',
					packageName: 'software-board',
					teamName: 'a4t-tanuki',
				}}
			/>
		);
	}

	return null;
});
