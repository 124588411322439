import React from 'react';
import Heading from '@atlaskit/heading';
import LockIcon from '@atlaskit/icon/core/migration/lock-locked--lock';
import { Flex, Stack, Text, Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from '../messages.tsx';

const DisabledButtonContent = () => {
	const { formatMessage } = useIntl();

	return (
		<Flex gap="space.100" xcss={widthWrapperStyles}>
			<LockIcon spacing="spacious" label="" color={token('color.icon.danger')} />
			<Stack space="space.100">
				<Box paddingBlockStart="space.050">
					<Heading size="xsmall" as="h2">
						{formatMessage(messages.disabledButtonHelperHeading)}
					</Heading>
				</Box>
				<Text as="p">{formatMessage(messages.disabledButtonHelperBody)}</Text>
			</Stack>
		</Flex>
	);
};

const widthWrapperStyles = xcss({
	/**
	 * fixed width to keep title on a single line
	 */
	width: '410px',
	padding: 'space.250',
});

export default DisabledButtonContent;
