import React, { useCallback, useMemo, memo, type ComponentPropsWithoutRef } from 'react';
import noop from 'lodash/noop';
import { useIntl } from '@atlassian/jira-intl';
import { setReturnToProjectUrl } from '@atlassian/jira-navigation-apps-sidebar-project-utils/src/index.tsx';
import type { AnalyticsAttributes } from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import type {
	CustomFilter as CustomFilterType,
	CustomFilterId as CustomFilterTypeId,
	CheckboxSelectEntry,
} from '../../../common/types.tsx';
import ListFilter from '../../../common/ui/list/index.tsx';
import messages from './messages.tsx';

type Props = {
	filters?: CustomFilterType[];
	selectedFilters?: CustomFilterTypeId[];
	showLink?: boolean;
	manageUrl?: string;
	onChange?: (ids: CustomFilterTypeId[], attributes?: AnalyticsAttributes) => void;
	isNested?: boolean;
	isCompanyManaged?: boolean;
	showPanelToggle?: boolean;
	isPanelToggleChecked?: boolean;
	onTogglePanel?: () => void;
	removeOuterPadding?: boolean;
	shouldReturnFocus?: boolean;
};

const MAX_SELECTION_ALLOWED = 10;

const CustomFilter = ({
	filters = [],
	selectedFilters = [],
	showLink = false,
	manageUrl = '',
	onChange = noop,
	isNested = false,
	isCompanyManaged = false,
	isPanelToggleChecked = false,
	onTogglePanel = noop,
	removeOuterPadding = false,
	showPanelToggle = false,
	shouldReturnFocus,
}: Props) => {
	const { formatMessage } = useIntl();

	const values = useMemo(
		(): CheckboxSelectEntry[] =>
			filters.map((filter) => ({
				label: filter.name,
				value: filter.id,
			})),
		[filters],
	);

	const onChangeMapValues = useCallback(
		(entries: CheckboxSelectEntry[]) => {
			const analyticAttributes = { isNested };
			onChange(
				entries.map((entry) => entry.value),
				analyticAttributes,
			);
		},
		[onChange, isNested],
	);

	const maxSelectionReachedTooltip = formatMessage(messages.maxSelectionReachedTooltip, {
		maxSelectionAllowed: MAX_SELECTION_ALLOWED,
	});

	const onLinkItemClick = useCallback(() => {
		setReturnToProjectUrl();
	}, []);

	const filterLabel = isCompanyManaged ? messages.labelCmp : messages.label;
	const manageLinkTitle = isCompanyManaged ? messages.manageLinkTitleCmp : messages.manageLinkTitle;
	const toggleLabel = isCompanyManaged ? formatMessage(messages.toggleLabelCmp) : undefined;

	return (
		<ListFilter
			shouldReturnFocus={shouldReturnFocus}
			buttonType="QuickFilters"
			data-testid="filters.ui.filters.custom.assignee-filter"
			label={formatMessage(filterLabel)}
			values={values}
			selectedValues={selectedFilters}
			onChange={onChangeMapValues}
			showLink={showLink}
			url={manageUrl}
			useHrefForUrl={isCompanyManaged}
			linkTitle={formatMessage(manageLinkTitle)}
			maxSelectionAllowed={isCompanyManaged ? undefined : MAX_SELECTION_ALLOWED}
			maxSelectionReachedTooltip={maxSelectionReachedTooltip}
			isNested={isNested}
			onLinkItemClick={onLinkItemClick}
			showPanelToggle={showPanelToggle}
			onTogglePanel={onTogglePanel}
			isPanelToggleChecked={isPanelToggleChecked}
			toggleLabel={toggleLabel}
			removeOuterPadding={removeOuterPadding}
			placeholder={formatMessage(isCompanyManaged ? messages.placeholderCmp : messages.placeholder)}
			{...(showPanelToggle &&
				fg('jfp_a11y_team_list_filter_interactivity') && { shouldCloseMenuOnTab: false })}
		/>
	);
};

const CustomFilterComponent = memo(CustomFilter);

CustomFilterComponent.displayName = 'CustomFilter';
// @ts-expect-error - TS2339 - Property 'whyDidYouRender' does not exist on type 'NamedExoticComponent<Props>'.
CustomFilterComponent.whyDidYouRender = true;

export type CustomFilterProps = JSX.LibraryManagedAttributes<
	typeof CustomFilter,
	ComponentPropsWithoutRef<typeof CustomFilter>
>;
export default CustomFilterComponent;
