import type { MenuNode } from '@atlassian/jira-software-context-menu/src/common/types.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useBoardSelector, useBoardActionCreator } from '../../../../../../../../state/index.tsx';
import { cardTransitionsColumnsSelector } from '../../../../../../../../state/selectors/card-transitions/card-transitions-selectors.tsx';
import { issueRankTransitionRequest } from '../../../../../../../../state/actions/issue/rank-transition/index.tsx';
import { boardIssuesSelector } from '../../../../../../../../state/selectors/issue/board-issue-selectors.tsx';
import type { Props } from './types.tsx';
import { useChangeStatusContent } from './view.tsx';

export const useChangeStatus = ({ issueId }: Props): MenuNode[] => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const analyticsEvent = createAnalyticsEvent({});

	const allIssues = useBoardSelector(boardIssuesSelector);
	const issue = allIssues[String(issueId)];

	const { transitionsByStatusName } = useBoardSelector((state) =>
		issue ? cardTransitionsColumnsSelector(state, issue) : { transitionsByStatusName: new Map() },
	);

	const onChangeStatus = useBoardActionCreator((targetStatusName: string) => {
		const transition = transitionsByStatusName.get(targetStatusName);

		if (!transition) {
			throw new Error(`No transition found for status: ${targetStatusName}`);
		}

		const { destinationColumnId, transitionId } = transition;

		return issueRankTransitionRequest({
			issueIds: [issue.id],
			sourceColumnId: issue.columnId,
			destinationColumnId,
			transitionId,
			rankBeforeIssueId: null,
			rankAfterIssueId: null,
			sourceSwimlaneId: null,
			destinationSwimlaneId: null,
			isSourceDone: false,
			isDestinationDone: false,
			analyticsEvent,
		});
	});

	return useChangeStatusContent({
		onChangeStatus,
		transitionsByStatusName,
	});
};
