import React from 'react';
import noop from 'lodash/noop';
import {
	Action,
	type ActionSubject,
	type AnalyticsAttributes,
	type IncrementPlanningAnalyticsAttributes,
	AnalyticsEvent,
} from '../../common/types/analytics.tsx';
import { ViewAs, View } from '../../common/types/constant.tsx';
import type { ViewSettingMenuProps } from '../../common/types/menu.tsx';
import { useSendAnalyticsEvent } from '../../common/utils/hooks/use-send-analytics-event/index.tsx';
import { BacklogMenu } from './views/backlog/index.tsx';
import { BoardMenu } from './views/board/index.tsx';
import { IncrementPlanningBoardMenu } from './views/increment-planning-board/index.tsx';

export const ViewSettingsMenu = ({
	boardId,
	isClassic,
	view,
	viewAs = ViewAs.POPUP,
	showEpicAsPanel,
	isVersionsEnabled,
	epicCustomName = 'Epic',
	showFilterBarToggle = false,
	showCardDetails = true,
	areSubtasksEnabled = false,
	showWorkSuggestions = false,
	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onSubtasksExpandAll = () => {},
	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onSubtasksCollapseAll = () => {},
	areAllSubtasksExpanded = false,
	areAllSubtasksCollapsed = false,
	isSwimlanesEnabled = false,
	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onSwimlaneExpandAll = () => {},
	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onSwimlaneCollapseAll = () => {},
	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onToggleWorkSuggestions = () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onLinkedIssuesStatsToggledOn = () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onShowCardCoversToggledOn = () => {},
	isDaysInColumnEnabled = false,
	showLinkedIssueStatsToggle = false,
	cardExtraFields = [],
	isEstimateEnabled = false,
	isDueDateEnabled = false,
	isLabelsEnabled = false,
	isPriorityEnabled = false,
	isDevelopmentEnabled = false,
	isWorkSuggestionsEnabled = false,
	dependencyIssueOptions = {},
	disableScrollbar = false,
	onUpdate = noop,
	onClose,
	analyticsData,
	isShowBoardNameEnabled,
}: ViewSettingMenuProps) => {
	const sendAnalyticsEvent = useSendAnalyticsEvent();

	const handleChange = (
		actionSubject: (typeof ActionSubject)[keyof typeof ActionSubject],
		attributes: AnalyticsAttributes,
	) => {
		sendAnalyticsEvent(
			Action.CLICKED,
			actionSubject,
			AnalyticsEvent.UI_EVENT,
			{
				...attributes,
				isClassic,
			},
			view,
		);
	};

	const incrementPlanningHandleChange = (
		actionSubject: (typeof ActionSubject)[keyof typeof ActionSubject],
		attributes: IncrementPlanningAnalyticsAttributes,
		action?: (typeof Action)[keyof typeof Action],
	) => {
		sendAnalyticsEvent(
			action ?? Action.CLICKED,
			actionSubject,
			AnalyticsEvent.UI_EVENT,
			{
				...attributes,
				isClassic,
			},
			view,
		);
	};

	switch (view) {
		case View.BACKLOG: {
			return (
				<BacklogMenu
					viewAs={viewAs}
					boardId={boardId}
					onHandleChange={handleChange}
					showCardDetails={showCardDetails}
					isClassic={isClassic}
					isVersionsEnabled={isVersionsEnabled}
					showEpicAsPanel={showEpicAsPanel}
					showFilterBarToggle={showFilterBarToggle}
					epicCustomName={epicCustomName}
					areSubtasksEnabled={areSubtasksEnabled}
					areAllSubtasksExpanded={areAllSubtasksExpanded}
					areAllSubtasksCollapsed={areAllSubtasksCollapsed}
					onSubtasksExpandAll={onSubtasksExpandAll}
					onSubtasksCollapseAll={onSubtasksCollapseAll}
					cardExtraFields={cardExtraFields}
					isEstimateEnabled={isEstimateEnabled}
					isDueDateEnabled={isDueDateEnabled}
					isPriorityEnabled={isPriorityEnabled}
					isShowBoardNameEnabled={isShowBoardNameEnabled}
				/>
			);
		}
		case View.BOARD: {
			return (
				<BoardMenu
					viewAs={viewAs}
					boardId={boardId}
					isClassic={isClassic}
					isSwimlanesEnabled={isSwimlanesEnabled}
					onHandleChange={handleChange}
					onSwimlaneExpandAll={onSwimlaneExpandAll}
					onSwimlaneCollapseAll={onSwimlaneCollapseAll}
					showFilterBarToggle={showFilterBarToggle}
					showLinkedIssueStatsToggle={showLinkedIssueStatsToggle}
					showCardDetails={showCardDetails}
					epicCustomName={epicCustomName}
					isDaysInColumnEnabled={isDaysInColumnEnabled}
					cardExtraFields={cardExtraFields}
					isEstimateEnabled={isEstimateEnabled}
					isDueDateEnabled={isDueDateEnabled}
					isLabelsEnabled={isLabelsEnabled}
					isPriorityEnabled={isPriorityEnabled}
					isDevelopmentEnabled={isDevelopmentEnabled}
					showWorkSuggestions={showWorkSuggestions}
					onToggleWorkSuggestions={onToggleWorkSuggestions}
					isWorkSuggestionsEnabled={isWorkSuggestionsEnabled}
					onLinkedIssuesStatsToggledOn={onLinkedIssuesStatsToggledOn}
					onShowCardCoversToggledOn={onShowCardCoversToggledOn}
				/>
			);
		}
		case View.JSM_BOARD: {
			return (
				<BoardMenu
					viewAs={viewAs}
					boardId={boardId}
					isSwimlanesEnabled={isSwimlanesEnabled}
					onHandleChange={handleChange}
					showFilterBarToggle={showFilterBarToggle}
					showCardDetails={false}
					onSwimlaneExpandAll={onSwimlaneExpandAll}
					onSwimlaneCollapseAll={onSwimlaneCollapseAll}
				/>
			);
		}
		case View.INCREMENT_PLANNING_BOARD: {
			return (
				<IncrementPlanningBoardMenu
					viewAs={viewAs}
					boardId={boardId}
					isSwimlanesEnabled={isSwimlanesEnabled}
					dependencyIssueOptions={dependencyIssueOptions}
					onHandleChange={incrementPlanningHandleChange}
					onSwimlaneExpandAll={onSwimlaneExpandAll}
					onSwimlaneCollapseAll={onSwimlaneCollapseAll}
					onUpdate={onUpdate}
					epicCustomName={epicCustomName}
					onClose={onClose}
					disableScrollbar={disableScrollbar}
					analyticsData={analyticsData}
				/>
			);
		}
		default:
			return null;
	}
};
