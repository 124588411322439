import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	headingRemoveDoneItemsAfter: {
		id: 'software.main.board-container.board.column.header.menu.menu-group.heading-remove-done-items-after',
		defaultMessage: 'Remove done items after',
		description: 'Label for the remove done items menu group',
	},
	selected: {
		id: 'software.main.board-container.board.column.header.menu.menu-group.selected',
		defaultMessage: 'Selected',
		description: 'Aria label for the selected option icon',
	},
	removeDoneItemsBackButton: {
		id: 'software.main.board-container.board.column.header.menu.menu-group.remove-done-items-back-button',
		defaultMessage: 'Back',
		description: 'Aria label for the back icon button',
	},
	removeDoneItemsAfter: {
		id: 'software.main.board-container.board.column.header.menu.menu-group.remove-done-items-after',
		defaultMessage: 'Remove done items after {days}',
		description:
			'Label for the remove done menu items. The {days} will include the word "days" as part of BE response',
	},
});
