import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { PACKAGE_NAME, TEAM_NAME } from '../../common/constants/index.tsx';
import { useIsTargetedForRaisingAwarenessOfImporter } from '../../controllers/is-targeted-for-raising-awareness-of-importer/index.tsx';
import type { ImportWorkButtonInternal as ImportWorkButtonInternalType } from './index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyImportWorkButtonInternal = lazy<typeof ImportWorkButtonInternalType>(
	() =>
		import(/* webpackChunkName: "async-onboarding-invites-import-work-button" */ './index').then(
			({ ImportWorkButtonInternal }) => ImportWorkButtonInternal,
		),
	{ ssr: false },
);

const ImportWorkButtonWithTargeting = ({ isThemed }: { isThemed?: boolean }) => {
	const isTargeted = useIsTargetedForRaisingAwarenessOfImporter();

	const inner = isTargeted ? <LazyImportWorkButtonInternal isThemed={isThemed} /> : null;

	if (fg('jfp-magma-fix-misaligned-suspense-boundaries')) {
		return <Placeholder name="ImportWorkButtonWithTargeting">{inner}</Placeholder>;
	}

	return inner;
};

export const ImportWorkButton = ({ isThemed }: { isThemed?: boolean }) => (
	<JSErrorBoundary
		id="importWorkButton"
		packageName={PACKAGE_NAME}
		teamName={TEAM_NAME}
		fallback="unmount"
	>
		<ImportWorkButtonWithTargeting isThemed={isThemed} />
	</JSErrorBoundary>
);
