/** @jsx jsx */
import React, {
	memo,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
	type ReactNode,
} from 'react';
import { css, jsx, styled } from '@compiled/react';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import without from 'lodash/without';
import { di } from 'react-magnetic-di';
import PageIcon from '@atlaskit/icon/core/migration/page';
import AkSearchIcon from '@atlaskit/icon/core/migration/search';
import { Box } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import Textfield, { type TextFieldProps } from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import { ScreenReaderText } from '@atlassian/jira-accessibility/src/common/ui/screenreader-text/index.tsx';
import { COMMAND_PALETTE_SECTIONS } from '@atlassian/jira-command-palette-common/src/common/constants.tsx';
import { COMMAND_PALETTE_QUICK_ACTIONS_PRIORITY } from '@atlassian/jira-command-palette-common/src/constants.tsx';
import { useCommandKeywords } from '@atlassian/jira-command-palette-common/src/controllers/use-command-keywords/index.tsx';
import { CommandShortcuts } from '@atlassian/jira-command-palette-common/src/ui/command-shortcuts/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import OverflowHandler from '@atlassian/jira-filters/src/common/ui/overflow-handler/index.tsx';
import type { OverflowConfigItem } from '@atlassian/jira-filters/src/common/ui/overflow-handler/types.tsx';
import AssigneeFilter from '@atlassian/jira-filters/src/ui/filters/assignee/main.tsx';
import ClearButton from '@atlassian/jira-filters/src/ui/filters/clear-button/index.tsx';
import ComponentFilter from '@atlassian/jira-filters/src/ui/filters/component/index.tsx';
import DependenciesFilter from '@atlassian/jira-filters/src/ui/filters/dependencies/index.tsx';
import GoalFilter from '@atlassian/jira-filters/src/ui/filters/goals/index.tsx';
import IssueProjectFilter from '@atlassian/jira-filters/src/ui/filters/issue-project/index.tsx';
import IssueTypeGroupFilter from '@atlassian/jira-filters/src/ui/filters/issue-type-group/index.tsx';
import IssueTypeFilter from '@atlassian/jira-filters/src/ui/filters/issue-type/index.tsx';
import LabelFilter from '@atlassian/jira-filters/src/ui/filters/label/index.tsx';
import ParentFilter from '@atlassian/jira-filters/src/ui/filters/parent/index.tsx';
import QuickFilter from '@atlassian/jira-filters/src/ui/filters/quick/index.tsx';
import RequestTypeFilter from '@atlassian/jira-filters/src/ui/filters/request-type/index.tsx';
import SprintFilter from '@atlassian/jira-filters/src/ui/filters/sprint/index.tsx';
import StatusCategoryFilter from '@atlassian/jira-filters/src/ui/filters/status-category/index.tsx';
import StatusFilter from '@atlassian/jira-filters/src/ui/filters/status/index.tsx';
import VersionFilter from '@atlassian/jira-filters/src/ui/filters/version/index.tsx';
import {
	FilterBar,
	ListFilterContainerNextGen,
} from '@atlassian/jira-filters/src/ui/layout/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import BoardSwitcher from '@atlassian/jira-portfolio-3-plan-increment-common/src/ui/board-switcher/async.tsx';
import type { AnalyticsAttributes } from '@atlassian/jira-product-analytics-bridge';
import { useResizeObserver } from '@atlassian/jira-react-use-resize-observer/src/index.tsx';
import { SearchField } from '@atlassian/jira-searchfield/src/index.tsx';
import {
	toAccountId,
	toAccountIdArray,
	toComponentIdArray,
	toVersionIdArray,
	type ComponentId,
	type IssueId,
	type IssueStatusCategoryId,
	type IssueTypeId,
	type VersionId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import { useShortcutAnalytics } from '@atlassian/jira-software-keyboard-shortcuts/src/utils/analytics/index.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { FREE_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { Tokens } from '@atlassian/jira-custom-theme-constants/src/constants.tsx';
import { useSoftwareProjectTheming } from '@atlassian/jira-project-theme-providers/src/controllers/software-theme/index.tsx';
import {
	ASSIGNEE,
	COMPONENT,
	CUSTOM_FILTER,
	DEPENDENCIES,
	GOAL,
	ISSUE_PARENT,
	ISSUE_PROJECT,
	ISSUE_TYPE,
	LABEL,
	QUICK_FILTER,
	REQUEST_TYPE,
	SPRINT,
	STATUS,
	STATUS_CATEGORY,
	TEXT,
	UNASSIGNED_COMPONENT_ID,
	UNASSIGNED_LABEL_ID,
	UNASSIGNED_USER_ID,
	UNASSIGNED_VERSION_ID,
	VERSION,
} from '../../common/constants.tsx';
import CustomFilterBoundary from '../custom-filter-boundary/index.tsx';
import HijackBrowserDefaultSearch from '../hijack-browser-default-search/index.tsx';
import SearchFilterChangeboarding from '../search-filter-changeboarding/index.tsx';
import VersionFilterRelayBoundary from '../version-filter-relay-boundary/index.tsx';
import messages from './messages.tsx';
import type { APIProps as Props } from './types.tsx';
import { getManageCustomFiltersUrl } from './utils.tsx';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EMPTY_ARRAY: Array<any> = [];
const MIN_VISIBLE_USERS = 2;
const USER_AVATAR_WIDTH = 30;
const DEFAULT_VISIBLE_USERS = 7;
const MIN_FILTER_BAR_WIDTH = 510;
const LARGE_SEARCH_FILTER_BREAKPOINT = 1440;

const SoftwareFiltersStateless = (props: Props) => {
	di(requestAnimationFrame);

	const {
		filter,
		issueParentsLabel,
		assignees = [],
		issueParents = [],
		versions = [],
		components = [],
		labels = [],
		issueProjects = {},
		issueTypes = [],
		issueTypeGroups = [],
		statuses = [],
		requestTypes = [],
		onChange,
		onSearchFieldFocus = noop,
		onExtraAvatarMenuOpen = noop,
		onFilterPanelMounted = noop,
		onClearAllButtonClicked = noop,
		onToggleParentPanel = noop,
		onToggleVersionsPanel = noop,
		isIssueParentPanelToggleChecked = false,
		isVersionPanelToggleChecked = false,
		isAnyModalOpen = false,
		AddPeopleModal,
		/**
		 * @deprecated `showPanelToggle` used to control visibility of all panel toggles. Use `panelToggleVisibility` instead to control each panel toggle visibility.
		 */
		showPanelToggle = false,
		panelToggleVisibility,
		showParentIssueMenuIfValuesAreEmpty = false,
		showSearchFilterChangeboarding = false,
		hijackBrowserDefaultSearch = false,
		screenName,
		isVersionsFeatureEnabled = false,
		isComponentsFeatureEnabled = false,
		isStatusCategoriesFeatureEnabled = true,
		isIssueParentsFeatureEnabled = true,
		isLabelsFeatureEnabled = true,
		isIssueProjectsFeatureEnabled = false,
		isIssueTypesFeatureEnabled = true,
		isSprintsFeatureEnabled = true,
		isRequestTypesFeatureEnabled = false,
		isStatusesFeatureEnabled = false,
		isGroupIssueTypesEnabled = false,
		isDependenciesFeatureEnabled = false,
		onChangeInteractionStart = noop,
		projectId,
		projectName,
		projectKey,
		boardId,
		statusCategories = [],
		customFiltersQueryRef,
		CustomFilter,
		showAssigneeFilter = true,
		showCustomFilters = false,
		showBoardSwitcher = false,
		isBoardSwitcherReadOnly = true,
		versionFilterRelayQueryRef,
		VersionFilterRelay,
		isVersionFilterRelayEnabled = false,
		isUnassignedAssigneeOptionEnabled = false,
		isUnassignedVersionOptionEnabled = false,
		isUnassignedLabelOptionEnabled = false,
		isUnassignedComponentOptionEnabled = false,
		isCustomFiltersLoading = false,
		placeholder = '',
		screenReaderInstruction = '',
		sprints = [],
		isAddPeopleButtonEnabled = false,
		isCustomFilterManageLinkEnabled = false,
		jqlFilters = [],
		isCompanyManaged = false,
		isJSMBoard = false,
		hasMultipleProjects = false,
		isGoalsFeatureOptionEnabled = false,
		goals = [],
		shouldShowUnassignedFilterNudge = false,
	} = props;

	const accountId = useAccountId();
	const isAsyncFilterLoading = isCustomFiltersLoading;
	const [screenReaderInstructionForFilters, setScreenReaderInstructionForFilters] = useState(false);
	const { software } = useAppEditions();
	const shouldDisableAddPeople = isCompanyManaged && software === FREE_EDITION;
	const keepUnassignedUserOnTop =
		isUnassignedAssigneeOptionEnabled &&
		screenName === 'board' &&
		fg('unassigned_filter_kill_switch');

	const { formatMessage } = useIntl();

	const allAssignees = useMemo(() => {
		if (!isUnassignedAssigneeOptionEnabled) {
			return assignees;
		}

		const unassignedUser = {
			id: toAccountId(UNASSIGNED_USER_ID),
			displayName: formatMessage(messages.unassignedAssigneeDisplayName),
			avatarUrl: undefined,
		};

		if (keepUnassignedUserOnTop) {
			return [unassignedUser, ...assignees];
		}

		return [...assignees, unassignedUser];
	}, [isUnassignedAssigneeOptionEnabled, formatMessage, keepUnassignedUserOnTop, assignees]);

	const allVersions = useMemo(
		() =>
			isUnassignedVersionOptionEnabled && versions.length
				? [
						{
							id: UNASSIGNED_VERSION_ID,
							name: formatMessage(
								expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
									? messages.unassignedVersionLabelIssueTermRefresh
									: messages.unassignedVersionLabel,
							),
						},
						...versions,
					]
				: versions,
		[versions, isUnassignedVersionOptionEnabled, formatMessage],
	);

	const unassignedOption: { label: string; value: string } = useMemo(
		() => ({
			label: formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.unassignedLabelLabelIssueTermRefresh
					: messages.unassignedLabelLabel,
			),
			value: UNASSIGNED_LABEL_ID,
		}),
		[formatMessage],
	);

	const allComponents = useMemo(
		() =>
			isUnassignedComponentOptionEnabled && components.length
				? [
						{
							id: UNASSIGNED_COMPONENT_ID,
							name: formatMessage(
								expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
									? messages.unassignedComponentLabelIssueTermRefresh
									: messages.unassignedComponentLabel,
							),
						},
						...components,
					]
				: components,
		[components, isUnassignedComponentOptionEnabled, formatMessage],
	);

	useEffect(() => {
		onFilterPanelMounted();
	}, [onFilterPanelMounted]);

	// go/jfe-eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const onSearchChange = useCallback(
		debounce((newText) => {
			onChangeInteractionStart();
			onChange({ [TEXT]: newText }, TEXT, undefined);
		}, 300),
		[onChange, onChangeInteractionStart],
	);

	const assigneeValue = useMemo(() => get(filter, [ASSIGNEE], EMPTY_ARRAY), [filter]);
	const onToggleAssignee = useCallback(
		(entryId: string) => {
			setScreenReaderInstructionForFilters(true);
			setTimeout(() => {
				setScreenReaderInstructionForFilters(false);
			});
			const withoutValue = without(assigneeValue, entryId);
			const newFilterValue =
				withoutValue.length === assigneeValue.length ? withoutValue.concat(entryId) : withoutValue;
			onChange({ [ASSIGNEE]: toAccountIdArray(newFilterValue) }, ASSIGNEE, {
				isUnassignedFilterCta: false, // part of experiment unassigned_filter_kill_switch
				isUnassignedSelected: newFilterValue.includes(UNASSIGNED_USER_ID),
				isOnlyCurrentUserSelected:
					accountId && newFilterValue.length === 1 && newFilterValue[0] === accountId,
			});
		},
		[assigneeValue, onChange, accountId],
	);

	const onClickUnassignedFilterNudge = useCallback(() => {
		setScreenReaderInstructionForFilters(true);
		setTimeout(() => {
			setScreenReaderInstructionForFilters(false);
		});

		accountId &&
			onChange({ [ASSIGNEE]: [UNASSIGNED_USER_ID, accountId] }, ASSIGNEE, {
				isUnassignedFilterCta: true,
				isUnassignedSelected: true,
				isOnlyCurrentUserSelected: false,
			});
	}, [onChange, accountId]);

	const onChangeParents = useCallback(
		(parentIds: IssueId[], attributes?: AnalyticsAttributes) => {
			onChange({ [ISSUE_PARENT]: parentIds }, ISSUE_PARENT, attributes);
		},
		[onChange],
	);

	const onChangeVersions = useCallback(
		(versionIds: VersionId[], attributes?: AnalyticsAttributes) => {
			onChange({ [VERSION]: toVersionIdArray(versionIds) }, VERSION, attributes);
		},
		[onChange],
	);

	const onChangeComponents = useCallback(
		(componentIds: ComponentId[], attributes?: AnalyticsAttributes) => {
			onChange({ [COMPONENT]: toComponentIdArray(componentIds) }, COMPONENT, attributes);
		},
		[onChange],
	);

	const onChangeLabels = useCallback(
		(newLabels: string[], attributes?: AnalyticsAttributes) => {
			onChange({ [LABEL]: newLabels }, LABEL, attributes);
		},
		[onChange],
	);

	const onChangeIssueProjects = useCallback(
		(newProjects: string[], attributes?: AnalyticsAttributes) => {
			onChange({ [ISSUE_PROJECT]: newProjects }, ISSUE_PROJECT, attributes);
		},
		[onChange],
	);

	const onChangeIssueTypes = useCallback(
		(issueTypeIds: IssueTypeId[], attributes?: AnalyticsAttributes) => {
			onChange({ [ISSUE_TYPE]: issueTypeIds }, ISSUE_TYPE, attributes);
		},
		[onChange],
	);

	const onChangeStatusCategories = useCallback(
		(newStatusCategories: IssueStatusCategoryId[], attributes?: AnalyticsAttributes) => {
			onChange({ [STATUS_CATEGORY]: newStatusCategories }, STATUS_CATEGORY, attributes);
		},
		[onChange],
	);

	const onChangeCustomFilters = useCallback(
		(newCustomFilters: string[], attributes?: AnalyticsAttributes) => {
			onChange({ [CUSTOM_FILTER]: newCustomFilters }, CUSTOM_FILTER, attributes);
		},
		[onChange],
	);

	const onChangeSprints = useCallback(
		(newSprintIds: string[], attributes?: AnalyticsAttributes) => {
			onChange({ [SPRINT]: newSprintIds }, SPRINT, attributes);
		},
		[onChange],
	);

	const onChangeStatuses = useCallback(
		(newStatuses: string[], attributes?: AnalyticsAttributes) => {
			onChange({ [STATUS]: newStatuses }, STATUS, attributes);
		},
		[onChange],
	);
	const onChangeRequestTypes = useCallback(
		(newRequestTypeIds: string[], attributes?: AnalyticsAttributes) => {
			onChange({ [REQUEST_TYPE]: newRequestTypeIds }, REQUEST_TYPE, attributes);
		},
		[onChange],
	);
	const onChangeQuickFilters = useCallback(
		(newQuickFilters: string[], attributes?: AnalyticsAttributes) => {
			onChange({ [QUICK_FILTER]: newQuickFilters }, QUICK_FILTER, attributes);
		},
		[onChange],
	);

	const onChangeGoals = useCallback(
		(goalIds: string[], attributes?: AnalyticsAttributes) => {
			onChange({ [GOAL]: goalIds }, GOAL, attributes);
		},
		[onChange],
	);

	const onChangeDependencies = useCallback(
		(dependencyOptions: string[], attributes?: AnalyticsAttributes) => {
			onChange({ [DEPENDENCIES]: dependencyOptions }, DEPENDENCIES, attributes);
		},
		[onChange],
	);

	const isClearButtonVisible = useMemo(() => filter && Object.keys(filter).length > 0, [filter]);

	const searchInputRef = useRef<HTMLInputElement>(null);

	const fireShortcutAnalytics = useShortcutAnalytics(screenName);
	const focusSearchInput = fg('blu-3488_small_refactor_in_filters_bar')
		? () => {
				if (!searchInputRef.current) return;

				// This is the same way the global search input prevents
				// the shortcut from being fed into the input
				// See: src/packages/navigation-apps/atlassian-navigation/src/ui/integrations/keyboard-shortcuts/index.tsx
				requestAnimationFrame(() => {
					searchInputRef.current && searchInputRef.current.focus();
				});

				const analyticsPayload = {
					id: 'focusSearchField',
					key: 'f',
					attributes: {},
				};

				fireShortcutAnalytics(analyticsPayload);
			}
		: () => {
				if (!isNil(searchInputRef) && !isNil(searchInputRef.current)) {
					// This is the same way the global search input prevents
					// the shortcut from being fed into the input
					// See: src/packages/navigation-apps/atlassian-navigation/src/ui/integrations/keyboard-shortcuts/index.tsx
					requestAnimationFrame(() => {
						searchInputRef.current && searchInputRef.current.focus();
					});

					const analyticsPayload = {
						id: 'focusSearchField',
						key: 'f',
						attributes: {},
					};

					fireShortcutAnalytics(analyticsPayload);
				}
			};

	const screenReaderDescriptionId = 'search-issue-description';

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const isUserBoard = window.location.pathname.startsWith('/jira/people/');

	const customFilterManageUrl = getManageCustomFiltersUrl(
		projectKey,
		boardId.toString(),
		isCompanyManaged,
		isUserBoard,
		isJSMBoard,
	);

	const showEpicPanelToggle = panelToggleVisibility
		? panelToggleVisibility.showEpicPanelToggle
		: showPanelToggle;

	const isMounted = useRef(false);

	useEffect(() => {
		if (isMounted.current) {
			if (!isClearButtonVisible) {
				setScreenReaderInstructionForFilters(true);
				setTimeout(() => {
					setScreenReaderInstructionForFilters(false);
				}, 1000);
			}
		} else {
			isMounted.current = true;
		}
	}, [isClearButtonVisible]);

	const parentFilter = {
		id: ISSUE_PARENT,
		isStatic: false,
		isHidden: !isIssueParentsFeatureEnabled,
		renderer: (isNested?: boolean) => (
			<ParentFilter
				key="ParentFilter"
				isNested={isNested}
				label={issueParentsLabel}
				parents={issueParents}
				selectedParents={filter[ISSUE_PARENT]}
				isToggleChecked={isIssueParentPanelToggleChecked}
				onChange={onChangeParents}
				onTogglePanel={onToggleParentPanel}
				showPanelToggle={showEpicPanelToggle}
				showMenuIfValuesAreEmpty={showParentIssueMenuIfValuesAreEmpty}
			/>
		),
	};

	const showVersionsPanelToggle = panelToggleVisibility
		? panelToggleVisibility.showVersionsPanelToggle
		: showPanelToggle;

	const versionFilter = {
		id: VERSION,
		isStatic: false,
		hidden: !isVersionsFeatureEnabled,
		renderer: (isNested?: boolean) =>
			isVersionFilterRelayEnabled ? (
				<VersionFilterRelayBoundary
					key="VersionFilterRelayBoundary"
					screenName={screenName}
					fallback={
						<VersionFilter
							isNested={isNested}
							versions={EMPTY_ARRAY}
							isToggleChecked={isVersionPanelToggleChecked}
							onChange={onChangeVersions}
							onTogglePanel={onToggleVersionsPanel}
							selectedVersions={filter[VERSION] ?? EMPTY_ARRAY}
							// RLSS-3020 Spotlight will be cleanup soon
							isSpotlightEnabled={false}
							showPanelToggle={showVersionsPanelToggle}
							isVersionsFeatureEnabled={isVersionsFeatureEnabled}
							hasMultipleProjects={hasMultipleProjects}
							isLoading
						/>
					}
				>
					{versionFilterRelayQueryRef != null && VersionFilterRelay != null ? (
						<VersionFilterRelay
							isNested={isNested}
							isToggleChecked={isVersionPanelToggleChecked}
							onChange={onChangeVersions}
							onTogglePanel={onToggleVersionsPanel}
							selectedVersions={filter[VERSION] ?? EMPTY_ARRAY}
							// RLSS-3020 Spotlight will be cleanup soon
							isSpotlightEnabled={false}
							showPanelToggle={showVersionsPanelToggle}
							isVersionsFeatureEnabled={isVersionsFeatureEnabled}
							versionFilterRelayQueryRef={versionFilterRelayQueryRef}
							isUnassignedVersionOptionEnabled={isUnassignedVersionOptionEnabled}
							hasMultipleProjects={hasMultipleProjects}
						/>
					) : null}
				</VersionFilterRelayBoundary>
			) : (
				<VersionFilter
					key="VersionFilter"
					isNested={isNested}
					versions={allVersions}
					isToggleChecked={isVersionPanelToggleChecked}
					onChange={onChangeVersions}
					onTogglePanel={onToggleVersionsPanel}
					selectedVersions={filter[VERSION] ?? EMPTY_ARRAY}
					// RLSS-3020 Spotlight will be cleanup soon
					isSpotlightEnabled={false}
					showPanelToggle={showVersionsPanelToggle}
					isVersionsFeatureEnabled={isVersionsFeatureEnabled}
				/>
			),
	};

	let overflowItemsBefore: OverflowConfigItem[] = [
		{
			id: STATUS_CATEGORY,
			isStatic: false,
			isHidden: !isStatusCategoriesFeatureEnabled,
			renderer: (isNested?: boolean) => (
				<StatusCategoryFilter
					key="StatusCategoryFilter"
					isNested={isNested}
					statusCategories={statusCategories}
					selectedStatusCategories={filter[STATUS_CATEGORY]}
					onChange={onChangeStatusCategories}
				/>
			),
		},
		/**
		 * the "Project" filter is currently only used by the Increment Planning board of Advanced Roadmaps
		 * please refer to src/packages/software/board/src/services/filter/filter-url-bindings.tsx
		 */
		{
			id: ISSUE_PROJECT,
			isStatic: false,
			isHidden: !isIssueProjectsFeatureEnabled,
			renderer: (isNested?: boolean) => (
				<IssueProjectFilter
					isNested={isNested}
					issueProjects={issueProjects}
					key="IssueProjectFilter"
					onChange={onChangeIssueProjects}
					selectedProjects={filter[ISSUE_PROJECT]}
				/>
			),
		},
		versionFilter,
		parentFilter,
		{
			id: LABEL,
			isStatic: false,
			isHidden: !isLabelsFeatureEnabled,
			renderer: (isNested?: boolean) => (
				<LabelFilter
					key="LabelFilter"
					isNested={isNested}
					{...(isUnassignedLabelOptionEnabled && labels.length ? { unassignedOption } : undefined)}
					labels={labels}
					selectedLabels={filter[LABEL]}
					onChange={onChangeLabels}
				/>
			),
		},
		{
			id: ISSUE_TYPE,
			isStatic: false,
			isHidden: !isIssueTypesFeatureEnabled,
			renderer: (isNested?: boolean) =>
				isGroupIssueTypesEnabled ? (
					// In the program board, we could have multiple issue types with the same hierarchy level and names coming from multiple TWP project. We group them so there's no visually duplicated items in the dropdown
					<IssueTypeGroupFilter
						key="IssueTypeGroupFilter"
						isNested={isNested}
						issueTypeGroups={issueTypeGroups}
						selectedIssueTypes={filter[ISSUE_TYPE]}
						onChange={onChangeIssueTypes}
					/>
				) : (
					<IssueTypeFilter
						key="IssueTypeFilter"
						isNested={isNested}
						issueTypes={issueTypes}
						selectedIssueTypes={filter[ISSUE_TYPE]}
						onChange={onChangeIssueTypes}
					/>
				),
		},
		{
			id: COMPONENT,
			isStatic: false,
			isHidden: !isComponentsFeatureEnabled,
			renderer: (isNested?: boolean) => (
				<ComponentFilter
					key="ComponentFilter"
					isNested={isNested}
					components={allComponents}
					onChange={onChangeComponents}
					selectedComponents={filter[COMPONENT]}
				/>
			),
		},
		{
			id: SPRINT,
			isStatic: false,
			isHidden: !isSprintsFeatureEnabled || sprints.length <= 1,
			renderer: (isNested?: boolean) => (
				<SprintFilter
					key="SprintFilter"
					isNested={isNested}
					sprints={sprints}
					selectedSprints={filter[SPRINT]}
					onChange={onChangeSprints}
				/>
			),
		},
		{
			id: STATUS,
			isStatic: false,
			isHidden: !isStatusesFeatureEnabled,
			renderer: (isNested?: boolean) => (
				<StatusFilter
					key="StatusFilter"
					isNested={isNested}
					statuses={statuses}
					selectedStatuses={filter[STATUS]}
					onChange={onChangeStatuses}
				/>
			),
		},
		{
			id: REQUEST_TYPE,
			isStatic: false,
			isHidden: !isRequestTypesFeatureEnabled,
			renderer: (isNested?: boolean) => (
				<RequestTypeFilter
					key="RequestTypeFilter"
					isNested={isNested}
					requestTypes={requestTypes}
					selectedRequestTypes={filter[REQUEST_TYPE]}
					onChange={onChangeRequestTypes}
				/>
			),
		},
		{
			id: CUSTOM_FILTER,
			isStatic: false,
			renderer: (isNested?: boolean) => (
				<CustomFilterBoundary
					key="CustomFilterBoundary"
					screenName={screenName}
					customFiltersQueryRef={customFiltersQueryRef}
				>
					{(!!customFiltersQueryRef || showCustomFilters) && !!CustomFilter && (
						<CustomFilter
							isNested={isNested}
							customFiltersQueryRef={customFiltersQueryRef}
							selectedFilters={filter[CUSTOM_FILTER]}
							filters={jqlFilters}
							onChange={onChangeCustomFilters}
							showLink={isCustomFilterManageLinkEnabled}
							manageUrl={customFilterManageUrl}
							screenName={screenName}
						/>
					)}
				</CustomFilterBoundary>
			),
		},
		{
			id: QUICK_FILTER,
			isStatic: false,
			isHidden: showCustomFilters,
			renderer: (isNested?: boolean) => (
				<QuickFilter
					key="QuickFilter"
					isNested={isNested}
					filters={jqlFilters}
					selectedFilters={filter[QUICK_FILTER]}
					onChange={onChangeQuickFilters}
				/>
			),
		},
		{
			id: GOAL,
			isStatic: false,
			isHidden: !isGoalsFeatureOptionEnabled || goals.length === 0,
			renderer: (isNested?: boolean) => (
				<GoalFilter
					key="GoalFilter"
					isNested={isNested}
					goals={goals}
					selectedGoals={filter[GOAL]}
					onChange={onChangeGoals}
				/>
			),
		},
		{
			id: DEPENDENCIES,
			isStatic: false,
			isHidden: !isDependenciesFeatureEnabled,
			renderer: () => (
				<DependenciesFilter
					key="DependenciesFilter"
					onChange={onChangeDependencies}
					selectedValues={filter[DEPENDENCIES]}
				/>
			),
		},
	];

	if (isVisualRefreshEnabled() && fg('visual-refresh_drop_3')) {
		overflowItemsBefore = [
			versionFilter,
			parentFilter,
			{
				id: ISSUE_TYPE,
				isStatic: false,
				isHidden: !isIssueTypesFeatureEnabled,
				renderer: (isNested?: boolean) =>
					isGroupIssueTypesEnabled ? (
						// In the program board, we could have multiple issue types with the same hierarchy level and names coming from multiple TWP project. We group them so there's no visually duplicated items in the dropdown
						<IssueTypeGroupFilter
							key="IssueTypeGroupFilter"
							isNested={isNested}
							issueTypeGroups={issueTypeGroups}
							selectedIssueTypes={filter[ISSUE_TYPE]}
							onChange={onChangeIssueTypes}
						/>
					) : (
						<IssueTypeFilter
							key="IssueTypeFilter"
							isNested={isNested}
							issueTypes={issueTypes}
							selectedIssueTypes={filter[ISSUE_TYPE]}
							onChange={onChangeIssueTypes}
						/>
					),
			},
			{
				id: LABEL,
				isStatic: false,
				isHidden: !isLabelsFeatureEnabled,
				renderer: (isNested?: boolean) => (
					<LabelFilter
						key="LabelFilter"
						isNested={isNested}
						{...(isUnassignedLabelOptionEnabled && labels.length
							? { unassignedOption }
							: undefined)}
						labels={labels}
						selectedLabels={filter[LABEL]}
						onChange={onChangeLabels}
					/>
				),
			},
			{
				id: STATUS,
				isStatic: false,
				isHidden: !isStatusesFeatureEnabled,
				renderer: (isNested?: boolean) => (
					<StatusFilter
						key="StatusFilter"
						isNested={isNested}
						statuses={statuses}
						selectedStatuses={filter[STATUS]}
						onChange={onChangeStatuses}
					/>
				),
			},
			{
				id: STATUS_CATEGORY,
				isStatic: false,
				isHidden: !isStatusCategoriesFeatureEnabled,
				renderer: (isNested?: boolean) => (
					<StatusCategoryFilter
						key="StatusCategoryFilter"
						isNested={isNested}
						statusCategories={statusCategories}
						selectedStatusCategories={filter[STATUS_CATEGORY]}
						onChange={onChangeStatusCategories}
					/>
				),
			},
			/**
			 * the "Project" filter is currently only used by the Increment Planning board of Advanced Roadmaps
			 * please refer to src/packages/software/board/src/services/filter/filter-url-bindings.tsx
			 */
			{
				id: ISSUE_PROJECT,
				isStatic: false,
				isHidden: !isIssueProjectsFeatureEnabled,
				renderer: (isNested?: boolean) => (
					<IssueProjectFilter
						isNested={isNested}
						issueProjects={issueProjects}
						key="IssueProjectFilter"
						onChange={onChangeIssueProjects}
						selectedProjects={filter[ISSUE_PROJECT]}
					/>
				),
			},
			{
				id: COMPONENT,
				isStatic: false,
				isHidden: !isComponentsFeatureEnabled,
				renderer: (isNested?: boolean) => (
					<ComponentFilter
						key="ComponentFilter"
						isNested={isNested}
						components={allComponents}
						onChange={onChangeComponents}
						selectedComponents={filter[COMPONENT]}
					/>
				),
			},
			{
				id: SPRINT,
				isStatic: false,
				isHidden: !isSprintsFeatureEnabled || sprints.length <= 1,
				renderer: (isNested?: boolean) => (
					<SprintFilter
						key="SprintFilter"
						isNested={isNested}
						sprints={sprints}
						selectedSprints={filter[SPRINT]}
						onChange={onChangeSprints}
					/>
				),
			},
			{
				id: GOAL,
				isStatic: false,
				isHidden: !isGoalsFeatureOptionEnabled || goals.length === 0,
				renderer: (isNested?: boolean) => (
					<GoalFilter
						key="GoalFilter"
						isNested={isNested}
						goals={goals}
						selectedGoals={filter[GOAL]}
						onChange={onChangeGoals}
					/>
				),
			},
			{
				id: DEPENDENCIES,
				isStatic: false,
				isHidden: !isDependenciesFeatureEnabled,
				renderer: () => (
					<DependenciesFilter
						key="DependenciesFilter"
						onChange={onChangeDependencies}
						selectedValues={filter[DEPENDENCIES]}
					/>
				),
			},
			{
				id: REQUEST_TYPE,
				isStatic: false,
				isHidden: !isRequestTypesFeatureEnabled,
				renderer: (isNested?: boolean) => (
					<RequestTypeFilter
						key="RequestTypeFilter"
						isNested={isNested}
						requestTypes={requestTypes}
						selectedRequestTypes={filter[REQUEST_TYPE]}
						onChange={onChangeRequestTypes}
					/>
				),
			},
			{
				id: CUSTOM_FILTER,
				isStatic: false,
				renderer: (isNested?: boolean) => (
					<CustomFilterBoundary
						key="CustomFilterBoundary"
						screenName={screenName}
						customFiltersQueryRef={customFiltersQueryRef}
					>
						{(!!customFiltersQueryRef || showCustomFilters) && !!CustomFilter && (
							<CustomFilter
								isNested={isNested}
								customFiltersQueryRef={customFiltersQueryRef}
								selectedFilters={filter[CUSTOM_FILTER]}
								filters={jqlFilters}
								onChange={onChangeCustomFilters}
								showLink={isCustomFilterManageLinkEnabled}
								manageUrl={customFilterManageUrl}
								screenName={screenName}
							/>
						)}
					</CustomFilterBoundary>
				),
			},
			{
				id: QUICK_FILTER,
				isStatic: false,
				isHidden: showCustomFilters,
				renderer: (isNested?: boolean) => (
					<QuickFilter
						key="QuickFilter"
						isNested={isNested}
						filters={jqlFilters}
						selectedFilters={filter[QUICK_FILTER]}
						onChange={onChangeQuickFilters}
					/>
				),
			},
		];
	}

	const overflowItemsAfter: OverflowConfigItem[] = [
		{
			id: 'CLEAR_BUTTON',
			// The CLEAR_BUTTON should be non-static until a filter is added, else it causes the More button to appear
			// in the filter bar on small window sizes, due to overflow calculations in <OverflowHandler />
			isStatic: isClearButtonVisible,
			isHidden: !isClearButtonVisible,
			renderer: () => <ClearButton key="ClearButton" onClick={onClearAllButtonClicked} />,
		},
	];

	if (isAsyncFilterLoading) {
		overflowItemsAfter.push({
			id: 'SPINNER',
			isStatic: true,
			isHidden: !isAsyncFilterLoading,
			renderer: () => (
				<SpinnerContainer key="SpinnerContainer">
					<Spinner size="small" />
				</SpinnerContainer>
			),
		});
	}

	const { getKeywords } = useCommandKeywords();

	const [maxVisibleUsers, setMaxVisibleUsers] = useState(DEFAULT_VISIBLE_USERS);
	const [showLargeSearchFilter, setShowLargeSearchFilter] = useState(true);
	const filterBarRef = useRef<HTMLDivElement>(null);

	const onResize = useCallback(() => {
		if (!filterBarRef.current || !searchInputRef.current) return;
		const completeFilterBarWidth = filterBarRef.current.offsetWidth; // complete bar with input + filter controls
		const searchInputWidth = searchInputRef.current.offsetWidth; // only the input
		const remainingWidth = completeFilterBarWidth - searchInputWidth; // remaining room for avatar, etc.

		// Handle avatar overflow

		if (remainingWidth < MIN_FILTER_BAR_WIDTH) {
			const usersToReduce = Math.ceil((MIN_FILTER_BAR_WIDTH - remainingWidth) / USER_AVATAR_WIDTH);
			const newMax = Math.max(DEFAULT_VISIBLE_USERS - usersToReduce, MIN_VISIBLE_USERS);
			setMaxVisibleUsers(newMax);
		} else {
			setMaxVisibleUsers(DEFAULT_VISIBLE_USERS);
		}

		// Handle search field width

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		setShowLargeSearchFilter(window.innerWidth >= LARGE_SEARCH_FILTER_BREAKPOINT);
	}, []);

	useResizeObserver({ ref: filterBarRef, onResize });
	useResizeObserver({ ref: searchInputRef, onResize });

	return (
		<FilterBar ref={filterBarRef}>
			<CommandShortcuts
				keyMap={{
					f: {
						callback: focusSearchInput,
						registerInCommandPalette: {
							id: 'software-filters-stateless-focus-search-input',
							name: formatMessage(messages.searchCurrentPage),
							keywords: getKeywords('pageSearchSynonyms'),
							priority: COMMAND_PALETTE_QUICK_ACTIONS_PRIORITY.searchOnCurrentPage,
							section: COMMAND_PALETTE_SECTIONS.quickActions,
							components: {
								LeftIcon: () => (
									<PageIcon spacing="spacious" label={formatMessage(messages.searchCurrentPage)} />
								),
							},
							analytics: {
								action: 'searchCurrentPage',
							},
						},
					},
				}}
				location="software.filters.keyboard-shortcuts"
			/>
			<ScreenReaderText id={screenReaderDescriptionId}>
				{screenReaderInstruction || formatMessage(messages.screenReaderInstruction)}
			</ScreenReaderText>

			<>
				{screenReaderInstructionForFilters ? (
					<ScreenReaderText
						data-testid="software-filters.ui.stateless.screen-reader-instruction"
						id="software-filters.ui.stateless.screen-reader-instruction"
						role="status"
						aria-relevant="all"
					>
						{formatMessage(messages.screenReaderInstructionForFilters)}
					</ScreenReaderText>
				) : (
					<ScreenReaderText
						data-testid="software-filters.ui.stateless.screen-reader-instruction"
						id="software-filters.ui.stateless.screen-reader-instruction"
						role="status"
					/>
				)}
			</>

			{hijackBrowserDefaultSearch === true && (
				<HijackBrowserDefaultSearch
					onFocusSearch={focusSearchInput}
					isAnyModalOpen={isAnyModalOpen}
				/>
			)}
			{showSearchFilterChangeboarding === true ? (
				<SearchFilterChangeboarding
					hijackBrowserDefaultSearch={hijackBrowserDefaultSearch}
					screenName={screenName}
					isCompanyManaged={isCompanyManaged}
				>
					<SearchField
						placeholderAlwaysVisible
						describedById={screenReaderDescriptionId}
						isAlwaysExpanded={showLargeSearchFilter}
						searchIconVisible={false}
						renderCustomSearchField={CustomSearchInput}
						value={filter[TEXT] || ''}
						placeholder={
							placeholder ||
							formatMessage(
								expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
									? messages.textFilterIssueTermRefresh
									: messages.textFilter,
							)
						}
						onChange={onSearchChange}
						onFocus={onSearchFieldFocus}
						inputRef={searchInputRef}
						maxLength={255} // URLs have a max length
						testId="software-filters.ui.stateless.search-field"
						{...(isVisualRefreshEnabled() && fg('visual-refresh_drop_6')
							? { reducedHeight: true }
							: {})}
					/>
				</SearchFilterChangeboarding>
			) : (
				<SearchField
					placeholderAlwaysVisible
					describedById={screenReaderDescriptionId}
					isAlwaysExpanded={showLargeSearchFilter}
					searchIconVisible={false}
					renderCustomSearchField={CustomSearchInput}
					value={filter[TEXT] || ''}
					placeholder={
						placeholder ||
						formatMessage(
							expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
								? messages.textFilterIssueTermRefresh
								: messages.textFilter,
						)
					}
					onChange={onSearchChange}
					onFocus={onSearchFieldFocus}
					inputRef={searchInputRef}
					maxLength={255} // URLs have a max length
					testId="software-filters.ui.stateless.search-field"
					{...(isVisualRefreshEnabled() && fg('visual-refresh_drop_6')
						? { reducedHeight: true }
						: {})}
				/>
			)}
			{showBoardSwitcher && (
				<BoardSwitcher boardId={boardId} isReadOnly={isBoardSwitcherReadOnly} />
			)}
			{showAssigneeFilter && (
				<AssigneeFilter
					maxUsers={maxVisibleUsers}
					users={allAssignees}
					screenName={screenName}
					selectedUsers={assigneeValue}
					onToggle={onToggleAssignee}
					onAssigneeMenuOpen={onExtraAvatarMenuOpen}
					AddPeopleModal={shouldDisableAddPeople ? undefined : AddPeopleModal}
					projectId={projectId}
					projectName={projectName}
					isAddPeopleButtonEnabled={isAddPeopleButtonEnabled}
					projectKey={projectKey}
					shouldShowUnassignedFilterNudge={shouldShowUnassignedFilterNudge}
					onClickUnassignedFilterNudge={onClickUnassignedFilterNudge}
				/>
			)}
			<ListFilterContainerNextGen
				// eslint-disable-next-line jira/integration/test-id-by-folder-structure
				data-testid="software-filters.ui.list-filter-container"
			>
				<OverflowHandler
					itemsBefore={overflowItemsBefore}
					itemsAfter={overflowItemsAfter}
					selectedItems={filter}
				/>
			</ListFilterContainerNextGen>
		</FilterBar>
	);
};

const customSearchInputStyles = css({
	// https://atlassian.design/components/textfield/examples#customization
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& > [data-ds--text-field--input]': {
		paddingInline: token('space.075'),
		paddingBlock: token('space.050'),
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
		lineHeight: '16px',
	},
});

const customSearchInputStylesOld2 = css({
	// https://atlassian.design/components/textfield/examples#customization
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& > [data-ds--text-field--input]': {
		paddingInline: token('space.075'),
		paddingBlock: token('space.050'),
	},
});

const customSearchInputStylesForTheming = css({
	// https://atlassian.design/components/textfield/examples#customization
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	backgroundColor: Tokens.COLOR_BACKGROUND_INPUT,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/design-system/no-unsafe-design-token-usage
	'--ds-background-input-pressed': Tokens.COLOR_BACKGROUND_INPUT,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/design-system/no-unsafe-design-token-usage
	'--ds-background-input-hovered': Tokens.COLOR_BACKGROUND_INPUT,
});

// TODO: remove on visual-refresh cleanup
const customSearchInputStylesOld = css({
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage -- The token value "4px" and fallback "5px" do not match and can't be replaced automatically.
	borderRadius: token('border.radius.100', '5px'),
	paddingRight: token('space.0'),
	paddingLeft: token('space.0'),
	// https://atlassian.design/components/textfield/examples#customization
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& > [data-ds--text-field--input]': {
		paddingTop: token('space.050'),
		paddingRight: token('space.050'),
		paddingBottom: token('space.050'),
		paddingLeft: token('space.050'),
		height: token('space.400'),
	},
});

const CustomSearchInput = ({
	textFieldProps,
	searchIcon,
}: {
	textFieldProps: TextFieldProps;
	searchIcon: ReactNode;
}) => {
	const { isSoftwareFullTheming } = useSoftwareProjectTheming();
	return (
		<Textfield
			// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides
			css={[
				// eslint-disable-next-line no-nested-ternary
				isVisualRefreshEnabled()
					? fg('visual-refresh_drop_6')
						? customSearchInputStyles
						: customSearchInputStylesOld2
					: customSearchInputStylesOld,
				isSoftwareFullTheming && customSearchInputStylesForTheming,
			]}
			{...textFieldProps}
			elemAfterInput={searchIcon}
			elemBeforeInput={
				<Box paddingInlineStart="space.100" paddingBlockStart="space.025">
					<AkSearchIcon
						// @ts-expect-error - TS2322
						color={isSoftwareFullTheming ? Tokens.COLOR_TEXT_SUBTLE : token('color.icon')}
						LEGACY_size="small"
						label=""
					/>
				</Box>
			}
			autoComplete="off"
			testId="software-filters.ui.stateless.search-field"
		/>
	);
};

export default memo<Props>(SoftwareFiltersStateless);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinnerContainer = styled.div({
	paddingLeft: token('space.050'),
	height: '100%',
	display: 'flex',
	alignItems: 'center',
});
