import React, { useCallback, useRef, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import isNil from 'lodash/fp/isNil';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import ButtonGroup from '@atlaskit/button/button-group';
import { DateTimePicker } from '@atlaskit/datetime-picker';
import Form, { ErrorMessage, Field, FormSection } from '@atlaskit/form';
import Heading from '@atlaskit/heading';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import Link from '@atlaskit/link';
import {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@atlaskit/modal-dialog';
import { Box, Stack, Inline, xcss, Text } from '@atlaskit/primitives';
import SectionMessage from '@atlaskit/section-message';
import Select, { type ValueType } from '@atlaskit/select';
import TextField from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import { JiraModal as Modal } from '@atlassian/jira-modal/src/ui/jira-modal.tsx';
import { getDefaultTimes } from '@atlassian/eoc-day-time-interval';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	fireUIAnalytics,
	FireScreenAnalytics,
	ContextualAnalyticsData,
	MODAL,
} from '@atlassian/jira-product-analytics-bridge';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import {
	DOC_LINKS,
	DEFAULT_ITERATION_LENGTH,
	DEFAULT_ITERATIONS_COUNT,
	ITERATIONS_COUNT_OPTIONS,
} from '../../../common/constants.tsx';

import type { NumberOption } from '../../../common/types.tsx';
import { getBoardScopeFilterType } from '../../../common/utils.tsx';
import planIncrementCommonMessages from '../../../messages.tsx';
import { useIncrementCreationService } from '../../../services/create-increment/index.tsx';
import { ScopeFilterValue, ScopeFilterField } from '../board-scope-filter/index.tsx';
import CalculatedBoardEndDate from '../calculated-end-date/index.tsx';
import IssueCount from '../issue-count/index.tsx';
import IterationsCount from '../iterations-count/index.tsx';
import type { FormData, Props } from '../types.tsx';
import { getNextQuarterStartDate, getWeekStartDay } from '../utils.tsx';
import messages from './messages.tsx';

const DOC_LINK =
	'https://support.atlassian.com/jira-software-cloud/docs/create-a-new-program-board-in-your-plan/';

const IncrementCreation = ({
	onIncrementCreated,
	isOpen = false,
	onClose,
	returnFocusRef,
}: Props) => {
	const { saving, error, save } = useIncrementCreationService();
	const { formatMessage } = useIntl();
	const locale = useLocale();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleCancelButtonClick = useCallback(() => {
		onClose();
	}, [onClose]);

	const focusRef = useRef<HTMLElement>();

	const getStartDate = useCallback((): string => getNextQuarterStartDate(new Date()), []);

	const handleSubmitForm = useCallback(
		async (data: FormData) => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const errorMessages: any = {};
			if ((data.name?.length || 0) < 1) {
				errorMessages.name = formatMessage(messages.nameEmptyError);
			}
			if ((data.start?.length || 0) < 1) {
				errorMessages.start = fg('jpo_a11y_pb_fixes')
					? formatMessage(messages.startDateAndTimeEmptyError)
					: formatMessage(messages.startDateEmptyError);
			}
			if (!isNil(data.filterFieldId) && isNil(data.filterFieldValue)) {
				errorMessages.filterFieldValue = formatMessage(messages.fieldValueEmptyError);
			}
			if (isNil(data.name) || isNil(data.start) || Object.keys(errorMessages).length > 0) {
				return errorMessages;
			}

			const newBoard = {
				name: data.name,
				start: new Date(data.start).getTime(),
				iterationsCount: data.iterationsCount?.value,
				iterationLength: data.iterationLength?.value,
				filterFieldId: data.filterFieldId?.value,
				filterFieldValue: data.filterFieldValue?.value,
			};
			const result = await save(newBoard);
			if (result !== undefined) {
				const attributes = {
					iterationLength: data.iterationLength?.value,
					iterationsCount: data.iterationsCount?.value,
					...(fg('program_board_misc_improvements') && {
						newScopeFilterType: getBoardScopeFilterType(result.boardScopeFilter),
					}),
				};
				onIncrementCreated(result);
				fireUIAnalytics(createAnalyticsEvent({ action: 'added', actionSubject: 'programBoard' }), {
					...attributes,
				});
				onClose();
			}
		},
		[save, onIncrementCreated, onClose, formatMessage, createAnalyticsEvent],
	);

	const scopeFilterMessage = expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
		? messages.scopeFilterDescriptionIssueTermRefresh
		: messages.scopeFilterDescription;
	const scopeFilterDescription = formatMessage(scopeFilterMessage, {
		bold: (chunks: ReactNode) => <b>{chunks}</b>,
		link: (chunks: ReactNode) => (
			<Link href={DOC_LINKS.PROGRAM_BOARD_RECOMMENDATIONS} target="_blank">
				{chunks}
			</Link>
		),
	});

	const iterationLengthOptions = [
		{ label: formatMessage(planIncrementCommonMessages.weeksOption, { count: 1 }), value: 1 },
		{ label: formatMessage(planIncrementCommonMessages.weeksOption, { count: 2 }), value: 2 },
		{ label: formatMessage(planIncrementCommonMessages.weeksOption, { count: 3 }), value: 3 },
		{ label: formatMessage(planIncrementCommonMessages.weeksOption, { count: 4 }), value: 4 },
	];

	const defaultLengthOption = iterationLengthOptions.find(
		({ value }) => value === DEFAULT_ITERATION_LENGTH,
	);

	const filterHeadingMessage = expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
		? messages.scopeFilterHeadingIssueTermRefresh
		: messages.scopeFilterHeading;

	return (
		<ContextualAnalyticsData sourceName="incrementCreation" sourceType={MODAL}>
			<ModalTransition>
				{isOpen && (
					<Modal
						messageId="portfolio-3-plan-increment-common.ui.increment-forms.creation-modal.modal"
						messageType="transactional"
						onClose={handleCancelButtonClick}
						autoFocus={focusRef}
						shouldReturnFocus={
							returnFocusRef && fg('increment_board_a11y_fix') ? returnFocusRef : true
						}
					>
						<ModalHeader>
							<div>
								<ModalTitle>{formatMessage(messages.addIncrementTitle)}</ModalTitle>
								<Box xcss={paragraphStyles}>
									<Text as="p">
										{formatMessage(messages.addIncrementDescriptionNew)}
										<>
											<br />
											<Link href={DOC_LINK} target="_blank">
												{formatMessage(messages.doclink)}
											</Link>
										</>
									</Text>
								</Box>
							</div>
							<ModalClose>
								<Button appearance="link" spacing="none" onClick={handleCancelButtonClick}>
									<CrossIcon
										spacing="spacious"
										label={formatMessage(messages.closeModal)}
										color={token('color.text.subtle')}
									/>
								</Button>
							</ModalClose>
						</ModalHeader>

						<Form<FormData> onSubmit={handleSubmitForm}>
							{({ formProps, submitting }) => (
								<FormStyled {...formProps}>
									<ModalBody>
										<Stack space="space.100">
											<Stack>
												<Inline space="space.100">
													<Box xcss={FieldBoxStyles}>
														<Field
															aria-required
															name="name"
															testId="portfolio-3-plan-increment-common.ui.increment-forms.creation-modal.name-field"
															label={formatMessage(messages.nameShort)}
															defaultValue=""
														>
															{
																// eslint-disable-next-line @typescript-eslint/no-shadow
																({ fieldProps, error, valid }) => (
																	<>
																		<TextField
																			autoComplete="off"
																			placeholder={formatMessage(messages.namePlaceholder)}
																			maxLength={255}
																			{...fieldProps}
																			ref={focusRef}
																		/>
																		{valid ||
																			(error && (
																				<ErrorMessage testId="portfolio-3-plan-increment-common.ui.increment-forms.creation-modal.user-submission-error">
																					{error}
																				</ErrorMessage>
																			))}
																	</>
																)
															}
														</Field>
													</Box>
													<Box xcss={FieldBoxStyles}>
														<Field
															aria-required
															name="start"
															testId="portfolio-3-plan-increment-common.ui.increment-forms.creation-modal.start-date-field"
															label={formatMessage(messages.startDateShort)}
															defaultValue={getStartDate()}
														>
															{
																// eslint-disable-next-line @typescript-eslint/no-shadow
																({ fieldProps, error }) => (
																	<>
																		<DateTimePicker
																			{...fieldProps}
																			locale={locale}
																			datePickerProps={{
																				weekStartDay: getWeekStartDay(locale),
																				...(fg('jpo_a11y_pb_fixes') && {
																					label: formatMessage(messages.startDateShort),
																				}),
																			}}
																			timePickerProps={{
																				times: getDefaultTimes(),
																				...(fg('jpo_a11y_pb_fixes') && {
																					label: formatMessage(messages.startTime),
																				}),
																			}}
																			testId="portfolio-3-plan-increment-common.ui.increment-forms.creation-modal.start-date-picker"
																		/>
																		{error && <ErrorMessage>{error}</ErrorMessage>}
																	</>
																)
															}
														</Field>
													</Box>
												</Inline>
												<Inline space="space.100">
													<Box xcss={FieldBoxStyles}>
														<Field<ValueType<NumberOption>>
															label={formatMessage(messages.iterationLength)}
															name="iterationLength"
															defaultValue={defaultLengthOption}
														>
															{({ fieldProps }) => (
																<Select<NumberOption>
																	{...fieldProps}
																	options={iterationLengthOptions}
																	styles={{
																		menuPortal: (base) => ({
																			...base,

																			zIndex: layers.modal,
																		}),
																	}}
																	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
																	menuPortalTarget={document.body}
																/>
															)}
														</Field>
													</Box>
													<Box xcss={FieldBoxStyles}>
														{' '}
														<Field<ValueType<NumberOption>>
															label={formatMessage(messages.iterationsCount)}
															name="iterationsCount"
															defaultValue={ITERATIONS_COUNT_OPTIONS.find(
																({ value }) => value === DEFAULT_ITERATIONS_COUNT,
															)}
														>
															{({ fieldProps }) => <IterationsCount {...fieldProps} />}
														</Field>
													</Box>
												</Inline>
											</Stack>
											<CalculatedBoardEndDate />
										</Stack>

										<FormSection>
											<Heading size="medium" as={fg('increment_board_a11y_fix') ? 'h2' : undefined}>
												{formatMessage(filterHeadingMessage)}
											</Heading>
											<Box xcss={paragraphStyles}>
												<Text as="p">{scopeFilterDescription}</Text>
											</Box>
											<Inline spread="space-between" space="space.100">
												<ScopeFilterField shouldFetchCustomFields={isOpen} />
												<ScopeFilterValue />
											</Inline>
										</FormSection>

										{!isNil(error) && (
											<FormSection>
												<SectionMessage appearance="error">
													<Box xcss={paragraphStyles}>
														<Text as="p">{formatMessage(messages.errorLabel)}</Text>
													</Box>
												</SectionMessage>
											</FormSection>
										)}
									</ModalBody>
									<ModalFooter>
										<IssueCount initialStartDate={getStartDate()} />
										<ButtonGroup>
											<Button
												appearance="subtle"
												testId="portfolio-3-plan-increment-common.ui.increment-forms.creation-modal.cancel-button"
												onClick={handleCancelButtonClick}
												isDisabled={saving || submitting}
											>
												{formatMessage(messages.cancel)}
											</Button>
											<Button
												type="submit"
												testId="portfolio-3-plan-increment-common.ui.increment-forms.creation-modal.submit-button"
												appearance="primary"
												isDisabled={saving || submitting}
											>
												{formatMessage(messages.submit)}
											</Button>
										</ButtonGroup>
									</ModalFooter>
								</FormStyled>
							)}
						</Form>
						<FireScreenAnalytics />
					</Modal>
				)}
			</ModalTransition>
		</ContextualAnalyticsData>
	);
};

export default IncrementCreation;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ModalClose = styled.div({
	alignSelf: 'flex-start',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FormStyled = styled.form({
	marginTop: 0,
});

const FieldBoxStyles = xcss({
	width: '50%',
});

const paragraphStyles = xcss({
	marginTop: 'space.150',
});
