import React, { type ComponentType } from 'react';
import Spinner from '@atlaskit/spinner';
import { Box, xcss } from '@atlaskit/primitives';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { fg } from '@atlassian/jira-feature-gating';
import type { Props } from './types.tsx';

const LazyBoardInsightsPanel: ComponentType<Props> = lazyForPaint(
	() => import(/* webpackChunkName: "async-board-insights-panel" */ './index'),
);

export const AsyncBoardInsightsPanel = (props: Props) => (
	<Placeholder
		name="board-insights-panel"
		fallback={
			<Box xcss={fg('jira_theming_milestone_1_fg') ? containerStyles : containerStylesOld}>
				<Spinner size="large" />
			</Box>
		}
	>
		<LazyBoardInsightsPanel {...props} />
	</Placeholder>
);

const maxWidth = gridSize * 47;

const containerStylesOld = xcss({
	paddingBottom: 'space.025',
	marginTop: 'space.0',
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${maxWidth}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${maxWidth}px`,
	marginLeft: 'space.150',
	marginRight: 'space.500',
	marginBottom: 'space.200',
});

const containerStyles = xcss({
	paddingBottom: 'space.025',
	marginTop: 'space.0',
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${maxWidth}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${maxWidth}px`,
	marginRight: 'space.300',
	marginBottom: 'space.300',
});
