import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { ISSUE_ARCHIVE_API } from '@atlassian/jira-issue-archival-modal/src/constants.tsx';

export const archiveIssue = async (issueKey: string | null) => {
	await performPutRequest(ISSUE_ARCHIVE_API, {
		body: JSON.stringify({
			issueIdsOrKeys: [issueKey],
		}),
	});
};
