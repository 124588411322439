/**
 * @generated SignedSource<<da1463290e86a01242e846443d4243e7>>
 * @relayHash 2338b790df7ebc66fe2543fb4c270170
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID a5881abf38b02300f367b288249bcf7115bf7b130ca999a33676b1422ffdbc7f

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type JiraLongRunningTaskStatus = "CANCELLED" | "CANCEL_REQUESTED" | "COMPLETE" | "DEAD" | "ENQUEUED" | "FAILED" | "RUNNING" | "%future added value";
export type JiraCloneIssueInput = {
  assignee?: JiraUserInfoInput | null | undefined;
  includeAttachments?: boolean | null | undefined;
  includeChildrenWithSubtasks?: boolean | null | undefined;
  includeComments?: boolean | null | undefined;
  includeLinks?: boolean | null | undefined;
  includeSubtasksOrChildren?: boolean | null | undefined;
  issueId: string;
  optionalFields?: ReadonlyArray<JiraOptionalFieldInput> | null | undefined;
  reporter?: JiraUserInfoInput | null | undefined;
  summary?: string | null | undefined;
};
export type JiraUserInfoInput = {
  id?: string | null | undefined;
};
export type JiraOptionalFieldInput = {
  fieldId: string;
  shouldClone: boolean;
};
export type cloneIssueMutation$variables = {
  input: JiraCloneIssueInput;
};
export type cloneIssueMutation$data = {
  readonly jira: {
    readonly cloneIssue: {
      readonly errors: ReadonlyArray<{
        readonly extensions: {
          readonly errorType: string | null | undefined;
          readonly statusCode: number | null | undefined;
        } | null | undefined;
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly success: boolean;
      readonly taskDescription: string | null | undefined;
      readonly taskId: string | null | undefined;
      readonly taskStatus: JiraLongRunningTaskStatus | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type cloneIssueMutation = {
  response: cloneIssueMutation$data;
  variables: cloneIssueMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "kind": "ScalarField",
  "name": "message"
},
v4 = {
  "kind": "ScalarField",
  "name": "statusCode"
},
v5 = {
  "kind": "ScalarField",
  "name": "errorType"
},
v6 = {
  "kind": "ScalarField",
  "name": "taskId"
},
v7 = {
  "kind": "ScalarField",
  "name": "taskStatus"
},
v8 = {
  "kind": "ScalarField",
  "name": "taskDescription"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "cloneIssueMutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraCloneIssueResponse",
            "kind": "LinkedField",
            "name": "cloneIssue",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ]
                  }
                ]
              },
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "cloneIssueMutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraCloneIssueResponse",
            "kind": "LinkedField",
            "name": "cloneIssue",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "__typename"
                      },
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "id"
                          }
                        ],
                        "type": "BulkMutationErrorExtension"
                      }
                    ]
                  }
                ]
              },
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "a5881abf38b02300f367b288249bcf7115bf7b130ca999a33676b1422ffdbc7f",
    "metadata": {},
    "name": "cloneIssueMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "bcc3082af70fed04b401430419a8fc63";

export default node;
