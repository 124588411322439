import React, { createContext, useCallback, useContext, useState, type ReactNode } from 'react';
import noop from 'lodash/noop';
import { fg } from '@atlassian/jira-feature-gating';
import {
	componentWithCondition,
	functionWithCondition,
} from '@atlassian/jira-feature-flagging-utils';
import type { FieldKey, FieldValue } from '../../common/types.tsx';
import {
	IccProvider as OldIccProvider,
	useIccContext as oldUseIccContext,
} from '../context-old/index.tsx';
import type { IccContextType } from './types.tsx';

const defaultValue: IccContextType = {
	initialSummary: '',
	initialCardTypeId: null,
	initialFieldValues: new Map(),
	initialProjectId: null,
	onChangeSummary: noop,
	onChangeCardType: noop,
	onChangeFieldValue: noop,
	onChangeProject: noop,
	onCancel: noop,
	onCreate: noop,
};

/**
 * Note this is an export with the "Local" suffix!
 * This means it is only exported for consumption within this package.
 * External usages should use the `IccProvider` and `useIccContext` exports instead.
 */
export const IccContextLocal = createContext<IccContextType>(defaultValue);

const useIccContextLocal = () => useContext(IccContextLocal);

const IccProviderLocal: React.FC<{
	children: ReactNode;
}> = ({ children }) => {
	const [initialSummary, setInitialSummary] = useState<IccContextType['initialSummary']>(
		defaultValue.initialSummary,
	);
	const [initialCardTypeId, setInitialCardTypeId] = useState<IccContextType['initialCardTypeId']>(
		defaultValue.initialCardTypeId,
	);
	const [initialFieldValues, setInitialFieldValues] = useState<
		IccContextType['initialFieldValues']
	>(defaultValue.initialFieldValues);
	const [initialProjectId, setInitialProjectId] = useState<IccContextType['initialProjectId']>(
		defaultValue.initialProjectId,
	);

	const onChangeSummary = useCallback((summary: string) => {
		setInitialSummary(summary);
	}, []);

	const onChangeProject = useCallback((projectId: number) => {
		setInitialProjectId(projectId);
	}, []);

	const onChangeCardType = useCallback((cardTypeId: IccContextType['initialCardTypeId']) => {
		setInitialCardTypeId(cardTypeId);
	}, []);

	const onChangeFieldValue = useCallback((fieldKey: FieldKey, fieldValue: FieldValue) => {
		setInitialFieldValues((prevFieldValues) => {
			const updatedFieldValues = new Map(prevFieldValues);
			updatedFieldValues.set(fieldKey, fieldValue);
			return updatedFieldValues;
		});
	}, []);

	const onCancel = useCallback(() => {
		setInitialSummary('');
		setInitialCardTypeId(null);
	}, []);

	const onCreate = useCallback(() => {
		setInitialSummary('');
	}, []);

	return (
		<IccContextLocal.Provider
			value={{
				initialSummary,
				initialCardTypeId,
				initialFieldValues,
				initialProjectId,
				onChangeCardType,
				onChangeSummary,
				onChangeFieldValue,
				onChangeProject,
				onCancel,
				onCreate,
			}}
		>
			{children}
		</IccContextLocal.Provider>
	);
};

const useIccContext = functionWithCondition(
	() => fg('icc_context_to_function_component'),
	useIccContextLocal,
	oldUseIccContext,
);
const IccProvider = componentWithCondition(
	() => fg('icc_context_to_function_component'),
	IccProviderLocal,
	OldIccProvider,
);

export { IccProvider, useIccContext };
