import React, { type CSSProperties, useEffect, useRef, useCallback } from 'react';

type Props = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onResize?: (input?: any) => void;
	children?: React.ReactNode | null;
	style?: CSSProperties;
	showFooter?: boolean;
};

export const ElementSizeMeasurer = ({ onResize, children, style, showFooter }: Props) => {
	const resizeObserver = useRef<ResizeObserver | null>(null);

	const onElement = useCallback(
		(element: HTMLElement | null) => {
			// If we have an existing resize observer, disconnect it before we
			// re-create it
			resizeObserver.current?.disconnect();

			if (element == null) {
				// Do nothing
				return;
			}

			// Anytime we enter this function, we want to rebind the ResizeObserver since
			// our calback has changed (see below dependencies)
			resizeObserver.current = new ResizeObserver(() => {
				onResize?.(element);
			});

			resizeObserver.current.observe(element);
		},
		[resizeObserver, onResize],
	);

	useEffect(
		() => () => {
			// If we are unmounting, cleanup the resize observer by disconnecting it
			resizeObserver.current?.disconnect();
		},
		[resizeObserver],
	);

	return showFooter ? (
		// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
		<li style={style} ref={onElement}>
			{children}
		</li>
	) : (
		// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
		<li style={style} ref={onElement} aria-hidden="true">
			{children}
		</li>
	);
};
