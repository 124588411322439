import { useMemo } from 'react';
import union from 'lodash/union';
import {
	type Operand,
	CLAUSE_TYPE_TERMINAL,
	OPERAND_TYPE_KEYWORD,
	OPERAND_TYPE_LIST,
	OPERAND_TYPE_VALUE,
	type TerminalClause,
	type CompoundClause,
} from '@atlaskit/jql-ast';
import { useClauseMap } from '../index.tsx';
import { UnsupportedRefinementError } from '../unsupported-refinement-error/index.tsx';

const getOperands = (clauseName: string, operand: Operand): string[] => {
	switch (operand?.operandType) {
		case OPERAND_TYPE_KEYWORD:
		case OPERAND_TYPE_VALUE: {
			return [operand.value];
		}
		case OPERAND_TYPE_LIST: {
			return union(...operand.values.map((operandValue) => getOperands(clauseName, operandValue)));
		}
		default: {
			throw new UnsupportedRefinementError(
				`Unsupported operand type (${operand?.operandType}) for ${clauseName}`,
			);
		}
	}
};

export const getSelectedValuesForField = (
	clauses: (TerminalClause | CompoundClause)[],
	clauseName: string,
) => {
	if (!clauses || clauses.length === 0) {
		return [];
	}

	if (clauses.length > 1) {
		throw new UnsupportedRefinementError(`Too many clauses for ${clauseName}`);
	}

	const [clause] = clauses;

	if (clause.clauseType !== CLAUSE_TYPE_TERMINAL) {
		throw new UnsupportedRefinementError(
			`Unsupported clause type (${clause.clauseType}) for ${clauseName}`,
		);
	}

	const { operand } = clause;

	if (!operand) {
		throw new UnsupportedRefinementError(`No operand for ${clauseName}`);
	}

	return getOperands(clauseName, operand);
};

export const useSelectedValues = (clauseName: string): string[] => {
	const clauseMap = useClauseMap();

	const clauses = clauseMap[clauseName.toLowerCase()];

	return useMemo(() => {
		return getSelectedValuesForField(clauses, clauseName);
	}, [clauses, clauseName]);
};

export const useNumberOfSelectedValues = () => {
	const clauseMap = useClauseMap();

	return useMemo(() => {
		let numberOfSelectedValues = 0;
		const clauseName = Object.keys(clauseMap);

		clauseName.forEach((name) => {
			const clauses = clauseMap[name];
			const fieldValues = getSelectedValuesForField(clauses, name);
			numberOfSelectedValues += fieldValues.length;
		});

		return numberOfSelectedValues;
	}, [clauseMap]);
};
