import React, { useCallback } from 'react';
import { ButtonItem } from '@atlaskit/menu';
import { useIntl } from '@atlassian/jira-intl';
import { setReturnToProjectUrl } from '@atlassian/jira-navigation-apps-sidebar-project-utils/src/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useRouterActions } from '@atlassian/react-resource-router';
import messages from './messages.tsx';

export type OwnProps = {};

export type StateProps = {
	url: string;
};

export type Props = OwnProps & StateProps;

export const ManageCustomFiltersItem = ({ url }: Props) => {
	const { formatMessage } = useIntl();
	const { push } = useRouterActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const onClick = useCallback(() => {
		setReturnToProjectUrl();
		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: 'menuItem',
				action: 'clicked',
			}),
			'clickBoardManageCustomFilterItem',
		);
		push(url);
	}, [push, url, createAnalyticsEvent]);

	return <ButtonItem onClick={onClick}>{formatMessage(messages.displayText)}</ButtonItem>;
};

export default ManageCustomFiltersItem;
