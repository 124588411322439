import { fg } from '@atlassian/jira-feature-gating';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import type { Location } from '@atlassian/react-resource-router';

export function getBoardSettingsHref(view: string, location: Location) {
	if (
		(fg('blu-7261-change-blu-5799-to-support-nav3') || getWillShowNav4()) && // Delete line when cleaning up blu-7261
		!isServiceDeskLocation(location) &&
		fg('blu-5799-jira-software-board-entrypoint-route')
	) {
		const basePath = location.pathname.slice(0, location.pathname.lastIndexOf('/'));
		const query = location.search ? location.search : '';
		return `${basePath}/${view}${query}`;
	}
	return `${location.pathname}?config=${view}`;
}

export function isSelected(view: string, queryConfig = '', pathConfig = '') {
	if (
		(fg('blu-7261-change-blu-5799-to-support-nav3') || getWillShowNav4()) && // Delete line when cleaning up blu-7261
		fg('blu-5799-jira-software-board-entrypoint-route')
	) {
		return pathConfig === view;
	}
	return queryConfig === view;
}

export function getBoardUrl(projectKey: string, boardId: string, isCMPBoard: boolean) {
	return `/jira/software${isCMPBoard ? '/c' : ''}/projects/${projectKey}/boards/${boardId}`;
}

export function getJSMBoardUrl(projectKey: string, boardId: string) {
	return `/jira/servicedesk/projects/${projectKey}/boards/${boardId}`;
}

export function getJSMCalendarUrl(projectKey: string, boardId: string) {
	return `/jira/servicedesk/projects/${projectKey}/boards/${boardId}/calendar`;
}

export function getUserBoardUrl(accountId: string, boardId: string) {
	return `/jira/people/${accountId}/boards/${boardId}`;
}

export function getBoardSettingsUrl(ordinaryBoardUrl: string) {
	return `${ordinaryBoardUrl}/settings`;
}

function isServiceDeskLocation(location: Location) {
	return location.pathname.includes('/servicedesk');
}
