import { chain } from 'icepick';
import { UPDATE_OLD_DONE_ISSUES_CUTOFF } from '../../../actions/board/old-done-issues-cutoff/index.tsx';
import { WORK_DATA_SET } from '../../../actions/work/index.tsx';
import type { Action } from '../../../types.tsx';
import type { OldDoneIssuesCutoffConfigTypesState } from './types.tsx';

export const oldOneIssuesCutOffConfigReducer = (
	state: OldDoneIssuesCutoffConfigTypesState = null,
	action: Action,
): OldDoneIssuesCutoffConfigTypesState => {
	if (action.type === WORK_DATA_SET) {
		const {
			payload: { oldDoneIssuesCutOffConfig },
		} = action;
		return oldDoneIssuesCutOffConfig ?? null;
	}
	if (action.type === UPDATE_OLD_DONE_ISSUES_CUTOFF) {
		const oldDoneIssuesCutoff = action.payload;
		const newState = chain(state).setIn(['oldDoneIssuesCutoff'], oldDoneIssuesCutoff).value();
		return newState;
	}
	return state;
};
