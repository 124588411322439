import React from 'react';
import { styled } from '@compiled/react';
import type { IconProps } from '@atlaskit/icon';

export type CustomIconProps = Pick<IconProps, 'glyph' | 'testId' | 'label'>;

const CustomIcon = (props: CustomIconProps) => {
	const { glyph: Glyph, testId, label } = props;
	const glyphProps = { children: Glyph ? <Glyph role="presentation" /> : null };

	return (
		<IconWrapper
			data-testid={testId}
			role={label ? 'img' : 'presentation'}
			aria-label={label || undefined}
			{...glyphProps}
		/>
	);
};

export default CustomIcon;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SVG = styled.svg({
	maxHeight: '100%',
	maxWidth: '100%',
	overflow: 'hidden',
	pointerEvents: 'none',
	verticalAlign: 'bottom',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IconWrapper = styled.span({
	flexShrink: 0,
});
