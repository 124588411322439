import React from 'react';
import type { FlagComponentProps, FlagsMapper } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { CardCreateGlobalFlag } from '@atlassian/jira-software-flags/src/ui/card-create-global/index.tsx';
import { SHOW_ERROR_FLAG_FROM_SWAG } from '@atlassian/jira-software-swag/src/common/constants.tsx';
import { extractFlagFromAction } from '@atlassian/jira-software-swag/src/services/error-to-flag/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { FETCH_CARD_COVERS_FAILURE } from '../state/actions/card-covers/index.tsx';
import {
	FILTERED_CARD_FAILURE,
	REFILTER_FAILURE,
} from '../state/actions/card/filtered-cards/index.tsx';
import {
	ISSUE_CREATE_FILTERED_V2,
	ISSUE_CREATE_MOVED_BETWEEN_SWIMLANES,
	CARD_CREATE_FILTERED_OUT_IN_IP_BOARD,
} from '../state/actions/flags/index.tsx';
import { CARD_CREATE_GLOBAL, CARD_CREATE_UNMAPPED } from '../state/actions/issue/create/index.tsx';
import { SHOW_MISSING_ISSUE_TYPE_MESSAGE } from '../state/actions/work/index.tsx';
import type { Action as CombinedActions, State } from '../state/types.tsx';
import { CardCoverToggleFailedFlag } from './card-cover-toggle-failed/index.tsx';
import CardCreateUnmappedFlagInIpBoard from './card-create-unmapped-in-ip-board/index.tsx';
import CardCreateUnmappedFlag from './card-create-unmapped/index.tsx';
import CardFilteredFlag from './card-filtered/index.tsx';
import CustomFilterFailed from './custom-filter-failed/index.tsx';
import MissingIssueType from './missing-issue-type/index.tsx';

export const flagsMapper: FlagsMapper<CombinedActions, State> = (action: CombinedActions) => {
	switch (action.type) {
		case SHOW_ERROR_FLAG_FROM_SWAG:
			return extractFlagFromAction(action);
		case ISSUE_CREATE_FILTERED_V2:
			return (props: FlagComponentProps) => (
				<CardFilteredFlag
					{...props}
					cardKeys={action.payload.cardKeys}
					sprints={action.payload.sprints}
					{...(fg('fix-gryf-a11y-issues-2') ? { isAutoDismissed: false } : {})}
				/>
			);
		case ISSUE_CREATE_MOVED_BETWEEN_SWIMLANES:
			return (props: FlagComponentProps) => (
				<CardFilteredFlag
					{...props}
					cardKeys={action.payload.cardKeys}
					issueMovedBetweenSwimlanes
				/>
			);
		case CARD_CREATE_UNMAPPED:
			return (props: FlagComponentProps) => (
				<CardCreateUnmappedFlag
					{...props}
					cardKeys={action.payload.cardKeys}
					{...(fg('fix-gryf-a11y-issues-2') ? { isAutoDismissed: false } : {})}
				/>
			);
		case CARD_CREATE_FILTERED_OUT_IN_IP_BOARD:
			return (props: FlagComponentProps) => <CardCreateUnmappedFlagInIpBoard {...props} />;

		case CARD_CREATE_GLOBAL:
			return (props: FlagComponentProps) => (
				<CardCreateGlobalFlag {...props} cardKeys={action.payload.cardKeys} />
			);
		case FILTERED_CARD_FAILURE:
		case REFILTER_FAILURE:
			return (props: FlagComponentProps) => <CustomFilterFailed {...props} />;
		case SHOW_MISSING_ISSUE_TYPE_MESSAGE:
			return (props: FlagComponentProps) => (
				<MissingIssueType {...props} missingIssueType={action.payload} />
			);
		case FETCH_CARD_COVERS_FAILURE:
			return (props: FlagComponentProps) => <CardCoverToggleFailedFlag {...props} />;
		default:
			return undefined;
	}
};
