import type { GraphQLError } from 'graphql';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { SwagError } from './utils/error/index.tsx';

export const SHOW_ERROR_FLAG_FROM_SWAG = 'SHOW_ERROR_FLAG_FROM_SWAG' as const;

export type ShowErrorFlagAction = {
	type: typeof SHOW_ERROR_FLAG_FROM_SWAG;
	payload: SwagError | FetchError | GraphQLError;
};

export const showErrorFlag = (
	error: SwagError | FetchError | GraphQLError,
): ShowErrorFlagAction => ({
	type: SHOW_ERROR_FLAG_FROM_SWAG,
	payload: error,
});

export const XSRF_CHECK_FAILED = 'XSRF_CHECK_FAILED' as const;
