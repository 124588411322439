import { JastBuilder } from '@atlaskit/jql-ast';
import { JqlClauseCollectingVisitor } from './jql-clause-collecting-visitor/index.tsx';
import type { ClauseMap, TextSearchInputClauseType } from './types.tsx';

export const getStateFromJQL = (jql: string) => {
	const ast = new JastBuilder().build(jql);
	let clauseMap: ClauseMap = {};
	let textSearchInputClause: TextSearchInputClauseType;

	if (ast.query) {
		({ clauseMap, textSearchInputClause } = new JqlClauseCollectingVisitor().visit(ast.query));
	} else {
		throw new Error();
	}

	return { ast, clauseMap, jql, textSearchInputClause };
};
