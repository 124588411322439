import React, { useMemo } from 'react';
import { LinkItem } from '@atlaskit/menu';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useContainerContext } from '@atlassian/jira-providers-container-context/src/main.tsx';
import {
	type ContainerContext,
	isUserContext,
	isProjectContext,
} from '@atlassian/jira-providers-container-context/src/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { useBoardSettingsUrl } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/controllers/use-board-settings-url/index.tsx';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import { useBoardSelector } from '../../../../state/index.tsx';
import messages from './messages.tsx';

export type ConfigureBoardMenuItemProps = {};

// TODO Add an app prefix to separate apps
const storageProvider = createLocalStorageProvider('SelectedTabConfig');

/**
 * Reads the CMP selected tab from localStorage. This is set by the legacy FE on:
 *
 * `jira-components/jira-legacy-frontend/greenhopper-frontend/src/main/resources/jira-agile-module/includes/js/rapid/state/RapidBoardState.js#389,408,414,431`
 *
 * In case there is an error reading/parsing the value of it its missing we will
 * use the 'default' tab.
 */
export function readSelectedConfigTab(): string {
	const defaultValue = 'default';
	let value;
	try {
		if (fg('jfp-vulcan-browser-storage-migration')) {
			value = storageProvider.get('selectedConfigTab');
		} else {
			value = localStorage.getItem('selectedConfigTab');
		}
		return value ? JSON.parse(value) : defaultValue;
	} catch (err) {
		return defaultValue;
	}
}

// When cleaning up blu-5799, remove this (and other unused code)
export function buildConfigureBoardUrl({
	containerData,
	boardId,
	selectedConfigTab,
}: {
	containerData: ContainerContext | null;
	boardId: string;
	selectedConfigTab: string;
}): string {
	// containerData should never be null, but we are being defensive here.
	if (containerData == null) return '';

	if (isProjectContext(containerData) && containerData.project.type === SERVICE_DESK_PROJECT) {
		return `/jira/servicedesk/projects/${containerData.project.key}/boards/${boardId}/?config=${selectedConfigTab}`;
	}

	return isUserContext(containerData)
		? `/jira/people/${containerData.user.accountId}/boards/${boardId}/?config=${selectedConfigTab}`
		: `/jira/software/c/projects/${containerData.project.key}/boards/${boardId}/?config=${selectedConfigTab}`;
}

function useConfigureBoardUrl() {
	const boardId = useBoardSelector((state) => state.configuration.rapidViewId);

	// Read the current settings tab from localStorage to redirect.
	const selectedConfigTab = useMemo(readSelectedConfigTab, []);
	const [{ data: containerData }] = useContainerContext();
	const configureBoardUrl = useMemo(
		() => buildConfigureBoardUrl({ containerData, boardId, selectedConfigTab }),
		[boardId, containerData, selectedConfigTab],
	);

	return configureBoardUrl;
}

const useBoardSettingsUrlFn = functionWithCondition(
	() =>
		(fg('blu-7261-change-blu-5799-to-support-nav3') || getWillShowNav4()) && // Delete this line when cleaning up blu-7261
		fg('blu-5799-jira-software-board-entrypoint-route'),
	useBoardSettingsNew,
	useConfigureBoardUrl,
);

function useBoardSettingsNew() {
	const settingsUrl = useBoardSettingsUrl();
	const view = readSelectedConfigTab();
	return `${settingsUrl}/${view === 'default' ? 'filter' : view}`;
}

const ConfigureBoardMenuItem = () => {
	const { formatMessage } = useIntl();
	const configureBoardUrl = useBoardSettingsUrlFn();

	return (
		<LinkItem
			href={configureBoardUrl}
			data-testid="software-board.header.menu.configure-board.custom-item"
		>
			{formatMessage(messages.displayText)}
		</LinkItem>
	);
};

export default ConfigureBoardMenuItem;
