/** @jsx jsx */
import React from 'react';
import { styled, jsx } from '@compiled/react';
import PresenceActiveIcon from '@atlaskit/icon/glyph/presence-active';
import AppIcon from '@atlaskit/icon/core/app';
import { Inline, Text, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import type { TemplateRecommendation, TemplateRecommendationIcon } from '../../../common/types.tsx';
import { templateIcons } from './icons/index.tsx';

const ElevatedIcon: React.FunctionComponent<{
	icons: TemplateRecommendationIcon[];
}> = ({ icons }) => {
	const Icon = templateIcons[icons[0].src];

	return (
		<ElevatedIconWrapper>
			{Icon ? (
				<Icon label={icons[0].altText} />
			) : (
				<AppIcon label="" LEGACY_fallbackIcon={PresenceActiveIcon} />
			)}
		</ElevatedIconWrapper>
	);
};

export const TemplateSection: React.FunctionComponent<{
	template: TemplateRecommendation;
}> = ({ template }) => (
	<Inline alignBlock="center" space="space.200">
		<Inline xcss={iconContainerStyles}>
			<ElevatedIcon icons={template.trigger.icons} />
			<RightArrow />
			<ElevatedIcon icons={template.action.icons} />
		</Inline>
		<Text as="p">{template.description}</Text>
	</Inline>
);

const iconContainerStyles = xcss({ flexShrink: 0 });

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ElevatedIconWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	height: token('space.500'),
	width: token('space.500'),
	borderRadius: '10px',
	// Template icons are always on a white background regardless of theme
	/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	backgroundColor: colors.N0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	color: colors.N800, // forces icons to black when using atlas-kit icons
	/* eslint-enable @atlaskit/design-system/ensure-design-token-usage */
	boxShadow: token('elevation.shadow.raised'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RightArrow = styled.div({
	height: token('space.500'),
	width: '30px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	font: token('font.heading.large'),
	'&::before': {
		content: '→',
	},
});
