import React, {
	// eslint-disable-next-line jira/restricted/react-component-props
	type ComponentProps,
	forwardRef,
	memo,
	useCallback,
	useMemo,
} from 'react';
import set from 'lodash/set';
import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import { Box, Pressable, Text, xcss } from '@atlaskit/primitives';
import { type PopupSelectProps, PopupSelect, components, type OptionProps } from '@atlaskit/select';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { TOOLTIP_DELAY } from '../../../common/constants.tsx';
import type { StatusTransition } from '../../../common/types.tsx';
import messages from './messages.tsx';
import type { SelectRef, Props, StatusOption, StatusTheme } from './types.tsx';

const ANALYTICS_SUBJECT = 'iccStatusSelect';
export const FIELD_KEY_STATUS_TRANSITIONS = 'statusTransitions';

const getStatusTheme = (id: string): StatusTheme => statusCategoryToThemeMap[id] || 'default';

const statusCategoryToThemeMap: { [key: string]: StatusTheme } = {
	'1': 'default',
	'2': 'default',
	'3': 'success',
	'4': 'inprogress',
};

const Option = ({ children, ...props }: OptionProps<StatusOption>) => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const { statusInfo } = props.data as StatusOption;
	const buttonTheme = getStatusTheme(String(statusInfo?.statusCategoryId));
	return (
		<components.Option {...props}>
			<Box
				testId="platform-inline-card-create.ui.form.status-select.option"
				xcss={[optionContentStyles, buttonThemeStyle[buttonTheme]]}
			>
				{children}
			</Box>
		</components.Option>
	);
};

const transformOptions = (statusTransitions: StatusTransition[], selectedTransitionId?: number) => {
	let value;
	const options: StatusOption[] = [];

	statusTransitions.forEach((transition) => {
		const option = {
			label: transition.name,
			value: transition.transitionId,
			statusInfo: transition,
		};

		if (transition.transitionId === selectedTransitionId) {
			value = option;
		} else {
			options.push(option);
		}
	});

	if (!value) {
		value = options?.[0];
	}

	return { options, value };
};

type ButtonThemeStyleType = Record<StatusTheme, ReturnType<typeof xcss>>;

const buttonThemeStyle: ButtonThemeStyleType = {
	default: xcss({
		backgroundColor: 'color.background.neutral',
		color: 'color.text',
		':hover': {
			color: 'color.text',
			backgroundColor: 'color.background.neutral.hovered',
		},
	}),
	success: xcss({
		backgroundColor: 'color.background.success',
		color: 'color.text.success',
		':hover': {
			color: 'color.text.success',
			backgroundColor: 'color.background.success.hovered',
		},
	}),
	inprogress: xcss({
		backgroundColor: 'color.background.information',
		color: 'color.text.information',
		':hover': {
			color: 'color.text.information',
			backgroundColor: 'color.background.information.hovered',
		},
	}),
};

const StatusTrigger = forwardRef<HTMLButtonElement, { value: StatusOption; isOpen: boolean }>(
	({ value, isOpen, ...triggerProps }, ref) => {
		const buttonTheme = getStatusTheme(String(value?.statusInfo?.statusCategoryId));
		const { formatMessage } = useIntl();
		return (
			<Box xcss={statusContainerStyles}>
				<Box xcss={statusButtonWrapperStyles}>
					<Tooltip content={value.label ?? null} delay={TOOLTIP_DELAY} hideTooltipOnClick>
						<Pressable
							key="pressable"
							{...triggerProps}
							ref={ref}
							xcss={[buttonContentStyles, buttonThemeStyle[buttonTheme]]}
							aria-label={formatMessage(messages.ariaLabel, {
								value: value.label ?? formatMessage(messages.invalid),
							})}
							testId="platform-inline-card-create.ui.form.status-select.trigger"
						>
							<Text maxLines={1} weight="bold" size="small" color="inherit">
								{value.label}
							</Text>
							<Box xcss={iconContainerStyles}>
								<ChevronDownIcon
									LEGACY_size="medium"
									label=""
									aria-hidden="true"
									color="currentColor"
								/>
							</Box>
						</Pressable>
					</Tooltip>
				</Box>
			</Box>
		);
	},
);

const StatusSelect = forwardRef<SelectRef, Props>(
	({ statusTransitions, selectedTransitionId, onSelectTransition }, ref) => {
		const { createAnalyticsEvent } = useAnalyticsEvents();

		const { value, options } = useMemo(
			() => transformOptions(statusTransitions, selectedTransitionId),
			[statusTransitions, selectedTransitionId],
		);
		const onChange = useCallback(
			(option: StatusOption | null) => {
				option && onSelectTransition(option.statusInfo.transitionId);

				fireUIAnalytics(
					createAnalyticsEvent({
						action: 'dropdown changed',
						actionSubject: ANALYTICS_SUBJECT,
					}),
					{},
				);
			},
			[createAnalyticsEvent, onSelectTransition],
		);

		const onMenuOpen = useCallback(() => {
			fireUIAnalytics(
				createAnalyticsEvent({
					action: 'dropdown opened',
					actionSubject: ANALYTICS_SUBJECT,
				}),
				{},
			);
		}, [createAnalyticsEvent]);

		const setRefNode = useCallback(
			(popupSelectRef: PopupSelect<StatusOption>) => {
				ref && popupSelectRef && set(ref, 'current.node', popupSelectRef);
			},
			[ref],
		);

		const renderTrigger = useCallback(
			(props: PopupSelectProps & { isOpen: boolean }) => <StatusTrigger {...props} value={value} />,
			[value],
		);

		return (
			<PopupSelect
				ref={setRefNode}
				components={{ Option }}
				target={renderTrigger}
				options={options}
				value={value}
				onChange={onChange}
				onMenuOpen={onMenuOpen}
				isSearchable={false}
				// DSP-2990 - PopupSelect doesn't respect isSearchable, searchThreshold is required
				searchThreshold={Number.MAX_SAFE_INTEGER}
				minMenuWidth="auto"
				classNamePrefix="inline-card-create-status-select"
				testId="platform-inline-card-create.ui.form.status-select.status-select"
			/>
		);
	},
);

export default memo<
	JSX.LibraryManagedAttributes<typeof StatusSelect, ComponentProps<typeof StatusSelect>>
>(StatusSelect);

const statusContainerStyles = xcss({
	display: 'flex',
	minWidth: '0px',
	position: 'relative',
});

const statusButtonWrapperStyles = xcss({
	width: '100%',
	paddingLeft: 'space.100',
});

export const StatusSelectPrimitive =
	memo<JSX.LibraryManagedAttributes<typeof StatusSelect, ComponentProps<typeof StatusSelect>>>(
		StatusSelect,
	);

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { SelectRef, Props } from './types';

const buttonContentStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	paddingBlock: 'space.0',
	paddingLeft: 'space.100',
	paddingRight: 'space.0',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	textTransform: 'uppercase',
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage -- The token value "4px" and fallback "3px" do not match and can't be replaced automatically.
	borderRadius: token('border.radius', '3px'),
	textAlign: 'left',
	width: '100%',
});

const iconContainerStyles = xcss({
	minWidth: '24px',
	height: '24px',
	display: 'flex',
	flex: '1',
	alignItems: 'center',
	justifyContent: 'center',
});

const optionContentStyles = xcss({
	display: 'inline-block',
	width: 'auto',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	textTransform: 'uppercase',
	font: token('font.body.small'),
	fontWeight: token('font.weight.bold'),
	paddingBlock: 'space.0',
	paddingInline: 'space.050',
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage -- The token value "4px" and fallback "3px" do not match and can't be replaced automatically.
	borderRadius: token('border.radius', '3px'),
});
