/**
 * @generated SignedSource<<2d785464d75821ccfde96324076a5601>>
 * @relayHash 873051a74587d6c49f05196ce773f399
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 6ff904961b309477abb532380594e59bfdf262d775e0e87da6eb4e5fe33ed6e9

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraBoardSwimlaneStrategy = "ASSIGNEE_UNASSIGNED_FIRST" | "ASSIGNEE_UNASSIGNED_LAST" | "CUSTOM" | "EPIC" | "ISSUE_CHILDREN" | "ISSUE_PARENT" | "NONE" | "PARENT_CHILD" | "PROJECT" | "REQUEST_TYPE" | "%future added value";
export type JiraJqlScopeInput = {
  board?: JiraJqlBoardInput | null | undefined;
};
export type JiraJqlBoardInput = {
  boardId: AGG$Long;
  swimlaneStrategy?: JiraBoardSwimlaneStrategy | null | undefined;
};
export type boardFilterRefinementRefetchQuery$variables = {
  cloudId: string;
  scope?: JiraJqlScopeInput | null | undefined;
};
export type boardFilterRefinementRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"boardFilterRefinement_softwareBoard">;
};
export type boardFilterRefinementRefetchQuery = {
  response: boardFilterRefinementRefetchQuery$data;
  variables: boardFilterRefinementRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "scope"
  }
],
v1 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v2 = {
  "kind": "Variable",
  "name": "scope",
  "variableName": "scope"
},
v3 = {
  "kind": "ScalarField",
  "name": "__typename"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "boardFilterRefinementRefetchQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "boardFilterRefinement_softwareBoard"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "boardFilterRefinementRefetchQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": [
              (v1/*: any*/)
            ],
            "concreteType": "JiraJqlBuilder",
            "kind": "LinkedField",
            "name": "jqlBuilder",
            "plural": false,
            "selections": [
              {
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 100
                  },
                  {
                    "kind": "Literal",
                    "name": "jqlTerm",
                    "value": "assignee"
                  },
                  (v2/*: any*/)
                ],
                "concreteType": "JiraJqlFieldValueConnection",
                "kind": "LinkedField",
                "name": "fieldValues",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraJqlFieldValueEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "jqlTerm"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "displayName"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "name": "picture"
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "name": "id"
                                  }
                                ]
                              }
                            ],
                            "type": "JiraJqlUserFieldValue"
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "6ff904961b309477abb532380594e59bfdf262d775e0e87da6eb4e5fe33ed6e9",
    "metadata": {},
    "name": "boardFilterRefinementRefetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "9bf0ca6b005e985814f36248385113e3";

export default node;
