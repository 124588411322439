import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import {
	UNASSIGNED_FILTER_NUDGE_STORAGE_KEY,
	DISMISSAL_COUNT_KEY,
	DISMISS_UNTIL_KEY,
} from './constants.tsx';
import type { DismissalState } from './types.tsx';

export const unassignedDismissalLocalStorage = createLocalStorageProvider(
	UNASSIGNED_FILTER_NUDGE_STORAGE_KEY,
);

export const getDismissalLocalStorageState = (): DismissalState => ({
	count: unassignedDismissalLocalStorage.get(DISMISSAL_COUNT_KEY) ?? 0,
	dismissUntil: unassignedDismissalLocalStorage.get(DISMISS_UNTIL_KEY) ?? 0,
});

export const setDismissalLocalStorageState = (dismissalState: DismissalState) => {
	unassignedDismissalLocalStorage.set(DISMISSAL_COUNT_KEY, dismissalState.count);
	unassignedDismissalLocalStorage.set(DISMISS_UNTIL_KEY, dismissalState.dismissUntil);
};

export const clearDismissalLocalStorageState = () => {
	unassignedDismissalLocalStorage.remove(DISMISSAL_COUNT_KEY);
	unassignedDismissalLocalStorage.remove(DISMISS_UNTIL_KEY);
};
