/** @jsx jsx */
import React, { forwardRef, useEffect, useRef } from 'react';
import { styled, jsx } from '@compiled/react';
import Badge from '@atlaskit/badge';
import Button from '@atlaskit/button';
import ChevronDownIconOld from '@atlaskit/icon/glyph/chevron-down';
import ChevronRightIconOld from '@atlaskit/icon/glyph/chevron-right';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { Box, xcss, Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { cssMap } from '@atlaskit/css';
import Tooltip from '@atlaskit/tooltip';
import { ThemedButton } from '@atlassian/jira-project-theme-components/src/ui/themed-button/ThemedButton.tsx';
import { useSoftwareProjectTheming } from '@atlassian/jira-project-theme-providers/src/controllers/software-theme/index.tsx';
import { Tokens } from '@atlassian/jira-custom-theme-constants/src/constants.tsx';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import { SCREEN, ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';

type PopupProps = {
	isOpen: boolean;
	'aria-haspopup': 'true';
	'aria-expanded': boolean;
	'aria-controls'?: string;
};

type Props = {
	isNested: boolean;
	label: string;
	isDisabled?: boolean;
	buttonType?: string;
	selectedCount: number;
	onMouseEnter: () => void;
	removeOuterPadding?: boolean;
	shouldReturnFocus?: boolean;
} & PopupProps;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Ref = React.Ref<any>;

const badgeStyles = cssMap({
	root: {
		borderRadius: token('border.radius'),
		/* eslint-disable @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values */
		// @ts-expect-error - need to apply custom tokens for sufficient text contrast in themed projects
		backgroundColor: Tokens.COLOR_BACKGROUND_ACCENT_SELECTED,
		/* eslint-disable @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values */
		// @ts-expect-error - need to apply custom tokens for sufficient text contrast in themed projects
		color: Tokens.COLOR_TEXT_ACCENT_SELECTED,
		paddingInline: token('space.050'),
		minWidth: '24px',
		textAlign: 'center',
		font: token('font.body.small'),
		display: 'inline-flex',
		justifyContent: 'center',
		blockSize: 'min-content',
	},
	textWrapper: {
		maxWidth: `${gridSize * 15}px`,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	trigger: {
		paddingTop: token('space.075'),
		paddingBottom: token('space.075'),
	},
	triggerWithCount: {
		display: 'flex',
		alignItems: 'center',
	},
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FilterListTrigger = forwardRef<any, Props>(
	(
		{
			isOpen,
			isNested,
			isDisabled,
			removeOuterPadding,
			label,
			buttonType,
			selectedCount,
			onMouseEnter,
			shouldReturnFocus,
			...popupProps
		}: Props,
		ref: Ref,
	) => {
		const { isSoftwareFullTheming: isThemed } = useSoftwareProjectTheming();

		const testId = `filters.common.ui.list.${label.toLowerCase().replace(/ /g, '-')}-filter`;

		const primaryColor = !isOpen ? token('color.icon.subtle') : undefined;
		const primaryColorOld = isOpen ? token('color.icon.selected') : token('color.icon');

		const IconComponent = isNested ? (
			<ChevronRightIcon
				label=""
				LEGACY_fallbackIcon={ChevronRightIconOld}
				LEGACY_primaryColor={primaryColorOld}
				{...(isVisualRefreshEnabled() && fg('visual-refresh-headers-buttons')
					? { color: primaryColor }
					: !isThemed && {
							color: primaryColorOld,
						})}
			/>
		) : (
			<ChevronDownIcon
				label=""
				LEGACY_fallbackIcon={ChevronDownIconOld}
				LEGACY_primaryColor={primaryColorOld}
				{...(isVisualRefreshEnabled() && fg('visual-refresh-headers-buttons')
					? { color: primaryColor }
					: !isThemed && {
							color: primaryColorOld,
						})}
			/>
		);

		const inputRef = useRef<HTMLButtonElement>();
		const isMounted = useRef(false);
		useEffect(() => {
			if (shouldReturnFocus && buttonType === 'QuickFilters' && isMounted.current) {
				inputRef?.current?.focus();
			} else {
				isMounted.current = true;
			}
		}, [shouldReturnFocus, buttonType]);

		const conditionalRef = buttonType === 'QuickFilters' ? mergeRefs(ref, inputRef) : ref;

		// eslint-disable-next-line no-nested-ternary
		const ButtonComponent = isThemed
			? ThemedButton
			: fg('jira_theming_milestone_1_fg')
				? Button
				: StyledButton;

		const countContent = (
			<>
				{selectedCount > 0 && (
					<CountWrapper isNested={isNested} data-testid={`${testId}-badge`}>
						{isThemed ? (
							<Box xcss={badgeStyles.root}>{selectedCount} </Box>
						) : (
							<Badge appearance="primary">{selectedCount}</Badge>
						)}
					</CountWrapper>
				)}
			</>
		);

		return (
			<Box xcss={[!isNested && !removeOuterPadding && horizontalPaddingStyles]}>
				<ContextualAnalyticsData
					sourceName="dropDownMenu"
					sourceType={SCREEN}
					attributes={{ filterType: label }}
				>
					<ButtonComponent
						{...popupProps}
						ref={conditionalRef}
						isSelected={isOpen || (isVisualRefreshEnabled() && selectedCount > 0)}
						shouldFitContainer={isNested}
						appearance={isVisualRefreshEnabled() && !isNested ? 'default' : 'subtle'}
						onMouseEnter={onMouseEnter}
						iconAfter={isVisualRefreshEnabled() && selectedCount > 0 ? undefined : IconComponent}
						isDisabled={isDisabled || false}
						{...(isThemed &&
							isNested &&
							fg('jira_theming_milestone_1_fg') && {
								css: {
									borderRadius: '0 !important',
									paddingRight: `${token('space.050')} !important`,
									'&:focus': {
										outlineOffset: '-2px',
									},
								},
							})}
						isNested={isNested} // TODO: remove this prop on jira_theming_milestone_1_fg cleanup
					>
						<Tooltip content={label} hideTooltipOnClick tag="span">
							{isVisualRefreshEnabled() && fg('visual-refresh-headers-buttons') ? (
								<Box
									xcss={selectedCount > 0 ? badgeStyles.triggerWithCount : badgeStyles.trigger}
									testId={testId}
									as="span"
								>
									<Box xcss={badgeStyles.textWrapper}>
										<Text
											{...(isOpen || selectedCount > 0
												? {
														color: 'inherit',
													}
												: {
														color: 'color.text.subtle',
													})}
											weight="medium"
										>
											{label}
										</Text>
									</Box>
									{countContent}
								</Box>
							) : (
								<TriggerContainer data-testid={testId}>
									<TriggerLabel>{label}</TriggerLabel>
									{countContent}
								</TriggerContainer>
							)}
						</Tooltip>
					</ButtonComponent>
				</ContextualAnalyticsData>
			</Box>
		);
	},
);

export default FilterListTrigger;

const horizontalPaddingStyles = xcss({
	paddingLeft: 'space.050',
	paddingRight: 'space.050',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const StyledButton = styled(Button)<{ isNested: boolean }>(({ isNested }) =>
	isNested
		? `
            border-radius: 0 !important;
            padding-right: ${gridSize / 2}px !important;
            &:focus {
                outline-offset: -2px;
            }
    `
		: '',
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CountWrapper = styled.span<{ isNested: boolean }>(
	{
		display: 'inline-block',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isNested }) =>
		isNested ? `margin-left: auto; padding-left: ${gridSize}px` : `padding-left: ${gridSize / 2}px`,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TriggerContainer = styled.span({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TriggerLabel = styled.span({
	maxWidth: `${gridSize * 15}px`,
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});
