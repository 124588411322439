import React, { useLayoutEffect, useRef, useMemo } from 'react';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useResizeObserver } from '@atlassian/jira-react-use-resize-observer/src/index.tsx';
import { useEvent } from '@atlassian/jira-software-react-use-event/src/index.tsx';
import { appendUnscheduledWorkSwimlaneIdSuffix } from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/utils.tsx';
import { useSoftwareProjectTheming } from '@atlassian/jira-project-theme-providers/src/controllers/software-theme/index.tsx';
import type { SwimlaneId } from '../../../../model/issue/issue-types.tsx';
import { useIsIncrementPlanningBoard } from '../../../../state/state-hooks/capabilities/index.tsx';
import ColumnsContainer from '../columns-container/index.tsx';
import { SwimlaneMoveToDoneButton } from '../swimlane-move-to-done/index.tsx';
import { IncrementPlanningSwimlane } from '../increment-planning-swimlane/index.tsx';
import Swimlane from '../swimlane/index.tsx';
import { useHasGroupingStyle } from '../../../../common/hooks/use-has-grouping-style/useHasGroupingStyle.tsx';
import { useBoardSelector } from '../../../../state/index.tsx';
import { getIsCMPBoard } from '../../../../state/selectors/software/software-selectors.tsx';

const VirtualizedSwimlane = ({
	id,
	index,
	top,
	measure,
	forceRemeasure,
	isLastSwimlane,
	isUnscheduledWorkColumnPanel,
}: {
	id: SwimlaneId;
	index: number;
	top: number;
	measure: (el: Element | null) => void;
	forceRemeasure: (el?: Element | null) => void;
	isLastSwimlane: boolean;
	isUnscheduledWorkColumnPanel: boolean;
}) => {
	const wrapperRef = useRef<HTMLDivElement>(null);
	const isCMPBoard = fg('jira_theming_milestone_1_fg')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useBoardSelector(getIsCMPBoard)
		: undefined;

	const onResize = useEvent(() => {
		forceRemeasure(wrapperRef.current);
	});
	useLayoutEffect(() => {
		if (wrapperRef.current) {
			measure(wrapperRef.current);
		}
	}, [wrapperRef, measure]);

	useResizeObserver({
		ref: wrapperRef,
		onResize,
	});
	const isIPBoard = useIsIncrementPlanningBoard();

	const swimlaneId = isUnscheduledWorkColumnPanel ? appendUnscheduledWorkSwimlaneIdSuffix(id) : id;

	const hasGrouping = useHasGroupingStyle();

	const { isSoftwareFullTheming } = useSoftwareProjectTheming();

	const customRightContent = useMemo(() => {
		if (!isIPBoard) {
			return <SwimlaneMoveToDoneButton id={id} />;
		}
		return null;
	}, [id, isIPBoard]);

	const swimlane = isIPBoard ? (
		<IncrementPlanningSwimlane
			id={swimlaneId}
			innerRef={wrapperRef}
			isUnscheduledWorkColumnPanel={isUnscheduledWorkColumnPanel}
			top={top}
		>
			<ColumnsContainer
				swimlaneId={id}
				isLastSwimlane={isLastSwimlane}
				offsetTop={top}
				isUnscheduledWorkColumn={isUnscheduledWorkColumnPanel}
			/>
		</IncrementPlanningSwimlane>
	) : (
		<Swimlane
			id={swimlaneId}
			key={swimlaneId}
			isFirstSwimlane={index === 0}
			wrapperStyle={{
				position: 'absolute',
				top,
				...(isSoftwareFullTheming
					? {
							boxSizing: 'border-box',
							padding: isCMPBoard ? `0 ${token('space.050')}` : `0 0 0 ${token('space.050')}`,
						}
					: {
							width: '100%',
						}),
				...(isSoftwareFullTheming &&
					(isCMPBoard ? { width: '100%' } : { width: `calc(100% - ${token('space.050')}` })),
			}}
			innerRef={wrapperRef}
			customRightContent={customRightContent}
			isMatrixLayout={hasGrouping}
			isThemed={isSoftwareFullTheming}
		>
			<ColumnsContainer
				swimlaneId={id}
				isLastSwimlane={isLastSwimlane}
				offsetTop={top}
				isUnscheduledWorkColumn={isUnscheduledWorkColumnPanel}
			/>
		</Swimlane>
	);

	return swimlane;
};

export default VirtualizedSwimlane;
