// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createStore,
	createContainer,
	type ContainerComponent,
	createHook,
} from '@atlassian/react-sweet-state';
import { STORE_NAME } from '../../common/constants.tsx';
import type { State, StoreContainerProps } from './types.tsx';
import { type Actions, actions } from './utils/actions.tsx';
import { initialState } from './utils/initial-state.tsx';

const Store = createStore<State, Actions>({
	// @ts-expect-error - TS2322 - Type '{ readonly status: { readonly hasFailed: false; readonly hasCompleted: false; readonly hasStarted: false; readonly hasTimedOut: false; }; readonly timeoutId: null; readonly eventsSuccess: readonly []; readonly eventsFail: readonly []; }' is not assignable to type 'State'.
	initialState,
	actions,
	name: STORE_NAME,
});

export const InsightsExperienceTrackerStoreContainer: ContainerComponent<StoreContainerProps> =
	createContainer<State, Actions, StoreContainerProps>(Store);

export const useInsightsExperienceTracker = createHook(Store);
