import type { Observable } from 'rxjs/Observable';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';

/**
 * Check if all the issue children are in final column
 */
export function issueChildrenInFinalColumnRest(
	parentId: IssueId,
	rapidViewId: string,
): Observable<{ issueId: IssueId; subtasksInFinalColumn: boolean }> {
	return fetchJson$(
		`/rest/greenhopper/1.0/xboard/issue/subtasksInFinalColumn.json?issueId=${parentId}&rapidViewId=${rapidViewId}&operation=IssueChildrenInFinalColumn`,
		{
			method: 'GET',
		},
	);
}
