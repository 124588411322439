/** @jsx jsx */
import React, { useEffect, useCallback, useMemo, useState } from 'react';
import isNil from 'lodash/isNil';
import { IconButton } from '@atlaskit/button/new';
import InfoIcon from '@atlaskit/icon/utility/migration/information--editor-panel';
import Image from '@atlaskit/image';
import Link from '@atlaskit/link';
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu';
import { cssMap, jsx } from '@atlaskit/css';
import { Box, Inline, Text, xcss } from '@atlaskit/primitives';
import type { FormatOptionLabelMeta, InputActionMeta } from '@atlaskit/select';
import VisuallyHidden from '@atlaskit/visually-hidden';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import HelpPanelButton from '@atlassian/jira-help-panel-button/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useActiveRightSidebarState } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/right-sidebar-controller/index.tsx';
import {
	DOC_LINKS,
	IPH_IDS,
} from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/constants.tsx';
import {
	getIssueLinksAnalytics,
	isScenarioIssue,
} from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/utils.tsx';
import commonMessages from '../../../../common/messages.tsx';
import {
	Action,
	ActionSubject,
	ToggleKey,
	IncrementPlanningAnalyticsKey,
} from '../../../../common/types/analytics.tsx';
import { ExpandableState, View } from '../../../../common/types/constant.tsx';
import type { IncrementPlanningBoardViewProps } from '../../../../common/types/menu.tsx';
import { ViewSetting } from '../../../../common/types/settings.tsx';
import {
	ViewSettingsSelect,
	type IssueOption,
} from '../../../../common/ui/view-settings-select/index.tsx';
import { ViewSettingsToggle } from '../../../../common/ui/view-settings-toggle/index.tsx';
import { useViewSettings } from '../../../../controllers/index.tsx';
import messages from './messages.tsx';

// Note: as part of the changes in issue_cards_in_program_board we moved the maxHeight to a parent wrapping div
// for the view settings menu. This has been moved back to the menuGroup when rolling out jpo_a11y_pb_fixes.
// The parent wrapping div and this style can be deleted when jpo_a11y_pb_fixes is being cleaned up.
const styles = cssMap({
	menuGroupWrapper: {
		maxHeight: '600px',
	},
});

const MAX_OPTIONS_LENGTH = 20;

const InfoIconWithTooltip = ({ tooltipContent }: { tooltipContent: string }) => (
	<Box xcss={infoIconWrapperStyles}>
		<IconButton
			icon={InfoIcon}
			label={tooltipContent}
			appearance="subtle"
			isTooltipDisabled={false}
			spacing="compact"
		/>
	</Box>
);

const MAX_SELECTED_ISSUES = 10;

export const IncrementPlanningBoardMenu = ({
	viewAs,
	boardId,
	isSwimlanesEnabled = false,
	epicCustomName = 'Epic',
	disableScrollbar = false, // hide scrollbar for storybook
	onHandleChange,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onSwimlaneExpandAll = () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onSwimlaneCollapseAll = () => {},
	dependencyIssueOptions = {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onUpdate = () => {},
	onClose,
	analyticsData,
}: IncrementPlanningBoardViewProps) => {
	const { formatMessage } = useIntl();
	const [state, actions] = useViewSettings();
	const rightPanelState = useActiveRightSidebarState();
	const issueOptions: IssueOption[] = useMemo(
		() =>
			Object.values(dependencyIssueOptions).map((issue) => ({
				value: issue.id,
				label: `${issue.key} ${issue.summary}`,
				iconUrl: issue.typeUrl,
			})),
		[dependencyIssueOptions],
	);
	const [slicedOptions, setSlicedOptions] = useState<IssueOption[]>(
		issueOptions.slice(0, MAX_OPTIONS_LENGTH),
	);

	useEffect(() => {
		if (!isNil(rightPanelState?.panelId)) {
			onUpdate();
		}
	}, [rightPanelState?.panelId, onUpdate]);

	const {
		setShowUnscheduledColumn,
		setShowOfftrackDependencyLines,
		setIssueIdsToShowDependencies,
		toggleViewSetting,
	} = actions;

	const onFormatOptionLabel = (
		option: IssueOption,
		formatOptionLabelMeta: FormatOptionLabelMeta<IssueOption>,
	) => {
		return (
			<Inline space="space.050" alignBlock="start">
				{option.iconUrl && (
					<Box xcss={formatOptionLabelMeta.context === 'menu' ? menuIconStyles : valueIconStyles}>
						<Image src={option.iconUrl} alt="" />
					</Box>
				)}
				<Box>{option.label}</Box>
			</Inline>
		);
	};

	const selectedIssueOptions: IssueOption[] = useMemo(() => {
		if (Array.isArray(state.issueIdsToShowDependencies)) {
			const tempDefaultIssueOptions: IssueOption[] = [];
			state.issueIdsToShowDependencies.forEach((issueId) => {
				const issue = dependencyIssueOptions[issueId];
				if (issue) {
					tempDefaultIssueOptions.push({
						value: issue.id,
						label: `${issue.key} ${issue.summary}`,
						iconUrl: issue.typeUrl,
					});
				}
			});
			return tempDefaultIssueOptions;
		}
		return [];
	}, [state.issueIdsToShowDependencies, dependencyIssueOptions]);

	const onInputChange = useCallback(
		(newValue: string, actionMeta: InputActionMeta) => {
			if (actionMeta.action === 'input-change') {
				if (newValue.trim().length === 0) {
					setSlicedOptions(issueOptions.slice(0, MAX_OPTIONS_LENGTH));
				} else {
					const filteredInOptions = issueOptions.filter((o) =>
						(o?.label || '').toLowerCase().includes(newValue.toLowerCase()),
					);
					setSlicedOptions(filteredInOptions.slice(0, MAX_OPTIONS_LENGTH));
				}
			}
		},
		[issueOptions, setSlicedOptions],
	);

	const getViewSettingsValidationMessage = () => {
		if (state.issueIdsToShowDependencies.length > MAX_SELECTED_ISSUES) {
			return formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? commonMessages.maxDependenciesErrorIssueTermRefresh
					: commonMessages.maxDependenciesError,
				{ maxDeps: MAX_SELECTED_ISSUES },
			);
		}

		return undefined;
	};

	const getMenuGroupMaxHeight = useMemo(() => {
		if (fg('jpo_a11y_pb_fixes')) {
			return { maxHeight: 600 };
		}
		return !disableScrollbar && !fg('issue_cards_in_program_board')
			? // when issue_cards_in_program_board is true, the maxHeight is moved to the parent div
				{ maxHeight: 500 }
			: {};
	}, [disableScrollbar]);

	const fields = (
		<>
			<ViewSettingsToggle
				testId="software-view-settings.ui.view-settings-menu.views.increment-planning-board.show-type-toggle"
				title={formatMessage(commonMessages.cardDetailIssueType)}
				onChange={() => {
					toggleViewSetting({
						boardId,
						view: View.INCREMENT_PLANNING_BOARD,
						setting: ViewSetting.SHOW_ISSUE_TYPE,
					});
					onHandleChange(ActionSubject.TOGGLE, {
						key: ToggleKey.SHOW_ISSUE_TYPE,
						state: !state.showIssueType,
					});
				}}
				isChecked={state.showIssueType}
				viewAs={viewAs}
			/>
			<ViewSettingsToggle
				testId="software-view-settings.ui.view-settings-menu.views.increment-planning-board.show-issue-key-toggle"
				title={formatMessage(commonMessages.cardDetailIssueKey)}
				onChange={() => {
					toggleViewSetting({
						boardId,
						view: View.INCREMENT_PLANNING_BOARD,
						setting: ViewSetting.SHOW_ISSUE_KEY,
					});
					onHandleChange(
						ActionSubject.TOGGLE,
						{
							key: ToggleKey.SHOW_ISSUE_KEY,
							state: !state.showIssueKey,
						},
						Action.CLICKED,
					);
				}}
				isChecked={state.showIssueKey}
				viewAs={viewAs}
			/>
			<ViewSettingsToggle
				testId="software-view-settings.ui.view-settings-menu.views.increment-planning-board.show-epics-toggle"
				title={epicCustomName}
				onChange={() => {
					toggleViewSetting({
						boardId,
						view: View.INCREMENT_PLANNING_BOARD,
						setting: ViewSetting.SHOW_EPICS,
					});
					onHandleChange(
						ActionSubject.TOGGLE,
						{
							key: ToggleKey.SHOW_EPICS_TOGGLE,
							state: !state.showEpics,
						},
						Action.CLICKED,
					);
				}}
				isChecked={state.showEpics}
				viewAs={viewAs}
			/>
			<ViewSettingsToggle
				testId="software-view-settings.ui.view-settings-menu.views.increment-planning-board.show-estimate-toggle"
				title={formatMessage(commonMessages.cardDetailEstimate)}
				onChange={() => {
					toggleViewSetting({
						boardId,
						view: View.INCREMENT_PLANNING_BOARD,
						setting: ViewSetting.SHOW_ESTIMATE,
					});
					onHandleChange(
						ActionSubject.TOGGLE,
						{
							key: ToggleKey.SHOW_ESTIMATE,
							state: !state.showEstimate,
						},
						Action.CLICKED,
					);
				}}
				isChecked={state.showEstimate}
				viewAs={viewAs}
			/>
			<ViewSettingsToggle
				testId="software-view-settings.ui.view-settings-menu.views.increment-planning-board.show-assignee-toggle"
				title={formatMessage(commonMessages.cardDetailAssignee)}
				onChange={() => {
					toggleViewSetting({
						boardId,
						view: View.INCREMENT_PLANNING_BOARD,
						setting: ViewSetting.SHOW_ASSIGNEE,
					});
					onHandleChange(
						ActionSubject.TOGGLE,
						{
							key: ToggleKey.SHOW_ASSIGNEE,
							state: !state.showAssignee,
						},
						Action.CLICKED,
					);
				}}
				isChecked={state.showAssignee}
				viewAs={viewAs}
			/>
			<ViewSettingsToggle
				testId="software-view-settings.ui.view-settings-menu.views.increment-planning-board.show-status-toggle"
				title={formatMessage(commonMessages.cardDetailStatus)}
				onChange={() => {
					toggleViewSetting({
						boardId,
						view: View.INCREMENT_PLANNING_BOARD,
						setting: ViewSetting.SHOW_STATUSES,
					});
					onHandleChange(
						ActionSubject.TOGGLE,
						{
							key: ToggleKey.SHOW_STATUSES_TOGGLE,
							state: !state.showStatuses,
						},
						Action.CLICKED,
					);
				}}
				isChecked={state.showStatuses}
				viewAs={viewAs}
			/>
			<ViewSettingsToggle
				testId="software-view-settings.ui.view-settings-menu.views.increment-planning-board.show-priority-toggle"
				title={formatMessage(commonMessages.cardDetailPriority)}
				onChange={() => {
					toggleViewSetting({
						boardId,
						view: View.INCREMENT_PLANNING_BOARD,
						setting: ViewSetting.SHOW_PRIORITY,
					});
					onHandleChange(
						ActionSubject.TOGGLE,
						{
							key: ToggleKey.SHOW_PRIORITY,
							state: !state.showPriority,
						},
						Action.CLICKED,
					);
				}}
				isChecked={state.showPriority}
				viewAs={viewAs}
			/>
		</>
	);

	const menuGroup = (
		<MenuGroup
			data-testId="software-view-settings.ui.view-settings-menu.menu-increment-planning-board"
			{...getMenuGroupMaxHeight}
		>
			<Section>
				<ViewSettingsToggle
					testId="software-view-settings.ui.view-settings-menu.views.increment-planning-board.show-unscheduled-column-toggle"
					title={formatMessage(commonMessages.showUnscheduledWork)}
					onChange={() => {
						setShowUnscheduledColumn(boardId, !state.showUnscheduledColumn);
						onHandleChange(
							ActionSubject.TOGGLE,
							{
								key: IncrementPlanningAnalyticsKey.UNSCHEDULED_COLUMN_TOGGLE,
								state: !state.showUnscheduledColumn,
							},
							Action.CLICKED,
						);
					}}
					isChecked={state.showUnscheduledColumn}
					viewAs={viewAs}
				/>
			</Section>
			{isSwimlanesEnabled && (
				<Section title={formatMessage(commonMessages.swimlanes)} hasSeparator>
					<ButtonItem
						data-testid="software-view-settings.ui.view-settings-menu.views.increment-planning-board.expand-all"
						onClick={() => {
							onSwimlaneExpandAll();
							onHandleChange(
								ActionSubject.BUTTON,
								{
									key: ToggleKey.SWIMLANE_EXPAND_ALL,
									state: ExpandableState.EXPANDED,
								},
								Action.CLICKED,
							);
						}}
					>
						{formatMessage(commonMessages.expandAll)}
					</ButtonItem>
					<ButtonItem
						data-testid="software-view-settings.ui.view-settings-menu.views.increment-planning-board.collapse-all"
						onClick={() => {
							onSwimlaneCollapseAll();
							onHandleChange(
								ActionSubject.BUTTON,
								{
									key: ToggleKey.SWIMLANE_COLLAPSE_ALL,
									state: ExpandableState.COLLAPSED,
								},
								Action.CLICKED,
							);
						}}
					>
						{formatMessage(commonMessages.collapseAll)}
					</ButtonItem>
				</Section>
			)}
			{
				<Section title={formatMessage(messages.dependencyLinesSection)} hasSeparator>
					<ViewSettingsToggle
						testId="software-view-settings.ui.view-settings-menu.views.increment-planning-board.offtrack-dependencies-toggle"
						title={formatMessage(messages.showOfftrackDependencyLines)}
						onChange={() => {
							setShowOfftrackDependencyLines(boardId, !state.showOfftrackDependencyLines);
							onHandleChange(
								ActionSubject.TOGGLE,
								{
									key: IncrementPlanningAnalyticsKey.SHOW_OFFTRACK_DEPENDENCY_LINES,
									state: !state.showOfftrackDependencyLines,
								},
								Action.CLICKED,
							);
						}}
						isChecked={state.showOfftrackDependencyLines}
						viewAs={viewAs}
						footer={
							<HelpPanelButton
								articleId={IPH_IDS.OFFTRACK_DEPENDENCIES}
								appearance="link"
								spacing="none"
								fallbackComponent={
									<Link href={DOC_LINKS.OFFTRACK_DEPENDENCIES} target="_blank">
										{formatMessage(messages.offtrackDependenciesHelpLink)}
									</Link>
								}
								onClick={(event) => {
									event.stopPropagation();
									onClose && onClose(event);
								}}
							>
								<Text color="color.text.accent.blue">
									{formatMessage(messages.offtrackDependenciesHelpLink)}
								</Text>
							</HelpPanelButton>
						}
					/>
					<Box xcss={selectWrapper}>
						<ViewSettingsSelect
							title={formatMessage(messages.showDependenciesOnIssues)}
							titleIcon={
								<InfoIconWithTooltip
									tooltipContent={formatMessage(
										fg('jira-issue-terminology-refresh-m3')
											? messages.onlyShowScheduledIssueDependencyLinesTooltipIssueTermRefresh
											: messages.onlyShowScheduledIssueDependencyLinesTooltip,
									)}
								/>
							}
							testId="software-view-settings.ui.view-settings-menu.views.increment-planning-board.dependency-lines-select"
							isMulti
							isSearchable
							options={slicedOptions}
							formatOptionLabel={onFormatOptionLabel}
							viewAs={viewAs}
							value={selectedIssueOptions}
							placeholder={formatMessage(
								fg('jira-issue-terminology-refresh-m3')
									? messages.chooseIssueIssueTermRefresh
									: messages.chooseIssue,
							)}
							isInvalid={state.issueIdsToShowDependencies.length > MAX_SELECTED_ISSUES}
							validationErrorMessage={getViewSettingsValidationMessage()}
							menuIsOpen={
								// undefined is default value for menuIsOpen prop where it's controlled by the click. Setting it to false will prevent the menu from opening
								state.issueIdsToShowDependencies.length <= MAX_SELECTED_ISSUES ? undefined : false
							}
							menuPlacement="auto" // menuPlacement is to ensure the dropdown is repositioned based on the window height
							onInputChange={onInputChange}
							onChange={(selectedOptions, actionMeta) => {
								let selectedIssueIds = [];
								if (Array.isArray(selectedOptions)) {
									selectedIssueIds = selectedOptions.map((option) => option.value);
								} else if (selectedOptions && 'value' in selectedOptions) {
									selectedIssueIds = selectedOptions ? [selectedOptions.value] : [];
								}
								setIssueIdsToShowDependencies(boardId, selectedIssueIds);

								const id =
									actionMeta.action === 'select-option'
										? actionMeta?.option?.value
										: actionMeta.removedValue?.value;
								const issue = analyticsData?.issuesWithLinksById[id ?? ''];
								const issueLinks = issue?.issueLinks;

								const attributes = getIssueLinksAnalytics(
									issueLinks,
									id,
									analyticsData?.issueLinkTypes,
									analyticsData?.issuesWithLinksById,
									analyticsData?.issueTypes,
								);

								onHandleChange(
									ActionSubject.SELECT,
									{
										key: IncrementPlanningAnalyticsKey.ISSUE_IDS_TO_SHOW_DEPENDENCIES,
										NumberOfCardDependencies: issue?.issueLinks?.length || 0,
										ToggledOn: actionMeta.action === 'select-option',
										isScenarioIssue: isScenarioIssue(id),
										...attributes,
									},
									Action.CHANGED,
								);
							}}
						/>
					</Box>
				</Section>
			}
			{fg('issue_cards_in_program_board') && (
				<Section title={formatMessage(commonMessages.cardFields)} hasSeparator>
					<fieldset data-testid="software-view-settings.ui.view-settings-menu.views.increment-planning-board.fieldset">
						<legend>
							<VisuallyHidden>{formatMessage(commonMessages.cardFields)}</VisuallyHidden>
						</legend>
						{fields}
					</fieldset>
				</Section>
			)}
		</MenuGroup>
	);

	return fg('issue_cards_in_program_board') ? (
		// Move menuGroup back into the div wrapper when cleaning up FG
		<div css={styles.menuGroupWrapper}>{menuGroup}</div>
	) : (
		menuGroup
	);
};

const menuIconStyles = xcss({
	marginTop: 'space.025',
	width: '16px',
	minWidth: '16px',
	height: '16px',
	minHeight: '16px',
});

const valueIconStyles = xcss({
	width: '16px',
	minWidth: '16px',
	height: '16px',
	minHeight: '16px',
});

const selectWrapper = xcss({
	width: '450px',
});

const infoIconWrapperStyles = xcss({
	height: '24px',
	display: 'flex',
	alignItems: 'center',
});
