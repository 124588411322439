/**
 * @generated SignedSource<<7bf9edb2c7b6d9d66214e51140f8e3c1>>
 * @relayHash 27527c08f2750b205f0c72f03077cd6e
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID fdad91f830d5bcb6a2dba58d5b2d28d9cceb4f17e31ee5115b4e9591b0f830ac

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { mainCompleteSprintMetadataQuery } from './mainCompleteSprintMetadataQuery.graphql';

const node: PreloadableConcreteRequest<mainCompleteSprintMetadataQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "fdad91f830d5bcb6a2dba58d5b2d28d9cceb4f17e31ee5115b4e9591b0f830ac",
    "metadata": {},
    "name": "mainCompleteSprintMetadataQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
