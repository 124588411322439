import { useCallback, useRef } from 'react';
import {
	creators,
	print,
	CLAUSE_TYPE_TERMINAL,
	COMPOUND_OPERATOR_AND,
	OPERATOR_EQUALS,
	OPERATOR_IN,
	OPERAND_EMPTY,
	OPERAND_TYPE_LIST,
	OPERAND_TYPE_VALUE,
	OPERAND_TYPE_KEYWORD,
	type Operand,
	type Clause,
} from '@atlaskit/jql-ast';
import { useAST, useClauseMap } from '../index.tsx';
import { UnsupportedRefinementError } from '../unsupported-refinement-error/index.tsx';

const getOperand = (selectedValue: string) => {
	if (selectedValue === OPERAND_EMPTY) {
		return creators.keywordOperand();
	}

	return creators.byText.valueOperand(selectedValue);
};

const getOperands = (selectedValues: string[]) =>
	selectedValues.map((selectedValue) => getOperand(selectedValue));

const getOperandValues = (operand?: Operand): string[] => {
	if (!operand) {
		return [];
	}

	if (operand.operandType === OPERAND_TYPE_LIST) {
		const values: string[] = [];

		for (let i = 0; i < operand.values.length; i++) {
			values.push(...getOperandValues(operand.values[i]));
		}

		return values;
	}

	if (operand.operandType === OPERAND_TYPE_VALUE) {
		return [operand.text];
	}

	if (operand.operandType === OPERAND_TYPE_KEYWORD) {
		return [operand.value];
	}

	return [operand.function.value];
};

const getInitialTotalCount = (clause?: Clause) => {
	if (clause?.clauseType === CLAUSE_TYPE_TERMINAL && clause.operand) {
		return getOperandValues(clause.operand).length;
	}

	return 0;
};

const createTerminalClause = (clauseName: string, selectedValues: string[]) =>
	creators.terminalClause(
		creators.field(clauseName),
		creators.operator(selectedValues.length > 1 ? OPERATOR_IN : OPERATOR_EQUALS),
		selectedValues.length > 1
			? creators.listOperand(getOperands(selectedValues))
			: getOperand(selectedValues[0]),
	);

export const useClauseUpdater = (clauseName: string) => {
	const ast = useAST();

	const clauseMap = useClauseMap();

	const clauses = clauseMap[clauseName.toLowerCase()];

	if (clauses && clauses.length > 1) {
		throw new UnsupportedRefinementError(`Too many clauses on update for ${clauseName}`);
	}

	const totalCount = useRef(getInitialTotalCount(clauses?.[0]));

	const addValueToClause = useCallback(
		(selectedValue?: string) => {
			if (!selectedValue) {
				return ast.represents;
			}

			if (clauses?.length) {
				const [clause] = clauses;

				if (clause.clauseType === CLAUSE_TYPE_TERMINAL && clause.operand) {
					const existingValues = getOperandValues(clause.operand);

					clause.replace(createTerminalClause(clauseName, [...existingValues, selectedValue]));
					totalCount.current = existingValues.length + 1;
				} else {
					clause.replace(createTerminalClause(clauseName, [selectedValue]));
					totalCount.current = 1;
				}
			} else {
				ast.query?.appendClause(
					createTerminalClause(clauseName, [selectedValue]),
					COMPOUND_OPERATOR_AND,
				);
				totalCount.current = 1;
			}

			return print(ast, {
				operatorCase: 'upper',
			});
		},
		[clauses, ast, clauseName],
	);

	const removeValueFromClause = useCallback(
		(selectedValue?: string) => {
			if (!selectedValue) {
				return ast.represents;
			}

			if (clauses?.length) {
				const [clause] = clauses;

				if (clause.clauseType === CLAUSE_TYPE_TERMINAL && clause.operand) {
					const existingValues = getOperandValues(clause.operand);

					const newSelectedValues = existingValues.filter((value) => value !== selectedValue);

					if (newSelectedValues.length) {
						clause.replace(createTerminalClause(clauseName, newSelectedValues));
						totalCount.current = newSelectedValues.length;
					} else {
						clause.remove();
						totalCount.current = 0;
					}
				} else {
					clause.remove();
					totalCount.current = 0;
				}
			} else {
				totalCount.current = 0;
			}

			return print(ast, {
				operatorCase: 'upper',
			});
		},
		[clauses, ast, clauseName],
	);

	const removeClause = useCallback(() => {
		if (clauses?.length) {
			const [clause] = clauses;

			clause.remove();
		}

		totalCount.current = 0;

		return print(ast, {
			operatorCase: 'upper',
		});
	}, [ast, clauses]);

	const getTotalCount = useCallback(() => {
		return totalCount.current;
	}, []);

	return { addValueToClause, removeValueFromClause, removeClause, getTotalCount };
};
