import React from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import InformationIcon from '@atlaskit/icon/core/information';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { token } from '@atlaskit/tokens';
import { expVal } from '@atlassian/jira-feature-experiments';
import AutoDismissFlag from '@atlassian/jira-flags/src/common/ui/components/ak-auto-dismiss-flag'; // ignore-for-ENGHEALTH-17759
import FlagGroup from '@atlassian/jira-flags/src/common/ui/components/group-flag'; // ignore-for-ENGHEALTH-17759
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import {
	ContextualAnalyticsData,
	fireUIAnalytics,
	MODAL,
} from '@atlassian/jira-product-analytics-bridge';
import message from './messages.tsx';

type Props = {
	numDoneCards?: number;
	resolvedIssuesUrl: string;
	onUndo: () => void;
	onDismiss: () => void;
	intl: IntlShape;
};

const UndoFlag = ({
	intl: { formatMessage },
	onUndo,
	onDismiss,
	numDoneCards,
	resolvedIssuesUrl,
}: Props) => (
	<ContextualAnalyticsData sourceName="flag" sourceType={MODAL}>
		<FlagGroup onDismissed={onDismiss}>
			<AutoDismissFlag
				messageId="software-board-clearing.common.ui.undo-flag.auto-dismiss-flag"
				messageType="transactional"
				id="software-board-clearing.ui.undo-flag"
				icon={
					<InformationIcon
						color={token('color.icon.discovery')}
						spacing="spacious"
						LEGACY_fallbackIcon={InfoIcon}
						LEGACY_primaryColor={token('color.icon.discovery')}
						label=""
					/>
				}
				actions={[
					{
						content: formatMessage(message.undo),
						onClick: (_, analyticsEvent: UIAnalyticsEvent) => {
							fireUIAnalytics(analyticsEvent, 'clearIssuesFlagUndoClearIssuesLink');
							onUndo();
						},
					},
					{
						content: formatMessage(
							expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
								? message.viewIssuesIssueTermRefresh
								: message.viewIssues,
						),
						href: resolvedIssuesUrl,
						onClick: (_, analyticsEvent: UIAnalyticsEvent) => {
							fireUIAnalytics(analyticsEvent, 'clearIssuesFlagViewIssuesLink');
						},
					},
				]}
				title={formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? message.titleIssueTermRefresh
						: message.title,
					{ numDoneCards },
				)}
				description={formatMessage(message.description, { numDoneCards })}
			/>
		</FlagGroup>
	</ContextualAnalyticsData>
);

export default injectIntl(UndoFlag);
