/** @jsx jsx */
import React, { type ReactNode } from 'react';
import { styled, jsx } from '@compiled/react';
import type { PreloadedQuery } from 'react-relay';
import { Box, xcss, media, Flex } from '@atlaskit/primitives';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import type { boardFilterRefinementQuery } from '@atlassian/jira-relay/src/__generated__/boardFilterRefinementQuery.graphql.ts';
import type { viewBoardCustomFiltersQuery } from '@atlassian/jira-relay/src/__generated__/viewBoardCustomFiltersQuery.graphql';
import { ImportWorkButton } from '@atlassian/jira-software-onboarding-invites/src/ui/import-work-button/async.tsx';
import { useShowQuickFilters } from '@atlassian/jira-software-view-settings/src/controllers/index.tsx';
import { StandupControlButtons } from '@atlassian/jira-standups/src/ui/control-buttons/index.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { useSoftwareProjectTheming } from '@atlassian/jira-project-theme-providers/src/controllers/software-theme/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import ErrorComponent from '@atlassian/jira-filter-refinement/src/ui/error/index.tsx';
import { Capability } from '../../../common/capability/index.tsx';
import { useIsEmbedView } from '../../../common/hooks/use-is-embed-view/index.tsx';
import {
	useCapability,
	useIsCMPBoard,
	useIsIncrementPlanningBoard,
	useIsJSWBoard,
	useIsJSMBoard,
} from '../../../state/state-hooks/capabilities/index.tsx';
import InsightsButton from '../insights-button/index.tsx';
import { shouldDisableStandup } from '../utils.tsx';
import { shouldDisplayFilterRefinement } from '../../../common/utils/should-display-filter-refinement/index.tsx';
import { BoardFilterRefinement } from './board-filter-refinement/index.tsx';
import FilterBar from './filter-bar/index.tsx';
import FilterSelectionBar from './filter-selection-bar/index.tsx';
import { FullscreenButton } from './fullscreen-button/index.tsx';
import SwimlaneSwitch from './swimlane-switch/index.tsx';
import ViewSettings from './view-settings/index.tsx';

export type Props = {
	isSprintsEnabled: boolean;
	hasActiveSprint: boolean;
	rapidViewId: number;
	customFiltersQueryRef: PreloadedQuery<viewBoardCustomFiltersQuery> | null | undefined;
	boardFilterRefinementQueryRef?: PreloadedQuery<boardFilterRefinementQuery> | null;
	leftHandSideActions?: ReactNode;
	children?: ReactNode;
	showCardDetails?: boolean;
	boardType?: string;
	isActiveStandup: boolean;
	setIsActiveStandup: React.Dispatch<React.SetStateAction<boolean>>;
};

const ControlsBar = ({
	rapidViewId,
	isSprintsEnabled,
	hasActiveSprint,
	customFiltersQueryRef,
	boardFilterRefinementQueryRef,
	leftHandSideActions,
	showCardDetails = true,
	isActiveStandup,
	setIsActiveStandup,
	boardType,
	children,
}: Props) => {
	const appEditions = useAppEditions();
	const showInsightsButton = !isSprintsEnabled || hasActiveSprint;
	const isCMPBoard = useIsCMPBoard();
	const isJSWBoard = useIsJSWBoard();
	const isJSMBoard = useIsJSMBoard();
	const isIncrementPlanningBoard = useIsIncrementPlanningBoard();
	const isFilterBarVisible = useShowQuickFilters();
	const isTabNavigation = useCapability(Capability.IS_TAB_NAVIGATION);
	const shouldRenderFullscreenMenuItem = useCapability(
		Capability.RENDER_BOARD_FULLSCREEN_MENU_ITEM,
	);
	const shouldRenderFullscreenButton = useCapability(Capability.RENDER_BOARD_FULLSCREEN_BUTTON);
	const isEmbedView = useIsEmbedView();
	const { isSoftwareFullTheming } = useSoftwareProjectTheming();

	const isStandupDisabled = shouldDisableStandup({ boardType, isSprintsEnabled, hasActiveSprint });

	const shouldShowNewRefinementBar =
		shouldDisplayFilterRefinement({
			isIncrementPlanningBoard,
			isCMPBoard,
			isJSMBoard,
			isJSWBoard,
		}) && expVal('filter_refinement_in_tmp_board', 'isEnabled', false);

	const shouldRenderGroupBy = () => {
		/**
		 * Increment Planning board only has one swimlane grouping - Team (rendered by default).
		 * We don't allow users to switch to a different grouping therefore we're not rendering the SwimlaneSwitcher.
		 */
		if (isIncrementPlanningBoard) {
			return false;
		}

		return true;
	};

	/*
	 * lg and above -> filter bar grows, actions do not shrink or grow. Actions at full size.
	 * will adapt to screen size by putting things into more.
	 * md -> lg: filter bar has basis of 70%, actions shrink and wrap onto two lines (with small buttons grouped together) if they can't fit in the remaining space.
	 * sm and below -> actions grow and wraps above the filter bar. Filter bar and actions grow.
	 */
	if (isTabNavigation) {
		return (
			<Box xcss={outerBoxStylesNewStyles}>
				<Box xcss={combinedFilterAndActionStyles} testId="software-board.header.controls-bar">
					<Box xcss={filterBarWrapperStyles}>
						{shouldShowNewRefinementBar ? (
							boardFilterRefinementQueryRef && (
								<ErrorBoundaryWrapper>
									<BoardFilterRefinement
										boardFilterRefinementQueryRef={boardFilterRefinementQueryRef}
									/>
								</ErrorBoundaryWrapper>
							)
						) : (
							/* @ts-expect-error - TS2741 - Property 'screenName' is missing in type '{ customFiltersQueryRef: PreloadedQuery<viewBoardCustomFiltersQuery, Record<string, unknown>> | null | undefined; }' */
							<FilterBar
								isActiveStandup={isActiveStandup}
								customFiltersQueryRef={customFiltersQueryRef}
							/>
						)}

						{isFilterBarVisible && isCMPBoard && <FilterSelectionBar />}
					</Box>
					{isVisualRefreshEnabled() && fg('visual-refresh_drop_6') ? (
						<Box xcss={rightSideActionsStyles}>
							<Flex gap="space.100" justifyContent="end" alignItems="center">
								{leftHandSideActions}
								{shouldRenderGroupBy() && <SwimlaneSwitch />}
								<ImportWorkButton
									{...(fg('jira_theming_milestone_1_fg') && {
										isThemed: isSoftwareFullTheming,
									})}
								/>

								{showInsightsButton && (
									<InsightsButton
										boardId={rapidViewId}
										edition={appEditions.software}
										isSprintsEnabled={isSprintsEnabled}
									/>
								)}

								{!shouldRenderFullscreenMenuItem &&
									shouldRenderFullscreenButton &&
									!isEmbedView && (
										<FullscreenButton
											boardId={Number(rapidViewId)}
											shouldRenderContent={!isActiveStandup}
										/>
									)}
								{!isIncrementPlanningBoard &&
									isJSWBoard &&
									isActiveStandup &&
									expVal('jira-in-product-standups', 'is_enabled', false) && (
										<StandupControlButtons
											isStandupDisabled={isStandupDisabled}
											isActiveStandup={isActiveStandup}
											setIsActiveStandup={setIsActiveStandup}
											boardId={Number(rapidViewId)}
										/>
									)}
								{!isActiveStandup && <ViewSettings showCardDetails={showCardDetails} />}
							</Flex>
							{!isActiveStandup && children}
						</Box>
					) : (
						<Box xcss={rightSideActionsStyles}>
							{shouldRenderGroupBy() && <SwimlaneSwitch />}
							{leftHandSideActions}
							<Flex gap="space.100" justifyContent="end" alignItems="center">
								<ImportWorkButton
									{...(fg('jira_theming_milestone_1_fg') && {
										isThemed: isSoftwareFullTheming,
									})}
								/>
								{showInsightsButton && (
									<InsightsButton
										boardId={rapidViewId}
										edition={appEditions.software}
										isSprintsEnabled={isSprintsEnabled}
									/>
								)}
								{!shouldRenderFullscreenMenuItem &&
									shouldRenderFullscreenButton &&
									!isEmbedView && (
										<FullscreenButton
											boardId={Number(rapidViewId)}
											shouldRenderContent={!isActiveStandup}
										/>
									)}
								{!isIncrementPlanningBoard &&
									isJSWBoard &&
									expVal('jira-in-product-standups', 'is_enabled', false) && (
										<StandupControlButtons
											isStandupDisabled={isStandupDisabled}
											isActiveStandup={isActiveStandup}
											setIsActiveStandup={setIsActiveStandup}
											boardId={Number(rapidViewId)}
										/>
									)}
								{!isActiveStandup && <ViewSettings showCardDetails={showCardDetails} />}
							</Flex>

							{!isActiveStandup && children}
						</Box>
					)}
				</Box>
			</Box>
		);
	}

	return (
		<Box xcss={styles}>
			<Flex
				gap="space.100"
				justifyContent="end"
				alignItems="center"
				testId="software-board.header.controls-bar"
			>
				<FilterBarWrapper>
					{shouldShowNewRefinementBar ? (
						boardFilterRefinementQueryRef && (
							<ErrorBoundaryWrapper>
								<BoardFilterRefinement
									boardFilterRefinementQueryRef={boardFilterRefinementQueryRef}
								/>
							</ErrorBoundaryWrapper>
						)
					) : (
						/* @ts-expect-error - TS2741 - Property 'screenName' is missing in type '{ customFiltersQueryRef: PreloadedQuery<viewBoardCustomFiltersQuery, Record<string, unknown>> | null | undefined; }' */
						<FilterBar
							isActiveStandup={isActiveStandup}
							customFiltersQueryRef={customFiltersQueryRef}
						/>
					)}
				</FilterBarWrapper>
				{shouldRenderGroupBy() && <SwimlaneSwitch />}
				{leftHandSideActions}
				<ImportWorkButton
					{...(fg('jira_theming_milestone_1_fg') && {
						isThemed: isSoftwareFullTheming,
					})}
				/>
				{showInsightsButton && (
					<InsightsButton
						boardId={rapidViewId}
						edition={appEditions.software}
						isSprintsEnabled={isSprintsEnabled}
					/>
				)}
				<ViewSettings showCardDetails={showCardDetails} />
				{children}
			</Flex>
			{isFilterBarVisible && isCMPBoard && <FilterSelectionBar />}
		</Box>
	);
};

const ErrorBoundaryWrapper = ({ children }: { children: ReactNode }) => (
	<JSErrorBoundary
		id="board-filter-refinement"
		packageName="software-filters"
		teamName="a4t-tanuki"
		sendToPrivacyUnsafeSplunk
		// eslint-disable-next-line jira/react-no-inline-function-prop
		fallback={() => <ErrorComponent />}
	>
		{children}
	</JSErrorBoundary>
);

export default ControlsBar;
const outerBoxStylesNewStyles = xcss({ marginBottom: 'space.200' });
const styles = xcss({ marginBottom: 'space.400' });

const filterBarWrapperStyles = xcss({
	flex: '1 1 auto',
	display: 'flex',
	flexDirection: 'column',
	// minimum width required for expanded search + More button + clear filters
	minWidth: '468px',
	justifyContent: 'end',
});

const rightSideActionsStyles = xcss({
	display: 'flex',
	flex: '1 0 auto',
	gap: 'space.100',
	justifyContent: 'flex-end',
	alignItems: 'start',
});

const combinedFilterAndActionStyles = xcss({
	display: 'flex',
	justifyContent: 'space-between',
	gap: 'space.100',
	flexWrap: 'wrap-reverse',
	alignItems: 'stretch',
	[media.above.lg]: {
		flexWrap: 'nowrap',
	},
});

// If updating this component, also update filterBarWrapperStyles
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FilterBarWrapper = styled.div({
	flex: 1,
	minWidth: 0,
});
