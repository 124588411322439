import { useCallback } from 'react';
import { useUserHasConfluenceAccess } from '@atlassian/jira-confluence-integration-controls/src/controllers/use-user-has-confluence-access/index.tsx';
import { useLicensedProducts } from '@atlassian/jira-tenant-context-controller/src/components/licensed-products/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useRetros } from '../index.tsx';

export const useShowCreateRetroOrCrossSellModal = () => {
	const [retrosState, retrosActions] = useRetros();
	const licensedProducts = useLicensedProducts();
	const { hasAccess } = useUserHasConfluenceAccess();

	return useCallback(() => {
		if (!retrosState.shouldCreateRetro) {
			return;
		}

		let action = retrosActions.setLoadCreateRetroModal;

		if (
			licensedProducts.confluence &&
			!hasAccess &&
			fg('jira_sprint_completion_cross_join_confluence')
		) {
			action = retrosActions.setLoadCrossJoinModal;
		}

		action(true);
	}, [licensedProducts, hasAccess, retrosState.shouldCreateRetro, retrosActions]);
};
