import 'rxjs/add/operator/concatMap';
import type { Observable } from 'rxjs/Observable';
import { catchError, flatMap } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { from } from 'rxjs/observable/from';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import {
	contextPathSelector,
	rapidViewIdSelector,
} from '../../state/selectors/software/software-selectors.tsx';
import type { Action, ActionsObservable, MiddlewareAPI } from '../../state/types.tsx';
import {
	UPDATE_OLD_DONE_ISSUES_CUTOFF,
	updateOldDoneIssuesCutoffRequestSuccess,
	updateOldDoneIssuesCutoffRequestFailure,
} from '../../state/actions/board/old-done-issues-cutoff/index.tsx';
import { updateOldDoneIssuesCutoffService } from '../../services/board/old-done-issues-cutoff-graphql/index.tsx';
import { REFRESH_SOURCE_OLD_DONE_ISSUE_CUTOFF_CHANGED } from '../../model/constants.tsx';
import { workRefreshData } from '../../state/actions/work/index.tsx';

export const boardUpdateOldDoneIssuesCutoffEpic = (
	action$: ActionsObservable,
	store: MiddlewareAPI,
): Observable<Action> =>
	action$.ofType(UPDATE_OLD_DONE_ISSUES_CUTOFF).concatMap((action) => {
		const state = store.getState();
		const boardId = rapidViewIdSelector(state);
		const contextPath = contextPathSelector(state);

		const {
			payload: oldDoneIssuesCutoff,
			meta: { optimistic, analyticsEvent },
		} = action;

		return updateOldDoneIssuesCutoffService(contextPath, boardId, oldDoneIssuesCutoff).pipe(
			flatMap(() => {
				if (analyticsEvent) {
					fireOperationalAnalytics(analyticsEvent, 'OldDoneIssuesCutoffUpdate Success', {
						boardId,
						oldDoneIssuesCutoff,
					});
				}
				return from([
					updateOldDoneIssuesCutoffRequestSuccess(optimistic.id),
					workRefreshData(REFRESH_SOURCE_OLD_DONE_ISSUE_CUTOFF_CHANGED),
				]);
			}),
			catchError((error) => {
				if (analyticsEvent) {
					fireOperationalAnalytics(analyticsEvent, 'OldDoneIssuesCutoffUpdate Failed', {
						error,
						boardId,
						oldDoneIssuesCutoff,
					});
				}
				return of(updateOldDoneIssuesCutoffRequestFailure(optimistic.id));
			}),
		);
	});
