import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';

/**
 * Use the router to determine if we are on a CMP board.
 */
export const useIsCMPBoardRoute = (): boolean => {
	const { name: routeName } = useCurrentRoute();

	return ['rapidboard-board', 'rapidboard-board-embed', 'rapidboard-user-board'].includes(
		routeName,
	);
};

/**
 * Use the router to determine if we are on a user board
 */
export const useIsUserBoardRoute = (): boolean => {
	const { name: routeName } = useCurrentRoute();

	return routeName === 'rapidboard-user-board';
};
