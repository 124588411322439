import { useMemo } from 'react';
import { useRegisterNudgeTarget } from '@atlassiansox/nudge-tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { SHOULD_SHOW_PERSONALIZED_SAMPLE_DATA_FOR_KANBAN } from '@atlassian/jira-onboarding-core/src/constants.tsx';
import { sessionStorageProvider } from '@atlassian/jira-onboarding-core/src/utils/index.tsx';
import { useKeepIccOpenState } from '@atlassian/jira-software-onboarding-keep-icc-open/src/controllers/index.tsx';
import { BOARD_CONTAINER_NUDGE_ID } from '@atlassian/jira-software-onboarding-nudges--next/src/common/constants.tsx';

export const usePersonalizedSampleData = () => {
	const isModalClosed = useKeepIccOpenState();
	const { ref: sampleProjectDataNudgeRef } = useRegisterNudgeTarget<HTMLDivElement>(
		BOARD_CONTAINER_NUDGE_ID,
		fg('jfind-personalized-sample-data-kill-switch'),
	);

	const result = useMemo(() => {
		if (fg('jfind-personalized-sample-data-kill-switch') === false || isModalClosed === false) {
			return {
				sampleProjectDataNudgeRef,
				shouldShowSampleProjectDataNudge: false,
			};
		}

		const shouldShowSampleProjectDataNudge = sessionStorageProvider.get(
			SHOULD_SHOW_PERSONALIZED_SAMPLE_DATA_FOR_KANBAN,
		);

		return {
			sampleProjectDataNudgeRef,
			shouldShowSampleProjectDataNudge,
		};
	}, [isModalClosed, sampleProjectDataNudgeRef]);

	return result;
};
