import React, { useCallback } from 'react';
import {
	PortalledNudge as PortalledNudgeCommon,
	type PortalledNudgeProps,
} from '@atlassiansox/nudge-tooltip';
import { layers as akLayers } from '@atlaskit/theme/constants';
import type { MaybeChoreographedComponentProps } from '@atlassian/jira-choreographer-services/src/types.tsx';
import { shouldBeChoreographed } from '@atlassian/jira-choreographer-services/src/utils.tsx';
import { withChoreographedRender } from '@atlassian/jira-choreographer-services/src/withChoreographedRender.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';

type JiraPortalledNudgeProps = PortalledNudgeProps & {
	nudgeIdForAnalytics?: string;
};
const PortalledNudgeInternal = ({
	onRender,
	nudgeTargetId,
	nudgeIdForAnalytics,
	analyticsAttributes = {},
	...props
}: JiraPortalledNudgeProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onRenderNudge = useCallback(() => {
		const event = createAnalyticsEvent({});

		fireUIAnalytics(
			event,
			'nudgeSpotlightCard viewed',
			nudgeIdForAnalytics ?? nudgeTargetId,
			analyticsAttributes,
		);
		if (onRender) {
			onRender();
		}
	}, [createAnalyticsEvent, nudgeIdForAnalytics, nudgeTargetId, analyticsAttributes, onRender]);

	return (
		<PortalledNudgeCommon
			onRender={onRenderNudge}
			nudgeTargetId={nudgeTargetId}
			zIndex={akLayers.dialog()}
			{...props}
		/>
	);
};

const PortalledNudgeWithChoreograph =
	withChoreographedRender<JiraPortalledNudgeProps>(PortalledNudgeInternal);

// disabledChoreographedRender: nth nudge in a nudge tour should be set this parameter as true.
/**
 * @ messageType : explicit to pass "engagement" will enable choreograph render
 */
export const PortalledNudge = (
	props: JiraPortalledNudgeProps & Partial<MaybeChoreographedComponentProps>,
) => {
	const { messageType, ...restProps } = props;
	const { nudgeTargetId } = restProps;
	if (shouldBeChoreographed({ messageType })) {
		return <PortalledNudgeWithChoreograph messageId={nudgeTargetId} {...props} />;
	}
	return <PortalledNudgeInternal {...restProps} />;
};
