import React, { useEffect, useRef } from 'react';
import { useRegisterNudgeTarget } from '@atlassiansox/nudge-tooltip';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import {
	useHasNudgeActive,
	useNudgeStep,
} from '@atlassian/jira-onboarding-quickstart-core/src/services/index.tsx';
import { TAILOR_BOARD_COLUMN_NUDGE_ID } from '@atlassian/jira-onboarding-core/src/constants.tsx';
import { ERROR_BOUNDARY_ID_JWM_BOARD_TOUR_NUDGE } from '../../common/constants.tsx';
import type { WrapperProps } from '../../common/types.tsx';
import { tailorBoardColumnsNudgeEntryPoint } from './entrypoint.tsx';

const runtimeProps = {};
const entryPointParams = {};

const TailorBoardColumnsAsyncOld = ({ children }: WrapperProps) => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		tailorBoardColumnsNudgeEntryPoint,
		entryPointParams,
	);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
	}, [entryPointActions]);

	const { ref: tailorBoardColumnsNudgeRef } = useRegisterNudgeTarget<HTMLDivElement>(
		TAILOR_BOARD_COLUMN_NUDGE_ID,
		true,
	);

	return (
		<>
			<div ref={tailorBoardColumnsNudgeRef}>{children}</div>
			<JiraEntryPointContainer
				id={ERROR_BOUNDARY_ID_JWM_BOARD_TOUR_NUDGE}
				packageName="jiraBoardOnboarding"
				entryPointReferenceSubject={entryPointReferenceSubject}
				fallback={<></>}
				runtimeProps={runtimeProps}
			/>
		</>
	);
};

const TailorBoardColumnsAsyncNew = ({ children }: WrapperProps) => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		tailorBoardColumnsNudgeEntryPoint,
		entryPointParams,
	);

	const [nudgeStep] = useNudgeStep();
	const [hasNudgeActive] = useHasNudgeActive();
	const firstLoadRef = useRef(true);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		if (firstLoadRef.current && hasNudgeActive && nudgeStep === TAILOR_BOARD_COLUMN_NUDGE_ID) {
			entryPointActions.load();
			firstLoadRef.current = false;
		}
	}, [entryPointActions, hasNudgeActive, nudgeStep]);

	const { ref: tailorBoardColumnsNudgeRef } = useRegisterNudgeTarget<HTMLDivElement>(
		TAILOR_BOARD_COLUMN_NUDGE_ID,
		true,
	);

	return (
		<>
			<div ref={tailorBoardColumnsNudgeRef}>{children}</div>
			<JiraEntryPointContainer
				id={ERROR_BOUNDARY_ID_JWM_BOARD_TOUR_NUDGE}
				packageName="jiraBoardOnboarding"
				entryPointReferenceSubject={entryPointReferenceSubject}
				fallback={<></>}
				runtimeProps={runtimeProps}
			/>
		</>
	);
};

export const TailorBoardColumnsAsync = componentWithCondition(
	() => fg('tailored_onboarding_m3_gate'),
	TailorBoardColumnsAsyncNew,
	TailorBoardColumnsAsyncOld,
);

export const TailorBoardColumnsAsyncChildless = () => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		tailorBoardColumnsNudgeEntryPoint,
		entryPointParams,
	);

	const [nudgeStep] = useNudgeStep();
	const [hasNudgeActive] = useHasNudgeActive();
	const firstLoadRef = useRef(true);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		if (firstLoadRef.current && hasNudgeActive && nudgeStep === TAILOR_BOARD_COLUMN_NUDGE_ID) {
			entryPointActions.load();
			firstLoadRef.current = false;
		}
	}, [entryPointActions, hasNudgeActive, nudgeStep]);

	return (
		<>
			<JiraEntryPointContainer
				id={ERROR_BOUNDARY_ID_JWM_BOARD_TOUR_NUDGE}
				packageName="jiraBoardOnboarding"
				entryPointReferenceSubject={entryPointReferenceSubject}
				fallback={<></>}
				runtimeProps={runtimeProps}
			/>
		</>
	);
};
