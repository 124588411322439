import React, { useCallback, type ChangeEvent, useRef, useMemo } from 'react';
import Search from '@atlaskit/icon/core/search';
import { Box, xcss } from '@atlaskit/primitives';
import Textfield from '@atlaskit/textfield';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useASTActions, useAST, useTextSearchInputClause } from '../../controllers/ast/index.tsx';
import { TEXT_FIELDS } from '../../common/constants.tsx';
import { ClearButton } from './clear-button/index.tsx';
import KeyboardShortcuts from './keyboard-shortcuts/index.tsx';
import { update, getValue } from './utils.tsx';

type Props = {
	/**
	 * unique string identifying the input label for screen reader users and used for placeholder text
	 */
	inputLabel: string;
	/**
	 * `true` when the input should be disabled e.g. when search result is still loading
	 */
	isDisabled?: boolean;
};

const SEARCH_FIELD_WIDTH = 160;

const SearchField = ({ inputLabel, isDisabled = false }: Props) => {
	const ast = useAST();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const textSearchInputClause = useTextSearchInputClause();
	const { updateJql } = useASTActions();
	const searchRef = useRef<HTMLInputElement>(null);
	const value = useMemo(() => getValue(textSearchInputClause) || '', [textSearchInputClause]);

	const handleSearch = useCallback(
		(newSearchValue: string) => {
			const updatedSearchString = update(ast, textSearchInputClause, newSearchValue);
			updateJql({ jql: updatedSearchString, fieldType: TEXT_FIELDS });
		},
		[ast, textSearchInputClause, updateJql],
	);

	const handleChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			handleSearch(event.target.value);
		},
		[handleSearch],
	);

	const handleClear = useCallback(() => {
		handleSearch('');
		searchRef.current?.focus();
	}, [handleSearch]);

	const handleFocus = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), {
			action: 'focused',
			actionSubject: 'input',
			actionSubjectId: 'search',
			attributes: { keyboardShortcut: 'false' },
		});
	}, [createAnalyticsEvent]);

	return (
		<>
			<KeyboardShortcuts searchRef={searchRef} />
			<Textfield
				isCompact
				onMouseDown={handleFocus}
				ref={searchRef}
				value={value}
				aria-label={inputLabel}
				placeholder={inputLabel}
				onChange={handleChange}
				elemAfterInput={value !== '' ? <ClearButton onClear={handleClear} /> : null}
				elemBeforeInput={
					<Box xcss={iconStyle}>
						<Search color="currentColor" label="" spacing="none" LEGACY_size="small" />
					</Box>
				}
				width={SEARCH_FIELD_WIDTH}
				testId="filter-refinement.ui.search-field"
				isDisabled={isDisabled}
			/>
		</>
	);
};

export default SearchField;

const iconStyle = xcss({
	display: 'inline-flex',
	marginLeft: 'space.075',
});
