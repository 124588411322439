import type { Observable } from 'rxjs/Observable';
import fetchText$ from '@atlassian/jira-fetch/src/utils/as-text-stream.tsx';

export function addToSprint(
	issueKey: string,
	sprintId: number,
	rapidViewId: number,
): Observable<string | null> {
	return fetchText$('/rest/greenhopper/1.0/sprint/rank?operation=AddToSprint', {
		method: 'PUT',
		body: JSON.stringify({
			addToBacklog: false,
			sprintId,
			rapidViewId,
			calculateNewIssuesOrder: false,
			idOrKeys: [issueKey],
		}),
	});
}
