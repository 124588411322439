// delete when `icc_context_to_function_component` is removed
import React, { createContext, useContext, Component, type ReactNode } from 'react';
import noop from 'lodash/noop';
import type { FieldKey, FieldValue } from '../../common/types.tsx';
import type { IccContextType } from '../context/types.tsx';

type Props = {
	children: ReactNode;
};

const IccContextOld = createContext<IccContextType>({
	initialSummary: '',
	initialCardTypeId: null,
	initialFieldValues: new Map(),
	initialProjectId: null,
	onChangeSummary: noop,
	onChangeCardType: noop,
	onChangeFieldValue: noop,
	onChangeProject: noop,
	onCancel: noop,
	onCreate: noop,
});

const useIccContext = () => useContext(IccContextOld);

type State = {
	initialSummary: string;
	initialCardTypeId: number | string | null;
	initialProjectId: number | null;
	initialFieldValues: Map<FieldKey, FieldValue>;
};

// eslint-disable-next-line jira/react/no-class-components
class IccProvider extends Component<Props, State> {
	state = {
		initialSummary: '',
		initialCardTypeId: null,
		initialFieldValues: new Map(),
		initialProjectId: null,
	};

	onChangeSummary = (summary: string) => {
		this.setState({ initialSummary: summary });
	};

	onChangeProject = (projectId: number) => {
		this.setState({ initialProjectId: projectId });
	};

	onChangeCardType = (cardTypeId: number | string | null) => {
		this.setState({ initialCardTypeId: cardTypeId });
	};

	onChangeFieldValue = (fieldKey: FieldKey, fieldValue: FieldValue) => {
		this.setState(({ initialFieldValues }) => ({
			initialFieldValues: initialFieldValues.set(fieldKey, fieldValue),
		}));
	};

	onCancel = () => {
		this.setState({ initialSummary: '', initialCardTypeId: null });
	};

	onCreate = () => {
		this.setState({ initialSummary: '' });
	};

	render() {
		return (
			<IccContextOld.Provider
				value={{
					initialSummary: this.state.initialSummary,
					initialCardTypeId: this.state.initialCardTypeId,
					initialFieldValues: this.state.initialFieldValues,
					initialProjectId: this.state.initialProjectId,
					onChangeCardType: this.onChangeCardType,
					onChangeSummary: this.onChangeSummary,
					onChangeFieldValue: this.onChangeFieldValue,
					onChangeProject: this.onChangeProject,
					onCancel: this.onCancel,
					onCreate: this.onCreate,
				}}
			>
				{this.props.children}
			</IccContextOld.Provider>
		);
	}
}

export { IccContextOld, IccProvider, useIccContext };
