/** @jsx jsx */
import { useRef, useEffect, type ReactNode } from 'react';
import Portal from '@atlaskit/portal';
import { token } from '@atlaskit/tokens';
import { Manager, Reference } from '@atlaskit/popper';
import { cssMap, jsx } from '@atlaskit/css';
import { JiraPopper as Popper } from '@atlassian/jira-popper/src/ui/jira-popper.tsx';

const styles = cssMap({
	popperReference: {
		width: '100%',
	},
	cardContainer: {
		backgroundColor: token('elevation.surface.sunken'),
	},
});
const HEIGHT_PADDING = 4;
export const CardPopper = ({
	shouldRenderPopper = true,
	cardHeight,
	children,
}: {
	shouldRenderPopper?: boolean;
	cardHeight?: number;
	children: ReactNode;
}) => {
	const triggerRef = useRef<HTMLDivElement>(null);
	const contentRef = useRef<HTMLDivElement>(null);
	const contentHeight = contentRef.current?.offsetHeight;
	const triggerHeight = contentHeight ? contentHeight + HEIGHT_PADDING : cardHeight;
	const popperUpdateRef = useRef<(() => void) | null>(null);
	const triggerTop = triggerRef.current?.getBoundingClientRect().top;
	useEffect(() => {
		// Update content popper position when trigger position changes, for example when ICC in the same column opens and closes
		const timeoutId = setTimeout(() => {
			if (popperUpdateRef.current) {
				popperUpdateRef.current();
			}
		}, 100);
		return () => clearTimeout(timeoutId);
	}, [triggerTop]);

	if (!shouldRenderPopper) {
		return children;
	}

	return (
		<Manager>
			<Reference>
				{({ ref }) => {
					return (
						<div ref={ref}>
							<div
								ref={triggerRef}
								css={styles.popperReference}
								// need to match the height of the card content as it might grow when inline edit is active
								// eslint-disable-next-line jira/react/no-style-attribute
								style={{ height: triggerHeight ? `${triggerHeight}px` : 'auto' }}
							/>
						</div>
					);
				}}
			</Reference>

			<Popper
				messageId="portfolio-3-plan-increment-common.ui.card-popper.popper"
				messageType="transactional"
				placement="bottom-start"
				offset={[0, -1 * (triggerHeight || 0)]}
				// It is intentional to disable preventOverflow and flip modifiers so the card is not repositioned when it's partially outside the viewport
				modifiers={[
					{
						name: 'preventOverflow',
						enabled: false,
					},
					{
						name: 'flip',
						enabled: false,
					},
				]}
			>
				{({ ref, style, placement, isReferenceHidden, update }) => {
					popperUpdateRef.current = update;
					if (isReferenceHidden) {
						return children;
					}

					const triggerWidth = triggerRef?.current?.offsetWidth;
					return (
						<Portal>
							<div
								ref={ref}
								// eslint-disable-next-line jira/react/no-style-attribute
								style={{
									// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
									...style,
									// match the width of the trigger element, which will be the column width
									width: triggerWidth ? `${triggerWidth}px` : 'auto',
								}}
								css={styles.cardContainer}
								data-placement={placement}
							>
								<div ref={contentRef}>{children}</div>
							</div>
						</Portal>
					);
				}}
			</Popper>
		</Manager>
	);
};

export default CardPopper;
