import React, {
	useEffect,
	useRef,
	useState,
	useCallback,
	useMemo,
	useContext,
	type FocusEvent,
	type ReactNode,
} from 'react';
import { styled } from '@compiled/react';
import isNil from 'lodash/isNil';
import debounce from 'lodash/debounce';
import { Box } from '@atlaskit/primitives';
import { ProgramBoardParentSwitcherEntrypointContext } from '@atlassian/jira-software-program-board-parent-switcher/entrypoint-context.tsx';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { HIDDEN } from '@atlassian/jira-platform-inline-card-create/src/common/constants.tsx';
import { UNSCHEDULED_COLUMN_ID } from '@atlassian/jira-common-constants/src/column-types.tsx';
import { useIccContext } from '@atlassian/jira-platform-inline-card-create/src/services/context/index.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { CardPopper } from '@atlassian/jira-portfolio-3-plan-increment-common/src/ui/card-popper/index.tsx';
import type { CardId } from '../../../../../model/card/card-types.tsx';
import type { ColumnId } from '../../../../../model/column/column-types.tsx';
import type { SwimlaneId } from '../../../../../model/swimlane/swimlane-types.tsx';
import { setActiveIssueWithIcc } from '../../../../../state/actions/card/index.tsx';
import { useBoardSelector, useBoardStore, useBoardDispatch } from '../../../../../state/index.tsx';
import {
	getCardEditAssignee,
	getActiveInlineEditCardIds,
} from '../../../../../state/selectors/card/card-selectors.tsx';
import {
	isActiveCardSelector,
	shouldRenderIcc,
} from '../../../../../state/selectors/work/work-selectors.tsx';
import {
	useIsCMPBoard,
	useIsIncrementPlanningBoard,
} from '../../../../../state/state-hooks/capabilities/index.tsx';
import InlineCardCreate from '../../inline-card-create/index.tsx';
import { ICCPlaceholder } from '../../inline-card-create/styled.tsx';
import {
	getActiveElement,
	useIccRef,
	isIccIntersectingWithHeader,
	useShouldRenderCardInPopper,
} from './util/index.tsx';

export type Props = {
	isColumnIccEnabled: boolean;
	isLastSwimlane: boolean;
	canShowIccNudge: boolean;
	id: IssueId;
	cardIndex: number;
	columnId: ColumnId;
	swimlaneId: SwimlaneId | null | undefined;
	forceIccRender: boolean;
	onIssueChange: () => void;
	children: ReactNode;
	groupParentId?: CardId;
	headerRef: React.RefObject<HTMLDivElement>;
	isScrolling?: boolean;
};

const renderRichCardDelay = 200;
export const CardWithIcc = (props: Props) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const { entryPointActions: programBoardParentSwitcherEntrypointActions } = useContext(
		ProgramBoardParentSwitcherEntrypointContext,
	);
	const iccRef = useIccRef();
	const isIPBoard = useIsIncrementPlanningBoard();
	const isUnscheduledColumn = props.columnId === UNSCHEDULED_COLUMN_ID;
	const isIPBoardScheduledColumns = isIPBoard && !isUnscheduledColumn;
	const { initialProjectId: projectId } = useIccContext();
	const pendingHoverAction = useRef<ReturnType<typeof setTimeout> | null>(null);
	const hoverDebounce = useRef<ReturnType<typeof debounce> | null>(null);
	const hasRenderedIcc: {
		current: boolean;
	} = useRef(false);

	const {
		isColumnIccEnabled,
		id,
		cardIndex,
		columnId,
		swimlaneId,
		isLastSwimlane,
		children = null,
		onIssueChange,
		canShowIccNudge,
		forceIccRender,
		groupParentId,
		headerRef,
		isScrolling = false,
	} = props;
	const isCMPBoard = useIsCMPBoard();
	const boardStore = useBoardStore();
	const dispatch = useBoardDispatch();
	const shouldRenderCardInPopper = useShouldRenderCardInPopper({
		columnId,
		cardId: id,
		isScrolling,
	});
	const isActualIcc = useBoardSelector((state) => shouldRenderIcc(state, { issueId: id }));
	const isAssigneeEditing = Boolean(useBoardSelector((state) => getCardEditAssignee(state)(id)));
	const activeInlineEditCardIds = useBoardSelector(getActiveInlineEditCardIds);
	const isCardInInlineEditing = activeInlineEditCardIds.includes(String(id));
	const isCardActive = isActiveCardSelector(boardStore.getState(), { issueId: id });
	const [isHovered, setIsHovered] = useState(false);
	const [isTabFocused, setIsTabFocused] = useState(false);

	useEffect(
		() => () => {
			// Clean up timer
			if (pendingHoverAction.current != null) {
				clearTimeout(pendingHoverAction.current);
			}

			if (fg('render_program_board_card_in_popup')) {
				if (hoverDebounce.current != null) {
					hoverDebounce.current.cancel();
				}
				setIsHovered(false);
				setIsTabFocused(false);
			}
		},
		[],
	);
	useEffect(() => {
		// Reset is hovered if the conditions to render card in popper is failed
		if (!shouldRenderCardInPopper && isHovered && fg('render_program_board_card_in_popup')) {
			setIsHovered(false);
		}
	}, [shouldRenderCardInPopper, isHovered]);

	const onKeyboardTab = useCallback(
		(event: React.KeyboardEvent) => {
			if (
				isIPBoardScheduledColumns &&
				event.key === 'Tab' &&
				fg('render_program_board_card_in_popup')
			) {
				setIsTabFocused(true);
			}
		},
		[isIPBoardScheduledColumns],
	);

	const onMouseOver = useCallback(() => {
		if (isIPBoardScheduledColumns && fg('render_program_board_card_in_popup')) {
			setIsTabFocused(false);
		}
		// Set hover state for IP board scheduled columns
		if (shouldRenderCardInPopper && !isHovered && fg('render_program_board_card_in_popup')) {
			hoverDebounce.current = debounce(() => {
				setIsHovered(true);
			}, renderRichCardDelay);
			hoverDebounce.current();
		}
		// Early return if card is rich already
		if (isCardActive) {
			return;
		}
		// Delay setting the richActiveCard to avoid unneccessary renders when scrolling or moving the mouse around
		pendingHoverAction.current = setTimeout(() => {
			dispatch(setActiveIssueWithIcc(id));
		}, renderRichCardDelay);

		if (isIPBoard && fg('fix-parent_flick_on_hovering_program_board')) {
			programBoardParentSwitcherEntrypointActions.load();
		}
	}, [
		id,
		dispatch,
		shouldRenderCardInPopper,
		isHovered,
		isCardActive,
		isIPBoardScheduledColumns,
		programBoardParentSwitcherEntrypointActions,
		isIPBoard,
	]);

	const onFocus = useCallback(() => {
		dispatch(setActiveIssueWithIcc(id));
		if (isIPBoard && fg('fix-parent_flick_on_hovering_program_board')) {
			programBoardParentSwitcherEntrypointActions.load();
		}
	}, [dispatch, id, isIPBoard, programBoardParentSwitcherEntrypointActions]);

	const onMouseLeave = useCallback(() => {
		// Clear hover state for IP board scheduled columns
		if (isHovered && fg('render_program_board_card_in_popup')) {
			setIsHovered(false);
			if (hoverDebounce.current) {
				hoverDebounce.current.cancel();
				hoverDebounce.current = null;
			}
		}
		const containerElement = containerRef.current;
		if (!containerElement) {
			return;
		}
		// Remove pending setActiveIssueWithIcc if it hasn't already fired
		if (pendingHoverAction.current) {
			clearTimeout(pendingHoverAction.current);
			pendingHoverAction.current = null;
		}

		const activeElement = getActiveElement();
		// Ignore event if focused on a child
		if (activeElement instanceof Node && containerElement.contains(activeElement)) {
			return;
		}

		dispatch(setActiveIssueWithIcc(null));
	}, [containerRef, dispatch, isHovered]);

	const onBlur = useCallback(
		(e: FocusEvent<HTMLElement>) => {
			const { relatedTarget } = e;
			if (
				containerRef.current &&
				relatedTarget instanceof Node &&
				!containerRef.current.contains(relatedTarget)
			) {
				dispatch(setActiveIssueWithIcc(null));
			}
		},
		[dispatch],
	);

	if (isActualIcc && !hasRenderedIcc.current) {
		hasRenderedIcc.current = true;
	}

	const canUseIcc = isIPBoard ? !isNil(id) : typeof id === 'number' && id > 0;

	const iccIntersectingWithHeader =
		isIPBoard && isIccIntersectingWithHeader(headerRef, iccRef, cardIndex === 0);

	const shouldRenderIccAbove =
		canUseIcc &&
		!iccIntersectingWithHeader &&
		(cardIndex === 0 || forceIccRender || isActualIcc || hasRenderedIcc.current);

	const icc =
		isColumnIccEnabled && shouldRenderIccAbove ? (
			<InlineCardCreate
				canShowIccNudge={canShowIccNudge}
				columnId={columnId}
				swimlaneId={swimlaneId}
				cardIndex={cardIndex}
				triggerAppearance={HIDDEN}
				// TODO-BOARD-REFACTOR-TYPES
				// @ts-expect-error id is string but insertBefore is wanting a number
				insertBefore={id}
				isLastSwimlane={isLastSwimlane}
				onChangeFormVisible={onIssueChange}
				// TODO-BOARD-REFACTOR-TYPES
				// @ts-expect-error groupParentId is string but inline-card-create is wanting a number
				groupParentId={groupParentId}
				// TODO-BOARD-REFACTOR-TYPES
				// @ts-expect-error projectID expects number but it is number | null
				projectId={projectId}
			/>
		) : (
			<ICCPlaceholder />
		);

	// Render card in a popup on mouse hover, or if any of the inline edit is active.
	// Currently we don't support render card in popup on keyboard focus. A ticket in the backlog has been created to support this https://hello.jira.atlassian.cloud/browse/JPO-31230
	const shouldRenderPopper = useMemo(() => {
		const value =
			shouldRenderCardInPopper &&
			((isHovered && isCardActive) || (isCardInInlineEditing && !isTabFocused));
		return value;
	}, [shouldRenderCardInPopper, isHovered, isCardActive, isCardInInlineEditing, isTabFocused]);

	const onIccHover = () => {
		setIsHovered(false);
	};
	const ipBoardContent = fg('render_program_board_card_in_popup') ? (
		<>
			<Box ref={iccRef} onMouseOver={onIccHover}>
				{icc}
			</Box>
			<CardPopper shouldRenderPopper={shouldRenderPopper}>{children}</CardPopper>
		</>
	) : (
		<>
			<Box ref={iccRef}>{icc}</Box>
			{children}
		</>
	);
	const boardContent = (
		<>
			{icc}
			{children}
		</>
	);

	return (
		<CardWithICC
			raiseCardForPopup={
				fg('remove_zindex_on_cardwithicc_when_editing_inline') ? false : isAssigneeEditing
			}
			data-testid="software-board.board-container.board.card-container.card-with-icc"
			onFocus={onFocus}
			onBlur={onBlur}
			onKeyDown={onKeyboardTab}
			ref={containerRef}
			{...(!isCMPBoard
				? { onMouseOver, onMouseLeave }
				: { onMouseEnter: onMouseOver, onMouseLeave })}
		>
			{isIPBoard ? ipBoardContent : boardContent}
		</CardWithICC>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardWithICC = styled.div<{ raiseCardForPopup: boolean }>({
	display: 'block',
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	zIndex: (props) => (props.raiseCardForPopup ? layers.card : 'auto'),
});
