import { useSoftwareProjectTheming } from '@atlassian/jira-project-theme-providers/src/controllers/software-theme/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useBoardSelector } from '../../../state/index.tsx';
import { getSwimlaneMode } from '../../../state/selectors/swimlane/swimlane-mode-selectors.tsx';

export const useHasGroupingStyle = () => {
	const { hasSoftwareTheming } = useSoftwareProjectTheming();
	const swimlaneMode = useBoardSelector(getSwimlaneMode);

	if (!fg('jira_theming_milestone_1_fg') || !hasSoftwareTheming) {
		return false;
	}

	if (!!swimlaneMode && swimlaneMode !== 'NO_SWIMLANE') {
		return true;
	}
	return false;
};
