// eslint-disable-next-line jira/restricted/react
import React, { PureComponent, type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import AkButton from '@atlaskit/button';
import { LinkButton } from '@atlaskit/button/new';
import SearchIcon from '@atlaskit/icon/core/search';
import SearchIconOld from '@atlaskit/icon/glyph/search';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { layout } from '../../../common/constants/styles/index.tsx';
import clearDoneIssuesMessages from './messages.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	textAlign: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	padding: `${layout.gutter}px`,
});

type Props = {
	url: string;
	isCMPBoard?: boolean;
	onClick: () => void;
	intl: IntlShape;
};

// eslint-disable-next-line jira/react/no-class-components
export class DoneCardsButton extends PureComponent<Props> {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onClick = (e: SyntheticEvent<any>, analyticsEvent: UIAnalyticsEvent) => {
		fireUIAnalytics(analyticsEvent, 'findClearedIssues');
		this.props.onClick();
	};

	renderButton = (intl: IntlShape) => {
		const oldIssuesSearchLink = expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
			? clearDoneIssuesMessages.oldIssuesSearchLinkIssueTermRefresh
			: clearDoneIssuesMessages.oldIssuesSearchLink;

		const findDoneClearedCardsLink = expVal(
			'issue-terminology-refresh-m2-replace',
			'isEnabled',
			false,
		)
			? clearDoneIssuesMessages.findDoneClearedCardsLinkIssueTermRefresh
			: clearDoneIssuesMessages.findDoneClearedCardsLink;

		const message = intl.formatMessage(
			this.props.isCMPBoard ? oldIssuesSearchLink : findDoneClearedCardsLink,
		);

		if (isVisualRefreshEnabled() && fg('visual-refresh_drop_3')) {
			return (
				<LinkButton
					href={this.props.url}
					iconBefore={SearchIcon}
					appearance="subtle"
					spacing="compact"
					onClick={this.onClick}
				>
					{message}
				</LinkButton>
			);
		}

		return (
			<AkButton
				appearance="subtle"
				spacing="compact"
				href={this.props.url}
				onClick={this.onClick}
				iconBefore={
					<SearchIcon
						label=""
						color={token('color.text.subtle')}
						LEGACY_fallbackIcon={SearchIconOld}
						LEGACY_size="small"
					/>
				}
			>
				{message}
			</AkButton>
		);
	};

	render = () => {
		const { intl } = this.props;
		const oldIssuesSearchMessage = expVal(
			'issue-terminology-refresh-m2-replace',
			'isEnabled',
			false,
		)
			? clearDoneIssuesMessages.oldIssuesSearchMessageIssueTermRefresh
			: clearDoneIssuesMessages.oldIssuesSearchMessage;

		const doneClearedCardsTooltip = expVal(
			'issue-terminology-refresh-m2-replace',
			'isEnabled',
			false,
		)
			? clearDoneIssuesMessages.doneClearedCardsTooltipIssueTermRefresh
			: clearDoneIssuesMessages.doneClearedCardsTooltip;

		return (
			<Container>
				<Tooltip
					content={intl.formatMessage(
						this.props.isCMPBoard ? oldIssuesSearchMessage : doneClearedCardsTooltip,
					)}
				>
					{this.renderButton(intl)}
				</Tooltip>
			</Container>
		);
	};
}

export default injectIntl(DoneCardsButton);
