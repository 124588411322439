import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { FIELD_TYPE_MAP } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/constants.tsx';
import { FLAGGED_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import {
	type MenuNode,
	MenuNodeType,
} from '@atlassian/jira-software-context-menu/src/common/types.tsx';
import { showIssueFlagWithCommentModal } from '../../../../../../../../state/actions/issue/flag-with-comment-modal/index.tsx';
import { useBoardActionCreator, useBoardSelector } from '../../../../../../../../state/index.tsx';
import { isIssueFlagged } from '../../../../../../../../state/selectors/issue/issue-selectors.tsx';
import { FLAG_MENU_ID } from '../../constants.tsx';
import messages from '../../messages.tsx';
import { useMenuItemWithStartAnalytics } from '../../utils.tsx';
import type { Props, ContentProps } from './types.tsx';

export const useFlagContent = ({
	onToggleFlag,
	isFlagged,
	selectedCardIds,
}: ContentProps): MenuNode[] => {
	const { formatMessage } = useIntl();

	if (fg('one_event_rules_them_all_fg')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		return useMenuItemWithStartAnalytics({
			fieldKey: FIELD_TYPE_MAP[FLAGGED_CF_TYPE],
			fieldType: FLAGGED_CF_TYPE,
			shouldFireAnalytics: selectedCardIds?.length === 1,
			menuItemProps: {
				label: isFlagged ? formatMessage(messages.removeFlag) : formatMessage(messages.addFlag),
				id: FLAG_MENU_ID,
				onClick: onToggleFlag,
			},
			firstSelectedCardId:
				selectedCardIds && fg('one_event_improvements_1') ? selectedCardIds[0] : undefined,
		});
	}

	return [
		{
			type: MenuNodeType.Item,
			label: isFlagged ? formatMessage(messages.removeFlag) : formatMessage(messages.addFlag),
			id: FLAG_MENU_ID,
			onClick: onToggleFlag,
		},
	];
};

export const useFlag = ({ issueId, selectedCardIds }: Props) => {
	const isFlagged = useBoardSelector((state) => isIssueFlagged(state, issueId));

	const onToggleFlag = useBoardActionCreator(() => showIssueFlagWithCommentModal(issueId));

	return useFlagContent({
		onToggleFlag,
		isFlagged,
		...(fg('one_event_rules_them_all_fg') && { selectedCardIds }),
	});
};
