/**
 * @generated SignedSource<<8825b425ddfa9c72c6bf5aa39e568e93>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type assigneePicker_filterRefinement$data = {
  readonly jira: {
    readonly jqlBuilder: {
      readonly fieldValues: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly __typename: string;
            readonly displayName: string;
            readonly jqlTerm: string;
            readonly user?: {
              readonly picture: AGG$URL;
            } | null | undefined;
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "assigneePicker_filterRefinement";
};
export type assigneePicker_filterRefinement$key = {
  readonly " $data"?: assigneePicker_filterRefinement$data;
  readonly " $fragmentSpreads": FragmentRefs<"assigneePicker_filterRefinement">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cloudId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scope"
    }
  ],
  "kind": "Fragment",
  "name": "assigneePicker_filterRefinement",
  "selections": [
    {
      "concreteType": "JiraQuery",
      "kind": "LinkedField",
      "name": "jira",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "cloudId",
              "variableName": "cloudId"
            }
          ],
          "concreteType": "JiraJqlBuilder",
          "kind": "LinkedField",
          "name": "jqlBuilder",
          "plural": false,
          "selections": [
            {
              "args": [
                {
                  "kind": "Literal",
                  "name": "first",
                  "value": 100
                },
                {
                  "kind": "Literal",
                  "name": "jqlTerm",
                  "value": "assignee"
                },
                {
                  "kind": "Variable",
                  "name": "scope",
                  "variableName": "scope"
                }
              ],
              "concreteType": "JiraJqlFieldValueConnection",
              "kind": "LinkedField",
              "name": "fieldValues",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraJqlFieldValueEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "name": "__typename"
                        },
                        {
                          "kind": "ScalarField",
                          "name": "jqlTerm"
                        },
                        {
                          "kind": "ScalarField",
                          "name": "displayName"
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "name": "user",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "ScalarField",
                                  "name": "picture"
                                }
                              ]
                            }
                          ],
                          "type": "JiraJqlUserFieldValue"
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};

(node as any).hash = "808045c54ef090c458c64924643ed710";

export default node;
