import React, { useCallback, useContext } from 'react';
import Tooltip from '@atlaskit/tooltip';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { ThemedButtonNew } from '@atlassian/jira-project-theme-components/src/ui/themed-button-new/ThemedButtonNew.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIsFullscreen } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/fullscreen/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import {
	fireUIAnalytics,
	FireScreenAnalytics,
	SCREEN,
	type AnalyticsEvent,
	ContextualAnalyticsData,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { View } from '@atlassian/jira-software-view-settings/src/common/types/constant.tsx';
import { useViewSettingsActions } from '@atlassian/jira-software-view-settings/src/controllers/index.tsx';
import type { StandupActions, StandupButtonRenderProps } from '../../../common/types.tsx';
import { StandupEntryPointContext } from '../../../controllers/context-provider/index.tsx';
import messages from './messages.tsx';

type StartStandupButtonProps = StandupActions & {
	isStandupDisabled: boolean;
	boardId: number;
	render?: (props: StandupButtonRenderProps) => JSX.Element;
};

export const StartStandupButton = ({
	setIsActiveStandup,
	isStandupDisabled,
	boardId,
	render,
}: StartStandupButtonProps) => {
	const { formatMessage } = useIntl();
	const { entryPointActions } = useContext(StandupEntryPointContext);
	const triggerRef = useEntryPointButtonTrigger(entryPointActions);
	const { setIsFullscreen: setIsFullscreenNav3 } = useViewSettingsActions();
	const [, { setIsFullscreen }] = useIsFullscreen();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const enterFullscreen = useCallback(() => {
		getWillShowNav4()
			? setIsFullscreen(true)
			: setIsFullscreenNav3({ boardId, isFullscreen: true, view: View.BOARD });
	}, [boardId, setIsFullscreen, setIsFullscreenNav3]);

	const handleStartStandupOld = (analyticsEvent: AnalyticsEvent) => {
		fireUIAnalytics(analyticsEvent, 'startStandup');
		enterFullscreen();
		setIsActiveStandup(true);
	};

	const handleStartStandup = () => {
		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: 'button',
				action: 'clicked',
				source: `jiraStandupsStartStandupButton${SCREEN}`,
			}),
			'startStandup',
		);
		enterFullscreen();
		setIsActiveStandup(true);
	};

	if (render && isVisualRefreshEnabled() && fg('visual-refresh_drop_6')) {
		return (
			<ContextualAnalyticsData sourceName="jiraStandupsStartStandupButton" sourceType={SCREEN}>
				{render({
					isDisabled: isStandupDisabled,
					ref: triggerRef,
					onClick: handleStartStandup,
					label: formatMessage(messages.startStandupButton),
				})}
				<FireScreenAnalytics attributes={{ isStandupDisabled }} />
			</ContextualAnalyticsData>
		);
	}

	return (
		<ContextualAnalyticsData sourceName="jiraStandupsStartStandupButton" sourceType={SCREEN}>
			<Tooltip content={isStandupDisabled ? formatMessage(messages.disabledButtonTooltip) : null}>
				<ThemedButtonNew
					isDisabled={isStandupDisabled}
					ref={triggerRef}
					onClick={(__, analyticsEvent: AnalyticsEvent) => handleStartStandupOld(analyticsEvent)}
				>
					{formatMessage(messages.startStandupButton)}
				</ThemedButtonNew>
			</Tooltip>
			<FireScreenAnalytics attributes={{ isStandupDisabled }} />
		</ContextualAnalyticsData>
	);
};
