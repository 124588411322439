import React, { useCallback } from 'react';
import { ButtonItem } from '@atlaskit/menu';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import { setReturnToProjectUrl } from '@atlassian/jira-navigation-apps-sidebar-project-utils/src/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import messages from './messages.tsx';

type Props = Intl & {
	url: string;
};
export const ConfigureBoardItem = ({ intl, url }: Props) => {
	const { push } = useRouterActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onClick = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: 'menuItem',
				action: 'clicked',
			}),
			'clickConfigureBoardItem',
		);
		setReturnToProjectUrl();
		push(url);
	}, [push, url, createAnalyticsEvent]);

	return <ButtonItem onClick={onClick}>{intl.formatMessage(messages.displayText)}</ButtonItem>;
};

export default injectIntl(ConfigureBoardItem);
