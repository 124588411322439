import {
	COMPOUND_OPERATOR_AND,
	COMPOUND_OPERATOR_OR,
	creators,
	type Jast,
	OPERATOR_EQUALS,
	OPERATOR_LIKE,
	print,
} from '@atlaskit/jql-ast';
import { ISSUE_KEY, TEXT_FIELDS } from '../../common/constants.tsx';
import {
	isIssueKeyValue,
	TextSearchInputVisitor,
} from '../../controllers/ast/text-search-input-visitor/index.tsx';
import type { TextSearchInputClauseType } from '../../controllers/ast/types.tsx';

export const wrapWithQuotes = (text: string) => `"${text.replace(/"/g, '\\"')}"`;

// TODO: escapeBackslashes serves as a temporary patch to convert jqlTerm into a format suitable for creator API.
// EM-6563 should resolve this by updating creator API to accept the semantic JQL value for jqlTerm currently returned by hydrateJqlQuery.
export const escapeBackslashes = (text: string) => text.replace(/\\/g, '\\\\');

/**
 * Check if search input clause is valid
 */
export const validate = (compoundClause: TextSearchInputClauseType): boolean => {
	if (!compoundClause) {
		return true;
	}
	try {
		compoundClause.accept(new TextSearchInputVisitor());
		return true;
	} catch (e) {
		return false;
	}
};

export const getValue = (textSearchInputClause: TextSearchInputClauseType) => {
	if (!textSearchInputClause) {
		return undefined;
	}
	return textSearchInputClause.accept(new TextSearchInputVisitor());
};

const createTextSearchInputClause = ({
	quotedKeyword,
	isIssueKey,
}: {
	quotedKeyword: string;
	isIssueKey: boolean;
}): NonNullable<TextSearchInputClauseType> => {
	const textSearchInputClause = creators.terminalClause(
		creators.field(TEXT_FIELDS),
		creators.operator(OPERATOR_LIKE),
		creators.byText.valueOperand(quotedKeyword),
	);

	if (isIssueKey) {
		const issueKeyClause = creators.terminalClause(
			creators.field(ISSUE_KEY),
			creators.operator(OPERATOR_EQUALS),
			creators.byText.valueOperand(quotedKeyword),
		);

		return creators.compoundClause(creators.compoundOperator(COMPOUND_OPERATOR_OR), [
			textSearchInputClause,
			issueKeyClause,
		]);
	}

	return textSearchInputClause;
};

/**
 * Update search input clause with new value
 */
export const update = (
	ast: Jast,
	textSearchInputClause: TextSearchInputClauseType,
	val: string,
): string => {
	if (!validate(textSearchInputClause)) {
		return ast.represents;
	}

	if (val === undefined || val === null || val === '') {
		if (textSearchInputClause) {
			textSearchInputClause.remove();
		} else {
			return ast.represents;
		}
	} else {
		const isIssueKey = isIssueKeyValue(val);
		const quotedKeyword = wrapWithQuotes(escapeBackslashes(val));
		const newTextSearchInputClause = createTextSearchInputClause({ quotedKeyword, isIssueKey });

		if (textSearchInputClause) {
			textSearchInputClause.replace(newTextSearchInputClause);
		} else {
			ast.query?.appendClause(newTextSearchInputClause, COMPOUND_OPERATOR_AND);
		}
	}
	return print(ast, {
		operatorCase: 'upper',
	});
};
