import { chain, freeze, set, setIn, unset } from 'icepick';
import union from 'lodash/union';
import { ColumnType } from '@atlassian/jira-common-constants/src/column-types.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import { SUBTASK_DND_TYPE } from '../../../../model/constants.tsx';
import { ASSIGNEE, STORY } from '../../../../model/swimlane/swimlane-types.tsx';
import { entityArrayToMap } from '../../../../services/software/software-data-transformer.tsx';
import {
	CARD_CLEAR,
	CARD_DATA_SET,
	CARD_DELETE,
	type CardDataSetAction,
	type CardDeleteAction,
	type CardClearAction,
} from '../../../actions/card/index.tsx';
import {
	COLUMN_DELETE_REQUEST,
	type ColumnDeleteRequestAction,
} from '../../../actions/column/delete/index.tsx';
import type { Action } from '../../../actions/index.tsx';
import {
	SET_ISSUE_CHILDREN,
	type SetIssueChildrenAction,
} from '../../../actions/issue-children/index.tsx';
import {
	ISSUE_ADD_LABELS,
	type IssueAddLabelsAction,
} from '../../../actions/issue/add-label-modal/index.tsx';
import {
	type AssignToMeRequestAction,
	ASSIGN_TO_ME_REQUEST,
} from '../../../actions/issue/assign-to-me/index.tsx';
import {
	ISSUE_CREATE_REQUEST,
	ISSUE_CREATE_SUCCESS,
	type IssueCreateRequestAction,
	type IssueCreateSuccessAction,
} from '../../../actions/issue/create/index.tsx';
import {
	ISSUE_DELETE_REQUEST,
	type IssueDeleteRequestAction,
} from '../../../actions/issue/delete/index.tsx';
import {
	DEV_STATUS_LOAD_SUCCESS,
	type DevStatusLoadSuccessAction,
} from '../../../actions/issue/dev-status/index.tsx';
import {
	ISSUE_FLAG_WITH_COMMENT_REQUEST,
	type IssueFlagWithCommentRequestAction,
} from '../../../actions/issue/flag-with-comment/index.tsx';
import {
	ISSUE_CHANGE_SWIMLANE,
	type IssueChangeSwimlaneAction,
} from '../../../actions/issue/index.tsx';
import {
	SET_PARENT_OPTIMISTIC,
	type SetParentOptimisticAction,
} from '../../../actions/issue/parent/index.tsx';
import {
	type IssueRankTransitionUpdateOptimisticAction,
	ISSUE_RANK_TRANSITION_UPDATE_OPTIMISTIC,
} from '../../../actions/issue/rank-transition/index.tsx';
import {
	ISSUE_UPDATE_SUCCESS,
	type IssueUpdateSuccessAction,
	SET_ISSUE_PARENT,
	type SetIssueParentAction,
} from '../../../actions/issue/update/index.tsx';
import { WORK_DATA_CRITICAL_SET, WORK_DATA_SET } from '../../../actions/work/index.tsx';
import {
	ISSUE_ARCHIVE_REQUEST,
	type IssueArchiveRequestAction,
} from '../../../actions/issue/archive/index.tsx';
import type { IssueChildrenState } from './types.tsx';

export const issueChildrenReducer = (
	state: IssueChildrenState = {},
	action: Action,
): IssueChildrenState => {
	if (action.type === WORK_DATA_SET || action.type === WORK_DATA_CRITICAL_SET) {
		return entityArrayToMap(action.payload.issueChildren);
	}

	if (action.type === ISSUE_CREATE_REQUEST) {
		const {
			payload: { issues, type },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as IssueCreateRequestAction;
		if (type !== SUBTASK_DND_TYPE) return state;
		const newState = { ...state };
		issues.forEach((issue) => {
			newState[String(issue.id)] = issue;
		});
		return freeze(newState);
	}

	if (action.type === ISSUE_CREATE_SUCCESS) {
		const {
			payload: { temporaryIssueIds, issues },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as IssueCreateSuccessAction;
		const newState = { ...state };

		if (temporaryIssueIds.some((id) => state[String(id)])) {
			temporaryIssueIds.forEach((temporaryId) => {
				delete newState[String(temporaryId)];
			});
			issues.forEach((issue) => {
				newState[String(issue.id)] = issue;
			});
			return freeze(newState);
		}
		return state;
	}

	if (action.type === SET_ISSUE_CHILDREN) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const { payload: issueChildren } = action as SetIssueChildrenAction;
		return freeze(entityArrayToMap(issueChildren));
	}

	if (action.type === ISSUE_UPDATE_SUCCESS) {
		const {
			payload: { issue },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as IssueUpdateSuccessAction;
		const issueId = String(issue.id);
		if (state[issueId]) return set(state, issueId, issue);
		return state;
	}

	if (action.type === CARD_CLEAR) {
		const {
			payload: { childCardIds },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as CardClearAction;

		const newState = chain(state);
		childCardIds.forEach((cardId) => {
			newState.unset(cardId);
		});
		return newState.value();
	}

	if (action.type === CARD_DATA_SET) {
		const {
			payload: { issue },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as CardDataSetAction;
		const issueId = String(issue.id);
		if (state[issueId]) return set(state, issueId, issue);
		return state;
	}

	if (action.type === ISSUE_DELETE_REQUEST || action.type === ISSUE_ARCHIVE_REQUEST) {
		const {
			payload: {
				issue: { id },
				childIssueIds,
			},
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as IssueDeleteRequestAction | IssueArchiveRequestAction;
		const subtaskIssueId = String(id);
		if (state[subtaskIssueId]) {
			return unset(state, subtaskIssueId);
		}
		if (childIssueIds.length > 0) {
			const changeChain = chain(state);
			childIssueIds.forEach((childIssueId: IssueId) => {
				changeChain.unset(String(childIssueId));
			});
			return changeChain.value();
		}
		return state;
	}

	if (action.type === CARD_DELETE) {
		const {
			payload: { issueId },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as CardDeleteAction;
		if (state[String(issueId)]) return unset(state, String(issueId));
		return state;
	}

	if (action.type === ISSUE_RANK_TRANSITION_UPDATE_OPTIMISTIC) {
		const {
			payload: { issueIds, destinationColumnId, isDestinationDone, destinationStatus },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as IssueRankTransitionUpdateOptimisticAction;

		const changeChain = chain(state);
		issueIds.forEach((issueId) => {
			if (!state[String(issueId)]) return;

			changeChain.setIn([String(issueId), 'columnId'], destinationColumnId);
			if (destinationStatus) {
				changeChain.setIn([String(issueId), 'statusId'], Number(destinationStatus.id));
			}
			const currentIssue = state[String(issueId)];
			if (currentIssue.columnId !== destinationColumnId) {
				changeChain.setIn([String(issueId), 'isDone'], isDestinationDone);
			}
		});

		return changeChain.value();
	}

	if (action.type === ISSUE_CHANGE_SWIMLANE) {
		const {
			payload: { issueIds, swimlaneValues },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as IssueChangeSwimlaneAction;
		const changeChain = chain(state);

		issueIds.forEach((issueId) => {
			if (!state[String(issueId)]) return;
			if (swimlaneValues.type === STORY) {
				changeChain.setIn([String(issueId), 'parentId'], swimlaneValues.parentId);
			}

			if (swimlaneValues.type === ASSIGNEE) {
				changeChain.setIn([String(issueId), 'assigneeAccountId'], swimlaneValues.assigneeAccountId);
			}
		});

		return changeChain.value();
	}

	if (action.type === DEV_STATUS_LOAD_SUCCESS) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const { payload } = action as DevStatusLoadSuccessAction;
		const newState = chain(state);

		Object.keys(payload).forEach((issueIdString) => {
			if (!state[String(issueIdString)]) return;
			newState.setIn([issueIdString, 'devStatus'], payload[issueIdString]);
		});

		return newState.value();
	}

	if (action.type === ISSUE_FLAG_WITH_COMMENT_REQUEST) {
		const {
			payload: { issueIds, flag },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as IssueFlagWithCommentRequestAction;
		const newState = chain(state);

		issueIds.forEach((id) => {
			if (state[String(id)]) {
				newState.setIn([String(id), 'isFlagged'], flag);
			}
		});

		return newState.value();
	}

	if (action.type === COLUMN_DELETE_REQUEST) {
		const newState = { ...state };
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const { payload } = action as ColumnDeleteRequestAction;
		Object.keys(state)
			.filter((issueKey) => state[issueKey].columnId === payload.columnId)
			.forEach((issueKey) => {
				if (!payload.closestColumn) {
					delete newState[issueKey];
				} else if (payload.closestColumn.type === ColumnType.STATUS) {
					newState[issueKey] = {
						...newState[issueKey],
						columnId: payload.closestColumn.id,
						statusId: payload.closestColumn.statuses[0].id,
					};
				}
			});
		return newState;
	}

	if (action.type === ISSUE_ADD_LABELS) {
		const {
			payload: { issueIds, labels },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as IssueAddLabelsAction;
		const newState = chain(state);

		issueIds.forEach((id) => {
			if (!state[String(id)]) return;
			const currentLabels = state[String(id)].labels || [];
			newState.setIn([String(id), 'labels'], union(currentLabels, labels));
		});

		return newState.value();
	}

	if (action.type === SET_PARENT_OPTIMISTIC) {
		const {
			payload: { issues, parentId },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as SetParentOptimisticAction;
		const newState = chain(state);
		issues.forEach((issue) => {
			if (!state[String(issue.id)]) return;
			newState.setIn([String(issue.id), 'parentId'], parentId);
		});
		return newState.value();
	}

	if (action.type === SET_ISSUE_PARENT) {
		const {
			payload: { issueId, issueParentId },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as SetIssueParentAction;
		return state[String(issueId)]
			? setIn(state, [String(issueId), 'parentId'], issueParentId)
			: state;
	}

	if (action.type === ASSIGN_TO_ME_REQUEST) {
		const {
			payload: { issueId, assigneeAccountId },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as AssignToMeRequestAction;
		return state[String(issueId)]
			? setIn(state, [String(issueId), 'assigneeAccountId'], assigneeAccountId)
			: state;
	}

	return state;
};
