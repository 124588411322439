import React, { type ReactElement, useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { styled } from '@compiled/react';
import isNil from 'lodash/isNil';
import {
	type Edge,
	attachClosestEdge,
	extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
	draggable,
	dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import { useAutobatch } from '@atlassian/jira-software-react-scheduler/src/ui/autobatch/index.tsx';
import { useEvent } from '@atlassian/jira-software-react-use-event/src/index.tsx';
import { useIsCardOverColumn } from '../../../ui/column/column-edge-provider/index.tsx';
import { CARD_DND_TYPE, COLUMN_DND_TYPE } from '../../constants/drag-drop/index.tsx';
import { columnFixedWidth, columnFixedInnerWidth } from '../../constants/styles/index.tsx';
import type { ColumnId, DraggableCardData, DraggableColumnData, SwimlaneId } from '../../types.tsx';

export interface UseDraggableColumnParams {
	isDraggable: boolean;
	elementRef: React.RefObject<HTMLElement>;
	columnId: ColumnId;
	swimlaneId?: SwimlaneId | null;
	columnIndex: number | undefined | null;
	renderPreview: () => ReactElement | undefined | null;
	onDragLeave?: (dragType: string) => void;
	isCardDroppable?: (cardData: DraggableCardData) => boolean;
	isMatrixLayout?: boolean;
}

export type ColumnDragState = { type: 'idle' } | { type: 'dragging'; container: HTMLElement };

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColumnDragPreviewContainer = styled.div<{ isMatrixLayout?: boolean }>({
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	width: ({ isMatrixLayout }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
		`${isMatrixLayout ? columnFixedInnerWidth : columnFixedWidth}px`,
});

const defaultIsCardDroppable = () => false;
// eslint-disable-next-line @typescript-eslint/no-empty-function
const defaultOnDragLeave = () => {};

export const useDraggableColumn = ({
	isDraggable,
	elementRef,
	columnId,
	swimlaneId,
	columnIndex,
	renderPreview,
	onDragLeave = defaultOnDragLeave,
	isCardDroppable = defaultIsCardDroppable,
	isMatrixLayout,
}: UseDraggableColumnParams) => {
	const autobatch = useAutobatch();
	const [dragState, setDragState] = useState<ColumnDragState>({ type: 'idle' });
	const batchSetDragState = useCallback(
		(state: ColumnDragState) => autobatch(() => setDragState(state)),
		[autobatch],
	);
	const [closestEdge, setClosestEdge] = useState<Edge | null>(null);
	const batchSetClosestEdge = useCallback(
		(edge: Edge | null) => autobatch(() => setClosestEdge(edge)),
		[autobatch],
	);
	const onDragLeaveStable = useEvent(onDragLeave);
	const isCardDroppableStable = useEvent(isCardDroppable);
	const { setIsOverColumn } = useIsCardOverColumn();

	useEffect(() => {
		if (isNil(columnIndex) || !elementRef.current) return undefined;

		return combine(
			isDraggable
				? draggable({
						element: elementRef.current,
						getInitialData: (): DraggableColumnData => ({
							type: COLUMN_DND_TYPE,
							columnId,
							swimlaneId,
							columnIndex,
						}),
						onDrop: () => batchSetDragState({ type: 'idle' }),
						onGenerateDragPreview: ({ nativeSetDragImage }) => {
							setCustomNativeDragPreview({
								getOffset: () => ({ x: 8, y: 8 }),
								render({ container }) {
									batchSetDragState({ type: 'dragging', container });
									return () => batchSetDragState({ type: 'idle' });
								},
								nativeSetDragImage,
							});
						},
					})
				: // eslint-disable-next-line @typescript-eslint/no-empty-function
					() => {},
			dropTargetForElements({
				element: elementRef.current,
				getIsSticky: () => true,
				getData: ({ input, element }) =>
					attachClosestEdge(
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						{
							type: 'COLUMN',
							columnId,
							swimlaneId,
							columnIndex,
						} as DraggableColumnData,
						{
							input,
							element,
							allowedEdges: ['left', 'right'],
						},
					),
				canDrop: ({ source }) => {
					const { type } = source.data;
					if (type === COLUMN_DND_TYPE) return true;

					if (type === CARD_DND_TYPE) {
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						return isCardDroppableStable(source.data as DraggableCardData) ?? false;
					}

					return false;
				},
				onDrag: ({ source: { data }, self, location }) => {
					if (data.type === COLUMN_DND_TYPE && data.columnId !== columnId) {
						batchSetClosestEdge(extractClosestEdge(self.data));
					}
					if (
						data.type === CARD_DND_TYPE &&
						location.current.dropTargets[0]?.data.type === COLUMN_DND_TYPE
					) {
						setIsOverColumn(true);
						return;
					}
					setIsOverColumn(false);
				},
				onDragEnter: ({ source: { data }, self }) => {
					if (data.type === COLUMN_DND_TYPE && data.columnId !== columnId) {
						batchSetClosestEdge(extractClosestEdge(self.data));
					}
				},
				onDragLeave: ({ source: { data } }) => {
					batchSetClosestEdge(null);
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					onDragLeaveStable(data.type as string);
					setIsOverColumn(false);
				},
				onDrop: () => {
					batchSetClosestEdge(null);
					setIsOverColumn(false);
				},
			}),
		);
	}, [
		batchSetClosestEdge,
		batchSetDragState,
		closestEdge,
		columnId,
		columnIndex,
		elementRef,
		isCardDroppableStable,
		isDraggable,
		onDragLeaveStable,
		swimlaneId,
		setIsOverColumn,
	]);

	return {
		dragPreview:
			dragState.type === 'dragging'
				? ReactDOM.createPortal(
						<ColumnDragPreviewContainer isMatrixLayout={isMatrixLayout}>
							{renderPreview()}
						</ColumnDragPreviewContainer>,
						dragState.container,
					)
				: null,
		closestEdge,
	};
};
