import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types.tsx';
import type { OptimisticId } from '../../../../model/software/software-types.tsx';
import type { FlagMeta } from '../../meta/flag-meta.tsx';
import {
	beginOptimisticUiMeta,
	commitOptimisticUiMeta,
	revertOptimisticUiWithFlagMeta,
	type OptimisticUiMeta,
} from '../../meta/optimistic-ui-meta.tsx';

export const UPDATE_OLD_DONE_ISSUES_CUTOFF =
	'state.actions.board.old-done-issues-cutoff.UPDATE_OLD_DONE_ISSUES_CUTOFF' as const;

export type UpdateOldDoneIssuesCutoffRequestAction = {
	type: typeof UPDATE_OLD_DONE_ISSUES_CUTOFF;
	payload: string;
	meta: {
		analyticsEvent?: AnalyticsEvent;
	} & OptimisticUiMeta;
};

export const updateOldDoneIssuesCutoffRequest = (
	oldDoneIssuesCutoff: string,
	analyticsEvent?: AnalyticsEvent,
): UpdateOldDoneIssuesCutoffRequestAction => ({
	type: UPDATE_OLD_DONE_ISSUES_CUTOFF,
	payload: oldDoneIssuesCutoff,
	meta: {
		...beginOptimisticUiMeta(),
		analyticsEvent,
	},
});

export const UPDATE_OLD_DONE_ISSUES_CUTOFF_SUCCESS =
	'state.actions.board.old-done-issues-cutoff.UPDATE_OLD_DONE_ISSUES_CUTOFF_SUCCESS' as const;

export type UpdateOldDoneIssuesCutoffRequestSuccessAction = {
	type: typeof UPDATE_OLD_DONE_ISSUES_CUTOFF_SUCCESS;
	meta: {
		analyticsEvent?: AnalyticsEvent;
	} & OptimisticUiMeta;
};

export const updateOldDoneIssuesCutoffRequestSuccess = (
	optimisticId: OptimisticId,
): UpdateOldDoneIssuesCutoffRequestSuccessAction => ({
	type: UPDATE_OLD_DONE_ISSUES_CUTOFF_SUCCESS,
	meta: commitOptimisticUiMeta(optimisticId),
});

export const UPDATE_OLD_DONE_ISSUES_CUTOFF_FAILURE =
	'state.actions.board.old-done-issues-cutoff.UPDATE_OLD_DONE_ISSUES_CUTOFF_FAILURE' as const;

export type UpdateOldDoneIssuesCutoffRequestFailureAction = {
	type: typeof UPDATE_OLD_DONE_ISSUES_CUTOFF_FAILURE;
	meta: OptimisticUiMeta & FlagMeta;
};

export const updateOldDoneIssuesCutoffRequestFailure = (
	optimisticId: OptimisticId,
): UpdateOldDoneIssuesCutoffRequestFailureAction => ({
	type: UPDATE_OLD_DONE_ISSUES_CUTOFF_FAILURE,
	meta: revertOptimisticUiWithFlagMeta(optimisticId),
});

export type Action =
	| UpdateOldDoneIssuesCutoffRequestAction
	| UpdateOldDoneIssuesCutoffRequestSuccessAction
	| UpdateOldDoneIssuesCutoffRequestFailureAction;
