import React, { Component, type MouseEvent, type Ref } from 'react';
import { styled as styled2, css as css2 } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled, { css } from 'styled-components';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import { Box, xcss } from '@atlaskit/primitives';
import GlobalTheme from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { gridSize, borderRadius } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage } from '@atlassian/jira-intl';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { ExternalAction } from '@atlassian/jira-issue-view-store/src/actions/external-actions.tsx';
import type { CardProps, GlobalThemeTokens, ThemeTokens } from '../../common/types.tsx';
import { CardColor } from '../../common/ui/color/index.tsx';
import { Theme } from '../../common/utils/theme.utils.tsx';
import { CardContent } from './card-content/index.tsx';
import { InteractionLayer } from './interaction-layer/index.tsx';
import messages from './messages.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const stopPropagation = (event: any) => event.stopPropagation();

// eslint-disable-next-line jira/react/no-class-components
export class Card extends Component<CardProps> {
	static defaultProps = {
		isDisabled: false,
		isDone: false,
		isSelected: false,
		isFlagged: false,
		isLoading: false,
		persistActions: false,
		typeName: null,
		typeUri: null,
		cardKey: null,
		coverUri: null,
		cover: null,
		avatarName: null,
		avatarUri: null,
		icons: null,
		highlightText: [],
		actions: null,
		color: null,
		customFields: null,
		children: null,
		onClick: noop,
		onSizeChange: noop,
		renderAssignee: undefined,
		isCMPBoard: false,
		isIPBoard: false,
		status: null,
		hasCompactIssueType: false,
		hasScenarioChanges: false,
		id: null,
		renderIssueLinksStats: () => <></>,
	};

	onClick = (event: MouseEvent<HTMLElement>, externalAction?: ExternalAction) => {
		const { isDisabled, onClick } = this.props;
		if (!isDisabled) {
			onClick(event, externalAction);
		}
	};

	renderMenu() {
		const { actions, isLoading, persistActions } = this.props;

		if (fg('fix_jira_board_card_nested_interactive_elements')) {
			return (
				!isLoading &&
				!isNil(actions) && (
					<Box
						testId="platform-card.ui.card.actions-section"
						data-component-selector={ACTIONS_SECTION_CONTENT_SELECTOR}
						onClick={stopPropagation}
						xcss={[
							actionsSectionStyles,
							fg('visual-refresh_drop_3') && actionSectionStylesVisualRefresh,
							persistActions ? visibilityStyles.initial : visibilityStyles.hidden,
						]}
					>
						{actions}
					</Box>
				)
			);
		}

		return (
			!isLoading &&
			!isNil(actions) && (
				<ActionsSection
					data-test-id="platform-card.ui.card.actions-section"
					data-testid="platform-card.ui.card.actions-section"
					persistActions={persistActions}
					onClick={stopPropagation}
				>
					{actions}
				</ActionsSection>
			)
		);
	}

	renderCardColor() {
		const { color, hasScenarioChanges, isIPBoard } = this.props;
		/**
		 * In increment planning boards, we render the card color only if the issue:
		 * * is a new issue created in Plans that doesn't yet exist in Jira
		 * * has scenario changes not yet saved to Jira
		 *
		 * The card color is wrapped with a tooltip to inform the user about the fact that
		 * changes only exist in Plans and need to be saved to Jira to be reflected there.
		 */
		if (isIPBoard) {
			if (hasScenarioChanges) {
				return (
					<Tooltip content={<FormattedMessage {...messages.scenarioChangeTooltip} />}>
						<CardColor color={color} />
					</Tooltip>
				);
			}
			return null;
		}
		return <CardColor color={color} />;
	}

	render() {
		const {
			isDisabled,
			isDone,
			isFlagged,
			isSelected,
			isFlexible,
			isLoading,
			summary,
			typeName,
			typeUri,
			cardKey,
			cardRef,
			cardContainerRef,
			coverUri,
			cover,
			color,
			customFields,
			avatarName,
			avatarUri,
			icons,
			highlightText,
			children,
			onSizeChange,
			onBlur,
			onFocus,
			onMouseEnter,
			onMouseLeave,
			renderSummary,
			renderAssignee,
			isCMPBoard,
			isIPBoard,
			status,
			hasCompactIssueType,
			renderIssueLinksStats,
			id,
			hasScenarioChanges,
			fixVersions,
		} = this.props;

		if (fg('fix_jira_board_card_nested_interactive_elements')) {
			return (
				<Theme.Provider>
					<GlobalTheme.Consumer>
						{({ mode }: GlobalThemeTokens) => (
							<Theme.Consumer
								isDisabled={isDisabled}
								isSelected={isSelected}
								isFlagged={isFlagged}
								mode={mode}
							>
								{({
									backgroundColor,
									textColor,
									hoverBackgroundColor,
									hoverTextColor,
								}: ThemeTokens) => (
									<ContainerNew
										// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
										ref={cardContainerRef as Ref<HTMLDivElement>}
										isDisabled={isDisabled}
										isFlexible={Boolean(isFlexible)}
										textColor={textColor}
										backgroundColor={backgroundColor}
										hoverBackgroundColor={hoverBackgroundColor}
										hoverTextColor={hoverTextColor}
									>
										<InteractionLayer
											onClick={this.onClick}
											onMouseEnter={onMouseEnter}
											onMouseLeave={onMouseLeave}
											onFocus={onFocus}
											onBlur={onBlur}
											cardRef={cardRef}
											summary={summary}
											cardKey={cardKey}
										/>
										<CardContent
											id={id}
											isDone={isDone}
											isFlagged={isFlagged}
											isLoading={isLoading}
											summary={summary}
											typeName={typeName}
											typeUri={typeUri}
											cardKey={cardKey}
											coverUri={coverUri}
											cover={cover}
											customFields={customFields}
											avatarName={avatarName}
											avatarUri={avatarUri}
											icons={icons}
											highlightText={highlightText}
											onImageLoad={onSizeChange ?? noop}
											shouldMenuRender
											renderSummary={renderSummary}
											onClick={this.onClick}
											isCMPBoard={isCMPBoard}
											isIPBoard={isIPBoard}
											status={status}
											hasCompactIssueType={hasCompactIssueType}
											renderAssignee={renderAssignee}
											renderIssueLinksStats={renderIssueLinksStats}
											color={color}
											hasScenarioChanges={hasScenarioChanges}
											fixVersions={fixVersions}
										>
											{children}
										</CardContent>
										{!isLoading && this.renderMenu()}
									</ContainerNew>
								)}
							</Theme.Consumer>
						)}
					</GlobalTheme.Consumer>
				</Theme.Provider>
			);
		}

		return (
			<Theme.Provider>
				<GlobalTheme.Consumer>
					{({ mode }: GlobalThemeTokens) => (
						<Theme.Consumer
							isDisabled={isDisabled}
							isSelected={isSelected}
							isFlagged={isFlagged}
							mode={mode}
						>
							{(themeTokens: ThemeTokens) => (
								// eslint-disable-next-line styled-components-a11y/click-events-have-key-events, styled-components-a11y/no-static-element-interactions
								<Container
									{...themeTokens}
									isDisabled={isDisabled}
									isFlexible={isFlexible}
									onClick={this.onClick}
									// eslint-disable-next-line styled-components-a11y/no-noninteractive-tabindex
									tabIndex={0}
									onFocus={onFocus}
									onBlur={onBlur}
									onMouseEnter={onMouseEnter}
									onMouseLeave={onMouseLeave}
									innerRef={cardRef}
									data-test-id="platform-card.ui.card.focus-container"
									data-testid="platform-card.ui.card.focus-container"
								>
									<CardContent
										id={id}
										isDone={isDone}
										isFlagged={isFlagged}
										isLoading={isLoading}
										summary={summary}
										typeName={typeName}
										typeUri={typeUri}
										cardKey={cardKey}
										coverUri={coverUri}
										cover={cover}
										customFields={customFields}
										avatarName={avatarName}
										avatarUri={avatarUri}
										icons={icons}
										highlightText={highlightText}
										onImageLoad={onSizeChange ?? noop}
										shouldMenuRender
										renderSummary={renderSummary}
										onClick={this.onClick}
										isCMPBoard={isCMPBoard}
										isIPBoard={isIPBoard}
										status={status}
										hasCompactIssueType={hasCompactIssueType}
										renderAssignee={renderAssignee}
										renderIssueLinksStats={renderIssueLinksStats}
										color={color}
										hasScenarioChanges={hasScenarioChanges}
										fixVersions={fixVersions}
									>
										{children}
									</CardContent>
									{!isLoading && this.renderMenu()}
								</Container>
							)}
						</Theme.Consumer>
					)}
				</GlobalTheme.Consumer>
			</Theme.Provider>
		);
	}
}

// @ts-expect-error - Argument of type 'typeof Card' is not assignable to parameter of type 'ComponentType<WithIntlProvided<CardProps>> & typeof Card'.
export default injectIntl(Card);

const EDIT_ICON_CONTENT_SELECTOR = 'issue-field-summary-inline-edit.ui.read.edit-icon';
const STATIC_SUMMARY_CONTENT_SELECTOR = 'issue-field-summary-inline-edit.ui.read.static-summary';
const EDITABLE_SUMMARY_CONTENT_SELECTOR =
	'issue-field-summary-inline-edit.ui.read.editable-summary';
const ACTIONS_SECTION_CONTENT_SELECTOR = 'issue-field-summary-inline-edit.ui.read.actions-section';

const maxCardWidth = 270;
const boxShadow = token('elevation.shadow.raised');

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const ActionsSection = styled.div<{ persistActions?: boolean }>((props) => ({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	visibility: props.persistActions ? 'initial' : 'hidden',
	position: 'absolute',
	right: token('space.100'),
	top: token('space.100'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('elevation.surface.raised'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius}px`,
}));

const actionsSectionStyles = xcss({
	pointerEvents: 'auto',
	position: 'absolute',
	right: 'space.100',
	top: 'space.100',
	backgroundColor: 'elevation.surface.raised',
	borderRadius: 'border.radius',
});

const actionSectionStylesVisualRefresh = xcss({
	// @ts-expect-error Type '"var(--ds-UNSAFE-transparent)"' is not assignable to type '"color.background.accent.lime.subtlest" | "color.background.accent.lime.subtlest.hovered" | "color.background.accent.lime.subtlest.pressed" | "color.background.accent.lime.subtler" | ... 193 more ... | undefined'.
	backgroundColor: token('utility.UNSAFE.transparent'),
	right: 'space.150',
	top: 'space.150',
});

// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression
const mixinVisibleActionsStyles = css`
	${/* sc-selector */ ActionsSection} {
		visibility: visible;
	}
`;

// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{
	backgroundColor: string;
	textColor: string;
	isDisabled?: boolean;
	isFlexible?: boolean;
	hoverBackgroundColor: string;
	hoverTextColor: string;
}>`
	cursor: pointer;
	user-select: none;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	position: relative;
	margin: ${token('space.025')} 0;
	border-radius: ${borderRadius}px;
	box-shadow: ${boxShadow};
	transition:
		background-color 140ms ease-in-out,
		color 140ms ease-in-out;
	background-color: ${(props) => props.backgroundColor};
	--jsw-card-background-color: ${({ backgroundColor, isDisabled }) =>
		isDisabled ? token('elevation.surface.raised') : backgroundColor};
	color: ${(props) => props.textColor};
	filter: ${(props) => (props.isDisabled ? 'grayscale(100%)' : 'none')};
	max-width: ${(props) => (props.isFlexible ? '100%' : `${maxCardWidth}px`)};

	&:hover {
		background-color: ${(props) => props.hoverBackgroundColor};
		--jsw-card-background-color: ${(props) => props.hoverBackgroundColor};
		color: ${(props) => props.hoverTextColor};
	}
	&:focus {
		box-shadow: inset 0 0 0 ${gridSize / 4}px ${token('color.text.brand')};
		outline: none;
	}
	&:hover,
	&:focus,
	&:focus-within {
		${mixinVisibleActionsStyles};
		[data-component-selector='${STATIC_SUMMARY_CONTENT_SELECTOR}'] {
			display: none;
		}
		[data-component-selector='${EDITABLE_SUMMARY_CONTENT_SELECTOR}'],
		[data-component-selector='${EDIT_ICON_CONTENT_SELECTOR}'] {
			display: inline;
		}
	}
`;

const containerStyles = css2<{
	isDisabled: boolean;
	isFlexible: boolean;
	textColor: string;
	backgroundColor: string;
	hoverBackgroundColor: string;
	hoverTextColor: string;
}>({
	pointerEvents: 'none',
	cursor: 'pointer',
	userSelect: 'none',
	boxSizing: 'border-box',
	display: 'flex',
	flexDirection: 'column',
	marginTop: token('space.025'),
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage -- The token value "4px" and fallback "3px" do not match and can't be replaced automatically.
	borderRadius: token('border.radius', '3px'),
	boxShadow: token('elevation.shadow.raised'),
	transition: 'background-color 140ms ease-in-out, color 140ms ease-in-out',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	backgroundColor: ({ backgroundColor }) => backgroundColor,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	color: ({ textColor }) => textColor,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	filter: ({ isDisabled }) => (isDisabled ? 'grayscale(100%)' : 'none'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	maxWidth: ({ isFlexible }) => (isFlexible ? '100%' : `${maxCardWidth}px`),

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	'&:hover': ({ hoverBackgroundColor, hoverTextColor }) => ({
		backgroundColor: hoverBackgroundColor,

		color: hoverTextColor,
	}),

	'&:focus': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
		boxShadow: `inset 0 0 0 ${token('space.025')} ${token('color.text.brand')}`,
		outline: 'none',
	},

	'&:hover, &:focus, &:focus-within': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors
		[`[data-component-selector="${ACTIONS_SECTION_CONTENT_SELECTOR}"]`]: {
			visibility: 'visible',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors
		[`[data-component-selector="${STATIC_SUMMARY_CONTENT_SELECTOR}"]`]: {
			display: 'none',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors
		[`[data-component-selector="${EDITABLE_SUMMARY_CONTENT_SELECTOR}"]`]: {
			display: 'inline',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors
		[`[data-component-selector="${EDIT_ICON_CONTENT_SELECTOR}"]`]: {
			display: 'inline',
		},
	},
});

// Rename when cleaning up fix_jira_board_card_nested_interactive_elements
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-unsafe-values
const ContainerNew = styled2.div(containerStyles);

const visibilityStyles = {
	initial: xcss({ visibility: 'initial' }),
	visible: xcss({ visibility: 'visible' }),
	hidden: xcss({ visibility: 'hidden' }),
};
