/**
 * @generated SignedSource<<fe92ddba5b4701ee4c883cf0ddaa04ad>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_filterRefinement_FilterRefinement$data = {
  readonly " $fragmentSpreads": FragmentRefs<"assigneePicker_filterRefinement">;
  readonly " $fragmentType": "ui_filterRefinement_FilterRefinement";
};
export type ui_filterRefinement_FilterRefinement$key = {
  readonly " $data"?: ui_filterRefinement_FilterRefinement$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_filterRefinement_FilterRefinement">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cloudId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scope"
    }
  ],
  "kind": "Fragment",
  "name": "ui_filterRefinement_FilterRefinement",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "cloudId",
          "variableName": "cloudId"
        },
        {
          "kind": "Variable",
          "name": "scope",
          "variableName": "scope"
        }
      ],
      "kind": "FragmentSpread",
      "name": "assigneePicker_filterRefinement"
    }
  ],
  "type": "Query"
};

(node as any).hash = "3a7cc5342cef1417af0126b6a4c255eb";

export default node;
