import type { State, PanelState } from '../../common/types/state.tsx';

export const getIsFullscreen = (state: State): boolean => state.isFullscreen;

export const getShowVersionPanel = (state: State): boolean => state.showVersionPanel;

export const getShowEpicPanel = (state: State): boolean => state.showEpicPanel;

export const getShowQuickFilters = (state: State): boolean => state.showQuickFilters;

export const getShowCompactMode = (state: State): boolean => state.showCompactMode;

export const getShowPanels = (state: State): PanelState => ({
	EPIC_PANEL: state.showEpicPanel,
	VERSION_PANEL: state.showVersionPanel,
});

export const getShowEpics = (state: State): boolean => state.showEpics;

export const getShowVersions = (state: State): boolean => state.showVersions;

export const getShowStatuses = (state: State): boolean => state.showStatuses;

export const getShowUnscheduledColumn = (state: State): boolean => state.showUnscheduledColumn;

export const getShowIssueLinksStats = (state: State): boolean => state.showIssueLinksStats;

export const getShowIssueType = (state: State): boolean => state.showIssueType;

export const getShowIssueKey = (state: State): boolean => state.showIssueKey;

export const getShowPriority = (state: State): boolean => state.showPriority;

export const getShowDevelopment = (state: State): boolean => state.showDevelopment;

export const getShowAssignee = (state: State): boolean => state.showAssignee;

export const getShowEstimate = (state: State): boolean => state.showEstimate;

export const getShowCardExtraField = (state: State): { [key: string]: boolean } =>
	state.cardExtraFields;

export const getShowDaysInColumn = (state: State): boolean => state.showDaysInColumn;

export const getShowCardCovers = (state: State): boolean => state.showCardCovers;

export const getShowOfftrackDependencyLines = (state: State): boolean =>
	state.showOfftrackDependencyLines;

export const getShowBoardName = (state: State) => state.showBoardName;

export const getIssueIdsToShowDependencies = (state: State) => state.issueIdsToShowDependencies;

export const getCardFieldsSettings = (state: State) => ({
	showIssueType: state.showIssueType,
	showIssueKey: state.showIssueKey,
	showPriority: state.showPriority,
	showDevelopment: state.showDevelopment,
	showAssignee: state.showAssignee,
	showEstimate: state.showEstimate,
	cardExtraFields: state.cardExtraFields,
	showDaysInColumn: state.showDaysInColumn,
	showDueDate: state.showDueDate,
	showLabels: state.showLabels,
	showCardCovers: state.showCardCovers,
	showStatuses: state.showStatuses,
});

export const getHasCardExtraFields = (state: State): boolean =>
	Object.values(state.cardExtraFields).length > 0 &&
	Object.values(state.cardExtraFields).some((value) => value === true);
