import { RetroTypes, type State } from './types.tsx';

export const initialState: State = {
	sprintId: undefined,
	shouldCreateRetro: false,
	loadCreateRetroModal: false,
	loadCrossFlowModal: false,
	loadCrossJoinModal: false,
	isRetroLinked: false,
	isRetroLinking: false,
	isRetroPublished: false,
	isWhiteboardsDisabled: false,
	retroLinkingError: undefined,
	retroType: RetroTypes.WHITEBOARD,
	confluenceData: {
		spaceKey: undefined,
		parentPageId: undefined,
		spaceId: undefined,
	},
	createRetroPage: {
		data: undefined,
		loading: false,
		error: undefined,
	},
	createWhiteboard: {
		data: undefined,
		loading: false,
		error: undefined,
	},
};
