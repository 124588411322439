import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'software-flags.card-filtered.title',
		defaultMessage:
			"Changes are saved, but {numIssues, plural, one {issue isn't} other {issues aren't}} visible",
		description: "Flag title when created or updated issues aren't visible to the user",
	},
	titleMovedBetweenSwimlanes: {
		id: 'software-flags.card-filtered.title-moved-between-swimlanes',
		defaultMessage: "You've created {numIssues, plural, one {# issue} other {# issues}}",
		description: 'Flag title when created issues move to a different swimlane',
	},
	descriptionMovedBetweenSwimlanes: {
		id: 'software-flags.card-filtered.description-moved-between-swimlanes',
		defaultMessage:
			"{numIssues, plural, one {It's} other {They've}} been created in a different swimlane because of the board's JQL setup.",
		description:
			'Flag description when created issues move to a different swimlane. The words "it" and "they" refer to the new created issue(s)',
	},
	descriptionWithLink: {
		id: 'software-flags.card-filtered.description-with-link',
		defaultMessage:
			'For issue {linkStart}{linkEnd} to be visible, it must match your project and filters, and have a status that’s assigned to the board.',
		description: "Flag description when created or updated issue isn't visible to the user",
	},
	description: {
		id: 'software-flags.card-filtered.description',
		defaultMessage:
			'For an issue to be visible, it must match your project and filters, and have a status that’s assigned to the board.',
		description:
			"Flag description when multiple issues are created or updated but aren't visible to the user",
	},
	issueLink: {
		id: 'software-flags.card-filtered.issue-link',
		defaultMessage: 'View {numIssues, plural, one {issue} other {issues}}',
		description: 'Link to the issue',
	},
	issueAddToSprint: {
		id: 'software-flags.card-filtered.issue-add-to-sprint',
		defaultMessage: 'Add to {sprintName}',
		description: 'Add the new issue to an active sprint',
	},
	issueAddToSprintLoading: {
		id: 'software-flags.card-filtered.issue-add-to-sprint-loading',
		defaultMessage: 'Adding...',
		description: 'Loading text for adding issue to sprint',
	},
	issueAddToSprintSuccess: {
		id: 'software-flags.card-filtered.issue-add-to-sprint-success',
		defaultMessage: 'Added to sprint',
		description: 'Success text for adding issue to sprint',
	},
	titleIssueTermRefresh: {
		id: 'software-flags.card-filtered.title-issue-term-refresh',
		defaultMessage:
			"Changes are saved, but {numIssues, plural, one {work item isn't} other {work items aren't}} visible",
		description: "Flag title when created or updated issues aren't visible to the user",
	},
	titleMovedBetweenSwimlanesIssueTermRefresh: {
		id: 'software-flags.card-filtered.title-moved-between-swimlanes-issue-term-refresh',
		defaultMessage: "You've created {numIssues, plural, one {# work item} other {# work items}}",
		description: 'Flag title when created issues move to a different swimlane',
	},
	descriptionMovedBetweenSwimlanesIssueTermRefresh: {
		id: 'software-flags.card-filtered.description-moved-between-swimlanes-issue-term-refresh',
		defaultMessage:
			"{numIssues, plural, one {It's} other {They've}} been created in a different swimlane because of the board's JQL setup.",
		description:
			'Flag description when created issues move to a different swimlane. The words "it" and "they" refer to the new created issue(s)',
	},
	descriptionWithLinkIssueTermRefresh: {
		id: 'software-flags.card-filtered.description-with-link-issue-term-refresh',
		defaultMessage:
			'For work item {linkStart}{linkEnd} to be visible, it must match your project and filters, and have a status that’s assigned to the board.',
		description: "Flag description when created or updated issue isn't visible to the user",
	},
	descriptionIssueTermRefresh: {
		id: 'software-flags.card-filtered.description-issue-term-refresh',
		defaultMessage:
			'For a work item to be visible, it must match your project and filters, and have a status that’s assigned to the board.',
		description:
			"Flag description when multiple issues are created or updated but aren't visible to the user",
	},
	issueLinkIssueTermRefresh: {
		id: 'software-flags.card-filtered.issue-link-issue-term-refresh',
		defaultMessage: 'View{numIssues, plural, one {} other { work items}}',
		description: 'Link to the issue',
	},
});
