import get from 'lodash/get';
import type { ApolloError } from 'apollo-client';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { type FlagConfiguration, errorFlags } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { type ShowErrorFlagAction, XSRF_CHECK_FAILED } from '../../common/constants.tsx';
import messages from '../../common/messages.tsx';
import { type SwagError, isFetchError, isSwagError } from '../../common/utils/error/index.tsx';

type ParsedApplicationError = {
	errorType: string;
} | null;

export const getApplicationError = (error: SwagError | ApolloError): ParsedApplicationError => {
	const { graphQLErrors } = error;
	if (!graphQLErrors || graphQLErrors.length === 0) {
		return null;
	}

	const applicationError = graphQLErrors.find((e) => get(e, ['extensions', 'errorType'], false));

	if (!applicationError) return null;

	return {
		errorType: get(applicationError, ['extensions', 'errorType'], ''),
	};
};

type ParsedSwagError = {
	statusCode: number | null;
	userMessage: string | null;
};

export const getParsedError = (error: SwagError | ApolloError | FetchError): ParsedSwagError => {
	if (isFetchError(error)) {
		return {
			statusCode: get(error, ['statusCode'], null),
			userMessage: null,
		};
	}

	const { graphQLErrors, networkError } = error;
	if (networkError) {
		return {
			statusCode: get(networkError, ['statusCode'], null),
			userMessage: null,
		};
	}
	if (graphQLErrors.length > 0) {
		return {
			statusCode: get(graphQLErrors, [0, 'extensions', 'statusCode'], null),
			userMessage: get(graphQLErrors, [0, 'extensions', 'userMessage'], null),
		};
	}

	return {
		statusCode: null,
		userMessage: null,
	};
};

const isApplicationError = (error: SwagError | ApolloError): boolean => {
	const applicationError = getApplicationError(error);
	return applicationError?.errorType === XSRF_CHECK_FAILED;
};

export const getErrorFlag = (error: SwagError | ApolloError | FetchError): FlagConfiguration => {
	if (!isFetchError(error)) {
		// check if any error contains "errorType", and process prior to http-status-code
		if (isApplicationError(error)) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return {
				type: 'error',
				title: messages.xsrfCheckFailedTitle,
				description: messages.xsrfCheckFailedDescription,
				messageId: 'software-swag.services.error-to-flag.flag.error.xsrf-check-failed',
				messageType: 'transactional',
				error,
			} as FlagConfiguration;
		}
	}

	const { statusCode: httpStatusCode, userMessage } = getParsedError(error);

	if ((httpStatusCode === 400 || httpStatusCode === 403) && userMessage) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return {
			type: 'error',
			title: errorFlags.genericError().title,
			description: [messages.genericErrorDescription, { userMessage }],
			messageId: 'software-swag.services.error-to-flag.flag.error.generic-error',
			messageType: 'transactional',
			error,
		} as FlagConfiguration;
	}

	if (httpStatusCode === 401) {
		const unauthorizedError = errorFlags.unauthorized();
		unauthorizedError.error = error;
		return unauthorizedError;
	}
	if (httpStatusCode === 403) {
		const forbiddenError = errorFlags.forbidden();
		forbiddenError.error = error;
		return forbiddenError;
	}
	const genericError = errorFlags.genericError();
	genericError.error = error;
	return genericError;
};

export const extractFlagFromAction = (
	action: ShowErrorFlagAction,
): FlagConfiguration | undefined => {
	const { payload: error } = action;

	if (isSwagError(error) || isFetchError(error)) {
		return getErrorFlag(error);
	}

	// eslint-disable-next-line no-console
	console.error(
		'Action showErrorFlag(error) contains invalid error format. \n Make sure you are using query$() or mutation$() utils from @atlassian/jira-software-swag in /rest layer',
	);

	return undefined;
};
