import meanBy from 'lodash/meanBy';
import {
	generateLongTaskAnalyticsContinuousActionSummary,
	getAverageResultsFromContinuousActions,
} from '@atlassian/jira-common-long-task-metrics/src/reporters/common.tsx';
import {
	isExpandedObservationData,
	type ObservationData,
} from '@atlassian/jira-common-long-task-metrics/src/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { OPERATIONAL_EVENT_TYPE } from '@atlassian/jira-product-analytics-bridge';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';

interface LongTaskSummary {
	/**
	 * What percentage of frames ran at 60fps or higher. This nº is calculated using the
	 * blocked duration.
	 */
	qualityIndex: number;
	/**
	 * Are there tasks over 50ms in this interaction span
	 */
	hasLongTasks: boolean;
	/**
	 * Are there tasks over 100ms in this interaction span
	 */
	hasExtraLongTasks: boolean;
	// Raw data
	longTasks: { duration: number; timestamp: number }[];
	rawLongTaskCount: number;
	longTaskCount: number;
	minimumBlockedDuration: number;
	timeOverBudget: number;
	duration: number;
	startTime: number;
	endTime: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const aggregatedDataTimeout: Record<string, any> = {};

/**
 * Generic long-tasks reporter builder.
 *
 * @param observationType Determines the `actionSubjectId` in the event (`${appName}-${observationType}`)
 * @param observationEndTime Force and endTime to the observation in case there's debouncing in place
 * @param logAttributes Log attributes to add to the event
 * @example
 *
 *     const scrollReporter = softwareGenericLongTasksReporter();
 *     getLongTasksMetrics('scroll').stop(scrollReporter);
 */
export const softwareGenericLongTasksReporter =
	(observationType: string, observationEndTime?: number, logAttributes = {}) =>
	(observationData: ObservationData) => {
		if (fg('jsw_refactor_long-tasks_monitoring')) {
			if (!isExpandedObservationData(observationData) || !('appName' in observationData)) {
				return;
			}

			const { appName, startTimestamp, events } = observationData;

			const startTime = startTimestamp;
			const endTime = observationEndTime ?? performance.now();
			const duration = endTime - startTime;

			if (duration < 100) {
				return;
			}

			const rawLongTasks = events ?? [];
			const longTasks = rawLongTasks
				.filter(
					(event) => event.timestamp >= startTime && event.timestamp + event.duration <= endTime,
				)
				.map((event) => ({
					timestamp: event.timestamp - startTime,
					duration: event.duration,
				}));
			const minimumBlockedDuration = longTasks.reduce(
				(sum, longTask) => sum + longTask.duration,
				0,
			);

			const frameRateTarget = 60;
			const budgetPerFrame = 1000 / frameRateTarget;
			const timeOverBudget = longTasks
				.map((event) => event.duration - budgetPerFrame)
				.reduce((sum, time) => sum + time, 0);

			const summary: LongTaskSummary = {
				qualityIndex: 1 - timeOverBudget / duration,
				hasLongTasks: longTasks.length > 0,
				hasExtraLongTasks: longTasks.filter((l) => l.duration >= 100).length > 0,
				longTasks,
				rawLongTaskCount: rawLongTasks.length,
				longTaskCount: longTasks.length,
				minimumBlockedDuration,
				timeOverBudget,
				duration,
				startTime,
				endTime,
			};

			getAnalyticsWebClientPromise().then((client) => {
				client.sendEvent({
					type: OPERATIONAL_EVENT_TYPE,
					payload: {
						source: appName,
						action: 'longTask',
						actionSubject: 'ui',
						actionSubjectId: `${appName}-${observationType}`,
						attributes: {
							key: appName,
							...summary,
							...logAttributes,
						},
					},
				});
			});
		} else {
			if (!isExpandedObservationData(observationData)) {
				return;
			}

			const startTime = observationData.events.map((event) => event.timestamp);
			const analyticsData = {
				...generateLongTaskAnalyticsContinuousActionSummary(
					observationType,
					observationData.events,
				),
				measureTimespan: performance.now() - Math.min(observationData.startTimestamp, ...startTime),
			};

			const { appName } = observationData;
			if (aggregatedDataTimeout[`software.${appName}.${observationType}`]) {
				aggregatedDataTimeout[`software.${appName}.${observationType}`].data.push(analyticsData);
			} else {
				aggregatedDataTimeout[`software.${appName}.${observationType}`] = {
					data: [analyticsData],
					extra: observationData.extra,
					timeout: setTimeout(() => {
						getAnalyticsWebClientPromise().then((client) => {
							const { data, extra: extraData } =
								aggregatedDataTimeout[`software.${appName}.${observationType}`];
							client.sendEvent({
								type: OPERATIONAL_EVENT_TYPE,
								payload: {
									source: appName,
									action: 'longTask',
									actionSubject: 'ui',
									actionSubjectId: `${appName}-${observationType}`,
									attributes: {
										key: appName,
										...getAverageResultsFromContinuousActions(observationType, data),
										...extraData,
										avgScrollTimespan: meanBy(data, 'measureTimespan'),
										...logAttributes,
									},
								},
							});
							delete aggregatedDataTimeout[`software.${appName}.${observationType}`];
						});
					}, 7000),
				};
			}
		}
	};
