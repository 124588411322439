/** @jsx jsx */
import { memo, type MouseEvent } from 'react';
import { css, keyframes, jsx } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import AddIcon from '@atlaskit/icon/core/migration/add--editor-add';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { TOOLTIP_DELAY } from '../../../common/constants.tsx';
import type { Props } from './types.tsx';

const fadeInAnimation = keyframes({
	from: {
		opacity: 0,
	},
	to: {
		opacity: 1,
	},
});

const triggerStyles = css({
	display: 'block',
	position: 'relative',
	zIndex: 1,
	marginTop: token('space.negative.050'),
	marginRight: 0,
	marginBottom: token('space.negative.050'),
	marginLeft: 0,
	padding: 0,
	border: 'none',
	outline: 'none',
	height: token('space.100'),
	width: '100%',
	backgroundColor: 'transparent',
	opacity: 0,
	'&:hover': {
		cursor: 'pointer',
		animationName: fadeInAnimation,
		animationDuration: '200ms',
		animationDelay: '200ms',
		animationFillMode: 'forwards',
	},
	'&:focus-within': {
		cursor: 'pointer',
		opacity: 1,
		'&:hover': {
			animation: 'none',
		},
	},
});

const TriggerFallback = (props: { onClick: (arg1: MouseEvent<HTMLElement>) => void }) => (
	<div css={triggerStyles} {...props} />
);

const HiddenTrigger = ({
	label,
	onClick,
	TriggerWrapper = TriggerFallback,
	shouldFocusTrigger = false,
}: Props) => (
	<TriggerWrapper onClick={onClick}>
		<Tooltip content={label} position="left" delay={TOOLTIP_DELAY} hideTooltipOnClick>
			{isVisualRefreshEnabled() && fg('visual-refresh_drop_6') ? (
				<button
					css={[buttonStyles, visualRefreshStyles]}
					data-testid="platform-inline-card-create.ui.trigger.hidden.button"
					autoFocus={shouldFocusTrigger}
				>
					<AddIcon label={label} color={token('color.icon')} />
				</button>
			) : (
				<button
					css={buttonStyles}
					data-testid="platform-inline-card-create.ui.trigger.hidden.button"
					autoFocus={shouldFocusTrigger}
				>
					<Box xcss={centeredIconStyles}>
						<AddIcon spacing="spacious" label={label} color={token('color.icon.inverse')} />
					</Box>
				</button>
			)}
		</Tooltip>
		<Box xcss={dividerStyles} backgroundColor="color.background.brand.bold" />
	</TriggerWrapper>
);

export { HiddenTrigger };
export default memo<Props>(HiddenTrigger);

const dividerStyles = xcss({
	position: 'absolute',
	transform: 'translateY(3px)',
	height: token('space.025'),
	width: '100%',
});

const centeredIconStyles = xcss({
	display: 'flex',
	height: token('space.300'),
	width: token('space.300'),
});

const buttonStyles = css({
	display: 'block',
	position: 'absolute',
	transform: `translate(${token('space.negative.150')}, ${token('space.negative.100')})`,
	zIndex: 1,
	margin: 0,
	padding: 0,
	outline: 'none',
	height: token('space.300'),
	width: token('space.300'),
	border: 'none',
	borderRadius: token('border.radius'),
	backgroundColor: token('color.background.brand.bold'),
	cursor: 'inherit',
});

const visualRefreshStyles = css({
	backgroundColor: token('elevation.surface.overlay'),
	border: `1px solid ${token('color.border')}`,

	'&:hover': {
		backgroundColor: token('elevation.surface.overlay.hovered'),
	},
});
