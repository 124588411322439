import { expVal } from '@atlassian/jira-feature-experiments';
import { CUSTOM_FILTER, JQL } from '../../../../../model/filter/filter-types.tsx';
import {
	FILTERED_CARD_SUCCESS,
	FILTERED_CARD_CLEAR,
	REFILTER_SUCCESS,
	FILTERED_CARD_FAILURE,
} from '../../../../actions/card/filtered-cards/index.tsx';
import {
	CUSTOM_FILTER_FORCE_REFRESH_START,
	SET_ALL_FILTERS,
} from '../../../../actions/filter/index.tsx';
import { WORK_DATA_CRITICAL_SET } from '../../../../actions/work/index.tsx';
import type { Action } from '../../../../types.tsx';
import { ISSUE_CREATE_SUCCESS } from '../../../../actions/issue/create/index.tsx';
import type { FilteredCardsState } from './types.tsx';

export const initialState: FilteredCardsState = {
	isLoading: false,
	filteredCardsIds: null,
};

export const filteredCardsReducer = (state: FilteredCardsState = initialState, action: Action) => {
	if (
		action.type === SET_ALL_FILTERS &&
		(action.meta.filterType === CUSTOM_FILTER || action.meta.filterType === JQL)
	) {
		return {
			...state,
			isLoading: true,
		};
	}

	if (action.type === CUSTOM_FILTER_FORCE_REFRESH_START) {
		return {
			...state,
			isLoading: true,
		};
	}

	if (
		action.type === WORK_DATA_CRITICAL_SET &&
		action.payload.filteredCardIds &&
		expVal('filter_refinement_in_tmp_board', 'isEnabled', false)
	) {
		return {
			isLoading: false,
			filteredCardsIds: new Set<string>(action.payload.filteredCardIds),
		};
	}

	if (action.type === FILTERED_CARD_SUCCESS) {
		return {
			isLoading: false,
			filteredCardsIds: new Set<string>(action.payload),
		};
	}

	if (action.type === FILTERED_CARD_FAILURE) {
		return {
			isLoading: false,
			filteredCardsIds: null,
		};
	}

	if (action.type === FILTERED_CARD_CLEAR) {
		return initialState;
	}

	if (action.type === REFILTER_SUCCESS) {
		const { excludedCardIds, filteredCardIds } = action.payload;
		const updatedFilteredCardIds = new Set<string>([
			...(state.filteredCardsIds || []),
			...filteredCardIds,
		]);
		excludedCardIds.forEach((cardId: string) => updatedFilteredCardIds.delete(cardId)); // delete is faster than filtering on state
		return {
			isLoading: false,
			filteredCardsIds: updatedFilteredCardIds,
		};
	}

	if (action.type === ISSUE_CREATE_SUCCESS && action.meta.isFilterRefinementEnabled) {
		const {
			payload: { issues },
		} = action;

		return {
			...state,
			...(state.filteredCardsIds
				? {
						filteredCardsIds: new Set<string>([
							...(state.filteredCardsIds || []),
							...issues.map((issue) => issue.id.toString()),
						]),
					}
				: {}),
		};
	}

	return state;
};
