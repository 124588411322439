import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	teamSearchLabel: {
		id: 'software.main.board-container.board.card-container.card.context-menu.menu-items.change-team.team-search-label',
		defaultMessage: 'Search for a team',
		description:
			'Aria label for text field that allows the user to search for a team in a list of teams',
	},
});
