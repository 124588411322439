import { useRegisterNudgeTarget } from '@atlassiansox/nudge-tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { UNASSIGNED_FILTER_NUDGE_ID } from '../../ui/main.tsx';
import { useDismissal } from '../use-dismissal/index.tsx';

export const useUnassignedFilterNudge: (shouldShowUnassignedFilterNudge: boolean) => {
	unassignedFilterNudgeRef: React.RefObject<HTMLDivElement> | undefined;
} = (shouldShowUnassignedFilterNudge) => {
	const { ref: unassignedFilterNudgeRef } = useRegisterNudgeTarget<HTMLDivElement>(
		UNASSIGNED_FILTER_NUDGE_ID,
		shouldShowUnassignedFilterNudge && fg('unassigned_filter_kill_switch'),
	);
	const { isDismissed } = useDismissal();

	if (!fg('unassigned_filter_kill_switch')) {
		return {
			unassignedFilterNudgeRef: undefined,
		};
	}

	const showUnassignedFilterNudge =
		(!isDismissed &&
			shouldShowUnassignedFilterNudge &&
			expValEquals('software_business_board_unassigned_filter_ga', 'isEnabled', true)) === true;

	return {
		unassignedFilterNudgeRef: showUnassignedFilterNudge ? unassignedFilterNudgeRef : undefined,
	};
};
