import { fg } from '@atlassian/jira-feature-gating';
import { SET_INLINE_EDITING } from '../../../../actions/card/index.tsx';
import type { Action } from '../../../../types.tsx';
import type { InlineEditing } from './types.tsx';

export const initialState: InlineEditing = {};

export const cardInlineEditingReducer = (
	state: InlineEditing = initialState,
	action: Action,
): InlineEditing => {
	switch (action.type) {
		case SET_INLINE_EDITING: {
			// don't change state if the value is the same
			if (
				state[action.payload.issueId] &&
				state[action.payload.issueId][action.payload.field] === action.payload.value &&
				fg('render_program_board_card_in_popup')
			) {
				return state;
			}
			return {
				...state,
				[action.payload.issueId]: {
					// Setting all fields to false except the one we are setting
					assignee: false,
					summary: false,
					priority: false,
					parentField: false,
					status: false,
					dependencies: false,
					estimate: false,
					contextMenu: false,
					[action.payload.field]: action.payload.value,
				},
			};
		}

		default: {
			return state;
		}
	}
};
