import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { Sprint } from '../../../model/sprint/sprint-types.tsx';
import {
	getIsCMPBoard,
	rapidViewIdSelector,
} from '../../../state/selectors/software/software-selectors.tsx';
import {
	activeSprintsSelector,
	getSelectedSprintsKeys,
} from '../../../state/selectors/sprint/sprint-selectors.tsx';
import { workIssuesSelector } from '../../../state/selectors/work/work-selectors.tsx';
import type { State } from '../../../state/types.tsx';
import { type StateProps, type OwnProps, CompleteSprintButton } from './view.tsx';

export const getAppearanceState = (sprints: Sprint[] | null) => {
	if (isVisualRefreshEnabled() && fg('visual-refresh_drop_6')) {
		if (sprints && sprints[0]?.daysRemaining != null && sprints[0].daysRemaining <= 0) {
			return 'primary';
		}
	}

	// FIXME next dev actively working in this area
	// not urgent bc (null <= 0) => true so no crash
	// @ts-expect-error TS2531: Object is possibly 'null'.
	if (sprints && sprints[0]?.daysRemaining <= 0) {
		return 'primary';
	}

	return 'default';
};

const mapStateToProps = (state: State): StateProps => {
	const activeSprints = activeSprintsSelector(state);

	return {
		activeSprints,
		appearance: getAppearanceState(activeSprints),
		boardId: rapidViewIdSelector(state),
		selectedSprintIds: getSelectedSprintsKeys(state),
		sourceIssueNo: workIssuesSelector(state).length,
		isCMPBoard: getIsCMPBoard(state),
	};
};

export default connect<StateProps, {}, OwnProps, State>(mapStateToProps, {})(CompleteSprintButton);
