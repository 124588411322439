import React, { type ComponentPropsWithoutRef } from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import Heading from '@atlaskit/heading';
import ClockIcon from '@atlaskit/icon/core/clock';
import EmojiFrequentIcon from '@atlaskit/icon/glyph/emoji/frequent';
import { Box, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { defaultLocale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import { Capability } from '../../../common/capability/index.tsx';
import type { Sprint } from '../../../model/sprint/sprint-types.tsx';
import { useCapability } from '../../../state/state-hooks/capabilities/index.tsx';
import messages from './messages.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const AsyncTooltipContent = lazy(
	() =>
		import(
			/* webpackChunkName: "async-software-board-sprint-remaining-tooltip" */ './tooltip-content'
		),
);

export type Props = {
	remainingDays: Sprint['daysRemaining'];
	startDate: Sprint['startDate'];
	endDate: Sprint['endDate'];
	locale?: string | null;
};

const RemainingDays = ({ label }: { label: string }) => (
	<SprintRemainingDaysStyled>
		<RecentIconWrapper>
			<ClockIcon
				testId="software-board.header.sprint-remaining-days.clock-icon"
				label=""
				spacing="spacious"
				LEGACY_fallbackIcon={EmojiFrequentIcon}
				color={token('color.icon')}
			/>
		</RecentIconWrapper>
		{label}
	</SprintRemainingDaysStyled>
);

const RemainingDaysNoIcon = ({ label }: { label: string }) => (
	<SprintRemainingDaysStyled>{label}</SprintRemainingDaysStyled>
);

export const SprintRemainingDays = (props: Props) => {
	const intl = useIntl();
	const { remainingDays, startDate, endDate, locale = defaultLocale } = props;
	const isTabNavigation = useCapability(Capability.IS_TAB_NAVIGATION);

	const contentHeader = intl.formatMessage(messages.remainingDays, {
		remainingDays,
	});

	const label = !isTabNavigation
		? intl.formatMessage(messages.remainingDaysLabel, {
				remainingDays,
			})
		: contentHeader;

	if (remainingDays === null) {
		return null;
	}

	if (startDate === null || endDate === null || locale === null) {
		return isTabNavigation ? (
			<RemainingDaysNoIcon label={label} />
		) : (
			<RemainingDays label={label} />
		);
	}

	return (
		<Tooltip
			content={
				<ErrorBoundary id="async.software-board.sprint-remaining-days" packageName="software-board">
					<Placeholder
						name="software-board-sprint-remaining-tooltip"
						fallback={<Spinner size="small" />}
					>
						<AsyncTooltipContent
							contentHeader={contentHeader}
							startDate={startDate}
							locale={locale}
							endDate={endDate}
						/>
					</Placeholder>
				</ErrorBoundary>
			}
		>
			{isTabNavigation ? <RemainingDaysNoIcon label={label} /> : <RemainingDays label={label} />}
		</Tooltip>
	);
};

export default SprintRemainingDays;

const SprintRemainingDaysStyledWrapper = (
	props: ComponentPropsWithoutRef<typeof SprintRemainingDaysStyledExperiment>,
) => (
	<Box as="div" xcss={sprintRemainingDaysWrapperStyles}>
		<Heading size="xxsmall" as="div">
			<SprintRemainingDaysStyledExperiment {...props} />
		</Heading>
	</Box>
);

const sprintRemainingDaysWrapperStyles = xcss({
	marginTop: 'space.0',
	marginRight: 'space.100',
	marginLeft: 'space.050',
});

// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SprintRemainingDaysStyledControl = styled.div`
	font: ${token('font.body.UNSAFE_small')};
	color: ${token('color.text.subtlest')};
	display: flex;
	align-items: center;
	margin-top: 0;
	margin-right: ${token('space.100')};
	margin-left: ${token('space.050')};
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SprintRemainingDaysStyledExperiment = styled2.span({
	display: 'flex',
	alignItems: 'center',
	font: token('font.body.UNSAFE_small'),
});

const SprintRemainingDaysStyled = styledComponentWithCondition(
	() => fg('jsw_compiled_migration_tanuki'),
	SprintRemainingDaysStyledWrapper,
	SprintRemainingDaysStyledControl,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RecentIconWrapper = styled2.span({
	color: token('color.icon'),
	display: 'flex',
	alignItems: 'center',
	paddingRight: token('space.050'),
});
