/** @jsx jsx */
import React, {
	type RefObject,
	type ReactNode,
	Component,
	type ReactElement,
	type KeyboardEvent,
	type MouseEvent,
	type SyntheticEvent,
	type PropsWithChildren,
} from 'react';
import { styled, css, jsx } from '@compiled/react';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import { EngagementSpotlight } from '@atlassiansox/engagekit-ts';
import keycode from 'keycode';
import { cssMap } from '@atlaskit/css';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Avatar from '@atlaskit/avatar';
import FlagFilledIcon from '@atlaskit/icon/core/flag-filled';
import ChevronDownIconOld from '@atlaskit/icon/glyph/chevron-down';
import ChevronRightIconOld from '@atlaskit/icon/glyph/chevron-right';
import FlagFilledIconOld from '@atlaskit/icon/glyph/flag-filled';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import Lozenge from '@atlaskit/lozenge';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { Flex, Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { ScreenReaderText } from '@atlassian/jira-accessibility/src/common/ui/screenreader-text/index.tsx';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
import { UNDEFINED } from '@atlassian/jira-common-constants/src/status-categories.tsx';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import {
	type Color,
	type ColorSchema,
	PURPLE,
} from '@atlassian/jira-issue-epic-color-types/src/types.tsx';
import { transformColorToTheme } from '@atlassian/jira-issue-epic-color/src/common/utils.tsx';
import { CardKey } from '@atlassian/jira-platform-card/src/common/ui/key/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { Link as LinkComponent } from '@atlassian/react-resource-router';
import { Tokens } from '@atlassian/jira-custom-theme-constants/src/constants.tsx';
import { getIsSoftwareThemingEnabled } from '@atlassian/jira-project-theme-providers/src/utils/getIsSoftwareThemingEnabled.tsx';
import {
	zIndex,
	swimlaneHeaderHeight,
	unscheduledColumnHeaderHeight,
} from '../../common/constants/styles/index.tsx';
import type { IssueStatus } from '../../common/types.tsx';
import { getLozengeAppearanceByStatus } from '../../common/utils/lozenge-appearance/index.tsx';
import CardEpic from './epic/index.tsx';
import messages from './messages.tsx';
import { TruncatedTooltip } from './truncated-tooltip/index.tsx';
import type { IssueParent, Assignee, SwimlaneModeId } from './types.tsx';

type Props = {
	isSwimlaneHeaderClickable: boolean;
	issueKey: string | null;
	issueStatus: IssueStatus | null;
	issueParent: IssueParent | null;
	name: string;
	spotlightTargetId: string;
	showCardAmount?: boolean;
	cardAmount: number;
	icon: ReactElement | null;
	children: ReactElement | ReactElement[];
	collapsed: boolean;
	assignee: Assignee | null;
	customSwimlaneContent: ReactNode;
	customRightContent: ReactNode;
	summaryTooltip: string | null;
	// TODO Update class to functional component and use the analytics hooks
	onToggle: (analyticsEvent: UIAnalyticsEvent) => void;
	onClick: (analyticsEvent: UIAnalyticsEvent) => void;
	intl: IntlShape;
	isSubtask: boolean;
	link?: string;
	projectKey: string | null | undefined;
	isFlagged: boolean;
	isDone: boolean;
	wrapperStyle?: React.CSSProperties;
	innerRef?: RefObject<HTMLDivElement>;
	lozenge?: ReactElement | null;
	customLeftContent?: ReactElement | ReactNode | null;
	swimlaneMode?: SwimlaneModeId | null;
	isUnscheduledWorkColumnPanel: boolean;
	isMatrixLayout?: boolean;
	isThemed?: boolean;
};

const AVATAR_BORDER_COLOR = 'transparent';

const LOZENGE_THEMES = transformColorToTheme(
	(colorSchema: ColorSchema) => colorSchema.textBackground,
);

const Wrapper = ({
	children,
	isMatrixLayout,
}: PropsWithChildren & {
	isMatrixLayout?: boolean;
}) => {
	if (isMatrixLayout) {
		return (
			<Box
				xcss={getIsSoftwareThemingEnabled() ? innerWrapperStyles.root : innerWrapperStylesOld.root}
			>
				{children}
			</Box>
		);
	}
	return children;
};

const getLozengeThemeByColor = (color: Color) =>
	// This uses schema from src/packages/issue/epic-color/src/common/constants.js
	LOZENGE_THEMES[color] || LOZENGE_THEMES[PURPLE];

// eslint-disable-next-line jira/react/no-class-components
class Swimlane extends Component<Props> {
	static defaultProps = {
		issueKey: null,
		issueParent: null,
		issueStatus: null,
		isSwimlaneHeaderClickable: false,
		collapsed: false,
		spotlightTargetId: '',
		assignee: null,
		icon: null,
		customSwimlaneContent: null,
		customRightContent: null,
		summaryTooltip: null,
		onToggle: noop,
		onClick: noop,
		isSubtask: false,
		link: undefined,
		projectKey: undefined,
		isFlagged: false,
		isDone: false,
		wrapperStyles: {},
		lozenge: null,
		showCardAmount: true,
		isUnscheduledWorkColumnPanel: false,
		isMatrixLayout: false,
	};

	onKeyDown = (event: KeyboardEvent<HTMLDivElement>, analyticsEvent: UIAnalyticsEvent) => {
		if (event.keyCode === keycode('enter') || event.keyCode === keycode('space')) {
			event.preventDefault();
			this.props.onToggle(analyticsEvent);
		}
	};

	onSwimlaneHeaderKeyDown = (
		event: KeyboardEvent<HTMLDivElement>,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		if (event.keyCode === keycode('enter') || event.keyCode === keycode('space')) {
			event.preventDefault();
			this.onLinkClick(event, analyticsEvent);
		}
	};

	onLinkClick = (
		e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLDivElement>,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		e.stopPropagation();
		this.props.onClick(analyticsEvent);
	};

	getSwimlaneContent(): ReactElement {
		const {
			intl: { formatMessage },
			showCardAmount,
			cardAmount,
			icon,
			name,
			collapsed,
			isSwimlaneHeaderClickable,
			issueKey,
			issueParent,
			issueStatus,
			assignee,
			customSwimlaneContent,
			summaryTooltip,
			isSubtask,
			link,
			projectKey,
			isFlagged,
			isDone,
			lozenge,
			swimlaneMode,
			customLeftContent,
			isThemed,
		} = this.props;

		if (!isNil(customSwimlaneContent)) {
			return <>{customSwimlaneContent}</>;
		}

		const issueCountMessage = expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
			? messages.issueCountIssueTermRefresh
			: messages.issueCount;

		const cardsAmountLabel = showCardAmount
			? formatMessage(isSubtask ? messages.subtaskCount : issueCountMessage, {
					count: cardAmount || 0,
				})
			: null;

		const summary = projectKey ? `${name} (${projectKey})` : name;

		const headerContent = (
			<Flex gap="space.100" alignItems="center" xcss={headerContentStyles}>
				{(icon || (issueParent && swimlaneMode)) && (
					<Flex alignItems="center" gap="space.050">
						{icon}
						{issueParent && swimlaneMode === 'SWIMLANE_BY_PARENT_ISSUE' && (
							<Tooltip content={issueParent.summary}>
								<ColorBox color={getLozengeThemeByColor(issueParent.color)} />
							</Tooltip>
						)}
					</Flex>
				)}
				{issueKey && <CardKey text={issueKey} stopEventPropagation={false} isDone={isDone} />}
				{summaryTooltip ? (
					<Tooltip content={summaryTooltip} data-testid="platform-board-kit.ui.swimlane.tooltip">
						{isThemed && fg('jira_theming_milestone_1_fg') ? (
							<Box
								xcss={summarySectionStyles}
								testId="platform-board-kit.ui.swimlane.summary-section"
							>
								{summary}
							</Box>
						) : (
							<SummarySection data-testid="platform-board-kit.ui.swimlane.summary-section">
								{summary}
							</SummarySection>
						)}
					</Tooltip>
				) : (
					<TruncatedTooltip content={summary}>
						{({ ref }) =>
							isThemed && fg('jira_theming_milestone_1_fg') ? (
								<Box
									xcss={summarySectionStyles}
									ref={ref}
									testId="platform-board-kit.ui.swimlane.summary-section"
								>
									{summary}
								</Box>
							) : (
								<SummarySection
									ref={ref}
									data-testid="platform-board-kit.ui.swimlane.summary-section"
								>
									{summary}
								</SummarySection>
							)
						}
					</TruncatedTooltip>
				)}
				{lozenge && <LozengeWrapper>{lozenge}</LozengeWrapper>}
				{customLeftContent}
				{showCardAmount &&
					(isThemed && fg('jira_theming_milestone_1_fg') ? (
						<Box xcss={swimlaneIssuesAmountStyles}>{`(${cardsAmountLabel})`}</Box>
					) : (
						<SwimlaneIssuesAmount>{`(${cardsAmountLabel})`}</SwimlaneIssuesAmount>
					))}
				{swimlaneMode !== 'SWIMLANE_BY_PARENT_ISSUE' && issueParent && (
					<CardEpic color={issueParent.color}>{issueParent.summary}</CardEpic>
				)}
				{issueStatus && (issueStatus.name || '').toString().length > 0 && (
					<StatusSection>
						<ScreenReaderText>{formatMessage(messages.statusLozenge)}</ScreenReaderText>
						<Tooltip content={issueStatus.name}>
							<Lozenge
								appearance={getLozengeAppearanceByStatus(
									issueStatus && issueStatus.category ? issueStatus.category : UNDEFINED,
								)}
								testId="platform-board-kit.ui.swimlane.lozenge"
								isBold={isVisualRefreshEnabled() && fg('visual-refresh_drop_5')}
							>
								{issueStatus.name}
							</Lozenge>
						</Tooltip>
					</StatusSection>
				)}
				{isFlagged && (
					<FlagFilledIcon
						label={formatMessage(messages.flagged)}
						color={token('color.icon.danger')}
						LEGACY_fallbackIcon={FlagFilledIconOld}
						LEGACY_primaryColor={token('color.icon.danger')}
						LEGACY_size="small"
					/>
				)}
				{assignee && (
					<AvatarWrapper>
						<Tooltip content={assignee.name}>
							<Avatar
								src={assignee.avatarUrl}
								name={assignee.name}
								size="small"
								borderColor={AVATAR_BORDER_COLOR}
							/>
						</Tooltip>
					</AvatarWrapper>
				)}
			</Flex>
		);

		const renderHeader = () => {
			if (isSwimlaneHeaderClickable) {
				if (issueKey) {
					return (
						<LinkButtonWithAnalytics
							data-testid="platform-board-kit.ui.swimlane.link-button"
							onClick={this.onLinkClick}
							// @ts-expect-error - Type '(event: KeyboardEvent<HTMLDivElement>, analyticsEvent: UIAnalyticsEvent) => void' is not assignable to type 'KeyboardEventHandler<HTMLButtonElement>'.
							onKeyDown={this.onSwimlaneHeaderKeyDown}
						>
							{headerContent}
						</LinkButtonWithAnalytics>
					);
				}

				if (link) {
					return (
						<LinkWithAnalytics
							href={link}
							// @ts-expect-error - Type '(e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLDivElement>, analyticsEvent: UIAnalyticsEvent) => void' is not assignable to type '((e: MouseEvent<Element, MouseEvent> | KeyboardEvent<Element>, args_1: UIAnalyticsEvent) => any) | ((e: MouseEvent<...> | KeyboardEvent<...>, args_1: UIAnalyticsEvent) => any) | undefined'.
							onClick={this.onLinkClick}
							// @ts-expect-error - Type '(event: KeyboardEvent<HTMLDivElement>, analyticsEvent: UIAnalyticsEvent) => void' is not assignable to type 'KeyboardEventHandler<HTMLAnchorElement>'.
							onKeyDown={this.onSwimlaneHeaderKeyDown}
						>
							{headerContent}
						</LinkWithAnalytics>
					);
				}
			}

			return headerContent;
		};

		const renderChevronIcon = () => (
			<Box
				xcss={[
					isVisualRefreshEnabled() && iconSpacing,
					fg('visual-refresh_drop_3') && iconSpacingExtra,
				]}
			>
				{collapsed ? (
					<ChevronRightIcon
						LEGACY_fallbackIcon={ChevronRightIconOld}
						LEGACY_primaryColor={token('color.icon')}
						color={token('color.icon')}
						label=""
					/>
				) : (
					<ChevronDownIcon
						LEGACY_fallbackIcon={ChevronDownIconOld}
						LEGACY_primaryColor={token('color.icon')}
						color={token('color.icon')}
						label=""
					/>
				)}
			</Box>
		);

		return (
			<SwimlaneContent
				data-testid="platform-board-kit.ui.swimlane.swimlane-content"
				role="button"
				// @ts-expect-error Type '(event: SyntheticEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => void' is not assignable to type 'MouseEventHandler<HTMLDivElement>'.
				onClick={this.toggleColumns}
				tabIndex={0}
				// @ts-expect-error Type '(event: KeyboardEvent<HTMLDivElement>, analyticsEvent: UIAnalyticsEvent) => void' is not assignable to type 'KeyboardEventHandler<HTMLDivElement>'.
				onKeyDown={this.onKeyDown}
				aria-expanded={!collapsed}
			>
				{renderChevronIcon()}

				{renderHeader()}
			</SwimlaneContent>
		);
	}

	toggleColumns = (event: SyntheticEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
		this.props.onToggle(analyticsEvent);
	};

	renderSwimlaneInner = () => {
		const { isUnscheduledWorkColumnPanel, spotlightTargetId, customRightContent } = this.props;

		return (
			<Box
				testId="platform-board-kit.ui.swimlane.swimlane-inner"
				xcss={[
					SwimlaneInnerStyles,
					isUnscheduledWorkColumnPanel && SwimlaneInnerSingleColumnWidthStyles,
					!isUnscheduledWorkColumnPanel && SwimlaneInnerFullWidthStyles,
				]}
			>
				<SpotlightTarget name={spotlightTargetId}>{this.getSwimlaneContent()}</SpotlightTarget>
				<EngagementSpotlight engagementId={spotlightTargetId} />
				{Boolean(customRightContent) && (
					<SwimlaneRightContentContainer>{customRightContent}</SwimlaneRightContentContainer>
				)}
			</Box>
		);
	};

	renderSwimlaneBar = () => {
		const { isUnscheduledWorkColumnPanel, isThemed } = this.props;
		return (
			<div
				css={[
					swimlaneBarStyles,
					isUnscheduledWorkColumnPanel && swimlaneBarUnscheduledColumnStyles,
					isThemed && fg('jira_theming_milestone_1_fg')
						? swimlaneThemingStyles
						: swimlaneThemingStylesOld,
				]}
			>
				{this.renderSwimlaneInner()}
			</div>
		);
	};

	render() {
		const { children, collapsed, wrapperStyle, innerRef, isMatrixLayout } = this.props;

		return (
			<div
				data-testid="platform-board-kit.ui.swimlane.swimlane-wrapper"
				// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={wrapperStyle}
				ref={innerRef}
			>
				<Wrapper isMatrixLayout={isMatrixLayout}>
					<>
						<SwimlaneShadow isSoftwareThemingEnabled={getIsSoftwareThemingEnabled()} />
						{getIsSoftwareThemingEnabled() ? (
							<div css={[SwimlaneShadowMaskStyles]} />
						) : (
							<SwimlaneShadowMaskOld />
						)}
					</>

					{this.renderSwimlaneBar()}
					{!collapsed && (
						<Flex testId="platform-board-kit.ui.swimlane.swimlane-columns">{children}</Flex>
					)}
				</Wrapper>
			</div>
		);
	}
}

export default injectIntl(Swimlane);

const swimlaneShadowSize = 2;
const headerHeight = swimlaneHeaderHeight;

const innerWrapperStylesOld = cssMap({
	root: {
		backgroundColor: token('elevation.surface'),
		// @ts-expect-error Type '"6px"' is not assignable to type '"inherit" | "var(--ds-border-radius-050)" | "var(--ds-border-radius)" | "var(--ds-border-radius-100)" | "var(--ds-border-radius-200)" | "var(--ds-border-radius-300)" | "var(--ds-border-radius-400)" | "var(--ds-border-radius-circle)" | undefined'.ts(2322)
		borderRadius: '6px',
		marginBottom: token('space.100'),
		paddingBottom: token('space.050'),
	},
});

const innerWrapperStyles = cssMap({
	root: {
		// @ts-expect-error Type 'string' is not assignable to type '"transparent" | "currentcolor" | "var(--ds-background-accent-lime-subtlest-hovered)" | "var(--ds-background-accent-lime-subtler-hovered)" | "var(--ds-background-accent-lime-subtle-hovered)" | ... 191 more ... | undefined'.ts(2322)
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
		backgroundColor: Tokens.COLOR_ELEVATION_SURFACE,
		// @ts-expect-error Type '"6px"' is not assignable to type '"inherit" | "var(--ds-border-radius-050)" | "var(--ds-border-radius)" | "var(--ds-border-radius-100)" | "var(--ds-border-radius-200)" | "var(--ds-border-radius-300)" | "var(--ds-border-radius-400)" | "var(--ds-border-radius-circle)" | undefined'.ts(2322)
		borderRadius: '6px',
		marginBottom: token('space.100'),
		paddingBottom: token('space.050'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SwimlaneContent = styled.div({
	display: 'inline-flex',
	flex: 1,
	minWidth: 0,
	alignItems: 'center',
	marginLeft: token('space.025'),
	maxWidth: '100%',
});

const SwimlaneInnerStyles = xcss({
	display: 'flex',
	gap: 'space.100',
	alignItems: 'center',
	flex: 1,
});

const SwimlaneInnerSingleColumnWidthStyles = xcss({
	minWidth: '100%',
	maxWidth: '100%',
});

const SwimlaneInnerFullWidthStyles = xcss({
	// So things dont overlap on smaller screens
	minWidth: '700px',
	maxWidth: `min(100%, calc(var(--board-scroll-element-width, 2000) * 1px - ${token('space.500')})`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const SwimlaneShadow = styled.div<{ isSoftwareThemingEnabled: boolean }>(
	{
		display: 'block',

		backgroundColor: token('color.blanket'),
		opacity: 0.2,
		height: `${swimlaneShadowSize}px`,
		position: 'sticky',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		top: `${headerHeight * 2}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		zIndex: zIndex.stickySwimlaneBar,
		pointerEvents: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	({ isSoftwareThemingEnabled }) =>
		isSoftwareThemingEnabled
			? {
					marginRight: token('space.075'),
				}
			: {
					margin: `0 ${token('space.075')}`,
				},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SwimlaneShadowMaskOld = styled.div({
	display: 'block',
	backgroundColor: token('elevation.surface'),
	height: `${swimlaneShadowSize}px`,
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	top: `${headerHeight}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.stickySwimlaneBar,
	pointerEvents: 'none',
	marginTop: token('space.negative.025'),
	marginRight: 0,
	marginBottom: 0,
	marginLeft: 0,
});

const SwimlaneShadowMaskStyles = css({
	display: 'block',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	backgroundColor: Tokens.COLOR_ELEVATION_SURFACE,
	height: `${swimlaneShadowSize}px`,
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	top: `${headerHeight}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.stickySwimlaneBar,
	pointerEvents: 'none',
	marginTop: token('space.negative.025'),
	marginRight: 0,
	marginBottom: 0,
	marginLeft: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarWrapper = styled.div({
	height: '28px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SwimlaneIssuesAmount = styled.div({
	font: token('font.body.UNSAFE_small'),
	color: token('color.text.subtle'),
});

const swimlaneIssuesAmountStyles = xcss({
	font: token('font.body.UNSAFE_small'),
	// @ts-expect-error - need to apply custom tokens for sufficient text contrast in themed projects
	color:
		Tokens.COLOR_TEXT_SUBTLE /* eslint-disable-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values */,
});

const swimlaneBarStyles = css({
	display: 'flex',
	alignItems: 'center',
	cursor: 'pointer',
	'&:focus': {
		boxShadow: `inset 0 0 0 2px ${token('color.border.focused')}`,
		outline: 'none',
	},
	position: 'sticky',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.stickySwimlaneBar,
	paddingTop: token('space.025'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${headerHeight - swimlaneShadowSize}px`,
	marginTop: token('space.negative.025'),
	marginBottom: token('space.025'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	top: `${headerHeight}px`,
});

// TODO: combine this style into swimlaneBarStyles above on cleanup of jira_theming_milestone_1_fg
const swimlaneThemingStyles = css({
	backgroundColor: Tokens.COLOR_ELEVATION_SURFACE, // eslint-disable-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values
	marginBottom: 'unset',
});

const swimlaneThemingStylesOld = css({
	borderRadius: '3px',
	backgroundColor: token('elevation.surface'),
});

const swimlaneBarUnscheduledColumnStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	top: `${unscheduledColumnHeaderHeight}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LinkButton = styled.button({
	background: 'none',
	border: 'none',
	paddingTop: 0,
	paddingRight: token('space.100'),
	paddingBottom: 0,
	paddingLeft: 0,
	font: 'inherit',
	cursor: 'pointer',
	minWidth: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Link = styled(LinkComponent)({
	background: 'none',
	border: 'none',
	paddingTop: 0,
	paddingRight: token('space.100'),
	paddingBottom: 0,
	paddingLeft: 0,
	font: 'inherit',
	cursor: 'pointer',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&, &:hover': {
		textDecoration: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SummarySection = styled.div({
	flex: 1,
	minWidth: 0,
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

const summarySectionStyles = xcss({
	flex: 1,
	minWidth: '0px',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	// @ts-expect-error - need to apply custom tokens for sufficient text contrast in themed projects
	color:
		Tokens.COLOR_TEXT /* eslint-disable-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values */,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StatusSection = styled.div({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& span': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/design-system/use-tokens-typography -- Ignored via go/DSP-18766
		lineHeight: `${gridSize * 2}px`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div': {
		display: 'flex',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SwimlaneRightContentContainer = styled.div({
	display: 'flex',
	justifyContent: 'flex-end',
	paddingTop: 0,
	paddingRight: token('space.100'),
	paddingBottom: 0,
	paddingLeft: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LozengeWrapper = styled.span({
	marginLeft: token('space.100'),
	display: 'inline-block',
});

const LinkButtonWithAnalytics = ComponentWithAnalytics('swimlaneHeader', {
	onClick: 'clicked',
})(LinkButton);

const LinkWithAnalytics = ComponentWithAnalytics('swimlaneHeader', {
	onClick: 'clicked',
})(Link);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColorBox = styled.div<{ color: string }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: (props) => props.color,
	width: '1.25rem',
	height: '1.25rem',
	borderRadius: token('border.radius.050'),
});

const headerContentStyles = xcss({ minWidth: '0' });

const iconSpacing = xcss({ margin: 'space.050' });

// When clean up fg visual-refresh_drop_3, combine this with iconSpacing
const iconSpacingExtra = xcss({
	width: '20px',
	display: 'flex',
	justifyContent: 'center',
});
