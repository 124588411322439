// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, createHook } from '@atlassian/react-sweet-state';
import actions, { type Actions } from './actions/index.tsx';
import { initialState } from './config.tsx';
import type { State } from './types.tsx';

export const Store = createStore<State, Actions>({
	initialState,
	actions,
});

export const useCardMediaActions = createHook(Store, {
	selector: null,
});
