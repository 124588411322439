import React, { type ComponentType } from 'react';
import get from 'lodash/get';
import memoizeOne from 'memoize-one';
import type { PreloadedQuery } from 'react-relay';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { getLongTasksMetrics } from '@atlassian/jira-common-long-task-metrics/src/index.tsx';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import type { Parent } from '@atlassian/jira-filters/src/common/types.tsx';
import { useViewMode } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { AnalyticsAttributes } from '@atlassian/jira-product-analytics-bridge';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { viewBoardCustomFiltersQuery } from '@atlassian/jira-relay/src/__generated__/viewBoardCustomFiltersQuery.graphql';
import { MAX_AVATARS } from '../../../../common/constants.tsx';
import {
	type IssueType,
	type IssueTypeGroup,
	type FilterType,
	type Filters,
	CUSTOM_FILTER,
	SPRINT,
} from '../../../../model/filter/filter-types.tsx';
import type { Person } from '../../../../model/people/people-types.tsx';
import { CAN_EDIT_BOARD } from '../../../../model/permission/permission-types.tsx';
import type { IssueProject } from '../../../../model/software/software-types.tsx';
import type { SprintArray } from '../../../../model/sprint/sprint-types.tsx';
import { cardAutoScrollRequestOnFilter } from '../../../../state/actions/card/card-auto-scroll/index.tsx';
import { clearFilteredCards } from '../../../../state/actions/card/filtered-cards/index.tsx';
import {
	searchFieldFocus,
	extraAvatarMenuOpen,
	filterPanelMounted,
	setAllFilters,
	clearAllFiltersClicked,
} from '../../../../state/actions/filter/index.tsx';
import { selectSprint as selectSprints } from '../../../../state/actions/sprints/select/index.tsx';
import { getPermissionsSelector } from '../../../../state/selectors/board/board-permissions-selectors.tsx';
import {
	filteredIssueParentsToListItemSelector,
	firstParentIssueTypeNameSelector,
} from '../../../../state/selectors/issue-parent/index.tsx';
import { isIssueModalShowingSelector } from '../../../../state/selectors/issue/issue-modal-selectors.tsx';
import {
	issueLabelsSelector,
	sortedIssueTypeSelector,
	issueTypeGroupSelector,
} from '../../../../state/selectors/issue/issue-selectors.tsx';
import { isAnyModalOpenSelector } from '../../../../state/selectors/modals/modals-selectors.tsx';
import {
	getIssueProjects,
	rapidViewIdSelector,
	projectIdSelector,
	projectNameSelector,
	projectKeySelector,
	getIsCMPBoard,
	getHierarchyNomenclature,
	getIsIncrementPlanningBoard,
	getCanInviteOthersToProject,
} from '../../../../state/selectors/software/software-selectors.tsx';
import { getSprintsSelector } from '../../../../state/selectors/sprint/sprint-selectors.tsx';
import {
	orderedWorkAssigneesByAccountIdSelector,
	orderedWorkAssigneesAlphabeticallySelector,
	getWorkFilters,
	isFilteredCardsLoading,
} from '../../../../state/selectors/work/work-selectors.tsx';
import type { State, Dispatch } from '../../../../state/types.tsx';
import AddPeopleModal from './add-people-modal/index.tsx';
import FilterBar, { type FilterBarWrapperProps } from './view.tsx';

type DispatchProps = {
	onChange: (
		filters: Filters,
		filterType: FilterType,
		attributes: AnalyticsAttributes,
		analyticsEvent: UIAnalyticsEvent,
	) => // eslint-disable-next-line @typescript-eslint/no-explicit-any
	| any // eslint-disable-next-line @typescript-eslint/no-explicit-any
		| ((filters: Filters, filterType: FilterType, analyticsEvent: UIAnalyticsEvent) => any);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onSearchFieldFocus: () => any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onExtraAvatarMenuOpen: () => any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onFilterPanelMounted: () => any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onClearAllButtonClicked: () => any;
};

type OwnProps = {
	customFiltersQueryRef: PreloadedQuery<viewBoardCustomFiltersQuery> | null | undefined;
	isActiveStandup: boolean;
};

type StateProps = {
	issueParents: Parent[] | null | undefined;
	issueProjects: Record<string, IssueProject>;
	issueTypes: IssueType[] | null | undefined;
	issueTypeGroups: IssueTypeGroup[] | null | undefined;
	isGroupIssueTypesEnabled: boolean | undefined;
	issueParentsLabel: string | undefined;
	isAnyModalOpen: boolean;
	isIssueModalShowing: boolean;
	isCustomFiltersLoading: boolean;
	isAddPeopleButtonEnabled: boolean | undefined;
	isCustomFilterManageLinkEnabled: boolean | undefined;
	isVersionFilterSpotlightEnabled: boolean | undefined;
	filter: Filters;
	assignees: Person[] | undefined;
	labels: string[] | null | undefined;
	AddPeopleModal: typeof AddPeopleModal;
	externalId: string;
	maxAvatars: number;
	projectId: number;
	projectName: string;
	projectKey: string;
	boardId: number;
	sprints: SprintArray;
	customFiltersQueryRef: PreloadedQuery<viewBoardCustomFiltersQuery> | null | undefined;
	isActiveStandup: boolean;
};

const isTMPBoardAddPeopleExperimentEnabled = (state: State) => {
	const canInviteOthersToProject = getCanInviteOthersToProject(state);
	return (
		canInviteOthersToProject &&
		expValEquals('open_invite_for_open_tmp_projects', 'cohort', 'experiment')
	);
};

const isVisualRefreshDisabled = () => {
	return !(isVisualRefreshEnabled() && fg('visual-refresh_drop_4'));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EMPTY_LIST: any[] = [];

export const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
	customFiltersQueryRef: ownProps.customFiltersQueryRef,
	isActiveStandup: ownProps.isActiveStandup,
	assignees: getIsCMPBoard(state)
		? orderedWorkAssigneesAlphabeticallySelector(state)
		: orderedWorkAssigneesByAccountIdSelector(state),
	filter: get(getWorkFilters(state), ['values']),
	issueParentsLabel: getHierarchyNomenclature(state)
		? getHierarchyNomenclature(state)?.firstLevelName
		: firstParentIssueTypeNameSelector(state),
	issueParents: filteredIssueParentsToListItemSelector(state),
	isAnyModalOpen: isAnyModalOpenSelector(state),
	isIssueModalShowing: isIssueModalShowingSelector(state),
	labels: issueLabelsSelector(state),
	issueTypes: sortedIssueTypeSelector(state),
	issueTypeGroups: getIsIncrementPlanningBoard(state) ? issueTypeGroupSelector(state) : EMPTY_LIST,
	isGroupIssueTypesEnabled: getIsIncrementPlanningBoard(state),
	issueProjects: getIssueProjects(state),
	maxAvatars: MAX_AVATARS,
	isAddPeopleButtonEnabled: getIsCMPBoard(state)
		? getPermissionsSelector(state)[CAN_EDIT_BOARD] && isVisualRefreshDisabled()
		: (getPermissionsSelector(state)[CAN_EDIT_BOARD] ||
				isTMPBoardAddPeopleExperimentEnabled(state)) &&
			isVisualRefreshDisabled(),
	isCustomFilterManageLinkEnabled:
		getPermissionsSelector(state)[CAN_EDIT_BOARD] && !getIsCMPBoard(state),
	isVersionFilterSpotlightEnabled:
		getPermissionsSelector(state)[CAN_EDIT_BOARD] && !getIsCMPBoard(state),
	AddPeopleModal,
	externalId: 'software.board',
	projectId: projectIdSelector(state),
	projectName: projectNameSelector(state),
	projectKey: projectKeySelector(state),
	boardId: Number(rapidViewIdSelector(state)),
	isCustomFiltersLoading: isFilteredCardsLoading(state),
	sprints: getSprintsSelector(state),
});

const getOnChange = memoizeOne(
	(dispatch) =>
		(
			filters: Filters,
			filterType: FilterType,
			_: AnalyticsAttributes,
			analyticsEvent: UIAnalyticsEvent,
		) => {
			if (filterType !== CUSTOM_FILTER) {
				getLongTasksMetrics('filter').start('board');
			}
			if (filterType === SPRINT) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const sprintFilterIds = filters[SPRINT] as string[];
				dispatch(selectSprints({ sprintFilterIds }));
			}
			return dispatch(setAllFilters(filters, filterType, analyticsEvent));
		},
);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onSearchFieldFocus: (...args) => dispatch(searchFieldFocus(...args)),
	onExtraAvatarMenuOpen: (...args) => dispatch(extraAvatarMenuOpen(...args)),
	onFilterPanelMounted: (...args) => dispatch(filterPanelMounted(...args)),
	onChange: getOnChange(dispatch),
	onClearAllButtonClicked: () => {
		dispatch(clearAllFiltersClicked()); // used just for the analytics event, has no reducer
		dispatch(clearFilteredCards());
		dispatch(cardAutoScrollRequestOnFilter());
	},
});

const withAnyModalOpen =
	(WrappedComponent: ComponentType<FilterBarWrapperProps>) =>
	(props: StateProps & FilterBarWrapperProps) => {
		const viewMode = useViewMode();
		const isDetailViewModal = viewMode === 'MODAL';
		const { isAnyModalOpen, isIssueModalShowing, ...restProps } = props;
		const isIssueDetailViewModalOpen = isIssueModalShowing && isDetailViewModal;

		return (
			<WrappedComponent
				{...restProps}
				isAnyModalOpen={isIssueDetailViewModalOpen || isAnyModalOpen}
			/>
		);
	};

// @ts-expect-error - TS2345 - Property 'values' is missing in type 'Filters' but required in type 'FilterState'.
export default connect(mapStateToProps, mapDispatchToProps)(withAnyModalOpen(FilterBar));
