import React from 'react';
import { DocumentTitle } from '@atlassian/jira-global-document-title/src/DocumentTitle.tsx';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Sprint } from '../../model/sprint/sprint-types.tsx';
import messages from './messages.tsx';

type Props = {
	boardName: string;
	sprints?: Sprint[];
	isKanbanBoard?: boolean;
} & Intl;

const DocumentTitleWrapper = ({ intl, boardName, sprints, isKanbanBoard }: Props) => {
	return (
		<DocumentTitle
			title={intl.formatMessage(messages.pageTitleWithSprint, {
				boardName,
				sprintCount: sprints?.length || 0,
				sprintName: sprints?.length ? sprints[0].name : '',
				isKanbanBoard,
			})}
		/>
	);
};

export default DocumentTitleWrapper;
