import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/debounceTime';
import type { Action, ActionsObservable, MiddlewareAPI } from '../../state/types.tsx';
import {
	FILTERS_SET,
	SET_ALL_FILTERS,
	type SetAllFiltersAction,
	type SetFiltersAction,
	setFiltersFromJql,
} from '../../state/actions/filter/index.tsx';
import { getCustomFiltersDebounceTime } from '../filtered-cards/utils.tsx';
import { JQL } from '../../model/filter/filter-types.tsx';
import { fetchFieldValues } from '../../services/filter/filter-field-values.tsx';
import {
	cloudIdSelector,
	projectKeySelector,
} from '../../state/selectors/software/software-selectors.tsx';

import { isFilterRefinementEnabled } from '../../state/selectors/work/work-selectors.tsx';

const handleFilterFieldValuesUpdate = (
	action: SetAllFiltersAction | SetFiltersAction,
	store: MiddlewareAPI,
): Observable<Action> => {
	const state = store.getState();
	const cloudId = cloudIdSelector(state);
	const projectKey = projectKeySelector(state);
	const filterRefinementEnabled = isFilterRefinementEnabled(state);

	if (
		filterRefinementEnabled &&
		((action.type === SET_ALL_FILTERS && action.meta.filterType === JQL) ||
			(action.type === FILTERS_SET && action.payload.filterType === JQL))
	) {
		const jql =
			action.type === SET_ALL_FILTERS ? action.payload.filters[JQL] : action.payload.values;
		if (jql && jql.length > 0) {
			return fetchFieldValues({
				cloudId,
				jql: `project = ${projectKey} and ${jql}`,
			}).mergeMap((response) => Observable.of(setFiltersFromJql(response)));
		}
	}

	return Observable.of();
};

// update (old) work-filters with fieldIds from jql to make sure that all existing selectors are still working
export const filterUpdateFieldValuesEpic = (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$
		.ofType(SET_ALL_FILTERS, FILTERS_SET)
		.debounceTime(getCustomFiltersDebounceTime())
		.switchMap((action: SetAllFiltersAction | SetFiltersAction) =>
			handleFilterFieldValuesUpdate(action, store),
		);
