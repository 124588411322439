import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import type { Sprint } from '../../../model/sprint/sprint-types.tsx';

type Props = {
	goal: NonNullable<Sprint['goal']>;
};

export const SprintGoal = ({ goal }: Props) => (
	<Tooltip content={goal} position="bottom-end">
		{(tooltipProps) => (
			<SprintGoalContent tabIndex={0} aria-label={goal} {...tooltipProps}>
				{goal}
			</SprintGoalContent>
		)}
	</Tooltip>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SprintGoalContent = styled.div({
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	color: token('color.text.subtlest'),
	font: token('font.body.UNSAFE_small'),
	marginTop: token('space.025'),
	marginBottom: token('space.025'),
	width: 'fit-content',
	maxWidth: '100%',
});
