import includes from 'lodash/includes';
import isNil from 'lodash/isNil';
import uniqWith from 'lodash/uniqWith';
import type {
	OverlayDependencyItem,
	DependencyItemSource,
} from '@atlassian/jira-aais-dependency-lines-overlay/src/common/ui/types.tsx';
import logger from '@atlassian/jira-common-util-logging/src/log.tsx';
import { swimlaneHeaderHeight } from '@atlassian/jira-platform-board-kit/src/common/constants/styles/index.tsx';
import type { IssueLink as DependencyModalIssueLink } from '@atlassian/jira-portfolio-3-dependency-line-detail/src/common/types.tsx';
import { SWIMLANE_TEAMLESS } from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/constants.tsx';
import {
	isIssueEntryIssue,
	DEFAULT_CARD_HEIGHT,
	CARD_LEFT_RIGHT_MARGIN,
	type IssueId,
	type IssueEntry,
	type IssueLink,
	type IssueLinkType,
} from '@atlassian/jira-software-board-common/src/index.tsx';
import type { RowState } from '@atlassian/jira-software-fast-virtual/src/common/types.tsx';
import type { RowDescriptor } from '@atlassian/jira-software-fast-virtual/src/services/use-virtual/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, createHook, createSelector, type Action } from '@atlassian/react-sweet-state';

type ColumnId = number;

type ColumnPosition = { x: number; width: number; height: number };

type DependencyLinesContainerPosition = { x: number };

type DependencyEdge = 'left' | 'right';

type IssuePosition = RowState & { relativeTop: number };

type SwimlanePosition = {
	isCollapsed: boolean; // Whether the swimlane is collapsed - do not draw lines to/from collapsed swimlanes
	isVisible: boolean; // Whether the swimlane is onscreen - affects y position calculations
	swimlanePosition: RowState; // useVirtual data about swimlane position such as y position on screen and height
};

export type IssueData = {
	id: IssueId;
	columnId: ColumnId;
	teamId?: string | null; // Represents the swimlane that the issue is in
	issueLinks?: IssueLink[] | null;
	typeName?: string;
	typeUrl?: string;
	key: string;
	summary: string;
};

/** Represents a clicked line with X and Y coordinates where the click was done */
export type ClickedLine = {
	fromId: string;
	toId: string;
	x: number;
	y: number;
};

export type State = {
	dependencyLinesContainerPosition: DependencyLinesContainerPosition;
	issuesWithIssueLinkPositionMap: Map<string, IssuePosition>;
	swimlanePositionMap: Map<string, SwimlanePosition>;
	columnPositionMap: Map<ColumnId, ColumnPosition>;
	activeLine: ClickedLine | null;
};

type Actions = typeof actions;

const initialState: State = {
	dependencyLinesContainerPosition: { x: 0 },
	issuesWithIssueLinkPositionMap: new Map(),
	swimlanePositionMap: new Map(),
	columnPositionMap: new Map(),
	activeLine: null,
};

const LINE_VERTICAL_OFFSET = 8;

const actions = {
	// We save the board position because we want to draw lines relative to the board, not the viewport
	// Add the board's left and top positions from line calculations to offset the board
	updateDependencyLinesContainerPosition:
		(dependencyLinesContainerPosition: DependencyLinesContainerPosition): Action<State> =>
		({ setState }) => {
			setState({ dependencyLinesContainerPosition });
		},

	updateIssuesWithIssueLinkPositions:
		(
			issueEntries: IssueEntry[],
			issuesWithIssueLinksIds: Array<IssueId>,
			issuePositions?: RowState[],
			isUnscheduledWorkColumnPanel?: boolean,
			swimlaneId?: string | null,
		): Action<State> =>
		({ setState, getState }) => {
			if (!isUnscheduledWorkColumnPanel && issuePositions) {
				const { swimlanePositionMap } = getState();

				if (swimlanePositionMap === undefined || isNil(swimlaneId)) {
					return;
				}

				const swimlanePosition = swimlanePositionMap.get(swimlaneId);

				if (swimlanePosition === undefined) {
					return;
				}

				const issueIdsWithLinks = issueEntries.reduce<{ issueId: string; index: number }[]>(
					(acc, issueEntry, index) => {
						if (
							isIssueEntryIssue(issueEntry) &&
							includes(issuesWithIssueLinksIds, issueEntry.issueId)
						) {
							acc.push({ issueId: String(issueEntry.issueId), index });
						}
						return acc;
					},
					[],
				);

				const { issuesWithIssueLinkPositionMap } = getState();

				const newIssuesWithIssueLinkPositionMap = new Map(issuesWithIssueLinkPositionMap);

				issueIdsWithLinks.forEach(({ issueId, index }) => {
					const issuePosition = issuePositions[index];
					if (issuePosition) {
						// Don't cache the issue position if relativeTop is negative, as it will draw the line upward of the screen
						const relativeTop = issuePosition.top - swimlanePosition.swimlanePosition.top;
						if (relativeTop >= 0) {
							newIssuesWithIssueLinkPositionMap.set(issueId, {
								...issuePosition,
								relativeTop,
							});
						}
					}
				});

				setState({
					issuesWithIssueLinkPositionMap: newIssuesWithIssueLinkPositionMap,
				});
			}
		},

	updateSwimlanePositions:
		(
			swimlanePositions: RowState[],
			visibleSwimlanePositions: RowDescriptor[],
			swimlaneIds: string[],
			collapsedSwimlaneIds: string[],
		): Action<State> =>
		({ setState }) => {
			const newSwimlanePositionMap = new Map();

			swimlaneIds.forEach((swimlaneId, index) => {
				const swimlanePosition = swimlanePositions[index];
				const isVisible = visibleSwimlanePositions.some(
					(visibleSwimlane) => visibleSwimlane.index === index,
				);
				const isCollapsed = collapsedSwimlaneIds.includes(swimlaneId);
				if (swimlaneId && swimlanePosition) {
					newSwimlanePositionMap.set(swimlaneId, {
						isCollapsed,
						isVisible,
						swimlanePosition,
					});
				}
			});

			setState({ swimlanePositionMap: newSwimlanePositionMap });
		},

	updateColumnPositionMap:
		(columnPosition: ColumnPosition, columnId: number): Action<State> =>
		({ setState, getState }) => {
			const { columnPositionMap } = getState();

			const newColumnPositionMap = new Map(columnPositionMap);

			newColumnPositionMap.set(columnId, columnPosition);

			setState({ columnPositionMap: newColumnPositionMap });
		},

	setActiveLine:
		(activeLine: ClickedLine | null): Action<State> =>
		({ setState }) => {
			setState({ activeLine });
		},
};

const Store = createStore<State, Actions>({
	name: 'dependencyLineStore',
	initialState,
	actions,
});

export const useDependencyLinks = createHook(Store);

const swimlaneColumnIssueEntriesKey = (swimlaneId: string, columnId: number): string =>
	`${swimlaneId}-${columnId}`;

const calculateEstimatedCardPosition = (
	cardIndex: number,
	swimlanePositionTop: number,
	columnHeaderHeight: number,
	edgeOffset: number,
) =>
	swimlanePositionTop +
	DEFAULT_CARD_HEIGHT * cardIndex +
	columnHeaderHeight +
	swimlaneHeaderHeight +
	DEFAULT_CARD_HEIGHT / 2 +
	edgeOffset;

const getYPosition = (
	issue: IssueData,
	issuesWithIssueLinkPositionMap: Map<IssueId, IssuePosition>,
	swimlanePositionMap: Map<string, { isVisible: boolean; swimlanePosition: RowState }>,
	columnPositionMap: Map<number, ColumnPosition>,
	edge: DependencyEdge,
	getOrderedIssueIdsForColumn: (columnId: number, swimlaneId: string) => IssueId[],
	swimlaneColumnIssueEntriesCache: Map<string, IssueId[]>,
): number => {
	const swimlaneId = issue.teamId ?? SWIMLANE_TEAMLESS;

	const swimlanePosition = swimlanePositionMap.get(swimlaneId);

	// When the page loads, it is possible that we try to get this data before it's ready - returning a default value to begin with
	if (issuesWithIssueLinkPositionMap === undefined || swimlanePosition === undefined) {
		return 0;
	}

	const issuePosition = issuesWithIssueLinkPositionMap.get(String(issue.id));

	const columnHeaderHeight = columnPositionMap.get(issue.columnId)?.height ?? 0;
	const edgeOffset = edge === 'left' ? LINE_VERTICAL_OFFSET : -LINE_VERTICAL_OFFSET;

	if (issuePosition) {
		const top =
			swimlanePosition.swimlanePosition.top +
			issuePosition.relativeTop +
			columnHeaderHeight +
			swimlaneHeaderHeight;
		return top + issuePosition.height / 2 + edgeOffset;
	}

	// if the swimlane the issue belongs to is offscreen and we haven't gotten the card position yet, we do an estimate based on the issue's position in the column
	if (swimlanePosition.isVisible === false) {
		const cachedSwimlaneColumnIssueEntries = swimlaneColumnIssueEntriesCache.get(
			swimlaneColumnIssueEntriesKey(swimlaneId, issue.columnId),
		);

		if (cachedSwimlaneColumnIssueEntries) {
			const index = cachedSwimlaneColumnIssueEntries.indexOf(issue.id);
			if (index !== -1) {
				return calculateEstimatedCardPosition(
					index,
					swimlanePosition.swimlanePosition.top,
					columnHeaderHeight,
					edgeOffset,
				);
			}
		}

		const orderedIssueIdsInColumn = getOrderedIssueIdsForColumn(issue.columnId, swimlaneId);
		swimlaneColumnIssueEntriesCache.set(
			swimlaneColumnIssueEntriesKey(swimlaneId, issue.columnId),
			orderedIssueIdsInColumn,
		);

		const index = orderedIssueIdsInColumn.indexOf(issue.id);
		if (index !== -1) {
			return calculateEstimatedCardPosition(
				index,
				swimlanePosition.swimlanePosition.top,
				columnHeaderHeight,
				edgeOffset,
			);
		}
	}

	// If the swimlane is visible, we should theoretically have the issue position and not gotten here.
	logger.safeErrorWithoutCustomerData(
		'plan-increment-common.useDependencyLinks.getYPosition',
		'Failed to find the y position of an issue when calculating dependency line positions.',
	);

	// Return a default value in case we can't find the y position of the card we're trying to draw to.
	return 0;
};

const getXPosition = (
	issue: IssueData,
	dependencyLinesContainerPosition: DependencyLinesContainerPosition,
	columnPositionMap: Map<number, ColumnPosition>,
	edge: DependencyEdge,
): number => {
	const columnPosition = columnPositionMap.get(issue.columnId);

	if (columnPosition) {
		return (
			columnPosition.x +
			(edge === 'left' ? CARD_LEFT_RIGHT_MARGIN : -CARD_LEFT_RIGHT_MARGIN) +
			(edge === 'left' ? 0 : columnPosition.width) -
			dependencyLinesContainerPosition.x
		);
	}

	// Issues must belong to a column - we theoretically shouldn't have gotten here.
	logger.safeErrorWithoutCustomerData(
		'plan-increment-common.useDependencyLinks.getXPosition',
		'Failed to find the x position of an issue when calculating dependency line positions.',
	);

	return 0;
};

const getIssuePosition = (
	state: State,
	issue: IssueData,
	edge: DependencyEdge = 'right',
	getOrderedIssueIdsForColumn: (columnId: number, swimlaneId: string) => IssueId[],
	swimlaneColumnIssueEntriesCache: Map<string, IssueId[]>,
): DependencyItemSource => {
	const {
		dependencyLinesContainerPosition,
		issuesWithIssueLinkPositionMap,
		swimlanePositionMap,
		columnPositionMap,
	} = state;

	const yPosition = getYPosition(
		issue,
		issuesWithIssueLinkPositionMap,
		swimlanePositionMap,
		columnPositionMap,
		edge,
		getOrderedIssueIdsForColumn,
		swimlaneColumnIssueEntriesCache,
	);

	const xPosition = getXPosition(issue, dependencyLinesContainerPosition, columnPositionMap, edge);

	return {
		id: String(issue.id),
		y: yPosition,
		x: xPosition,
		color: 'DARK_GREY',
	};
};

// Arbitrary number - this affects the curvature of the drawn dependency line only.
const ITEM_HEIGHT = 40;

const getDependencyLine = ({
	state,
	from,
	to,
	isOfftrack,
	getOrderedIssueIdsForColumn,
	swimlaneColumnIssueEntriesCache,
}: {
	state: State;
	from: IssueData;
	to: IssueData;
	isOfftrack: boolean;
	getOrderedIssueIdsForColumn: (columnId: number, swimlaneId: string) => IssueId[];
	swimlaneColumnIssueEntriesCache: Map<string, IssueId[]>;
}): OverlayDependencyItem => ({
	itemHeight: ITEM_HEIGHT,
	isOverlapping: isOfftrack,
	from: getIssuePosition(
		state,
		from,
		'right',
		getOrderedIssueIdsForColumn,
		swimlaneColumnIssueEntriesCache,
	),
	to: getIssuePosition(
		state,
		to,
		'left',
		getOrderedIssueIdsForColumn,
		swimlaneColumnIssueEntriesCache,
	),
});

// The list of issues passed in should have already filtered out issues from the unscheduled column.
// If either end of the issue link is undefined, those issues are not in the board.
const issuesAreInBoard = (sourceIssue: IssueData, destinationIssue: IssueData) =>
	sourceIssue !== undefined && destinationIssue !== undefined;

// If either issue in the link belongs to a swimlane that is collapsed, we should not draw that line
const issuesSwimlanesAreNotCollapsed = (
	sourceIssue: IssueData,
	destinationIssue: IssueData,
	swimlanePositionMap: Map<string, SwimlanePosition>,
) => {
	const sourceSwimlaneId = sourceIssue?.teamId ?? SWIMLANE_TEAMLESS;
	const destinationSwimlaneId = destinationIssue?.teamId ?? SWIMLANE_TEAMLESS;
	const sourceSwimlane = swimlanePositionMap.get(sourceSwimlaneId);
	const destinationSwimlane = swimlanePositionMap.get(destinationSwimlaneId);

	return (
		sourceSwimlane &&
		destinationSwimlane &&
		!sourceSwimlane.isCollapsed &&
		!destinationSwimlane.isCollapsed
	);
};

export const getDependencyLinesFromStateAndIssueData = (
	state: State,
	issuesWithLinksById: Record<string, IssueData>,
	showOffTrackDependencies: boolean,
	issueIdsToShowDependencies: IssueId[],
	getOrderedIssueIdsForColumn: (columnId: number, swimlaneId: string) => IssueId[],
): OverlayDependencyItem[] => {
	const { swimlanePositionMap } = state;

	if (
		!issuesWithLinksById ||
		Object.values(issuesWithLinksById).length === 0 ||
		(!showOffTrackDependencies && issueIdsToShowDependencies.length === 0)
	) {
		return [];
	}

	const filteredIssues: IssueData[] = [];

	if (issueIdsToShowDependencies.length) {
		issueIdsToShowDependencies.forEach((id) => {
			const issue = issuesWithLinksById[id];
			if (issue) {
				filteredIssues.push(issue);
			}
		});
	}

	if (showOffTrackDependencies) {
		Object.values(issuesWithLinksById).forEach((issue) => {
			if (issue.issueLinks && issue.issueLinks.length) {
				const offTrackLinks = issue.issueLinks.filter((link) => link.isOfftrack);

				if (offTrackLinks.length) {
					const issueWithOfftrackLinks = {
						...issue,
						issueLinks: offTrackLinks,
					};
					filteredIssues.push(issueWithOfftrackLinks);
				}
			}
		});
	}

	const uniqueIssueLinkIds = new Set();

	/**
	 * Use a cache for the lookup of issue entries in a particular swimlane/column
	 * because the lookup involves looping over many issues in the state and processing the data.
	 * This is necessary for calculating the estimated position of a card if it is offscreen and hasn't yet been rendered onscreen.
	 */
	const swimlaneColumnIssueEntriesCache = new Map<string, IssueId[]>();

	const overlayDependencies = filteredIssues.reduce<OverlayDependencyItem[]>((acc, issue) => {
		if (!issue.issueLinks) {
			return acc;
		}

		// We need to ensure we only include a single entry when there are multiple issue link types
		// between the same source and destination issue so that we don't unnecessarily render multiple lines.
		// We don't need to be opinionated about which one we choose, as the user can click on
		// the line to ascertain the details of the different issue links.
		const uniqueSourceDestinationIssueLinks = uniqWith(
			issue.issueLinks,
			(a, b) => a.sourceId === b.sourceId && a.destinationId === b.destinationId,
		);

		uniqueSourceDestinationIssueLinks.forEach((issueLink) => {
			// Every issue link appears twice, so we don't want to handle ones we have already handled.
			if (uniqueIssueLinkIds.has(issueLink.id)) {
				return;
			}
			uniqueIssueLinkIds.add(issueLink.id);

			const sourceIssue = issuesWithLinksById[issueLink.sourceId];
			const destinationIssue = issuesWithLinksById[issueLink.destinationId];

			if (
				issuesAreInBoard(sourceIssue, destinationIssue) &&
				issuesSwimlanesAreNotCollapsed(sourceIssue, destinationIssue, swimlanePositionMap)
			) {
				acc.push(
					getDependencyLine({
						state,
						from: sourceIssue,
						to: destinationIssue,
						isOfftrack: !!issueLink.isOfftrack,
						getOrderedIssueIdsForColumn,
						swimlaneColumnIssueEntriesCache,
					}),
				);
			}
		});
		return acc;
	}, []);

	return overlayDependencies;
};

export type DependencyIssueLinkType = IssueLinkType & {
	isOutward?: boolean;
};

export type DependencyLinesProps = {
	issuesWithLinksById: Record<string, IssueData>;
	issueLinkTypes: DependencyIssueLinkType[];
	canDelete: boolean;
	onUnlink: (dependencyModalIssueLink: DependencyModalIssueLink) => Promise<void>;
	showOffTrackDependencies: boolean;
	issueIdsToShowDependencies: IssueId[];
	getOrderedIssueIdsForColumn: (columnId: number, swimlaneId: string) => IssueId[];
};

type GetDependencyLinesArgs = Pick<
	DependencyLinesProps,
	| 'issuesWithLinksById'
	| 'showOffTrackDependencies'
	| 'issueIdsToShowDependencies'
	| 'getOrderedIssueIdsForColumn'
>;

const getDependencyLines = createSelector<
	State,
	GetDependencyLinesArgs,
	OverlayDependencyItem[],
	State,
	GetDependencyLinesArgs
>(
	(state: State) => state,
	(_: State, props: GetDependencyLinesArgs) => props,
	(
		state: State,
		{
			issuesWithLinksById,
			showOffTrackDependencies,
			issueIdsToShowDependencies,
			getOrderedIssueIdsForColumn,
		}: GetDependencyLinesArgs,
	) => {
		return getDependencyLinesFromStateAndIssueData(
			state,
			issuesWithLinksById,
			showOffTrackDependencies,
			issueIdsToShowDependencies,
			getOrderedIssueIdsForColumn,
		);
	},
);

export const useIPDependencyLines = createHook(Store, {
	selector: getDependencyLines,
});
