// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { State, HasSeenItKey } from '../../types.tsx';
import { performFetchHasSeenRequest } from './utils.tsx';

export const fetchHasSeen =
	(hasSeenKey: HasSeenItKey): Action<State> =>
	async ({ getState, setState }) => {
		const currentState = getState();

		const currentStateForHasSeenKey = currentState[hasSeenKey];

		if (
			currentStateForHasSeenKey?.fetchStatus === 'fetched' ||
			currentStateForHasSeenKey?.fetchStatus === 'fetching' ||
			// currently no retry on error
			currentStateForHasSeenKey?.fetchStatus === 'errored'
		) {
			return;
		}

		setState({
			[hasSeenKey]: { fetchStatus: 'fetching' },
		});

		const response = await performFetchHasSeenRequest(hasSeenKey);

		if (response === 'errored') {
			setState({
				[hasSeenKey]: {
					fetchStatus: 'errored',
				},
			});
		} else {
			setState({
				[hasSeenKey]: {
					fetchStatus: 'fetched',
					value: response,
				},
			});
		}
	};
