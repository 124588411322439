import React, { useState, useCallback, forwardRef } from 'react';
import { PortalledNudge, type PortalledNudgeProps } from '@atlassiansox/nudge-tooltip';
import type { EntryPointProps } from 'react-relay';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import { IconButton } from '@atlaskit/button/new';
import { token } from '@atlaskit/tokens';
import { SpotlightCard } from '@atlaskit/onboarding';
import { useIntl } from '@atlassian/jira-intl';
import type { FormatMessage } from '@atlassian/jira-shared-types/src/general.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { withChoreographedRender } from '@atlassian/jira-choreographer-services/src/withChoreographedRender.tsx';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useDismissal } from '../controllers/use-dismissal/index.tsx';
import messages from './messages.tsx';

export type UnassignedFilterNudgeProps = {
	onFilterClick: () => void;
};

export const UNASSIGNED_FILTER_NUDGE_ID = 'unassigned-filter-nudge';
const UNASSIGNED_FILTER_NUDGE_ANALYTICS_ID = 'unassignedFilterNudge';

const renderSpotlight = ({
	onDismiss,
	onClick,
	formatMessage,
}: {
	onDismiss: (turnOnUnassignedFilter: boolean) => void;
	onClick: () => void;
	formatMessage: FormatMessage;
}) => {
	return forwardRef<HTMLDivElement>((_, ref) => (
		<div ref={ref}>
			<SpotlightCard
				heading={formatMessage(messages.header)}
				headingAfterElement={
					<IconButton
						icon={(iconProps) => <CrossIcon {...iconProps} color={token('color.icon.inverse')} />}
						onClick={() => onDismiss(false)}
						label={formatMessage(messages.dismissIconLabel)}
						appearance="subtle"
					/>
				}
				actions={[
					{
						text: formatMessage(messages.turnOnUnassignedFilter),
						onClick,
					},
				]}
				width={374}
			>
				{formatMessage(messages.content)}
			</SpotlightCard>
		</div>
	));
};

const PortalledNudgeWithChoreograph = withChoreographedRender<PortalledNudgeProps>(PortalledNudge);

const UnassignedFilterNudge = ({
	props: { onFilterClick },
}: EntryPointProps<{}, {}, UnassignedFilterNudgeProps, {}>) => {
	const [isHidden, setIsHidden] = useState(false);
	const { hide, dismiss, hideCount } = useDismissal();
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onRender = useCallback(() => {
		const event = createAnalyticsEvent({});
		fireUIAnalytics(event, 'nudgeSpotlightCard viewed', UNASSIGNED_FILTER_NUDGE_ANALYTICS_ID, {
			viewedCount: hideCount + 1,
		});
	}, [createAnalyticsEvent, hideCount]);

	const onHide = useCallback(() => {
		const event = createAnalyticsEvent({});
		fireUIAnalytics(event, 'button clicked', UNASSIGNED_FILTER_NUDGE_ANALYTICS_ID, {
			isClickOutside: true,
			hideCount: hideCount + 1,
		});
		setIsHidden(true);
		hide();
	}, [createAnalyticsEvent, hide, hideCount]);

	const onDismiss = useCallback(
		(turnOnUnassignedFilter: boolean) => {
			const event = createAnalyticsEvent({});
			fireUIAnalytics(event, 'button clicked', UNASSIGNED_FILTER_NUDGE_ANALYTICS_ID, {
				isClickOutside: false,
				hideCount: hideCount + 1,
				turnOnUnassignedFilter,
			});
			setIsHidden(true);
			dismiss();
		},
		[createAnalyticsEvent, dismiss, hideCount],
	);

	const onClick = useCallback(() => {
		onFilterClick();
		onDismiss(true);
	}, [onDismiss, onFilterClick]);

	return (
		<PortalledNudgeWithChoreograph
			key="unassigneeAvatarSpotlight"
			nudgeTargetId={UNASSIGNED_FILTER_NUDGE_ID}
			hidden={isHidden}
			setHidden={onHide}
			onOutsideClick={onHide}
			spotlightCard={renderSpotlight({ onDismiss, onClick, formatMessage })}
			position="bottom-start"
			pulseBorderRadius={24}
			onRender={onRender}
			messageId={UNASSIGNED_FILTER_NUDGE_ID}
			zIndex={fg('board_unassigned_nudge_collision_fix') ? layers.card : undefined}
		/>
	);
};

export default UnassignedFilterNudge;
