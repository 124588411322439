export const SCRUM_TEAM = 'Scrum' as const;
export const KANBAN_TEAM = 'Kanban' as const;

export const BOARD_ISSUE_SOURCE = 'Board' as const;
export const PROJECT_ISSUE_SOURCE = 'Project' as const;
export const FILTER_ISSUE_SOURCE = 'Filter' as const;

export const SINGLE_CUSTOM_FIELD_TYPE_KEY =
	'com.atlassian.jira.plugin.system.customfieldtypes:select';
export const MULTI_CUSTOM_FIELD_TYPE_KEY =
	'com.atlassian.jira.plugin.system.customfieldtypes:multiselect';

export const SYSTEM_LABEL_FIELD_ID = 'labels';

export const CUSTOM_FIELD_OPTION_MISSED_ERROR = 'CUSTOM_FIELD_OPTION_MISSED';

export const FILTER_FIELD_ERROR = {
	NOT_FOUND: 'CUSTOM_FIELD_NOT_FOUND', // Custom field is permanently deleted that you cannot restore.
	TRASHED: 'CUSTOM_FIELD_TRASHED', // Custom field is in trash and would be deleted in 2 months after it's trashed. Admin could restore it.
	NOT_IN_PLAN: 'CUSTOM_FIELD_NOT_IN_PLAN', // Custom field is not found in plan settings.
	OPTION_NOT_FOUND: 'CUSTOM_FIELD_OPTION_NOT_FOUND', // Custom field option is either renamed or deleted in the admin page.
} as const;

export const MAX_ACTIVE_BOARDS_OLD = 2; // remove when jpo_adjust_program_board_active_limit is cleaned up
export const MAX_ACTIVE_BOARDS = 10;

export const IP_BOARD_STORY_POINTS_PLANNING_UNIT = 'StoryPoints' as const;
export const IP_BOARD_HOURS_PLANNING_UNIT = 'Hours' as const;
export const IP_BOARD_DAYS_PLANNING_UNIT = 'Days' as const;

// Lowercase the team name in teams.json to follow jira/dev-tooling/packages/eslint-plugin-jira/rules/js/team-name-enforcer-for-error-analytics/index.js
export const ERROR_REPORTING_TEAM = 'axolotl';
export const PACKAGE_NAME = 'jiraPortfolio3PlanIncrementCommon';
export const SERVICES_ERROR_ID = 'services';

export const DOC_LINKS = {
	PROGRAM_BOARD:
		'https://support.atlassian.com/jira-software-cloud/docs/what-is-the-program-board-in-my-plan/',
	LINK_SPRINTS:
		'https://support.atlassian.com/jira-software-cloud/docs/add-and-change-sprints-on-the-program-board-in-your-plan/',
	CREATION:
		'https://support.atlassian.com/jira-software-cloud/docs/what-does-the-program-board-show/',
	CAPACITY_PLANNING:
		'https://support.atlassian.com/jira-software-cloud/docs/enable-capacity-planning-in-advanced-roadmaps/',
	OFFTRACK_DEPENDENCIES:
		'https://support.atlassian.com/jira-software-cloud/docs/what-are-dependencies-in-advanced-roadmaps/',
	PROGRAM_BOARD_RECOMMENDATIONS:
		'https://support.atlassian.com/jira-software-cloud/docs/recommended-preparations-before-using-the-program-board/',
};

export const IPH_IDS = {
	PROGRAM_BOARDS_CREATE: '6gJCMknYvLjnUgKrm4BKIj',
	PROGRAM_BOARDS_SETTINGS: '1pdnvDJUBHtLdAcH6F03rm',
	CAPACITY_PLANNING: '4kDm3TUQ7LwIEubH72m3Gk',
	OFFTRACK_DEPENDENCIES: '2HQv4dgTi14DCpGY1YU2ZF',
	PROGRAM_BOARD_RECOMMENDATIONS: '7CJiNQmImx2kqJa9MhpEwt',
};

export const MARK_SYNC_SPRINTS_PREFIX = 'plan-increment.sync-sprints:';
export const MARK_SYNC_SPRINTS_START = `${MARK_SYNC_SPRINTS_PREFIX}start`;
export const MARK_SYNC_SPRINTS_END = `${MARK_SYNC_SPRINTS_PREFIX}end`;

export const BOARD_SCOPE_FILTER_NONE = 'none';
export const BOARD_SCOPE_FILTER_LABEL = 'label';
export const BOARD_SCOPE_FILTER_SINGLE_SELECT = 'select';
export const BOARD_SCOPE_FILTER_MULTI_SELECT = 'multiselect';

export const DEFAULT_ITERATION_LENGTH = 2;
export const DEFAULT_ITERATIONS_COUNT = 6;

export const ITERATIONS_COUNT_OPTIONS = [
	{ label: '3', value: 3 },
	{ label: '4', value: 4 },
	{ label: '5', value: 5 },
	{ label: '6', value: 6 },
	{ label: '7', value: 7 },
	{ label: '8', value: 8 },
	{ label: '9', value: 9 },
	{ label: '10', value: 10 },
	{ label: '11', value: 11 },
	{ label: '12', value: 12 },
	{ label: '13', value: 13 },
];

export const TEAM = 'TEAM' as const;

export const SWIMLANE_TEAMLESS = '$teamless$';

export const MAX_SELECTED_ISSUES_FOR_DEPENDENCY_LINES = 10;

export const UNSCHEDULED_WORK_COLUMN_SWIMLANE_ID_SUFFIX = '-unscheduled';
export const SCENARIO_ISSUE_ID_PREFIX = 'SCEN-';

export const PLANS_USER_AUTOCOMPLETE_URL = '/rest/teams/1.0/persons/find';
