import React from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import { SuccessAutoDismissFlag, AkFlag } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import { fg } from '@atlassian/jira-feature-gating';
import { getIssueDiscoveryUrl } from '../../common/utils.tsx';
import flagMessages from './messages.tsx';

export type CardCreateUnmappedFlagProps = {
	isConfigureBoardEnabled: boolean;
	cardKeys: string[];
	id: string | number;
	boardConfigUrl: string;
	onDismissed: undefined | ((arg1: string | number) => void);
	isAutoDismissed?: boolean;
};

export const CardCreateUnmappedFlag = ({
	cardKeys,
	onDismissed,
	id,
	isAutoDismissed = false,
}: CardCreateUnmappedFlagProps) => {
	const { formatMessage } = useIntl();
	const numIssues = cardKeys.length;
	const getFlagDescription = () =>
		formatMessage(
			expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? flagMessages.updatedIssueHiddenDescriptionIssueTermRefresh
				: flagMessages.updatedIssueHiddenDescription,
		);
	const getActions = () => {
		const actions = [
			{
				content: formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? flagMessages.issueLinkIssueTermRefresh
						: flagMessages.issueLink,
					{ numIssues },
				),
				href: getIssueDiscoveryUrl(cardKeys),
				target: '_blank',
			},
		];
		return actions;
	};

	if (!isAutoDismissed && fg('fix-gryf-a11y-issues-2')) {
		return (
			<AkFlag
				id={id}
				onDismissed={onDismissed}
				title={formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? flagMessages.updatedIssueHiddenTitleIssueTermRefresh
						: flagMessages.updatedIssueHiddenTitle,
					{ numIssues },
				)}
				description={getFlagDescription()}
				actions={getActions()}
			/>
		);
	}
	return (
		<SuccessAutoDismissFlag
			id={id}
			onDismissed={onDismissed}
			title={formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? flagMessages.updatedIssueHiddenTitleIssueTermRefresh
					: flagMessages.updatedIssueHiddenTitle,
				{ numIssues },
			)}
			description={getFlagDescription()}
			actions={getActions()}
		/>
	);
};
