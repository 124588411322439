export const TEXT = 'TEXT' as const;
export const ASSIGNEE = 'ASSIGNEE' as const;
export const LABEL = 'LABEL' as const;
export const ISSUE_PARENT = 'ISSUE_PARENT' as const;
export const ISSUE_PROJECT = 'ISSUE_PROJECT' as const;
export const ISSUE_TYPE = 'ISSUE_TYPE' as const;
export const VERSION = 'VERSION' as const;
export const COMPONENT = 'COMPONENT' as const;
export const STATUS_CATEGORY = 'STATUS_CATEGORY' as const;
export const SPRINT = 'SPRINT' as const;
export const STATUS = 'STATUS' as const;
export const REQUEST_TYPE = 'REQUEST_TYPE' as const;
export const CUSTOM_FILTER = 'CUSTOM_FILTER' as const;
export const QUICK_FILTER = 'QUICK_FILTER' as const;
export const GOAL = 'GOAL' as const;
export const CLEAR = 'CLEAR' as const;
export const DEPENDENCIES = 'DEPENDENCIES' as const;
export const JQL = 'JQL' as const;

export const UNASSIGNED_USER_ID = 'unassigned' as const;
export const UNASSIGNED_VERSION_ID = 'unassigned' as const;
// NOTE: Since labels need to be unique in names, the label names themselves are used as identifiers
// To avoid conflicts between users-created labels and UNASSIGNED_LABEL_ID, we must use an invalid label value here
// Labels with space are considered invalid and can't be created via UI, which makes them good candidates
export const UNASSIGNED_LABEL_ID = 'label unassigned' as const;
export const UNASSIGNED_COMPONENT_ID = 'unassigned' as const;

export const PRESENCE_OFFLINE = 'offline';
export const PRESENCE_ONLINE = 'online';
