/** @jsx jsx */
import React, { type ReactNode } from 'react';
import { styled, jsx } from '@compiled/react';
import Badge from '@atlaskit/badge';
import { token } from '@atlaskit/tokens';
import { cssMap, cx } from '@atlaskit/css';
import { Box } from '@atlaskit/primitives';
import { FormattedMessage } from '@atlassian/jira-intl';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import TransitiveNumber from '@atlassian/react-transitive-number';
import { useSoftwareProjectTheming } from '@atlassian/jira-project-theme-providers/src/controllers/software-theme/index.tsx';

export type Props = {
	isEditing: boolean;
	isCMPBoard: boolean;
	totalCount: number;
	showTotalCount: boolean;
	visibleCount: number;
};

const countStyles = cssMap({
	root: {
		color: token('color.text.subtlest'),
		minWidth: '0px',
		flexGrow: 0,
		flexShrink: 0,
		marginRight: token('space.050'),
		overflow: 'hidden',
	},
});

const badgeStyles = cssMap({
	root: {
		borderRadius: token('border.radius'),
		backgroundColor: token('color.background.neutral'),
		color: token('color.text'),
		paddingInline: token('space.050'),
		minWidth: '24px',
		textAlign: 'center',
	},
});

const CountWrapper = ({ children }: { children: ReactNode }) => {
	const { isSoftwareFullTheming } = useSoftwareProjectTheming();

	return isSoftwareFullTheming ? (
		<Box xcss={cx(countStyles.root)}>{children}</Box>
	) : (
		<Count>{children}</Count>
	);
};

const ColumnCardCount = ({
	isCMPBoard,
	isEditing,
	totalCount,
	showTotalCount,
	visibleCount,
}: Props) => {
	if (isEditing || totalCount === 0) {
		return null;
	}

	if (showTotalCount) {
		if (isCMPBoard) {
			return (
				<CountWrapper>
					<BadgeWrapper>
						<FormattedMessage
							id="platform-board.column-header-count-uif"
							defaultMessage="{visibleCountNumber}/{totalCountNumber}"
							values={{
								visibleCountNumber: <TransitiveNumber>{visibleCount}</TransitiveNumber>,
								totalCountNumber: <TransitiveNumber>{totalCount}</TransitiveNumber>,
							}}
						/>
					</BadgeWrapper>
				</CountWrapper>
			);
		}
		return (
			<CountWrapper>
				<BadgeWrapper>
					<FormattedMessage
						id="platform-board.column-header-count"
						defaultMessage="{visibleCountNumber} of {totalCountNumber}"
						values={{
							visibleCountNumber: <TransitiveNumber>{visibleCount}</TransitiveNumber>,
							totalCountNumber: <TransitiveNumber>{totalCount}</TransitiveNumber>,
						}}
					/>
				</BadgeWrapper>
			</CountWrapper>
		);
	}

	return (
		<CountWrapper>
			<BadgeWrapper>
				<TransitiveNumber>{visibleCount}</TransitiveNumber>
			</BadgeWrapper>
		</CountWrapper>
	);
};

export default ColumnCardCount;

// remove Count styled component on clean up - jira_theming_milestone_1
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Count = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest'),
	minWidth: 0,
	flexGrow: 0,
	flexShrink: 0,
	marginRight: token('space.050'),
});

const BadgeWrapper = ({ children }: { children: ReactNode }) => {
	const { isSoftwareFullTheming } = useSoftwareProjectTheming();

	if (isVisualRefreshEnabled()) {
		if (isSoftwareFullTheming) {
			return <Box xcss={badgeStyles.root}>{children} </Box>;
		}
		return <Badge>{children} </Badge>;
	}
	return <>{children}</>;
};
