import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { MigrationIdStore } from './types.tsx';

const MIGRATION_ID_STORE_KEY = 'X2C-Migration-Id-Store';

// TODO: JFP-2248 Please add the prefix for storage provider here
const localStorageProvider = createLocalStorageProvider('');
export const getMigrationIdStore = (): MigrationIdStore =>
	fg('jfp-vulcan-browser-storage-migration')
		? localStorageProvider.get(MIGRATION_ID_STORE_KEY) || JSON.parse('{}')
		: JSON.parse(localStorage.getItem(MIGRATION_ID_STORE_KEY) || '{}');

export const setMigrationIdStore = (migrationIdStore: MigrationIdStore) =>
	fg('jfp-vulcan-browser-storage-migration')
		? localStorageProvider.set(MIGRATION_ID_STORE_KEY, migrationIdStore)
		: localStorage.setItem(MIGRATION_ID_STORE_KEY, JSON.stringify(migrationIdStore));

export const removeMigrationIdStore = () =>
	fg('jfp-vulcan-browser-storage-migration')
		? localStorageProvider.remove(MIGRATION_ID_STORE_KEY)
		: localStorage.removeItem(MIGRATION_ID_STORE_KEY);
