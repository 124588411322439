import React, { forwardRef, useCallback, useRef } from 'react';
import Button, {
	CustomThemeButton,
	type ButtonProps,
	type CustomThemeButtonProps,
} from '@atlaskit/button';
import {
	type ButtonProps as ButtonNewProps,
	IconButton,
	type IconButtonProps,
} from '@atlaskit/button/new';

import { ThemedButtonNew } from '@atlassian/jira-project-theme-components/src/ui/themed-button-new/ThemedButtonNew.tsx';
import { useModalDialogActions } from '../../controllers/index.tsx';

type Props =
	| ButtonProps
	| CustomThemeButtonProps
	| IconButtonProps
	| (ButtonNewProps & {
			isThemed?: boolean;
	  });

/**
 * When clicked, a reference to this button will be stored,
 * allowing focus to return to it upon the dismissal of a
 * modal. Works in tandem with ModalDialog that is exposed
 * via @atlassian/jira-software-modal-dialog.
 *
 * Accepts either ButtonProps OR CustomThemeButtonProps
 * as per @atlaskit/button
 */
export const ModalTriggerButton = forwardRef<HTMLButtonElement | null, Props>((props, ref) => {
	const internalRef = useRef<HTMLButtonElement | null>(null);
	const { setReturnFocusTo } = useModalDialogActions();

	const handleRef = useCallback(
		(element: HTMLButtonElement) => {
			if (ref) {
				if (typeof ref === 'function') {
					ref(element);
				} else {
					// We need to proxy ref to avoid an eslint error (no-param-reassign)
					const mutableRef = ref;
					mutableRef.current = element;
				}
			}
			internalRef.current = element;
		},
		[ref],
	);

	const isIconProp = 'icon' in props;
	const onClickHandler = !isIconProp ? props.onClick : undefined;
	const iconButtonOnClickHandler = isIconProp ? props.onClick : undefined;

	const onClick = useCallback(
		(...args: Parameters<NonNullable<ButtonProps['onClick']>>) => {
			if (!isIconProp) {
				// @ts-expect-error - Type 'HTMLElement' is missing the following properties from type 'HTMLButtonElement': disabled, form, formAction, formEnctype, and 15 more.ts(2345)
				onClickHandler?.(...args);
				setReturnFocusTo(internalRef);
			}
		},
		[isIconProp, setReturnFocusTo, internalRef, onClickHandler],
	);

	const onIconButtonClick = useCallback(
		(...args: Parameters<NonNullable<IconButtonProps['onClick']>>) => {
			if (isIconProp) {
				iconButtonOnClickHandler?.(...args);
				setReturnFocusTo(internalRef);
			}
		},
		[isIconProp, setReturnFocusTo, internalRef, iconButtonOnClickHandler],
	);

	if ('icon' in props) {
		return <IconButton {...props} ref={handleRef} onClick={onIconButtonClick} />;
	}

	if ('theme' in props) {
		return <CustomThemeButton {...props} ref={handleRef} onClick={onClick} />;
	}

	if ('isThemed' in props) {
		// TODO: this is a temporary solution to make sure themed button only exist for board
		// we should clean up this component so the types don't need to be suppressed
		// https://hello.jira.atlassian.cloud/browse/DEE-6985
		const { isThemed, ...others } = props;
		if (isThemed) {
			return <ThemedButtonNew {...others} ref={handleRef} onClick={onClick} />;
		}
	}
	// @ts-expect-error - TS2322 - Type 'Props' is not assignable to type 'ButtonProps'.
	return <Button {...props} ref={handleRef} onClick={onClick} />;
});
