/** @jsx jsx */
import React, {
	type ComponentType,
	Component,
	cloneElement,
	type ReactNode,
	type ReactElement,
	// eslint-disable-next-line jira/restricted/react-component-props
	type ComponentProps,
	useState,
	useMemo,
	useCallback,
} from 'react';
import { styled } from '@compiled/react';
import isBoolean from 'lodash/isBoolean';
import { Inline, Box, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import { css, cssMap, cx, jsx } from '@atlaskit/css';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { columnHeaderHeight, layout } from '../../../constants/styles/index.tsx';
import { ColumnTheme, type ColumnId } from '../../../types.tsx';
import { columnThemes } from '../../column/index.tsx';

type ColumnHeaderContainerExperimentProps = {
	isDraggable?: boolean;
	appearance: ColumnTheme;
	isMenuShown?: boolean;
	canShowMenu?: boolean;
	isTanukiA11yFixEnabled?: boolean;
};

type State = {
	isMenuOpen: boolean;
};

type Props = {
	isEditingTitle?: boolean;
	isDragging?: boolean;
	isDraggable?: boolean;
	isSpinnerVisible?: boolean;
	isMenuEnabled?: boolean;
	isMenuShown?: boolean | null;
	id: ColumnId;
	title: ReactNode;
	titleHeadingText?: string;
	appearance?: ColumnTheme;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	menu?: ReactElement<ComponentProps<ComponentType<any>>> | null;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	OverlayContent?: ComponentType<any> | undefined;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onClick?: () => any;
	isMatrixLayout?: boolean;
};

// eslint-disable-next-line jira/react/no-class-components
class ColumnHeaderOld extends Component<Props, State> {
	static defaultProps = {
		isDragging: false,
		isDraggable: false,
		isSpinnerVisible: false,
		isMenuEnabled: true,
		isEditingTitle: false,
	};

	state: State = {
		isMenuOpen: false,
	};

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (
			nextProps.isDragging !== this.props.isDragging &&
			nextProps.isDragging &&
			this.state.isMenuOpen
		) {
			this.setState({
				isMenuOpen: false,
			});
		}
	}

	timeoutId: NodeJS.Timeout | null = null;

	onMenuOpenChange = (attrs: { isOpen: boolean }) => {
		this.setState({
			isMenuOpen: attrs.isOpen,
		});
	};

	hasTemporaryId = () => Number(this.props.id) <= 0;

	canShowMenu = () =>
		this.props.isMenuEnabled &&
		!this.hasTemporaryId() &&
		!this.props.isEditingTitle &&
		!this.props.isDragging;

	renderMenu = () => {
		const { titleHeadingText } = this.props;
		if (this.props.menu) {
			return (
				<div
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className="agility-column-menu-meatball"
					data-testid="platform-board-kit.common.ui.column-header.header.meatball"
				>
					{cloneElement(this.props.menu, {
						title: titleHeadingText,
						onOpenChange: this.onMenuOpenChange,
					})}
				</div>
			);
		}

		return null;
	};

	onFocus = () => {
		this.timeoutId && clearTimeout(this.timeoutId);
		if (!this.state.isMenuOpen && this.canShowMenu()) {
			this.setState({
				isMenuOpen: true,
			});
		}
	};

	onBlur = () => {
		this.timeoutId = setTimeout(() => {
			if (this.state.isMenuOpen) {
				this.setState({
					isMenuOpen: false,
				});
			}
		}, 0);
	};

	render() {
		const { title, isSpinnerVisible, isDraggable, onClick, OverlayContent, appearance } =
			this.props;

		return (
			<ColumnHeaderContainer
				onClick={onClick}
				canShowMenu={this.canShowMenu()}
				isMenuShown={
					this.props.isMenuShown != null ? this.props.isMenuShown : this.state.isMenuOpen
				}
				isDraggable={isDraggable}
				onFocus={this.onFocus}
				onBlur={this.onBlur}
				appearance={appearance ?? ColumnTheme.Default}
				isTanukiA11yFixEnabled={fg('column_reordering_using_keyboard')}
				data-testid="platform-board-kit.common.ui.column-header.header.column-header-container"
			>
				{OverlayContent && <OverlayContent />}
				{isVisualRefreshEnabled() && fg('visual-refresh_drop_3') ? (
					<Inline
						spread="space-between"
						alignBlock="center"
						xcss={[fg('visual-refresh_drop_6') ? ColumnHeaderStyles : ColumnHeaderStylesOld]}
					>
						<TitleWrapper>{title}</TitleWrapper>
						<Box
							xcss={[
								!(isVisualRefreshEnabled() && fg('visual-refresh_drop_6')) && menuWrapperStyles,
							]}
							// Prevent menu click to bubble up and fire header analytic event.
							onClick={(event: React.MouseEvent) => event.stopPropagation()}
						>
							{this.renderMenu()}
							{isSpinnerVisible && (
								<SpinnerContainer>
									<Spinner size="small" />
								</SpinnerContainer>
							)}
						</Box>
					</Inline>
				) : (
					<>
						<TitleWrapperOld>{title}</TitleWrapperOld>
						<ColumnHeaderActionsOld
							// Prevent menu click to bubble up and fire header analytic event.
							onClick={(event: React.MouseEvent) => event.stopPropagation()}
						>
							{this.renderMenu()}
							{isSpinnerVisible && (
								<SpinnerContainer>
									<Spinner size="small" />
								</SpinnerContainer>
							)}
						</ColumnHeaderActionsOld>
					</>
				)}
			</ColumnHeaderContainer>
		);
	}
}

const ColumnHeaderNew = ({
	id,
	menu,
	title,
	onClick,
	isDragging,
	appearance,
	isDraggable,
	isMenuShown,
	isMenuEnabled,
	OverlayContent,
	isEditingTitle,
	titleHeadingText,
	isSpinnerVisible,
	isMatrixLayout,
}: Props) => {
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
	const [isContainerFocused, setIsContainerFocused] = useState<boolean>(false);

	const hasTemporaryId = useMemo(() => Number(id) <= 0, [id]);

	const backgroundColor = useMemo(
		() => getBackgroundColor(appearance ?? ColumnTheme.Default),
		[appearance],
	);

	const isMenuTriggerShown = useMemo(
		() => (isBoolean(isMenuShown) ? isMenuShown : isContainerFocused || isMenuOpen),
		[isMenuShown, isContainerFocused, isMenuOpen],
	);

	const canShowMenu = useMemo(
		() => isMenuEnabled && !hasTemporaryId && !isEditingTitle && !isDragging,
		[isMenuEnabled, isEditingTitle, isDragging, hasTemporaryId],
	);

	const dynamicBackgroundColorStyleOverride = css({
		// As we can't access this div element from our component scope, adding this to avoid button background when focusing on container
		// @ts-expect-error Property Type '{ backgroundColor: string; }' is not assignable to type 'undefined'.ts(2322)
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'& > div': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
			backgroundColor,
		},
	});

	const onMenuOpenChange = useCallback((attrs: { isOpen: boolean }) => {
		setIsMenuOpen(attrs.isOpen);
	}, []);

	const renderMenu = useCallback(
		() =>
			menu ? (
				<div
					css={[
						headerStyleMap.menuWrapperBase,
						canShowMenu && headerStyleMap.menuWrapperVisibility,
						isMenuTriggerShown && headerStyleMap.menuWrapperOpacity,
						// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
						dynamicBackgroundColorStyleOverride,
					]}
					data-testid="platform-board-kit.common.ui.column-header.header.meatball"
				>
					{cloneElement(menu, {
						title: titleHeadingText,
						onOpenChange: onMenuOpenChange,
					})}
				</div>
			) : null,
		[
			canShowMenu,
			dynamicBackgroundColorStyleOverride,
			isMenuTriggerShown,
			menu,
			onMenuOpenChange,
			titleHeadingText,
		],
	);

	const onContainerFocus = useCallback(() => {
		if (canShowMenu) {
			setIsContainerFocused(true);
		}
	}, [canShowMenu]);

	const onContainerBlur = () => {
		setIsContainerFocused(false);
	};

	return (
		<Box
			onClick={onClick}
			testId="platform-board-kit.common.ui.column-header.header.column-header-container"
			onFocus={onContainerFocus}
			onBlur={onContainerBlur}
			onMouseEnter={onContainerFocus}
			onMouseLeave={onContainerBlur}
			xcss={cx(
				headerStyleMap.columnHeaderContainerBase,
				isDraggable && headerStyleMap.cursorGrab,
				isMatrixLayout ? headerStyleMap.roundedBox : headerStyleMap.roundedTopBorder,
			)}
			// eslint-disable-next-line jira/react/no-style-attribute
			style={{ backgroundColor }}
		>
			{OverlayContent && <OverlayContent />}
			{isVisualRefreshEnabled() && fg('visual-refresh_drop_3') ? (
				<Inline
					spread="space-between"
					alignBlock="center"
					xcss={[fg('visual-refresh_drop_6') ? ColumnHeaderStyles : ColumnHeaderStylesOld]}
				>
					<div css={[headerStyleMap.titleWrapper]}>{title}</div>
					<Box
						xcss={[!(isVisualRefreshEnabled() && fg('visual-refresh_drop_6')) && menuWrapperStyles]}
						// Prevent menu click to bubble up and fire header analytic event.
						onClick={(event: React.MouseEvent) => event.stopPropagation()}
					>
						{renderMenu()}
						{isSpinnerVisible && (
							<SpinnerContainer>
								<Spinner size="small" />
							</SpinnerContainer>
						)}
					</Box>
				</Inline>
			) : (
				<>
					<TitleWrapperOld>{title}</TitleWrapperOld>
					<ColumnHeaderActionsOld
						// Prevent menu click to bubble up and fire header analytic event.
						onClick={(event: React.MouseEvent) => event.stopPropagation()}
					>
						{renderMenu()}
						{isSpinnerVisible && (
							<SpinnerContainer>
								<Spinner size="small" />
							</SpinnerContainer>
						)}
					</ColumnHeaderActionsOld>
				</>
			)}
		</Box>
	);
};
ColumnHeaderNew.defaultProps = {
	isDragging: false,
	isDraggable: false,
	isSpinnerVisible: false,
	isMenuEnabled: true,
	isEditingTitle: false,
};
const ColumnHeader = componentWithFG(
	'jfp_a11y_team_board_column_focus_return',
	ColumnHeaderNew,
	ColumnHeaderOld,
);
export default ColumnHeader;

const getBackgroundColor = (appearance: ColumnTheme): string => {
	return fg('jira_theming_milestone_1_fg')
		? columnThemes[appearance].backgroundNew
		: columnThemes[appearance].background;
};

const SpinnerContainer = ({ children }: { children: ReactNode }) => (
	<Box xcss={refactoredSpinnerStyle}>{children}</Box>
);

const headerStyleMap = cssMap({
	columnHeaderContainerBase: {
		userSelect: 'none',
		width: '100%',
		maxWidth: '100%',
		boxSizing: 'border-box',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		height: `${columnHeaderHeight}px`,
		position: 'relative',
		transition: 'background-color 0.2s ease',
	},
	cursorGrab: {
		cursor: 'grab',
	},
	menuWrapperBase: {
		visibility: 'hidden',
		opacity: 0,
		width: '0px',
	},
	menuWrapperVisibility: {
		visibility: 'visible',
	},
	menuWrapperOpacity: {
		opacity: 1,
		width: 'auto',
		paddingRight: token('space.050'),
	},
	titleWrapper: {
		flex: '1 1 auto',
		minWidth: `${0}px`,
		'&:focus': {
			boxShadow: token('elevation.shadow.overflow'),
			outline: 'none',
		},
	},
	roundedBox: {
		// @ts-expect-error Type '"6px"' is not assignable to type '"inherit" | "var(--ds-border-radius-050)" | "var(--ds-border-radius)" | "var(--ds-border-radius-100)" | "var(--ds-border-radius-200)" | "var(--ds-border-radius-300)" | "var(--ds-border-radius-400)" | "var(--ds-border-radius-circle)" | undefined'.ts(2322)
		borderRadius: '6px',
		marginBottom: token('space.100'),
	},
	roundedTopBorder: {
		// @ts-expect-error Type '"6px"' is not assignable to type '"inherit" | "var(--ds-border-radius-050)" | "var(--ds-border-radius)" | "var(--ds-border-radius-100)" | "var(--ds-border-radius-200)" | "var(--ds-border-radius-300)" | "var(--ds-border-radius-400)" | "var(--ds-border-radius-circle)" | undefined'.ts(2322)
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
		borderTopLeftRadius: `${layout.cardListRadius}px`,
		// @ts-expect-error Type '"6px"' is not assignable to type '"inherit" | "var(--ds-border-radius-050)" | "var(--ds-border-radius)" | "var(--ds-border-radius-100)" | "var(--ds-border-radius-200)" | "var(--ds-border-radius-300)" | "var(--ds-border-radius-400)" | "var(--ds-border-radius-circle)" | undefined'.ts(2322)
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
		borderTopRightRadius: `${layout.cardListRadius}px`,
	},
});

const refactoredSpinnerStyle = xcss({ paddingRight: 'space.100' });

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColumnHeaderContainer = styled.div<ColumnHeaderContainerExperimentProps>({
	userSelect: 'none',
	width: '100%',
	maxWidth: '100%',
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	height: `${columnHeaderHeight}px`,
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ appearance }) => getBackgroundColor(appearance),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderTopLeftRadius: `${layout.cardListRadius}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderTopRightRadius: `${layout.cardListRadius}px`,
	transition: 'background-color 0.2s ease',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ isDraggable }: ColumnHeaderContainerExperimentProps) =>
		isDraggable ? 'grab' : 'default',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& .agility-column-menu-meatball': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		position: (props: ColumnHeaderContainerExperimentProps) =>
			props.isMenuShown ? 'initial' : 'absolute',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		visibility: (props: ColumnHeaderContainerExperimentProps) =>
			props.isMenuShown ? 'initial' : 'hidden',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		opacity: (props: ColumnHeaderContainerExperimentProps) => (props.isMenuShown ? '1' : '0'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		transition: (props: ColumnHeaderContainerExperimentProps) =>
			props.isTanukiA11yFixEnabled ? 'undefined' : 'opacity 0s linear 0.3s',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& > div': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			backgroundColor: ({ appearance }) => getBackgroundColor(appearance),
		},
	},

	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& .agility-column-menu-meatball': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			position: (props: ColumnHeaderContainerExperimentProps) =>
				props.canShowMenu ? 'initial' : 'absolute',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			visibility: (props: ColumnHeaderContainerExperimentProps) =>
				props.canShowMenu ? 'initial' : 'hidden',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			opacity: (props: ColumnHeaderContainerExperimentProps) => (props.canShowMenu ? '1' : '0'),
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColumnHeaderActionsOld = styled.div({
	display: 'flex',
	position: 'absolute',
	right: token('space.100'),
	top: token('space.300'),
	transform: 'translateY(-50%)',
});

const ColumnHeaderStylesOld = xcss({
	height: '100%',
	paddingRight: 'space.050',
});

const ColumnHeaderStyles = xcss({
	height: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitleWrapperOld = styled.div({
	display: 'flex',
	flexFlow: 'column nowrap',
	justifyContent: 'center',
	alignItems: 'stretch',
	flex: '1 1 auto',
	alignSelf: 'auto',
	maxWidth: '100%',
	'&:focus': {
		boxShadow: `inset 0 0 0 2px ${token('color.border.focused')}`,
		outline: 'none',
	},

	paddingTop: token('space.200'),
	paddingRight: 0,
	paddingBottom: token('space.200'),
	paddingLeft: 0,
	/* ie11 below */
	width: '100%',

	height: `calc(100% - ${token('space.400')})`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitleWrapper = styled.div({
	flex: '1 1 auto',
	minWidth: 0,

	'&:focus': {
		boxShadow: 'elevation.shadow.overflow',
		outline: 'none',
	},
});

const menuWrapperStyles = xcss({
	flexShrink: 0,
	width: token('space.400'),
});
