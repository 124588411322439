import React, { useCallback, type KeyboardEvent, type MouseEvent } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { ButtonItem } from '@atlaskit/menu';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import { useScreenReader } from '../../../../screen-reader-context/index.tsx';
import messages from './messages.tsx';
import type { ItemMoveViewProps } from './types.tsx';

export const ItemMoveView = ({ onClick, isDisabled, direction }: ItemMoveViewProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { setScreenReaderText } = useScreenReader();

	const handleOnClick = useCallback(
		(event: MouseEvent | KeyboardEvent) => {
			const analyticsEvent = createAnalyticsEvent({
				actionSubject:
					direction === 'left'
						? 'columnSettingsMoveLeftMenuItem'
						: 'columnSettingsMoveRightMenuItem',
				action: 'clicked',
			});
			setScreenReaderText?.(formatMessage(messages.columnMoved, { direction }));
			onClick(event, analyticsEvent);
			fireUIAnalytics(analyticsEvent);
		},
		[createAnalyticsEvent, direction, onClick, setScreenReaderText, formatMessage],
	);
	if (isDisabled) {
		return null;
	}

	return fg('horizn_490_consolidate_old_issues_in_board') ? (
		<ButtonItem
			onClick={handleOnClick}
			isDisabled={isDisabled}
			role="menuitem"
			data-testid={`software-board.board-container.board.column.header.menu.item-move-${direction}.dropdown-item`}
		>
			{direction === 'left' ? formatMessage(messages.moveLeft) : formatMessage(messages.moveRight)}
		</ButtonItem>
	) : (
		<DropdownItem
			onClick={handleOnClick}
			isDisabled={isDisabled}
			data-testid={`software-board.board-container.board.column.header.menu.item-move-${direction}.dropdown-item`}
		>
			{direction === 'left' ? formatMessage(messages.moveLeft) : formatMessage(messages.moveRight)}
		</DropdownItem>
	);
};

export default ItemMoveView;
