import React, { useMemo } from 'react';
import FilterIcon from '@atlaskit/icon/core/filter';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { CommandShortcuts } from '@atlassian/jira-command-palette-common/src/ui/command-shortcuts/index.tsx';
import { COMMAND_PALETTE_SECTIONS } from '@atlassian/jira-command-palette-common/src/common/constants.tsx';
import { COMMAND_PALETTE_QUICK_ACTIONS_PRIORITY } from '@atlassian/jira-command-palette-common/src/constants.tsx';
import { useCommandKeywords } from '@atlassian/jira-command-palette-common/src/controllers/use-command-keywords/index.tsx';
import messages from './messages.tsx';

interface KeyboardShortcutsProps {
	onShortcutTriggered: () => void;
}

const KeyboardShortcuts = ({ onShortcutTriggered }: KeyboardShortcutsProps) => {
	const { formatMessage } = useIntl();
	const { getKeywords } = useCommandKeywords();

	const keyMap = useMemo(
		() => ({
			F: {
				callback: onShortcutTriggered,
				registerInCommandPalette: {
					id: 'filter-refinement-open-filter-popup',
					name: formatMessage(messages.openFilterPopup),
					keywords: getKeywords('pageSearchSynonyms'),
					components: {
						LeftIcon: () => (
							<FilterIcon
								label={formatMessage(messages.openFilterPopup)}
								color={token('color.icon')}
							/>
						),
					},
					priority: COMMAND_PALETTE_QUICK_ACTIONS_PRIORITY.openFilterPopup,
					section: COMMAND_PALETTE_SECTIONS.quickActions,
				},
			},
		}),
		[formatMessage, getKeywords, onShortcutTriggered],
	);

	return <CommandShortcuts keyMap={keyMap} />;
};

export default KeyboardShortcuts;
