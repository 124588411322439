import React, {
	type ComponentType,
	type ComponentPropsWithRef,
	useEffect,
	useRef,
	useState,
	forwardRef,
} from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import ChevronRightIcon from '@atlaskit/icon/utility/migration/chevron-right';
import { Box, Flex, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { AnalyticsEventToProps } from '@atlassian/jira-product-analytics-bridge';
import { useEvent } from '@atlassian/jira-software-react-use-event/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	CONTEXT_MENU_ITEM_CHEVRON_TESTID,
	CONTEXT_MENU_ITEM_LABEL_TESTID,
	CONTEXT_MENU_ITEM_TESTID,
	CONTEXT_MENU_ITEM_TOOLTIP_TESTID,
	ITEM_LINE_HEIGHT,
} from '../../../../../../common/constants.tsx';
import type { MenuItem } from '../../../../../../common/types.tsx';
import type { Props } from './types.tsx';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const ContextMenuItemButtonAnalyticsWrapper = AnalyticsEventToProps('dropdownItem', {
	onClick: 'clicked',
})(
	forwardRef(({ createAnalyticsEvent, ...props }, ref) => (
		<StyledContextMenuItemButton {...props} ref={ref} />
	)),
) as ComponentType<
	Omit<ComponentPropsWithRef<typeof StyledContextMenuItemButton>, 'onClick' | 'onKeyDown'> & {
		onClick: (
			e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
			analyticsEvent: UIAnalyticsEvent,
		) => void;
		onKeyDown: (e: React.KeyboardEvent<HTMLButtonElement>) => void;
	}
>;

export const InnerLabel = forwardRef(
	(
		{ menuItem, hasChildren }: { menuItem: MenuItem; hasChildren: boolean },
		ref: React.ForwardedRef<HTMLSpanElement | null>,
	) => {
		const getContextMenuLabel = () => {
			const menuItemLabel = typeof menuItem.label === 'string' ? menuItem.label : menuItem.label();
			if (menuItem.elemAfterLabel) {
				return (
					<Flex
						alignItems="center"
						as="span"
						ref={ref}
						testId={CONTEXT_MENU_ITEM_LABEL_TESTID}
						xcss={menuItemLabelWrapperStyles}
					>
						<Text maxLines={1}>{menuItemLabel}</Text>
						{menuItem.elemAfterLabel}
					</Flex>
				);
			}
			return (
				<ContextMenuLabel ref={ref} data-testid={CONTEXT_MENU_ITEM_LABEL_TESTID}>
					{menuItemLabel}
				</ContextMenuLabel>
			);
		};
		return (
			<>
				{menuItem.icon && <Box xcss={contextMenuIconStyles}>{menuItem.icon}</Box>}

				{getContextMenuLabel()}

				{hasChildren && (
					<ChevronRightIcon
						spacing="spacious"
						label={fg('jpo_a11y_pb_fixes') ? '' : menuItem.id}
						testId={CONTEXT_MENU_ITEM_CHEVRON_TESTID}
					/>
				)}
			</>
		);
	},
);

export const useLabelRef = () => useRef<HTMLSpanElement | null>(null);

export const ContextMenuItemButton = forwardRef(
	(
		{ menuItem, isOpen, onMouseEnter, onClick, hasChildren, onOpenWithKeyboard }: Props,
		ref: React.ForwardedRef<HTMLButtonElement | null>,
	) => {
		const [hasEllipsis, setHasEllipsis] = useState(false);
		const labelRef = useLabelRef();
		const onMouseEnterHandler = useEvent(() => onMouseEnter && onMouseEnter(menuItem));

		const onHandleClick = useEvent((e: React.MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
			e.preventDefault();
			e.stopPropagation();
			if (e.detail === 0) {
				onOpenWithKeyboard?.();
			}
			onClick(menuItem, analyticsEvent);
		});

		const onHandleKeyDown = useEvent((e: React.KeyboardEvent) => {
			if (e.key === 'Enter') {
				e.stopPropagation();
			}
		});

		useEffect(() => {
			if (labelRef.current) {
				const isContentOverflowing = labelRef.current.scrollWidth > labelRef.current.clientWidth;
				setHasEllipsis(isContentOverflowing);
			}
		}, [labelRef]);

		const commonProps = {
			'data-testid': CONTEXT_MENU_ITEM_TESTID,
			ref,
			isOpen,
			onClick: onHandleClick,
			onMouseEnter: onMouseEnterHandler,
			onKeyDown: onHandleKeyDown,
		};

		return hasEllipsis ? (
			<Tooltip
				testId={CONTEXT_MENU_ITEM_TOOLTIP_TESTID}
				content={typeof menuItem.label === 'string' ? menuItem.label : menuItem.label()}
			>
				{({ ref: tooltipRef, ...rest }) => (
					<ContextMenuItemButtonAnalyticsWrapper
						{...rest}
						{...commonProps}
						{...(hasChildren && { 'aria-expanded': !!isOpen })}
					>
						<InnerLabel
							hasChildren={hasChildren}
							menuItem={menuItem}
							ref={(r) => {
								labelRef.current = r;
								tooltipRef(r);
							}}
						/>
					</ContextMenuItemButtonAnalyticsWrapper>
				)}
			</Tooltip>
		) : (
			<ContextMenuItemButtonAnalyticsWrapper
				{...commonProps}
				{...(hasChildren && { 'aria-expanded': !!isOpen })}
			>
				<InnerLabel hasChildren={hasChildren} menuItem={menuItem} ref={labelRef} />
			</ContextMenuItemButtonAnalyticsWrapper>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledContextMenuItemButton = styled.button<{
	isOpen: boolean | undefined;
}>({
	width: '100%',
	border: 'none',
	margin: 0,
	textAlign: 'left',
	paddingTop: token('space.100'),
	paddingRight: token('space.250'),
	paddingBottom: token('space.100'),
	paddingLeft: token('space.250'),
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: ITEM_LINE_HEIGHT,
	gap: token('space.050'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: (props) => (props.isOpen ? token('color.background.selected') : 'inherit'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: (props) => (props.isOpen ? token('color.text.selected') : token('color.text')),
	'&:focus': {
		boxShadow: 'none',
		outlineOffset: token('space.negative.025'),
	},
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		backgroundColor: (props) =>
			props.isOpen
				? token('color.background.selected')
				: token('color.background.neutral.subtle.hovered'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContextMenuLabel = styled.span({
	font: token('font.body'),
	flex: 1,
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

const menuItemLabelWrapperStyles = xcss({
	flex: 1,
	font: token('font.body'),
});

const contextMenuIconStyles = xcss({
	paddingRight: 'space.100',
});
