import React, { useCallback } from 'react';
import { css, styled } from '@compiled/react';
import type { ContentProps } from '@atlaskit/popup';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import type { Position } from '../../common/types.tsx';
import { useContextMenuStore, useIsMenuOpen } from '../../controllers/context-menu/index.tsx';
import { useSetOpenContextMenuRef } from '../../controllers/use-set-open-context-menu-ref/index.tsx';
import { CONTEXT_MENU_TESTID } from './constants.tsx';
import messages from './messages.tsx';
import type { ContextMenuProps } from './types.tsx';

export const ContextMenu = ({
	renderMenu,
	scope,
	openContextMenuRef,
	jsErrorBoundaryProps,
}: ContextMenuProps) => {
	useSetOpenContextMenuRef({ scope, openContextMenuRef });

	const [{ menus }] = useContextMenuStore();
	const position = menus[scope]?.position || { x: 0, y: 0 };
	const isOpen = useIsMenuOpen({ scope });
	const { formatMessage } = useIntl();

	const renderContent = useCallback(
		({ isOpen: isContentOpen }: ContentProps) =>
			isContentOpen ? (
				<JSErrorBoundary fallback="unmount" {...jsErrorBoundaryProps}>
					{renderMenu(scope)}
				</JSErrorBoundary>
			) : null,
		[jsErrorBoundaryProps, renderMenu, scope],
	);

	return (
		<Popup
			isOpen={isOpen}
			offset={[0, 0]}
			placement="bottom-start"
			content={renderContent}
			shouldRenderToParent
			messageId="software-context-menu.ui.context-menu.popup"
			messageType="transactional"
			trigger={(triggerProps) => (
				<Absolute
					data-testid={CONTEXT_MENU_TESTID}
					position={position}
					role="button"
					{...triggerProps}
					aria-label={formatMessage(messages.contextMenuAriaLabel)}
				/>
			)}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Absolute = styled.div<{ position?: Position }>(
	{
		position: 'absolute',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ position }) =>
		!!position &&
		position.x &&
		position.y &&
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		css({
			// The position property controls the exact rendering location of the context menu based on the mouse position.
			// It needs to be pixel perfect to ensure accurate position.

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			top: `${position.y}px`,

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			left: `${position.x}px`,
		}),
);
