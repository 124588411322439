import isNil from 'lodash/isNil';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { PARENT_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import {
	type MenuNode,
	MenuNodeType,
} from '@atlassian/jira-software-context-menu/src/common/types.tsx';
import { showAssignIssueParentModal } from '../../../../../../../../state/actions/issue/assign-issue-parent-modal/index.tsx';
import { useBoardActionCreator, useBoardSelector } from '../../../../../../../../state/index.tsx';
import { isCardSelected } from '../../../../../../../../state/selectors/card/card-selectors.tsx';
import { isAssignIssueParentActionVisible as isAssignIssueParentActionVisibleSelector } from '../../../../../../../../state/selectors/issue/assign-issue-parent-selector.tsx';
import {
	getIssueById,
	isAssignedToIssueParent,
} from '../../../../../../../../state/selectors/issue/issue-selectors.tsx';
import { CHANGE_PARENT_MENU_ID } from '../../constants.tsx';
import messages from '../../messages.tsx';
import { useMenuItemWithStartAnalytics } from '../../utils.tsx';
import type { Props, ContentProps } from './types.tsx';

export const useChangeParentContent = ({
	onChangeParent,
	isAssignParentVisible,
	isReassigning,
	selectedCardIds,
}: ContentProps): MenuNode[] => {
	const { formatMessage } = useIntl();

	if (fg('one_event_rules_them_all_fg')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		return useMenuItemWithStartAnalytics({
			fieldKey: PARENT_TYPE,
			fieldType: PARENT_TYPE,
			shouldFireAnalytics: selectedCardIds?.length === 1,
			firstSelectedCardId:
				selectedCardIds && fg('one_event_improvements_1') ? selectedCardIds[0] : undefined,
			menuItemProps: {
				label: isReassigning
					? formatMessage(messages.changeIssueParent)
					: formatMessage(messages.addIssueParent),
				id: CHANGE_PARENT_MENU_ID,
				onClick: onChangeParent,
			},
			shouldHide: !isAssignParentVisible,
		});
	}

	if (!isAssignParentVisible) return [];

	return [
		{
			type: MenuNodeType.Item,
			label: isReassigning
				? formatMessage(messages.changeIssueParent)
				: formatMessage(messages.addIssueParent),
			id: CHANGE_PARENT_MENU_ID,
			onClick: onChangeParent,
		},
	];
};

export const useChangeParent = ({ isMultiSelectMode, issueId, selectedCardIds }: Props) => {
	const isSelected = useBoardSelector(isCardSelected)(issueId);

	const selectedIds = isSelected ? selectedCardIds : [issueId];
	const isReassigning = useBoardSelector(isAssignedToIssueParent)(selectedIds);

	const issue = useBoardSelector((state) => getIssueById(state, issueId));

	const isAssignParentVisible = useBoardSelector(
		(state) =>
			!isNil(issue) && isAssignIssueParentActionVisibleSelector(state)(issue, isMultiSelectMode),
	);
	const onChangeParent = useBoardActionCreator(() => showAssignIssueParentModal(selectedIds));

	return useChangeParentContent({
		onChangeParent,
		isAssignParentVisible,
		isReassigning,
		...(fg('one_event_rules_them_all_fg') ? { selectedCardIds: selectedIds } : {}),
	});
};
