import React, { useState, type ReactNode, useCallback, useMemo, memo } from 'react';
import isNil from 'lodash/isNil';
import memoize from 'lodash/memoize';
import InfoIcon from '@atlaskit/icon/core/migration/information--info';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { AnalyticsSubject } from '@atlassian/jira-analytics-web-react/src/components/decorators.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { useIsDismissed } from '@atlassian/jira-onboarding-quickstart-core/src/services/index.tsx';
import {
	VISIBLE,
	HIDDEN,
} from '@atlassian/jira-platform-inline-card-create/src/common/constants.tsx';
import type { CardType } from '@atlassian/jira-platform-inline-card-create/src/index.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { ConditionalNudgeWrapper } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/conditional-nudge-wrapper/index.tsx';
import { BoardKanbanCreateIssueNudgeAsync } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/kanban-onboarding-tour/board-create-issue/async.tsx';
import type { CardType as SoftwareCardType } from '../../../../model/software/software-types.tsx';
import { useBoardSelector } from '../../../../state/index.tsx';
import {
	allowedCardTypesForIccSelector,
	getIssueTypesForIssueCreation,
} from '../../../../state/selectors/card-create/card-create-selectors.tsx';
import { getColumnIndex } from '../../../../state/selectors/column/column-selectors.tsx';
import { getIsIncrementPlanningBoard } from '../../../../state/selectors/software/software-selectors.tsx';
import { getFirstOpenSwimlaneIdSelector } from '../../../../state/selectors/work/work-selectors.tsx';
import messages from './messages.tsx';
import { ICCPlaceholder, TooltipIconWrapper } from './styled.tsx';
import { Trigger } from './trigger/index.tsx';
import type { InlineCardCreateWithCardTypesProps } from './types.tsx';
import { shouldDisplayNudge, mapInlineCardCreateCardType } from './utils/index.tsx';
import { VisibleIcc } from './visible-icc/index.tsx';

export const InlineCardCreateWithCardTypes = memo(
	({
		canShowIccNudge,
		// TODO-BOARD-REFACTOR-TYPES: this isn't a type error yet, but this is not set anywhere although it's marked as required
		// @ts-expect-error issueHierarchyLevel it was marked as required
		issueHierarchyLevel,
		triggerAppearance = VISIBLE,
		triggerAriaLabel,
		cardIndex,
		columnId,
		swimlaneId,
		groupParentId,
		isLastSwimlane,
		onChangeFormVisible,
		insertBefore,
		projectId,
		showOnHover,
		isFooter,
	}: InlineCardCreateWithCardTypesProps) => {
		const [showInlineCardCreate, setInlineCardCreateVisibility] = useState(false);
		const [isDismissed] = useIsDismissed();

		const isIPBoard = useBoardSelector(getIsIncrementPlanningBoard);

		const firstOpenSwimlaneId = useBoardSelector(getFirstOpenSwimlaneIdSelector);

		const columnIndex = useBoardSelector((state) => getColumnIndex(state, columnId));

		const isTheFirstCurrentSwimlaneOpen = swimlaneId === null || firstOpenSwimlaneId === swimlaneId;

		const intl = useIntl();

		const cardTypes = useBoardSelector((state) => {
			if (isIPBoard) {
				return getIssueTypesForIssueCreation(state)(projectId).map(
					(issueType: SoftwareCardType) => ({
						...issueType,
						isDisabled: false,
					}),
				);
			}

			return allowedCardTypesForIccSelector(state)(columnId, swimlaneId, !!groupParentId);
		});

		const mappedCardTypes = useMemo(
			() => cardTypes.map((cardType) => mapInlineCardCreateCardType(cardType)),
			[cardTypes],
		);

		const externalValues = getExternalValues(cardIndex, columnIndex);

		const renderAdditionalTypeSelectOptionContent = useCallback(
			(cardType: CardType) => {
				if (!cardType.isDisabled) {
					return null;
				}

				return (
					<Tooltip
						content={intl.formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.disabledIssueTypeTooltipIssueTermRefresh
								: messages.disabledIssueTypeTooltip,
						)}
						position="bottom-start"
					>
						<TooltipIconWrapper>
							<InfoIcon spacing="spacious" color={token('color.text.discovery')} label="" />
						</TooltipIconWrapper>
					</Tooltip>
				);
			},
			[intl],
		);

		if (mappedCardTypes.length < 1) {
			return <ICCPlaceholder />;
		}

		const showHiddenCardForNudge = () => {
			setInlineCardCreateVisibility(true);
		};

		const hideHiddenCardForNudge = () => {
			setInlineCardCreateVisibility(false);
		};

		const canDisplayNudge = shouldDisplayNudge({
			isTheFirstCurrentSwimlaneOpen,
			triggerAppearance,
			canShowIccNudge,
			cardIndex: externalValues.cardIndex,
			isDismissed,
		});

		const IconLabel = fg('jira-issue-terminology-refresh-m3')
			? () => (
					<FormattedMessage
						id="platform-board.create-button-text.icon-label-v2-issue-term-refresh"
						defaultMessage="Create"
					>
						{(formattedMessage: ReactNode[]) => (
							<Trigger
								onClick={hideHiddenCardForNudge}
								label={typeof formattedMessage[0] === 'string' ? formattedMessage[0] : 'Create'}
							/>
						)}
					</FormattedMessage>
				)
			: () => (
					<FormattedMessage
						id="platform-board.create-button-text.icon-label-v2"
						defaultMessage="Create issue"
					>
						{(formattedMessage: ReactNode[]) => (
							<Trigger
								onClick={hideHiddenCardForNudge}
								label={
									typeof formattedMessage[0] === 'string' ? formattedMessage[0] : 'Create issue'
								}
							/>
						)}
					</FormattedMessage>
				);

		return (
			<ContextualAnalyticsData attributes={{ issueHierarchyLevel }}>
				<ConditionalNudgeWrapper
					Wrapper={BoardKanbanCreateIssueNudgeAsync}
					conditionsToApplyWrapper={canDisplayNudge}
					onShow={showHiddenCardForNudge}
					onHide={hideHiddenCardForNudge}
					attributes={{
						triggerAppearance,
					}}
				>
					{showInlineCardCreate && triggerAppearance === HIDDEN ? (
						<IconLabel />
					) : (
						<VisibleIcc
							renderAdditionalTypeSelectOptionContent={renderAdditionalTypeSelectOptionContent}
							cardIndex={cardIndex}
							cardTypes={cardTypes}
							// TODO-BOARD-REFACTOR-TYPES
							// @ts-expect-error CardType is not compatible with BoardCardType
							mappedCardTypes={mappedCardTypes}
							columnId={columnId}
							groupParentId={groupParentId}
							swimlaneId={swimlaneId}
							triggerAppearance={triggerAppearance}
							{...(fg('jfp-a11y-team_board_identical-create-issue-button') && { triggerAriaLabel })}
							isLastSwimlane={isLastSwimlane}
							insertBefore={insertBefore}
							onChangeFormVisible={onChangeFormVisible}
							showOnHover={showOnHover}
							projectId={projectId}
							isFooter={isFooter}
						/>
					)}
				</ConditionalNudgeWrapper>
			</ContextualAnalyticsData>
		);
	},
);

const getExternalValues = memoize(
	(cardIndex, columnIndex) => ({
		cardIndex: !isNil(cardIndex) ? cardIndex + 1 : null,
		columnIndex: !isNil(columnIndex) ? columnIndex + 1 : null,
	}),
	(cardIndex, columnIndex) => `${cardIndex}.${columnIndex}`,
);

export default AnalyticsSubject('issue')(InlineCardCreateWithCardTypes);
