import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import {
	initialState,
	VIEW_SETTINGS,
	VIEW_SETTINGS_LOCAL_STORAGE,
} from '../../common/constants.tsx';
import { Panels, View } from '../../common/types/constant.tsx';
import type { ExtraField } from '../../common/types/menu.tsx';
import type {
	SetExtraFieldsProps,
	SetViewSettingsCardExtraFieldsProps,
	SetShowDaysInColumnProps,
	SetShowDueDateProps,
	SetShowLabelsProps,
	SetShowUnscheduledColumnProps,
	SetShowStatusesProps,
	SetShowIssueLinksStatsProps,
	GetViewSettingsProps,
	SetViewSettingsIsFullscreenProps,
	SetViewSettingsProps,
	SetViewSettingsPanelsProps,
	SetViewSettingsPanelProps,
	SetViewSettingsQuickFiltersProps,
	SetViewSettingsCompactModeProps,
	SetViewSettingsCardDetailsProps,
	SetShowEpicsProps,
	SetShowVersionsProps,
	SetShowCardCoversProps,
	SetShowOfftrackDependencyLinesProps,
	SetIssueIdsToShowDependenciesProps,
} from '../../common/types/settings.tsx';
import type {
	CardExtraFieldsState,
	CardDetailsState,
	State,
	PanelState,
	IssueId,
} from '../../common/types/state.tsx';
import { isViewSettingsPanelAndTailoredViewExperimentEnabled } from '../../common/utils/index.tsx';

const browserLocalStorage = createLocalStorageProvider(VIEW_SETTINGS_LOCAL_STORAGE);

/**
 * Get the right view settings prefix based on the view
 * This is used to differentiate increment planning board and others as they might have the same ID for different board type
 * @param view
 * @returns
 */
export const getPrefix = (view?: (typeof View)[keyof typeof View]): string => {
	if (view && view === View.INCREMENT_PLANNING_BOARD) {
		return `${VIEW_SETTINGS}-${View.INCREMENT_PLANNING_BOARD}`;
	}
	if (isViewSettingsPanelAndTailoredViewExperimentEnabled()) {
		switch (view) {
			case View.INCREMENT_PLANNING_BOARD:
				return `${VIEW_SETTINGS}-${View.INCREMENT_PLANNING_BOARD}`;
			case View.BACKLOG:
				return `${VIEW_SETTINGS}-${View.BACKLOG}`;
			case View.BOARD:
				return `${VIEW_SETTINGS}-${View.BOARD}`;
			case View.JSM_BOARD:
				return `${VIEW_SETTINGS}-${View.JSM_BOARD}`;
			default:
				return VIEW_SETTINGS;
		}
	}
	return VIEW_SETTINGS;
};

export const getViewSettings = ({ boardId, view }: GetViewSettingsProps): State | undefined =>
	browserLocalStorage.get(`${getPrefix(view)}-${boardId}`) ||
	browserLocalStorage.get(`${VIEW_SETTINGS}-${boardId}`); // return legacy view settings if prefixed storage doesn't exist

export const setViewSettingsIsFullscreen = ({
	boardId,
	isFullscreen,
	view,
}: SetViewSettingsIsFullscreenProps) => {
	const state = getViewSettings({ boardId, view });
	browserLocalStorage.set(`${getPrefix(view)}-${boardId}`, {
		...state,
		isFullscreen,
	});
};

export const getViewSettingsIsFullscreen = ({ boardId, view }: GetViewSettingsProps): boolean => {
	const state = getViewSettings({ boardId, view });
	return state?.isFullscreen ?? initialState.isFullscreen;
};

export const getViewSettingsPanels = ({ boardId, view }: GetViewSettingsProps): PanelState => {
	const state = getViewSettings({ boardId, view });
	return {
		EPIC_PANEL: state?.showEpicPanel ?? initialState.showEpicPanel,
		VERSION_PANEL: state?.showVersionPanel ?? initialState.showVersionPanel,
	};
};

export const setViewSettings = ({ boardId, state, view }: SetViewSettingsProps) =>
	browserLocalStorage.set(`${getPrefix(view)}-${boardId}`, state);

export const setViewSettingsPanels = ({
	boardId,
	panelState,
	view,
}: SetViewSettingsPanelsProps) => {
	const state = getViewSettings({ boardId, view });
	browserLocalStorage.set(`${getPrefix(view)}-${boardId}`, {
		...state,
		showEpicPanel: panelState.EPIC_PANEL,
		showVersionPanel: panelState.VERSION_PANEL,
	});
};

export const setViewSettingsPanel = ({ boardId, panel, show, view }: SetViewSettingsPanelProps) => {
	const state = getViewSettings({ boardId, view });
	if (panel === Panels.EPIC_PANEL) {
		browserLocalStorage.set(`${getPrefix(view)}-${boardId}`, {
			...state,
			showEpicPanel: show,
		});
	}
	if (panel === Panels.VERSION_PANEL) {
		browserLocalStorage.set(`${getPrefix(view)}-${boardId}`, {
			...state,
			showVersionPanel: show,
		});
	}
};

export const getViewSettingsQuickFilters = ({ boardId, view }: GetViewSettingsProps): boolean => {
	const state = getViewSettings({ boardId, view });
	return state?.showQuickFilters ?? initialState.showQuickFilters;
};

export const setViewSettingsQuickFilters = ({
	boardId,
	showQuickFilters,
	view,
}: SetViewSettingsQuickFiltersProps) => {
	const state = getViewSettings({ boardId, view });
	browserLocalStorage.set(`${getPrefix(view)}-${boardId}`, {
		...state,
		showQuickFilters,
	});
};

export const getViewSettingsCompactMode = ({ boardId, view }: GetViewSettingsProps): boolean => {
	const state = getViewSettings({ boardId, view });
	return state?.showCompactMode ?? initialState.showCompactMode;
};

export const setViewSettingsCompactMode = ({
	boardId,
	showCompactMode,
	view,
}: SetViewSettingsCompactModeProps) => {
	const state = getViewSettings({ boardId, view });
	browserLocalStorage.set(`${getPrefix(view)}-${boardId}`, {
		...state,
		showCompactMode,
	});
};

export const getViewSettingsCardDetails = ({
	boardId,
	view,
	isDaysInColumnEnabled,
	isDueDateEnabled,
	isLabelsEnabled,
	isEstimateEnabled,
	isPriorityEnabled,
}: GetViewSettingsProps): CardDetailsState => {
	const state = getViewSettings({ boardId, view });

	return {
		SHOW_EPICS: state?.showEpics ?? initialState.showEpics,
		SHOW_STATUSES: state?.showStatuses ?? initialState.showStatuses,
		SHOW_VERSIONS: state?.showVersions ?? initialState.showVersions,
		SHOW_ISSUE_LINKS_STATS: state?.showIssueLinksStats ?? initialState.showIssueLinksStats,
		SHOW_ISSUE_KEY: state?.showIssueKey ?? initialState.showIssueKey,
		SHOW_ISSUE_TYPE: state?.showIssueType ?? initialState.showIssueType,
		SHOW_ASSIGNEE: state?.showAssignee ?? initialState.showAssignee,
		SHOW_PRIORITY:
			(isPriorityEnabled ? state?.showPriority : initialState.showPriority) ??
			initialState.showPriority,
		SHOW_DEVELOPMENT: state?.showDevelopment ?? initialState.showDevelopment,
		SHOW_ESTIMATE:
			(isEstimateEnabled ? state?.showEstimate : initialState.showEstimate) ??
			initialState.showEstimate,
		SHOW_DAYS_IN_COLUMN:
			(isDaysInColumnEnabled ? state?.showDaysInColumn : initialState.showDaysInColumn) ??
			initialState.showDaysInColumn,
		SHOW_DUE_DATE:
			(isDueDateEnabled ? state?.showDueDate : initialState.showDueDate) ??
			initialState.showDueDate,
		SHOW_LABELS:
			(isLabelsEnabled ? state?.showLabels : initialState.showLabels) ?? initialState.showLabels,
		SHOW_CARD_COVERS: state?.showCardCovers ?? initialState.showCardCovers,
		SHOW_BOARD_NAME: state?.showBoardName ?? initialState.showBoardName,
	};
};

export const setViewSettingsCardDetails = ({
	boardId,
	cardDetailsState,
	view,
}: SetViewSettingsCardDetailsProps) => {
	const state = getViewSettings({ boardId, view });
	browserLocalStorage.set(`${getPrefix(view)}-${boardId}`, {
		...state,
		showEpics: cardDetailsState.SHOW_EPICS,
		showStatuses: cardDetailsState.SHOW_STATUSES,
		showVersions: cardDetailsState.SHOW_VERSIONS,
		showDaysInColumn: cardDetailsState.SHOW_DAYS_IN_COLUMN,
		showEstimate: cardDetailsState.SHOW_ESTIMATE,
		showIssueLinksStats: cardDetailsState.SHOW_ISSUE_LINKS_STATS,
		showIssueKey: cardDetailsState.SHOW_ISSUE_KEY,
		showIssueType: cardDetailsState.SHOW_ISSUE_TYPE,
		showAssignee: cardDetailsState.SHOW_ASSIGNEE,
		showPriority: cardDetailsState.SHOW_PRIORITY,
		showDevelopment: cardDetailsState.SHOW_DEVELOPMENT,
		showDueDate: cardDetailsState.SHOW_DUE_DATE,
		showLabels: cardDetailsState.SHOW_LABELS,
		showCardCovers: cardDetailsState.SHOW_CARD_COVERS,
		showBoardName: cardDetailsState.SHOW_BOARD_NAME,
	});
};

export const getViewSettingsCardExtraFields = ({
	boardId,
	view,
	cardExtraFields,
}: GetViewSettingsProps & {
	cardExtraFields: ExtraField[];
}): CardExtraFieldsState => {
	const state = getViewSettings({ boardId, view });

	return cardExtraFields.reduce((acc, field) => {
		acc[field.id] = state?.cardExtraFields?.[field.id] ?? true;
		return acc;
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	}, {} as CardExtraFieldsState);
};

export const setViewSettingsCardExtraFields = ({
	boardId,
	cardExtraFieldsState,
	view,
}: SetViewSettingsCardExtraFieldsProps) => {
	const state = getViewSettings({ boardId, view });
	browserLocalStorage.set(`${getPrefix(view)}-${boardId}`, {
		...state,
		cardExtraFields: cardExtraFieldsState,
	});
};

export const setShowEpics = ({ boardId, showEpics, view }: SetShowEpicsProps) => {
	const state = getViewSettings({ boardId, view });
	browserLocalStorage.set(`${getPrefix(view)}-${boardId}`, {
		...state,
		showEpics,
	});
};

export const setShowVersions = ({ boardId, showVersions, view }: SetShowVersionsProps) => {
	const state = getViewSettings({ boardId, view });
	browserLocalStorage.set(`${getPrefix(view)}-${boardId}`, {
		...state,
		showVersions,
	});
};

export const setShowStatuses = ({ boardId, showStatuses, view }: SetShowStatusesProps) => {
	const state = getViewSettings({ boardId, view });
	browserLocalStorage.set(`${getPrefix(view)}-${boardId}`, {
		...state,
		showStatuses,
	});
};

export const setShowIssueLinksStats = ({
	boardId,
	showIssueLinksStats,
	view,
}: SetShowIssueLinksStatsProps) => {
	const state = getViewSettings({ boardId, view });
	browserLocalStorage.set(`${getPrefix(view)}-${boardId}`, {
		...state,
		showIssueLinksStats,
	});
};

export const setShowUnscheduledColumn = ({
	boardId,
	showUnscheduledColumn,
}: SetShowUnscheduledColumnProps) => {
	const state = getViewSettings({ boardId, view: View.INCREMENT_PLANNING_BOARD });
	browserLocalStorage.set(`${getPrefix(View.INCREMENT_PLANNING_BOARD)}-${boardId}`, {
		...state,
		showUnscheduledColumn,
	});
};

export const getShowUnscheduledColumn = (boardId: number): boolean => {
	const state = getViewSettings({ boardId, view: View.INCREMENT_PLANNING_BOARD });
	return state?.showUnscheduledColumn ?? initialState.showUnscheduledColumn;
};

export const setShowDaysInColumn = ({
	boardId,
	showDaysInColumn,
	view,
}: SetShowDaysInColumnProps) => {
	const state = getViewSettings({ boardId, view });
	browserLocalStorage.set(`${getPrefix(view)}-${boardId}`, {
		...state,
		showDaysInColumn,
	});
};

export const setShowDueDate = ({ boardId, showDueDate, view }: SetShowDueDateProps) => {
	const state = getViewSettings({ boardId, view });
	browserLocalStorage.set(`${getPrefix(view)}-${boardId}`, {
		...state,
		showDueDate,
	});
};

export const setShowLabels = ({ boardId, showLabels, view }: SetShowLabelsProps) => {
	const state = getViewSettings({ boardId, view });
	browserLocalStorage.set(`${getPrefix(view)}-${boardId}`, {
		...state,
		showLabels,
	});
};

export const setExtraFields = ({ boardId, cardExtraFields, view }: SetExtraFieldsProps) => {
	const state = getViewSettings({ boardId, view });
	browserLocalStorage.set(`${getPrefix(view)}-${boardId}`, {
		...state,
		cardExtraFields,
	});
};

export const setShowCardCovers = ({ boardId, showCardCovers, view }: SetShowCardCoversProps) => {
	const state = getViewSettings({ boardId, view });
	browserLocalStorage.set(`${getPrefix(view)}-${boardId}`, {
		...state,
		showCardCovers,
	});
};

export const setShowOfftrackDependencyLines = ({
	boardId,
	showOfftrackDependencyLines,
}: SetShowOfftrackDependencyLinesProps) => {
	const state = getViewSettings({ boardId, view: View.INCREMENT_PLANNING_BOARD });
	browserLocalStorage.set(`${getPrefix(View.INCREMENT_PLANNING_BOARD)}-${boardId}`, {
		...state,
		showOfftrackDependencyLines,
	});
};

export const getShowOfftrackDependencyLines = (boardId: number): boolean => {
	const state = getViewSettings({ boardId, view: View.INCREMENT_PLANNING_BOARD });
	return state?.showOfftrackDependencyLines ?? initialState.showOfftrackDependencyLines;
};

export const setIssueIdsToShowDependencies = ({
	boardId,
	issueIds,
}: SetIssueIdsToShowDependenciesProps) => {
	const state = getViewSettings({ boardId, view: View.INCREMENT_PLANNING_BOARD });
	browserLocalStorage.set(`${getPrefix(View.INCREMENT_PLANNING_BOARD)}-${boardId}`, {
		...state,
		issueIdsToShowDependencies: issueIds,
	});
};

export const getIssueIdsToShowDependencies = (boardId: number): IssueId[] => {
	const state = getViewSettings({ boardId, view: View.INCREMENT_PLANNING_BOARD });
	return state?.issueIdsToShowDependencies ?? initialState.issueIdsToShowDependencies;
};
