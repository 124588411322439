import { createTheme } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { Tokens } from '@atlassian/jira-custom-theme-constants/src/constants.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	BACKGROUND_COLOR_THEME_TOKEN,
	DARK_MODE,
	DEFAULT_THEME_STATE,
	DISABLED_THEME_STATE,
	FLAGGED_AND_SELECTED_THEME_STATE,
	FLAGGED_THEME_STATE,
	HOVER_ENTER_THEME_STATE_OLD,
	HOVER_LEAVE_THEME_STATE,
	LIGHT_MODE,
	SELECTED_THEME_STATE,
	TEXT_COLOR_THEME_TOKEN,
	HOVER_ENTER_THEME_STATE,
} from '../constants.tsx';
import type {
	CssProp,
	ThemeProps,
	ThemeTokens,
	TokenTheme,
	HoverThemeState,
	ThemeMode,
} from '../types.tsx';

const selectedDisabledAndDefaultThemeState = {
	[SELECTED_THEME_STATE]: {
		[HOVER_LEAVE_THEME_STATE]: {
			[LIGHT_MODE]: token('color.background.selected'),
			[DARK_MODE]: token('color.background.brand.bold'),
		},
		[HOVER_ENTER_THEME_STATE_OLD]: {
			// remove on cleanup - jira_theming_milestone_1_fg
			[LIGHT_MODE]: token('color.background.selected.hovered'),
			[DARK_MODE]: token('color.background.neutral.bold.hovered'),
		},
		[HOVER_ENTER_THEME_STATE]: {
			[LIGHT_MODE]: token('color.background.selected.hovered'),
			[DARK_MODE]: token('color.background.neutral.bold.hovered'),
		},
	},
	[DISABLED_THEME_STATE]: {
		[HOVER_LEAVE_THEME_STATE]: {
			[LIGHT_MODE]: token('color.background.disabled'),
			[DARK_MODE]: token('color.background.disabled'),
		},
		[HOVER_ENTER_THEME_STATE_OLD]: {
			// remove on cleanup - jira_theming_milestone_1_fg
			[LIGHT_MODE]: token('color.background.disabled'),
			[DARK_MODE]: token('color.background.disabled'),
		},
		[HOVER_ENTER_THEME_STATE]: {
			[LIGHT_MODE]: token('color.background.disabled'),
			[DARK_MODE]: token('color.background.disabled'),
		},
	},
	[DEFAULT_THEME_STATE]: {
		[HOVER_LEAVE_THEME_STATE]: {
			[LIGHT_MODE]: token('elevation.surface.raised'),
			[DARK_MODE]: token('elevation.surface.raised'),
		},
		[HOVER_ENTER_THEME_STATE_OLD]: {
			// remove on cleanup - jira_theming_milestone_1_fg
			[LIGHT_MODE]: token('color.background.neutral.subtle.hovered'),
			[DARK_MODE]: token('color.background.neutral.bold.hovered'),
		},
		[HOVER_ENTER_THEME_STATE]: {
			[LIGHT_MODE]: Tokens.COLOR_BACKGROUND_NEUTRAL_SUBTLE_HOVERED_CONTRAST,
			[DARK_MODE]: token('color.background.neutral.bold.hovered'),
		},
	},
};

const cardTheme: TokenTheme = {
	[BACKGROUND_COLOR_THEME_TOKEN]: {
		[FLAGGED_AND_SELECTED_THEME_STATE]: {
			[HOVER_LEAVE_THEME_STATE]: {
				[LIGHT_MODE]: token('color.background.warning.pressed'),
				[DARK_MODE]: token('color.background.warning.pressed'),
			},
			[HOVER_ENTER_THEME_STATE_OLD]: {
				// remove on cleanup - jira_theming_milestone_1_fg
				[LIGHT_MODE]: token('color.background.warning.hovered'),
				[DARK_MODE]: token('color.background.warning.hovered'),
			},
			[HOVER_ENTER_THEME_STATE]: {
				[LIGHT_MODE]: token('color.background.warning.hovered'),
				[DARK_MODE]: token('color.background.warning.hovered'),
			},
		},
		[FLAGGED_THEME_STATE]: {
			[HOVER_LEAVE_THEME_STATE]: {
				[LIGHT_MODE]: token('color.background.warning'),
				[DARK_MODE]: token('color.background.warning'),
			},
			[HOVER_ENTER_THEME_STATE_OLD]: {
				// remove on cleanup - jira_theming_milestone_1_fg
				[LIGHT_MODE]: token('color.background.warning.hovered'),
				[DARK_MODE]: token('color.background.warning.hovered'),
			},
			[HOVER_ENTER_THEME_STATE]: {
				[LIGHT_MODE]: token('color.background.warning.hovered'),
				[DARK_MODE]: token('color.background.warning.hovered'),
			},
		},
		...selectedDisabledAndDefaultThemeState,
	},
	[TEXT_COLOR_THEME_TOKEN]: {
		[FLAGGED_AND_SELECTED_THEME_STATE]: {
			[HOVER_LEAVE_THEME_STATE]: {
				[LIGHT_MODE]: token('color.text'),
				[DARK_MODE]: token('color.text'),
			},
			[HOVER_ENTER_THEME_STATE_OLD]: {
				// remove on cleanup - jira_theming_milestone_1_fg
				[LIGHT_MODE]: token('color.text'),
				[DARK_MODE]: token('color.text'),
			},
			[HOVER_ENTER_THEME_STATE]: {
				[LIGHT_MODE]: token('color.text'),
				[DARK_MODE]: token('color.text'),
			},
		},
		[FLAGGED_THEME_STATE]: {
			[HOVER_LEAVE_THEME_STATE]: {
				[LIGHT_MODE]: token('color.text'),
				[DARK_MODE]: token('color.text'),
			},
			[HOVER_ENTER_THEME_STATE_OLD]: {
				// remove on cleanup - jira_theming_milestone_1_fg
				[LIGHT_MODE]: token('color.text'),
				[DARK_MODE]: token('color.text'),
			},
			[HOVER_ENTER_THEME_STATE]: {
				[LIGHT_MODE]: token('color.text'),
				[DARK_MODE]: token('color.text'),
			},
		},
		[SELECTED_THEME_STATE]: {
			[HOVER_LEAVE_THEME_STATE]: {
				[LIGHT_MODE]: token('color.text'),
				[DARK_MODE]: token('color.text'),
			},
			[HOVER_ENTER_THEME_STATE_OLD]: {
				// remove on cleanup - jira_theming_milestone_1_fg
				[LIGHT_MODE]: token('color.text'),
				[DARK_MODE]: token('color.text'),
			},
			[HOVER_ENTER_THEME_STATE]: {
				[LIGHT_MODE]: token('color.text'),
				[DARK_MODE]: token('color.text'),
			},
		},
		[DISABLED_THEME_STATE]: {
			[HOVER_LEAVE_THEME_STATE]: {
				[LIGHT_MODE]: token('color.text'),
				[DARK_MODE]: token('color.text'),
			},
			[HOVER_ENTER_THEME_STATE_OLD]: {
				// remove on cleanup - jira_theming_milestone_1_fg
				[LIGHT_MODE]: token('color.text'),
				[DARK_MODE]: token('color.text'),
			},
			[HOVER_ENTER_THEME_STATE]: {
				[LIGHT_MODE]: token('color.text'),
				[DARK_MODE]: token('color.text'),
			},
		},
		[DEFAULT_THEME_STATE]: {
			[HOVER_LEAVE_THEME_STATE]: {
				[LIGHT_MODE]: token('color.text'),
				[DARK_MODE]: token('color.text'),
			},
			[HOVER_ENTER_THEME_STATE_OLD]: {
				// remove on cleanup - jira_theming_milestone_1_fg
				[LIGHT_MODE]: token('color.text'),
				[DARK_MODE]: token('color.text'),
			},
			[HOVER_ENTER_THEME_STATE]: {
				[LIGHT_MODE]: token('color.text'),
				[DARK_MODE]: token('color.text'),
			},
		},
	},
};

type TokenOptions = {
	cssProp: CssProp;
	isSelected?: Boolean;
	isFlagged?: Boolean;
	isDisabled?: Boolean;
	hoverState?: HoverThemeState;
	mode: ThemeMode;
};

const getCssPropValue = ({
	cssProp,
	isSelected = false,
	isFlagged = false,
	isDisabled = false,
	hoverState = HOVER_LEAVE_THEME_STATE,
	mode,
}: TokenOptions): string => {
	if (isFlagged && isSelected) {
		return cardTheme[cssProp][FLAGGED_AND_SELECTED_THEME_STATE][hoverState][mode];
	}
	if (isFlagged) {
		return cardTheme[cssProp][FLAGGED_THEME_STATE][hoverState][mode];
	}
	if (isSelected) {
		return cardTheme[cssProp][SELECTED_THEME_STATE][hoverState][mode];
	}
	if (isDisabled) {
		return cardTheme[cssProp][DISABLED_THEME_STATE][hoverState][mode];
	}

	return cardTheme[cssProp][DEFAULT_THEME_STATE][hoverState][mode];
};

const getBackgroundColor = ({ isSelected, isFlagged, isDisabled, mode }: ThemeProps): string =>
	getCssPropValue({
		cssProp: BACKGROUND_COLOR_THEME_TOKEN,
		isSelected,
		isFlagged,
		isDisabled,
		mode,
	});

const getTextColor = ({ isSelected, isFlagged, isDisabled, mode }: ThemeProps): string =>
	getCssPropValue({
		cssProp: TEXT_COLOR_THEME_TOKEN,
		isSelected,
		isFlagged,
		isDisabled,
		mode,
	});

const getHoverBackgroundColor = ({ isSelected, isFlagged, isDisabled, mode }: ThemeProps): string =>
	getCssPropValue({
		cssProp: BACKGROUND_COLOR_THEME_TOKEN,
		isSelected,
		isFlagged,
		isDisabled,
		mode,
		hoverState: fg('jira_theming_milestone_1_fg')
			? HOVER_ENTER_THEME_STATE
			: HOVER_ENTER_THEME_STATE_OLD,
	});

const getHoverTextColor = ({ isSelected, isFlagged, isDisabled, mode }: ThemeProps): string =>
	getCssPropValue({
		cssProp: TEXT_COLOR_THEME_TOKEN,
		isSelected,
		isFlagged,
		isDisabled,
		mode,
		hoverState: fg('jira_theming_milestone_1_fg')
			? HOVER_ENTER_THEME_STATE
			: HOVER_ENTER_THEME_STATE_OLD,
	});

const defaultTheme = ({ isSelected, isFlagged, isDisabled, mode }: ThemeProps): ThemeTokens => ({
	backgroundColor: getBackgroundColor({ isSelected, isFlagged, isDisabled, mode }),
	textColor: getTextColor({ isSelected, isFlagged, isDisabled, mode }),
	hoverBackgroundColor: getHoverBackgroundColor({ isSelected, isFlagged, isDisabled, mode }),
	hoverTextColor: getHoverTextColor({ isSelected, isFlagged, isDisabled, mode }),
});

export const Theme = createTheme<ThemeTokens, ThemeProps>(defaultTheme);
