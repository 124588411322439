import React, { useEffect, useMemo } from 'react';
import { createSelector } from 'reselect';
import memoizeOne from 'memoize-one';
import { getNormalisedPerformanceFFs } from '@atlassian/jira-common-long-task-metrics/src/common/util/collectors.tsx';
import { useViewMode } from '@atlassian/jira-issue-context-service/src/main.tsx';
import killswitch from '@atlassian/jira-killswitch/src/index.tsx';
import SubmitApdex from '@atlassian/jira-providers-spa-apdex-analytics/src/submit-apdex-mark/index.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { BoardFeatureGQL } from '@atlassian/jira-software-board-fetch-scope-critical/src/types.tsx';
import { pushEarlyMarksToBrowserMetrics } from '@atlassian/jira-software-browser-metrics/src/services/push-early-marks-to-browser-metrics/index.tsx';
import { getFeatureAnalyticsAttributes } from '@atlassian/jira-software-feature-analytics/src/common/utils.tsx';
import { BOARD } from '@atlassian/jira-software-resource-invalidator/src/common/types.tsx';
import { getAndClearInvalidationReasons } from '@atlassian/jira-software-resource-invalidator/src/controllers/resources-cache-invalidation-subject/index.tsx';
import { useQueryParam } from '@atlassian/react-resource-router';
import type { ColumnWithResolution } from '../../../model/column/column-types.tsx';
import type { Filters } from '../../../model/filter/filter-types.tsx';
import { CAN_RENAME_COLUMNS } from '../../../model/permission/permission-types.tsx';
import {
	nextGenBoardPageLoad,
	uifBoardNewUiPageLoad,
	incrementPlanningBoardPageLoad,
} from '../../../services/utils/performance-analytics/index.tsx';
import { getPermissionsSelector } from '../../../state/selectors/board/board-permissions-selectors.tsx';
import {
	getBoardConfig,
	getIsCMPBoard,
	rapidViewIdSelector,
	sessionIdSelector,
	getIsIncrementPlanningBoard,
} from '../../../state/selectors/software/software-selectors.tsx';
import { canCompleteSprintSelector } from '../../../state/selectors/sprint/sprint-selectors.tsx';
import { getSwimlaneMode } from '../../../state/selectors/swimlane/swimlane-mode-selectors.tsx';
import {
	workIssuesSelector,
	columnsWithResolutionSelector,
	isInlineColumnEditEnabled,
	getWorkFilters,
} from '../../../state/selectors/work/work-selectors.tsx';
import type { State } from '../../../state/types.tsx';

type StateProps = {
	extra: {
		[key: string]: number | string | boolean;
	};
	columns: ColumnWithResolution[];
	isCMPBoard: boolean;
	isIncrementPlanningBoard: boolean;
};

export type OwnProps = {
	isCacheHit: boolean;
};

type Props = OwnProps & StateProps;

const getHasServiceWorkerController = (): boolean => {
	try {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		return !!navigator?.serviceWorker?.controller;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (err: any) {
		return false;
	}
};

export const getActiveFilterKeys = (filters: Filters): string =>
	Object.entries(filters)
		.filter(([, value]) => !!value && (!Array.isArray(value) || value.length > 0))
		.map(([key]) => key)
		.join(',');

const getExtra = memoizeOne(
	(
		isCacheHit,
		issueNo,
		boardSessionId,
		boardId,
		allFeatures,
		isCMPBoard,
		canCompleteSprint,
		canEditColumns,
		filters,
		swimlaneMode,
	) => {
		const allFeaturesReadOnly: Readonly<typeof allFeatures> = { ...allFeatures };
		return {
			...getNormalisedPerformanceFFs(),
			...allFeaturesReadOnly,
			isCacheHit,
			cacheInvalidationReasons: getAndClearInvalidationReasons(BOARD).join(','),
			issueNo,
			boardSessionId,
			boardId,
			hasServiceWorkerController: getHasServiceWorkerController(),
			isSentryEnabled: !killswitch('fe_platform_error_tracking_enabled_sentry'),
			isClassic: isCMPBoard,
			canCompleteSprint,
			canEditColumns,
			filters: getActiveFilterKeys(filters),
			swimlaneMode,
		};
	},
);

const getFeatureAnalyticsAttributesMemoized = () =>
	createSelector(
		(allFeatures: BoardFeatureGQL[]) => getFeatureAnalyticsAttributes(allFeatures),
		(res) => res,
	);

export const mapStateToProps = () => {
	const getFeatureAnalyticsAttributesInstance = getFeatureAnalyticsAttributesMemoized();
	return (state: State, ownProps: OwnProps): StateProps => {
		const { isCacheHit } = ownProps;
		const issueNo = workIssuesSelector(state).length;

		const isCMPBoard = getIsCMPBoard(state);
		const boardSessionId = sessionIdSelector(state);
		const boardId = rapidViewIdSelector(state);
		const allFeatures = getFeatureAnalyticsAttributesInstance(getBoardConfig(state).allFeatures);
		const columns = columnsWithResolutionSelector(state);
		const isIncrementPlanningBoard = getIsIncrementPlanningBoard(state);
		const canCompleteSprint = canCompleteSprintSelector(state);
		const canEditColumns =
			getPermissionsSelector(state)[CAN_RENAME_COLUMNS] && isInlineColumnEditEnabled(state);
		const filters = getWorkFilters(state).values;
		const swimlaneMode = getSwimlaneMode(state) ?? 'NO_SWIMLANE';

		return {
			extra: getExtra(
				isCacheHit,
				issueNo,
				boardSessionId,
				boardId,
				allFeatures,
				isCMPBoard,
				canCompleteSprint,
				canEditColumns,
				filters,
				swimlaneMode,
			),
			columns,
			isCMPBoard,
			isIncrementPlanningBoard,
		};
	};
};

// Depending on feature flags, used to:
// 1. Also fire BM3 metric.mark() for custom timings, on top of performance.mark() invocations
export const SubmitApdexConnectedWithMountEffect = ({
	columns,
	extra,
	isCMPBoard,
	isIncrementPlanningBoard,
}: Props) => {
	const issueViewMode = useViewMode() || '';
	const [issueKey] = useQueryParam('selectedIssue');
	const extraIssueViewData = useMemo(
		() => ({ issueViewMode, isIssueViewOpen: !!issueKey }),
		[issueKey, issueViewMode],
	);

	useEffect(() => {
		if (isCMPBoard) {
			pushEarlyMarksToBrowserMetrics(uifBoardNewUiPageLoad);
		} else if (isIncrementPlanningBoard) {
			pushEarlyMarksToBrowserMetrics(incrementPlanningBoardPageLoad);
		} else {
			pushEarlyMarksToBrowserMetrics(nextGenBoardPageLoad);
		}
	}, [isCMPBoard, isIncrementPlanningBoard]);

	let metric;
	switch (true) {
		case isCMPBoard:
			metric = uifBoardNewUiPageLoad;
			break;
		case isIncrementPlanningBoard:
			metric = incrementPlanningBoardPageLoad;
			break;
		default:
			metric = nextGenBoardPageLoad;
	}

	if (columns.length > 0) {
		return (
			<SubmitApdex
				extra={{ ...extra, ...extraIssueViewData }}
				appName="software-board"
				// @ts-expect-error - TS2322 - Type 'PageLoadMetric' is not assignable to type 'BM3Metric'.
				metric={metric}
				testId="software-board.board-container.submit-apdex.submit-apdex"
			/>
		);
	}

	return null;
};

export default connect(mapStateToProps, {})(SubmitApdexConnectedWithMountEffect);
