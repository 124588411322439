import React, { useCallback, useEffect, useMemo } from 'react';
import mapValues from 'lodash/mapValues';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { ACTION_SUBJECT_ID as WORK_SUGGESTION_TOGGLE_ACTION_SUBJECT_ID } from '@atlassian/jira-insights-next-best-task/src/services/use-next-best-task-user-preference/constants.tsx';
import { useNextBestTaskUserPreference } from '@atlassian/jira-insights-next-best-task/src/services/use-next-best-task-user-preference/index.tsx';
import type { SwimlaneId } from '@atlassian/jira-platform-board-kit/src/common/types.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { nextBestTaskFeatureResource } from '@atlassian/jira-router-resources-next-best-task/src/services/index.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import { View } from '@atlassian/jira-software-view-settings/src/common/types/constant.tsx';
import {
	useShowUnscheduledColumn,
	useShowOfftrackDependencyLines,
	useIssueIdsToShowDependencies,
} from '@atlassian/jira-software-view-settings/src/controllers/index.tsx';
import ViewSettingsPopup from '@atlassian/jira-software-view-settings/src/ui/index.tsx';
import {
	ViewSettingsButton,
	ViewSettingsSyncFromLocalStorageWrapper,
} from '@atlassian/jira-software-view-settings/src/ui/view-settings-button/index.tsx';
import {
	ViewSettingsPanelOnboarding,
	ViewSettingsPanelButtonSpotlight,
} from '@atlassian/jira-software-view-settings/src/ui/view-settings-panel-onboarding/index.tsx';
import { useResource } from '@atlassian/react-resource-router';
import { useSoftwareProjectTheming } from '@atlassian/jira-project-theme-providers/src/controllers/software-theme/index.tsx';
import { isViewSettingAsPanelExpEnabledWithNoExposure } from '../../../../feature-flags.tsx';
import { fetchCardCovers } from '../../../../state/actions/card-covers/index.tsx';
import { fetchIssueLinksStats } from '../../../../state/actions/issue-links-stats/index.tsx';
import { useBoardActionCreator, useBoardSelector } from '../../../../state/index.tsx';
import { getIssueLinksPermissions } from '../../../../state/selectors/issue-links-permissions/issue-links-permissions-selectors.tsx';
import { boardOrderedIssueIdsSelector } from '../../../../state/selectors/issue/board-issue-selectors.tsx';
import { getFirstSelectableIssueAscending } from '../../../../state/selectors/issue/issue-navigation-selectors.tsx';
import {
	getCardExtraFields,
	getIsDaysInColumnEnabled,
	getIsDueDateEnabled,
	getIsIssueLabelsEnabled,
	projectTypeSelector,
	getIsEstimateEnabled,
	getIsPriorityEnabled,
	getCanEditBoard,
	getCanInviteOthersToProject,
	getIsIncrementPlanningBoard,
	getIssues,
	getIssueTypes,
	getIssueLinkTypes,
} from '../../../../state/selectors/software/software-selectors.tsx';
import {
	getCustomFilters,
	getIssuesEntriesWithIssueLinks,
} from '../../../../state/selectors/work/work-selectors.tsx';
import {
	useIsCMPBoard,
	useIsIncrementPlanningBoard,
	useIsJSWBoard,
} from '../../../../state/state-hooks/capabilities/index.tsx';

export type DispatchProps = {
	onSwimlaneExpandAll: () => void;
	onSwimlaneCollapseAll: (swimlaneIds: SwimlaneId[], isIPBoard: boolean) => void;
	onSetShowUnscheduledColumn: (isVisible: boolean) => void;
	onSetShowOfftrackDependencyLines: (isVisible: boolean) => void;
	onSetIssueIdsToShowDependencies: (issueIds: IssueId[]) => void;
	onTogglePanel: () => void;
};

export type StateProps = {
	rapidViewId: number;
	swimlaneIds: SwimlaneId[];
	hasSwimlanes: boolean;
	isClassic: boolean;
	epicCustomName?: string;
	isViewSettingPanelOpen?: boolean;
};

export type OwnProps = {
	showCardDetails: boolean;
};

export type ViewSettingsProps = StateProps & DispatchProps & OwnProps;

export const useIsJSMBoard = () => useBoardSelector(projectTypeSelector) === SERVICE_DESK_PROJECT;

export const ViewSettings = ({
	rapidViewId,
	isClassic,
	hasSwimlanes,
	showCardDetails,
	epicCustomName,
	swimlaneIds,
	isViewSettingPanelOpen,
	onSwimlaneExpandAll,
	onSwimlaneCollapseAll,
	onSetShowUnscheduledColumn,
	onSetShowOfftrackDependencyLines,
	onSetIssueIdsToShowDependencies,
	onTogglePanel,
}: ViewSettingsProps) => {
	const isIPBoard = useBoardSelector(getIsIncrementPlanningBoard);
	const handleSwimlaneCollapseAll = useCallback(() => {
		onSwimlaneCollapseAll(swimlaneIds, isIPBoard);
	}, [onSwimlaneCollapseAll, swimlaneIds, isIPBoard]);

	const isCompanyManaged = useIsCMPBoard();
	const isQuickFilterAvailable = useBoardSelector(getCustomFilters).length > 0;
	const showFilterBarToggle =
		isCompanyManaged && (!isViewSettingAsPanelExpEnabledWithNoExposure() || isQuickFilterAvailable);
	const isIncrementPlanningBoard = useIsIncrementPlanningBoard();
	const isJSMBoard = useIsJSMBoard();
	const isJSWBoard = useIsJSWBoard();

	const showUnscheduledColumn = useShowUnscheduledColumn();
	const showOfftrackDependencyLines = useShowOfftrackDependencyLines();
	const issueIdsToShowDependencies = useIssueIdsToShowDependencies();
	const isDaysInColumnEnabled = useBoardSelector(getIsDaysInColumnEnabled);
	const isDueDateEnabled = useBoardSelector(getIsDueDateEnabled);
	const isLabelsEnabled = useBoardSelector(getIsIssueLabelsEnabled);
	const cardExtraFields = useBoardSelector(getCardExtraFields);
	const isEstimateEnabled = useBoardSelector(getIsEstimateEnabled);
	const isPriorityEnabled = useBoardSelector(getIsPriorityEnabled);
	const canEdit = useBoardSelector(getCanEditBoard);
	const canInvite = useBoardSelector(getCanInviteOthersToProject);
	const { canUserLinkIssue, issueLinkTypes } = useBoardSelector(getIssueLinksPermissions);
	const firstSelectableIssueId = useBoardSelector(getFirstSelectableIssueAscending);
	const issues = useBoardSelector(getIssues);

	const issuesWithLinksById = useBoardSelector(getIssuesEntriesWithIssueLinks);
	const issueLinkTypesForBoard = useBoardSelector(getIssueLinkTypes);
	const issueTypes = useBoardSelector(getIssueTypes);

	// this is a hacky way to aviod cyclic dependency between view-settings and software board. It can go away if Issue type and CardType type gets extracted to board common.
	const issuesWithLinksByIdAnalytics = useMemo(
		() =>
			mapValues(issuesWithLinksById, (i) => ({
				typeId: i.typeId,
				issueLinks: i.issueLinks,
			})),
		[issuesWithLinksById],
	);

	const issueTypesAnalytics = useMemo(
		() =>
			mapValues(issueTypes, (t) => ({
				hierarchyLevelType: t.hierarchyLevelType,
			})),
		[issueTypes],
	);

	const isIssueLinkingEnabledOnInstance = canUserLinkIssue && issueLinkTypes?.length > 0;
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const boardIssueIds = useBoardSelector(boardOrderedIssueIdsSelector);
	const onLinkedIssuesStatsToggledOn = useBoardActionCreator(() =>
		fetchIssueLinksStats(boardIssueIds, { analyticsEvent: createAnalyticsEvent({}) }),
	);
	const onShowCardCoversToggledOn = useBoardActionCreator(() =>
		fetchCardCovers(boardIssueIds, { analyticsEvent: createAnalyticsEvent({}) }),
	);

	const [, { doFetch: updateWorkSuggestionsEnabled }] = useNextBestTaskUserPreference();

	const {
		data: isWorkSuggestionsEnabledData,
		update: updateWorkSuggestionsResource,
		loading: loadingWorkSuggestionsEnabled,
	} = useResource(nextBestTaskFeatureResource);
	const onToggleWorkSuggestions = (analyticsEvent: UIAnalyticsEvent) => {
		const newOptInValue = !isWorkSuggestionsEnabledData?.hasUserOptedIn;
		updateWorkSuggestionsResource(() => ({
			hasUserOptedIn: newOptInValue,
		}));
		updateWorkSuggestionsEnabled({ hasUserOptedIn: newOptInValue });
		fireUIAnalytics(analyticsEvent, WORK_SUGGESTION_TOGGLE_ACTION_SUBJECT_ID, {
			hasUserOptedIn: newOptInValue,
		});
	};

	const { isSoftwareFullTheming } = useSoftwareProjectTheming();

	useEffect(() => {
		// Setting the unscheduled column visibility state in IP board based on the value from view settings package
		if (isIncrementPlanningBoard) {
			onSetShowUnscheduledColumn(showUnscheduledColumn);
			onSetShowOfftrackDependencyLines(showOfftrackDependencyLines);
			onSetIssueIdsToShowDependencies(issueIdsToShowDependencies);
		}
	}, [
		isIncrementPlanningBoard,
		showUnscheduledColumn,
		showOfftrackDependencyLines,
		issueIdsToShowDependencies,
		onSetShowUnscheduledColumn,
		onSetShowOfftrackDependencyLines,
		onSetIssueIdsToShowDependencies,
	]);

	if (isIncrementPlanningBoard) {
		const analytics = {
			// Move the object into ViewSettingsPopup when cleaning up FG
			analyticsData: {
				issuesWithLinksById: issuesWithLinksByIdAnalytics,
				issueLinkTypes: issueLinkTypesForBoard,
				issueTypes: issueTypesAnalytics,
			},
		};

		// Increment planning settings only have unscheduled column toggle and swimlane expand/collapse
		return (
			<ViewSettingsPopup
				boardId={rapidViewId}
				isClassic={isClassic}
				view={View.INCREMENT_PLANNING_BOARD}
				isSwimlanesEnabled={hasSwimlanes}
				onSwimlaneExpandAll={onSwimlaneExpandAll}
				onSwimlaneCollapseAll={handleSwimlaneCollapseAll}
				dependencyIssueOptions={issues}
				shouldRenderPopupToParent={fg('jpo_a11y_pb_fixes') ? true : undefined}
				{...(fg('issue_cards_in_program_board')
					? { epicCustomName, isEstimateEnabled, isPriorityEnabled }
					: {})}
				{...analytics}
			/>
		);
	}

	if (isViewSettingAsPanelExpEnabledWithNoExposure() && isJSMBoard) {
		return (
			<ViewSettingsPopup
				boardId={rapidViewId}
				isClassic={isClassic}
				view={View.JSM_BOARD}
				epicCustomName={epicCustomName}
				showCardDetails={showCardDetails}
				isSwimlanesEnabled={hasSwimlanes}
				onSwimlaneExpandAll={onSwimlaneExpandAll}
				onSwimlaneCollapseAll={handleSwimlaneCollapseAll}
				showFilterBarToggle={showFilterBarToggle}
			/>
		);
	}

	const renderAdditionalFieldSpotlight = () => (
		<ViewSettingsSyncFromLocalStorageWrapper
			boardId={rapidViewId}
			view={View.BOARD}
			isDaysInColumnEnabled={isDaysInColumnEnabled}
			cardExtraFields={cardExtraFields}
			isDueDateEnabled={isDueDateEnabled}
			isLabelsEnabled={isLabelsEnabled}
			isEstimateEnabled={isEstimateEnabled}
			isPriorityEnabled={isPriorityEnabled}
		>
			<ViewSettingsButton
				isSelected={isViewSettingPanelOpen || false}
				onClick={onTogglePanel}
				{...(fg('jira_theming_milestone_1_fg') && {
					isThemedButton: isSoftwareFullTheming,
				})}
			/>
		</ViewSettingsSyncFromLocalStorageWrapper>
	);

	if (isViewSettingAsPanelExpEnabledWithNoExposure()) {
		return (
			<>
				<ViewSettingsPanelOnboarding
					isViewSettingPanelOpen={isViewSettingPanelOpen}
					onTogglePanel={onTogglePanel}
				/>
				<ViewSettingsPanelButtonSpotlight>
					{renderAdditionalFieldSpotlight()}
				</ViewSettingsPanelButtonSpotlight>
			</>
		);
	}

	return (
		<ViewSettingsPopup
			boardId={rapidViewId}
			isClassic={isClassic}
			view={View.BOARD}
			epicCustomName={epicCustomName}
			showCardDetails={showCardDetails}
			isSwimlanesEnabled={hasSwimlanes}
			onSwimlaneExpandAll={onSwimlaneExpandAll}
			onSwimlaneCollapseAll={handleSwimlaneCollapseAll}
			showFilterBarToggle={showFilterBarToggle}
			isWorkSuggestionsEnabled={!!isWorkSuggestionsEnabledData?.hasUserOptedIn}
			showWorkSuggestions={
				isWorkSuggestionsEnabledData != null && !loadingWorkSuggestionsEnabled && isJSWBoard
			}
			onToggleWorkSuggestions={onToggleWorkSuggestions}
			showLinkedIssueStatsToggle={
				isIssueLinkingEnabledOnInstance && Boolean(firstSelectableIssueId)
			}
			onLinkedIssuesStatsToggledOn={onLinkedIssuesStatsToggledOn}
			isAddPeopleButtonEnabled={
				canEdit ||
				(canInvite && expValEquals('open_invite_for_open_tmp_projects', 'cohort', 'experiment'))
			}
			onShowCardCoversToggledOn={onShowCardCoversToggledOn}
		/>
	);
};
