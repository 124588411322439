import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import type { Issue } from '../../../../model/issue/issue-types.tsx';
import {
	beginOptimisticUiMeta,
	commitOptimisticUiMeta,
	revertOptimisticUiMeta,
	type OptimisticUiMeta,
} from '../../meta/optimistic-ui-meta.tsx';

export const ISSUE_ARCHIVE_REQUEST = 'state.actions.issue.archive.ISSUE_ARCHIVE_REQUEST' as const;

export type IssueArchiveRequestAction = {
	type: typeof ISSUE_ARCHIVE_REQUEST;
	payload: {
		issue: Issue;
		childIssueIds: IssueId[];
		onArchivalSuccess: (issueKey: string | null) => void;
		onArchivalFailure: (issueKey: string | null) => void;
	};
	meta: OptimisticUiMeta;
};

export const issueArchiveRequest = (
	issue: Issue,
	childIssueIds: IssueId[],
	onArchivalSuccess: (issueKey: string | null) => void,
	onArchivalFailure: (issueKey: string | null) => void,
): IssueArchiveRequestAction => ({
	type: ISSUE_ARCHIVE_REQUEST,
	payload: {
		issue,
		childIssueIds,
		onArchivalSuccess,
		onArchivalFailure,
	},
	meta: {
		...beginOptimisticUiMeta(),
	},
});

export const ISSUE_ARCHIVE_SUCCESS = 'state.actions.issue.archive.ISSUE_ARCHIVE_SUCCESS' as const;

export type IssueArchiveSuccessAction = {
	type: typeof ISSUE_ARCHIVE_SUCCESS;
	payload: {
		issueId: IssueId;
	};
	meta: OptimisticUiMeta;
};

export const issueArchiveSuccess = (
	optimisticId: string,
	issueId: IssueId,
): IssueArchiveSuccessAction => ({
	type: ISSUE_ARCHIVE_SUCCESS,
	payload: {
		issueId,
	},
	meta: commitOptimisticUiMeta(optimisticId),
});

export const ISSUE_ARCHIVE_FAILURE = 'state.actions.issue.archive.ISSUE_ARCHIVE_FAILURE' as const;

export type IssueArchiveFailureAction = {
	type: typeof ISSUE_ARCHIVE_FAILURE;
	payload: {
		issueId: IssueId;
	};
	meta: OptimisticUiMeta;
};

export const issueArchiveFailure = (
	optimisticId: string,
	issueId: IssueId,
): IssueArchiveFailureAction => ({
	type: ISSUE_ARCHIVE_FAILURE,
	payload: {
		issueId,
	},
	meta: revertOptimisticUiMeta(optimisticId),
});

export type Action =
	| IssueArchiveRequestAction
	| IssueArchiveSuccessAction
	| IssueArchiveFailureAction;
