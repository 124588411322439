import React, { memo } from 'react';
import noop from 'lodash/noop';
import UFOCustomData from '@atlaskit/react-ufo/custom-data';
import { useIntl } from '@atlassian/jira-intl';
import { fg } from '@atlassian/jira-feature-gating';
import { VISIBLE } from '@atlassian/jira-platform-inline-card-create/src/common/constants.tsx';
import { useIccContext } from '@atlassian/jira-platform-inline-card-create/src/services/context/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { useBoardSelector } from '../../../../../../state/index.tsx';
import { getIsCMPBoard } from '../../../../../../state/selectors/software/software-selectors.tsx';
import DoneCardsButton from '../../../column/done-cards-button/index.tsx';
import InlineCardCreate from '../../../inline-card-create/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const Footer = memo(
	({
		isLastSwimlane,
		isInitialColumn,
		canShowIccNudge,
		isColumnIccEnabled,
		showDoneCardsButton,
		columnId,
		swimlaneId,
		cardIndex,
		openInitialColumnIcc,
		limitJql,
		columnName,
	}: Props) => {
		const { initialProjectId: projectId } = useIccContext();
		const { formatMessage } = useIntl();

		const isCompanyManaged = useBoardSelector(getIsCMPBoard);

		if (!isColumnIccEnabled && !showDoneCardsButton) {
			return null;
		}

		const ariaLabelMessage = fg('jira-issue-terminology-refresh-m3')
			? messages.triggerAriaLabelIssueTermRefresh
			: messages.triggerAriaLabel;

		const icc = (
			<InlineCardCreate
				showOnHover={!isInitialColumn}
				canShowIccNudge={canShowIccNudge}
				triggerAppearance={VISIBLE}
				{...(fg('jfp-a11y-team_board_identical-create-issue-button') && {
					triggerAriaLabel: formatMessage(ariaLabelMessage, { columnName }),
				})}
				columnId={columnId}
				swimlaneId={swimlaneId}
				cardIndex={cardIndex}
				// TODO-BOARD-REFACTOR-TYPES: should stay open is not a property you can pass in. It's calculated internally in the `mergeStateToProps`
				shouldStayOpen={openInitialColumnIcc}
				isLastSwimlane={isLastSwimlane}
				onChangeFormVisible={noop}
				// TODO-BOARD-REFACTOR-TYPES
				// @ts-expect-error projectID expects number but it is number | null
				projectId={projectId}
				isFooter
			/>
		);

		return (
			<>
				{isColumnIccEnabled &&
					(isInitialColumn ? (
						<UFOSegment name="icc-button-in-footer">
							<UFOCustomData data={{ isCompanyManaged }} />
							{icc}
						</UFOSegment>
					) : (
						icc
					))}

				{showDoneCardsButton && <DoneCardsButton limitJql={limitJql} />}
			</>
		);
	},
);

export default Footer;
