/**
 * @generated SignedSource<<22148d64d52406f9009e401abc6abc06>>
 * @relayHash 5c749d389bf268ee9226560ad568adcf
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 9283dbd6d43bd23893ba0d1fb905c533d07635b370710c93db0ea67cb8b41e06

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraBoardSwimlaneStrategy = "ASSIGNEE_UNASSIGNED_FIRST" | "ASSIGNEE_UNASSIGNED_LAST" | "CUSTOM" | "EPIC" | "ISSUE_CHILDREN" | "ISSUE_PARENT" | "NONE" | "PARENT_CHILD" | "PROJECT" | "REQUEST_TYPE" | "%future added value";
export type JiraJqlScopeInput = {
  board?: JiraJqlBoardInput | null | undefined;
};
export type JiraJqlBoardInput = {
  boardId: AGG$Long;
  swimlaneStrategy?: JiraBoardSwimlaneStrategy | null | undefined;
};
export type boardFilterRefinementQuery$variables = {
  boardId: string;
  cloudId: string;
  filterRefinementScope: JiraJqlScopeInput;
};
export type boardFilterRefinementQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"boardFilterRefinement_softwareBoard" | "customFilter_softwareFilters_CustomFilterRelay">;
};
export type boardFilterRefinementQuery = {
  response: boardFilterRefinementQuery$data;
  variables: boardFilterRefinementQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "boardId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterRefinementScope"
},
v3 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v4 = {
  "kind": "Variable",
  "name": "scope",
  "variableName": "filterRefinementScope"
},
v5 = [
  {
    "kind": "Variable",
    "name": "boardId",
    "variableName": "boardId"
  }
],
v6 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v7 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "name": "boardFilterRefinementQuery",
    "selections": [
      {
        "args": [
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "boardFilterRefinement_softwareBoard"
      },
      {
        "args": (v5/*: any*/),
        "kind": "FragmentSpread",
        "name": "customFilter_softwareFilters_CustomFilterRelay"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "boardFilterRefinementQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": [
              (v3/*: any*/)
            ],
            "concreteType": "JiraJqlBuilder",
            "kind": "LinkedField",
            "name": "jqlBuilder",
            "plural": false,
            "selections": [
              {
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 100
                  },
                  {
                    "kind": "Literal",
                    "name": "jqlTerm",
                    "value": "assignee"
                  },
                  (v4/*: any*/)
                ],
                "concreteType": "JiraJqlFieldValueConnection",
                "kind": "LinkedField",
                "name": "fieldValues",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraJqlFieldValueEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "jqlTerm"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "displayName"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "name": "picture"
                                  },
                                  (v7/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraJqlUserFieldValue"
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "args": (v5/*: any*/),
        "concreteType": "BoardScope",
        "kind": "LinkedField",
        "name": "boardScope",
        "plural": false,
        "selections": [
          {
            "concreteType": "CustomFilter",
            "kind": "LinkedField",
            "name": "customFilters",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              {
                "kind": "ScalarField",
                "name": "name"
              }
            ]
          },
          (v7/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "id": "9283dbd6d43bd23893ba0d1fb905c533d07635b370710c93db0ea67cb8b41e06",
    "metadata": {},
    "name": "boardFilterRefinementQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "76e69ce3a16eaa498d6a510d99a80a5c";

import { PreloadableQueryRegistry } from 'relay-runtime';
PreloadableQueryRegistry.set(node.params.id, node);

export default node;
