import React, { useState, useCallback, useMemo, useRef } from 'react';
import AddIcon from '@atlaskit/icon/core/add';
import AddIconLegacy from '@atlaskit/icon/glyph/add';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { ButtonItem, Section, MenuGroup } from '@atlaskit/menu';
import Popup, { type TriggerProps } from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { Box, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useModalDialogActions } from '@atlassian/jira-software-modal-dialog/src/controllers/index.tsx';
import {
	type VersionMenuItem,
	ENABLED,
	NO_AVAILABLE_ISSUE_OR_VERSION,
	NO_PERMISSION,
} from '../../../../model/version/version-types.tsx';
import { useBoardSelector } from '../../../../state/index.tsx';
import { hasMultipleProjects } from '../../../../state/selectors/software/software-selectors.tsx';
import { getFilteredVersionsSelector } from '../../../../state/selectors/version/version-selectors.tsx';
import { NewVersionReleaseModal } from '../../../modals/new-version-release-modal/index.tsx';
import { ReleaseVersionModal } from '../../../modals/release-version-modal/index.tsx';
import messages from './messages.tsx';

type Props = {
	rapidViewId: string;
};

const DROPDOWN_MENU_ITEM_TEST_ID = 'software-board.header.release-version.menu-item';

const ReleaseVersion = ({ rapidViewId }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isOpen, setIsOpen] = useState(false);
	const [isNewVersionModalOpen, setIsNewVersionModalOpen] = useState(false);
	const closeNewVersionReleaseModal = useCallback(
		() => setIsNewVersionModalOpen(false),
		[setIsNewVersionModalOpen],
	);

	const isMultiProjectBoard = useBoardSelector(hasMultipleProjects);
	const releaseButtonRef: React.Ref<HTMLButtonElement> = useRef(null);
	const { setReturnFocusTo } = useModalDialogActions();
	const [selectedVersion, setSelectedVersion] = useState<VersionMenuItem | null>(null);
	const closeReleaseModal = useCallback(() => setSelectedVersion(null), [setSelectedVersion]);
	const { releaseState, versionMenuItems, hasIssuesWithoutVersion, lastColumnName } =
		useBoardSelector(getFilteredVersionsSelector);

	const onPopupClick = useCallback(() => {
		if (isOpen) {
			fireUIAnalytics(
				createAnalyticsEvent({
					actionSubject: 'board.release.menu-open',
					action: 'release menu item clicked',
				}),
			);
		}

		setIsOpen(!isOpen);
	}, [createAnalyticsEvent, isOpen]);

	const onClose = useCallback(() => {
		setIsOpen(false);
	}, [setIsOpen]);

	const popupTrigger = useCallback(
		(triggerProps: TriggerProps) => {
			const isDisabled = releaseState === NO_PERMISSION;
			setReturnFocusTo(releaseButtonRef);
			const tooltipContent =
				!isOpen && releaseState === ENABLED
					? formatMessage(
							expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
								? messages.tooltipReleaseIssueTermRefresh
								: messages.tooltipRelease,
							{
								columnName: lastColumnName,
							},
						)
					: null;

			return (
				<Tooltip
					content={
						isDisabled
							? formatMessage(
									expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
										? messages.tooltipDisabledIssueTermRefresh
										: messages.tooltipDisabled,
								)
							: tooltipContent
					}
				>
					<ButtonItem
						{...triggerProps}
						aria-label={formatMessage(messages.title)}
						ref={mergeRefs(triggerProps.ref, releaseButtonRef)}
						iconAfter={<ChevronRightIcon label="" color={token('color.icon')} />}
						isDisabled={isDisabled}
						testId="software-board.header.menu.release-version.button"
						onClick={onPopupClick}
					>
						{formatMessage(messages.title)}
					</ButtonItem>
				</Tooltip>
			);
		},
		[formatMessage, isOpen, onPopupClick, lastColumnName, releaseState, setReturnFocusTo],
	);

	const handleNewVersionClick = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: 'board.release.create-new-version',
				action: 'create version menu item clicked',
			}),
		);
		setIsNewVersionModalOpen(true);
		setIsOpen(false);
	}, [setIsOpen, setIsNewVersionModalOpen, createAnalyticsEvent]);

	const versionMenuItemElems = useMemo(
		() =>
			versionMenuItems.map((versionItem) => {
				const handleClick = () => {
					fireUIAnalytics(
						createAnalyticsEvent({
							actionSubject: 'board.release.version',
							action: 'version menu item clicked',
						}),
					);
					setSelectedVersion(versionItem);
					setIsOpen(false);
				};
				return (
					<ButtonItem
						key={`${versionItem.id} ${versionItem.projectKey}`}
						onClick={handleClick}
						testId={DROPDOWN_MENU_ITEM_TEST_ID}
					>
						<Tooltip content={versionItem.name}>
							<Box xcss={versionMenuItemContainer}>
								<Box xcss={versionNameContainer}>{versionItem.name}</Box>
								<Text color="color.text.subtlest">
									{isMultiProjectBoard && versionItem.projectKey}
								</Text>
							</Box>
						</Tooltip>
					</ButtonItem>
				);
			}),
		[createAnalyticsEvent, isMultiProjectBoard, versionMenuItems],
	);

	const content = useCallback(() => {
		return (
			<MenuGroup maxWidth={240}>
				{versionMenuItemElems.length ? (
					<Section
						testId="software-board.header.menu.release-version.menu-group"
						title={formatMessage(messages.version)}
					>
						{versionMenuItemElems}
					</Section>
				) : null}

				{releaseState === NO_AVAILABLE_ISSUE_OR_VERSION ? (
					<ButtonItem isDisabled testId={DROPDOWN_MENU_ITEM_TEST_ID} shouldTitleWrap>
						{formatMessage(
							expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
								? messages.nothingToReleaseIssueTermRefresh
								: messages.nothingToRelease,
							{
								columnName: lastColumnName,
							},
						)}
					</ButtonItem>
				) : null}
				{hasIssuesWithoutVersion && (
					<Section hasSeparator={versionMenuItems.length > 0}>
						<ButtonItem
							iconBefore={
								<AddIcon
									LEGACY_fallbackIcon={AddIconLegacy}
									LEGACY_size="small"
									label=""
									color={token('color.icon')}
								/>
							}
							onClick={handleNewVersionClick}
							testId={DROPDOWN_MENU_ITEM_TEST_ID}
						>
							{formatMessage(messages.menuItemCreateVersion)}
						</ButtonItem>
					</Section>
				)}
			</MenuGroup>
		);
	}, [
		handleNewVersionClick,
		formatMessage,
		versionMenuItemElems,
		releaseState,
		lastColumnName,
		hasIssuesWithoutVersion,
		versionMenuItems,
	]);

	return (
		<>
			{selectedVersion !== null && (
				<ReleaseVersionModal
					setModalClosed={closeReleaseModal}
					versionId={selectedVersion.id}
					versionName={selectedVersion.name}
					projectKey={selectedVersion.projectKey}
				/>
			)}
			{isNewVersionModalOpen && (
				<NewVersionReleaseModal
					boardId={rapidViewId}
					setModalClosed={closeNewVersionReleaseModal}
				/>
			)}
			<Popup
				placement="left-start"
				isOpen={isOpen}
				onClose={onClose}
				trigger={popupTrigger}
				content={content}
			/>
		</>
	);
};

export default ReleaseVersion;

const versionMenuItemContainer = xcss({
	display: 'inline-flex',
	overflow: 'hidden',
	justifyContent: 'space-between',
	width: '100%',
});

const versionNameContainer = xcss({
	flexGrow: 1,
	marginRight: 'space.100',
	textOverflow: 'ellipsis',
	overflowX: 'hidden',
	whiteSpace: 'nowrap',
	width: '200px',
});
