import React, { createContext, useContext, useState, type ReactNode } from 'react';

interface ScreenReaderContextType {
	screenReaderText: string;
	setScreenReaderText: (message: string) => void;
}

const ScreenReaderContext = createContext<ScreenReaderContextType>({
	screenReaderText: '',
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setScreenReaderText: () => {},
});

export const useScreenReader = (): ScreenReaderContextType => useContext(ScreenReaderContext);

export const ScreenReaderProvider = ({ children }: { children: ReactNode }) => {
	const [screenReaderText, setScreenReaderTextState] = useState<string>('');

	const setScreenReaderText = (message: string) => {
		setScreenReaderTextState('');
		setTimeout(() => setScreenReaderTextState(message), 100);
	};

	return (
		<ScreenReaderContext.Provider value={{ screenReaderText, setScreenReaderText }}>
			{children}
		</ScreenReaderContext.Provider>
	);
};
