import React from 'react';
import { Box, Inline, Stack, xcss } from '@atlaskit/primitives';
import Skeleton from '@atlaskit/skeleton';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';

export const SelectFallbackSkeleton = () => {
	return (
		<UFOLoadHold name="filter-select-fallback-skeleton">
			<Box xcss={flexGrowStyles}>
				<Stack space="space.400">
					<Skeleton isShimmering width="320px" height="32px" borderRadius="3px" />
					{Array.from({ length: 4 }, (_, index) => (
						<Skeleton isShimmering key={index} width="120px" height="9px" />
					))}
				</Stack>
			</Box>
			<Box paddingBlockEnd="space.150">
				<Inline alignBlock="center" spread="space-between" grow="fill">
					<Skeleton isShimmering width="65px" height="9px" />
					<Skeleton isShimmering width="35px" height="9px" />
				</Inline>
			</Box>
		</UFOLoadHold>
	);
};

export default SelectFallbackSkeleton;

const flexGrowStyles = xcss({
	flex: 1,
});
