import camelCase from 'lodash/camelCase';
import type { RouteName } from '@atlassian/jira-route-name-types/src/RouteName.tsx';
import { WORK, PLAN, REPORT } from '../../common/constants.tsx';

export const toCamelCaseChartView = (chartView?: string) => camelCase(chartView);

export const getMode = (view: string) => {
	switch (view) {
		case 'planning':
		case 'planning.nodetail':
			return PLAN;
		case 'reporting':
			return REPORT;
		default:
			return WORK;
	}
};

export const getModeV2 = (routeName: RouteName | null) => {
	if (!routeName) {
		return WORK;
	}

	switch (routeName) {
		case 'rapidboard-backlog':
		case 'rapidboard-user-backlog':
			return PLAN;
		case 'rapidboard-report':
		case 'rapidboard-user-report':
			return REPORT;
		default:
			return WORK;
	}
};
