/* eslint-disable jira/deprecations/no-base-url */
import React, {
	type ReactNode,
	type ElementType,
	type ComponentType,
	useEffect,
	useState,
	type ComponentPropsWithoutRef,
	useRef,
	useCallback,
} from 'react';
import { styled as styled2 } from '@compiled/react';
import throttle from 'lodash/throttle';
import type { PreloadedQuery } from 'react-relay';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { autoScroller } from '@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-autoscroll';
import { token } from '@atlaskit/tokens';
import { WidthObserver } from '@atlaskit/width-detector';
import { AsyncAdminInaccessibleBoard } from '@atlassian/jira-admin-inaccessible-board/src/ui/async.tsx';
import { useShouldShowAdminInaccessibleBoard } from '@atlassian/jira-admin-inaccessible-board/src/ui/utils.tsx';
import type ViewTrackerDI from '@atlassian/jira-analytics-web-react/src/components/view-tracker.tsx';
import { PAGE_LAYOUT_OFFSET_TOP } from '@atlassian/jira-common-constants/src/page-layout.tsx';
import { setMark } from '@atlassian/jira-common-performance/src/marks.tsx';
import { DevDetailsDialogLoaderAsync } from '@atlassian/jira-development-details-loader/src/ui/async.tsx';
import { AsyncLaunchToolchainSpotlight } from '@atlassian/jira-devops-launch-toolchain-spotlight/src/async.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { ProgramBoardParentSwitcherEntrypointContextProvider } from '@atlassian/jira-software-program-board-parent-switcher/entrypoint-context.tsx';
import getMeta from '@atlassian/jira-get-meta';
import { UserPropertiesContainer } from '@atlassian/jira-insights-common/src/services/user-properties/index.tsx';
import { NEXT_BEST_TASK_USER_PROPERTIES_SCOPE } from '@atlassian/jira-insights-next-best-task/src/common/constants.tsx';
import { NextBestTaskContainer } from '@atlassian/jira-insights-next-best-task/src/services/next-best-task-container/index.tsx';
import { ViewSettingsSpotlightContainer as NBTViewSettingsSpotlightContainer } from '@atlassian/jira-insights-next-best-task/src/ui/view-settings-spotlight/index.tsx';
import { useViewMode } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { IssueLinksHandlerContainer as IssueLinksHandlerContainerImport } from '@atlassian/jira-issue-links-common/src/controllers/issue-links-handler-store/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { planIssueModal } from '@atlassian/jira-plan-issue-modal/entrypoint.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { boardFilterRefinementQuery } from '@atlassian/jira-relay/src/__generated__/boardFilterRefinementQuery.graphql.ts';
import type { viewBoardCustomFiltersQuery } from '@atlassian/jira-relay/src/__generated__/viewBoardCustomFiltersQuery.graphql';
import { uifBoardResource } from '@atlassian/jira-router-resources-uif-board/src/index.tsx';
import { NextGenBoardSkeleton } from '@atlassian/jira-skeletons/src/ui/next-gen-board/NextGenBoardSkeleton.tsx';
import BoardSizeLimitErrorEmptyState from '@atlassian/jira-software-board-size-limit-error-empty-state/src/ui/index.tsx';
import IssueLimitFlags from '@atlassian/jira-software-issue-limit-flags/src/index.tsx';
import ProjectKeyboardShortcuts from '@atlassian/jira-software-keyboard-shortcuts/src/ui/project-shortcuts/index.tsx';
import { useQueryParam } from '@atlassian/jira-software-router-utils/src/services/query-param/index.tsx';
import { AsyncJiraStandups } from '@atlassian/jira-standups/index.tsx';
import { StandupEntryPointContextProvider } from '@atlassian/jira-standups/src/controllers/context-provider/index.tsx';
import type { ExternalAction } from '@atlassian/jira-issue-view-store/src/actions/external-actions.tsx';
import { StorageLimitsBanner } from '@atlassian/jira-storage-limits-banner/src/ui/async.tsx';
import type AddBoardToHistoryDI from '@atlassian/jira-view-history/src/ui/add-board-to-history/index.tsx';

import { Capability } from '../common/capability/index.tsx';
import { INCREMENT_PLANNING_BOARD_TOP_OFFSET } from '../common/constants.tsx';
import { useActiveStandup } from '../common/hooks/use-active-standup/index.tsx';
import { useGICSidebarForcer } from '../common/hooks/use-gic-sidebar-forcer/index.tsx';
import { useIsEmbedView } from '../common/hooks/use-is-embed-view/index.tsx';
import { useOpenTeamAssociationModal } from '../common/hooks/use-open-team-association-modal/index.tsx';
import { useProgramBoardEmptyStates } from '../common/hooks/use-program-board-empty-states/index.tsx';
import { isViewSettingAsPanelExpEnabledWithNoExposure } from '../feature-flags.tsx';
import { BOARD_INTERACTIVE_MARK } from '../model/constants.tsx';
import { ASSIGNEE } from '../model/filter/filter-types.tsx';
import type { IssueKey } from '../model/issue/issue-types.tsx';
import type { Sprint } from '../model/sprint/sprint-types.tsx';
import type { EstimationStatisticFieldId } from '../model/work/work-types.tsx';
import { PlanModalEntrypointContext } from '../services/board/plan-modal-entrypoint/index.tsx';
import { cardClick } from '../state/actions/card/index.tsx';
import { setFilters } from '../state/actions/filter/index.tsx';
import { openIssueModal } from '../state/actions/issue/modal/index.tsx';
import { closeInsightsPanel, closeViewSettingsPanel } from '../state/actions/panels/index.tsx';
import { useBoardDispatch, useBoardSelector } from '../state/index.tsx';
import { getViewSettingsPanel } from '../state/selectors/panels/index.tsx';
import { getIssueCount } from '../state/selectors/software/software-selectors.tsx';
import {
	isBoardConfigLoaded,
	orderedWorkAssigneesByAccountIdSelector,
} from '../state/selectors/work/work-selectors.tsx';
import {
	useCanEditBoard,
	useCapability,
	useIsCMPBoard,
	useIsIncrementPlanningBoard,
	useIsJSWBoard,
	useIsFreeJswProject,
} from '../state/state-hooks/capabilities/index.tsx';
import { ForceRecalculateSubscribers as FastVirtualForceRecalculateSubscribers } from './board-container/board/virtual-board/fast-virtual-list/force-recalculate-subscribers/index.tsx';
import type BoardContainerDI from './board-container/index.tsx';
import { CmpBoardSlaTracker } from './board-sla-tracker/index.tsx';
import type DetailViewDI from './detail-view/index.tsx';
import type DocumentTitleDI from './document-title/index.tsx';
import type FlagsDI from './flags/index.tsx';
import type GlobalSearchTrackerDI from './global-search-tracker/index.tsx';
import type HeaderDI from './header/index.tsx';
import type ModalsDI from './modals/index.tsx';
import IssueViewModal from './modals/issue-view/index.tsx';
import type AsyncPrefetchIssuesDI from './prefetch-issues/async/index.tsx';
import type RealtimeDI from './realtime/index.tsx';

type DIType<CT extends ElementType> = ComponentType<ComponentPropsWithoutRef<CT>>;

export type Props = {
	isCacheHit: boolean;
	isInsightsPanelOpen: boolean;
	hasMinimap: boolean;
	hasModals: boolean;
	hasFlags: boolean;
	rapidViewId: string;
	projectId: number;
	estimationStatistic: EstimationStatisticFieldId;
	xsrfToken: string;
	Header: DIType<typeof HeaderDI>;
	DocumentTitle: DIType<typeof DocumentTitleDI>;
	Modals: DIType<typeof ModalsDI>;
	Flags: DIType<typeof FlagsDI>;
	Board: DIType<typeof BoardContainerDI>;
	ViewTracker: DIType<typeof ViewTrackerDI>;
	PrefetchIssues: DIType<typeof AsyncPrefetchIssuesDI>;
	Realtime: DIType<typeof RealtimeDI>;
	DetailView: DIType<typeof DetailViewDI>;
	AddBoardToHistory: DIType<typeof AddBoardToHistoryDI>;
	GlobalSearchTracker: DIType<typeof GlobalSearchTrackerDI>;
	baseUrl: string;
	projectKey: string;
	customFiltersQueryRef: PreloadedQuery<viewBoardCustomFiltersQuery> | null | undefined;
	boardFilterRefinementQueryRef?: PreloadedQuery<boardFilterRefinementQuery> | null;
	onExperienceSuccess: () => void;
	currentUserAccountId: string;
	issueLimitExceeded: boolean;
	savedFilterId: number;
	selectedSprintsOrAllSprints: Sprint[];
	externalActionForIssueView: ExternalAction | undefined;
};

const IssueLinksHandlerContainer = ({
	children,
	onIssueOpen,
	isGlobal,
}: {
	children: ReactNode;
	onIssueOpen?: (issueId: string) => void;
	isGlobal?: boolean;
}) => {
	if (onIssueOpen && isGlobal) {
		return (
			<IssueLinksHandlerContainerImport onIssueOpen={onIssueOpen} isGlobal={isGlobal}>
				{children}
			</IssueLinksHandlerContainerImport>
		);
	}
	return <>{children}</>;
};

const usePpsSurveyEvent = (isReady: boolean) => {
	const dontSendServeyEvent = useRef(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [selectedIssue] = useQueryParam('selectedIssue');
	const isCMPBoard = useIsCMPBoard();

	useEffect(() => {
		if (!isReady || dontSendServeyEvent.current) {
			return;
		}
		// we only want this to run once
		dontSendServeyEvent.current = true;

		if (selectedIssue) {
			// we are only interested in board view without issue selected
			return;
		}

		fireTrackAnalytics(createAnalyticsEvent({}), 'ppsSurvey ready', {
			experience: 'board',
			experienceType: isCMPBoard ? 'cmp' : 'tmp',
		});
	}, [isReady, isCMPBoard, createAnalyticsEvent, selectedIssue, dontSendServeyEvent]);
};
export const View = (props: Props) => {
	const {
		isCacheHit,
		hasModals = true,
		hasFlags = true,
		rapidViewId,
		estimationStatistic,
		DocumentTitle,
		Header,
		Modals,
		Flags,
		ViewTracker,
		xsrfToken,
		PrefetchIssues,
		Realtime,
		baseUrl,
		AddBoardToHistory,
		projectKey,
		GlobalSearchTracker,
		hasMinimap = true,
		isInsightsPanelOpen,
		Board,
		DetailView,
		customFiltersQueryRef,
		boardFilterRefinementQueryRef,
		projectId,
		currentUserAccountId,
		issueLimitExceeded,
		savedFilterId,
		selectedSprintsOrAllSprints,
		externalActionForIssueView = undefined,
	} = props;
	const { onExperienceSuccess } = props;
	const [width, setWidth] = useState<number>(0);
	const throttledSetWidth = throttle(setWidth, 50);
	const viewMode = useViewMode();
	const isDetailViewSidebar = viewMode === 'SIDEBAR';
	const isCMPBoard = useIsCMPBoard();
	const canEditBoard = useCanEditBoard();
	const isIncrementPlanningBoard = useIsIncrementPlanningBoard();
	const dispatch = useBoardDispatch();
	const columnDropIndicatorContainerRef = useRef<HTMLDivElement | null>(null);
	const isViewSettingPanelOpen = useBoardSelector(getViewSettingsPanel);
	const shouldShowAdminInaccessibleBoard = useShouldShowAdminInaccessibleBoard(uifBoardResource);
	const isJSWBoard = useIsJSWBoard();
	const isEmbedView = useIsEmbedView();
	const [isActiveStandup, setIsActiveStandup] = useActiveStandup();
	const shouldShowStandupPanel =
		isActiveStandup &&
		!isIncrementPlanningBoard &&
		isJSWBoard &&
		expVal('jira-in-product-standups', 'is_enabled', false);

	const issueCount = useBoardSelector(getIssueCount);
	const shouldShowIssueLimitFlags = useCapability(Capability.SHOW_ISSUE_LIMIT_FLAGS);
	const isTabNavigation = useCapability(Capability.IS_TAB_NAVIGATION);
	const [selectedIssue] = useQueryParam('selectedIssue');
	const assignees = useBoardSelector(orderedWorkAssigneesByAccountIdSelector);
	const setAssigneeFilter = useCallback(
		(id: string | null) => dispatch(setFilters(ASSIGNEE, id ? [id] : [])),
		[dispatch],
	);
	const currentlyFilteredAssignee = useBoardSelector(
		(state) => state.ui.workFilters?.values?.ASSIGNEE?.[0] || null,
	);

	// This change is added because on this branch there's no router resource
	const isLoaded = useBoardSelector((state) => {
		return !state.configuration.isCMPBoard || isBoardConfigLoaded(state);
	});

	const wrapperHeightOffset = isIncrementPlanningBoard
		? `(${PAGE_LAYOUT_OFFSET_TOP} + ${INCREMENT_PLANNING_BOARD_TOP_OFFSET})`
		: PAGE_LAYOUT_OFFSET_TOP;

	// Consolidate all of program board empty states into a hook
	const programBoardEmptyStates = useProgramBoardEmptyStates(
		`calc(100vh - ${wrapperHeightOffset})`,
	);

	const shouldMountStorageLimitsBanner = useIsFreeJswProject();

	const isSyntheticTenant = useCallback(() => getMeta('ajs-is-synthetic') === 'true', []);

	usePpsSurveyEvent(isLoaded);

	useEffect(() => {
		onExperienceSuccess();

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.body?.classList.add('spb');
		setMark(BOARD_INTERACTIVE_MARK);

		return () => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.body?.classList.remove('spb');
		};
	}, [onExperienceSuccess]);

	useEffect(
		() =>
			monitorForElements({
				onDragStart: ({ location }) => {
					autoScroller.start({ input: location.current.input });
				},
				onDrop: () => {
					autoScroller.stop();
				},
				onDrag: ({ location }) => {
					autoScroller.updateInput({ input: location.current.input });
				},
			}),
		[],
	);

	useGICSidebarForcer();

	useOpenTeamAssociationModal();

	const entryPointResult = useEntryPoint(planIssueModal, {});

	const onIssueOpen = useCallback(
		(issueId: string, issueKey?: IssueKey) => {
			if (isDetailViewSidebar) {
				dispatch(closeInsightsPanel());
				isViewSettingAsPanelExpEnabledWithNoExposure() && dispatch(closeViewSettingsPanel());
			}
			/**
			 * If the issueKey is present, then the open modal action can be called directly.
			 * used by Next Best Task to show issues that are not on the board.
			 */
			if (issueKey) {
				dispatch(openIssueModal(issueKey));
			} else {
				dispatch(cardClick(issueId, false, false));
			}
		},
		[dispatch, isDetailViewSidebar],
	);

	// Only render the program board empty states if it's not null
	if (programBoardEmptyStates) {
		return programBoardEmptyStates;
	}

	// Show a message to admin users if the board is inaccessible due to filter permissions and how to update it
	if (shouldShowAdminInaccessibleBoard) {
		return (
			<AsyncAdminInaccessibleBoard rapidViewId={rapidViewId} skeleton={NextGenBoardSkeleton} />
		);
	}

	// TODO FEBO-357 Fix when skeleton appears
	if (!isLoaded) {
		return (
			<>
				<NextGenBoardSkeleton />
				<CmpBoardSlaTracker />
			</>
		);
	}

	const renderBoardSizeLimitEmptyState = () => (
		<BoardSizeLimitErrorEmptyState
			projectKey={projectKey}
			isCMPBoard={isCMPBoard}
			savedFilterId={savedFilterId}
		/>
	);

	const renderBoard = () => (
		<SizeDetectorWrapper>
			<WidthObserver setWidth={throttledSetWidth} />

			<BoardArea data-test-id="software-board.board-area" data-testid="software-board.board-area">
				{shouldShowStandupPanel && (
					<AsyncJiraStandups
						boardId={Number(rapidViewId)}
						setIsActiveStandup={setIsActiveStandup}
						assignees={assignees}
						setAssigneeFilter={setAssigneeFilter}
						currentlyFilteredAssignee={currentlyFilteredAssignee}
					/>
				)}
				{Board && (
					<>
						<Board hasMinimap={hasMinimap} isCacheHit={isCacheHit} />
						<ErrorBoundary
							packageName="jiraDevelopmentDetailsLoader"
							id="devDetailsDialogLoader"
							extraEventData={{ teamName: 'nova' }}
						>
							<DevDetailsDialogLoaderAsync scopeId="software-board.board" />
						</ErrorBoundary>
						<FastVirtualForceRecalculateSubscribers />
					</>
				)}
				{!isIncrementPlanningBoard &&
					(isDetailViewSidebar ||
						isInsightsPanelOpen ||
						(isViewSettingAsPanelExpEnabledWithNoExposure() && isViewSettingPanelOpen)) && (
						<DetailView
							isDetailViewSidebar={isDetailViewSidebar}
							isInsightsPanelOpen={isInsightsPanelOpen}
							// @ts-expect-error TS2322 - XSRF token possibly is dead code
							xsrfToken={xsrfToken}
							rapidViewId={rapidViewId}
							parentWidth={width}
							projectId={projectId}
							estimationStatistic={estimationStatistic}
						/>
					)}
			</BoardArea>
		</SizeDetectorWrapper>
	);

	const boardContents = () => (
		<>
			{isCMPBoard && <CmpBoardSlaTracker />}
			<GlobalSearchTracker />
			{!isIncrementPlanningBoard && DocumentTitle && <DocumentTitle />}
			<NextBestTaskContainer
				accountId={currentUserAccountId}
				boardId={rapidViewId}
				isCMPBoard={isCMPBoard}
				sprints={selectedSprintsOrAllSprints}
				onIssueOpen={onIssueOpen}
			>
				<UserPropertiesContainer
					scope={NEXT_BEST_TASK_USER_PROPERTIES_SCOPE}
					accountId={currentUserAccountId}
				>
					<IssueLinksHandlerContainer onIssueOpen={onIssueOpen} isGlobal>
						<NBTViewSettingsSpotlightContainer
							shouldShowSpotlight={(!selectedIssue || isDetailViewSidebar) && isJSWBoard}
						>
							<StandupEntryPointContextProvider>
								<ViewWrapper>
									{shouldMountStorageLimitsBanner && <StorageLimitsBanner />}
									<Header
										customFiltersQueryRef={customFiltersQueryRef}
										boardFilterRefinementQueryRef={boardFilterRefinementQueryRef}
										isEmbedView={isEmbedView}
										isActiveStandup={isActiveStandup}
										setIsActiveStandup={setIsActiveStandup}
									/>
									<ColumnDropIndicatorContainer ref={columnDropIndicatorContainerRef} />
									{issueLimitExceeded ? renderBoardSizeLimitEmptyState() : renderBoard()}
								</ViewWrapper>
							</StandupEntryPointContextProvider>
						</NBTViewSettingsSpotlightContainer>
					</IssueLinksHandlerContainer>
				</UserPropertiesContainer>
			</NextBestTaskContainer>
			<PrefetchIssues />
			{ViewTracker && <ViewTracker />}
			{/* pre-tangerine-classic flags implementation. Use playbook/flags.md as an example instead */}
			{hasFlags && Flags && <Flags />}
			{hasModals && selectedIssue && !isIncrementPlanningBoard && !isDetailViewSidebar && (
				<IssueViewModal
					rapidViewId={Number(rapidViewId)}
					externalAction={externalActionForIssueView}
				/>
			)}
			{hasModals && Modals && (
				<ErrorBoundary id="async-board-modals" packageName="software-board">
					<Placeholder name="board-modals" fallback={null}>
						<Modals />
					</Placeholder>
				</ErrorBoundary>
			)}
			<AddBoardToHistory baseUrl={baseUrl} boardId={rapidViewId} />
			<Realtime />
		</>
	);

	const renderBoardContentsWithContextProviders = () => {
		if (isIncrementPlanningBoard) {
			if (fg('issue_view_in_program_board')) {
				if (fg('fix-parent_flick_on_hovering_program_board')) {
					return (
						<ProgramBoardParentSwitcherEntrypointContextProvider>
							<PlanModalEntrypointContext.Provider value={entryPointResult}>
								{boardContents()}
							</PlanModalEntrypointContext.Provider>
						</ProgramBoardParentSwitcherEntrypointContextProvider>
					);
				}
				return (
					<PlanModalEntrypointContext.Provider value={entryPointResult}>
						{boardContents()}
					</PlanModalEntrypointContext.Provider>
				);
			}
			if (fg('fix-parent_flick_on_hovering_program_board')) {
				return (
					<ProgramBoardParentSwitcherEntrypointContextProvider>
						{boardContents()}
					</ProgramBoardParentSwitcherEntrypointContextProvider>
				);
			}
		}
		return boardContents();
	};

	return (
		<>
			<Wrapper
				height={getWillShowNav4() ? '100%' : `calc(100vh - ${wrapperHeightOffset})`}
				data-test-id="software-board.board"
				data-testid="software-board.board"
				isThemingEnabled={fg('jira_theming_milestone_1_fg')}
			>
				{renderBoardContentsWithContextProviders()}
			</Wrapper>
			{shouldShowIssueLimitFlags && (
				<IssueLimitFlags
					boardId={rapidViewId}
					isCMP={isCMPBoard}
					isProjectAdmin={canEditBoard}
					projectKey={projectKey}
					views={[{ view: 'BOARD', issueCount }]}
				/>
			)}
			{!isTabNavigation && (
				<ProjectKeyboardShortcuts
					boardId={rapidViewId}
					projectKey={projectKey}
					isCMPBoard={isCMPBoard}
				/>
			)}
			{isSyntheticTenant() && (
				<AsyncLaunchToolchainSpotlight projectId={projectId} projectKey={projectKey} />
			)}
		</>
	);
};

export default View;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled2.div<{ height: string | number; isThemingEnabled: boolean }>(
	{
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		height: (props) => props.height,
		overflow: 'hidden',
		boxSizing: 'border-box',
		display: 'flex',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	(props) =>
		!props.isThemingEnabled && {
			backgroundColor: token('elevation.surface'),
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ViewWrapper = styled2.div({
	display: 'flex',
	flexDirection: 'column',
	boxSizing: 'border-box',
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BoardArea = styled2.div({
	display: 'flex',
	height: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SizeDetectorWrapper = styled2.div({
	height: '100%',
	maxHeight: '100%',
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColumnDropIndicatorContainer = styled2.div({
	position: 'relative',
	height: 0,
	width: '100%',
	marginTop: 0,
	marginRight: token('space.500'),
	marginBottom: 0,
	marginLeft: token('space.500'),
});
