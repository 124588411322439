import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	pageTitle: {
		id: 'software.main.document-title.page-title',
		defaultMessage: '{boardName} - Agile board',
		description: 'Main document title',
	},
	pageTitleWithSprint: {
		id: 'software.main.document-title.page-board-title-with-sprint',
		defaultMessage:
			'{isKanbanBoard, select, true {{boardName} - Kanban Board} other {{sprintCount, plural, =0 {{boardName} - Scrum Board} one {{sprintName} - {boardName} - Scrum Board} other {# sprints - {boardName} - Scrum Board} }} }',
		description: 'Main document title with a sprint, updated for dynamic title',
	},
});
