import { useEffect } from 'react';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useBoardSelector } from '../../../../state/index.tsx';
import {
	boardHasIncompleteUnassignedWorkSelector,
	userHasIncompleteAssignedIssuesSelector,
} from '../../../../state/selectors/unassigned-work/unassigned-work-selector.tsx';
import { workIssuesCountSelector } from '../../../../state/selectors/work/work-selectors.tsx';

export const UnassignedWorkAnalytics = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const userHasIncompleteAssignedIssues = useBoardSelector(userHasIncompleteAssignedIssuesSelector);
	const boardHasIncompleteUnassignedWork = useBoardSelector(
		boardHasIncompleteUnassignedWorkSelector,
	);
	const numberOfWorkItems = useBoardSelector(workIssuesCountSelector);

	useEffect(() => {
		const event = createAnalyticsEvent({});
		if (numberOfWorkItems > 0) {
			fireTrackAnalytics(event, 'boardInState viewed', {
				userHasIncompleteAssignedIssues,
				boardHasIncompleteUnassignedWork,
			});
		}
	}, [
		createAnalyticsEvent,
		userHasIncompleteAssignedIssues,
		boardHasIncompleteUnassignedWork,
		numberOfWorkItems,
	]);

	return null;
};
