import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createSuccessFlagTitle: {
		id: 'software-flags.card-create-global.create-success-flag-title',
		defaultMessage: 'You\'ve created "{issueKey}" issue.',
		description: 'Description for success flag when issue is created',
	},
	viewIssueLinkText: {
		id: 'software-flags.card-create-global.view-issue-link-text',
		defaultMessage: 'View {numIssues, plural, one {issue} other {issues}}',
		description: 'CTA for success flag when issue is created',
	},
	multiplesIssuesCreatedSuccessFlagTitle: {
		id: 'software-flags.card-create-global.multiples-issues-created-success-flag-title',
		defaultMessage: "You've created {issueCount, plural, one {# issue} other {# issues}}.",
		description: 'Description for success flag when multiple issues created',
	},
	copyLink: {
		id: 'software-flags.card-create-global.copy-link',
		defaultMessage: 'Copy link',
		description: 'CTA for copying issueDiscovery link',
	},
	linkCopied: {
		id: 'software-flags.card-create-global.link-copied',
		defaultMessage: 'Copied',
		description: 'Text to displayed when user copies the link',
	},
	createSuccessFlagTitleIssueTermRefresh: {
		id: 'software-flags.card-create-global.create-success-flag-title-issue-term-refresh',
		defaultMessage: 'You\'ve created "{issueKey}" work item.',
		description: 'Description for success flag when issue is created',
	},
	viewIssueLinkTextIssueTermRefresh: {
		id: 'software-flags.card-create-global.view-issue-link-text-issue-term-refresh',
		defaultMessage: 'View{numIssues, plural, one {} other { work items}}',
		description: 'CTA for success flag when issue is created',
	},
	multiplesIssuesCreatedSuccessFlagTitleIssueTermRefresh: {
		id: 'software-flags.card-create-global.multiples-issues-created-success-flag-title-issue-term-refresh',
		defaultMessage: "You've created {issueCount, plural, one {# work item} other {# work items}}.",
		description: 'Description for success flag when multiple issues created',
	},
});
