import { useMemo } from 'react';
import { usePathParam, useResource } from '@atlassian/react-resource-router';
// eslint-disable-next-line jira/import-whitelist
import { toItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/main.tsx';
import type { Page } from '@atlassian/jira-servicedesk-queues-common/src/rest/common/types.tsx';
import { queuesNavigationItems } from '../../services/index.tsx';

export const useCurrentQueue = () => {
	const [queueId] = usePathParam('queueId');
	const [practiceType] = usePathParam('practiceType');

	const category = toItsmPractice(practiceType);

	const { data: allData, loading, error, refresh, update } = useResource(queuesNavigationItems);

	const categorizedQueues: Page[] = useMemo(() => allData?.[category]?.queues, [allData, category]);

	const selectedQueue = categorizedQueues?.find((queue) => queue.id === Number(queueId));

	const updateCurrentQueue = (data: Pick<Page, 'name' | 'jql' | 'columns' | 'columnsDetails'>) => {
		update((currentData) => ({
			...currentData,
			[category]: {
				...currentData[category],
				queues: categorizedQueues.map((queue) => {
					if (queue.id === Number(queueId)) {
						return { ...queue, ...data };
					}
					return queue;
				}),
			},
		}));
	};

	return {
		queue: selectedQueue,
		loading,
		error,
		refreshQueue: refresh,
		updateCurrentQueue,
	};
};
