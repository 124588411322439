import React, { useState, useCallback, useMemo, useRef } from 'react';
import Button from '@atlaskit/button';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/show-more-horizontal';
import MoreIcon from '@atlaskit/icon/glyph/more';
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu';
import Popup, { type TriggerProps } from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { token } from '@atlaskit/tokens';
import { ThemedIconButton } from '@atlassian/jira-project-theme-components/src/ui/themed-button-new/ThemedButtonNew.tsx';
import type { ActiveKeyMap } from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-registry.tsx';
import Shortcuts from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcuts/index.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { ForgeAppsContainer } from '@atlassian/jira-forge-action-apps-controller/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIsFullscreen } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/fullscreen/index.tsx';
import { createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { ShortcutLabel } from '@atlassian/jira-shortcut-label/src/index.tsx';
import { useHasToolSections } from '@atlassian/jira-software-connect-toolbar/src/controllers/index.tsx';
import { BoardConnectMenuItems } from '@atlassian/jira-software-connect-toolbar/src/ui/board-connect-menu-items/index.tsx';
import { editSprintModalEntryPoint } from '@atlassian/jira-software-edit-sprint-modal-relay/entrypoint.tsx';
import ManageWorkflowItem from '@atlassian/jira-software-manage-workflow-menu-item/src/ui/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import { StandupControlButtons } from '@atlassian/jira-standups/src/ui/control-buttons/index.tsx';
import type { StandupButtonRenderProps } from '@atlassian/jira-standups/src/common/types.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { Capability } from '../../../common/capability/index.tsx';
import { editSprintModalLoad } from '../../../services/utils/performance-analytics/index.tsx';
import {
	useCapability,
	useIsCMPBoard,
	useIsJSMBoard,
	useCanEditBoard,
	useIsIncrementPlanningBoard,
	useIsJSWBoard,
} from '../../../state/state-hooks/capabilities/index.tsx';
import { shouldDisableStandup } from '../utils.tsx';
import CardMediaItem from './card-media-item/index.tsx';
import ConfigureBoardItem from './configure-board-item/index.tsx';
import ConfigureBoardMenuItem from './configure-board/index.tsx';
import { BoardConnectItems } from './connect-items/index.tsx';
import { boardConnectAppLocations } from './constants.tsx';
import CreateBoardMenuItem from './create-board/index.tsx';
import { EditSprintMenuItem } from './edit-sprint-item/index.tsx';
import EditSprintModalEntryPoint from './edit-sprint-modal/index.tsx';
import BoardForgeActionMenuItems from './forge-items/index.tsx';
import { BoardActionForgeRuntimeContainer } from './forge-runtime-container/index.tsx';
import ManageCustomFiltersItem from './manage-custom-filters-item/index.tsx';
import messages from './messages.tsx';
import ReleaseVersion from './release-version/index.tsx';

export type StateProps = {
	isCardMediaSwitchEnabled: boolean;
	isConfigureBoardEnabled: boolean;
	isDisabled: boolean;
	isEditSprintItemVisible: boolean;
	isManageCustomFiltersEnabled: boolean;
	issueTypeId: string;
	projectKey: string;
	boardId: string;
	sprintId?: number;
	isReleaseVersionVisible?: boolean;
	hasOperations?: boolean;
};

type OwnProps = {
	boardType?: string;
	isActiveStandup: boolean;
	hasActiveSprint: boolean;
	isSprintsEnabled: boolean;
	setIsActiveStandup: React.Dispatch<React.SetStateAction<boolean>>;
	rapidViewId: number;
};

export type BoardMenuProps = OwnProps & StateProps;

const BoardMenuWithForgeRuntimeContainer = ({
	isCardMediaSwitchEnabled,
	isConfigureBoardEnabled,
	isDisabled,
	isEditSprintItemVisible,
	isManageCustomFiltersEnabled,
	issueTypeId,
	projectKey,
	boardId,
	sprintId,
	isReleaseVersionVisible,
	hasOperations,
	boardType,
	isActiveStandup,
	hasActiveSprint,
	isSprintsEnabled,
	rapidViewId,
	setIsActiveStandup,
}: BoardMenuProps) => {
	const { formatMessage } = useIntl();
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const moreActionsTriggerRef = useRef<HTMLButtonElement | null>(null);

	const [isFullscreen, { setIsFullscreen }] = useIsFullscreen();

	const isJSMBoard = useIsJSMBoard();
	const canEditBoard = useCanEditBoard();

	const isVisualRefreshDrop6Enabled = isVisualRefreshEnabled() && fg('visual-refresh_drop_6');
	const isJSWBoard =
		// eslint-disable-next-line react-hooks/rules-of-hooks
		isVisualRefreshDrop6Enabled ? useIsJSWBoard() : undefined;
	const isIncrementPlanningBoard = isVisualRefreshDrop6Enabled
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useIsIncrementPlanningBoard()
		: undefined;

	const isTabNavigation = useCapability(Capability.IS_TAB_NAVIGATION);
	const shouldRenderBoardMoreMenuItem = useCapability(Capability.RENDER_BOARD_MORE_MENU_ITEM);
	// move the Enter Fullscreen action in dropdown if Nav4 && EAP for Software projects only
	const shouldRenderFullscreenMenuItem = useCapability(
		Capability.RENDER_BOARD_FULLSCREEN_MENU_ITEM,
	);
	const shouldRenderBoardSettingsMenuItem = useCapability(
		Capability.RENDER_BOARD_SETTINGS_MENU_ITEM,
	);
	const shouldRenderCreateBoardMenuItem = useCapability(Capability.RENDER_CREATE_BOARD_MENU_ITEM);
	const shouldRenderEditSprintMenuItem = useCapability(Capability.RENDER_EDIT_SPRINT_MENU_ITEM);
	const shouldRenderManageWorkflowMenuItem = useCapability(
		Capability.RENDER_MANAGE_WORKFLOW_MENU_ITEM,
	);

	const isCMPBoard = useIsCMPBoard();
	const cloudId = useCloudId();

	const isConnectAppsVisible =
		useHasToolSections({
			boardId,
			projectKey,
			mode: 'work',
			itemLocations: boardConnectAppLocations,
		}) ||
		(hasOperations ?? false);

	const onClose = useCallback(() => {
		setIsPopupOpen(false);
	}, [setIsPopupOpen]);

	const onPopupClick = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({ actionSubject: 'popupMenu', action: 'toggled' }),
			'boardHeaderMenu',
		);
		setIsPopupOpen(!isPopupOpen);
	}, [setIsPopupOpen, createAnalyticsEvent, isPopupOpen]);

	const popupTrigger = useCallback(
		({ ref, ...triggerProps }: TriggerProps) => {
			const triggerRef = mergeRefs(ref, moreActionsTriggerRef);
			if (isVisualRefreshEnabled() || isTabNavigation) {
				return (
					<ThemedIconButton
						{...triggerProps}
						ref={triggerRef}
						icon={ShowMoreHorizontalIcon}
						// explicit boolean here since default, value is true, so when clean up, we need to keep the props with false value
						// eslint-disable-next-line no-unneeded-ternary
						isTooltipDisabled={fg('visual-refresh_drop_3') ? false : true}
						label={formatMessage(
							fg('visual-refresh_drop_3') ? messages.iconButtonLabel : messages.iconButtonLabelOld,
						)}
						onClick={onPopupClick}
						testId="software-board.header.menu.icon-button"
						isSelected={isPopupOpen}
					/>
				);
			}
			return (
				<Button
					{...triggerProps}
					ref={triggerRef}
					iconBefore={
						<ShowMoreHorizontalIcon
							label={isVisualRefreshEnabled() && fg('visual-refresh_drop_3') ? '' : 'More actions'}
							color={token('color.icon')}
							LEGACY_fallbackIcon={MoreIcon}
							LEGACY_primaryColor={token('color.icon')}
						/>
					}
					aria-label={formatMessage(
						isVisualRefreshEnabled() && fg('visual-refresh_drop_3')
							? messages.iconButtonLabel
							: messages.dropdownAriaLabel,
					)}
					onClick={onPopupClick}
					isSelected={isPopupOpen}
					testId="software-board.header.menu.button"
				/>
			);
		},
		[formatMessage, onPopupClick, isPopupOpen, isTabNavigation],
	);

	const boardAri = createAri({
		resourceOwner: 'jira-software',
		cloudId,
		resourceType: 'board',
		resourceId: String(boardId),
	});

	const toggleFullscreen = useCallback(() => {
		setIsFullscreen(!isFullscreen);
		onClose();
	}, [isFullscreen, setIsFullscreen, onClose]);

	const shortcutsKeyMap: ActiveKeyMap = {
		z: {
			callback: toggleFullscreen,
		},
	};

	const entryPointParams = useMemo(
		() => ({
			boardId: boardAri,
			sprintId: createAri({
				resourceOwner: 'jira-software',
				cloudId,
				resourceType: 'sprint',
				resourceId: String(sprintId),
			}),
			fetchSprints: true,
			isCompanyManaged: isCMPBoard,
		}),
		[boardAri, cloudId, sprintId, isCMPBoard],
	);

	const { entryPointActions, entryPointReferenceSubject, stopMetric } = useEntryPoint(
		editSprintModalEntryPoint,
		entryPointParams,
		editSprintModalLoad,
	);

	const editSprintModalTriggerRef = useEntryPointButtonTrigger(entryPointActions);

	const renderReleaseDropdownItem = useCallback(
		() => isReleaseVersionVisible && isTabNavigation && <ReleaseVersion rapidViewId={boardId} />,
		[boardId, isReleaseVersionVisible, isTabNavigation],
	);

	const popupContentStandupButtonRender = useCallback(
		({
			label,
			isDisabled: isButtonDisabled,
			onClick: onButtonClicked,
			ref,
		}: StandupButtonRenderProps) => {
			const handleButtonClick = () => {
				setIsPopupOpen(false);
				onButtonClicked();
			};

			return (
				<ButtonItem ref={ref} isDisabled={isButtonDisabled} onClick={handleButtonClick}>
					{label}
				</ButtonItem>
			);
		},
		[],
	);

	const popupContent = useCallback(() => {
		return (
			<MenuGroup maxWidth={isTabNavigation ? 240 : undefined}>
				<Section>
					{shouldRenderFullscreenMenuItem && (
						<ButtonItem
							iconAfter={<ShortcutLabel text="Z" />}
							onClick={toggleFullscreen}
							aria-keyshortcuts="Z"
						>
							{isFullscreen
								? formatMessage(messages.exitFullScreen)
								: formatMessage(messages.enterFullScreen)}
						</ButtonItem>
					)}
					{!isIncrementPlanningBoard &&
						isJSWBoard &&
						isVisualRefreshDrop6Enabled &&
						expVal('jira-in-product-standups', 'is_enabled', false) && (
							<StandupControlButtons
								isStandupDisabled={shouldDisableStandup({
									boardType,
									isSprintsEnabled,
									hasActiveSprint,
								})}
								isActiveStandup={isActiveStandup}
								setIsActiveStandup={setIsActiveStandup}
								boardId={Number(rapidViewId)}
								render={popupContentStandupButtonRender}
							/>
						)}

					{isEditSprintItemVisible && shouldRenderEditSprintMenuItem && (
						<EditSprintMenuItem ref={editSprintModalTriggerRef} onClick={onClose} />
					)}

					{shouldRenderManageWorkflowMenuItem && (
						<ManageWorkflowItem
							projectKey={projectKey}
							isDisabled={isDisabled}
							issueTypeId={issueTypeId}
						/>
					)}

					{!isCMPBoard && isManageCustomFiltersEnabled ? <ManageCustomFiltersItem /> : null}

					{!isCMPBoard && isCardMediaSwitchEnabled ? (
						<CardMediaItem isDisabled={isDisabled} onClick={onClose} />
					) : null}

					{!isCMPBoard && isConfigureBoardEnabled ? <ConfigureBoardItem /> : null}

					{shouldRenderBoardSettingsMenuItem && <ConfigureBoardMenuItem />}
					{isCMPBoard && !isTabNavigation && (
						<BoardConnectMenuItems
							boardId={boardId}
							projectKey={projectKey}
							location="board-tools-1"
							onClickWebItem={onClose}
							mode="work"
						/>
					)}

					{shouldRenderCreateBoardMenuItem && <CreateBoardMenuItem onClosePopup={onClose} />}

					{isCMPBoard && !isTabNavigation && (
						<>
							<BoardConnectMenuItems
								boardId={boardId}
								projectKey={projectKey}
								location="view-actions-work"
								mode="work"
								onClickWebItem={onClose}
							/>
							<BoardConnectMenuItems
								boardId={boardId}
								projectKey={projectKey}
								location="view-actions-print"
								onClickWebItem={onClose}
								mode="work"
							/>
						</>
					)}

					{renderReleaseDropdownItem()}
				</Section>
				{isTabNavigation && isConnectAppsVisible && (
					<BoardConnectItems
						projectKey={projectKey}
						boardId={boardId}
						onClickWebItem={onClose}
						isTabNavigation
					/>
				)}
				{fg('forge-ui-project-web-items') && <BoardForgeActionMenuItems />}
			</MenuGroup>
		);
	}, [
		boardId,
		editSprintModalTriggerRef,
		isCMPBoard,
		isJSWBoard,
		isIncrementPlanningBoard,
		isCardMediaSwitchEnabled,
		isConfigureBoardEnabled,
		isConnectAppsVisible,
		isDisabled,
		isEditSprintItemVisible,
		isManageCustomFiltersEnabled,
		isTabNavigation,
		issueTypeId,
		onClose,
		projectKey,
		renderReleaseDropdownItem,
		shouldRenderBoardSettingsMenuItem,
		shouldRenderCreateBoardMenuItem,
		shouldRenderEditSprintMenuItem,
		shouldRenderManageWorkflowMenuItem,
		shouldRenderFullscreenMenuItem,
		isFullscreen,
		toggleFullscreen,
		formatMessage,
		isActiveStandup,
		boardType,
		isSprintsEnabled,
		hasActiveSprint,
		rapidViewId,
		setIsActiveStandup,
		popupContentStandupButtonRender,
		isVisualRefreshDrop6Enabled,
	]);

	const onEditSprintModalClose = useCallback((status?: 'SUCCESS' | 'FAILURE') => {
		//  Timeout is required because onClose callback called on Modal onClose instead of onCloseComplete
		setTimeout(() => moreActionsTriggerRef.current?.focus(), status ? 50 : 400);
	}, []);

	// hide the meatball menu button if user doesn't have edit board permission for JSM project
	if (isJSMBoard && !canEditBoard) {
		return null;
	}

	return (
		<>
			{fg('forge-ui-project-web-items') && (
				<BoardActionForgeRuntimeContainer onAppsFinishLoading={onClose} />
			)}

			{isEditSprintItemVisible && (
				<EditSprintModalEntryPoint
					entryPointReferenceSubject={entryPointReferenceSubject}
					entryPointActions={entryPointActions}
					onReady={stopMetric}
					onClose={onEditSprintModalClose}
				/>
			)}
			{(shouldRenderFullscreenMenuItem ||
				shouldRenderBoardMoreMenuItem ||
				(isTabNavigation && isConnectAppsVisible)) && (
				<>
					<Popup
						isOpen={isPopupOpen}
						onClose={onClose}
						placement="bottom-end"
						content={popupContent}
						trigger={popupTrigger}
						shouldRenderToParent
						testId="software-board.header.menu.popup"
					/>
					{shouldRenderFullscreenMenuItem && <Shortcuts keyMap={shortcutsKeyMap} />}
				</>
			)}
		</>
	);
};

const BoardMenu = (props: BoardMenuProps) => {
	if (fg('forge-ui-project-web-items')) {
		return (
			<ForgeAppsContainer scope="board-action">
				<BoardMenuWithForgeRuntimeContainer {...props} />
			</ForgeAppsContainer>
		);
	}
	return <BoardMenuWithForgeRuntimeContainer {...props} />;
};

export default BoardMenu;
