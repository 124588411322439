// eslint-disable-next-line jira/restricted/react
import React, { PureComponent } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import type InlineEditType from '@atlaskit/inline-edit';
import type { InlineEditProps } from '@atlaskit/inline-edit';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';

type Props = {
	isEditing: boolean;
} & InlineEditProps<string> & {
		onOuterClick: (event: MouseEvent) => void;
	};

const AsyncInlineEdit = lazyAfterPaint<typeof InlineEditType<string>>(
	() => import(/* webpackChunkName: "async-board-inline-edit" */ '@atlaskit/inline-edit'),
);

// eslint-disable-next-line jira/react/no-class-components
export default class InlineEdit extends PureComponent<Props> {
	static defaultProps = {
		onOuterClick: noop,
	};

	componentDidMount() {
		if (this.props.isEditing) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.addEventListener('click', this.onWindowMouseClick, true);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.isEditing !== nextProps.isEditing) {
			if (nextProps.isEditing) {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.addEventListener('click', this.onWindowMouseClick, true);
			} else {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.removeEventListener('click', this.onWindowMouseClick, true);
			}
		}
	}

	componentWillUnmount() {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.removeEventListener('click', this.onWindowMouseClick, true);
	}

	onWindowMouseClick = (event: MouseEvent) => {
		if (!(event.target instanceof Node) || !this.container?.contains(event.target)) {
			this.props.onOuterClick(event);
		}
	};

	setContainer = (container?: HTMLElement | null) => {
		this.container = container;
	};

	container: HTMLElement | null | undefined;

	render() {
		const { readView, onConfirm, onCancel, onOuterClick, ...otherProps } = this.props;

		return (
			<ErrorBoundary id="async-column-inline-edit" packageName="JiraPlatformBoardKit">
				<InlineEditContainer
					ref={this.setContainer}
					// Prevent click from propagate to column header
					onClick={(event: React.MouseEvent) => event.stopPropagation()}
				>
					<Placeholder
						name="async-board-inline-edit"
						fallback={<Box xcss={readViewWrapperStyles}>{readView()}</Box>}
					>
						<AsyncInlineEdit
							readView={readView}
							onCancel={onCancel}
							onConfirm={onConfirm}
							{...otherProps}
						/>
					</Placeholder>
				</InlineEditContainer>
			</ErrorBoundary>
		);
	}
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InlineEditContainer = styled.div({
	maxWidth: '100%',
	marginTop: token('space.negative.100'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&& > *': {
		marginLeft: 0,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'.msie-11 &': {
		marginBottom: token('space.negative.050'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'input:-ms-input-placeholder': {
			color: 'transparent',
		},
	},
});

const readViewWrapperStyles = xcss({
	marginTop: 'space.100',
	marginLeft: 'space.100',
	marginRight: 'space.025',
	paddingLeft: 'space.025',
});
