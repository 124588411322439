import { graphql, fetchQuery } from 'react-relay';
import { Observable as ObservableType } from 'rxjs/Observable';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';
import type {
	hydrateJqlQuery,
	hydrateJqlQuery$data as QueryResponse,
	hydrateJqlQuery$variables as HydrateJqlVariables,
} from '@atlassian/jira-relay/src/__generated__/hydrateJqlQuery.graphql';
import { ASSIGNEE, TEXT } from '../../model/filter/filter-types.tsx';
import type { HydratedValues } from './types.tsx';
import { getSelectedFieldValues, getFieldId, getFieldTypeName } from './utils.tsx';
import 'rxjs/add/observable/throw';

export const transformResponse = (
	response: QueryResponse | undefined,
	jqlContext: string,
): HydratedValues => {
	const fields = response?.jira?.jqlBuilder?.hydrateJqlQuery?.fields;
	const hydratedValues: HydratedValues = {};

	fields &&
		fields.forEach((hydratedField) => {
			if (!hydratedField) {
				return;
			}
			const { field, values } = hydratedField;

			if (!field || !values) {
				return;
			}
			const fieldTypeName = getFieldTypeName(field.type);
			if (fieldTypeName == null) {
				return;
			}

			hydratedValues[fieldTypeName] = values
				.map((item) => (item && item.values && item.values[0]) ?? null)
				.filter(Boolean)
				.map((item) => getFieldId(item, field.type));
		});

	if (hydratedValues[TEXT] && typeof hydratedValues[TEXT] === 'object') {
		// only use text not array
		// @ts-expect-error missing type for text
		hydratedValues[TEXT] = hydratedValues[TEXT][0];
	}

	// we can't use hydratedResponse because it does not return an empty object for 'empty' assignee
	const assignees = getSelectedFieldValues(jqlContext, 'assignee').map((name) =>
		name === 'empty' ? 'unassigned' : name,
	);

	if (assignees.length > 0) {
		hydratedValues[ASSIGNEE] = assignees;
	}

	return hydratedValues;
};

export const hydrateJqlBuilderQuery = ({
	cloudId,
	jql,
}: HydrateJqlVariables): ObservableType<HydratedValues> =>
	ObservableType.fromPromise(
		fetchQuery<hydrateJqlQuery>(
			getRelayEnvironment(),
			/* eslint-disable @atlassian/relay/unused-fields */
			graphql`
				query hydrateJqlQuery($cloudId: ID!, $jql: String!) {
					jira {
						jqlBuilder(cloudId: $cloudId) {
							hydrateJqlQuery(query: $jql) {
								... on JiraJqlHydratedQuery {
									fields {
										... on JiraJqlQueryHydratedField {
											field {
												jqlTerm
												displayName
												fieldId
												type
											}
											values {
												... on JiraJqlQueryHydratedValue {
													values {
														displayName
														jqlTerm
														__typename
														... on JiraJqlIssueFieldValue {
															issue {
																issueId
															}
														}

														... on JiraJqlIssueTypeFieldValue {
															issueTypes {
																issueTypeId
															}
														}
														... on JiraJqlSprintFieldValue {
															sprint {
																sprintId
															}
														}
														... on JiraJqlVersionFieldValue {
															version {
																versionId
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			`,
			{ cloudId, jql },
		)
			.toPromise()
			.then((response) => transformResponse(response, jql))
			.catch((error) => {
				fireErrorAnalytics({
					meta: {
						id: 'HydrateJqlQuery',
						packageName: 'jiraSoftwareBoards',
						teamName: 'A4T-Tanuki',
					},
					error,
					sendToPrivacyUnsafeSplunk: true,
				});
				throw error;
			}),
	);
