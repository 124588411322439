import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { PLANS_USER_AUTOCOMPLETE_URL } from '../../common/constants.tsx';
import type { PlansUserResponse, PlansUser, User } from './types.tsx';

const transformPlanAssigneeUserResponse = (response: PlansUserResponse): User[] =>
	(response &&
		response.results &&
		response.results.map((user: PlansUser) => ({
			accountId: user.jiraUser.accountId,
			avatarUrls: { '48x48': user.jiraUser.avatarUrl },
			displayName: user.jiraUser.title,
			emailAddress: user.jiraUser.email,
		}))) ||
	[];

export const fetchAssigneeSuggestions = (query?: string): Promise<User[]> =>
	fetchJson(PLANS_USER_AUTOCOMPLETE_URL, {
		method: 'POST',
		body: JSON.stringify({ excludedIds: [], maxResults: 50, query }),
	}).then((response: PlansUserResponse) => {
		return transformPlanAssigneeUserResponse(response);
	});
