import { freeze } from 'icepick';
import { expVal } from '@atlassian/jira-feature-experiments';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import {
	SWIMLANE_TEAMLESS,
	TEAM,
} from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/constants.tsx';
import type { SwimlaneByTeamValues } from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/types.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import type { ColumnIdKey } from '../column/column-types.tsx';
import messages from './messages.tsx';
import { SWIMLANE_CHILDLESS, SWIMLANE_PARENTLESS, SWIMLANE_UNASSIGNED } from './swimlane-modes.tsx';

export const ASSIGNEE = 'ASSIGNEE' as const;
export const PARENT = 'PARENT' as const;
export const STORY = 'STORY' as const;
export const JQL = 'JQL' as const;
export const PROJECT = 'PROJECT' as const;
export const REQUEST_TYPE = 'REQUEST_TYPE' as const;

export type SwimlaneId = string;

type SwimlaneByAssigneeValues = {
	type: typeof ASSIGNEE;
	assigneeAccountId: string | null;
};

type SwimlaneByParentValues = {
	type: typeof PARENT;
	parentId: IssueId | null;
};

type SwimlaneByStoryValues = {
	type: typeof STORY;
	parentId: IssueId | null;
};

type SwimlaneByJqlValues = {
	type: typeof JQL;
	issueIds: IssueId[];
};

type SwimlaneByProjectValues = {
	type: typeof PROJECT;
	projectId: number;
};

type SwimlaneByRequestTypeValues = {
	type: typeof REQUEST_TYPE;
	id: string | null;
};

export type SwimlaneValues =
	| SwimlaneByAssigneeValues
	| SwimlaneByParentValues
	| SwimlaneByStoryValues
	| SwimlaneByJqlValues
	| SwimlaneByProjectValues
	| SwimlaneByTeamValues
	| SwimlaneByRequestTypeValues;

export type Swimlane = {
	id: SwimlaneId;
	name: string | null;
	message?: MessageDescriptor;
	imageUrl: string | null | undefined;
	values?: SwimlaneValues;
	issueKey: string | null;
	parentId?: IssueId | null;
	assigneeAccountId?: string | null;
	projectKey?: string;
	isFlagged?: boolean;
};

export type PlatformSwimlane = {
	id: string;
	name: string | null;
	message?: MessageDescriptor;
	imageUrl?: string | undefined | null;
	cardAmount: number;
	issueKey: string | null;
	mode: string;
	values?: SwimlaneValues;
	parentId: IssueId | null;
	assigneeAccountId: string | null;
	projectKey: string | null;
	isFlagged?: boolean;
};

export const TeamlessSwimlane = freeze({
	id: SWIMLANE_TEAMLESS,
	message: messages.unassigned,
	name: null,
	imageUrl: null,
	// set the isPlanTeam is true to hide the external lozenge
	values: { type: TEAM, isPlanTeam: true, verified: false },
	issueKey: null,
});

export const UnassignedSwimlane = freeze({
	id: SWIMLANE_UNASSIGNED,
	message: messages.unassigned,
	name: null,
	imageUrl: null,
	values: { type: ASSIGNEE, assignee: null, assigneeAccountId: null },
	issueKey: null,
});

export const ParentlessSwimlane = {
	id: SWIMLANE_PARENTLESS,
	get message() {
		return expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
			? messages.parentlessIssueTermRefresh
			: messages.parentless;
	},
	name: null,
	imageUrl: null,
	values: { type: PARENT, parentId: null },
	issueKey: null,
} as const;

export const ChildlessSwimlane = freeze({
	id: SWIMLANE_CHILDLESS,
	message: messages.childless,
	name: null,
	imageUrl: null,
	values: { type: STORY, parentId: null },
	issueKey: null,
});

export const UnassignedRequestTypeSwimlane = freeze({
	id: SWIMLANE_UNASSIGNED,
	message: messages.noRequestType,
	name: null,
	imageUrl: null,
	values: { type: REQUEST_TYPE, id: null },
	issueKey: null,
});

export type IssueIdsBySwimlanes = Record<SwimlaneId, Record<ColumnIdKey, IssueId[]>>;
