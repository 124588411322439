import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from '@atlassian/jira-software-board-css-module/src/card-compact.module.css';
import type { RenderSummaryProps } from '../../types.tsx';
import { HighlightText, shouldHighlightText } from '../highlight-text/index.tsx';

export const CardSummary = ({
	text,
	highlight,
	shouldMenuRender,
	shouldTruncate = false,
}: RenderSummaryProps) => {
	const HighlightedText =
		highlight && shouldHighlightText(text, highlight) ? (
			<HighlightText phrases={highlight} content={text} />
		) : (
			text
		);
	const summary = (
		<Summary
			shouldMenuRender={shouldMenuRender || false}
			shouldTruncate={shouldTruncate}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={styles.summary}
		>
			{HighlightedText}
		</Summary>
	);

	if (!shouldTruncate) {
		return summary;
	}

	return (
		<Tooltip content={text} position="bottom">
			{summary}
		</Tooltip>
	);
};

CardSummary.defaultProps = {
	shouldMenuRender: false,
	highlight: [],
};

export default CardSummary;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Summary = styled.span<{ shouldMenuRender: boolean; shouldTruncate: boolean }>({
	wordWrap: 'break-word',
	overflowWrap: 'break-word',
	wordBreak: 'break-word',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingRight: ({ shouldMenuRender }) =>
		shouldMenuRender ? token('space.400') : token('space.0'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	WebkitLineClamp: ({ shouldTruncate }) => (shouldTruncate ? 3 : 'unset'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: ({ shouldTruncate }) => (shouldTruncate ? '-webkit-box' : 'inline'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	overflow: ({ shouldTruncate }) => (shouldTruncate ? 'hidden' : 'unset'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	WebkitBoxOrient: ({ shouldTruncate }) => (shouldTruncate ? 'vertical' : 'unset'),
});
