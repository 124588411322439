// Backend types
export const AGILITY_BOARD = 'agility-board' as const;
// suppressed to allow migration to typescript-eslint v8 - this is a new error reported with v8 and is only used as a type
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CORE_BOARD = 'core-board' as const;
export const KANBAN_BOARD = 'kanban' as const;
export const SCRUM_BOARD = 'scrum' as const;

export type BoardType =
	| typeof AGILITY_BOARD
	| typeof CORE_BOARD
	| typeof KANBAN_BOARD
	| typeof SCRUM_BOARD;
