import React from 'react';
import kebabCase from 'lodash/kebabCase';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Lozenge from '@atlaskit/lozenge';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import Toggle from '@atlaskit/toggle';
import { ScreenReaderText } from '@atlassian/jira-accessibility/src/common/ui/screenreader-text/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import messages from '../../messages.tsx';
import { ViewAs } from '../../types/constant.tsx';
import { isViewSettingsPanelAndTailoredViewExperimentEnabled } from '../../utils/index.tsx';

type Props = {
	title: string;
	isChecked: boolean;
	isDisabled?: boolean;
	onChange: (event: React.ChangeEvent<HTMLInputElement>, analyticsEvent: UIAnalyticsEvent) => void;
	keyboardShortcut?: string;
	subtitleLozenge?: string;
	testId?: string;
	viewAs?: (typeof ViewAs)[keyof typeof ViewAs];
	footer?: React.ReactNode;
};

export const ViewSettingsToggle = ({
	title,
	isChecked,
	isDisabled,
	onChange,
	keyboardShortcut,
	subtitleLozenge,
	testId,
	viewAs = ViewAs.POPUP,
	footer = undefined,
}: Props) => {
	const id = kebabCase(`jira-software-view-settings-${title}`);
	const descriptionId = `${id}-description`;
	const { formatMessage } = useIntl();

	return (
		<Box
			xcss={
				viewAs === ViewAs.PANEL && isViewSettingsPanelAndTailoredViewExperimentEnabled()
					? panelContainerStyles
					: popupContainerStyles
			}
		>
			<Inline space="space.200" spread="space-between" alignBlock="center">
				<Inline space="space.100" alignBlock="center">
					<label data-testid={testId} htmlFor={id}>
						{title}
					</label>
					{subtitleLozenge && <Lozenge appearance="new">{subtitleLozenge}</Lozenge>}
				</Inline>
				<Box
					xcss={
						isVisualRefreshEnabled() && fg('visual-refresh_drop_5')
							? tooltipContainerStyles
							: tooltipContainerStylesOld
					}
				>
					{keyboardShortcut && <Lozenge>{keyboardShortcut}</Lozenge>}
					<Toggle
						id={id}
						isChecked={isChecked}
						onChange={onChange}
						isDisabled={isDisabled}
						{...(keyboardShortcut && { descriptionId })}
					/>
					{keyboardShortcut && (
						<ScreenReaderText id={descriptionId}>
							{formatMessage(messages.toggleHint, {
								key: keyboardShortcut,
								panel: title,
							})}
						</ScreenReaderText>
					)}
				</Box>
			</Inline>
			{footer}
		</Box>
	);
};

const popupContainerStyles = xcss({
	marginBlock: 'space.100',
	marginInline: 'space.200',
	minWidth: '200px',
});

const panelContainerStyles = xcss({
	marginBlock: 'space.100',
	marginInline: 'auto',
	minWidth: '200px',
});

const tooltipContainerStylesOld = xcss({
	color: 'color.text.subtlest',
	whiteSpace: 'nowrap',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: 0,
});

const tooltipContainerStyles = xcss({
	color: 'color.text.subtlest',
	whiteSpace: 'nowrap',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: 0,
	display: 'flex',
	alignItems: 'center',
});
