/**
 * @generated SignedSource<<eb86e8d1943906f163ffbd378ad31dc2>>
 * @relayHash 7ee60f991e19b3350fd56f78e329edaf
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 4b6d01b54a80a6f305eec59cbde819a6e3d77e9f44d65db14ef4425cf88285c8

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraBoardSwimlaneStrategy = "ASSIGNEE_UNASSIGNED_FIRST" | "ASSIGNEE_UNASSIGNED_LAST" | "CUSTOM" | "EPIC" | "ISSUE_CHILDREN" | "ISSUE_PARENT" | "NONE" | "PARENT_CHILD" | "PROJECT" | "REQUEST_TYPE" | "%future added value";
export type JiraJqlScopeInput = {
  board?: JiraJqlBoardInput | null | undefined;
};
export type JiraJqlBoardInput = {
  boardId: AGG$Long;
  swimlaneStrategy?: JiraBoardSwimlaneStrategy | null | undefined;
};
export type ui_filterRefinementBasicFieldsQuery$variables = {
  cloudId: string;
  jqlContext?: string | null | undefined;
  scope?: JiraJqlScopeInput | null | undefined;
};
export type ui_filterRefinementBasicFieldsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ui_filterRefinement_FilterPopupContent">;
};
export type ui_filterRefinementBasicFieldsQuery = {
  response: ui_filterRefinementBasicFieldsQuery$data;
  variables: ui_filterRefinementBasicFieldsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "jqlContext"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "scope"
  }
],
v1 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v2 = {
  "kind": "Variable",
  "name": "jqlContext",
  "variableName": "jqlContext"
},
v3 = {
  "kind": "Variable",
  "name": "scope",
  "variableName": "scope"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "ui_filterRefinementBasicFieldsQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "ui_filterRefinement_FilterPopupContent"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ui_filterRefinementBasicFieldsQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": [
              (v1/*: any*/)
            ],
            "concreteType": "JiraJqlBuilder",
            "kind": "LinkedField",
            "name": "jqlBuilder",
            "plural": false,
            "selections": [
              {
                "args": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "kind": "LinkedField",
                "name": "fields",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "__typename"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "concreteType": "JiraJqlFieldEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "JiraJqlField",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "displayName"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "jqlTerm"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "type"
                              }
                            ]
                          }
                        ]
                      }
                    ],
                    "type": "JiraJqlFieldConnection"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "4b6d01b54a80a6f305eec59cbde819a6e3d77e9f44d65db14ef4425cf88285c8",
    "metadata": {},
    "name": "ui_filterRefinementBasicFieldsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "91f7a7d682ec5f25027ad0c9c9a2b35c";

import { PreloadableQueryRegistry } from 'relay-runtime';
PreloadableQueryRegistry.set(node.params.id, node);

export default node;
