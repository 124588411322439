import React from 'react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { IconButton } from '@atlaskit/button/new';
import Heading from '@atlaskit/heading';
import EditorCloseIcon from '@atlaskit/icon/core/migration/close--editor-close';
import EditorPanelIcon from '@atlaskit/icon/core/migration/information--editor-panel';
import OverviewIcon from '@atlaskit/icon/core/migration/align-left--overview';
import SprintIcon from '@atlaskit/icon/core/migration/sprint';
import InlineEdit from '@atlaskit/inline-edit';
import Lozenge, { type ThemeAppearance } from '@atlaskit/lozenge';
import { Stack, Inline, Text, Box, xcss, Bleed } from '@atlaskit/primitives';
import SectionMessage from '@atlaskit/section-message';
import Textfield from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl, type MessageDescriptor } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { SprintState } from '@atlassian/jira-shared-types/src/rest/jira/sprint.tsx';
import {
	IP_BOARD_DAYS_PLANNING_UNIT,
	IP_BOARD_HOURS_PLANNING_UNIT,
} from '../../../common/constants.tsx';
import commonMessages from '../../../common/messages.tsx';
import {
	getSprintStateLabel,
	convertCapacity,
	getCapacityUnitMessage,
} from '../../../common/utils.tsx';
import { useFormatDateUTC } from '../../../hooks/use-format-date/index.tsx';
import messages from './messages.tsx';
import type { Props, SprintDateProps, ProgressBarProps } from './types.tsx';

const sprintStatusAppearanceMap: Record<SprintState, ThemeAppearance> = {
	ACTIVE: 'inprogress',
	CLOSED: 'success',
	FUTURE: 'default',
};

const GOAL_LIMIT = 190;

const percentage = (count: number, total: number): number =>
	total === 0 ? 0 : Math.round((count / total) * 100);

const ProgressBar = ({
	progressInfo,
	progressPercentage,
	progressWidth,
	additionalBarStyles,
}: ProgressBarProps) => (
	<Inline spread="space-between" shouldWrap rowSpace="space.050">
		<Text
			size="small"
			weight="semibold"
			testId="portfolio-3-plan-increment-common.ui.sprint-column-header.sprint-capacity-flyout.progress-info"
		>
			{progressInfo}
		</Text>
		<Text size="small" weight="semibold">
			{progressPercentage}
		</Text>
		<Box xcss={[barStyles, progressBarStyles]}>
			<Box
				// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
				xcss={[barStyles, additionalBarStyles]}
				// eslint-disable-next-line jira/react/no-style-attribute
				style={{ width: `${progressWidth}%` }}
			/>
		</Box>
	</Inline>
);

const SprintDate = ({
	dateLabel,
	hasSprintDate,
	date,
	tooltipContent,
	iconLabel,
}: SprintDateProps) => (
	<Inline shouldWrap>
		<Text size="small" weight="semibold" color="color.text.subtle">
			{dateLabel}
		</Text>
		<Inline alignBlock="center" xcss={dateContainerStyles}>
			<Text as={hasSprintDate ? 'span' : 'em'}>{date}</Text>
			<Tooltip content={tooltipContent}>
				<EditorPanelIcon
					label={iconLabel}
					LEGACY_size="medium"
					spacing="spacious"
					color={token('color.icon')}
				/>
			</Tooltip>
		</Inline>
	</Inline>
);

const getProgressBarLabel = (planningUnit: string, isDone: boolean): MessageDescriptor => {
	const labelType = isDone ? 'Done' : 'Allocated';
	switch (planningUnit) {
		case IP_BOARD_DAYS_PLANNING_UNIT:
			return messages[`sprintDays${labelType}Label`];
		case IP_BOARD_HOURS_PLANNING_UNIT:
			return messages[`sprintHours${labelType}Label`];
		default:
			return messages[`sprintPoints${labelType}Label`];
	}
};

const Goal = ({ goal }: { goal?: string | null }) => {
	const { formatMessage } = useIntl();
	if (!goal) {
		return null;
	}
	const isOverLimit = goal.length > GOAL_LIMIT;
	return isOverLimit ? (
		<Tooltip content={goal} position="auto">
			<Text>
				{formatMessage(messages.sprintGoalEllipsed, { goal: goal.substring(0, GOAL_LIMIT).trim() })}
			</Text>
		</Tooltip>
	) : (
		<Text>{goal}</Text>
	);
};

const SprintCapacityFlyout = ({
	onFlyoutClose,
	sprint,
	totalCapacity,
	usedCapacity,
	doneCapacity,
	defaultCapacity,
	iterationEndDate,
	iterationStartDate,
	planningConfig: { planningUnit, workingHoursPerDay },
	onChangeCapacity,
	isReadOnly,
}: Props) => {
	const intl = useIntl();

	const total = convertCapacity(totalCapacity ?? defaultCapacity, planningUnit, workingHoursPerDay);

	const allocatedPercentage = percentage(usedCapacity, total);
	const donePercentage = percentage(doneCapacity, usedCapacity);

	const formatDateBasic = useFormatDateUTC(intl, true);
	const formatDateExtended = useFormatDateUTC(intl, true, true, true);
	const sprintStart = sprint.startDate || iterationStartDate;
	const sprintEnd = sprint.completedDate || sprint.endDate || iterationEndDate;
	const sprintStateLabel = getSprintStateLabel(sprint.state, intl.formatMessage);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return (
		<Stack
			space="space.150"
			xcss={stackStyles}
			testId="portfolio-3-plan-increment-common.ui.sprint-column-header.sprint-capacity-flyout.sprint-flyout"
		>
			<Inline alignBlock="center" spread="space-between">
				<Inline space="space.050" alignBlock="center">
					<SprintIcon label="" LEGACY_size="small" color={token('color.icon.subtle')} />
					<Text size="small" weight="semibold" color="color.text.subtlest">
						{intl.formatMessage(messages.titleForSprintCapacityFlyout)}
					</Text>
				</Inline>
				<IconButton
					onClick={onFlyoutClose}
					appearance="subtle"
					spacing="compact"
					icon={EditorCloseIcon}
					label={intl.formatMessage(messages.closeIconLabel)}
				/>
			</Inline>

			<Inline>
				<Lozenge appearance={sprintStatusAppearanceMap[sprint.state]}>{sprintStateLabel}</Lozenge>
			</Inline>

			<Heading size="small" as={fg('increment_board_a11y_fix') ? 'h2' : undefined}>
				{sprint.name}
			</Heading>

			<Goal goal={sprint.goal} />

			<Bleed block="space.200">
				<Inline
					space="space.150"
					spread="space-between"
					grow="fill"
					alignBlock="center"
					xcss={capacityInfoEditStyles}
				>
					<Inline space="space.100" alignBlock="center" xcss={capacityInfoStyles}>
						<OverviewIcon
							label={intl.formatMessage(messages.sprintCapacityOverviewLabel)}
							LEGACY_size="medium"
							spacing="spacious"
							color={token('color.icon')}
						/>
						<Text>
							{intl.formatMessage(messages.sprintCapacityLabel, {
								planningUnit: getCapacityUnitMessage(planningUnit, intl.formatMessage),
							})}
						</Text>
					</Inline>

					{isReadOnly ? (
						<Box
							xcss={[readViewContainerStyles]}
							testId="portfolio-3-plan-increment-common.ui.sprint-column-header.sprint-capacity-flyout.sprint-capacity-read-only"
						>
							{total}
						</Box>
					) : (
						<InlineEdit
							defaultValue={total}
							keepEditViewOpenOnBlur
							editView={({ errorMessage, ...fieldProps }) => (
								<Textfield
									{...fieldProps}
									min={0}
									max={99999}
									width="64"
									aria-label={intl.formatMessage(commonMessages.editCapacityLabel)}
									autoFocus
									type="number"
									isCompact
									{...(planningUnit === IP_BOARD_DAYS_PLANNING_UNIT && { step: 0.1 })}
								/>
							)}
							readView={() => (
								<Box
									xcss={[readViewContainerStyles]}
									testId="portfolio-3-plan-increment-common.ui.sprint-column-header.sprint-capacity-flyout.sprint-capacity-read-view"
								>
									{total}
								</Box>
							)}
							onConfirm={(value: string) => {
								onChangeCapacity(value);
								fireUIAnalytics(
									createAnalyticsEvent({ action: 'edited', actionSubject: 'sprintCapacity' }),
								);
							}}
							editButtonLabel={intl.formatMessage(commonMessages.editCapacityLabel)}
							confirmButtonLabel={intl.formatMessage(commonMessages.confirmButtonLabel)}
							cancelButtonLabel={intl.formatMessage(commonMessages.cancelButtonLabel)}
						/>
					)}
				</Inline>
			</Bleed>

			<ProgressBar
				progressInfo={intl.formatMessage(getProgressBarLabel(planningUnit, false), {
					numerator: usedCapacity,
					denominatorCount: total,
				})}
				progressPercentage={`${allocatedPercentage}%`}
				progressWidth={usedCapacity < total ? allocatedPercentage : 100}
				additionalBarStyles={usedCapacity > total ? overloadedBarStyles : allocatedBarStyles}
			/>

			<ProgressBar
				progressInfo={intl.formatMessage(getProgressBarLabel(planningUnit, true), {
					numerator: doneCapacity,
					denominatorCount: usedCapacity,
				})}
				progressPercentage={`${donePercentage}%`}
				progressWidth={donePercentage}
				additionalBarStyles={doneBarStyles}
			/>

			{sprintStart && sprintEnd && (
				<Inline space="space.200" alignBlock="center">
					<SprintDate
						dateLabel={intl.formatMessage(messages.sprintStartDateLabel)}
						hasSprintDate={!!sprint.startDate}
						date={formatDateBasic(sprintStart)}
						tooltipContent={formatDateExtended(sprintStart)}
						iconLabel={intl.formatMessage(messages.sprintStartDateInfoIconLabel)}
					/>
					<SprintDate
						dateLabel={intl.formatMessage(messages.sprintEndDateLabel)}
						hasSprintDate={!!sprint.endDate}
						date={formatDateBasic(sprintEnd)}
						tooltipContent={formatDateExtended(sprintEnd)}
						iconLabel={intl.formatMessage(messages.sprintEndDateInfoIconLabel)}
					/>
				</Inline>
			)}

			{(!sprint.startDate || !sprint.endDate) && (
				<Inline>
					<SectionMessage>
						{intl.formatMessage(messages.sprintDatesMissing, {
							sprintName: sprint.name,
						})}
					</SectionMessage>
				</Inline>
			)}
		</Stack>
	);
};

export default SprintCapacityFlyout;

const capacityInfoEditStyles = xcss({
	marginBlockEnd: 'space.200',
});
const capacityInfoStyles = xcss({
	marginBlockStart: 'space.250',
});

const readViewContainerStyles = xcss({
	paddingBlock: 'space.050',
	paddingInline: 'space.075',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: '20px',
});

const stackStyles = xcss({
	width: '290px',
	padding: 'space.150',
});

const barStyles = xcss({
	height: '6px',
	borderRadius: 'border.radius.100',
});

const progressBarStyles = xcss({
	width: '100%',
	backgroundColor: 'color.background.neutral',
});

const allocatedBarStyles = xcss({
	backgroundColor: 'color.background.neutral.bold',
});

const doneBarStyles = xcss({
	backgroundColor: 'color.background.success.bold',
});

const overloadedBarStyles = xcss({
	backgroundColor: 'color.background.danger.bold',
});

const dateContainerStyles = xcss({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: 1,
});
