import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import values from 'lodash/values';
import { AnalyticsSubject } from '@atlassian/jira-analytics-web-react/src/components/decorators.tsx';
import { UNSCHEDULED_COLUMN_ID } from '@atlassian/jira-common-constants/src/column-types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import BoardKitInlineCardCreate from '@atlassian/jira-platform-board-kit/src/ui/inline-card-create/index.tsx';
import type { StatusTransition } from '@atlassian/jira-platform-inline-card-create/src/index.tsx';
import { useIccContext } from '@atlassian/jira-platform-inline-card-create/src/services/context/index.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import {
	useKeepIccOpenActions,
	useKeepIccOpenState,
	useTriggerEventState,
} from '@atlassian/jira-software-onboarding-keep-icc-open/src/controllers/index.tsx';
import { CardPopper } from '@atlassian/jira-portfolio-3-plan-increment-common/src/ui/card-popper/index.tsx';
import { JSW_BOARD_SOURCE } from '@atlassian/jira-issue-type-config-common/src/types.tsx';
import { CAN_EDIT_BOARD } from '../../../../../model/permission/permission-types.tsx';
import type { IssueProject } from '../../../../../model/software/software-types.tsx';
import {
	setIssueIdWithAboveICCOpen,
	clearSpecifiedIssueIdWithAboveICCOpen,
	closeIccInUnscheduledColumn,
	openIccInUnscheduledColumn,
} from '../../../../../state/actions/card/index.tsx';
import {
	clearInlineCreateSessionId,
	clearSpecifiedInlineCreateSessionId,
	setInlineCreateSessionId,
} from '../../../../../state/actions/inline-create/index.tsx';
import { useBoardDispatch, useBoardSelector } from '../../../../../state/index.tsx';
import { getPermissionsSelector } from '../../../../../state/selectors/board/board-permissions-selectors.tsx';
import {
	isBoardEmptySelector,
	shouldAutoOpenIccAndShowCreateButton,
	shouldShowIssueTypeConfigOptions,
} from '../../../../../state/selectors/board/board-selectors.tsx';
import {
	getInitialTransitionsForColumn,
	makeGetStatusTransitionsForType,
	makeGetStatusTransitionsForTypeOld,
	manageIssueTypesLinkSelector,
	getIssueProjectsForIssueCreation,
} from '../../../../../state/selectors/card-create/card-create-selectors.tsx';
import {
	hasICCOpen,
	keepICCOpenInColumn,
	getColumnIdToKeepICCOpen,
	getLastOpenedColumnId,
	isUnscheduledColumnICCOpen,
} from '../../../../../state/selectors/card/card-selectors.tsx';
import { getColumnIndex } from '../../../../../state/selectors/column/column-selectors.tsx';
import { defaultIssueTypeIdSelector } from '../../../../../state/selectors/issue-type/issue-type-selectors.tsx';
import { getIsIncrementPlanningBoard } from '../../../../../state/selectors/software/software-selectors.tsx';
import {
	filtersForInlineCardCreateCountSelector,
	hasCustomFiltersSelector,
} from '../../../../../state/selectors/work/work-selectors.tsx';
import type { VisibleIccProps } from './types.tsx';
import { useOnCreate, useShouldRenderIccInPopper } from './utils/index.tsx';

const EMPTY_ARRAY: StatusTransition[] = [];
const EMPTY_PROJECTS_ARRAY: IssueProject[] = [];

const VisibleIccComponent = ({
	columnId,
	cardTypes,
	cardIndex,
	insertBefore,
	isLastSwimlane,
	projectId,
	swimlaneId,
	groupParentId,
	mappedCardTypes,
	onChangeFormVisible,
	showOnHover,
	triggerAppearance,
	triggerAriaLabel,
	renderAdditionalTypeSelectOptionContent,
	isFooter,
}: VisibleIccProps) => {
	const [isFormVisible, setIsFormVisible] = useState(false);
	const sessionIdRef = useRef<string | undefined>();

	const { initialCardTypeId } = useIccContext();

	const keepIccOpen = useKeepIccOpenState();

	const { triggerReason } = useTriggerEventState();

	const { setTriggerReason } = useKeepIccOpenActions();

	const dispatch = useBoardDispatch();

	const hasCustomFilters = useBoardSelector(hasCustomFiltersSelector);

	const appliedFilterCount = useBoardSelector((state) =>
		hasCustomFilters ? 0 : filtersForInlineCardCreateCountSelector(state),
	);
	const isIPBoard = useBoardSelector(getIsIncrementPlanningBoard);

	const canEditBoard = useBoardSelector((state) => getPermissionsSelector(state)[CAN_EDIT_BOARD]);

	const manageIssueTypesLink = useBoardSelector((state) =>
		canEditBoard ? manageIssueTypesLinkSelector(state) : null,
	);
	const getStatusTransitions = useBoardSelector(
		fg('reduce_board_icc_render_times')
			? makeGetStatusTransitionsForType
			: makeGetStatusTransitionsForTypeOld,
	);

	const defaultIssueTypeId = useBoardSelector(defaultIssueTypeIdSelector);

	const isUnscheduledColumn = isIPBoard && columnId === UNSCHEDULED_COLUMN_ID;

	const shouldStayOpen = useBoardSelector((state) => {
		if (isUnscheduledColumn) {
			return !!isFooter && isUnscheduledColumnICCOpen(state, swimlaneId, columnId);
		}

		if (shouldAutoOpenIccAndShowCreateButton(state)) {
			if (
				isBoardEmptySelector(state) &&
				getColumnIndex(state, columnId) === 0 &&
				getColumnIdToKeepICCOpen(state) === null &&
				getLastOpenedColumnId(state) === null
			) {
				if (triggerReason !== 'emptyBoard') {
					setTriggerReason('emptyBoard');
				}

				dispatch(setIssueIdWithAboveICCOpen(null, columnId));
				return true;
			}

			// This is an existing code path and is not part of the
			// changes in the variation cohort, so no checks needed
			if (insertBefore) {
				return hasICCOpen(state, insertBefore);
			}

			return keepICCOpenInColumn(state, columnId);
		}

		return hasICCOpen(state, insertBefore);
	});

	const projects = useBoardSelector((state) =>
		isIPBoard ? values(getIssueProjectsForIssueCreation(state)) : EMPTY_PROJECTS_ARRAY,
	);

	const issueTypeTransitions = useBoardSelector((state) =>
		!isIPBoard ? getInitialTransitionsForColumn(state)(columnId) : undefined,
	);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onAutoOpen = useCallback(
		(sessionId: string) => {
			if (isIPBoard && fg('render_program_board_card_in_popup')) {
				sessionIdRef.current = sessionId;
			}
			// start event for auto open icc
			const startAutoOpenICCAnalyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			});

			// firing start event for icc that is auto open - same as onTriggerClickCallback
			// @ts-expect-error Second argument should be of format "actionSubject action"
			fireUIAnalytics(startAutoOpenICCAnalyticsEvent, 'triggerClicked', 'triggerButton', {
				formWasOpen: isFormVisible,
				triggerAppearance,
				sessionId,
				isAutoOpen: true,
			});
			dispatch(setInlineCreateSessionId(sessionId));
		},
		[createAnalyticsEvent, dispatch, isFormVisible, isIPBoard, triggerAppearance],
	);

	const onTriggerClick = useCallback(
		(sessionId?: string) => {
			setIsFormVisible(true);
			if (isIPBoard && fg('render_program_board_card_in_popup')) {
				sessionIdRef.current = sessionId;
			}

			if (isIPBoard) {
				// When other create button is clicked, close ICC in unscheduled column
				dispatch(closeIccInUnscheduledColumn());

				if (isUnscheduledColumn && isFooter && swimlaneId) {
					// If the button clicked in unscheduled column and footer, sync the swimlane ID to the state
					// This is so the create button in the header is disabled
					dispatch(openIccInUnscheduledColumn(swimlaneId));
				}
			}
			dispatch(setInlineCreateSessionId(sessionId));
			dispatch(setIssueIdWithAboveICCOpen(insertBefore, columnId));
		},

		[dispatch, insertBefore, columnId, swimlaneId, isIPBoard, isUnscheduledColumn, isFooter],
	);

	const onCancelOrBlur = useCallback(() => {
		setIsFormVisible(false);
		if (isIPBoard && fg('render_program_board_card_in_popup')) {
			sessionIdRef.current = undefined;
		}

		if (isUnscheduledColumn) {
			dispatch(closeIccInUnscheduledColumn());
		}
		dispatch(clearInlineCreateSessionId());
		dispatch(setIssueIdWithAboveICCOpen(null, null));
	}, [dispatch, isUnscheduledColumn, isIPBoard]);

	const onCreate = useOnCreate({
		columnId,
		cardTypes,
		cardIndex,
		insertBefore,
		isLastSwimlane,
		projectId,
		swimlaneId,
		groupParentId,
		isUnscheduledColumn,
	});

	const statusTransitions = useMemo(() => {
		if ((shouldStayOpen || isFormVisible) && !isIPBoard) {
			return getStatusTransitions(columnId, issueTypeTransitions, initialCardTypeId);
		}

		return EMPTY_ARRAY;
	}, [
		columnId,
		getStatusTransitions,
		initialCardTypeId,
		isFormVisible,
		isIPBoard,
		issueTypeTransitions,
		shouldStayOpen,
	]);

	const shouldShowCreateButton = useBoardSelector((state) =>
		shouldAutoOpenIccAndShowCreateButton(state) ? projects.length === 0 : false,
	);

	useEffect(() => {
		if (isUnscheduledColumn) {
			setIsFormVisible(shouldStayOpen);
		} else if (shouldStayOpen) {
			// Checking if it's undefined here because we should still be setting it to
			// false if keepIccOpen is false
			setIsFormVisible(keepIccOpen);
		} else {
			setIsFormVisible(shouldStayOpen);
		}
	}, [keepIccOpen, shouldStayOpen, isUnscheduledColumn]);

	const showIssueTypeConfigOptions = useBoardSelector((state) =>
		shouldShowIssueTypeConfigOptions(state),
	);

	useEffect(() => {
		return () => {
			// Clean up state and session ID when component unmounts prior cancel or blur (eg. when board is scrolled)
			if (isIPBoard && sessionIdRef.current && fg('render_program_board_card_in_popup')) {
				setIsFormVisible(false);
				dispatch(clearSpecifiedInlineCreateSessionId(sessionIdRef.current));
				dispatch(clearSpecifiedIssueIdWithAboveICCOpen(insertBefore, columnId));
			}
		};
	}, [isIPBoard, dispatch, insertBefore, columnId]);

	const icc = (
		<BoardKitInlineCardCreate
			defaultCardTypeId={defaultIssueTypeId}
			cardTypes={mappedCardTypes}
			// appliedFilterCount is used to show the info dialog with the X filters applied message
			// hide it if we have custom filters active as it'll be misleading
			appliedFilterCount={appliedFilterCount}
			manageIssueTypesLink={manageIssueTypesLink}
			shouldStayOpen={shouldStayOpen}
			isIPBoard={isIPBoard}
			statusTransitions={statusTransitions}
			isFormVisible={isFormVisible}
			// TODO-BOARD-REFACTOR-TYPES
			// @ts-expect-error inconsistent types between IssueProject and Project
			projects={projects}
			onCreate={onCreate}
			onTriggerClick={onTriggerClick}
			onCancel={onCancelOrBlur}
			onBlur={onCancelOrBlur}
			onChangeFormVisible={onChangeFormVisible}
			renderAdditionalTypeSelectOptionContent={renderAdditionalTypeSelectOptionContent}
			showOnHover={showOnHover}
			triggerAppearance={triggerAppearance}
			{...(fg('jfp-a11y-team_board_identical-create-issue-button') && { triggerAriaLabel })}
			shouldShowCreateButton={shouldShowCreateButton}
			{...(isUnscheduledColumn && { shouldScrollIntoView: true })}
			onAutoOpen={onAutoOpen}
			showIssueTypeConfigOptions={showIssueTypeConfigOptions}
			configSource={JSW_BOARD_SOURCE}
		/>
	);
	const shouldRenderPopper = useShouldRenderIccInPopper({
		columnId,
		isFormVisible,
	});
	return isIPBoard && fg('render_program_board_card_in_popup') ? (
		<CardPopper shouldRenderPopper={shouldRenderPopper} cardHeight={100}>
			{icc}
		</CardPopper>
	) : (
		icc
	);
};

export const VisibleIcc = AnalyticsSubject('issue')(VisibleIccComponent);
