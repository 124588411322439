import { gqlTagSwag } from '@atlassian/jira-software-swag/src/index.tsx';
import { mutation$ } from '@atlassian/jira-software-swag/src/services/mutation/index.tsx';

export const updateOldDoneIssuesCutoffOperationName = 'UpdateOldDoneIssuesCutoff';

export const updateOldDoneIssuesCutoffMutation = gqlTagSwag`mutation UpdateOldDoneIssuesCutoff($boardId: ID!, $oldDoneIssueCutoff: String!){
		setOldDoneIssuesCutoff(input: {boardId: $boardId, oldDoneIssuesCutoff: $oldDoneIssueCutoff}) {
			boardId,
			oldDoneIssuesCutoff
		}
}
`;

const oldDoneIssuesCutoffMutation = (
	contextPath: string,
	boardId: string,
	oldDoneIssueCutoff: string,
) =>
	mutation$(
		contextPath,
		updateOldDoneIssuesCutoffOperationName,
		updateOldDoneIssuesCutoffMutation,
		{
			boardId,
			oldDoneIssueCutoff,
		},
	);

export default oldDoneIssuesCutoffMutation;
