import { useEffect } from 'react';
import { useRouter, useRouterActions } from '@atlassian/react-resource-router';
import { View } from '../../../common/types/constant.tsx';
import type { Issue } from '../../../common/types/state.tsx';
import {
	setIncrementPlanningSettingsQuery,
	parseSearchQueryForIPBoard,
} from '../../../common/utils/index.tsx';
import {
	getShowUnscheduledColumn as getShowUnscheduledColumnFromLocalStorage,
	getShowOfftrackDependencyLines as getShowOfftrackDependencyLinesFromLocalStorage,
	getIssueIdsToShowDependencies as getIssueIdsToShowDependenciesFromLocalStorage,
} from '../../../services/local-storage/index.tsx';
import {
	useViewSettingsActions,
	useShowUnscheduledColumn,
	useShowOfftrackDependencyLines,
	useIssueIdsToShowDependencies,
} from '../../index.tsx';

export const useOnIncrementPlanningBoard = (
	boardId: number,
	view: (typeof View)[keyof typeof View],
	dependencyIssueOptions: Record<string, Issue>,
) => {
	const {
		setShowUnscheduledColumn,
		setShowOfftrackDependencyLines,
		setIssueIdsToShowDependencies,
	} = useViewSettingsActions();
	const [{ location }] = useRouter();
	const { replace } = useRouterActions();
	const isUnscheduledColumnVisible = useShowUnscheduledColumn();
	const isOfftrackDependencyLinesVisible = useShowOfftrackDependencyLines();
	const issueIdsToShowDependencies = useIssueIdsToShowDependencies();

	useEffect(() => {
		if (view !== View.INCREMENT_PLANNING_BOARD) {
			return;
		}
		const unscheduledColumnFromLocalStorage = getShowUnscheduledColumnFromLocalStorage(boardId);
		const settingsFromQuery = parseSearchQueryForIPBoard(location.search);

		// Search query state takes precedence over localStorage state, which supersedes react-sweet-state state.
		// If there are IP board settings from the search query, update localStorage and react-sweet-state.
		// If not, update react-sweet-state using localStorage instead.
		const showUnscheduledColumn =
			settingsFromQuery.SHOW_UNSCHEDULED_COLUMN !== undefined
				? settingsFromQuery.SHOW_UNSCHEDULED_COLUMN
				: unscheduledColumnFromLocalStorage;

		setShowUnscheduledColumn(boardId, showUnscheduledColumn);

		const offtrackDependencyLinesFromLocalStorage =
			getShowOfftrackDependencyLinesFromLocalStorage(boardId);
		const issueIdsToShowDependenciesFromLocalStorage =
			getIssueIdsToShowDependenciesFromLocalStorage(boardId);

		const showOfftrackDependencyLines =
			settingsFromQuery.SHOW_OFFTRACK_DEPENDENCY_LINES !== undefined
				? settingsFromQuery.SHOW_OFFTRACK_DEPENDENCY_LINES
				: offtrackDependencyLinesFromLocalStorage;

		setShowOfftrackDependencyLines(boardId, showOfftrackDependencyLines);

		const issuesIdsToShowDependencies =
			settingsFromQuery.ISSUE_IDS_TO_SHOW_DEPENDENCIES &&
			settingsFromQuery.ISSUE_IDS_TO_SHOW_DEPENDENCIES.length
				? settingsFromQuery.ISSUE_IDS_TO_SHOW_DEPENDENCIES
				: issueIdsToShowDependenciesFromLocalStorage;

		const filteredIssueIds = Array.isArray(issuesIdsToShowDependencies)
			? issuesIdsToShowDependencies.filter(
					(issueId) => dependencyIssueOptions[issueId] !== undefined,
				)
			: [];

		setIssueIdsToShowDependencies(boardId, filteredIssueIds);
	}, [
		boardId,
		view,
		dependencyIssueOptions,
		location.search,
		setShowUnscheduledColumn,
		setShowOfftrackDependencyLines,
		setIssueIdsToShowDependencies,
	]);

	useEffect(() => {
		if (view !== View.INCREMENT_PLANNING_BOARD) {
			return;
		}
		const url = setIncrementPlanningSettingsQuery(
			{
				SHOW_UNSCHEDULED_COLUMN: isUnscheduledColumnVisible,
				SHOW_OFFTRACK_DEPENDENCY_LINES: isOfftrackDependencyLinesVisible,
				ISSUE_IDS_TO_SHOW_DEPENDENCIES: issueIdsToShowDependencies,
			},
			location,
		);

		if (location.search !== `?${url.search}`) {
			replace(url);
		}
		// `Location` has a `hash` attribute which causes unnecessary calls, so we are only using `search` in the dependency array.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		view,
		isUnscheduledColumnVisible,
		isOfftrackDependencyLinesVisible,
		issueIdsToShowDependencies,
		location.search,
		replace,
	]);
};
