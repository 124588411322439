import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { Link } from '@atlassian/react-resource-router';
import { useBacklogUrl } from '../../../../../common/hooks/use-backlog-url/index.tsx';
import agileImage from './assets/agile.svg';
import messages from './messages.tsx';

const NoActiveSprintMessage = () => {
	const intl = useIntl();
	const backlogUrl = useBacklogUrl() || '';
	return (
		<Container>
			<Image src={agileImage} alt="" />
			<TitleStyle>{intl.formatMessage(messages.title)}</TitleStyle>
			<TextStyle>
				{intl.formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.descriptionIssueTermRefresh
						: messages.description,
				)}
			</TextStyle>
			<Button component={Link} href={backlogUrl}>
				{intl.formatMessage(messages.button)}
			</Button>
		</Container>
	);
};

export default NoActiveSprintMessage;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	paddingTop: token('space.400'),
	paddingRight: token('space.400'),
	paddingBottom: token('space.400'),
	paddingLeft: token('space.400'),
	display: 'flex',
	alignItems: 'center',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Image = styled.img({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 16}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 16}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TextStyle = styled.div({
	textAlign: 'center',
	marginBottom: token('space.300'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitleStyle = styled(TextStyle)({
	fontWeight: token('font.weight.bold'),
	marginBottom: token('space.100'),
});
