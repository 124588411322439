import React from 'react';
import { ButtonItem } from '@atlaskit/menu';
import { FormattedMessage } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

export type Props = {
	isMediaEnabled: boolean;
	isDisabled: boolean;
	onClick: () => void;
};

type MenuItemProps = Partial<
	Flow.Diff<
		Props,
		{
			isMediaEnabled: boolean;
		}
	>
>;

const MediaCardEnableItem = (props: MenuItemProps) => {
	return (
		<ButtonItem key="card-media-enable" isDisabled={props.isDisabled} onClick={props.onClick}>
			<FormattedMessage {...messages.showCover} />
		</ButtonItem>
	);
};

const MediaCardDisableItem = (props: MenuItemProps) => {
	return (
		<ButtonItem key="card-media-disable" isDisabled={props.isDisabled} onClick={props.onClick}>
			<FormattedMessage {...messages.hideCover} />
		</ButtonItem>
	);
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: Props) => {
	const { isMediaEnabled, ...otherProps } = props;
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const mediaCardProps = {
		...otherProps,
		onClick: () => {
			props.onClick();
			const event = createAnalyticsEvent({
				action: 'toggle',
				actionSubject: 'cardCoverImageVisibilityToggleMenuItem',
			});
			fireUIAnalytics(event, { isEnabled: !isMediaEnabled });
		},
	};

	return isMediaEnabled
		? MediaCardDisableItem(mediaCardProps)
		: MediaCardEnableItem(mediaCardProps);
};
