import React, {
	useState,
	useCallback,
	createContext,
	useContext,
	type SyntheticEvent,
} from 'react';
import { styled } from '@compiled/react';
import { IconButton } from '@atlaskit/button/new';
import LinkIcon from '@atlaskit/icon/core/link';
import LinkFilledIconOld from '@atlaskit/icon/glyph/link-filled';
import type { IconProps } from '@atlaskit/icon/types';
import Popup, { type TriggerProps } from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { useBoardSelector, useBoardDispatch } from '../../../../../../state/index.tsx';
import { getIssueIdsToShowDependencies } from '../../../../../../state/selectors/issue-link/issue-link-selectors.tsx';
import { getIssueById } from '../../../../../../state/selectors/issue/issue-selectors.tsx';
import { setInlineEditing } from '../../../../../../state/actions/card/index.tsx';
import Flyout from './flyout/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const DependencyIndicatorContext = createContext({
	isOfftrack: false,
	isSelected: false,
});

const stopPropagation = (e: SyntheticEvent<HTMLElement>) => e.stopPropagation();

const DependencyIndicatorIcon = (props: IconProps) => {
	const { formatMessage } = useIntl();
	const { isOfftrack, isSelected } = useContext(DependencyIndicatorContext);
	const message = formatMessage(messages.viewDependencyDetails);
	if (!isVisualRefreshEnabled()) {
		const { primaryColor, secondaryColor } = (() => {
			if (isSelected) {
				return {
					primaryColor: token('color.icon.selected'),
					secondaryColor: token('color.icon.inverse'),
				};
			}
			if (isOfftrack) {
				return {
					primaryColor: token('color.background.accent.red.subtlest'),
					secondaryColor: token('color.icon.danger'),
				};
			}
			return {
				primaryColor: token('color.background.neutral'),
				secondaryColor: token('color.text'),
			};
		})();
		return (
			<LinkIcon
				{...props}
				label={message}
				color={token('color.icon')}
				LEGACY_fallbackIcon={LinkFilledIconOld}
				LEGACY_size="medium"
				LEGACY_primaryColor={primaryColor}
				LEGACY_secondaryColor={secondaryColor}
			/>
		);
	}

	const iconColor = (() => {
		if (isSelected) {
			// Setting the color to undefined will reset to the default behaviour to automatically adjust based on the background color
			return undefined;
		}
		if (isOfftrack) {
			return token('color.icon.danger');
		}
		return token('color.icon');
	})();
	return <LinkIcon {...props} label={message} color={iconColor} />;
};

export const IssueLinksIndicator = (props: Props) => {
	const { formatMessage } = useIntl();
	const [popupOpen, setPopupOpen] = useState(false);
	const dispatch = useBoardDispatch();
	const isOfftrack = useBoardSelector((state) => {
		const issueLinks = getIssueById(state, props.issueId)?.issueLinks || [];
		let flag = false;
		for (let i = 0; i < issueLinks.length; i++) {
			if (issueLinks[i].isOfftrack) {
				flag = true;
				break;
			}
		}
		return flag;
	});
	const isSelected = useBoardSelector((state) =>
		getIssueIdsToShowDependencies(state).includes(`${props.issueId}`),
	);

	const flyoutContent = useCallback(
		() => <Flyout issueKey={props.issueKey} issueId={props.issueId} />,
		[props.issueKey, props.issueId],
	);

	const onIndicatorBtnClick = useCallback(() => {
		setPopupOpen(!popupOpen);

		if (fg('render_program_board_card_in_popup')) {
			dispatch(setInlineEditing('dependencies', props.issueId, true));
		}
	}, [popupOpen, setPopupOpen, dispatch, props.issueId]);

	const onPopUpClose = useCallback(() => {
		setPopupOpen(false);

		if (fg('render_program_board_card_in_popup')) {
			dispatch(setInlineEditing('dependencies', props.issueId, false));
		}
	}, [setPopupOpen, dispatch, props.issueId]);

	const trigger = useCallback(
		(triggerProps: TriggerProps) => {
			const message = formatMessage(messages.viewDependencyDetails);
			return (
				<DependencyIndicatorContext.Provider value={{ isOfftrack, isSelected }}>
					<Box onClick={stopPropagation} onKeyDown={stopPropagation}>
						<Tooltip content={message}>
							<IconButton
								{...triggerProps}
								appearance={isSelected && isVisualRefreshEnabled() ? 'primary' : 'subtle'}
								spacing="compact"
								shape="circle"
								onClick={onIndicatorBtnClick}
								isSelected={popupOpen}
								label={message}
								icon={DependencyIndicatorIcon}
								testId="software-board.board-container.board.card-container.card.issue-links-indicator.icon-button"
							/>
						</Tooltip>
					</Box>
				</DependencyIndicatorContext.Provider>
			);
		},
		[isOfftrack, isSelected, onIndicatorBtnClick, popupOpen, formatMessage],
	);
	return (
		<Container>
			<Popup
				isOpen={popupOpen}
				content={flyoutContent}
				trigger={trigger}
				onClose={onPopUpClose}
				shouldUseCaptureOnOutsideClick
			/>
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});

export default IssueLinksIndicator;
