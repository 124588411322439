import React from 'react';
import { ButtonItem } from '@atlaskit/menu';
import createBoardLegacy from '@atlassian/jira-create-board-legacy/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export type CreateBoardMenuItemProps = {
	onClosePopup?: () => void;
};

const CreateBoardMenuItem = ({ onClosePopup }: CreateBoardMenuItemProps) => {
	const { formatMessage } = useIntl();
	const onClickCreateBoard = () => {
		createBoardLegacy();
		onClosePopup && onClosePopup();
	};

	return (
		<ButtonItem
			data-testid="software-board.header.menu.create-board.button-item"
			onClick={onClickCreateBoard}
		>
			{formatMessage(messages.displayText)}
		</ButtonItem>
	);
};

export default CreateBoardMenuItem;
