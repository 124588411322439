import { useCallback, useEffect, useRef } from 'react';
import noop from 'lodash/noop';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createHook,
	createStore,
	createContainer,
	type StoreActionApi,
} from '@atlassian/react-sweet-state';
import type { State } from './types.tsx';

const initialState: State = {
	selectedIssuesKeys: [],
	crossProjectSelection: false,
};

const actions = {
	setSelectionDetails:
		(state: State) =>
		({ setState }: StoreActionApi<State>) =>
			setState(state),
} as const;

const Store = createStore({
	initialState,
	actions,
	name: 'automation-menu.selected-issues',
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SelectedIssuesContainer = createContainer<State, typeof actions, Record<any, any>>(
	Store,
);

const isCrossProjectScope = (selectedIssueKeys: string[], projectKey: string): boolean =>
	selectedIssueKeys.find((issueKey: string) => !issueKey.startsWith(`${projectKey}-`)) !==
	undefined;

export const useSelectedIssues = createHook(Store);

const useSelectedIssuesSubscriptionClassic = (
	initialIssuesSelection: string[] = [],
	projectKey: string,
): string[] => {
	const [state, { setSelectionDetails }] = useSelectedIssues();
	useEffect(
		() =>
			setSelectionDetails({
				selectedIssuesKeys: initialIssuesSelection,
				crossProjectSelection: isCrossProjectScope(initialIssuesSelection, projectKey),
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[setSelectionDetails, projectKey],
	);

	const onSelectedIssuesChanged = useCallback(
		// make flow happy
		(event: unknown) => {
			if (event instanceof CustomEvent) {
				setSelectionDetails({
					selectedIssuesKeys: event.detail.selectedIssues,
					crossProjectSelection: isCrossProjectScope(event.detail.selectedIssues, projectKey),
				});
			}
		},
		[setSelectionDetails, projectKey],
	);

	const subscriptionHandle = useRef<() => void>(noop);

	useEffect(() => {
		if (typeof document !== 'undefined') {
			document.addEventListener('JiraAgile.selectedIssuesChanged', onSelectedIssuesChanged);
			subscriptionHandle.current = () =>
				document.removeEventListener('JiraAgile.selectedIssuesChanged', onSelectedIssuesChanged);
		}

		return () => {
			subscriptionHandle.current();
			subscriptionHandle.current = noop;
		};
	}, [onSelectedIssuesChanged]);

	return state.selectedIssuesKeys;
};

const useSelectedIssuesSubscriptionNextGen = (
	selectedIssueKeys: string[] = [],
	projectKey: string,
): string[] => {
	const [state, { setSelectionDetails }] = useSelectedIssues();
	useEffect(
		() =>
			setSelectionDetails({
				selectedIssuesKeys: selectedIssueKeys,
				crossProjectSelection: isCrossProjectScope(selectedIssueKeys, projectKey),
			}),
		[setSelectionDetails, selectedIssueKeys, projectKey],
	);

	return state.selectedIssuesKeys;
};

export const useSelectedIssuesSubscription = (
	isSimplified: boolean,
	initialIssuesSelection: string[] = [],
	selectedIssueKeys: string[] = [],
	projectKey: string,
): string[] =>
	isSimplified
		? useSelectedIssuesSubscriptionNextGen(selectedIssueKeys, projectKey) // eslint-disable-line react-hooks/rules-of-hooks
		: useSelectedIssuesSubscriptionClassic(initialIssuesSelection, projectKey); // eslint-disable-line react-hooks/rules-of-hooks
