import { useCallback, useMemo } from 'react';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueLinkedModalEntrypointActions } from '@atlassian/jira-issue-links-common/src/controllers/issue-links-modal-entrypoint-store/index.tsx';
import type { NewIssueLinksType } from '@atlassian/jira-issue-view-content-issue-links/src/add/types.tsx';
import { linkIssueEntryPoint } from '@atlassian/jira-link-issue-content/entrypoint.tsx';
import { wrapActionWithPromise } from '../../../../../../../../common/utils/increment-planning/index.tsx';
import type { Issue } from '../../../../../../../../model/issue/issue-types.tsx';
import type { CardType } from '../../../../../../../../model/software/software-types.tsx';
import { issueLinkCreateRequest } from '../../../../../../../../state/actions/issue/issue-link/index.tsx';
import { useBoardSelector, useBoardDispatch } from '../../../../../../../../state/index.tsx';
import { getIssueLinksForDependenciesModal } from '../../../../../../../../state/selectors/issue-link/issue-link-selectors.tsx';
import { getIssueLinksPermissions } from '../../../../../../../../state/selectors/issue-links-permissions/issue-links-permissions-selectors.tsx';
import {
	getIssueById,
	getIssueTypeById,
} from '../../../../../../../../state/selectors/issue/issue-selectors.tsx';
import {
	getIssueLinkTypes,
	getIssues,
} from '../../../../../../../../state/selectors/software/software-selectors.tsx';
import { INWARD_LINK_DIRECTION } from '../../../../../../../modals/link-issue-modal/constants.tsx';
import type { IssueLinkType } from '../../../../../../../modals/link-issue-modal/types.tsx';
import messages from './messages.tsx';
import type { Props, IPBoardProps } from './types.tsx';
import { useLinkContent } from './view.tsx';

export const useLinkIssue = (props: Props) => {
	const { canUserLinkIssue, issueLinkTypes } = useBoardSelector((state) =>
		getIssueLinksPermissions(state),
	);
	const { issue, issueType } = useBoardSelector(
		(state): { issue: Issue | undefined; issueType: CardType | undefined } => {
			const issueItem = getIssueById(state, props.issueId);
			if (!issueItem) {
				return { issue: undefined, issueType: undefined };
			}
			return {
				issue: issueItem,
				issueType: getIssueTypeById(state, issueItem.typeId),
			};
		},
	);
	const { entryPointActions } = useEntryPoint(linkIssueEntryPoint, {});
	const onMouseEnter = entryPointActions.preload;
	const { setIssueLinkModalEntryPointSubject } = useIssueLinkedModalEntrypointActions();
	const onClick = () => {
		setIssueLinkModalEntryPointSubject({
			issueData: {
				issueId: String(issue?.id),
				issueKey: issue?.key,
				issueSummary: issue?.summary,
				issueType,
				returnFocusRef: fg('jfp_a11y_fix_jsw_board') ? props.returnFocusRef : undefined,
			},
			trackOriginSource: 'fromJSWBoardCardContextMenu',
		});
	};

	const menuNodes = useLinkContent({
		onClick,
		onMouseEnter,
		canUserLinkIssue,
		hasIssueLinkTypes: issueLinkTypes.length > 0,
		issueId: fg('one_event_improvements_1') ? issue?.id : undefined,
	});

	// if issue or issue type are undefined the menu nodes may have issues so we return an empty list instead
	if (!issue || !issueType) {
		return [];
	}

	return menuNodes;
};

export const useLinkIssueIPBoard = (props: IPBoardProps) => {
	const issue = useBoardSelector((state) => getIssueById(state, props.issueId));
	const issueType = useBoardSelector((state) => getIssueTypeById(state, issue.typeId));
	const issues = useBoardSelector((state) => getIssues(state));
	const issuesArray = useMemo(() => Object.values(issues), [issues]);
	const { formatMessage } = useIntl();
	const title = formatMessage(messages.addDependencyKey);
	const confirmLabel = formatMessage(messages.addDependencyCta);
	const issueLinkTypes = useBoardSelector((state) => getIssueLinkTypes(state));
	const dispatch = useBoardDispatch();
	const issueLinkAndDirectionMap = useBoardSelector((state) =>
		getIssueLinksForDependenciesModal(state)(props.issueId),
	);

	const { setIssueLinkModalEntryPointSubject } = useIssueLinkedModalEntrypointActions();

	const onLinkIssueRequest = useCallback(
		async (
			issueId: string | number,
			newIssueLinks: NewIssueLinksType[],
			issueLinkType: IssueLinkType,
		) => {
			const isInwards = issueLinkType.direction === INWARD_LINK_DIRECTION;

			return Promise.all(
				newIssueLinks.map(({ value }) =>
					wrapActionWithPromise(
						dispatch,
						issueLinkCreateRequest({
							sourceItemKey: `${isInwards ? value : issueId}`,
							targetItemKey: `${isInwards ? issueId : value}`,
							type: Number(issueLinkType.id),
						}),
					),
				),
			);
		},
		[dispatch],
	);

	const onClick = useCallback(() => {
		setIssueLinkModalEntryPointSubject({
			issueData: {
				issueId: String(issue.id),
				issueKey: issue.key,
				issueSummary: issue.summary,
				issueType,
			},
			modalContent: {
				title,
				showDescription: false,
				showCreateIssueLinkButton: false,
				showFeedbackCollector: false,
				localIssuesOnly: true,
				confirmLabel,
				issues: issuesArray,
				onLinkIssueRequest,
				issueLinks: issueLinkAndDirectionMap,
				returnFocusRef: fg('increment_board_a11y_fix') ? props.returnFocusRef : undefined,
			},
		});
	}, [
		issue,
		title,
		confirmLabel,
		setIssueLinkModalEntryPointSubject,
		issuesArray,
		onLinkIssueRequest,
		issueType,
		issueLinkAndDirectionMap,
		props.returnFocusRef,
	]);

	const { entryPointActions } = useEntryPoint(linkIssueEntryPoint, {});
	const onMouseEnter = entryPointActions.preload;

	return useLinkContent({
		onClick,
		onMouseEnter,
		canUserLinkIssue: true,
		hasIssueLinkTypes: issueLinkTypes.length > 0,
		message: formatMessage(messages.addDependencyKey),
	});
};
