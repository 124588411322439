export const iterationLengthCountOptionsMap = {
	1: {
		min: 7,
		max: 13,
		default: 12,
	},
	2: {
		min: 5,
		max: 8,
		default: 6,
	},
	3: {
		min: 4,
		max: 6,
		default: 4,
	},
	4: {
		min: 3,
		max: 4,
		default: 3,
	},
};
