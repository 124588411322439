import PullRequestIcon from '@atlaskit/icon/core/migration/pull-request--bitbucket-pullrequests';
import CalendarIcon from '@atlaskit/icon/core/migration/calendar';
import IssueIcon from '@atlaskit/icon/core/migration/issue';
import UploadIcon from '@atlaskit/icon/core/migration/cloud-arrow-up--upload';
import PlayIcon from '@atlaskit/icon/core/migration/video-play--vid-play';
import { WebhookIcon } from './assets/webhook.tsx';
import messages from './messages.tsx';
import { filters, type MenuItem } from './types.tsx';

export const boardMenuItems: MenuItem[] = [
	{
		Icon: IssueIcon,
		title: messages.issueTitle,
		description: messages.issueDescription,
		filter: filters.CATEGORY_ISSUE,
	},
	{
		Icon: PullRequestIcon,
		title: messages.devopsTitle,
		description: messages.devopsDescription,
		filter: filters.CATEGORY_DEVOPS,
	},
	{
		Icon: UploadIcon,
		title: messages.devopsBuildAndDeployTitle,
		description: messages.devopsBuildAndDeployDescription,
		filter: filters.CATEGORY_DEVOPS,
	},
	{
		Icon: WebhookIcon,
		title: messages.integrationTitle,
		description: messages.integrationDescription,
		filter: filters.CATEGORY_INTEGRATION,
	},
	{
		Icon: CalendarIcon,
		title: messages.scheduleTitle,
		description: messages.scheduleDescription,
		filter: filters.CATEGORY_SCHEDULE,
	},
	{
		Icon: PlayIcon,
		title: messages.allTriggersTitle,
		description: messages.allTriggersDescription,
		filter: filters.CATEGORY_ALL,
	},
];
