import React, { useCallback, useRef, useState, type ComponentType } from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled, { keyframes } from 'styled-components';

import Button from '@atlaskit/button';
import { IconButton } from '@atlaskit/button/new';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import DropdownMenu, { type CustomTriggerProps, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/show-more-horizontal';
import MoreIcon from '@atlaskit/icon/core/migration/show-more-horizontal--more';
import { token } from '@atlaskit/tokens';

import { borderRadius } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import { useIsDismissed } from '@atlassian/jira-onboarding-quickstart-core/src/services/index.tsx';
import {
	DROPDOWN,
	FireUiAnalytics,
	FireScreenAnalytics,
	ContextualAnalyticsData,
} from '@atlassian/jira-product-analytics-bridge';
import { ConditionalNudgeWrapper } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/conditional-nudge-wrapper/index.tsx';
import { BoardInProgressColumnNudgeAsync } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/kanban-onboarding-tour/board-in-progress-column/async.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { useModalDialogActions } from '@atlassian/jira-software-modal-dialog/src/controllers/index.tsx';
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import ColumnClearAllMenuItem from './item-clear-all/index.tsx';
import ColumnDeleteMenuItem from './item-delete/index.tsx';
import ColumnLimitsMenuItem from './item-limits/index.tsx';
import ColumnMoveMenuItem from './item-move/index.tsx';
import { ColumnHeaderMenuGroup } from './menu-group/index.tsx';
import messages from './messages.tsx';

export type Props = {
	isDefaultOpen: boolean;
	isDisabled: boolean;
	columnId: number;
	externalValues: {
		columnIndex: number;
	};
	title: string;
	onVisibilityChange: (visible: boolean) => void;
	onOpenChange: (attrs: { isOpen: boolean }) => void;
	intl: IntlShape;
};

export const ColumnMenu = (props: Props) => {
	const {
		columnId,
		externalValues,
		title,
		onOpenChange,
		onVisibilityChange,
		intl: { formatMessage },
		isDisabled = false,
		isDefaultOpen = false,
	} = props;
	const [isOpen, setIsOpen] = useState(isDefaultOpen);
	const [isOnboardingDismissed] = useIsDismissed();
	const { setReturnFocusTo } = useModalDialogActions();
	const moreActionsButtonRef = useRef<HTMLElement>(null);
	const handleOpenChange = (attrs: { isOpen: boolean }) => {
		setIsOpen(attrs.isOpen);
		onOpenChange(attrs);
	};

	const onClose = useCallback(() => {
		setIsOpen(false);
		onOpenChange({ isOpen: false });
	}, [onOpenChange]);

	const handleClick = useCallback(() => {
		setIsOpen(false);
		onOpenChange({ isOpen: false });
	}, [onOpenChange]);

	const toggleMenu = (open: boolean) => () => {
		if (onVisibilityChange) {
			onVisibilityChange(open);
			setIsOpen(open);
		}
	};

	const onTriggerClick = useCallback(() => {
		setIsOpen((open) => !open);
	}, []);

	const popupRenderTrigger = (triggerProps: TriggerProps) => {
		return isVisualRefreshEnabled() ? (
			<IconButton
				{...triggerProps}
				appearance="subtle"
				icon={ShowMoreHorizontalIcon}
				label={formatMessage(messages.dropdownMenuAriaLabel, {
					heading: title,
				})}
				testId="software-board.board-container.board.column.header.menu.column-menu-trigger"
				onClick={onTriggerClick}
			/>
		) : (
			<Button
				{...triggerProps}
				appearance="subtle"
				iconBefore={
					fg('enghealth-12479-jsw-board-visual-refresh') ? (
						<ShowMoreHorizontalIcon
							label="More actions"
							LEGACY_fallbackIcon={MoreIcon}
							color={token('color.icon')}
						/>
					) : (
						<MoreIcon spacing="spacious" label="More actions" />
					)
				}
				testId="software-board.board-container.board.column.header.menu.column-menu-trigger"
				onClick={onTriggerClick}
				aria-label={formatMessage(messages.dropdownMenuAriaLabelOld, {
					heading: title,
				})}
			/>
		);
	};

	const renderTrigger = ({ triggerRef, ...triggerProps }: CustomTriggerProps) => {
		const ref = fg('jfp_a11y_team_board_column_focus_return')
			? mergeRefs(moreActionsButtonRef, triggerRef)
			: triggerRef;

		return isVisualRefreshEnabled() ? (
			<IconButton
				{...triggerProps}
				ref={ref}
				appearance="subtle"
				icon={ShowMoreHorizontalIcon}
				testId="software-board.board-container.board.column.header.menu.column-menu-trigger"
				label={formatMessage(messages.dropdownMenuAriaLabel, {
					heading: title,
				})}
			/>
		) : (
			<Button
				{...triggerProps}
				appearance="subtle"
				iconBefore={
					fg('enghealth-12479-jsw-board-visual-refresh') ? (
						<ShowMoreHorizontalIcon
							label="More actions"
							LEGACY_fallbackIcon={MoreIcon}
							color={token('color.icon')}
						/>
					) : (
						<MoreIcon spacing="spacious" label="More actions" />
					)
				}
				testId="software-board.board-container.board.column.header.menu.column-menu-trigger"
				aria-label={formatMessage(messages.dropdownMenuAriaLabelOld, {
					heading: title,
				})}
				ref={ref}
			/>
		);
	};

	const handleMenuItemClick = useCallback(() => {
		// To get return focus on Delete modal cancel or dismiss
		fg('jfp_a11y_team_board_column_focus_return') && setReturnFocusTo(moreActionsButtonRef);
		handleClick();
	}, [handleClick, setReturnFocusTo]);

	const content = useCallback(
		() => (
			<>
				<ColumnHeaderMenuGroup
					columnId={columnId}
					isDisabled={isDisabled}
					onClick={handleClick}
					onMenuItemClick={handleMenuItemClick}
				/>
				<FireUiAnalytics action="toggled" actionSubject="dropdownMenu" />
				<FireScreenAnalytics />
			</>
		),
		[columnId, isDisabled, handleClick, handleMenuItemClick],
	);

	return (
		<MeatballMenuButtonBackground>
			<ContextualAnalyticsData
				sourceName="columnSettings"
				sourceType={DROPDOWN}
				attributes={{ columnId, columnIndex: externalValues.columnIndex }}
			>
				<ConditionalNudgeWrapper
					Wrapper={BoardInProgressColumnNudgeAsync}
					conditionsToApplyWrapper={!isOnboardingDismissed && externalValues.columnIndex === 2}
					onShow={toggleMenu(true)}
					onHide={toggleMenu(false)}
				>
					{fg('horizn_490_consolidate_old_issues_in_board') ? (
						<Popup
							isOpen={isOpen}
							onClose={onClose}
							placement="bottom-end"
							trigger={popupRenderTrigger}
							content={content}
						/>
					) : (
						<DropdownMenu
							isOpen={isOpen}
							onOpenChange={handleOpenChange}
							trigger={renderTrigger}
							placement="bottom-end"
						>
							<DropdownItemGroup>
								{fg('column_reordering_using_keyboard') && (
									<>
										<ColumnMoveMenuItem
											columnId={columnId}
											direction="left"
											isDisabled={isDisabled}
											onClick={handleClick}
										/>
										<ColumnMoveMenuItem
											columnId={columnId}
											direction="right"
											isDisabled={isDisabled}
											onClick={handleClick}
										/>
									</>
								)}
								<ColumnLimitsMenuItem
									columnId={columnId}
									isDisabled={isDisabled}
									onClick={handleMenuItemClick}
								/>
								<ColumnClearAllMenuItem
									columnId={columnId}
									isDisabled={isDisabled}
									onClick={handleClick}
								/>
								<ColumnDeleteMenuItem
									columnId={columnId}
									isDisabled={isDisabled}
									onClick={handleMenuItemClick}
								/>
							</DropdownItemGroup>
							<FireUiAnalytics action="toggled" actionSubject="dropdownMenu" />
							<FireScreenAnalytics />
						</DropdownMenu>
					)}
				</ConditionalNudgeWrapper>
			</ContextualAnalyticsData>
		</MeatballMenuButtonBackground>
	);
};

export const hideableColumnMenu =
	(Menu: ComponentType<Props>): ComponentType<Props> =>
	(props: Props) =>
		props.isDisabled ? null : <Menu {...props} />;

export const HideableMenu = hideableColumnMenu(ColumnMenu);

export default HideableMenu;

const MeatballBackgroundAnimation = keyframes({
	'0%': {
		opacity: 0,
	},
	'100%': {
		opacity: 1,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MeatballMenuButtonBackground = styled.div({
	animation: `${MeatballBackgroundAnimation} 0.2s ease forwards`,

	background: token('color.background.neutral'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius}px`,
});
