import React, { type KeyboardEvent, type MouseEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import Tooltip from '@atlaskit/tooltip';
import { ButtonItem } from '@atlaskit/menu';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import messages from './messages.tsx';

type Props = {
	isDisabled: boolean;
} & {
	numDoneCards: number;
	onClick: (event: MouseEvent | KeyboardEvent, analyticsEvent: UIAnalyticsEvent) => void;
};

export const View = ({ onClick, numDoneCards, isDisabled }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleOnClick = (event: MouseEvent | KeyboardEvent) => {
		onClick(event, createAnalyticsEvent({ actionSubject: 'dropdownMenuItem', action: 'clicked' }));
	};

	const menuItemClearAll = fg('horizn_490_consolidate_old_issues_in_board') ? (
		<ButtonItem
			onClick={handleOnClick}
			isDisabled={isDisabled}
			data-testid="software-board.board-container.board.column.header.menu.item-clear-all"
		>
			{formatMessage(messages.labelRemoveRightNow)}
		</ButtonItem>
	) : (
		<DropdownItem
			onClick={handleOnClick}
			isDisabled={isDisabled}
			data-testid="software-board.board-container.board.column.header.menu.item-clear-all"
		>
			{formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.labelIssueTermRefresh
					: messages.label,
				{ numDoneCards },
			)}
		</DropdownItem>
	);

	if (isDisabled) {
		return <Tooltip content={formatMessage(messages.tooltip)}>{menuItemClearAll}</Tooltip>;
	}

	return menuItemClearAll;
};

export default View;
