import React, { useCallback, useState } from 'react';
import { useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import NewVersionReleaseModalAsync from '@atlassian/jira-software-new-version-release-modal/src/async.tsx';
import type { ServerErrorMap } from '@atlassian/jira-software-new-version-release-modal/src/common/types.tsx';
import { REFRESH_SOURCE_RELEASE_VERSION } from '../../../model/constants.tsx';
import { workRefreshData } from '../../../state/actions/work/index.tsx';
import { useBoardSelector, useBoardDispatch } from '../../../state/index.tsx';
import { getOrderedColumnIds } from '../../../state/selectors/software/software-selectors.tsx';
import messages from '../../header/release-version/messages.tsx';

type Props = {
	setModalClosed: () => void;
	boardId: string;
};

export const NewVersionReleaseModal = ({ setModalClosed, boardId }: Props) => {
	const dispatch = useBoardDispatch();
	const orderedColumnIds = useBoardSelector(getOrderedColumnIds);
	const lastColumnId = orderedColumnIds[orderedColumnIds.length - 1];
	const { showFlag } = useFlagsService();
	const { formatMessage } = useIntl();
	const [serverErrorsInState, setServerErrors] = useState<ServerErrorMap | undefined>(undefined);

	const onNewVersionReleased = useCallback(
		(versionName: string) => {
			dispatch(workRefreshData(REFRESH_SOURCE_RELEASE_VERSION));
			setModalClosed();
			showFlag({
				messageId:
					'software-board.modals.new-version-release-modal.show-flag.success.release-success',
				messageType: 'transactional',
				type: 'success',
				title: formatMessage(messages.releaseSuccessFlagTitle, { versionName }),
				isAutoDismiss: true,
			});
		},
		[dispatch, formatMessage, setModalClosed, showFlag],
	);

	const onNewVersionReleaseError = useCallback(
		async (serverErrors?: ServerErrorMap) => {
			setServerErrors(serverErrors);

			if (!serverErrors?.name) {
				showFlag({
					messageId:
						'software-board.modals.new-version-release-modal.show-flag.error.release-failed',
					messageType: 'transactional',
					type: 'error',
					title: formatMessage(messages.releaseFailedFlagTitle),
					description:
						serverErrors?.genericMessage ?? formatMessage(messages.releaseFailedFlagDescription),
					isAutoDismiss: true,
				});

				// generally when this is the case, we created a version
				// but failed to release it/assign issues vissues to it
				setModalClosed();
			}
		},
		[formatMessage, setModalClosed, setServerErrors, showFlag],
	);

	const onFetchModalError = useCallback(() => {
		setModalClosed();
		showFlag({
			messageId: 'software-board.modals.new-version-release-modal.show-flag.error.release-failed.1',
			messageType: 'transactional',
			type: 'error',
			title: formatMessage(messages.releaseFailedFlagTitle),
			description: formatMessage(messages.releaseFailedFlagDescription),
			isAutoDismiss: true,
		});
	}, [formatMessage, setModalClosed, showFlag]);

	return (
		<NewVersionReleaseModalAsync
			boardId={boardId}
			columnId={lastColumnId.toString()}
			onCancel={setModalClosed}
			onSuccess={onNewVersionReleased}
			onError={onNewVersionReleaseError}
			onFetchModalError={onFetchModalError}
			serverErrors={serverErrorsInState}
		/>
	);
};
