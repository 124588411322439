import get from 'lodash/get';
import type {
	StringifiableValue,
	UrlBinding,
} from '@atlassian/jira-common-bind-url-to-state/src/index.tsx';
import * as presets from '@atlassian/jira-common-url-transform-presets/src/index.tsx';
import {
	TEXT,
	ASSIGNEE,
	LABEL,
	ISSUE_PARENT,
	ISSUE_PROJECT,
	ISSUE_TYPE,
	CUSTOM_FILTER,
	SPRINT,
	DEPENDENCIES,
	JQL,
} from '../../model/filter/filter-types.tsx';
import { setFilters } from '../../state/actions/filter/index.tsx';
import type { Action, Dispatch, State } from '../../state/types.tsx';

/**
 * Despite incorrect types due to use of the `icepick` library setIn operators
 * most filters only accept array values. The exception is the text search.
 */
function castToArray(queryValue: StringifiableValue): string[] {
	if (queryValue && Array.isArray(queryValue)) {
		return queryValue.map((value) => String(value));
	}
	return [String(queryValue)];
}

// @todo deprecate setValueFromUrl and pop it directly to store as initial state
export default function filterUrlBindings(isCMPBoard: Boolean, isJqlFilterEnabled: Boolean) {
	if (isJqlFilterEnabled) {
		const filterTypesFilterRefinement: UrlBinding<State, Action>[] = [
			{
				urlKey: 'jql',
				transform: presets.string,
				getValueForUrl: (state: State) => state.ui.workFilters?.values[JQL] ?? undefined,
				setValueFromUrl: (dispatch: Dispatch, value: StringifiableValue) => {
					if (value) {
						dispatch(setFilters(JQL, String(value)));
					}
				},
			},
			{
				urlKey: 'dependencies',
				transform: presets.array,
				getValueForUrl: (state: State) => {
					const filters = state.ui.workFilters?.values[DEPENDENCIES] || [];
					return filters.length ? filters : undefined;
				},
				setValueFromUrl: (dispatch: Dispatch, values: StringifiableValue) => {
					if (values) {
						dispatch(setFilters(DEPENDENCIES, castToArray(values)));
					}
				},
			} /**
			 * the "Project" filter is currently only used by the Increment Planning board of Advanced Roadmaps
			 * please refer to src/packages/software/filters/src/ui/stateless/index.tsx
			 */,
			{
				urlKey: 'project',
				transform: presets.array,
				getValueForUrl: (state: State) => {
					const filters = state.ui.workFilters?.values[ISSUE_PROJECT] || [];
					return filters.length ? filters : undefined;
				},
				setValueFromUrl: (dispatch: Dispatch, values: StringifiableValue) => {
					if (values) {
						dispatch(setFilters(ISSUE_PROJECT, castToArray(values)));
					}
				},
			},
			{
				urlKey: isCMPBoard ? 'quickFilter' : 'customFilter',
				transform: isCMPBoard ? presets.multiple : presets.array,
				getValueForUrl: (state: State) => {
					const filters = get(state, ['ui', 'workFilters', 'values', CUSTOM_FILTER], []);
					return filters.length ? filters : undefined;
				},
				setValueFromUrl: (dispatch: Dispatch, values: StringifiableValue) => {
					// CMP only. TMP boards do NOT support this yet
					if (values && isCMPBoard) {
						dispatch(setFilters(CUSTOM_FILTER, castToArray(values)));
					}
				},
			},
		];
		return filterTypesFilterRefinement;
	}

	const filterTypes: UrlBinding<State, Action>[] = [
		{
			urlKey: isCMPBoard ? 'search' : 'text',
			transform: presets.string,
			getValueForUrl: (state: State) => state.ui.workFilters?.values[TEXT],
			setValueFromUrl: (dispatch: Dispatch, value: StringifiableValue) => {
				if (value) {
					dispatch(setFilters(TEXT, String(value)));
				}
			},
		},
		{
			urlKey: 'assignee',
			transform: isCMPBoard ? presets.multiple : presets.array,
			getValueForUrl: (state: State) => {
				const filters = get(state, ['ui', 'workFilters', 'values', ASSIGNEE], []);
				return filters.length ? filters : undefined;
			},
			setValueFromUrl: (dispatch: Dispatch, values: StringifiableValue) => {
				if (values) {
					dispatch(setFilters(ASSIGNEE, castToArray(values)));
				}
			},
		},
		{
			urlKey: 'label',
			transform: presets.array,
			getValueForUrl: (state: State) => {
				const filters = get(state, ['ui', 'workFilters', 'values', LABEL], []);
				return filters.length ? filters : undefined;
			},
			setValueFromUrl: (dispatch: Dispatch, values: StringifiableValue) => {
				if (values) {
					dispatch(setFilters(LABEL, castToArray(values)));
				}
			},
		},
		{
			urlKey: 'issueParent',
			transform: presets.array,
			getValueForUrl: (state: State) => {
				const filters = get(state, ['ui', 'workFilters', 'values', ISSUE_PARENT], []);
				return filters.length ? filters : undefined;
			},
			setValueFromUrl: (dispatch: Dispatch, values: StringifiableValue) => {
				if (values) {
					dispatch(setFilters(ISSUE_PARENT, castToArray(values)));
				}
			},
		},
		{
			urlKey: 'issueType',
			transform: presets.array,
			getValueForUrl: (state: State) => {
				const filters = get(state, ['ui', 'workFilters', 'values', ISSUE_TYPE], []);
				return filters.length ? filters : undefined;
			},
			setValueFromUrl: (dispatch: Dispatch, values: StringifiableValue) => {
				if (values) {
					dispatch(setFilters(ISSUE_TYPE, castToArray(values)));
				}
			},
		},
		{
			urlKey: isCMPBoard ? 'quickFilter' : 'customFilter',
			transform: isCMPBoard ? presets.multiple : presets.array,
			getValueForUrl: (state: State) => {
				const filters = get(state, ['ui', 'workFilters', 'values', CUSTOM_FILTER], []);
				return filters.length ? filters : undefined;
			},
			setValueFromUrl: (dispatch: Dispatch, values: StringifiableValue) => {
				// CMP only. TMP boards do NOT support this yet
				if (values && isCMPBoard) {
					dispatch(setFilters(CUSTOM_FILTER, castToArray(values)));
				}
			},
		},
		{
			urlKey: 'sprints',
			transform: presets.array,
			getValueForUrl: (state: State) => {
				const filters = get(state, ['ui', 'workFilters', 'values', SPRINT], []);
				return filters.length ? filters : undefined;
			},
			setValueFromUrl: (dispatch: Dispatch, values: StringifiableValue) => {
				if (values) {
					dispatch(setFilters(SPRINT, castToArray(values)));
				}
			},
		},
		/**
		 * the "Project" filter is currently only used by the Increment Planning board of Advanced Roadmaps
		 * please refer to src/packages/software/filters/src/ui/stateless/index.tsx
		 */
		{
			urlKey: 'project',
			transform: presets.array,
			getValueForUrl: (state: State) => {
				const filters = state.ui.workFilters?.values[ISSUE_PROJECT] || [];
				return filters.length ? filters : undefined;
			},
			setValueFromUrl: (dispatch: Dispatch, values: StringifiableValue) => {
				if (values) {
					dispatch(setFilters(ISSUE_PROJECT, castToArray(values)));
				}
			},
		},
		{
			urlKey: 'dependencies',
			transform: presets.array,
			getValueForUrl: (state: State) => {
				const filters = state.ui.workFilters?.values[DEPENDENCIES] || [];
				return filters.length ? filters : undefined;
			},
			setValueFromUrl: (dispatch: Dispatch, values: StringifiableValue) => {
				if (values) {
					dispatch(setFilters(DEPENDENCIES, castToArray(values)));
				}
			},
		},
	];

	return filterTypes;
}
