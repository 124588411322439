import { createSelector } from 'reselect';
import { SWIMLANE_BY_SUBTASK } from '../../../model/swimlane/swimlane-modes.tsx';
import { getIssueChildren } from '../issue-children/index.tsx';
import { getCurrentUserAccountId } from '../people/people-selectors.tsx';
import { getSwimlaneMode } from '../swimlane/swimlane-mode-selectors.tsx';
import { workFilteredIssuesSelector } from '../work/work-selectors.tsx';
/**
 * Returns true if the user has any in-progress work
 */
export const userHasIncompleteAssignedIssuesSelector = createSelector(
	[workFilteredIssuesSelector, getIssueChildren, getCurrentUserAccountId, getSwimlaneMode],
	(issues, childrenIssue, accountId, swimlaneMode) => {
		if (swimlaneMode !== SWIMLANE_BY_SUBTASK.id) {
			return issues.some((issue) => !issue.isDone && issue.assigneeAccountId === accountId);
		}

		return [...issues, ...Object.values(childrenIssue)].some(
			(issue) => !issue.isDone && issue.assigneeAccountId === accountId,
		);
	},
);

/**
 * returns the number of issues that are done and assigned to the current user
 */
export const numberOfUserHasCompleteAssignedIssuesSelector = createSelector(
	[workFilteredIssuesSelector, getIssueChildren, getCurrentUserAccountId, getSwimlaneMode],
	(issues, childrenIssue, accountId, swimlaneMode) => {
		if (swimlaneMode !== SWIMLANE_BY_SUBTASK.id) {
			return issues.filter((issue) => issue.isDone && issue.assigneeAccountId === accountId).length;
		}

		return [...issues, ...Object.values(childrenIssue)].filter(
			(issue) => issue.isDone && issue.assigneeAccountId === accountId,
		).length;
	},
);

/**
 * Returns true if the board has available work once the unassign filter is applied
 */
export const boardHasIncompleteUnassignedWorkSelector = createSelector(
	[workFilteredIssuesSelector],
	(issues) =>
		issues.some(
			(issue) =>
				!issue.isDone &&
				(issue.assigneeAccountId === undefined || issue.assigneeAccountId === null),
		),
);

export const shouldShowUnassignedFilterNudgeSelector = createSelector(
	[userHasIncompleteAssignedIssuesSelector, boardHasIncompleteUnassignedWorkSelector],
	(userHasIncompleteAssignedIssues, boardHasIncompleteUnassignedWork) =>
		!userHasIncompleteAssignedIssues && boardHasIncompleteUnassignedWork,
);
