import { useMemo } from 'react';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';

const userLocatedBoardRoutes = [
	'rapidboard-user-board',
	'rapidboard-user-backlog',
	'rapidboard-user-board-settings',
];

export const useIsUserLocatedBoard = (): boolean => {
	const { name } = useCurrentRoute();

	return useMemo(() => userLocatedBoardRoutes.includes(name), [name]);
};
