import { useIntl } from '@atlassian/jira-intl';
import {
	type MenuNode,
	MenuNodeType,
} from '@atlassian/jira-software-context-menu/src/common/types.tsx';
import { CHANGE_STATUS_MENU_ID } from '../../constants.tsx';
import messages from '../../messages.tsx';
import { useScreenReader } from '../../../../../screen-reader-context/index.tsx';
import type { ContentProps } from './types.tsx';

export const useChangeStatusContent = ({
	onChangeStatus,
	transitionsByStatusName,
}: ContentProps): MenuNode[] => {
	const { formatMessage } = useIntl();

	const changeStatusActions: MenuNode[] = [];

	const { setScreenReaderText } = useScreenReader();

	for (const [statusName] of transitionsByStatusName.entries()) {
		changeStatusActions.push({
			type: MenuNodeType.Item,
			id: `${CHANGE_STATUS_MENU_ID}${statusName}`,
			label: statusName,
			onClick: () => {
				setScreenReaderText(formatMessage(messages.changedStatusTo, { statusName }));
				onChangeStatus(statusName);
			},
		});
	}

	return changeStatusActions.length > 0
		? [
				{
					type: MenuNodeType.Item,
					label: formatMessage(messages.changeStatus),
					id: CHANGE_STATUS_MENU_ID,
					children: changeStatusActions,
				},
			]
		: [];
};
