import React from 'react';
import { styled } from '@compiled/react';
import Lozenge from '@atlaskit/lozenge';
import { token } from '@atlaskit/tokens';

import Tooltip from '@atlaskit/tooltip';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { type IntlShape, useIntl } from '@atlassian/jira-intl';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from '@atlassian/jira-software-board-css-module/src/column-header.module.css';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { COLUMN_TITLE_LOZENGES_SELECTOR_NAME } from '../../../../../constants/index.tsx';
import { type ColumnLozengeValues, ColumnTheme } from '../../../../../types.tsx';

type Props = {
	lozenges: (string | ColumnLozengeValues)[];
	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	appearance: ColumnTheme | void;
};

const lozengeTypeAppearance = {
	maximum: 'removed',
	minimum: 'moved',
} as const;

const lozengeAppearanceMap = {
	default: 'default',
	warning: 'moved',
	danger: 'removed',
} as const;

export function getTooltipContent(
	message: string | ColumnLozengeValues,
	formatMessage: IntlShape['formatMessage'],
): string {
	if (message instanceof Object) {
		return formatMessage(message.tooltip);
	}
	return String(message);
}

export function getAppearance(
	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	columnAppearance: ColumnTheme | void,
	lozenge: string | ColumnLozengeValues,
) {
	if (typeof lozenge !== 'object' || !('type' in lozenge)) {
		return lozengeAppearanceMap[columnAppearance ?? ColumnTheme.Default];
	}

	if (columnAppearance === ColumnTheme.Default || lozenge.type == null) {
		return 'default';
	}

	const typeToAp = {
		maximum: 'danger',
		minimum: 'warning',
	};
	if (lozenge.type && typeToAp[lozenge.type] === columnAppearance) {
		return lozengeTypeAppearance[lozenge.type];
	}
	return 'default';
}

export function getBackgroundColor(
	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	columnAppearance: ColumnTheme | void,
	lozenge: string | ColumnLozengeValues,
): string | undefined {
	// We need to override warning colors on this case only because of design
	// system differences with our warning color design.
	const minimumBustedColumn =
		columnAppearance === ColumnTheme.Warning &&
		typeof lozenge === 'object' &&
		'type' in lozenge &&
		lozenge.type === 'minimum' &&
		lozenge.busted;

	return minimumBustedColumn ? token('color.background.warning.bold') : undefined;
}

export function getIsBold(lozenge: string | ColumnLozengeValues) {
	return typeof lozenge === 'object' && 'busted' in lozenge ? Boolean(lozenge.busted) : false;
}

export function ColumnTitleLozenge({
	lozenge,
	appearance: columnAppearance,
}: {
	lozenge: string | ColumnLozengeValues;
	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	appearance: ColumnTheme | void;
}) {
	const { formatMessage } = useIntl();

	const lozengeAppearance = getAppearance(columnAppearance, lozenge);
	const isBold = getIsBold(lozenge);
	const style = {
		backgroundColor: getBackgroundColor(columnAppearance, lozenge),
	};

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={lozengeAppearance === 'default' ? styles.defaultLozengeContainer : undefined}>
			<Tooltip content={getTooltipContent(lozenge, formatMessage)}>
				<Lozenge
					appearance={lozengeAppearance}
					isBold={isBold}
					testId="platform-board-kit.common.ui.column-header.editable-title.column-title.lozanges.lozenge"
					// eslint-disable-next-line jira/react/no-style-attribute -- Ignored via go/DSP-18766
					style={style}
				>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<span className={styles.defaultLozenge}>
						{lozenge instanceof Object
							? formatMessage(lozenge.descriptor, lozenge.values)
							: String(lozenge)}
					</span>
					{lozengeAppearance !== 'default' && (
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						<span className={styles.compactLozenge}>
							{lozenge instanceof Object ? lozenge.values.count : String(lozenge)}
						</span>
					)}
				</Lozenge>
			</Tooltip>
		</div>
	);
}

function ColumnTitleLozenges({ appearance, lozenges }: Props) {
	return (
		<LozengeWrapper
			data-testid="platform-board-kit.common.ui.column-header.editable-title.column-title.lozanges.lozenge-wrapper"
			data-component-selector={COLUMN_TITLE_LOZENGES_SELECTOR_NAME}
			withVr={isVisualRefreshEnabled() && fg('visual-refresh_drop_6')}
		>
			{lozenges.map((lozenge, index) => (
				<ColumnTitleLozenge appearance={appearance} lozenge={lozenge} key={index} />
			))}
		</LozengeWrapper>
	);
}

export default ColumnTitleLozenges;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LozengeWrapper = styled.div<{ withVr: boolean }>({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	gap: `${gridSize}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	paddingLeft: (withVr) => (withVr ? 0 : token('space.100')),
});
