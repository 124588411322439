import type {
	BoardScopeFilter,
	PlanningUnit,
} from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/types.tsx';

export const FieldType = {
	BuiltIn: 'BuiltIn',
	Custom: 'Custom',
} as const;
export type FieldType = (typeof FieldType)[keyof typeof FieldType];
export type DateFieldType = {
	id: string;
	type: FieldType | string;
	key: string;
};

export type IncrementConfig = {
	startDateField: DateFieldType;
	endDateField: DateFieldType;
	planningUnit: PlanningUnit;
	scenarioColor: string;
	isConcurrentScheduling: boolean;
	boardScopeFilter?: BoardScopeFilter;
	iterationLength: number;
	iterationsCount: number;
};
