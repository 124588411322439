import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import CardIcon from '../icon/main.tsx';
import { CHILD_ISSUES_ICON } from '../icon/types.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

export const CardChildIssues = (props: Props) => {
	const { complete, total } = props;
	const { formatMessage } = useIntl();

	if (total === null || total === 0) return null;

	const message = formatMessage(
		expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
			? messages.childIssuesMetadataIssueTermRefresh
			: messages.childIssuesMetadata,
		{
			complete,
			total,
		},
	);

	return (
		<Container>
			<Tooltip content={message}>
				<CardIcon type={CHILD_ISSUES_ICON} label={message} primaryColor={token('color.icon')} />
			</Tooltip>
		</Container>
	);
};

export default CardChildIssues;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: 1,
});
