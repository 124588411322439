import { BEGIN, COMMIT, REVERT } from 'redux-optimistic-ui';
import { v4 as uuid } from 'uuid';
import { IS_MODERN_SSR } from '@atlassian/jira-conditional-ssr-render/src/constants.tsx';
import type { OptimisticId } from '../../../model/software/software-types.tsx';
import flagMessages from '../../../view/flags/messages.tsx';
import { type FlagMeta, warningFlagMeta } from './flag-meta.tsx';

export type OptimisticUiMeta = {
	optimistic: {
		type: typeof BEGIN | typeof COMMIT | typeof REVERT;
		id: OptimisticId;
	};
};

export const optimisticUiRevertFlagId = 'OPTIMISTIC_REVERT_FLAG';

// This is a temporary key for SSR until we can verify when we server render the board content
const getUniqueId = () => (IS_MODERN_SSR ? 'ssr-unique-id' : uuid());

export const beginOptimisticUiMeta = (): OptimisticUiMeta => ({
	optimistic: {
		type: BEGIN,
		id: getUniqueId(),
	},
});

export const commitOptimisticUiMeta = (optimisticId: OptimisticId): OptimisticUiMeta => ({
	optimistic: {
		type: COMMIT,
		id: optimisticId,
	},
});

export const revertOptimisticUiMeta = (optimisticId: OptimisticId): OptimisticUiMeta => ({
	optimistic: {
		type: REVERT,
		id: optimisticId,
	},
});

export const revertOptimisticUiWithFlagMeta = (
	optimisticId: OptimisticId,
): OptimisticUiMeta & FlagMeta => ({
	optimistic: {
		type: REVERT,
		id: optimisticId,
	},
	...warningFlagMeta({
		id: optimisticUiRevertFlagId,
		titleMessage: flagMessages.genericTitle,
		descriptionMessage: flagMessages.genericDescription,
	}),
});
