import React, { useRef } from 'react';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { asyncUnassignedFilterNudge } from './entrypoint.tsx';
import type { UnassignedFilterNudgeProps } from './main.tsx';

const entryPointParams = {};
const AsyncUnassignedFilterNudge = (props: UnassignedFilterNudgeProps) => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		asyncUnassignedFilterNudge,
		entryPointParams,
	);
	const hasLoadedEntrypoint = useRef(false);

	if (!hasLoadedEntrypoint.current) {
		entryPointActions.load();
		hasLoadedEntrypoint.current = true;
	}

	return (
		<JiraEntryPointContainer
			entryPointReferenceSubject={entryPointReferenceSubject}
			packageName="UnassignedFilterNudge"
			id="UnassignedFilterNudge"
			teamName="jira-warepil"
			fallback="unmount"
			runtimeProps={props}
		/>
	);
};

export default AsyncUnassignedFilterNudge;
