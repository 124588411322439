import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	filterButton: {
		id: 'filter-refinement.filter-popup.filter-button',
		defaultMessage: 'Filter',
		description:
			'Label shown for the "Filter" button which opens a popup displaying a list of filters that the user can apply to refine a list of Jira issues',
	},
});
