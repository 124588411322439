import React, { useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import { useQueryLoader } from 'react-relay';
import { fg } from '@atlassian/jira-feature-gating';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import QUERY, {
	type main_boardWorkSuggestions_Query,
	type WorkSuggestionsContextAri,
} from '@atlassian/jira-relay/src/__generated__/main_boardWorkSuggestions_Query.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useContextAri } from '../../services/use-context-ari/index.tsx';
import { useNextBestTaskPanel } from '../../services/use-next-best-task-panel/index.tsx';
import type { TaskPanel } from './index.tsx';
import type { Props } from './types.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyNBTPanel = lazyForPaint<typeof TaskPanel>(
	() =>
		import(/* webpackChunkName: "new-async-nbt-panel" */ './index').then(
			({ TaskPanel }) => TaskPanel,
		),
	{ ssr: false },
);

export const AsyncNBTPanel = (props: Props) => {
	const [queryRef, loadQuery, dispose] = useQueryLoader<main_boardWorkSuggestions_Query>(QUERY);
	const { sprintAri, projectAri } = useContextAri();

	const cloudId = useCloudId();
	const contextAri: WorkSuggestionsContextAri | null = useMemo(() => {
		if (projectAri == null) {
			return null;
		}
		const ari = sprintAri ? { sprintAri, projectAri } : { projectAri };
		return ari;
	}, [sprintAri, projectAri]);
	const errorCallbackRef = useRef<(() => void) | null>(null);
	const { isReadyToFetchNBT } = useNextBestTaskPanel();
	const hasPanelAnimation = props.isSimplifiedProject;

	const fetchGraphEndpoint = useCallback(() => {
		if (contextAri == null) {
			return;
		}
		loadQuery(
			{
				cloudId,
				projectAri: contextAri.projectAri,
				isPrRecentCommentEnabled: fg('jira-board-work-suggestions-recent-pr-comments'),
				contextAri,
				count: 12,
				cursor: null,
				isIssueDueSoonEnabled: expVal(
					'jira-board-work-suggestion-issue-due-soon-exp',
					'isEnabled',
					false,
				),
			},
			{ fetchPolicy: 'network-only' },
		);
	}, [loadQuery, contextAri, cloudId]);

	useLayoutEffect(() => {
		if (isReadyToFetchNBT) {
			if (errorCallbackRef.current !== null) {
				errorCallbackRef.current();
				errorCallbackRef.current = null;
			}
			fetchGraphEndpoint();
		} else if (!hasPanelAnimation) {
			// We will cleanup the data immediately because we don't need to wait for the animation to finish.
			dispose();
		}
	}, [isReadyToFetchNBT, fetchGraphEndpoint, dispose, hasPanelAnimation, errorCallbackRef]);

	return (
		<UFOSegment name="next-best-task-panel">
			<Placeholder name="async-nbt-panel" fallback={<></>}>
				<LazyNBTPanel
					{...props}
					queryRef={queryRef}
					fetchGraphEndpoint={fetchGraphEndpoint}
					dispose={dispose}
					errorCallbackRef={errorCallbackRef}
				/>
			</Placeholder>
		</UFOSegment>
	);
};
