import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	addIncrementTitle: {
		id: 'portfolio-3-plan-increment-common.ui.increment-forms.creation-modal.add-increment-title',
		defaultMessage: 'Create a program board',
		description: 'Title of the create increment modal.',
	},
	addIncrementDescriptionNew: {
		id: 'portfolio-3-plan-increment-common.ui.increment-forms.creation-modal.add-increment-description-new',
		defaultMessage: 'Schedule work across multiple teams and iterations for the next quarter. ',
		description: 'Description of the create increment modal.',
	},
	doclink: {
		id: 'portfolio-3-plan-increment-common.ui.increment-forms.creation-modal.doclink',
		defaultMessage: 'See how to configure your program board',
		description: 'Label for the learn more link',
	},
	nameShort: {
		id: 'portfolio-3-plan-increment-common.ui.increment-forms.creation-modal.name-short',
		defaultMessage: 'Board name (required)',
		description: 'Label for the required increment name field',
	},
	startDateShort: {
		id: 'portfolio-3-plan-increment-common.ui.increment-forms.creation-modal.start-date-short',
		defaultMessage: 'Start date',
		description: 'Label for the required increment start date field',
	},
	startTime: {
		id: 'portfolio-3-plan-increment-common.ui.increment-forms.creation-modal.start-time',
		defaultMessage: 'Start time',
		description: 'Label for the required for the time component of the increment start date field',
	},
	namePlaceholder: {
		id: 'portfolio-3-plan-increment-common.ui.increment-forms.creation-modal.name-placeholder',
		defaultMessage: 'e.g.Q1FY24',
		description: 'Placeholder for the name field',
	},
	submit: {
		id: 'portfolio-3-plan-increment-common.ui.increment-forms.creation-modal.submit',
		defaultMessage: 'Create',
		description: 'Label for the submit button',
	},
	cancel: {
		id: 'portfolio-3-plan-increment-common.ui.increment-forms.creation-modal.cancel',
		defaultMessage: 'Cancel',
		description: 'Label for the cancel button',
	},
	errorLabel: {
		id: 'portfolio-3-plan-increment-common.ui.increment-forms.creation-modal.error-label',
		defaultMessage: 'We couldn’t create your board. Refresh the page and try again. ',
		description: 'ARIA label for an error icon.',
	},
	closeModal: {
		id: 'portfolio-3-plan-increment-common.ui.increment-forms.creation-modal.close-modal',
		defaultMessage: 'Close modal',
		description: 'Accessible label for the close modal button',
	},
	scopeFilterHeading: {
		id: 'portfolio-3-plan-increment-common.ui.increment-forms.creation-modal.scope-filter-heading',
		defaultMessage: 'What issues do you want to include?',
		description: 'Heading for the program board scope filter setting',
	},
	scopeFilterHeadingIssueTermRefresh: {
		id: 'portfolio-3-plan-increment-common.ui.increment-forms.creation-modal.scope-filter-heading-issue-term-refresh',
		defaultMessage: 'What work items do you want to include?',
		description: 'Heading for the program board scope filter setting',
	},
	scopeFilterDescription: {
		id: 'portfolio-3-plan-increment-common.ui.increment-forms.creation-modal.scope-filter-description',
		defaultMessage:
			'Select a field to filter which issues from your plan are added to your program board. You can use the <bold>Label</bold> field or a custom field that’s been added to your plan. <link>Read more about program boards.</link>',
		description: 'Description for the program board scope filter setting',
	},
	scopeFilterDescriptionIssueTermRefresh: {
		id: 'portfolio-3-plan-increment-common.ui.increment-forms.creation-modal.scope-filter-description-issue-term-refresh',
		defaultMessage:
			'Select a field to filter which work items from your plan are added to your program board. You can use the <bold>Label</bold> field or a custom field that’s been added to your plan. <link>Read more about program boards.</link>',
		description: 'Description for the program board scope filter setting',
	},
	nameEmptyError: {
		id: 'portfolio-3-plan-increment-common.ui.increment-forms.creation-modal.name-empty-error',
		defaultMessage: 'Name is required',
		description: 'Error for the empty name',
	},
	startDateEmptyError: {
		id: 'portfolio-3-plan-increment-common.ui.increment-forms.creation-modal.start-date-empty-error',
		defaultMessage: 'Start date is required',
		description: 'Error for the empty start Date',
	},
	startDateAndTimeEmptyError: {
		id: 'portfolio-3-plan-increment-common.ui.increment-forms.creation-modal.start-date-and-time-empty-error',
		defaultMessage: 'Start date and time is required',
		description: 'Error for the empty start Date and time fields',
	},
	fieldValueEmptyError: {
		id: 'portfolio-3-plan-increment-common.ui.increment-forms.creation-modal.field-value-empty-error',
		defaultMessage: 'Field value is required when you select Field',
		description: 'Error for the empty field value',
	},
	iterationLength: {
		id: 'portfolio-3-plan-increment-common.ui.increment-forms.creation-modal.iteration-length',
		defaultMessage: 'Column duration',
		description: 'Label for the iteration length',
	},
	iterationsCount: {
		id: 'portfolio-3-plan-increment-common.ui.increment-forms.creation-modal.iterations-count',
		defaultMessage: 'Number of columns',
		description: 'Label for the iterations count',
	},
});
export default messages;
