import React, { type SyntheticEvent } from 'react';
import noop from 'lodash/noop';
import { SkeletonHeadingItem } from '@atlaskit/menu';
import { Inline, xcss, Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { getApplicationForProject } from '@atlassian/jira-shared-types/src/application.tsx';
import { IssueLinkedStatsContainer } from '../controllers/main.tsx';
import { useFocusOnIssueLinksStats } from '../controllers/use-focus-on-issue-links/index.tsx';
import { MaxIssueLinksStatsCount } from './issue-links-stats-button/constants.tsx';
import { IssueLinksStatsButton } from './issue-links-stats-button/index.tsx';
import type { IssueLinksStatsProps } from './types.tsx';

export const IssueLinksStats = ({
	issueLinksCount,
	testId,
	issueKey,
	issueId,
	issueSummary,
	issueType,
	onIssueUnlink,
	feedbackCollectorPrefix,
	isLoading = false,
	canUserLinkIssue,
	withDueDate = false,
	maxIssueLinksStatsCount = MaxIssueLinksStatsCount,
	sourceProductType,
	isLinkIssuePopupEnabled = false,
	onSuccessfulIssueLink = noop,
}: IssueLinksStatsProps) => {
	const { isFocusOnIssueLinksStats, focusOnIssueLinks } = useFocusOnIssueLinksStats();

	return (
		<ContextualAnalyticsData
			sourceName="issueLinksStats"
			attributes={{
				application: sourceProductType && getApplicationForProject(sourceProductType),
			}}
		>
			<IssueLinkedStatsContainer isGlobal>
				<Box
					xcss={issueStatsRowStyles}
					testId={testId}
					onClick={(event: SyntheticEvent) => event.stopPropagation()}
					onBlur={(event: SyntheticEvent) => event.stopPropagation()}
				>
					<Inline shouldWrap space="space.050">
						{isLoading ? (
							<Box xcss={skeletonLoaderStyles}>
								<SkeletonHeadingItem
									isShimmering
									width="100%"
									testId={testId ? `${testId}.skeleton-loader` : undefined}
								/>
							</Box>
						) : (
							<IssueLinksStatsButton
								issueKey={issueKey}
								issueId={issueId}
								issueSummary={issueSummary}
								issueType={issueType}
								issueLinkCount={issueLinksCount}
								isFocusOnTrigger={isFocusOnIssueLinksStats}
								feedbackCollectorPrefix={feedbackCollectorPrefix}
								onIssueUnlink={onIssueUnlink}
								onFocusToTrigger={focusOnIssueLinks}
								canUserLinkIssue={canUserLinkIssue}
								withDueDate={withDueDate}
								maxIssueLinksStatsCount={maxIssueLinksStatsCount}
								sourceProductType={sourceProductType}
								isLinkIssuePopupEnabled={isLinkIssuePopupEnabled}
								onSuccessfulIssueLink={onSuccessfulIssueLink}
							/>
						)}
					</Inline>
				</Box>
			</IssueLinkedStatsContainer>
		</ContextualAnalyticsData>
	);
};

const issueStatsRowStyles = xcss({
	cursor: 'default',
	borderTop: 'none',
});

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
const skeletonLoaderStyles = xcss({
	width: '29px',
	maxWidth: '100%',
	display: 'flex',
	justifyContent: 'flex-end',

	div: {
		width: '100%',
		padding: '0',
		'::after': {
			height: '23px',
			borderRadius: `${
				// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage -- The token value "2px" and fallback "3px" do not match and can't be replaced automatically.
				token('border.radius.050', '3px')
			}`,
		},
	},
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any);
