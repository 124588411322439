import React, { useCallback, useMemo, memo, type Ref } from 'react';
import noop from 'lodash/noop';
import { EngagementSpotlight } from '@atlassiansox/engagekit-ts';
import { SpotlightManager, SpotlightTarget } from '@atlaskit/onboarding';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import ReportErrors from '@atlassian/jira-errors-handling/src/utils/reporting-error-boundary.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
	type AnalyticsAttributes,
} from '@atlassian/jira-product-analytics-bridge';
import { type VersionId, toStringArray } from '@atlassian/jira-shared-types/src/general.tsx';
import { ViewSetting } from '@atlassian/jira-software-view-settings/src/common/types/settings.tsx';
import { useViewSettingsActions } from '@atlassian/jira-software-view-settings/src/controllers/index.tsx';
import { usePathParam } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
import type { Version, CheckboxSelectEntry } from '../../../common/types.tsx';
import ListFilter from '../../../common/ui/list/index.tsx';
import messages from './messages.tsx';

export type Props = {
	isToggleChecked: boolean;
	// go/jfe-eslint
	isVersionsFeatureEnabled: boolean;
	isSpotlightEnabled: boolean;
	showPanelToggle: boolean;
	versions: Readonly<Version[]>;
	selectedVersions: VersionId[];
	onChange: (arg1: string[], attributes?: AnalyticsAttributes) => void;
	onTogglePanel: () => void;
	isNested?: boolean;
	toggleRef?: Ref<HTMLInputElement>;
	hasMultipleProjects?: boolean;
	isLoading?: boolean;
};

const VersionFilter = (props: Props) => {
	const {
		versions,
		selectedVersions,
		isToggleChecked,
		onTogglePanel,
		isSpotlightEnabled,
		showPanelToggle,
		isNested,
		toggleRef,
		hasMultipleProjects,
		isLoading,
	} = props;
	const { formatMessage } = useIntl();
	const { toggleViewSetting } = useViewSettingsActions();
	const [boardId] = usePathParam('boardId');

	const values = useMemo(
		(): CheckboxSelectEntry[] =>
			versions.map((version) => ({
				label: version.name,
				// Casting to string normalises the id that may potentially be a number.
				value: String(version.id),
				subLabel: hasMultipleProjects ? version.projectKey : undefined,
			})),
		[versions, hasMultipleProjects],
	);

	const onChange = useCallback(
		(entries: CheckboxSelectEntry[]) => {
			const analyticAttributes = { isNested };
			props.onChange(
				entries.map(({ value }) => value),
				analyticAttributes,
			);
		},
		[props, isNested],
	);

	const handleTogglePanel = useCallback(() => {
		if (boardId) {
			toggleViewSetting({
				boardId: Number(boardId),
				setting: ViewSetting.VERSION_PANEL,
			});
		}
		onTogglePanel();
	}, [boardId, onTogglePanel, toggleViewSetting]);

	const renderContent = useMemo(
		() => (
			<ListFilter
				label={formatMessage(messages.label)}
				toggleLabel={formatMessage(messages.toggleLabel)}
				values={values}
				showPanelToggle={showPanelToggle}
				selectedValues={toStringArray(selectedVersions)}
				isPanelToggleChecked={isToggleChecked}
				onChange={onChange}
				onTogglePanel={handleTogglePanel}
				noOptionsMessage={formatMessage(
					values.length === 0 ? messages.noUnreleasedVersions : messages.noMatchingVersions,
				)}
				showMenuIfValuesAreEmpty
				isNested={isNested}
				toggleRef={toggleRef}
				isLoading={isLoading}
				placeholder={formatMessage(messages.placeholder)}
				{...(showPanelToggle &&
					fg('jfp_a11y_team_list_filter_interactivity') && { shouldCloseMenuOnTab: false })}
			/>
		),
		[
			formatMessage,
			values,
			showPanelToggle,
			selectedVersions,
			isToggleChecked,
			onChange,
			isNested,
			toggleRef,
			handleTogglePanel,
			isLoading,
		],
	);
	if (isSpotlightEnabled) {
		return (
			<SpotlightManager>
				<SpotlightTarget name="fusionBacklogVersionsPanel">
					{renderContent}
					<EngagementSpotlight engagementId="fusionBacklogVersionsPanel" />
				</SpotlightTarget>
			</SpotlightManager>
		);
	}
	return renderContent;
};

VersionFilter.displayName = 'VersionFilter';
VersionFilter.whyDidYouRender = true;

const ErrorWrapper = (props: Props) => {
	if (!props.isVersionsFeatureEnabled) {
		return null;
	}
	return (
		<ErrorBoundary id="jira.backlog.version.filter" packageName="jiraFilters">
			<ReportErrors id="jira.backlog.version.filter" packageName="jiraFilters">
				<ContextualAnalyticsData
					sourceType={SCREEN}
					sourceName="versionFilter"
					attributes={{
						isVersionPanelOpen: props.isToggleChecked,
					}}
				>
					<VersionFilter {...props} />
					<FireScreenAnalytics />
				</ContextualAnalyticsData>
			</ReportErrors>
		</ErrorBoundary>
	);
};

type DefaultProps = Props;
ErrorWrapper.defaultProps = {
	versions: [],
	selectedVersions: [],
	isToggleChecked: false,
	isSpotlightEnabled: false,
	showPanelToggle: true,
	isVersionsFeatureEnabled: false,
	onChange: noop,
	onTogglePanel: noop,
	isNested: false,
};

export default memo<Props & DefaultProps>(ErrorWrapper);
