import React, { useCallback, useRef } from 'react';
import { useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import UndoErrorFlag from '../../common/ui/undo-error-flag/index.tsx';
import getUndoSuccessFlag from '../../common/ui/undo-success-flag/index.tsx';
import { useUnclearCardsMutation } from '../../services/unclear-cards-mutation/main.tsx';
import type { MutationResult as UnclearCardsMutationResult } from '../../services/unclear-cards-mutation/types.tsx';

type MutationArgs = {
	cardIds: (number | string)[];
	boardId: string;
	onUndo?: (promise: UnclearCardsMutationResult, retry: boolean) => void;
	retry?: boolean;
};

export type UnclearCardsFunction = (arg1: MutationArgs) => UnclearCardsMutationResult;

export const useUnclearCards = () => {
	const [unclearMutation] = useUnclearCardsMutation();
	const { showFlag, dismissFlag } = useFlagsService();
	const errorFlagId = useRef<unknown>();

	const onDismissUndoErrorFlag = useCallback(() => {
		if (errorFlagId.current) {
			// @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'string'.
			dismissFlag(errorFlagId.current);
		}
	}, [dismissFlag]);

	const callback = useCallback(
		({ cardIds, boardId, onUndo, retry }: MutationArgs) => {
			const unclearMutationPromise = unclearMutation({
				variables: { cardIds: cardIds.map(String), boardId: String(boardId) },
			});

			unclearMutationPromise
				.then(() => {
					retry === true && showFlag(getUndoSuccessFlag());
				})
				.catch(() => {
					errorFlagId.current = showFlag({
						render: (props) => (
							<UndoErrorFlag
								onDismissFlag={onDismissUndoErrorFlag}
								onRetry={() => callback({ cardIds, boardId, onUndo, retry: true })}
								{...props}
							/>
						),
					});
				});

			onUndo && onUndo(unclearMutationPromise, !!retry);
		},
		[onDismissUndoErrorFlag, showFlag, unclearMutation],
	);

	return [callback] as const;
};
