import React, { type ComponentType } from 'react';
import flow from 'lodash/flow';
import type { PreloadedQuery } from 'react-relay';
// TODO: JFP-2824 | Suppressed to enable upgrade to ESLint v9 - please fix this if you can!
// eslint-disable-next-line jira/restricted/@atlaskit+analytics-next
import { AnalyticsContext } from '@atlaskit/analytics-next';
import ReduxAnalyticsData from '@atlassian/jira-analytics-web-react/src/components/redux-analytics-data.tsx';
import ViewTracker from '@atlassian/jira-analytics-web-react/src/components/view-tracker.tsx';
import { UNSCHEDULED_COLUMN_ID } from '@atlassian/jira-common-constants/src/column-types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { SCREEN } from '@atlassian/jira-product-analytics-bridge';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { viewBoardCustomFiltersQuery } from '@atlassian/jira-relay/src/__generated__/viewBoardCustomFiltersQuery.graphql';
import AddBoardToHistory from '@atlassian/jira-view-history/src/ui/add-board-to-history/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import type { boardFilterRefinementQuery } from '@atlassian/jira-relay/src/__generated__/boardFilterRefinementQuery.graphql.ts';
import {
	getIssueIdsToShowDependencies,
	getShowOfftrackDependencyLines,
} from '../state/selectors/issue-link/issue-link-selectors.tsx';
import {
	getExternalActionForModal,
	isIssueModalShowingSelector,
} from '../state/selectors/issue/issue-modal-selectors.tsx';
import { getOrderedIssuesByColumn } from '../state/selectors/issue/issue-selectors.tsx';
import { getInsightsPanel } from '../state/selectors/panels/index.tsx';
import { getCurrentUserAccountId } from '../state/selectors/people/people-selectors.tsx';
import {
	contextPathSelector,
	rapidViewIdSelector,
	projectIdSelector,
	projectKeySelector,
	getIsCMPBoard,
	getIsIncrementPlanningBoard,
	getIssues,
	projectTypeSelector,
	getBoardTypeForAnalytics,
	getIssueLimitExceeded,
	getSavedFilterId,
	getBoardScopeFilterType,
} from '../state/selectors/software/software-selectors.tsx';
import { getSelectedOrAllSprintsSelector } from '../state/selectors/sprint/sprint-selectors.tsx';
import { getSwimlaneMode } from '../state/selectors/swimlane/swimlane-mode-selectors.tsx';
import { getSprintCapacityInfo } from '../state/selectors/team/team-selectors.tsx';
import {
	getEstimationStatistic,
	getWorkFilters,
	getIssuesEntriesWithIssueLinks,
	getIssuesWithIssueLinksInBoardIds,
	getUnAssignedIssueCount,
} from '../state/selectors/work/work-selectors.tsx';
import type { State } from '../state/types.tsx';
import BoardContainer from './board-container/index.tsx';
import DetailView from './detail-view/index.tsx';
import DocumentTitle from './document-title/index.tsx';
import { withViewExperienceTracker } from './experience-tracker/index.tsx';
import Flags from './flags/index.tsx';
import GlobalSearchTracker from './global-search-tracker/index.tsx';
import Header from './header/index.tsx';
import AsyncPrefetchIssues from './prefetch-issues/async/index.tsx';
import Realtime from './realtime/index.tsx';
import Board from './view.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const AfterPaintModals = lazyAfterPaint(
	() => import(/* webpackChunkName: "async-board-modals" */ './modals'),
	{ ssr: false },
);

type OwnProps = {
	isCacheHit: boolean;
	customFiltersQueryRef: PreloadedQuery<viewBoardCustomFiltersQuery> | null | undefined;
	boardFilterRefinementQueryRef?: PreloadedQuery<boardFilterRefinementQuery> | null;
	rapidViewId: string;
	xsrfToken: string;
	hasMinimap: boolean;
	hasModals: boolean;
	hasFlags: boolean;
	projectId: number;
	isIncrementPlanningBoard: boolean;
};

const incrementPlanningBoardAnalyticsAttributes = (state: State) => {
	const issuesByColumn = getOrderedIssuesByColumn(state);
	const totalUnplannedIssues = issuesByColumn[UNSCHEDULED_COLUMN_ID]
		? Object.keys(issuesByColumn[UNSCHEDULED_COLUMN_ID]).length
		: 0;
	const boardScopeFilterType = getBoardScopeFilterType(state);

	return {
		teamsWithLinkedSprints: Object.keys(getSprintCapacityInfo(state)).length,
		appliedFilters: getWorkFilters(state),
		boardScopeFilterType,
		totalIssues: Object.keys(getIssues(state)).length,
		totalUnplannedIssues,
		totalDependencies: Object.keys(getIssuesEntriesWithIssueLinks(state)).length,
		scheduledDependencies: getIssuesWithIssueLinksInBoardIds(state).length,
		showOffTrackDependencies: getShowOfftrackDependencyLines(state),
		individualDependenciesSelected: getIssueIdsToShowDependencies(state).length,
	};
};

// define separate analytics source and subject for JSW board and Plan program board (aka increment planning board)
const BoardAnalyticsHOC =
	() =>
	<P extends OwnProps>(WrappedComponent: ComponentType<P>) =>
	(props: P) => {
		const analyticsSource = props.isIncrementPlanningBoard ? 'programBoard' : 'board';
		return (
			<AnalyticsContext
				data={{ actionSubject: analyticsSource, source: `${analyticsSource}${SCREEN}` }}
			>
				<WrappedComponent {...props} />
			</AnalyticsContext>
		);
	};

const getFeatureFlaggedUnAssignedIssueCount = (state: State) =>
	fg('thor-issue-view-experiment-unassigned-icon') ? getUnAssignedIssueCount(state) : null;

const App = flow(
	connect(
		(state: State, ownProps: OwnProps) => ({
			isCacheHit: ownProps.isCacheHit,
			customFiltersQueryRef: ownProps.customFiltersQueryRef,
			boardFilterRefinementQueryRef: ownProps.boardFilterRefinementQueryRef,
			Header,
			Modals: AfterPaintModals,
			Flags,
			Board: BoardContainer,
			ViewTracker,
			DocumentTitle,
			PrefetchIssues: AsyncPrefetchIssues,
			DetailView,
			Realtime,
			AddBoardToHistory,
			GlobalSearchTracker,
			isInsightsPanelOpen: getInsightsPanel(state),
			baseUrl: contextPathSelector(state),
			projectKey: projectKeySelector(state),
			estimationStatistic: getEstimationStatistic(state),
			currentUserAccountId: getCurrentUserAccountId(state),
			issueLimitExceeded: getIssueLimitExceeded(state),
			savedFilterId: getSavedFilterId(state),
			selectedSprintsOrAllSprints: getSelectedOrAllSprintsSelector(state),
			externalActionForIssueView: getExternalActionForModal(state),
		}),
		{},
	),
	BoardAnalyticsHOC(),
	ReduxAnalyticsData((state) => ({
		containerType: 'project',
		containerId: String(projectIdSelector(state)),
		attributes: {
			projectId: projectIdSelector(state),
			boardId: rapidViewIdSelector(state),
			isIssueDetailVisible: isIssueModalShowingSelector(state),
			isUIF: true,
			isClassic: getIsCMPBoard(state),
			unAssignedIssueCount: getFeatureFlaggedUnAssignedIssueCount(state),
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			isInactiveTab: document.hidden,
			boardType: getBoardTypeForAnalytics(state),
			projectType: projectTypeSelector(state),
			swimlaneMode: getSwimlaneMode(state),
			...(getIsIncrementPlanningBoard(state)
				? incrementPlanningBoardAnalyticsAttributes(state)
				: {}),
		},
	})),
)(Board);

export default withViewExperienceTracker<OwnProps>(App);
