import React from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import RestrictedImage from '../../../../common/assets/restricted.svg';
import { columnHeaderHeight } from '../../../../common/constants/styles/index.tsx';

type NoTransitionContainerProps = {
	noTransitionMessage: MessageDescriptor;
};

export function NoTransitionContainer({ noTransitionMessage }: NoTransitionContainerProps) {
	const { formatMessage } = useIntl();
	const message = formatMessage(noTransitionMessage);

	return (
		<Wrapper>
			<Img src={RestrictedImage} role="presentation" alt={message} />
			{message}
		</Wrapper>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	position: 'sticky',
	display: 'flex',
	flexDirection: 'column',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${columnHeaderHeight * 2}px`,
	alignItems: 'center',
	justifyContent: 'center',
	textAlign: 'center',
	color: token('color.text.subtlest'),
	font: token('font.body.small'),
	paddingTop: token('space.300'),
	paddingRight: token('space.300'),
	paddingBottom: token('space.300'),
	paddingLeft: token('space.300'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Img = styled.img({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 3}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 3}px`,
	marginBottom: token('space.100'),
});
