import React, { useEffect, useCallback, useRef } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import { IconButton } from '@atlaskit/button/new';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/show-more-horizontal';
import MoreIcon from '@atlaskit/icon/core/migration/show-more-horizontal--more';
import type { ContentProps } from '@atlaskit/popup';
import { token } from '@atlaskit/tokens';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIsMenuOpen, useContextMenuActions } from '../../controllers/context-menu/index.tsx';
import { MEATBALL_MENU_TESTID } from './constants.tsx';
import type { MeatballMenuProps } from './types.tsx';

export const MeatballMenu = ({
	onOpenChange,
	scope,
	renderMenu,
	ariaLabel,
	isCompact,
	jsErrorBoundaryProps,
}: MeatballMenuProps) => {
	const { openMenu, closeMenu } = useContextMenuActions();
	const isOpen = useIsMenuOpen({ scope });
	const triggerRef = useRef<HTMLButtonElement | null>(null);

	useEffect(() => {
		onOpenChange?.(isOpen);
	}, [isOpen, onOpenChange]);

	const renderContent = useCallback(
		({ isOpen: isContentOpen }: ContentProps) =>
			isContentOpen ? (
				<JSErrorBoundary fallback="unmount" {...jsErrorBoundaryProps}>
					{' '}
					{renderMenu(scope, triggerRef)}
				</JSErrorBoundary>
			) : null,
		[renderMenu, scope, jsErrorBoundaryProps],
	);

	const onClick = useCallback(
		(e: React.MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
			e.stopPropagation();

			analyticsEvent.update({
				action: 'opened',
				actionSubject: 'contextMenu',
			});

			if (!isOpen) {
				openMenu({
					scope,
					position: { x: 0, y: 0 },
					analyticsEvent,
					persistentFocus: e.detail === 0 || undefined,
					openedFrom: 'meatball',
				});
			} else {
				closeMenu(scope);
			}
		},
		[closeMenu, isOpen, openMenu, scope],
	);

	const handleKeyDown = useCallback((e: React.KeyboardEvent) => {
		if (e.key === 'Enter') {
			e.stopPropagation();
		}
	}, []);

	return (
		<Popup
			isOpen={isOpen}
			placement="bottom-end"
			content={renderContent}
			shouldRenderToParent
			messageId="software-context-menu.ui.meatball-menu.popup"
			messageType="transactional"
			trigger={({ ref: popupRef, ...triggerProps }) => (
				<ContextualAnalyticsData attributes={{ inputSource: 'threeDot' }}>
					{isVisualRefreshEnabled() ? (
						<IconButton
							{...triggerProps}
							appearance="subtle"
							spacing={
								// eslint-disable-next-line no-nested-ternary
								isVisualRefreshEnabled() && fg('visual-refresh_drop_3')
									? 'compact'
									: isCompact
										? 'compact'
										: 'default'
							}
							testId={MEATBALL_MENU_TESTID}
							icon={() => <ShowMoreHorizontalIcon label="" color={token('color.icon')} />}
							onClick={onClick}
							onKeyDown={handleKeyDown}
							label={ariaLabel}
							ref={(r: HTMLButtonElement) => {
								if (popupRef) {
									// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
									(popupRef as React.RefCallback<HTMLButtonElement | null>)(r);
								}
								triggerRef.current = r;
							}}
						/>
					) : (
						<Button
							{...triggerProps}
							spacing={isCompact ? 'compact' : 'default'}
							testId={MEATBALL_MENU_TESTID}
							iconBefore={<MoreIcon spacing="spacious" label="" color={token('color.icon')} />}
							onClick={onClick}
							onKeyDown={handleKeyDown}
							aria-label={ariaLabel}
							ref={(r: HTMLButtonElement) => {
								if (popupRef) {
									// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
									(popupRef as React.RefCallback<HTMLButtonElement | null>)(r);
								}
								triggerRef.current = r;
							}}
						/>
					)}
				</ContextualAnalyticsData>
			)}
		/>
	);
};
