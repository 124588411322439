import React from 'react';
import type { PreloadedQuery } from 'react-relay';
import { SIMPLE_SOFTWARE_BOARD } from '@atlassian/jira-common-constants/src/analytics-sources.tsx';
import { ExperienceTracker as ViewExperienceTracker } from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracker-consumer/tracker-base/index.tsx';
import { ViewExperienceTrackingProvider } from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracking-provider/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import type { viewBoardCustomFiltersQuery } from '@atlassian/jira-relay/src/__generated__/viewBoardCustomFiltersQuery.graphql';
import { getApplicationForProject } from '@atlassian/jira-shared-types/src/application.tsx';
import FilterBar from '@atlassian/jira-software-filters/src/ui/main.tsx';
import type { APIProps as Props } from '@atlassian/jira-software-filters/src/ui/stateless/types.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { Capability } from '../../../../common/capability/index.tsx';
import {
	setCustomFilterInteractionFeedbackMark,
	setCustomFilterInteractionStartMark,
} from '../../../../services/performance/interaction-metric-custom-filter.tsx';
import {
	setInteractionFeedbackMark,
	setInteractionStartMark,
} from '../../../../services/performance/interaction-metric-filter.tsx';
import { useBoardSelector } from '../../../../state/index.tsx';
import { isIncrementPlanningReadOnly } from '../../../../state/selectors/board/board-permissions-selectors.tsx';
import {
	requestTypesSelector,
	statusesSelector,
} from '../../../../state/selectors/issue/issue-selectors.tsx';
import { projectTypeSelector } from '../../../../state/selectors/software/software-selectors.tsx';
import {
	useCapability,
	useIsCMPBoard,
	useIsIncrementPlanningBoard,
	useIsJSMBoard,
} from '../../../../state/state-hooks/capabilities/index.tsx';
import { useShouldShowUnassignedFilter } from '../../../../state/state-hooks/unassigned-filter-nudge/index.tsx';
import { BoardCustomFilter } from './board-custom-filters/index.tsx';
import { SLA_EXPERIENCE, SLA_LOCATION, EXPERIENCE_ID } from './constants.tsx';
import messages from './messages.tsx';

const defaultProps = { versions: [] } as const;

export type FilterBarWrapperProps = Props & {
	customFiltersQueryRef: PreloadedQuery<viewBoardCustomFiltersQuery> | null | undefined;
	isActiveStandup: boolean;
};

const FilterBarWrapper = ({
	customFiltersQueryRef,
	isActiveStandup,
	...props
}: FilterBarWrapperProps) => {
	const { formatMessage } = useIntl();
	const tenantContext = useTenantContext();
	const isCMPBoard = useIsCMPBoard();
	const isJSMBoard = useIsJSMBoard();
	const isIncrementPlanningBoard = useIsIncrementPlanningBoard();

	const isVersionsFeatureEnabled = useCapability(Capability.RENDER_VERSIONS_FILTER);
	const isComponentsFeatureEnabled = useCapability(Capability.RENDER_COMPONENTS_FILTER);
	const isStatusCategoriesFeatureEnabled = useCapability(
		Capability.RENDER_STATUS_CATEGORIES_FILTER,
	);

	const isIssueParentsFeatureEnabled = isIncrementPlanningBoard
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useCapability(Capability.RENDER_ISSUE_PARENTS_FILTER)
		: undefined;

	const isLabelsFeatureEnabled = useCapability(Capability.RENDER_LABELS_FILTER);

	const isIssueProjectsFeatureEnabled = isIncrementPlanningBoard
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useCapability(Capability.RENDER_ISSUE_PROJECTS_FILTER)
		: undefined;

	const isDependenciesFeatureEnabled = useCapability(Capability.RENDER_DEPENDENCIES_FILTER);

	const isIssueTypesFeatureEnabled = useCapability(Capability.RENDER_ISSUE_TYPES_FILTER);
	const isSprintsFeatureEnabled = useCapability(Capability.RENDER_SPRINTS_FILTER);
	const isRequestTypesFeatureEnabled = useCapability(Capability.RENDER_REQUEST_TYPES_FILTER);
	const isStatusesFeatureEnabled = useCapability(Capability.RENDER_STATUSES_FILTER);
	const projectType = useBoardSelector(projectTypeSelector);
	const requestTypes = useBoardSelector(requestTypesSelector);
	const statuses = useBoardSelector(statusesSelector);
	const isBoardSwitcherReadOnly = useBoardSelector(isIncrementPlanningReadOnly);
	const { shouldShowUnassignedFilterNudge } = fg('unassigned_filter_kill_switch')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useShouldShowUnassignedFilter()
		: { shouldShowUnassignedFilterNudge: false };

	return (
		<ViewExperienceTrackingProvider
			experience={SLA_EXPERIENCE}
			application={getApplicationForProject(projectType)}
			edition={tenantContext.appEditions.software}
			analyticsSource={SIMPLE_SOFTWARE_BOARD}
			experienceId={EXPERIENCE_ID}
			additionalAttributes={{
				isClassic: isCMPBoard,
				isUIF: true,
			}}
		>
			<ViewExperienceTracker location={SLA_LOCATION}>
				{({ onExperienceSuccess }) => (
					<>
						<FilterBar
							{...defaultProps}
							{...props}
							screenName="board"
							isCompanyManaged={isCMPBoard}
							isJSMBoard={isJSMBoard}
							showSearchFilterChangeboarding={!isCMPBoard}
							hijackBrowserDefaultSearch={!isCMPBoard}
							onChangeInteractionFeedback={setInteractionFeedbackMark}
							onChangeInteractionStart={setInteractionStartMark}
							onCustomFilterChangeInteractionFeedback={setCustomFilterInteractionFeedbackMark}
							onCustomFilterChangeInteractionStart={setCustomFilterInteractionStartMark}
							customFiltersQueryRef={customFiltersQueryRef}
							// @ts-expect-error - TS2345 - Type 'CustomFiltersQueryRef | undefined' is not assignable to type 'PreloadedQuery<viewBoardCustomFiltersQuery, Record<string, unknown>>'.
							CustomFilter={BoardCustomFilter}
							showAssigneeFilter={!isIncrementPlanningBoard && !isActiveStandup}
							showBoardSwitcher={isIncrementPlanningBoard}
							isBoardSwitcherReadOnly={isBoardSwitcherReadOnly}
							showCustomFilters={isCMPBoard}
							onExperienceSuccess={onExperienceSuccess}
							placeholder={
								isVisualRefreshEnabled()
									? formatMessage(messages.filterPlaceholder)
									: formatMessage(messages.textFilter)
							}
							screenReaderInstruction={formatMessage(
								expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
									? messages.screenReaderInstructionIssueTermRefresh
									: messages.screenReaderInstruction,
							)}
							statuses={statuses}
							requestTypes={requestTypes}
							isUnassignedAssigneeOptionEnabled
							isVersionsFeatureEnabled={isVersionsFeatureEnabled}
							isComponentsFeatureEnabled={isComponentsFeatureEnabled}
							isStatusCategoriesFeatureEnabled={isStatusCategoriesFeatureEnabled}
							isIssueParentsFeatureEnabled={isIssueParentsFeatureEnabled}
							isLabelsFeatureEnabled={isLabelsFeatureEnabled}
							isIssueProjectsFeatureEnabled={isIssueProjectsFeatureEnabled}
							isDependenciesFeatureEnabled={isDependenciesFeatureEnabled}
							isIssueTypesFeatureEnabled={isIssueTypesFeatureEnabled}
							isSprintsFeatureEnabled={isSprintsFeatureEnabled}
							isRequestTypesFeatureEnabled={isRequestTypesFeatureEnabled}
							isStatusesFeatureEnabled={isStatusesFeatureEnabled}
							shouldShowUnassignedFilterNudge={
								fg('board_unassigned_nudge_collision_fix')
									? !props.isAnyModalOpen && shouldShowUnassignedFilterNudge
									: shouldShowUnassignedFilterNudge
							}
						/>
					</>
				)}
			</ViewExperienceTracker>
		</ViewExperienceTrackingProvider>
	);
};

export default FilterBarWrapper;
