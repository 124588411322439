import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const asyncIssueCloneModalEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "async-issue-clone-modal" */ './async').then(
			({ AsyncCloneModal }) => AsyncCloneModal,
		),
	),
	getPreloadProps: () => ({}),
});
