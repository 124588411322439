import { toAriOrDefault } from '@atlassian/jira-platform-ari/src/index.tsx';
import { toProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createHook,
	createStore,
	createContainer,
	type StoreActionApi,
} from '@atlassian/react-sweet-state';
import type { MenuContext } from './types.tsx';

const initialState: MenuContext = {
	canManageAutomations: false,
	projectAri: toAriOrDefault(''),
	projectId: toProjectId(''),
	baseAutomationUrl: '',
};

const actions = {
	setMenuContext:
		(menuContext: MenuContext) =>
		({ setState }: StoreActionApi<MenuContext>) =>
			setState(menuContext),
} as const;

const Store = createStore({ initialState, actions, name: 'automation-menu.menu-context' });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const MenuContextContainer = createContainer<MenuContext, typeof actions, Record<any, any>>(
	Store,
);

export const useMenuContext = createHook(Store);

export const useMenuContextActions = createHook(Store, {
	selector: null,
});

const getCanManageAutomations = (state: MenuContext) => state.canManageAutomations;
export const useCanManageAutomations = createHook(Store, {
	selector: getCanManageAutomations,
});

const getProjectAri = (state: MenuContext) => state.projectAri;
export const useProjectAri = createHook(Store, {
	selector: getProjectAri,
});

const getBaseAutomationUrl = (state: MenuContext) => state.baseAutomationUrl;
export const useBaseAutomationUrl = createHook(Store, {
	selector: getBaseAutomationUrl,
});
