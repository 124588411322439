import React, { useCallback } from 'react';
import { useCloneService } from '@atlassian/jira-clone-issue/src/services/clone/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useProjectKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import { useProjectType } from '@atlassian/jira-project-context-service/src/main.tsx';
import {
	type MenuNode,
	MenuNodeType,
} from '@atlassian/jira-software-context-menu/src/common/types.tsx';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';
import { asyncIssueCloneModalEntryPoint } from '@atlassian/jira-clone-issue-dialog/src/entrypoint.tsx';
import { ContextMenuItemButton } from '@atlassian/jira-software-context-menu/src/ui/menu-renderer/context-menu-inner/context-menu-node/context-menu-item/context-menu-item-button/index.tsx';

import { CAN_CREATE_CARDS } from '../../../../../../../../model/permission/permission-types.tsx';
import { useBoardSelector } from '../../../../../../../../state/index.tsx';
import { getPermissionsSelector } from '../../../../../../../../state/selectors/board/board-permissions-selectors.tsx';
import { getIssueById } from '../../../../../../../../state/selectors/issue/issue-selectors.tsx';
import { CLONE_ISSUE_MENU_ID } from '../../constants.tsx';
import messages from '../../messages.tsx';
import type { ContentProps, Props } from './types.tsx';

export const useCloneIssueContent = ({
	issueId,
	issueKey,
	isCloneEnabled,
	onCloneIssue,
}: ContentProps): MenuNode[] => {
	const { formatMessage } = useIntl();

	if (!isCloneEnabled) return [];

	if (fg('jira_context_menu_clone_dialog')) {
		return [
			{
				type: MenuNodeType.Node,
				id: CLONE_ISSUE_MENU_ID,
				node: ({ closeMenu }) => (
					<ModalEntryPointPressableTrigger
						entryPoint={asyncIssueCloneModalEntryPoint}
						entryPointProps={{
							issueKey,
							issueId: issueId.toString(),
						}}
						interactionName={CLONE_ISSUE_MENU_ID}
						useInternalModal={false}
					>
						{({ ref }) => (
							<ContextMenuItemButton
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								ref={ref as unknown as React.Ref<HTMLButtonElement>}
								menuItem={{
									type: MenuNodeType.Item,
									id: `${CLONE_ISSUE_MENU_ID}-item`,
									label: formatMessage(messages.clone),
								}}
								onClick={closeMenu}
								hasChildren={false}
							/>
						)}
					</ModalEntryPointPressableTrigger>
				),
			},
		];
	}

	return [
		{
			type: MenuNodeType.Item,
			label: formatMessage(messages.clone),
			id: CLONE_ISSUE_MENU_ID,
			onClick: onCloneIssue,
		},
	];
};

export const useCloneIssue = ({ issueKey, issueId }: Props) => {
	const canClone = useBoardSelector(getPermissionsSelector)[CAN_CREATE_CARDS];
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const projectKey = useProjectKey();
	const projectType = useProjectType(projectKey);
	const [, makeCloneRequest] = useCloneService(issueKey, projectType || null);
	const issue = useBoardSelector((state) => getIssueById(state, issueId));

	const onCloneIssue = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'cloneIssue');

		if (fg('jira_context_menu_clone_dialog')) {
			return;
		}

		makeCloneRequest({
			summary: `CLONE - ${issue.summary}`,
		});
	}, [createAnalyticsEvent, makeCloneRequest, issue.summary]);

	return useCloneIssueContent({
		issueId,
		issueKey,
		isCloneEnabled: canClone,
		onCloneIssue,
	});
};
