import type { Action } from '../../../actions/index.tsx';
import {
	SET_INLINE_CREATE_SESSION_ID,
	CLEAR_INLINE_CREATE_SESSION_ID,
	CLEAR_SPECIFIED_INLINE_CREATE_SESSION_ID,
} from '../../../actions/inline-create/index.tsx';
import type { InlineCreateState } from './types.tsx';

const defaultState: InlineCreateState = {
	sessionId: undefined,
};

export const inlineCreateReducer = (
	state: InlineCreateState = defaultState,
	action: Action,
): InlineCreateState => {
	switch (action.type) {
		case SET_INLINE_CREATE_SESSION_ID:
			return { ...state, sessionId: action.payload.sessionId };
		case CLEAR_INLINE_CREATE_SESSION_ID:
			return { ...state, sessionId: undefined };

		case CLEAR_SPECIFIED_INLINE_CREATE_SESSION_ID:
			if (state.sessionId === action.payload.sessionId) {
				return { ...state, sessionId: undefined };
			}
			return state;
		default:
			return state;
	}
};
