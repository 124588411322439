/** @jsx jsx */
import { styled as styled2, css, jsx } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { SimpleTag as Tag } from '@atlaskit/tag';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { memoizeGetAutoGeneratedColorForLabels } from '@atlassian/jira-option-color-picker/src/option-color-generator/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { getColorMetadata } from '@atlassian/jira-option-color-picker/src/color-picker/utils/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { CardLabelProps, ContainerProps } from './types.tsx';

export const CardLabel = (props: CardLabelProps) => {
	const { text, highlight } = props;
	const isSelected = !!highlight && highlight.includes(text);

	if (expVal('adding-color-to-labels-field-in-jira-experiment', 'isColoredLabelsEnabled', false)) {
		const { backgroundColor: borderColor } = getColorMetadata(
			memoizeGetAutoGeneratedColorForLabels(text),
		);

		return (
			<span
				css={tagBorderStyles}
				// eslint-disable-next-line jira/react/no-style-attribute, @typescript-eslint/consistent-type-assertions
				style={{ '--tag-border-color': borderColor } as React.CSSProperties}
			>
				<Tag text={text} />
			</span>
		);
	}

	if (isVisualRefreshEnabled()) {
		if (fg('visual-refresh_drop_6')) {
			return <Tag text={text} color={isSelected ? 'blue' : 'standard'} />;
		}

		return (
			<Box
				as="span"
				xcss={[ContainerStyles, ContainStylesExtra, isSelected && ContainerHighlightStyles]}
			>
				{text}
			</Box>
		);
	}

	return (
		<Container isSelected={isSelected}>
			<Content>{text}</Content>
		</Container>
	);
};

CardLabel.defaultProps = {
	highlight: [],
};

export default CardLabel;

const tagBorderStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'> span': {
		backgroundColor: 'transparent',
		borderStyle: 'solid',
		borderWidth: '1px',
		borderColor: 'var(--tag-border-color)',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'> span > a': {
		color: token('color.text'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'> span > a:hover': {
		color: token('color.text'),
	},
});

const ContainerStyles = xcss({
	margin: 'space.050',
	cursor: 'default',
	font: 'font.body',
	overflow: 'hidden',
	borderRadius: '3px',
	border: '1px solid',
	borderColor: 'color.border',
});

// Combine 2 styles when clean up FG visual-refresh
const ContainStylesExtra = xcss({
	paddingBlock: 'space.025',
	paddingInline: 'space.050',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

const ContainerHighlightStyles = xcss({
	backgroundColor: 'color.background.neutral',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled2.span<ContainerProps>({
	display: 'inline-flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 2.5}px`,
	marginTop: token('space.050'),
	marginRight: token('space.050'),
	marginBottom: token('space.050'),
	marginLeft: token('space.050'),
	padding: 0,
	position: 'relative',
	cursor: 'default',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: 1,
	overflow: 'hidden',
	borderRadius: '3px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: (props: ContainerProps) =>
		props.isSelected
			? token('color.background.accent.blue.subtler')
			: token('color.background.neutral'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: (props) => (props.isSelected ? token('color.text.accent.blue') : token('color.text')),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Content = styled2.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 22.5}px`,
	paddingTop: token('space.025'),
	paddingRight: token('space.050'),
	paddingBottom: token('space.025'),
	paddingLeft: token('space.050'),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});
