import { PURPLE } from '@atlassian/jira-issue-epic-color-types/src/types.tsx';
import { toPackageName } from '@atlassian/jira-project-settings-apps-common/src/consts.tsx';

export const PACKAGE_NAME = toPackageName('jiraParentSwitcherField');

export const DEFAULT_EPIC_COLOR = PURPLE;
export const MAX_SUGGESTIONS = 5;
export const MENU_WIDTH = 345;
export const MENU_EMPTY_WIDTH = 180;
export const LONG_LABEL_LIMIT = 40;

// The following are test ids
export const PARENT_SWITCHER_LOZENGE_TEST_ID =
	'issue-field-parent-switcher.common.ui.epic-lozenge.epic-lozenge';
export const PARENT_SWITCHER_TAG_TEST_ID = 'issue-field-parent-switcher.common.ui.epic-tag.tag';
export const PARENT_SWITCHER_LOZENGE_SELECTOR = `[data-testid="${PARENT_SWITCHER_LOZENGE_TEST_ID}"]`;

export const PARENT_SWITCHER_LOADING_SPINNER_TEST_ID =
	'issue-field-parent-switcher.ui.edit.suggested-parents-dropdown.loading-content.parent-switcher-suggested-parents-loading-spinner';

export const PARENT_SWITCHER_SUGGESTED_PARENTS_ACTIONS_TEST_ID =
	'issue-field-parent-switcher.ui.edit.suggested-parents-dropdown.actions';
export const PARENT_SWITCHER_LABEL_TEST_ID = 'issue-field-parent.edit-view.options.';
export const PARENT_SWITCHER_ADD_PARENT_BUTTON_TEST_ID =
	'issue-field-parent-switcher.ui.edit.suggested-parents-dropdown.add-parent-button';

// Footer Actions Test ID
export const PARENT_SWITCHER_SUGGESTED_PARENTS_REMOVE_PARENT_TEST_ID =
	'issue-field-parent-switcher.ui.edit.suggested-parents-dropdown.remove-parent';
export const PARENT_SWITCHER_SUGGESTED_PARENTS_REMOVE_PARENT_SELECTOR = `[data-testid="${PARENT_SWITCHER_SUGGESTED_PARENTS_REMOVE_PARENT_TEST_ID}"]`;

export const PARENT_SWITCHER_SUGGESTED_PARENTS_VIEW_ALL_PARENTS_TEST_ID =
	'issue-field-parent-switcher.ui.edit.view-all-parents';
export const PARENT_SWITCHER_SUGGESTED_PARENTS_VIEW_ALL_PARENTS_SELECTOR = `[data-testid="${PARENT_SWITCHER_SUGGESTED_PARENTS_VIEW_ALL_PARENTS_TEST_ID}"]`;

// Empty Message Test ID
export const PARENT_SWITCHER_SUGGESTED_PARENTS_EMPTY_TEST_ID =
	'issue-field-parent-switcher.ui.edit.empty-container';
export const PARENT_SWITCHER_SUGGESTED_PARENTS_EMPTY_SELECTOR = `[data-testid="${PARENT_SWITCHER_SUGGESTED_PARENTS_EMPTY_TEST_ID}"]`;

export const PARENT_SWITCHER_OPEN_ISSUE_CREATE_LINK_TEST_ID =
	'issue-field-parent-switcher.ui.edit.suggested-parents-dropdown.empty-content.open-issue-create';
