import React, { Component } from 'react';
import { styled } from '@compiled/react';
import Button, { ButtonGroup } from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import { token } from '@atlaskit/tokens';
import { JiraInlineDialog as InlineDialog } from '@atlassian/jira-inline-dialog/src/ui/jira-inline-dialog.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import { sharedStyles } from '@atlassian/jira-platform-board-kit/src/index.tsx';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from './messages.tsx';

type Props = {
	isOpen: boolean;
} & Intl & {
		onCancel: () => void;
		onConfirm: () => void;
	};

// eslint-disable-next-line jira/react/no-class-components
export class BacklogIssueMoveDialog extends Component<Props> {
	// Strip arguments from event handlers
	onConfirm = () => this.props.onConfirm();

	onCancel = () => this.props.onCancel();

	renderContent() {
		const { intl } = this.props;

		return (
			<Container>
				<Heading size="xsmall" as="h5">
					{intl.formatMessage(
						expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? messages.titleIssueTermRefresh
							: messages.title,
					)}
				</Heading>
				<DescriptionStyle>
					{intl.formatMessage(
						expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? messages.descriptionIssueTermRefresh
							: messages.description,
					)}
				</DescriptionStyle>
				<ButtonGroup>
					<Button onClick={this.onConfirm} appearance="primary">
						{intl.formatMessage(messages.confirm)}
					</Button>
					<Button onClick={this.onCancel} appearance="subtle">
						{intl.formatMessage(messages.cancel)}
					</Button>
				</ButtonGroup>
			</Container>
		);
	}

	render() {
		return this.props.isOpen ? (
			<StickyPosition>
				<InlineDialog
					messageId="software-board.board-container.board.backlog-issue-move-dialog.inline-dialog"
					messageType="transactional"
					content={this.renderContent()}
					isOpen
				>
					<div />
				</InlineDialog>
			</StickyPosition>
		) : null;
	}
}

export default injectIntl(BacklogIssueMoveDialog);

const columnDialogZindex = sharedStyles.zIndex.stickyHeaders + 1;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StickyPosition = styled.div({
	position: 'relative',
	top: token('space.200'),

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	left: `${sharedStyles.columnWithMarginWidth / 2}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div': {
		position: 'sticky',
		top: token('space.200'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		zIndex: columnDialogZindex,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	whiteSpace: 'normal',
	font: token('font.body'),
	width: '182px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DescriptionStyle = styled.p({
	marginBottom: token('space.200'),
});
