import React from 'react';
import { SkeletonItem } from '@atlaskit/menu';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import Skeleton from '@atlaskit/skeleton';
import { token } from '@atlaskit/tokens';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import type { PopupHeight, PopupWidth } from '../types.tsx';
import SelectFallbackSkeleton from './select-fallback-skeleton/index.tsx';

const POPUP_HEIGHT: PopupHeight = '380px';
const POPUP_WIDTH: PopupWidth = '600px';

export const FallbackSkeleton = () => {
	return (
		<UFOLoadHold name="filter-popup-fallback">
			<Box xcss={fallbackContentStyles}>
				<Inline xcss={FullHeightStyle}>
					<Box xcss={leftBoxStyles}>
						<Box xcss={flexGrowStyles}>
							{Array.from({ length: 4 }, (_, index) => (
								<SkeletonItem isShimmering key={index} width="120px" />
							))}
						</Box>
						<Box xcss={clearAllStyles}>
							<Skeleton isShimmering width="65px" height="9px" />
						</Box>
					</Box>
					<Box xcss={rightBoxStyles}>
						<SelectFallbackSkeleton />
					</Box>
				</Inline>
			</Box>
		</UFOLoadHold>
	);
};

export default FallbackSkeleton;

const flexGrowStyles = xcss({
	flex: 1,
});

const clearAllStyles = xcss({
	paddingLeft: 'space.300',
	paddingBottom: 'space.100',
});

const fallbackContentStyles = xcss({
	paddingBlock: 'space.250',
	// keep height and width the same as popup content
	height: POPUP_HEIGHT,
	width: POPUP_WIDTH,
});

const leftBoxStyles = xcss({
	paddingInline: 'space.250',
	width: '240px',
	display: 'flex',
	flexDirection: 'column',
});

const rightBoxStyles = xcss({
	paddingInline: 'space.250',
	width: '360px',
	display: 'flex',
	flexDirection: 'column',
	borderLeft: `1px solid ${token('color.border')}`,
});

const FullHeightStyle = xcss({
	// the grey border line right needs to fill full height
	alignItems: 'stretch',
	height: '100%',
});
