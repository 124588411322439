import type { JiraJqlScopeInput } from '@atlassian/jira-relay/src/__generated__/FilterPopupContentQuery.graphql';
import parameters from '@atlassian/jira-relay/src/__generated__/ui_filterRefinementBasicFieldsQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

type EntryPointParams = {
	cloudId: string;
	jqlContext: string;
	scope?: JiraJqlScopeInput;
	onFeedbackClick?: (isOpen: boolean) => void;
};

export const filterPopupContentEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(/* webpackChunkName: "async-jira-filter-refinement-filter-popup-content" */ './ui'),
	),
	getPreloadProps: ({ cloudId, jqlContext, scope, onFeedbackClick }: EntryPointParams) => ({
		queries: {
			filterRefinementBasicFields: {
				options: {
					fetchPolicy: 'store-or-network' as const,
				},
				parameters,
				variables: {
					cloudId,
					jqlContext,
					scope,
				},
			},
		},
		extraProps: {
			scope,
			onFeedbackClick,
		},
	}),
});
