import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	sprintGoalEllipsed: {
		id: 'portfolio-3-plan-increment-common.ui.sprint-column-header.sprint-capacity-flyout.sprint-goal-ellipsed',
		defaultMessage: '{goal}...',
		description: 'Sprint goal text with an ellipsis to indicate that the text is truncated',
	},
	titleForSprintCapacityFlyout: {
		id: 'portfolio-3-plan-increment-common.ui.sprint-column-header.sprint-capacity-flyout.title-for-sprint-capacity-flyout',
		defaultMessage: 'SPRINT',
		description: 'Title for the sprint capacity flyout',
	},
	sprintCapacityLabel: {
		id: 'portfolio-3-plan-increment-common.ui.sprint-column-header.sprint-capacity-flyout.sprint-capacity-label',
		defaultMessage: 'Sprint capacity ({planningUnit})',
		description: 'Label for the sprint capacity',
	},
	sprintPointsAllocatedLabel: {
		id: 'portfolio-3-plan-increment-common.ui.sprint-column-header.sprint-capacity-flyout.sprint-points-allocated-label',
		defaultMessage:
			'{numerator}/{denominatorCount, plural, one {# point allocated} other {# points allocated}}',
		description: 'Label for the sprint points allocated',
	},
	sprintPointsDoneLabel: {
		id: 'portfolio-3-plan-increment-common.ui.sprint-column-header.sprint-capacity-flyout.sprint-points-done-label',
		defaultMessage:
			'{numerator}/{denominatorCount, plural, one {# point done} other {# points done}}',
		description: 'Label for the sprint points done',
	},
	sprintDaysAllocatedLabel: {
		id: 'portfolio-3-plan-increment-common.ui.sprint-column-header.sprint-capacity-flyout.sprint-days-allocated-label',
		defaultMessage:
			'{numerator}/{denominatorCount, plural, one {# day allocated} other {# days allocated}}',
		description: 'Label for the sprint days allocated',
	},
	sprintDaysDoneLabel: {
		id: 'portfolio-3-plan-increment-common.ui.sprint-column-header.sprint-capacity-flyout.sprint-days-done-label',
		defaultMessage: '{numerator}/{denominatorCount, plural, one {# day done} other {# days done}}',
		description: 'Label for the sprint days done',
	},
	sprintHoursAllocatedLabel: {
		id: 'portfolio-3-plan-increment-common.ui.sprint-column-header.sprint-capacity-flyout.sprint-hours-allocated-label',
		defaultMessage:
			'{numerator}/{denominatorCount, plural, one {# hour allocated} other {# hours allocated}}',
		description: 'Label for the sprint hours allocated',
	},
	sprintHoursDoneLabel: {
		id: 'portfolio-3-plan-increment-common.ui.sprint-column-header.sprint-capacity-flyout.sprint-hours-done-label',
		defaultMessage:
			'{numerator}/{denominatorCount, plural, one {# hour done} other {# hours done}}',
		description: 'Label for the sprint hours done',
	},
	sprintStartDateLabel: {
		id: 'portfolio-3-plan-increment-common.ui.sprint-column-header.sprint-capacity-flyout.sprint-start-date-label',
		defaultMessage: 'Sprint start date',
		description: 'Label for the sprint start date',
	},
	sprintEndDateLabel: {
		id: 'portfolio-3-plan-increment-common.ui.sprint-column-header.sprint-capacity-flyout.sprint-end-date-label',
		defaultMessage: 'Sprint end date',
		description: 'Label for the sprint end date',
	},
	sprintDatesMissing: {
		id: 'portfolio-3-plan-increment-common.ui.sprint-column-header.sprint-capacity-flyout.sprint-dates-missing',
		defaultMessage:
			"{sprintName} has no start or end date. We've inferred dates based on your program board.",
		description: 'Sprint dates missing',
	},
	sprintCapacityOverviewLabel: {
		id: 'portfolio-3-plan-increment-common.ui.sprint-column-header.sprint-capacity-flyout.sprint-capacity-overview-label',
		defaultMessage: 'Sprint capacity overview',
		description: 'Label for the sprint overview icon',
	},
	sprintStartDateInfoIconLabel: {
		id: 'portfolio-3-plan-increment-common.ui.sprint-column-header.sprint-capacity-flyout.sprint-start-date-info-icon-label',
		defaultMessage: 'Sprint start date info',
		description: 'Label for the sprint start date icon',
	},
	sprintEndDateInfoIconLabel: {
		id: 'portfolio-3-plan-increment-common.ui.sprint-column-header.sprint-capacity-flyout.sprint-end-date-info-icon-label',
		defaultMessage: 'Sprint end date info',
		description: 'Label for the sprint end date icon',
	},
	closeIconLabel: {
		id: 'portfolio-3-plan-increment-common.ui.sprint-column-header.sprint-capacity-flyout.close-icon-label',
		defaultMessage: 'Close',
		description: 'Label for the close icon',
	},
});
