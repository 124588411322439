import React from 'react';
import { styled } from '@compiled/react';
import { Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage } from '@atlassian/jira-intl';

import { emptyState } from './icons/main.tsx';
import messages from './messages.tsx';

const isMacOS = (platform: string): boolean => /Mac/i.test(platform);

const getSelectKey = (): string => {
	if (
		fg('jfp-magma-undefined-navigator')
			? isMacOS(globalThis.navigator?.platform || '')
			: isMacOS(
					(typeof window !== 'undefined' &&
						window &&
						window.navigator && // eslint-disable-line jira/jira-ssr/no-unchecked-globals-usage
						window.navigator.platform) || // eslint-disable-line jira/jira-ssr/no-unchecked-globals-usage
						'',
				)
	) {
		return '⌘';
	}
	return 'Ctrl';
};

export const NoIssueSelectedEmptyState = () => (
	<Container>
		<EmptyState>{emptyState}</EmptyState>
		<Message>
			<FormattedMessage
				{...messages.noIssuesSelected}
				values={{
					ctrl: () => <Text as="strong">{getSelectKey()}</Text>,
					click: (chunks) => <Text as="strong">{chunks}</Text>,
				}}
			/>
		</Message>
	</Container>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 43.5}px`,
	paddingBottom: token('space.050'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyState = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 26.5}px`,
	marginTop: token('space.100'),
	marginRight: token('space.100'),
	marginBottom: token('space.100'),
	marginLeft: token('space.100'),
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Message = styled.div({
	paddingTop: token('space.150'),
	paddingRight: token('space.150'),
	paddingBottom: token('space.150'),
	paddingLeft: token('space.150'),
	textAlign: 'center',
	whiteSpace: 'wrap',
});
