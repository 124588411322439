import { useRef, useMemo, type MutableRefObject } from 'react';
import { UNSCHEDULED_COLUMN_ID } from '@atlassian/jira-common-constants/src/column-types.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useBoardSelector } from '../../../../../../state/index.tsx';
import { getActiveInlineEditCardIds } from '../../../../../../state/selectors/card/card-selectors.tsx';
import { isDependencyVisualisationVisible as isDependencyVisualisationVisibleSelector } from '../../../../../../state/selectors/issue-link/issue-link-selectors.tsx';
import { hasInlineCreateSessionId as hasInlineCreateSessionIdSelector } from '../../../../../../state/selectors/inline-create/inline-create-selectors.tsx';
import { useIsIncrementPlanningBoard } from '../../../../../../state/state-hooks/capabilities/index.tsx';

// @ts-expect-error - TS2322 - Type 'Element | null' is not assignable to type 'HTMLElement | null'.

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
export const getActiveElement = (): HTMLElement | null => document.activeElement;

export const useIccRef = () => useRef<HTMLDivElement>(null);

export const isIccIntersectingWithHeader = (
	headerRefObject: MutableRefObject<HTMLDivElement | null>,
	iccRefObject: MutableRefObject<HTMLDivElement | null>,
	isFirstCard: boolean,
): boolean => {
	// We allow a small amount of margin if the ICC is above the first card because the header is overlapping it by default
	// we still want to allow the ICC to be rendered in this case.
	const FIRST_CARD_ICC_MARGIN = 5;

	if (headerRefObject.current && iccRefObject.current) {
		const headerRect = headerRefObject.current.getBoundingClientRect();
		const iccRect = iccRefObject.current.getBoundingClientRect();

		return !(
			(isFirstCard ? headerRect.bottom - FIRST_CARD_ICC_MARGIN : headerRect.bottom) < iccRect.top
		);
	}
	return false;
};

export const useShouldRenderCardInPopper = ({
	columnId,
	cardId,
	isScrolling,
}: {
	columnId: number;
	cardId: IssueId;
	isScrolling: boolean;
}): boolean => {
	const isIPBoard = useIsIncrementPlanningBoard();
	const isUnscheduledColumn = columnId === UNSCHEDULED_COLUMN_ID;
	const isIPBoardScheduledColumns = isIPBoard && !isUnscheduledColumn;
	const activeInlineEditCardIds = useBoardSelector(getActiveInlineEditCardIds);
	const isCardInInlineEditing = activeInlineEditCardIds.includes(String(cardId));
	const isDependencyVisualisationVisible = useBoardSelector(
		isDependencyVisualisationVisibleSelector,
	);
	const hasInlineCreateSessionId = useBoardSelector(hasInlineCreateSessionIdSelector);

	return useMemo(() => {
		if (
			!isDependencyVisualisationVisible ||
			!isIPBoardScheduledColumns ||
			!fg('render_program_board_card_in_popup')
		) {
			return false;
		}

		const noOtherActiveCard =
			(activeInlineEditCardIds.length === 0 || isCardInInlineEditing) && !hasInlineCreateSessionId;

		return !isScrolling && noOtherActiveCard;
	}, [
		isDependencyVisualisationVisible,
		isIPBoardScheduledColumns,
		isScrolling,
		activeInlineEditCardIds,
		isCardInInlineEditing,
		hasInlineCreateSessionId,
	]);
};
