import { ERROR_BOUNDARY_HIT } from '@atlassian/jira-insights-common-constants/src/common/constants/analytics.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { BoundActions } from '@atlassian/react-sweet-state';
import type { StoreApi, StoreContainerProps } from '../types.tsx';
import { initialState } from './initial-state.tsx';

const success =
	(experienceId: string) =>
	(
		{ getState, setState }: StoreApi,
		{ metricsTracker, experienceIdsToCollect, ufoExperience }: StoreContainerProps,
	) => {
		const state = getState();

		const currentStatus = state.status;

		if (!experienceId) {
			return;
		}

		if (!currentStatus.hasStarted || currentStatus.hasCompleted || currentStatus.hasTimedOut) {
			return;
		}

		if (!experienceIdsToCollect.includes(experienceId)) {
			return;
		}

		if (state.eventsSuccess.includes(experienceId)) {
			return;
		}

		if (ufoExperience != null) ufoExperience.mark(`${experienceId}_success`);

		const nextEventsSuccess = [...state.eventsSuccess, experienceId];

		const shouldSetHasCompleted =
			nextEventsSuccess.length === experienceIdsToCollect.length && !currentStatus.hasCompleted;

		const nextState = {
			status: shouldSetHasCompleted
				? {
						...currentStatus,
						hasCompleted: true,
					}
				: currentStatus,
			timeoutId: state.timeoutId,
			eventsSuccess: [...state.eventsSuccess, experienceId],
			eventsFail: state.eventsFail,
		};

		setState(nextState);

		if (shouldSetHasCompleted) {
			if (state.timeoutId) {
				clearTimeout(state.timeoutId);
			}

			metricsTracker.end();
		}
	};

const fail =
	(experienceId: string, error: Error) =>
	(
		{ getState, setState }: StoreApi,
		{ metricsTracker, experienceIdsToCollect, ufoExperience }: StoreContainerProps,
	) => {
		const state = getState();

		const currentStatus = state.status;

		if (!experienceId) {
			return;
		}

		if (!currentStatus.hasStarted || currentStatus.hasCompleted) {
			return;
		}

		if (
			!experienceIdsToCollect.includes(experienceId) &&
			// Panel level error boundary hits do not pass in an experienceId,
			// so we accept the constant "error-boundary-hit" in addition to the experienceIds
			// Note: Insight level error boundary hits do pass in experienceId
			experienceId !== ERROR_BOUNDARY_HIT
		) {
			return;
		}

		if (state.timeoutId) {
			clearTimeout(state.timeoutId);
		}

		if (ufoExperience != null) ufoExperience.mark(`${experienceId}_fail`);

		const nextEventsFail = [...state.eventsFail, experienceId];

		const nextState = {
			status: {
				...currentStatus,
				hasFailed: true,
			},
			timeoutId: state.timeoutId,
			eventsSuccess: state.eventsSuccess,
			eventsFail: nextEventsFail,
		};

		setState(nextState);
		metricsTracker.cancel(error);
	};

const timeout =
	() =>
	({ getState, setState }: StoreApi, { metricsTracker }: StoreContainerProps) => {
		const state = getState();

		const currentStatus = state.status;

		if (!currentStatus.hasStarted || currentStatus.hasCompleted || currentStatus.hasFailed) {
			return;
		}

		const nextState = {
			status: {
				...currentStatus,
				hasFailed: true,
				hasTimedOut: true,
			},
			eventsSuccess: state.eventsSuccess,
			eventsFail: state.eventsFail,
		};

		setState(nextState);

		metricsTracker.cancel();
	};

const start =
	() =>
	(
		{ getState, setState, dispatch }: StoreApi,
		{ timeoutInMs, metricsTracker, experienceIdsToCollect }: StoreContainerProps,
	) => {
		const state = getState();

		const currentStatus = state.status;

		if (
			currentStatus.hasCompleted ||
			currentStatus.hasStarted ||
			currentStatus.hasFailed ||
			currentStatus.hasTimedOut ||
			experienceIdsToCollect.length === 0
		) {
			return;
		}

		const timeoutId = setTimeout(() => {
			dispatch(timeout());
		}, timeoutInMs);

		const nextState = {
			status: {
				...currentStatus,
				hasStarted: true,
			},
			timeoutId,
			eventsSuccess: [],
			eventsFail: [],
		};

		// @ts-expect-error - TS2345 - Argument of type '{ status: { hasStarted: boolean; hasFailed: boolean; hasCompleted: boolean; hasTimedOut: boolean; }; timeoutId: NodeJS.Timeout; eventsSuccess: never[]; eventsFail: never[]; }' is not assignable to parameter of type 'Partial<State>'.
		setState(nextState);

		metricsTracker.start();
	};

const reset =
	() =>
	({ getState, setState }: StoreApi, { metricsTracker }: StoreContainerProps) => {
		const state = getState();

		if (state.timeoutId) {
			clearTimeout(state.timeoutId);
		}

		const currentStatus = state.status;

		if (
			currentStatus.hasStarted &&
			!currentStatus.hasCompleted &&
			!currentStatus.hasFailed &&
			!currentStatus.hasTimedOut
		) {
			metricsTracker.cancel();
		}

		// @ts-expect-error - TS2345 - Argument of type '{ readonly status: { readonly hasFailed: false; readonly hasCompleted: false; readonly hasStarted: false; readonly hasTimedOut: false; }; readonly timeoutId: null; readonly eventsSuccess: readonly []; readonly eventsFail: readonly []; }' is not assignable to parameter of type 'Partial<State>'.
		setState(initialState);
	};

export const actions = {
	reset,
	start,
	timeout,
	success,
	fail,
} as const;

export type Actions = typeof actions;

// @ts-expect-error - TS2314 - Generic type 'BoundActions' requires 2 type argument(s).
export type BoundedActions = BoundActions<Actions>;
