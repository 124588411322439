import React, { memo, useCallback, type RefObject } from 'react';
import type { ProjectType } from '@atlassian/jira-common-constants/src/project-types.tsx';
import {
	ENTRYPOINT_ID_JIRA_PLATFORM,
	EntryKeys,
} from '@atlassian/jira-feedback-collector/src/constants.tsx';
import { useFeedbackCollectorActions } from '@atlassian/jira-feedback-collector/src/controllers/index.tsx';
import type { FeedbackCollectorProps } from '@atlassian/jira-feedback-collector/src/types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import messages from './messages.tsx';
import { licensedProductsToString } from './utils.tsx';

export const FeedbackContactLabel = memo(() => {
	const { formatMessage } = useIntl();

	return (
		// eslint-disable-next-line @atlaskit/design-system/use-primitives-text
		<p>
			{formatMessage(messages.canContactPrompt)}{' '}
			<a
				href="https://www.atlassian.com/legal/privacy-policy"
				target="_blank"
				rel="noopener noreferrer"
			>
				{formatMessage(messages.canContactPromptLink)}
			</a>
		</p>
	);
});

export const FeedbackResearchLabel = memo(() => {
	const { formatMessage } = useIntl();

	return (
		// eslint-disable-next-line @atlaskit/design-system/use-primitives-text
		<p>
			{formatMessage(messages.enrollInResearchPrompt, {
				link: (
					<a
						href="https://www.atlassian.com/research-group"
						target="_blank"
						rel="noopener noreferrer"
					>
						{formatMessage(messages.enrollInResearchLink)}
					</a>
				),
			})}
		</p>
	);
});

export const useFeedbackCollector = ({
	projectType = 'business',
}: { projectType?: ProjectType } = {}) => {
	const { openFeedbackCollector } = useFeedbackCollectorActions();
	const { licensedProducts } = useTenantContext();
	const route = useCurrentRoute();
	const { data: project } = useProjectContext();
	const isAdmin = useIsAdmin();

	const getFeedbackContext = useCallback(
		(feedbackGroupId: string) => {
			const baseFeedbackContext: FeedbackCollectorProps['feedbackContext'] = [
				{
					id: EntryKeys.INLINE_FEEDBACK_ID,
					value: feedbackGroupId,
				},
				{
					id: EntryKeys.ROUTE_NAME,
					value: route.name ?? 'unknown',
				},
				{
					id: EntryKeys.ROUTE_GROUP,
					value: route.group ?? 'unknown',
				},
				{
					id: EntryKeys.IS_ADMIN,
					value: isAdmin,
				},
				{
					id: EntryKeys.PROJECT_TYPE,
					value: project?.projectType || projectType,
				},
				{
					id: EntryKeys.SUPPORTED_PROJECT_TYPES,
					value: licensedProductsToString(licensedProducts),
				},
			];

			// Only send project specific information if project is available
			if (project) {
				baseFeedbackContext.push({
					id: EntryKeys.IS_SIMPLIFIED_PROJECT,
					value: project.simplified,
				});
			}

			return baseFeedbackContext;
		},
		[isAdmin, licensedProducts, project, projectType, route.group, route.name],
	);

	return useCallback(
		(feedbackGroupId: string, onClose?: () => void, triggerRef?: RefObject<HTMLButtonElement>) => {
			const feedbackContext = getFeedbackContext(feedbackGroupId);
			openFeedbackCollector({
				entrypointId: ENTRYPOINT_ID_JIRA_PLATFORM,
				feedbackGroupId,
				canBeContactedLabel: <FeedbackContactLabel />,
				enrolInResearchLabel: <FeedbackResearchLabel />,
				feedbackContext,
				onClose,
				...(triggerRef != null ? { triggerRef } : {}),
			});
		},
		[getFeedbackContext, openFeedbackCollector],
	);
};
