import React, { type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import SettingsIcon from '@atlaskit/icon/core/settings';
import SettingsIconOld from '@atlaskit/icon/glyph/settings';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { ThemedIconButton } from '@atlassian/jira-project-theme-components/src/ui/themed-button-new/ThemedButtonNew.tsx';
import { useSoftwareProjectTheming } from '@atlassian/jira-project-theme-providers/src/controllers/software-theme/index.tsx';
import messages from './messages.tsx';

type Props = {
	onClick: (arg1: SyntheticEvent<HTMLElement>, arg2: UIAnalyticsEvent) => void;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ onClick }: Props) => {
	const { formatMessage } = useIntl();

	const { isSoftwareFullTheming } = useSoftwareProjectTheming();

	return (
		<Container>
			<Sticky>
				<Tooltip content={formatMessage(messages.description)}>
					{isSoftwareFullTheming ? (
						<ThemedIconButton
							interactionName="column-configuration-button"
							icon={SettingsIcon}
							label={formatMessage(messages.description)}
							onClick={onClick}
							testId="platform-board-kit.ui.column.column-configuration.button"
							isTooltipDisabled={false}
						/>
					) : (
						<Button
							testId="platform-board-kit.ui.column.column-configuration.button"
							aria-label={formatMessage(messages.description)}
							iconBefore={
								<SettingsIcon
									label={formatMessage(messages.description)}
									spacing="spacious"
									LEGACY_fallbackIcon={SettingsIconOld}
									LEGACY_size="medium"
									color={token('color.icon')}
								/>
							}
							onClick={onClick}
						/>
					)}
				</Tooltip>
			</Sticky>
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	position: 'relative',
	paddingTop: 0,
	paddingRight: token('space.100'),
	paddingBottom: 0,
	paddingLeft: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Sticky = styled.div({
	position: 'sticky',
	top: 0,
});
