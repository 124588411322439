import React, {
	useMemo,
	useRef,
	type SyntheticEvent,
	type KeyboardEvent,
	type MouseEvent,
	forwardRef,
	useEffect,
	useCallback,
	useContext,
} from 'react';
import { css, styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import ToolTip from '@atlaskit/tooltip';
import { ProgramBoardParentSwitcherEntrypointContext } from '@atlassian/jira-software-program-board-parent-switcher/entrypoint-context.tsx';

import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useFieldConfigWithoutRefetch } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { transformToEpic } from '@atlassian/jira-issue-field-parent-switcher/src/common/utils.tsx';
import {
	ParentSwitcherBoundary,
	AsyncLazyParentSwitcherField,
} from '@atlassian/jira-issue-field-parent-switcher/src/ui/async.tsx';
import { PARENT_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { toIssueId, toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { programBoardParentSwitcherEntryPoint } from '@atlassian/jira-software-program-board-parent-switcher/entrypoint.tsx';
import type { ParentShape } from '@atlassian/jira-software-program-board-parent-switcher/src/common/types.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { IssueParentFieldStatic } from '../../../../../../common/fields/issue-parent-field-static/index.tsx';
import { useEditableField } from '../../../../../../common/fields/use-editable-field/index.tsx';
import { PACKAGE_NAME } from '../../../../../../model/constants.tsx';
import { issueIncrementPlanningUpdate } from '../../../../../../state/actions/issue/update/index.tsx';
import { useBoardSelector, useBoardDispatch } from '../../../../../../state/index.tsx';
import { getPreventInlineEditing } from '../../../../../../state/selectors/board/board-selectors.tsx';
import { INLINE_EDITING_FIELD_ZINDEX } from '../constants.tsx';
import { setInlineEditing } from '../../../../../../state/actions/card/index.tsx';
import { ISSUE_PARENT_FIELD_TEST_ID, ISSUE_PARENT_FIELD_WRAPPER_TEST_ID } from './constants.tsx';
import type { IssueParentFieldProps, ProgramBoardIssueParentFieldInnerProps } from './types.tsx';

const onParentFieldWrapperClick = (e: SyntheticEvent<HTMLElement>) => e.stopPropagation();

// Prevent enter from opening issue view
const stopEnterPropagation = (e: KeyboardEvent) => {
	if (e.key === 'Enter') {
		e.stopPropagation();
	}
};

const TooltipTag = forwardRef<HTMLElement>((props, ref) => (
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	<TooltipWrapper ref={ref as React.ForwardedRef<HTMLDivElement>} {...props} />
));

const fieldKey = 'parent';

const IssueParentFieldInner = ({
	issueId,
	issueKey,
	currentProjectId,
	projectIds,
	issueParents = [],
	isCompanyManaged,
	parentSummary,
	onUpdate: onSuccess,
	onSubmit,
	onFailure,
}: IssueParentFieldProps) => {
	const parentFieldWrapperRef = useRef<HTMLDivElement>(null);

	const preventInlineEditing = useBoardSelector((state) => getPreventInlineEditing(state));

	const transformedEpics = useMemo(
		() =>
			issueParents
				? issueParents.map((issueParent) => ({
						...transformToEpic({ ...issueParent, id: toIssueId(String(issueParent.id)) }),
						id: toIssueId(String(issueParent.id)),
					}))
				: [],
		[issueParents],
	);

	// It makes sure the tooltip showing on mouse over the trigger button, not mouse over dropdown items
	const handleMouseOver = (e: MouseEvent<HTMLElement>) => {
		const { relatedTarget } = e;
		if (
			parentFieldWrapperRef.current &&
			relatedTarget instanceof Node &&
			!relatedTarget.contains(parentFieldWrapperRef.current)
		) {
			e.stopPropagation();
		}
	};

	return (
		<ContextualAnalyticsData
			attributes={{
				isInlineEditing: true,
				fieldKey,
				fieldType: 'parent',
			}}
		>
			<ToolTip content={parentSummary} tag={TooltipTag}>
				<ParentFieldWrapper
					onClick={onParentFieldWrapperClick}
					onKeyDown={stopEnterPropagation}
					data-testid={ISSUE_PARENT_FIELD_WRAPPER_TEST_ID}
					disableClick={preventInlineEditing}
					onMouseOver={handleMouseOver}
					ref={parentFieldWrapperRef}
				>
					<AsyncLazyParentSwitcherField
						data-testid={ISSUE_PARENT_FIELD_TEST_ID}
						issueKey={toIssueKey(issueKey)}
						issueId={toIssueId(String(issueId))}
						epics={transformedEpics}
						/* We cannot get permission when no issue view is opened by using the useProjectPermission hook in @atlassian/jira-project-permissions-service
						 * So we have to pass canUserEditIssue from the backlog to the extracted epic field to force it to be editable
						 * */
						forceEditable
						/** Same reason as above, we need the projectId in order to
						 *  open the issue parent switcher modal */
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						currentProjectId={currentProjectId!}
						projectIds={projectIds}
						isSimplifiedProject={!isCompanyManaged}
						shouldHideIfNoParent
						onSubmit={onSubmit}
						onSuccess={onSuccess}
						onFailure={onFailure}
						maxWidth="100%"
					/>
				</ParentFieldWrapper>
			</ToolTip>
		</ContextualAnalyticsData>
	);
};

const entryPointParams = {};

const ProgramBoardIssueParentFieldInner = ({
	uniqueKey,
	issueId,
	issueParents = [],
	onUpdate: onSuccess,
	onSubmit,
	onFailure,
	fallback,
}: ProgramBoardIssueParentFieldInnerProps) => {
	const preventInlineEditing = useBoardSelector((state) => getPreventInlineEditing(state));
	const dispatch = useBoardDispatch();

	const { entryPointReferenceSubject } = useContext(ProgramBoardParentSwitcherEntrypointContext);

	const onOpen = useCallback(() => {
		if (fg('render_program_board_card_in_popup')) {
			dispatch(setInlineEditing('parentField', issueId, true));
		}
	}, [dispatch, issueId]);

	const onClose = useCallback(() => {
		if (fg('render_program_board_card_in_popup')) {
			dispatch(setInlineEditing('parentField', issueId, false));
		}
	}, [dispatch, issueId]);

	const runtimeProps = useMemo(
		() => ({
			issueKey: uniqueKey,
			issueParents,
			onSubmit,
			onSuccess,
			onFailure,
			onOpen,
			onClose,
			fallback,
			preventInlineEditing,
			onSaveField: async (_: string, fieldId: string, fieldValue: ParentShape | null) => {
				dispatch(
					issueIncrementPlanningUpdate({
						issueId,
						fieldId,
						fieldValue: fieldValue ? fieldValue.id : null,
					}),
				);
			},
		}),
		[
			dispatch,
			fallback,
			issueId,
			uniqueKey,
			issueParents,
			onFailure,
			onSubmit,
			onSuccess,
			onOpen,
			onClose,
			preventInlineEditing,
		],
	);

	return (
		<ContextualAnalyticsData
			attributes={{
				isInlineEditing: true,
				fieldKey: 'parent',
				fieldType: 'parent',
				origin: 'issueCard',
			}}
		>
			<JiraEntryPointContainer
				entryPointReferenceSubject={entryPointReferenceSubject}
				id="program-board-parent-switcher"
				packageName="jiraSoftwareBoard"
				runtimeProps={runtimeProps}
				fallback={fallback}
			/>
		</ContextualAnalyticsData>
	);
};

const ProgramBoardIssueParentFieldInnerOld = ({
	uniqueKey,
	issueId,
	issueParents = [],
	onUpdate: onSuccess,
	onSubmit,
	onFailure,
	fallback,
}: ProgramBoardIssueParentFieldInnerProps) => {
	const preventInlineEditing = useBoardSelector((state) => getPreventInlineEditing(state));
	const dispatch = useBoardDispatch();
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		programBoardParentSwitcherEntryPoint,
		entryPointParams,
	);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
	}, [entryPointActions]);

	const onOpen = useCallback(() => {
		if (fg('render_program_board_card_in_popup')) {
			dispatch(setInlineEditing('parentField', issueId, true));
		}
	}, [dispatch, issueId]);
	const onClose = useCallback(() => {
		if (fg('render_program_board_card_in_popup')) {
			dispatch(setInlineEditing('parentField', issueId, false));
		}
	}, [dispatch, issueId]);

	const runtimeProps = useMemo(
		() => ({
			issueKey: uniqueKey,
			issueParents,
			onSubmit,
			onSuccess,
			onFailure,
			onOpen,
			onClose,
			fallback,
			preventInlineEditing,
			onSaveField: async (_: string, fieldId: string, fieldValue: ParentShape | null) => {
				dispatch(
					issueIncrementPlanningUpdate({
						issueId,
						fieldId,
						fieldValue: fieldValue ? fieldValue.id : null,
					}),
				);
			},
		}),
		[
			dispatch,
			fallback,
			issueId,
			uniqueKey,
			issueParents,
			onFailure,
			onSubmit,
			onSuccess,
			onOpen,
			onClose,
			preventInlineEditing,
		],
	);

	return (
		<ContextualAnalyticsData
			attributes={{
				isInlineEditing: true,
				fieldKey: 'parent',
				fieldType: 'parent',
				origin: 'issueCard',
			}}
		>
			<JiraEntryPointContainer
				entryPointReferenceSubject={entryPointReferenceSubject}
				id="program-board-parent-switcher"
				packageName="jiraSoftwareBoard"
				runtimeProps={runtimeProps}
				fallback={fallback}
			/>
		</ContextualAnalyticsData>
	);
};

export const IssueParentField = ({
	shouldRenderRichField,
	isIncrementPlanningBoard,
	...props
}: IssueParentFieldProps) => {
	const uniqueKey =
		isIncrementPlanningBoard && props.issueKey === props.projectKey
			? `${props.issueId}`
			: props.issueKey;
	const [issueParentFieldValue] = useFieldValue({
		issueKey: uniqueKey,
		fieldKey: PARENT_TYPE,
	});
	const [{ value: parentFieldConfig }] = useFieldConfigWithoutRefetch(uniqueKey, PARENT_TYPE);

	const shouldRenderRich = Boolean(
		shouldRenderRichField &&
			props.currentProjectId &&
			props.projectIds?.length &&
			props?.issueParents?.length &&
			issueParentFieldValue &&
			parentFieldConfig &&
			// on the increment planning board, we render the rich field only if the hierarchy level of issue is story
			issueParentFieldValue.id !== props.issueId,
	);

	const editableField = useEditableField({
		isExperienceAvailable: shouldRenderRich,
	});

	const fallback = useMemo(
		() => (
			<IssueParentFieldStatic
				color={props.parentColor}
				summary={props.parentSummary}
				maxWidth="100%"
			/>
		),
		[props.parentColor, props.parentSummary],
	);

	if (shouldRenderRich) {
		if (!isIncrementPlanningBoard) {
			return (
				<ParentSwitcherBoundary
					packageName={PACKAGE_NAME}
					fallback={fallback}
					onError={editableField.onError}
				>
					<UFOSegment name="ng-board.inline-edit.parent-field">
						<IssueParentFieldInner {...props} {...editableField} />
					</UFOSegment>
				</ParentSwitcherBoundary>
			);
		}
		if (fg('fix-parent_flick_on_hovering_program_board')) {
			return (
				<ProgramBoardIssueParentFieldInner
					{...props}
					fallback={fallback}
					{...editableField}
					uniqueKey={uniqueKey}
				/>
			);
		}

		return (
			<ProgramBoardIssueParentFieldInnerOld
				{...props}
				fallback={fallback}
				{...editableField}
				uniqueKey={uniqueKey}
			/>
		);
	}

	return fallback;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ParentFieldWrapper = styled.div<{ disableClick?: boolean }>(
	{
		display: 'flex',
		alignItems: 'center',
		position: 'relative',
		cursor: 'default',
		font: token('font.body.UNSAFE_small'),

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& > [id$="popup-select"]': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			position: 'absolute !important',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			top: `${token('space.300')} !important`,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			right: 'auto !important',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			left: `${token('space.negative.050')} !important`,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			transform: 'none !important',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			zIndex: INLINE_EDITING_FIELD_ZINDEX,
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ disableClick }) =>
		disableClick &&
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		css({
			pointerEvents: 'none',
		}),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TooltipWrapper = styled.div({
	maxWidth: '100%',
});
