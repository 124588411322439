import { v4 as uuid } from 'uuid';
import { IS_MODERN_SSR } from '@atlassian/jira-conditional-ssr-render/src/constants.tsx';

const SSR_UNIQUE_ID = 'ssr-unique-id';

export const initialState = {
	info: {
		uniqueId: IS_MODERN_SSR ? SSR_UNIQUE_ID : uuid(),
		isDeepLinking: true,
	},
	data: {},
} as const;
