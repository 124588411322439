import React, { type ReactNode, useCallback, useEffect, useState } from 'react';
import noop from 'lodash/noop';
import { IconButton } from '@atlaskit/button/new';
import CustomizeIcon from '@atlaskit/icon/core/customize';
import type { Show } from '@atlaskit/primitives/responsive';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useBoardWorkSuggestionsSpotlightActions } from '@atlassian/jira-insights-next-best-task/src/services/spotlight-container/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { ResponsiveThemedButton } from '@atlassian/jira-project-theme-components/src/ui/responsive-themed-button/ResponsiveThemedButton.tsx';
import { ThemedButton } from '@atlassian/jira-project-theme-components/src/ui/themed-button/ThemedButton.tsx';
import { useGlobalRefStoreActions } from '@atlassian/jira-refs-store/src/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { ThemedIconButton } from '@atlassian/jira-project-theme-components/src/ui/themed-button-new/ThemedButtonNew.tsx';
import { VIEW_SETTINGS_BUTTON_RESPONSIVE } from '../../common/constants.tsx';
import type { View } from '../../common/types/constant.tsx';
import type { ExtraField } from '../../common/types/menu.tsx';
import { useCardDetailsInitialState } from '../../controllers/hooks/use-card-details-initial-state/index.tsx';
import { useOnBacklog } from '../../controllers/hooks/use-on-backlog/index.tsx';
import { useSyncFromLocalStorage } from '../../controllers/hooks/use-sync-from-local-storage/index.tsx';
import messages from './messages.tsx';

type Props = {
	isSelected: boolean;
	/**
	 * All spacing should come from flex container `gap:` or primitive `space=`.
	 */
	removeMargins?: boolean;
	onClick: (isPanelOpen: boolean) => void;
	ShowDI?: typeof Show;
	ariaControls?: string;
	ariaExpanded?: boolean;
	ariaHasPopup?: boolean | 'dialog';
	/* Indicates whether the button should be rendered with a themed appearance,
	specifically for views used in Business projects with background styling */
	isThemedButton?: boolean;
};

export const ViewSettingsSyncFromLocalStorageWrapper = ({
	boardId,
	view,
	cardExtraFields = [],
	isDaysInColumnEnabled = false,
	isDueDateEnabled = false,
	isLabelsEnabled = false,
	isEstimateEnabled = false,
	children,
	showEpicAsPanel = false,
	isVersionsEnabled = false,
	isPriorityEnabled = false,
}: {
	boardId: number;
	view: (typeof View)[keyof typeof View];
	children: ReactNode;
	cardExtraFields?: ExtraField[];
	isDaysInColumnEnabled?: boolean;
	isDueDateEnabled?: boolean;
	isLabelsEnabled?: boolean;
	showEpicAsPanel?: boolean;
	isVersionsEnabled?: boolean;
	isEstimateEnabled?: boolean;
	isPriorityEnabled?: boolean;
}) => {
	useOnBacklog(boardId, view, showEpicAsPanel, isVersionsEnabled);
	useSyncFromLocalStorage(
		boardId,
		view,
		cardExtraFields,
		isDaysInColumnEnabled,
		isDueDateEnabled,
		isLabelsEnabled,
		isEstimateEnabled,
		isPriorityEnabled,
	);
	useCardDetailsInitialState(
		boardId,
		view,
		isDaysInColumnEnabled,
		isDueDateEnabled,
		isLabelsEnabled,
		isEstimateEnabled,
		isPriorityEnabled,
	);

	return <>{children}</>;
};

export const ViewSettingsButton = ({
	isSelected,
	onClick = noop,
	isThemedButton = false,
	...restProps
}: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState(isSelected);
	const [, { register }] = useGlobalRefStoreActions();

	const handleClick = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			}),
			'viewSettingsButton',
		);

		setIsOpen(!isOpen);
		onClick(!isOpen);
	}, [createAnalyticsEvent, isOpen, onClick]);

	const openViewSettings = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			}),
			'viewSettingsButton',
		);

		setIsOpen(true);
		setTimeout(() => onClick(true));
	}, [createAnalyticsEvent, onClick]);

	const { setOpenViewSettings } = useBoardWorkSuggestionsSpotlightActions();

	useEffect(() => {
		if (setOpenViewSettings) {
			setOpenViewSettings(openViewSettings);
		}
	}, [openViewSettings, setOpenViewSettings]);

	const onRefButtonResponsive = useCallback(
		(ref: HTMLButtonElement | null) => {
			register(VIEW_SETTINGS_BUTTON_RESPONSIVE, ref);
		},
		[register],
	);

	useEffect(() => {
		setIsOpen(isSelected);
	}, [isSelected]);

	if (isVisualRefreshEnabled()) {
		const themedButton = fg('visual-refresh-headers-buttons') ? (
			<ThemedIconButton
				testId="software-view-settings.ui.view-settings-button.responsive-button"
				appearance="default"
				icon={CustomizeIcon}
				label={formatMessage(messages.buttonLabel)}
				isSelected={isOpen}
				onClick={handleClick}
				ref={onRefButtonResponsive}
				aria-expanded={restProps.ariaExpanded ?? isSelected}
				aria-controls={restProps.ariaControls}
				aria-haspopup={restProps.ariaHasPopup ?? true}
				title={!fg('visual-refresh_drop_5') ? formatMessage(messages.buttonLabel) : undefined}
			/>
		) : (
			<ThemedButton
				testId="software-view-settings.ui.view-settings-button.responsive-button"
				appearance="default"
				iconBefore={<CustomizeIcon label="" color="currentColor" spacing="spacious" />}
				label={formatMessage(messages.buttonLabel)}
				isSelected={isOpen}
				onClick={handleClick}
				ref={onRefButtonResponsive}
				aria-label={formatMessage(messages.buttonLabel)}
				aria-expanded={restProps.ariaExpanded ?? isSelected}
				aria-controls={restProps.ariaControls}
				aria-haspopup={restProps.ariaHasPopup ?? true}
				title={!fg('visual-refresh_drop_5') ? formatMessage(messages.buttonLabel) : undefined}
			/>
		);

		// eslint-disable-next-line no-nested-ternary
		return isThemedButton ? (
			fg('visual-refresh_drop_5') ? (
				<Tooltip content={formatMessage(messages.buttonLabel)}>{themedButton}</Tooltip>
			) : (
				themedButton
			)
		) : (
			<IconButton
				testId="software-view-settings.ui.view-settings-button.icon-button"
				icon={CustomizeIcon}
				label={formatMessage(messages.buttonLabel)}
				isSelected={isOpen}
				onClick={handleClick}
				ref={onRefButtonResponsive}
				aria-expanded={restProps.ariaExpanded ?? isSelected}
				aria-controls={restProps.ariaControls}
				aria-haspopup={restProps.ariaHasPopup ?? true}
				isTooltipDisabled={false}
			/>
		);
	}

	return (
		<ResponsiveThemedButton
			testId="software-view-settings.ui.view-settings-button.responsive-button"
			appearance="default"
			icon={<CustomizeIcon label="" color="currentColor" spacing="spacious" />}
			label={formatMessage(messages.buttonLabel)}
			isSelected={isOpen}
			onClick={handleClick}
			ref={onRefButtonResponsive}
			aria-label={formatMessage(messages.buttonLabel)}
			aria-expanded={restProps.ariaExpanded ?? isSelected}
			aria-controls={restProps.ariaControls}
			aria-haspopup={restProps.ariaHasPopup ?? true}
		/>
	);
};
