import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import { from } from 'rxjs/observable/from';
import { archiveIssue } from '../../services/board/archive-issue/index.tsx';
import type { Action, ActionsObservable } from '../../state/types.tsx';
import {
	ISSUE_ARCHIVE_REQUEST,
	type IssueArchiveRequestAction,
	issueArchiveFailure,
	issueArchiveSuccess,
} from '../../state/actions/issue/archive/index.tsx';

const archiveIssueObservable = (issueKey: string | null) => {
	return from(archiveIssue(issueKey));
};

const cardArchiveEpic = (action$: ActionsObservable): Observable<Action> =>
	action$.ofType(ISSUE_ARCHIVE_REQUEST).mergeMap((action: IssueArchiveRequestAction) => {
		const {
			payload: { issue, onArchivalSuccess, onArchivalFailure },
			meta: {
				optimistic: { id: optimisticId },
			},
		} = action;

		return archiveIssueObservable(issue.key)
			.mergeMap(() => {
				onArchivalSuccess(issue.key);
				return Observable.of(issueArchiveSuccess(optimisticId, issue.id));
			})
			.catch(() => {
				onArchivalFailure(issue.key);
				return Observable.of(issueArchiveFailure(optimisticId, issue.id));
			});
	});

export default cardArchiveEpic;
