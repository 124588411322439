import type { IconProps } from '@atlaskit/icon';
import EditorAddIcon from '@atlaskit/icon/core/migration/add--editor-add';
import UploadIcon from '@atlaskit/icon/core/migration/cloud-arrow-up--upload';
import type { IconSrc } from '../../../../common/types.tsx';
import JiraIcon from './jira-icon/index.tsx';
import LaunchDarklyIcon from './launch-darkly-icon/index.tsx';
import SecurityIcon from './security-icon/index.tsx';
import SlackIcon from './slack-icon/index.tsx';
import TransitionIcon from './transition-icon/index.tsx';

export const templateIcons: { [src in IconSrc]: React.ComponentType<IconProps> } = {
	LaunchDarklyIcon,
	SecurityIcon,
	SlackIcon,
	SoftwareIcon: JiraIcon,
	TransitionIcon,
	UploadIcon,
	EditorAddIcon,
};
