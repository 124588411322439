import React, { type ReactElement, type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button, { ButtonGroup } from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import { token } from '@atlaskit/tokens';
import { JiraInlineDialog } from '@atlassian/jira-inline-dialog/src/ui/jira-inline-dialog.tsx';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import CoordinationClient from '@atlassian/jira-engagement/src/ui/coordination-client/index.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import ReportErrors from '@atlassian/jira-errors-handling/src/utils/reporting-error-boundary.tsx';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import { useJiraSoftwareProjectNavigation } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/common/project-sidebar-navigation-resource/index.tsx';
import { sharedStyles } from '@atlassian/jira-platform-board-kit/src/index.tsx';
import {
	fireTrackAnalytics,
	FireScreenAnalytics,
	SCREEN,
	ContextualAnalyticsData,
} from '@atlassian/jira-product-analytics-bridge';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import { RouterActions, type RouterActionsType } from '@atlassian/react-resource-router';
import type { Column } from '../../../../model/column/column-types.tsx';
import messages from './messages.tsx';

type StopFunction = () => void;

export type Props = {
	isOpen: boolean;
	initialColumn: Column | undefined;
} & Intl & {
		onCancel: () => void;
		onConfirm: (push: RouterActionsType['push'], refresh: () => void) => void;
	};

type BacklogFeatureDialogProps = {
	initialColumnName: string;
} & Intl & {
		push: RouterActionsType['push'];
		stop: StopFunction;
		onCancel: () => void;
		onConfirm: (push: RouterActionsType['push'], refresh: () => void) => void;
	};

type CoordinationStopProviderProps = {
	stop?: StopFunction;
	children: (arg1: StopFunction | undefined) => ReactElement;
};

const CoordinationStopProvider = ({ stop = noop, children }: CoordinationStopProviderProps) =>
	children(stop);

export const BacklogFeatureDialog = ({
	intl,
	initialColumnName,
	onCancel,
	push,
	onConfirm,
	stop,
}: BacklogFeatureDialogProps) => {
	const { refresh } = useJiraSoftwareProjectNavigation();
	const title = intl.formatMessage(messages.title);
	const description = intl.formatMessage(messages.description, { initialColumnName });
	const confirm = intl.formatMessage(messages.confirm);
	const cancel = intl.formatMessage(messages.cancel);
	const boldDescription = intl.formatMessage(messages.boldDescription);
	const onConfirmClicked = (e: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
		fireTrackAnalytics(analyticsEvent, 'backlogFeatureDialogConfirm clicked');
		stop();
		onConfirm(push, refresh);
	};

	const onCancelClicked = (e: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
		fireTrackAnalytics(analyticsEvent, 'backlogFeatureDialogCancel clicked');
		stop();
		onCancel();
	};

	const renderDialogContent = (
		<Container>
			<Heading size="xsmall" as="h5">
				{title}
			</Heading>
			<DescriptionStyle>
				{description}
				<BoldDescriptionStyle>{boldDescription}</BoldDescriptionStyle>
			</DescriptionStyle>

			<ButtonGroupContainer>
				<ButtonGroup>
					<Button onClick={onCancelClicked} appearance="subtle">
						{cancel}
					</Button>
					<Button onClick={onConfirmClicked} appearance="primary">
						{confirm}
					</Button>
				</ButtonGroup>
			</ButtonGroupContainer>
			<FireScreenAnalytics />
		</Container>
	);

	return (
		<JiraInlineDialog
			messageId="software-board.board-container.board.backlog-feature-dialog.jira-inline-dialog"
			messageType="engagement"
			content={renderDialogContent}
			isOpen
		>
			<div />
		</JiraInlineDialog>
	);
};

const BacklogFeatureDialogWithRouter = ({ isOpen, initialColumn, ...props }: Props) =>
	initialColumn && isOpen ? (
		<ContextualAnalyticsData sourceType={SCREEN} sourceName="backlogFeatureDialog">
			<ErrorBoundary id="backlog-feature-dialog" packageName="software-board">
				<ReportErrors id="jira.board.backlog.feature.dialog" packageName="jiraSoftwareBoard">
					<RouterActions>
						{({ push }) => (
							<StickyPosition>
								<CoordinationClient messageId="software-board.board-container.board.backlog-feature-dialog.coordination-client.stg-ext-459">
									<CoordinationStopProvider>
										{(stop) => (
											<BacklogFeatureDialog
												{...props}
												initialColumnName={initialColumn.name}
												stop={stop || noop}
												push={push}
											/>
										)}
									</CoordinationStopProvider>
								</CoordinationClient>
							</StickyPosition>
						)}
					</RouterActions>
				</ReportErrors>
			</ErrorBoundary>
		</ContextualAnalyticsData>
	) : null;

export default injectIntl(BacklogFeatureDialogWithRouter);

const COLUMN_DIALOG_Z_INDEX = sharedStyles.zIndex.stickyHeaders + 1;
const CONTAINER_WIDTH = gridSize * 42;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StickyPosition = styled.div({
	position: 'relative',
	top: token('space.200'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	left: `${sharedStyles.columnWithMarginWidth / 2}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div': {
		position: 'sticky',
		top: token('space.200'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		zIndex: COLUMN_DIALOG_Z_INDEX,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonGroupContainer = styled.div({
	display: 'flex',
	justifyContent: 'flex-end',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	whiteSpace: 'normal',
	font: token('font.body'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${CONTAINER_WIDTH}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DescriptionStyle = styled.p({
	marginBottom: token('space.200'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BoldDescriptionStyle = styled.span({
	fontWeight: token('font.weight.bold'),
});
