import { useEffect, useRef } from 'react';
import { useBoardSelector } from '../../index.tsx';
import {
	numberOfUserHasCompleteAssignedIssuesSelector,
	shouldShowUnassignedFilterNudgeSelector,
} from '../../selectors/unassigned-work/unassigned-work-selector.tsx';
import { isBoardConfigLoaded } from '../../selectors/work/work-selectors.tsx';

export const useShouldShowUnassignedFilter = (): {
	shouldShowUnassignedFilterNudge: boolean;
} => {
	const isLoaded = useBoardSelector(isBoardConfigLoaded);
	const numberOfCompleteIssues = useBoardSelector(numberOfUserHasCompleteAssignedIssuesSelector);
	const shouldShowNudge = useBoardSelector(shouldShowUnassignedFilterNudgeSelector);

	const prevBoardStatsRef = useRef<{
		shouldShowNudge: boolean;
		numberOfCompleteIssues: number | null;
		shouldShowUnassignedFilterNudge: boolean;
	}>({
		shouldShowNudge,
		numberOfCompleteIssues: null,
		shouldShowUnassignedFilterNudge: isLoaded && shouldShowNudge,
	});

	useEffect(() => {
		if (isLoaded) {
			const prevBoardStats = prevBoardStatsRef.current;
			const shouldShowUnassignedFilterNudge =
				shouldShowNudge &&
				(prevBoardStats.numberOfCompleteIssues === null ||
					prevBoardStats.numberOfCompleteIssues + 1 === numberOfCompleteIssues);

			prevBoardStatsRef.current = {
				shouldShowNudge,
				numberOfCompleteIssues: shouldShowUnassignedFilterNudge
					? prevBoardStats.numberOfCompleteIssues
					: numberOfCompleteIssues,
				shouldShowUnassignedFilterNudge,
			};
		}
	}, [isLoaded, numberOfCompleteIssues, shouldShowNudge]);

	return {
		shouldShowUnassignedFilterNudge: prevBoardStatsRef.current.shouldShowUnassignedFilterNudge,
	};
};
