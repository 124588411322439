/** @jsx jsx */
import React, { useRef, useCallback, type SyntheticEvent } from 'react';
import { css, jsx } from '@compiled/react';
import isNil from 'lodash/isNil';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { StatusLozenge as IssueStatusFieldReadonly } from '@atlassian/jira-issue-field-status/src/ui/status-lozenge/index.tsx';
import type { PlanStatusIssue } from '@atlassian/jira-portfolio-3-plan-issue-status/src/types.tsx';
import PlanStatusField from '@atlassian/jira-portfolio-3-plan-issue-status/src/ui/index.tsx';
import type { StatusTransition } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import {
	ContextualAnalyticsData,
	useAnalyticsEvents,
	fireTrackAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { isScenarioIssue } from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/utils.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useBoardDispatch } from '../../../../../../state/index.tsx';
import { setInlineEditing } from '../../../../../../state/actions/card/index.tsx';
import type { Props } from './types.tsx';
import { isIssueStatusDone } from './utils.tsx';

const stopPropagation = (e: SyntheticEvent<HTMLElement>) => e.stopPropagation();

export const IssueStatusField = ({ issue, issueStatuses, onChange, isReadOnly }: Props) => {
	const statusFieldWrapperRef = useRef<HTMLDivElement>(null);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleChange = useCallback(
		(transition: StatusTransition) => {
			const isDone = isIssueStatusDone(transition.to.statusCategory.id);
			onChange(String(issue.id), transition, isDone);
			fireTrackAnalytics(createAnalyticsEvent({}), 'issue updated', {
				isDone,
				isInlineEditing: true,
				fieldKey: 'status',
				fieldType: 'status',
				origin: 'issueCard',
			});
		},
		[onChange, issue.id, createAnalyticsEvent],
	);
	const dispatch = useBoardDispatch();
	const onOpen = useCallback(() => {
		dispatch(setInlineEditing('status', issue.id, true));
	}, [dispatch, issue.id]);
	const onClose = useCallback(() => {
		dispatch(setInlineEditing('status', issue.id, false));
	}, [dispatch, issue.id]);

	if (!isNil(issue.statusId) && !isNil(issueStatuses[issue.statusId]) && !isNil(issue.projectId)) {
		const status = issueStatuses[issue.statusId];
		if (!status) return null;

		const value = {
			id: status.id.toString(),
			name: status.name,
			statusCategory: {
				id: status.categoryId,
			},
		};

		const normalizedIssue: PlanStatusIssue = {
			id: String(issue.id),
			type: Number(issue.typeId),
			key: issue.key,
			projectId: issue.projectId,
			statusId: String(issue.statusId),
			originalStatusId: isNil(issue?.originals?.statusId)
				? undefined
				: `${issue?.originals?.statusId}`,
		};

		return (
			<ContextualAnalyticsData
				attributes={{
					isInlineEditing: true,
					fieldKey: 'status',
					fieldType: 'status',
				}}
			>
				{/**
				 * Empty div is used to ensure status appears on it's own line. This is required because the parent css contains flex-flow: row wrap causing all children to appear side by side.
				 * Additionally, the div styles cannot be added to the status field wrapper container as it prevents the issue view modal being triggered open anywhere along the same row as the status lozenge.
				 * */}
				<div css={divStyles} />
				<Box
					onClick={stopPropagation}
					onKeyDown={stopPropagation}
					ref={statusFieldWrapperRef}
					xcss={
						isVisualRefreshEnabled() && fg('visual-refresh_drop_6')
							? statusFieldWrapperStyles
							: statusFieldWrapperStylesOld
					}
				>
					<Tooltip content={status.name}>
						{isScenarioIssue(String(issue.id)) || isReadOnly ? (
							<IssueStatusFieldReadonly value={value} />
						) : (
							<PlanStatusField
								issue={normalizedIssue}
								issueKey={issue.key}
								value={value}
								onChange={handleChange}
								projectId={issue.projectId}
								issueStatuses={issueStatuses}
								{...(fg('render_program_board_card_in_popup') ? { onOpen, onClose } : {})}
							/>
						)}
					</Tooltip>
				</Box>
			</ContextualAnalyticsData>
		);
	}
	return null;
};

const divStyles = css({
	flexBasis: '100%',
	display: 'flex',
});

const statusFieldWrapperStyles = xcss({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: `${token('space.200')}`,
	width: 'auto',
	alignSelf: 'flex-start',
	marginTop: 'space.negative.100',
	maxWidth: '100%',
});

const statusFieldWrapperStylesOld = xcss({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: `${token('space.200')}`,
	width: 'auto',
	alignSelf: 'flex-start',
	marginTop: 'space.negative.100',
});

export default IssueStatusField;
