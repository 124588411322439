import type { Color, ColorSchema } from '@atlassian/jira-issue-epic-color-types/src/types.tsx';
import { transformColorToTheme } from '@atlassian/jira-issue-epic-color/src/common/utils.tsx';
import { DEFAULT_EPIC_COLOR, MAX_SUGGESTIONS } from './constants.tsx';
import type {
	HierarchyShape,
	LozengeThemes,
	ParentIssueWithColor,
	ParentShape,
	PopupSelectOptionType,
	PartialIssueType,
	Epic,
	ParentList,
} from './types.tsx';

export const getLozengeThemes: Record<Color, LozengeThemes> = transformColorToTheme(
	(colorSchema: ColorSchema) => ({
		color: colorSchema.text,
		backgroundColor: colorSchema.textBackground,
	}),
);

// still trying again with default here incase a color was passed but it was invalid.
export const getLozengeThemeByColor = (color: Color = DEFAULT_EPIC_COLOR): LozengeThemes =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	getLozengeThemes[color] ?? (getLozengeThemes[DEFAULT_EPIC_COLOR] as LozengeThemes);

export const getParentShape = (
	id: string,
	key: string,
	summary: string,
	issuetype: PartialIssueType,
	color?: Color,
): ParentShape => ({
	id,
	key,
	color,
	fields: { summary, issuetype },
});

export const getHierarchyShape = ({ id, name, iconUrl }: PartialIssueType): HierarchyShape => ({
	name: 'Epic',
	level: 1, // This is always level 1
	issueTypeIds: [Number(id)],
	issueTypes: [
		{
			id,
			name,
			iconUrl,
		},
	],
});

type FormattedOptionList = {
	options: PopupSelectOptionType[];
	hasMoreOptions: boolean;
};

export const formatOptionList: (
	unformattedOptions: ParentIssueWithColor[],
	currentParent: string,
	label: string,
) => FormattedOptionList = (unformattedOptions, currentParent, label) => {
	const filteredOptions = unformattedOptions.filter((option) => option.key !== currentParent);

	const parentList: ParentList[] = filteredOptions.slice(0, MAX_SUGGESTIONS).map((option) => ({
		label: `${option.key} ${option.fields.summary}`,
		value: option.fields.summary,
		iconUrl: option.fields.issuetype.iconUrl,
		...option,
	}));

	return {
		options: parentList.length > 0 ? [{ label, options: parentList }] : [],
		hasMoreOptions: filteredOptions.length > MAX_SUGGESTIONS,
	};
};

export const transformToEpic = ({
	id,
	key,
	summary,
	color,
	issueType: { id: issueTypeId, name: issueTypeName, iconUrl },
}: {
	id: string;
	key: string;
	summary: string;
	color?: Color;
	issueType: { id: string; name: string; iconUrl: string; [key: string]: unknown };
	[key: string]: unknown;
}): Epic => ({
	id,
	key,
	summary,
	color,
	issueType: {
		id: issueTypeId,
		name: issueTypeName,
		iconUrl,
	},
});
