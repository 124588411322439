import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ICCPlaceholder = styled.div({
	width: '100%',
	height: '8px',
	marginTop: token('space.negative.050'),
	marginRight: 0,
	marginBottom: token('space.negative.050'),
	marginLeft: 0,
	pointerEvents: 'none',
	userSelect: 'none',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TooltipIconWrapper = styled.div({
	cursor: 'pointer',
});
