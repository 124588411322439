// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { initialState } from '../config.tsx';
import type { State } from '../types.tsx';

const actions = {
	setMediaHeight:
		(mediaId: string, height: number) =>
		({ setState }: StoreActionApi<State>) => {
			setState({
				[mediaId]: {
					height,
				},
			});
		},
	clearCardMedia:
		() =>
		({ setState }: StoreActionApi<State>) =>
			setState(initialState),
} as const;

export type Actions = typeof actions;
export default actions;
