import isNil from 'lodash/isNil';
import some from 'lodash/some';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { defaultLocale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import { chainUnsafe as chain } from '@atlassian/jira-common-icepick/src/utils/unsafe/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { SET_CAN_USER_INVITE } from '../../actions/can-user-invite/index.tsx';
import type { Action } from '../../actions/index.tsx';
import { WORK_DATA_SET } from '../../actions/work/index.tsx';
import type { IssueProject, boardLocation } from '../../../model/software/software-types.tsx';
import extensionReducer from './extension/reducer.tsx';
import type { ConfigurationState } from './types.tsx';

const initialState: ConfigurationState = {
	contextPath: '',
	rapidViewId: '',
	cloudId: '',
	activationId: '',
	userAccountId: '',
	locale: defaultLocale,
	projectKey: '',
	projectId: 0,
	projectName: '',
	projectType: SOFTWARE_PROJECT,
	siteAdminStatus: 'unknown',
	licensedProducts: {},
	productCrossSellConfig: 'unknown',
	extension: {
		operations: [],
	},
	sessionId: '',
	isCMPBoard: false,
	isUserBoard: false,
	isIncrementPlanningBoard: false,
	issueLimitExceeded: false,
	missingIssueType: '',
	canInviteOthersToProject: false,
};

/**
 * Resolve project details based on the projects and board location
 * in CMP board, the projects from the main endpoint return projects in project Id order
 * that leads to a issue that the board location is not the first project in the list
 */
export const projectDetailResolver = (
	projects: IssueProject[],
	boardLocation: boardLocation | undefined,
): {
	id: number;
	key: string;
	name: string;
} => {
	if (fg('jsw_refactor_main_project_detail_in_board_config')) {
		if (
			!isNil(boardLocation) &&
			boardLocation.type === 'PROJECT' &&
			some(projects, { id: boardLocation.id })
		) {
			return {
				id: boardLocation.id,
				key: boardLocation.key,
				name: boardLocation.name,
			};
		}
	}

	// Fallback to the first project if the board location is not a project type
	// this is the original behavior from UIF migration
	return {
		id: projects[0]?.id ?? 0,
		key: projects[0]?.key ?? '',
		name: projects[0]?.name ?? '',
	};
};

export const configurationReducer = (
	state: ConfigurationState = initialState,
	action: Action,
): ConfigurationState => {
	const newState = chain(state).set('extension', extensionReducer(state.extension, action)).value();

	if (action.type === WORK_DATA_SET) {
		const { projects, boardLocation } = action.payload;
		const projectDetails = projectDetailResolver(projects, boardLocation);

		return {
			...newState,
			projectName: projectDetails.name,
			projectId: projectDetails.id,
			projectKey: projectDetails.key,
			boardType: action.payload.boardType,
			etag: action.payload.etag,
			hierarchyNomenclature: action.payload.hierarchyNomenclature,
			issueLimitExceeded: action.payload.issueLimitExceeded ?? false,
			savedFilterId: action.payload.savedFilterId,
		};
	}

	if (action.type === SET_CAN_USER_INVITE) {
		return {
			...newState,
			canInviteOthersToProject: action.payload.canInviteOthersToProject,
		};
	}

	return newState;
};
