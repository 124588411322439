import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { userPreferenceAPI } from '@atlassian/jira-user-preferences-services/src/utils/index.tsx';
import type { HasSeenItKey } from '../../types.tsx';
import { createHasSeenPrefixedKey } from '../../utils.tsx';

export const performFetchHasSeenRequest = async (hasSeenKey: HasSeenItKey) => {
	try {
		const response = await userPreferenceAPI.get<boolean>(createHasSeenPrefixedKey(hasSeenKey));

		// if response is null, it means the user has not interacted with the feature yet
		if (response === null) {
			return false;
		}

		return response;
	} catch (error: unknown) {
		if (error instanceof Error) {
			fireErrorAnalytics({
				error,
				meta: {
					id: 'performFetchHasSeenRequest',
					packageName: 'jiraOnboardingCore',
					teamName: 'jlove-makkuro',
				},
				sendToPrivacyUnsafeSplunk: true,
				skipSentry: true,
				attributes: {
					hasSeenKey,
				},
			});
		}

		return 'errored';
	}
};
