import { NO_SWIMLANE } from '../../../../../model/swimlane/swimlane-modes.tsx';
import type { Action as SoftwareActions } from '../../../../actions/index.tsx';
import { WORK_DATA_SET } from '../../../../actions/work/index.tsx';
import type { ModeState } from './types.tsx';

export const swimlaneAdminConfiguredModeReducer = (
	state: ModeState = NO_SWIMLANE.id,
	action: SoftwareActions,
): ModeState => {
	if (action.type === WORK_DATA_SET) {
		const { payload } = action;
		return payload.adminConfiguredSwimlaneModeId || state;
	}

	return state;
};
