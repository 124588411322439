import React, { useCallback, useMemo } from 'react';
import noop from 'lodash/noop';
import { graphql, useFragment } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import AssigneeFilter, {
	type AssigneeFilterProps,
} from '@atlassian/jira-filters/src/ui/filters/assignee/main.tsx';
import type {
	assigneePicker_filterRefinement$data,
	assigneePicker_filterRefinement$key,
} from '@atlassian/jira-relay/src/__generated__/assigneePicker_filterRefinement.graphql';
import { type AccountId, toAccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { ASSIGNEE, SOURCE_NAME } from '../../common/constants.tsx';
import { useClauseUpdater } from '../../controllers/ast/use-clause-updater/index.tsx';
import { useSelectedValues } from '../../controllers/ast/use-selected-values/index.tsx';
import { useASTActions } from '../../controllers/ast/index.tsx';

export type Props = {
	fragmentRef: assigneePicker_filterRefinement$key;
	projectId?: number;
	projectKey?: string;
	projectName?: string;
};

const useAssignees = (data: assigneePicker_filterRefinement$data) => {
	return useMemo(() => {
		const result: AssigneeFilterProps['users'] = [];

		data.jira?.jqlBuilder?.fieldValues?.edges?.forEach((edge) => {
			if (edge?.node != null) {
				// empty is normalized to lowercase for easier AST and analytics matching
				const id = edge.node.__typename === 'JiraJqlEmptyFieldValue' ? 'empty' : edge.node.jqlTerm;

				result.push({
					id: toAccountId(id),
					displayName: edge.node.displayName,
					avatarUrl: edge.node.user?.picture,
				});
			}
		});

		return result;
	}, [data.jira?.jqlBuilder?.fieldValues?.edges]);
};

const AddPeopleModal = () => <></>;

function getAnalyticsData(
	currentUserAccountId: string | null,
	avatarAccountId: string,
	amountOfSelectedOptionsOfFieldType: number,
) {
	return {
		action: 'clicked',
		actionSubject: 'avatar',
		actionSubjectId: 'chooseAssignee',
		attributes: {
			isMe: avatarAccountId === currentUserAccountId,
			isUnassigned: avatarAccountId === 'empty',
			amountOfSelectedOptionsOfFieldType,
		},
	};
}

export const AssigneePicker = ({ fragmentRef, projectId, projectKey, projectName }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const currentUserAccountId = useAccountId();

	// TODO: shared assignee picker doesn't have pagination, re-evaluate number of field values we fetch based on data
	const data = useFragment<assigneePicker_filterRefinement$key>(
		graphql`
			fragment assigneePicker_filterRefinement on Query
			@argumentDefinitions(cloudId: { type: "ID!" }, scope: { type: "JiraJqlScopeInput" }) {
				jira {
					jqlBuilder(cloudId: $cloudId) {
						fieldValues(jqlTerm: "assignee", first: 100, scope: $scope) {
							edges {
								node {
									__typename
									jqlTerm
									displayName
									... on JiraJqlUserFieldValue {
										user {
											picture
										}
									}
								}
							}
						}
					}
				}
			}
		`,
		fragmentRef,
	);

	const showAddPeopleButton =
		projectId !== undefined && projectKey !== undefined && projectName !== undefined;

	const assignees = useAssignees(data);

	const selectedAssignees = useSelectedValues(ASSIGNEE);

	const { addValueToClause, removeValueFromClause, getTotalCount } = useClauseUpdater(ASSIGNEE);

	const { updateJql } = useASTActions();

	const onToggle = useCallback(
		(accountId: AccountId) => {
			updateJql({
				jql: selectedAssignees.includes(accountId)
					? removeValueFromClause(accountId)
					: addValueToClause(accountId),
				fieldType: ASSIGNEE,
				amountOfSelectedOptionsOfFieldType: getTotalCount(),
			});

			fireUIAnalytics(
				createAnalyticsEvent({}),
				getAnalyticsData(currentUserAccountId, accountId, getTotalCount()),
			);
		},
		[
			addValueToClause,
			createAnalyticsEvent,
			currentUserAccountId,
			getTotalCount,
			removeValueFromClause,
			selectedAssignees,
			updateJql,
		],
	);

	return (
		<Box xcss={[showAddPeopleButton && ContainerStyleWithAddPeopleButton]}>
			<AssigneeFilter
				screenName={SOURCE_NAME}
				isAddPeopleButtonEnabled={showAddPeopleButton}
				maxUsers={7}
				onAssigneeMenuOpen={noop}
				onToggle={onToggle}
				// only used in AddPeopleModal, setting to -1 to avoid typescript problem
				projectId={projectId ?? -1}
				projectKey={projectKey}
				selectedUsers={selectedAssignees}
				projectName={projectName}
				users={assignees}
				// no need to add people modal, since it is already within AssigneeFilter
				AddPeopleModal={AddPeopleModal}
			/>
		</Box>
	);
};

const ContainerStyleWithAddPeopleButton = xcss({
	// to align with 10px spacing between people avatars and addPeople button
	paddingInlineEnd: 'space.025',
	display: 'flex',
	alignItems: 'center',
});
