// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	type Action,
	createActionsHook,
	createContainer,
	createStateHook,
	createStore,
} from '@atlassian/react-sweet-state';
import type { ContainerProps, State, UpdateJqlProps } from './types.tsx';
import { getStateFromJQL } from './utils.tsx';

const initialState = {
	ast: {
		query: undefined,
		represents: '',
		errors: [],
	},
	clauseMap: {},
	jql: '',
	textSearchInputClause: undefined,
};

export const AstContainer = createContainer<ContainerProps>();

const Store = createStore({
	containedBy: AstContainer,
	initialState,
	actions: {
		updateJql:
			({ jql, fieldType, amountOfSelectedOptionsOfFieldType }: UpdateJqlProps) =>
			({ setState }, { onJqlChange }) => {
				const updatedResult = getStateFromJQL(jql);
				setState(updatedResult);
				onJqlChange && onJqlChange(jql, fieldType, amountOfSelectedOptionsOfFieldType);
			},
	},
	handlers: {
		onInit:
			(): Action<State, ContainerProps> =>
			({ setState }, { defaultJql }) => {
				setState(getStateFromJQL(defaultJql));
			},
		onContainerUpdate:
			(nextProps, prevProps): Action<State, ContainerProps> =>
			({ setState }) => {
				if (nextProps.defaultJql !== prevProps.defaultJql) {
					setState(getStateFromJQL(nextProps.defaultJql));
				}
			},
	},
	name: 'jira-filter-refinement-ast',
});

export const useASTActions = createActionsHook(Store);

export const useAST = createStateHook(Store, {
	selector: ({ ast }) => ast,
});

export const useJQL = createStateHook(Store, {
	selector: ({ jql }) => jql,
});

export const useClauseMap = createStateHook(Store, {
	selector: ({ clauseMap }) => clauseMap,
});

export const useTextSearchInputClause = createStateHook(Store, {
	selector: ({ textSearchInputClause }) => textSearchInputClause,
});
