import React, { type RefObject, type ReactNode, type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import flow from 'lodash/flow';
import memoize from 'lodash/memoize';
import memoizeOne from 'memoize-one';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Avatar from '@atlaskit/avatar';
import { fireUiAnalytics } from '@atlassian/jira-analytics-web-react/src/utils/fire-ui-event.tsx';
import { ColumnType } from '@atlassian/jira-common-constants/src/column-types.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type {
	IntlShapeV2 as IntlShape,
	MessageDescriptorV2 as MessageDescriptor,
} from '@atlassian/jira-intl/src/v2/types.tsx';
import Swimlane from '@atlassian/jira-platform-board-kit/src/ui/swimlane/index.tsx';
import type { SwimlaneModeId } from '@atlassian/jira-platform-board-kit/src/ui/swimlane/types.tsx';
import { AnalyticsEventToProps, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { IssueParent } from '../../../../model/issue/issue-types.tsx';
import type { Person } from '../../../../model/people/people-types.tsx';
import type { Status } from '../../../../model/software/software-types.tsx';
import {
	SWIMLANE_BY_ASSIGNEE,
	SWIMLANE_BY_PARENT_ISSUE,
	SWIMLANE_BY_SUBTASK,
	SWIMLANE_CHILDLESS,
	SWIMLANE_BY_PROJECT,
	SWIMLANE_BY_ASSIGNEE_UNASSIGNED_FIRST,
	SWIMLANE_BY_REQUEST_TYPE,
} from '../../../../model/swimlane/swimlane-modes.tsx';
import { type SwimlaneId, ChildlessSwimlane } from '../../../../model/swimlane/swimlane-types.tsx';
import { openIssueModal } from '../../../../state/actions/issue/modal/index.tsx';
import { swimlaneToggle } from '../../../../state/actions/swimlane/index.tsx';
import { columnByStatusIdSelector } from '../../../../state/selectors/column/column-selectors.tsx';
import {
	firstParentIssueTypeNameSelector,
	getIssueParents,
} from '../../../../state/selectors/issue-parent/index.tsx';
import { isDoneIssueSelector } from '../../../../state/selectors/issue/issue-selectors.tsx';
import { getPerson } from '../../../../state/selectors/people/people-selectors.tsx';
import {
	rapidViewIdSelector,
	projectIdSelector,
	getIssues,
	getIsCMPBoard,
	getMissingParents,
} from '../../../../state/selectors/software/software-selectors.tsx';
import { getSwimlaneMode } from '../../../../state/selectors/swimlane/swimlane-mode-selectors.tsx';
import { isSwimlaneCollapsed } from '../../../../state/selectors/swimlane/swimlane-selectors.tsx';
import {
	platformSwimlaneSelector,
	makeGetIssueParentForSwimlaneHeader,
} from '../../../../state/selectors/work/work-selectors.tsx';
import type { State } from '../../../../state/types.tsx';
import messages from './messages.tsx';

type OwnProps = {
	isFirstSwimlane: boolean;
	id: SwimlaneId;
	customRightContent: ReactNode;
	children: ReactNode;
	intl: IntlShape;
	wrapperStyle?: React.CSSProperties;
	innerRef?: RefObject<HTMLDivElement>;
	isMatrixLayout?: boolean;
	isThemed?: boolean;
};

type DispatchProps = {
	openIssueModal: typeof openIssueModal;
	swimlaneToggle: typeof swimlaneToggle;
};

const getExternalValues = memoize(
	(boardId, projectId) => ({
		boardId,
		projectId,
	}),
	(boardId, projectId) => `${boardId}.${projectId}`,
);

const getIcon = (
	isCMPBoard: boolean,
	swimlaneMode?: SwimlaneModeId | null,
	imageUrl?: string | null,
) => {
	if (!imageUrl) return null;
	if (
		swimlaneMode === SWIMLANE_BY_ASSIGNEE.id ||
		swimlaneMode === SWIMLANE_BY_ASSIGNEE_UNASSIGNED_FIRST.id
	) {
		return <Avatar size="small" src={imageUrl} name="" />;
	}
	if (swimlaneMode === SWIMLANE_BY_PARENT_ISSUE.id || swimlaneMode === SWIMLANE_BY_SUBTASK.id) {
		return <IssueTypeIcon src={imageUrl} alt="" />;
	}

	if (isCMPBoard && swimlaneMode === SWIMLANE_BY_PROJECT.id) {
		return <ProjectIcon src={imageUrl} alt="" />;
	}

	if (swimlaneMode === SWIMLANE_BY_REQUEST_TYPE.id) {
		return <RequestTypeIcon src={imageUrl} alt="" />;
	}

	return null;
};

const SPOTLIGHT_TARGET_ID = 'board-swimlane-header';

export const getIssuesWithoutSwimlaneHeaderName = (
	message: MessageDescriptor,
	intl: IntlShape,
	swimlaneMode: SwimlaneModeId | null | undefined,
	state: State,
): string => {
	if (swimlaneMode === SWIMLANE_BY_PARENT_ISSUE.id) {
		return intl.formatMessage(message, {
			issueParentTypeName: firstParentIssueTypeNameSelector(state),
		});
	}
	if (swimlaneMode === SWIMLANE_BY_SUBTASK.id) {
		return intl.formatMessage(message);
	}
	return intl.formatMessage(message);
};

export const getSwimlaneHeaderIssueStatus = (
	state: State,
	mode: string | undefined,
	issueId: string,
): Status | null => {
	if (mode === SWIMLANE_BY_PARENT_ISSUE.id) {
		const swimlaneHeaderIssue = getIssueParents(state)[issueId];
		return swimlaneHeaderIssue && swimlaneHeaderIssue.status ? swimlaneHeaderIssue.status : null;
	}

	// In CMP Kanplan, in a case of parent task in plan board but its subtasks in kanban board
	// when grouping by stories, swimlane header should look up in the the parent tasks (aka missing parent)
	if (getIsCMPBoard(state) && mode === SWIMLANE_BY_SUBTASK.id) {
		const swimlaneHeaderMissingParent = getMissingParents(state)[Number(issueId)];
		if (swimlaneHeaderMissingParent) {
			return swimlaneHeaderMissingParent.status;
		}
	}

	const swimlaneHeaderIssue = getIssues(state)[issueId];

	// Fetch the column that contains the given statusId
	const swimlaneHeaderIssueColumn =
		swimlaneHeaderIssue && swimlaneHeaderIssue.statusId
			? // @ts-expect-error - TS2554 - Expected 1 arguments, but got 2.
				columnByStatusIdSelector(state, swimlaneHeaderIssue.statusId)
			: null;

	// Get the status for the swimlane header issue
	return (
		(swimlaneHeaderIssueColumn && swimlaneHeaderIssueColumn.type === ColumnType.STATUS
			? swimlaneHeaderIssueColumn.statuses
			: []
		).find((status) => swimlaneHeaderIssue && status.id === swimlaneHeaderIssue.statusId) || null
	);
};

export const getSwimlaneSummaryTooltip = (
	swimlaneId: SwimlaneId,
	mode: SwimlaneModeId | null | undefined,
	intl: IntlShape,
) => {
	if (mode === SWIMLANE_BY_SUBTASK.id && swimlaneId === SWIMLANE_CHILDLESS) {
		return intl.formatMessage(
			expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? messages.childlessTooltipIssueTermRefresh
				: messages.childlessTooltip,
		);
	}
	return null;
};

export const mapStateToProps = () => {
	// memoization is used instead of weak map due to keys being potentially null
	const getIconFactory = memoizeOne(
		(
			swimlaneMode: SwimlaneModeId | null | undefined,
			imageUrl: string | null | undefined,
			isCMPBoard: boolean,
		) => getIcon(isCMPBoard, swimlaneMode, imageUrl),
	);

	const issueParentFactory = memoizeOne((issueParent: IssueParent | null) =>
		issueParent ? { summary: issueParent.summary, color: issueParent.color } : null,
	);
	const assigneeFactory = memoizeOne((assignee: Person | null) =>
		assignee
			? {
					avatarUrl: assignee.avatarUrl,
					name: assignee.displayName,
				}
			: null,
	);
	return (state: State, ownProps: OwnProps) => {
		const { intl } = ownProps;
		const {
			imageUrl,
			name,
			message,
			cardAmount,
			issueKey,
			mode,
			assigneeAccountId,
			projectKey,
			isFlagged,
		} = platformSwimlaneSelector(state)(ownProps.id) || {};

		const swimlaneMode = getSwimlaneMode(state);
		const isSwimlaneHeaderClickable =
			mode === SWIMLANE_BY_PARENT_ISSUE.id ||
			mode === SWIMLANE_BY_SUBTASK.id ||
			mode === SWIMLANE_BY_PROJECT.id;
		const issuesWithoutSwimlaneHeaderName = message
			? getIssuesWithoutSwimlaneHeaderName(message, intl, swimlaneMode, state)
			: null;

		const getParent = makeGetIssueParentForSwimlaneHeader(state);
		const issueParent = getParent(ownProps.id);

		const assignee =
			mode === SWIMLANE_BY_SUBTASK.id && assigneeAccountId
				? getPerson(state, assigneeAccountId)
				: null;
		const issueStatus = getSwimlaneHeaderIssueStatus(state, mode, ownProps.id);
		const isCMPBoard = getIsCMPBoard(state);

		// isDone here is used by the Swimlane component to show issue key strikethrough i.e. indicate issue resolution.
		// Moving away from `isSwimlaneBySubtaskParentDoneSelector` as it's very specific to covering all the edge cases required to correctly show the "Move to Done" button. It incorrectly strikethroughs issues when the issue is not resolved.
		// This change will also strikethrough here correctly for TMP (which previously we weren't doing at all).
		const isDone = isDoneIssueSelector(state)(ownProps.id);

		const icon = getIconFactory(swimlaneMode, imageUrl, isCMPBoard);

		return {
			icon,
			spotlightTargetId:
				ownProps.isFirstSwimlane && mode === SWIMLANE_BY_PARENT_ISSUE.id ? SPOTLIGHT_TARGET_ID : '',
			name: name || issuesWithoutSwimlaneHeaderName,
			// Don't show card amount for increment planning board
			showCardAmount: true,
			cardAmount,
			issueKey,
			issueStatus,
			isSwimlaneHeaderClickable,
			collapsed: isSwimlaneCollapsed(state, ownProps.id),
			externalId: 'software.board.swimlane',
			externalValues: getExternalValues(rapidViewIdSelector(state), projectIdSelector(state)),
			swimlaneMode,
			issueParent: issueParentFactory(issueParent),
			assignee: assigneeFactory(assignee),
			summaryTooltip: getSwimlaneSummaryTooltip(ownProps.id, swimlaneMode, intl),
			isSubtask: swimlaneMode === SWIMLANE_BY_SUBTASK.id && ownProps.id !== ChildlessSwimlane.id,
			link:
				isCMPBoard && swimlaneMode === SWIMLANE_BY_PROJECT.id && projectKey
					? `/browse/${projectKey}`
					: undefined,
			projectKey,
			isFlagged,
			isDone,
			customRightContent: ownProps.customRightContent,
		};
	};
};
type StateProps = ReturnType<ReturnType<typeof mapStateToProps>>;

const mapDispatchToProps = {
	swimlaneToggle,
	openIssueModal,
} as const;

const onClickFactory = memoizeOne(
	(
		issueKey: StateProps['issueKey'],
		dispatchPropsOpenIssueModal: DispatchProps['openIssueModal'],
		swimlaneMode: StateProps['swimlaneMode'],
	) =>
		(analyticsEvent: UIAnalyticsEvent) => {
			if (issueKey) {
				dispatchPropsOpenIssueModal(issueKey);
			}

			fireUiAnalytics(analyticsEvent, {
				name: 'board',
				attributes: { swimlaneMode },
			});
		},
);
const onToggleFactory = memoizeOne(
	(dispatchPropsWwimlaneToggle: DispatchProps['swimlaneToggle'], id: string) =>
		(event: SyntheticEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			dispatchPropsWwimlaneToggle(id);
			fireUIAnalytics(analyticsEvent, 'boardSwimlaneBar');
		},
);

export const mergeProps = (
	stateProps: StateProps,
	dispatchProps: DispatchProps,
	ownProps: OwnProps,
) => ({
	children: ownProps.children,
	wrapperStyle: ownProps.wrapperStyle,
	innerRef: ownProps.innerRef,
	...stateProps,
	onClick: onClickFactory(
		stateProps.issueKey,
		dispatchProps.openIssueModal,
		stateProps.swimlaneMode,
	),
	onToggle: onToggleFactory(dispatchProps.swimlaneToggle, ownProps.id),
	isMatrixLayout: ownProps.isMatrixLayout,
	isThemed: ownProps.isThemed,
});

export default flow(
	AnalyticsEventToProps('swimlaneBar', {
		onToggle: 'toggled',
	}),
	connect(mapStateToProps, mapDispatchToProps, mergeProps),
	injectIntl,
)(Swimlane);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueTypeIcon = styled.img({
	verticalAlign: 'middle',
	height: '1.25rem',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProjectIcon = styled.img({
	verticalAlign: 'middle',
	borderRadius: '3px',
	width: '20px',
	height: '20px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RequestTypeIcon = styled.img({
	verticalAlign: 'middle',
	height: '18px',
});
