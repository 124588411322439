import type { ReactNode } from 'react';
import { type Dispatch, bindActionCreators } from 'redux';
import memoizeOne from 'memoize-one';
import { ColumnType } from '@atlassian/jira-common-constants/src/column-types.tsx';
import { CARD_DND_TYPE } from '@atlassian/jira-platform-board-kit/src/common/constants/drag-drop/index.tsx';
import type {
	DraggableCardData,
	ColumnId,
} from '@atlassian/jira-platform-board-kit/src/common/types.tsx';
import Column from '@atlassian/jira-platform-board-kit/src/ui/column/draggable-column/index.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { Capability } from '../../../../common/capability/index.tsx';
import { CAN_RANK_COLUMNS } from '../../../../model/permission/permission-types.tsx';
import {
	SWIMLANE_BY_SUBTASK,
	SWIMLANE_CHILDLESS,
} from '../../../../model/swimlane/swimlane-modes.tsx';
import type { SwimlaneId } from '../../../../model/swimlane/swimlane-types.tsx';
import { cardDragOverTransitionRequest } from '../../../../state/actions/card/index.tsx';
import { columnRenameRequest } from '../../../../state/actions/column/rename/index.tsx';
import { getPermissionsSelector } from '../../../../state/selectors/board/board-permissions-selectors.tsx';
import { getCapability } from '../../../../state/selectors/capability/capability-selectors.tsx';
import {
	hasDifferentIssueTypeTransitionsInColumns,
	shouldDisableDropOnColumn,
} from '../../../../state/selectors/card-transitions/card-transitions-selectors.tsx';
import {
	draggingCardTargetColumnIdSelector,
	draggingCardHoverIntentSelector,
	isDragInSnapMode,
	hasNonGlobalTransitions,
	hasConditionsOnTransitions,
	draggingCardSourceColumnIdSelector,
	draggingCardSourceSwimlaneIdSelector,
	hasNoTransitionSelector,
	getAnyCardEditingSummary,
} from '../../../../state/selectors/card/card-selectors.tsx';
import {
	getColumnById,
	isDoneColumn,
	hasMultipleStatusesPerColumn,
} from '../../../../state/selectors/column/column-selectors.tsx';
import {
	getIsCMPBoard,
	getIssueProjects,
	isBoardRankable,
	getIsIncrementPlanningBoard,
} from '../../../../state/selectors/software/software-selectors.tsx';
import { getSwimlaneMode } from '../../../../state/selectors/swimlane/swimlane-mode-selectors.tsx';
import {
	getColumnTheme,
	isColumnUpdating,
	isAnyColumnUpdating,
	getCardDndType,
	isInlineColumnEditEnabled,
	isSwimlaneTransitionZoneEnabledForSubtask,
	isDraggingSubtaskSelector,
} from '../../../../state/selectors/work/work-selectors.tsx';
import type { State } from '../../../../state/types.tsx';
import { DROPZONE_VIRTUAL, DROPZONE_STANDARD } from './constants.tsx';
import ColumnTransitionZonesContainer from './transition-zones-container/index.tsx';

type OwnProps = {
	isLastSwimlane: boolean;
	id: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	header: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	children: any;
	draggableIndex: number;
	swimlaneId: SwimlaneId | undefined;
	footer?: ReactNode;
	dropZoneType?: string;
	onColumnMove?: (
		columnId: ColumnId,
		sourceColumnIndex: number,
		destinationColumnIndex: number,
	) => void;
	isMatrixLayout?: boolean;
};

const isCardDroppableFactory = memoizeOne(
	(
		isIncrementPlanningBoard: boolean,
		isCMPBoard: boolean,
		swimlaneId: string | undefined,
		isRankable: boolean,
		id: number,
		showTransitionZonesOnThisColumn: boolean,
		draggingCardHoverIntent: boolean,
	) =>
		(draggableCardData: DraggableCardData) => {
			if (isIncrementPlanningBoard) {
				return true;
			}
			if (isCMPBoard) {
				if (swimlaneId && draggableCardData.swimlaneId !== swimlaneId) {
					return false;
				}

				if (!isRankable && draggableCardData.columnId === id) {
					return false;
				}
			}

			if (showTransitionZonesOnThisColumn && !draggingCardHoverIntent) {
				return false;
			}

			// Childless cards cannot be moved to a subtask type swimlane and vice-versa
			const sourceSwimlaneId = draggableCardData.swimlaneId;
			if (
				swimlaneId &&
				((swimlaneId === SWIMLANE_CHILDLESS && sourceSwimlaneId !== SWIMLANE_CHILDLESS) ||
					(swimlaneId !== SWIMLANE_CHILDLESS && sourceSwimlaneId === SWIMLANE_CHILDLESS))
			) {
				return false;
			}

			return true;
		},
);

export const mapStateToProps = () => {
	return (state: State, ownProps: OwnProps) => {
		const { id, isLastSwimlane, swimlaneId } = ownProps;
		const column = getColumnById(state, id);
		const childrenDropZoneType = ownProps.dropZoneType || getCardDndType(state)(isLastSwimlane);

		const draggingCardSourceColumnId = draggingCardSourceColumnIdSelector(state);
		const draggingCardSourceSwimlaneId = draggingCardSourceSwimlaneIdSelector(state);
		const swimlaneMode = getSwimlaneMode(state);

		const anyColumnUpdating = isAnyColumnUpdating(state);
		const hasDropZones =
			hasNonGlobalTransitions(state) ||
			hasMultipleStatusesPerColumn(state) ||
			hasDifferentIssueTypeTransitionsInColumns(state);

		const shouldShowDropZoneForSwimlane = () => {
			if (swimlaneMode === SWIMLANE_BY_SUBTASK.id) {
				return isSwimlaneTransitionZoneEnabledForSubtask(state)(isLastSwimlane);
			}
			return true;
		};
		const isIncrementPlanningBoard = getIsIncrementPlanningBoard(state);
		const isCMPBoard = getIsCMPBoard(state);
		const isRankable = isBoardRankable(state);
		const isDraggingSubtask = isDraggingSubtaskSelector(state);
		const shouldDisableRank = !isBoardRankable(state);
		const hasNoTransition = isCMPBoard && hasNoTransitionSelector(state);
		const isSourceColumn = draggingCardSourceColumnId === id;

		// for a multi-project board, when at lease one project is not rankable, we should show the transition zones
		const hasOneNotRankableProject =
			isBoardRankable(state) &&
			Object.values(getIssueProjects(state)).some((project) => !project.canRank);

		// Software CMP projects are the only ones that can disable the transition in same column.
		const disableTransitionsInSameColumn =
			getCapability(state)(Capability.DISABLE_TRANSITION_IN_SAME_COLUMN) && isSourceColumn;
		const disableTransitionAcrossSwimlane =
			swimlaneId && swimlaneId !== draggingCardSourceSwimlaneId;

		const shouldShowTransitionZones = () => {
			if (isIncrementPlanningBoard) {
				return false;
			}
			if (
				anyColumnUpdating ||
				draggingCardSourceColumnId == null ||
				!shouldShowDropZoneForSwimlane()
			) {
				return false;
			}

			if (isCMPBoard) {
				if (disableTransitionAcrossSwimlane || disableTransitionsInSameColumn) {
					return false;
				}

				if (isDraggingSubtask || shouldDisableRank || hasNoTransition || hasOneNotRankableProject) {
					return true;
				}
			}

			return hasDropZones || hasConditionsOnTransitions(state);
		};

		const showTransitionZonesOnThisColumn =
			column?.type === ColumnType.STATUS && shouldShowTransitionZones();

		const draggingCardHoverIntent = draggingCardHoverIntentSelector(state);
		const isDraggingOverThisColumnWithHoverIntent =
			showTransitionZonesOnThisColumn &&
			draggingCardTargetColumnIdSelector(state) === id &&
			draggingCardHoverIntent;

		const TransitionZones = showTransitionZonesOnThisColumn
			? ColumnTransitionZonesContainer
			: undefined;

		const shouldDisableDrop = () => {
			if (isIncrementPlanningBoard) {
				return false;
			}
			if (isCMPBoard) {
				if (shouldDisableRank && draggingCardSourceColumnId === id) {
					return true;
				}

				if (disableTransitionAcrossSwimlane) {
					return true;
				}
			}

			return (
				anyColumnUpdating ||
				(isDragInSnapMode(state) && hasDropZones) ||
				(showTransitionZonesOnThisColumn && shouldDisableDropOnColumn(state)(id))
			);
		};

		const isDraggable =
			getPermissionsSelector(state)[CAN_RANK_COLUMNS] &&
			!isIncrementPlanningBoard &&
			!anyColumnUpdating &&
			isInlineColumnEditEnabled(state) &&
			!swimlaneId &&
			getAnyCardEditingSummary(state) === false;

		return {
			isDraggable,
			appearance: getColumnTheme(state, id),
			isUpdating: isColumnUpdating(state)(id),
			isDone: isDoneColumn(column),
			isFlexible: getCapability(state)(Capability.FLEXIBLE_BOARD_COLUMNS),
			// passing ownProps
			id: ownProps.id,
			header: ownProps.header || null,
			children: ownProps.children || null,
			draggableIndex: ownProps.draggableIndex,
			footer: ownProps.footer || null,
			childrenDropZoneType,
			dropZoneMode: !__SERVER__ ? DROPZONE_VIRTUAL : DROPZONE_STANDARD,
			isLastSwimlane: ownProps.isLastSwimlane,
			isOutlined: isDraggingOverThisColumnWithHoverIntent,
			TransitionZones,
			isDropDisabled: shouldDisableDrop(),
			swimlaneId,
			isCardDroppable: isCardDroppableFactory(
				isIncrementPlanningBoard,
				isCMPBoard,
				swimlaneId,
				isRankable,
				id,
				showTransitionZonesOnThisColumn,
				draggingCardHoverIntent,
			),
		};
	};
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) =>
	({
		dispatch,
		onRename: bindActionCreators(columnRenameRequest, dispatch),
		onDragLeave: (dragType: string) => {
			if (dragType === CARD_DND_TYPE) {
				dispatch(cardDragOverTransitionRequest(null, ownProps.id, false));
			}
		},
	}) as const;

export default connect(mapStateToProps, mapDispatchToProps)(Column);
