import React, { type KeyboardEvent, useMemo, useCallback, type RefCallback } from 'react';
import Button from '@atlaskit/button/new';
import IssueIcon from '@atlaskit/icon/core/migration/issue';
import IssuesIcon from '@atlaskit/icon/core/migration/issues';
import type { TriggerProps } from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { metrics } from '@atlassian/browser-metrics';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueLinkedModalEntrypointStore } from '@atlassian/jira-issue-links-common/src/controllers/issue-links-modal-entrypoint-store/index.tsx';
import { issueLinksDetailsContentEntryPoint } from '@atlassian/jira-issue-links-details-content/entrypoint.tsx';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import { fireUIAnalytics, type UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import type { srcAsyncIssueLinksDetailsContentQuery$variables } from '@atlassian/jira-relay/src/__generated__/srcAsyncIssueLinksDetailsContentQuery.graphql';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { useIssueLinkedStatsActions } from '../../../controllers/main.tsx';
import { useFocusOnIssueLinksStatsEffect } from '../../../controllers/use-focus-on-issue-links/index.tsx';
import { useHandleOnIssueLinksStatsClose } from '../../../controllers/use-handle-on-issue-links-stats-close/index.tsx';
import { IssueLinksDetailsErrorFallback } from './issue-links-details-error-fallback/index.tsx';
import { IssueLinksDetailsPopupSkeleton } from './issue-links-details-popup-skeleton/index.tsx';
import { LinkIssuePopup } from './link-issue-popup/index.tsx';
import { messages } from './messages.tsx';
import type { IssueLinksPopupProps } from './types.tsx';

const MAX_ISSUE_LINKS_COUNT = 50;
const asyncIssueLinksDetailsLoad = metrics.interaction({
	key: 'issue-links-details-content-load',
});
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

export const IssueLinksDetailsPopupEntryPointContainer = ({
	issueKey,
	isOpen,
	isFocusOnTrigger = false,
	issueId,
	issueSummary,
	issueType,
	issueLinkCount,
	feedbackCollectorPrefix,
	onIssueUnlink,
	canUserLinkIssue,
	onFocusToTrigger = noop,
	iconLabel = '',
	withDueDate = false,
	sourceProductType,
	onSuccessfulIssueLink = noop,
	isLinkIssuePopupEnabled = false,
}: IssueLinksPopupProps) => {
	const { handleOnClose, handleOnOpenLinkedIssueDetailsPopup } = useIssueLinkedStatsActions();
	const [state, { setIssueLinkModalEntryPointSubject }] = useIssueLinkedModalEntrypointStore();
	const cloudId = useCloudId();

	const entryPointParams: srcAsyncIssueLinksDetailsContentQuery$variables = useMemo(
		() => ({
			cloudId,
			issueKey,
			// Fetch 1 more than required so that we show view all linked issues button
			issueCount: MAX_ISSUE_LINKS_COUNT + 1,
			withDueDate,
			fields: ['issuetype', 'summary', 'status', ...(withDueDate ? ['duedate'] : [])],
		}),
		[cloudId, issueKey, withDueDate],
	);

	const { entryPointReferenceSubject, entryPointActions, stopMetric } = useEntryPoint(
		issueLinksDetailsContentEntryPoint,
		entryPointParams,
		asyncIssueLinksDetailsLoad,
	);

	const triggerRef = useEntryPointButtonTrigger(entryPointActions, !isOpen);

	const handleButtonClicked = useCallback(
		(event: React.MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			event.stopPropagation();

			setIssueLinkModalEntryPointSubject({ issueData: null });

			// Click the button to open details popup, and click again to close it.
			if (isOpen) {
				handleOnClose();
			} else {
				handleOnOpenLinkedIssueDetailsPopup(issueKey);
			}

			fireUIAnalytics(analyticsEvent, 'button clicked', 'issueLinksStats', {
				isToOpenPopup: !isOpen,
			});
		},
		[
			handleOnClose,
			handleOnOpenLinkedIssueDetailsPopup,
			isOpen,
			issueKey,
			setIssueLinkModalEntryPointSubject,
		],
	);

	const updateTriggerRefOnIssueLinksStatsClose = useHandleOnIssueLinksStatsClose({
		isIssueLinkStatsOpen: isOpen,
		isLinkIssueModalOpen: Boolean(state.issueData?.issueKey === issueKey),
	});

	const handleOnIssueUnlink = useCallback(
		(issueIds: IssueId[]) => {
			handleOnClose();
			onIssueUnlink?.(issueIds);
			onFocusToTrigger(true);
		},
		[handleOnClose, onFocusToTrigger, onIssueUnlink],
	);

	const stopEventPropagation = (event: React.SyntheticEvent) => event.stopPropagation();
	const onPopupKeyPressed = useCallback(
		(event: KeyboardEvent) => {
			stopEventPropagation(event);
			// According to the source code in Atlaskit/popup/src/use-close-manager.tsx#lines-61
			// The internal implementation of closing the Popup is exactly the same as below
			// And it triggers *no side effect* other than call the onClose callback directly,
			// which in this component simply calls handlePopupClose. This should be safe.
			// Review this in case any odd behaviours are observed when closing the popup with the escape key.
			if (event.key === 'Escape') handleOnClose();
		},
		[handleOnClose],
	);

	const runtimeProps = useMemo(
		() => ({
			cloudId,
			issueKey,
			issueSummary,
			issueId,
			issueType,
			canUserLinkIssue,
			onIssueUnlink: handleOnIssueUnlink,
			onPopupClose: handleOnClose,
			maxIssuesToDisplay: MAX_ISSUE_LINKS_COUNT,
			feedbackCollectorPrefix,
			onReady: stopMetric,
			sourceProductType,
		}),
		[
			feedbackCollectorPrefix,
			canUserLinkIssue,
			cloudId,
			handleOnClose,
			handleOnIssueUnlink,
			issueId,
			issueKey,
			issueSummary,
			issueType,
			sourceProductType,
			stopMetric,
		],
	);

	const openTriggerRef = useFocusOnIssueLinksStatsEffect({
		isFocusOnTrigger,
		onFocusToTrigger,
	});

	const { formatMessage } = useIntl();

	const triggerButton = useCallback(
		(triggerProps: TriggerProps) => (
			<Button
				{...triggerProps}
				appearance="subtle"
				spacing="compact"
				testId={`issue-links-stats.ui.issue-links-stats-button.custom-theme-button-${issueKey}`}
				ref={(element) => {
					triggerRef(element);
					openTriggerRef(element);
					updateTriggerRefOnIssueLinksStatsClose(element);
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					(triggerProps.ref as RefCallback<HTMLElement>)(element);
				}}
				isSelected={isOpen}
				onClick={handleButtonClicked}
				onContextMenu={stopEventPropagation}
				onKeyDown={stopEventPropagation}
				onMouseDown={(event: React.SyntheticEvent) => {
					event.preventDefault();
					event.stopPropagation();
				}}
				aria-label={formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.issueLinksButtonIssueTermRefresh
						: messages.issueLinksButton,
					{ issueLinkCount },
				)}
				iconBefore={() =>
					isVisualRefreshEnabled() ? (
						<IssueIcon
							LEGACY_size="small"
							color={isOpen ? token('color.icon.accent.blue') : token('color.icon')}
							label=""
						/>
					) : (
						<IssuesIcon
							LEGACY_size="small"
							color={isOpen ? token('color.icon.accent.blue') : token('color.icon')}
							label={iconLabel}
						/>
					)
				}
			>
				{issueLinkCount}
			</Button>
		),
		[
			issueKey,
			isOpen,
			handleButtonClicked,
			formatMessage,
			issueLinkCount,
			iconLabel,
			triggerRef,
			openTriggerRef,
			updateTriggerRefOnIssueLinksStatsClose,
		],
	);

	const showLinkIssuePopup = Boolean(
		canUserLinkIssue &&
			isLinkIssuePopupEnabled &&
			state.issueData?.issueId === String(issueId) &&
			state.issueData?.issueKey === issueKey,
	);

	const DetailsContent = useCallback(() => {
		if (showLinkIssuePopup) {
			return <LinkIssuePopup onSuccessfulIssueLink={onSuccessfulIssueLink} />;
		}
		return (
			<Box
				padding="space.200"
				paddingInlineEnd="space.050"
				xcss={ContainerStyles}
				onContextMenu={stopEventPropagation}
				onKeyDown={onPopupKeyPressed}
			>
				<JiraEntryPointContainer
					entryPointReferenceSubject={entryPointReferenceSubject}
					id="async-issue-links-details-content"
					runtimeProps={runtimeProps}
					packageName="@atlassian/jira-issue-links-details-content"
					teamName="jira-werewolves"
					errorFallback={({ error }) => (
						<IssueLinksDetailsErrorFallback
							callback={handleOnClose}
							error={error}
							testId="issue-links-stats.ui.issue-links-stats-button.issue-links-details-popup-entrypoint-container"
						/>
					)}
					fallback={
						<IssueLinksDetailsPopupSkeleton
							noPadding
							smallerWidth
							testId="issue-links-stats.ui.issue-links-stats-button.issue-links-details-popup-entrypoint-container.skeleton-loader"
						/>
					}
				/>
			</Box>
		);
	}, [
		entryPointReferenceSubject,
		handleOnClose,
		onPopupKeyPressed,
		onSuccessfulIssueLink,
		runtimeProps,
		showLinkIssuePopup,
	]);

	return (
		<Popup
			isOpen={isOpen || showLinkIssuePopup}
			onClose={handleOnClose}
			placement="bottom-start"
			messageId="issue-links-stats.ui.issue-links-stats-button.issue-links-details-popup-entrypoint-container.popup"
			messageType="transactional"
			testId="issue-links-stats.ui.issue-links-stats-button.issue-links-details-popup-entrypoint-container.popup"
			trigger={triggerButton}
			content={DetailsContent}
			label={formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.issueLinksPopupIssueTermRefresh
					: messages.issueLinksPopup,
			)}
		/>
	);
};
IssueLinksDetailsPopupEntryPointContainer.displayName = 'IssueLinksDetailsPopupEntryPointContainer';

const ContainerStyles = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 64}px`,
});
