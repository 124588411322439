import { useIntl } from '@atlassian/jira-intl';
import {
	type MenuNode,
	MenuNodeType,
} from '@atlassian/jira-software-context-menu/src/common/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	MOVE_DOWN_WITHIN_COLUMN_MENU_ID,
	MOVE_TO_BACKLOG_MENU_ID,
	MOVE_TO_BOTTOM_OF_COLUMN_MENU_ID,
	MOVE_TO_MENU_ID,
	MOVE_TO_TOP_OF_COLUMN_MENU_ID,
	MOVE_UP_WITHIN_COLUMN_MENU_ID,
} from '../../constants.tsx';
import messages from '../../messages.tsx';
import { useScreenReader } from '../../../../../screen-reader-context/index.tsx';
import type { ContentProps } from './types.tsx';

export const useMoveToContent = ({
	onMoveToBacklog,
	onMoveToTop,
	onMoveToBottom,
	onMoveUp,
	onMoveDown,
	isMoveUpActionVisible,
	isMoveDownActionVisible,
	isMoveToBacklogEnabled,
	isMoveToTopActionVisible,
	isMoveToBottomActionVisible,
	onMouseEnter,
}: ContentProps): MenuNode[] => {
	const { formatMessage } = useIntl();

	const moveToActions: MenuNode[] = [];

	const { setScreenReaderText } = useScreenReader();

	if (isMoveToBacklogEnabled) {
		moveToActions.push({
			type: MenuNodeType.Item,
			id: MOVE_TO_BACKLOG_MENU_ID,
			label: fg('move_work_item_context_menu')
				? formatMessage(messages.backlog)
				: formatMessage(messages.backlogOld),
			onClick: onMoveToBacklog,
		});
	}

	if (isMoveToTopActionVisible) {
		moveToActions.push({
			type: MenuNodeType.Item,
			id: MOVE_TO_TOP_OF_COLUMN_MENU_ID,
			label: fg('move_work_item_context_menu')
				? formatMessage(messages.topOfTheColumn)
				: formatMessage(messages.topOfTheColumnOld),
			onClick: () => {
				setScreenReaderText?.(
					fg('move_work_item_context_menu')
						? formatMessage(messages.movedToTopOfTheColumn)
						: formatMessage(messages.movedToTopOfTheColumnOld),
				);
				onMoveToTop();
			},
		});
	}
	if (fg('move_work_item_context_menu')) {
		if (isMoveUpActionVisible) {
			moveToActions.push({
				type: MenuNodeType.Item,
				id: MOVE_UP_WITHIN_COLUMN_MENU_ID,
				label: formatMessage(messages.up),
				onClick: () => {
					if (onMoveUp) {
						setScreenReaderText?.(formatMessage(messages.movedUp));
						onMoveUp();
					}
				},
			});
		}
		if (isMoveDownActionVisible) {
			moveToActions.push({
				type: MenuNodeType.Item,
				id: MOVE_DOWN_WITHIN_COLUMN_MENU_ID,
				label: formatMessage(messages.down),
				onClick: () => {
					if (onMoveDown) {
						setScreenReaderText?.(formatMessage(messages.movedDown));
						onMoveDown();
					}
				},
			});
		}
	}
	if (isMoveToBottomActionVisible) {
		moveToActions.push({
			type: MenuNodeType.Item,
			id: MOVE_TO_BOTTOM_OF_COLUMN_MENU_ID,
			label: fg('move_work_item_context_menu')
				? formatMessage(messages.bottomOfTheColumn)
				: formatMessage(messages.bottomOfTheColumnOld),
			onClick: () => {
				setScreenReaderText?.(
					fg('move_work_item_context_menu')
						? formatMessage(messages.movedToBottomOfTheColumn)
						: formatMessage(messages.movedToBottomOfTheColumnOld),
				);
				onMoveToBottom();
			},
		});
	}

	return moveToActions.length > 0
		? [
				{
					type: MenuNodeType.Item,
					label: fg('move_work_item_context_menu')
						? formatMessage(messages.moveWorkItem)
						: formatMessage(messages.moveTo),
					id: MOVE_TO_MENU_ID,
					children: moveToActions,
					onMouseEnter,
				},
			]
		: [];
};
