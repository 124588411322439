import type { SwimlaneModeId } from '@atlassian/jira-platform-board-kit/src/ui/swimlane/types.tsx';
import type { JiraBoardSwimlaneStrategy } from '@atlassian/jira-relay/src/__generated__/FilterPopupContentQuery.graphql.ts';
import {
	NO_SWIMLANE,
	SWIMLANE_BY_ASSIGNEE,
	SWIMLANE_BY_PARENT_ISSUE,
	SWIMLANE_BY_SUBTASK,
	SWIMLANE_BY_JQL,
	SWIMLANE_BY_PROJECT,
	SWIMLANE_BY_ASSIGNEE_UNASSIGNED_FIRST,
	SWIMLANE_BY_TEAM,
	SWIMLANE_BY_REQUEST_TYPE,
} from '../../model/swimlane/swimlane-modes.tsx';

export type SwimlaneTypeName =
	| 'none'
	| 'jql'
	| 'assignee'
	| 'assigneeunassignedfirst'
	| 'issueparent'
	| 'issuechildren'
	| 'project'
	| 'team'
	| 'requesttype';

export const swimlaneModeToStrategies: { [key in SwimlaneModeId]: SwimlaneTypeName } = {
	NO_SWIMLANE: 'none',
	SWIMLANE_BY_JQL: 'jql',
	SWIMLANE_BY_ASSIGNEE: 'assignee',
	SWIMLANE_BY_ASSIGNEE_UNASSIGNED_FIRST: 'assigneeunassignedfirst',
	SWIMLANE_BY_PARENT_ISSUE: 'issueparent',
	SWIMLANE_BY_SUBTASK: 'issuechildren',
	SWIMLANE_BY_PROJECT: 'project',
	SWIMLANE_BY_TEAM: 'team',
	SWIMLANE_BY_REQUEST_TYPE: 'requesttype',
} as const;

export const swimlaneModeToStrategiesFilterRefinement: {
	[key in SwimlaneModeId]: JiraBoardSwimlaneStrategy;
} = {
	NO_SWIMLANE: 'NONE',
	SWIMLANE_BY_JQL: 'NONE',
	SWIMLANE_BY_ASSIGNEE: 'ASSIGNEE_UNASSIGNED_LAST',
	SWIMLANE_BY_ASSIGNEE_UNASSIGNED_FIRST: 'ASSIGNEE_UNASSIGNED_FIRST',
	SWIMLANE_BY_PARENT_ISSUE: 'ISSUE_PARENT',
	SWIMLANE_BY_SUBTASK: 'ISSUE_CHILDREN',
	SWIMLANE_BY_PROJECT: 'PROJECT',
	SWIMLANE_BY_TEAM: 'NONE',
	SWIMLANE_BY_REQUEST_TYPE: 'REQUEST_TYPE',
} as const;

export const swimlaneStrategiesToMode: { [key in SwimlaneTypeName]: SwimlaneModeId } = {
	none: NO_SWIMLANE.id,
	jql: SWIMLANE_BY_JQL.id,
	assignee: SWIMLANE_BY_ASSIGNEE.id,
	issueparent: SWIMLANE_BY_PARENT_ISSUE.id,
	assigneeunassignedfirst: SWIMLANE_BY_ASSIGNEE_UNASSIGNED_FIRST.id,
	issuechildren: SWIMLANE_BY_SUBTASK.id,
	project: SWIMLANE_BY_PROJECT.id,
	team: SWIMLANE_BY_TEAM.id,
	requesttype: SWIMLANE_BY_REQUEST_TYPE.id,
};

export const transformSwimlaneModeToApi = (swimlaneModeId: SwimlaneModeId): string =>
	swimlaneModeToStrategies[swimlaneModeId] || 'none';
