import { useMemo } from 'react';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { JSM_BOARD_ROUTES_SET } from '@atlassian/jira-common-constants/src/spa-routes-sets.tsx';
import type { RouteName } from '@atlassian/jira-route-name-types/src/RouteName.tsx';

export const useIsJSMBoard = () => {
	const { name } = useCurrentRoute();

	const isJSMBoardRoute = (routeName: RouteName) =>
		fg('jsm_views_inside_queues_-_main_flag')
			? JSM_BOARD_ROUTES_SET.has(routeName)
			: routeName === 'servicedesk-boards';

	return useMemo(() => isJSMBoardRoute(name), [name]);
};
