import React, { type ReactNode } from 'react';
import { Inline, Text, xcss } from '@atlaskit/primitives';
import ErrorIcon from '@atlaskit/icon/core/error';
import Pressable from '@atlaskit/primitives/pressable';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export const onRefresh = () => {
	if (typeof window !== 'undefined') {
		window.location.reload();
	}
};

const ErrorComponent = () => {
	const { formatMessage } = useIntl();

	return (
		<Inline alignBlock="center" space="space.100" xcss={errorComponentStyles}>
			<ErrorIcon label="" color={token('color.icon.danger')} />
			<Text>
				{formatMessage(messages.errorMessage, {
					link: (linkText: ReactNode[]) => (
						<Pressable onClick={onRefresh} xcss={linkStyles}>
							{linkText}
						</Pressable>
					),
				})}
			</Text>
		</Inline>
	);
};

const linkStyles = xcss({
	color: 'color.link',
	textDecoration: 'underline',
	font: token('font.body'),
	backgroundColor: 'color.background.neutral.subtle',
	padding: 'space.0',
	':hover': {
		textDecoration: 'none',
	},
	':active': {
		color: 'color.link.pressed',
	},
});

const errorComponentStyles = xcss({
	height: '32px',
});

export default ErrorComponent;
