import React, { useCallback, type KeyboardEvent, type MouseEvent } from 'react';
import { ButtonItem } from '@atlaskit/menu';
import CheckIcon from '@atlaskit/icon/core/migration/check-mark--check';
import { token } from '@atlaskit/tokens';
import {
	fireUIAnalytics,
	useAnalyticsEvents,
	type UIAnalyticsEvent,
} from '@atlassian/jira-product-analytics-bridge';
import { useIntl } from '@atlassian/jira-intl';
import { useBoardDispatch } from '../../../../../../../state/index.tsx';
import { updateOldDoneIssuesCutoffRequest } from '../../../../../../../state/actions/board/old-done-issues-cutoff/index.tsx';
import messages from './messages.tsx';

type ItemOldDoneCutoffSelectProps = {
	option: {
		value: string;
		label: string;
	};
	selectedValue: string;
	onClick: (event: MouseEvent | KeyboardEvent, analyticsEvent: UIAnalyticsEvent) => void;
};

export const ItemOldDoneCutoffSelect = (props: ItemOldDoneCutoffSelectProps) => {
	const dispatch = useBoardDispatch();
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { option, selectedValue, onClick } = props;
	const iconCheckmark = (
		<CheckIcon
			label={formatMessage(messages.selected)}
			LEGACY_size="medium"
			spacing="spacious"
			color={token('color.text.selected')}
		/>
	);

	const handleOnClick = useCallback(
		(event: MouseEvent | KeyboardEvent) => {
			const analyticsEvent = createAnalyticsEvent({
				actionSubject: 'dropdownMenuItem',
				action: 'clicked',
			});
			dispatch(updateOldDoneIssuesCutoffRequest(option.value));
			onClick(event, analyticsEvent);
			fireUIAnalytics(analyticsEvent);
		},
		[createAnalyticsEvent, dispatch, option.value, onClick],
	);
	return (
		<ButtonItem
			key={option.value}
			iconAfter={option.value === selectedValue ? iconCheckmark : null}
			onClick={handleOnClick}
		>
			{option.label}
		</ButtonItem>
	);
};
