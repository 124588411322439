import React from 'react';
import { styled } from '@compiled/react';
import isNil from 'lodash/isNil';
import Button from '@atlaskit/button';
import EmptyState from '@atlaskit/empty-state';
import errorImageUrl from '@atlassian/jira-common-assets/src/assets/error/something-wrong.svg';
import { expVal } from '@atlassian/jira-feature-experiments';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from './messages.tsx';
import { BoardSizeLimitErrorWithNoCTA } from './size-limit-error-no-cta/index.tsx';

export type Props = Intl & {
	projectKey: string;
	baseUrl: string;
	isCMPBoard: boolean;
	savedFilterId?: number;
};

const BoardSizeLimitError = ({
	intl,
	projectKey,
	baseUrl,
	isCMPBoard = false,
	savedFilterId,
}: Props) => {
	const description = isCMPBoard
		? intl.formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.descriptionCmpIssueTermRefresh
					: messages.descriptionCmp,
			)
		: intl.formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.descriptionIssueTermRefresh
					: messages.description,
			);

	const url =
		isCMPBoard && !isNil(savedFilterId)
			? `${baseUrl}/issues/?filter=${savedFilterId}`
			: `${baseUrl}/issues/?jql=${encodeURIComponent(`project = ${projectKey} ORDER BY rank ASC`)}`;

	const buttonText = isCMPBoard
		? intl.formatMessage(messages.editBoardFilter)
		: intl.formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.goToSearchIssueTermRefresh
					: messages.goToSearch,
			);

	return (
		<Container>
			<EmptyState
				imageUrl={errorImageUrl}
				header={intl.formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.headerIssueTermRefresh
						: messages.header,
				)}
				description={description}
				primaryAction={
					<Button href={url} appearance="primary">
						{buttonText}
					</Button>
				}
			/>
		</Container>
	);
};

const BoardSizeLimitErrorEmptyState = (props: Props) => {
	const { name } = useCurrentRoute();

	const showErrorWithNoCTA = name === 'servicedesk-boards';

	return (
		<Container>
			{showErrorWithNoCTA ? (
				<BoardSizeLimitErrorWithNoCTA intl={props.intl} />
			) : (
				<BoardSizeLimitError {...props} />
			)}
		</Container>
	);
};

const BoardSizeLimitErrorEmptyStateWithIntl = injectIntl(BoardSizeLimitErrorEmptyState);
export default BoardSizeLimitErrorEmptyStateWithIntl;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	width: '100%',
});
