import type { ReactNode, ReactElement } from 'react';
import type { AnalyticsEventPayload, UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { BulkOpsFlow } from '@atlassian/jira-bulk-operations-analytics/src/common/types.tsx';

export type Position = {
	x: number;
	y: number;
};

export type AnalyticsPayload = {
	action?: string;
	actionSubject?: string;
	actionSubjectId?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	attributes?: Record<string, any>;
};

export type AnalyticsMapping = Record<string, string | AnalyticsPayload>;

export type AnalyticsProps = {
	selectedCardIds: (number | string)[];
	cardId: number | string;
	/**
	 * Map MenuNode ids to an ActionSubjectId analytics property or an entire analytics object.
	 */
	analyticsMapping?: AnalyticsMapping;
	bulkOpsFlow?: BulkOpsFlow | null;
};

export type NodeAnalytics =
	| AnalyticsPayload
	| ((payload: AnalyticsEventPayload) => AnalyticsEventPayload);

export const MenuNodeType = {
	Item: 'item',
	Node: 'node',
	Separator: 'separator',
	Section: 'section',
	Search: 'search',
	BulkIndicator: 'bulkIndicator',
} as const;

export type MenuNodeType = (typeof MenuNodeType)[keyof typeof MenuNodeType];

export type MenuItemNodeArgs = {
	scope: string;
	analyticsEvent: UIAnalyticsEvent;
	closeMenu: () => void;
	onClick: (menuNode: MenuNode, analyticsEvent: UIAnalyticsEvent) => void;
	onMouseEnter: (node: MenuNode) => void;
};

/**
 * A menu item has a label, ID (path), icon and can be used to render custom components
 */
export type MenuItemNode = {
	type: typeof MenuNodeType.Node;
	id: string;
	/**
	 * Optional analytics updater. Either an object to add to the
	 * underlying analytics event or a transformer function which receives the
	 * exiting event and returns a new one.
	 */
	analytics?: NodeAnalytics;
	node: ReactNode | ((arg: MenuItemNodeArgs) => ReactNode);
};

/**
 * A menu item has a label, ID (path), icon and optionally children.
 */
export type MenuItem = {
	type: typeof MenuNodeType.Item;
	id: string;
	label: string | (() => ReactNode);
	icon?: ReactNode;
	children?: MenuNode[];
	element?: ReactElement<{ hasSearch?: boolean }>;
	/**
	 * Optional analytics updater. Either an object to add to the
	 * underlying analytics event or a transformer function which receives the
	 * exiting event and returns a new one.
	 */
	analytics?: NodeAnalytics;
	onClick?: (analyticsEvent: UIAnalyticsEvent) => void;
	onMouseEnter?: () => void;
	submenuWidth?: number;
	shouldOverflowChildren?: boolean;
	/**
	 * Optional element to render after the menu item label, usually a badge or an icon.
	 */
	elemAfterLabel?: ReactNode;
};

/**
 * A section has a label, path and children. This renders as a non-clickable
 * heading.
 */
export type MenuSection = {
	type: typeof MenuNodeType.Section;
	id: string;
	label: string;
	children: MenuItem[];
};

type MenuItemSeparator = {
	id: string;
	type: typeof MenuNodeType.Separator;
};

type MenuBulkIndicator = {
	id: string;
	type: typeof MenuNodeType.BulkIndicator;
	selectedCount: number;
};

type MenuItemSearchBase = {
	type: typeof MenuNodeType.Search;
	id: string;
	autoFocus?: boolean;
	onChange?: (value: string) => void;
	hasSearch?: boolean;
	maxViewportItems?: number;
	children: MenuItem[];
	ariaLabel?: string;
	/**
	 * Indicates that the search results are fetched via
	 * an external async request. When true, isLoading is
	 * required
	 * */
	isAsyncSearch: boolean;
};

type MenuItemSearchSync = MenuItemSearchBase & {
	isAsyncSearch: false;
	isLoading?: boolean;
};

type MenuItemSearchAsync = MenuItemSearchBase & {
	isAsyncSearch: true;
	isLoading: boolean;
};

export type MenuItemSearch = MenuItemSearchSync | MenuItemSearchAsync;

export type MenuNode =
	| MenuItem
	| MenuSection
	| MenuItemNode
	| MenuItemSeparator
	| MenuItemSearch
	| MenuBulkIndicator;

type MenuState = {
	scope: string;
	position: Position;
	openPath: string | undefined;
	/** When set to true, the menu item will receive the focus */
	forcedFocusPath: string | undefined;
};

export type ContextMenuContainerProps = AnalyticsProps;

export type LastAction = {
	action: string;
	scope?: string;
};

export type State = {
	menus: { [scope: string]: MenuState | null };
	openMenu: string | null;
	persistentFocus?: boolean;
	analytics: Omit<AnalyticsProps, 'cardId'>;
	lastAction: LastAction;
};

export type OnOpenContextMenuParams = {
	position: { x: number; y: number };
	analyticsEvent: UIAnalyticsEvent;
};

export type OpenContextMenuRef = {
	onOpenContextMenu: ({ position, analyticsEvent }: OnOpenContextMenuParams) => void;
};

export type MenuJSErrorBoundaryProps = {
	id: string;
	packageName: string;
	teamName: string;
};

export type OpenMenuParams = {
	position: Position;
	analyticsEvent: UIAnalyticsEvent;
	scope: string;
	persistentFocus?: boolean;
	openedFrom: 'rightClick' | 'meatball';
};

export type ContextMenuParams = {
	scope: string;
	openContextMenuRef: React.Ref<OpenContextMenuRef>;
};

export type MenuRendererProps = {
	scope: string;
	menuItems: MenuNode[];
	triggerRef?: React.RefObject<HTMLButtonElement>;
	shouldRenderToParent?: boolean;
};

export type CommonMenuItemProps = {
	menuItem: MenuItem;
	onClick: (menuNode: MenuNode, analyticsEvent: UIAnalyticsEvent) => void;
	onMouseEnter?: (node: MenuNode) => void;
};
