import { useCallback } from 'react';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	type MenuNode,
	type MenuItem,
	MenuNodeType,
} from '@atlassian/jira-software-context-menu/src/common/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';

type Props = {
	menuItemProps: Omit<MenuItem, 'type'>;
	fieldType: string;
	fieldKey: string;
	shouldFireAnalytics: boolean;
	shouldHide?: boolean;
	firstSelectedCardId?: number | string;
};

export const useMenuItemWithStartAnalytics = ({
	fieldKey,
	fieldType,
	shouldFireAnalytics,
	shouldHide = false,
	menuItemProps: { onClick: onClickFn, ...others },
	firstSelectedCardId,
}: Props): MenuNode[] => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onClick = useCallback(
		(...args: Parameters<Exclude<MenuItem['onClick'], undefined>>) => {
			if (shouldFireAnalytics) {
				getUpdateAnalyticsFlowHelper().fireAnalyticsStart(fieldKey, {
					analytics: createAnalyticsEvent({}),
					attributes: {
						fieldType,
						...(fg('one_event_fast_follow_fg') && {
							isContextMenuEditing: true,
							...(firstSelectedCardId &&
								fg('one_event_improvements_1') && {
									issueId: Number(firstSelectedCardId),
								}),
						}),
					},
				});
			}
			onClickFn?.(...args);
		},
		[
			createAnalyticsEvent,
			fieldKey,
			fieldType,
			onClickFn,
			shouldFireAnalytics,
			firstSelectedCardId,
		],
	);

	return shouldHide
		? []
		: [
				{
					...others,
					type: MenuNodeType.Item,
					onClick,
				},
			];
};
