import { useCallback, useRef } from 'react';
import { graphql, useMutation } from 'react-relay';
import type { ProjectType } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import type { cloneIssueMutation } from '@atlassian/jira-relay/src/__generated__/cloneIssueMutation.graphql.ts';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import {
	CloneStatus,
	cloneStatusIsCancelled,
	cloneStatusIsComplete,
	cloneStatusIsFailed,
	cloneStatusIsInProgress,
} from '../constants.tsx';
import {
	cloneIssueExperienceDescription,
	type CloneIssueStage,
} from '../experience-description/index.tsx';
import messages from '../messages.tsx';
import { useCloneStatus } from './context.tsx';
import type {
	CloneApiPayload,
	CloneApiResponse,
	CloneTaskResult,
	GetIssueKeyResult,
	TaskApiResponse,
} from './types.tsx';

const getCloneUrl = (issueKey: IssueKey) => `/rest/internal/2/issue/${issueKey}/clone`;

const getCloneProgressUrl = (taskId: string) => `/rest/api/3/task/${taskId}`;

const getIssueKeyUrl = (issueId: string) => `/rest/api/3/issue/${issueId}?fields=key`;

const getYourWorkUrl = () => '/jira/your-work';

export const useCloneService = (issueKey: IssueKey, projectType: ProjectType | null) => {
	const cloneUrl = getCloneUrl(issueKey);
	const appEditions = useAppEditions();

	const [cloneFetchStatus, { setCloneFetchStatus }] = useCloneStatus();
	const { showFlag, dismissFlag } = useFlagsService();

	const flagId = useRef<string | undefined>();
	const progressTaskId = useRef('0');

	const cloudId = useCloudId();

	// For retries
	const payloadRef = useRef<CloneApiPayload>();
	const onTryAgainClicked = useRef<() => void>();

	const [commit] = useMutation<cloneIssueMutation>(graphql`
		mutation cloneIssueMutation($input: JiraCloneIssueInput!) {
			jira @optIn(to: "JiraIssueCloneMutation") {
				cloneIssue(input: $input) {
					success
					errors {
						message
						extensions {
							statusCode
							errorType
						}
					}
					taskId
					taskStatus
					taskDescription
				}
			}
		}
	`);

	const dismissFlagIfNeeded = useCallback(() => {
		if (flagId.current) {
			dismissFlag(flagId.current);
		}
	}, [dismissFlag]);

	const onFailure = useCallback(
		(
			stage: CloneIssueStage,
			errorMessage: string,
			errorStatusCode?: number,
			failedReason?: string,
			traceId?: string,
		): void => {
			setCloneFetchStatus(CloneStatus.FAILED);
			dismissFlagIfNeeded();

			// For 4xx erros, we will send it as taskFail events but at SLO level we will filter it
			if (errorStatusCode !== null && errorStatusCode !== undefined && errorStatusCode !== 0) {
				const successStatusCodes = fg('bento_send_4xx_to_task_fail_gate_1')
					? [200, 201, 202]
					: [200, 401, 403, 404];
				const wasSuccessful = successStatusCodes.includes(errorStatusCode);

				const errorMessageIfFailed = wasSuccessful
					? undefined
					: `${errorMessage} (Status: ${errorStatusCode})`;

				sendExperienceAnalytics(
					cloneIssueExperienceDescription(
						wasSuccessful,
						stage,
						'clone-issue',
						projectType,
						appEditions,
						errorMessageIfFailed,
						errorStatusCode,
						traceId,
					),
				);
			}

			flagId.current = showFlag({
				messageId: 'clone-issue.services.clone.show-flag.error.clone-failed',
				messageType: 'transactional',
				type: 'error',
				isAutoDismiss: false,
				title: [messages.cloneFailedTitle, { issueKey }],
				description: failedReason
					? [messages.cloneFailedWorkflow, { failedReason }]
					: messages.cloneStartFailed,
				actions: [
					{
						onClick: onTryAgainClicked.current,
						content: messages.tryAgain,
					},
				],
			});
		},
		[setCloneFetchStatus, dismissFlagIfNeeded, projectType, showFlag, issueKey, appEditions],
	);

	const showVagueSuccessFlag = useCallback(() => {
		// Clone was successful but we somehow lost the result or don't have the cloned issue key
		flagId.current = showFlag({
			messageId: 'clone-issue.services.clone.show-flag.success.clone-success',
			messageType: 'transactional',
			type: 'success',
			isAutoDismiss: true,
			title: messages.cloneSuccessTitle,
			description: [
				fg('jira-issue-terminology-refresh-m3')
					? messages.cloneSuccessButLostResultDescriptionIssueTermRefresh
					: messages.cloneSuccessButLostResultDescription,
				{ oldIssueKey: issueKey },
			],
			actions: [
				{
					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					onClick: () => window.open(getYourWorkUrl(), '_blank'),
					content: messages.goToYourWork,
				},
			],
		});
	}, [issueKey, showFlag]);

	const onSuccess = useCallback(
		(result?: CloneTaskResult | string) => {
			dismissFlagIfNeeded();

			sendExperienceAnalytics(
				cloneIssueExperienceDescription(true, 'CLONE', 'clone-issue', projectType, appEditions),
			);

			if (!result || typeof result === 'string') {
				showVagueSuccessFlag();
				return;
			}

			fetch(getIssueKeyUrl(result.issueId), {
				headers: {
					'Content-Type': 'application/json',
				},
			})
				.then((response) => {
					if (!response.ok) {
						const traceId = fg('thor_add_missing_attributes_across_issue_view_1')
							? response.headers.get('Atl-Traceid') || undefined
							: undefined;
						throw new FetchError(response.status, response.statusText, traceId);
					}
					return response.json();
				})
				.then(({ key: clonedKey }: GetIssueKeyResult) => {
					const clonedIssueLink = `/browse/${clonedKey}`;
					flagId.current = showFlag({
						messageId: 'clone-issue.services.clone.show-flag.success.clone-success.1',
						messageType: 'transactional',
						type: 'success',
						isAutoDismiss: false,
						title: messages.cloneSuccessTitle,
						description: [
							fg('jira-issue-terminology-refresh-m3')
								? messages.cloneSuccessDescriptionIssueTermRefresh
								: messages.cloneSuccessDescription,
							{ clonedIssueKey: clonedKey, oldIssueKey: issueKey },
						],
						actions: [
							{
								onClick: () => {
									// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
									window.open(clonedIssueLink, '_blank');
									dismissFlagIfNeeded();
								},
								content: fg('jira-issue-terminology-refresh-m3')
									? messages.cloneSuccessLinkIssueTermRefresh
									: messages.cloneSuccessLink,
							},
						],
					});
				})
				.catch(() => {
					showVagueSuccessFlag();
				});
		},
		[dismissFlagIfNeeded, issueKey, projectType, showFlag, showVagueSuccessFlag, appEditions],
	);

	const pollProgressAfterMs = useCallback(
		(delayMs: number) => {
			const progressUrl = getCloneProgressUrl(progressTaskId.current);

			setTimeout(() => {
				fetch(progressUrl, {
					headers: {
						'Content-Type': 'application/json',
					},
				})
					.then((response) => {
						if (!response.ok) {
							const traceId = fg('thor_add_missing_attributes_across_issue_view_1')
								? response.headers.get('Atl-Traceid') || undefined
								: undefined;
							throw new FetchError(response.status, response.statusText, traceId);
						}
						return response.json();
					})
					.then((json: TaskApiResponse) => {
						const { status, message, result } = json;
						setCloneFetchStatus(status);

						if (cloneStatusIsInProgress(status)) {
							pollProgressAfterMs(Math.min(delayMs + 2000, 10000));
						} else if (cloneStatusIsComplete(status)) {
							onSuccess(result);
						} else if (cloneStatusIsFailed(status) || cloneStatusIsCancelled(status)) {
							onFailure(
								'CLONE',
								`${status || 'NO_STATUS'}: ${message}`,
								200,
								typeof result === 'string' ? result : undefined,
							);
						}
					})
					.catch((error) => {
						onFailure('FETCH', error.message, error.statusCode, undefined, error.traceId);
					});
			}, delayMs);
		},
		[onFailure, onSuccess, setCloneFetchStatus],
	);

	const makeCloneRequest = useCallback(
		async (payload: CloneApiPayload) => {
			setCloneFetchStatus(CloneStatus.ENQUEUED);
			dismissFlagIfNeeded();

			// Saved for retries if needed
			payloadRef.current = payload;

			flagId.current = showFlag({
				messageId: 'clone-issue.services.clone.show-flag.info.clone-start',
				messageType: 'transactional',
				type: 'info',
				isAutoDismiss: true,
				title: [messages.cloneStartTitle, { issueKey }],
				description: [
					fg('jira-issue-terminology-refresh-m3')
						? messages.cloneStartDescriptionIssueTermRefresh
						: messages.cloneStartDescription,
					{ issueKey },
				],
			});

			/**
			 * issueId does not exist in the previous payload. Hence, using this to differentiate between the old and new clone call.
			 */
			if (
				payload?.issueId &&
				expVal(
					'enhance-clone-issue-capability-jracloud-5052-ga',
					'enhancedIssueCloneEnabled',
					false,
				)
			) {
				const optionalFields = Object.keys(payload.optionalFields ?? {})?.map((key) => ({
					fieldId: key,
					shouldClone: payload.optionalFields?.[key] ?? false,
				}));

				commit({
					variables: {
						input: {
							issueId: `ari:cloud:jira:${cloudId}:issue/${payload.issueId}`,
							summary: payload?.summary,
							includeAttachments: payload?.includeAttachments ?? false,
							includeLinks: payload?.includeLinks ?? false,
							includeComments: payload?.includeComments ?? false,
							includeSubtasksOrChildren: payload?.includeSubtasksOrChildren ?? false,
							...(fg('jira-clone-epic-child-issues-and-subtasks')
								? { includeChildrenWithSubtasks: payload?.includeChildrenWithSubtasks ?? false }
								: {}),
							assignee: payload?.assignee,
							reporter: payload?.reporter,
							optionalFields,
						},
					},
					onCompleted: (response) => {
						if (response?.jira?.cloneIssue?.errors && response.jira.cloneIssue.errors.length > 0) {
							const errors = response.jira.cloneIssue.errors;
							const error = errors[0];
							onFailure(
								'CLONE',
								error.message ?? 'No error returned',
								error.extensions?.statusCode ?? 0,
							);
							return;
						}
						progressTaskId.current = response?.jira?.cloneIssue?.taskId ?? '';
						pollProgressAfterMs(1000);
					},
					onError: (error) => {
						let statusCode = 0;
						if (error instanceof FetchError) {
							statusCode = error.statusCode;
						}
						onFailure('CLONE', error.message, statusCode);
					},
				});
			} else {
				fetch(cloneUrl, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(payload),
				})
					.then((response) => {
						if (!response.ok) {
							const traceId = fg('thor_add_missing_attributes_across_issue_view_1')
								? response.headers.get('Atl-Traceid') || undefined
								: undefined;
							throw new FetchError(response.status, response.statusText, traceId);
						}
						return response.json();
					})
					.then((json: CloneApiResponse) => {
						const { status, taskId } = json;

						// Failure on initiation is very unlikely
						if (cloneStatusIsFailed(status) || !taskId) {
							throw new Error(
								`Clone task (ID: ${taskId}) failed on initiation with status: ${status || 'null'}`,
							);
						}

						progressTaskId.current = taskId;

						pollProgressAfterMs(1000);
					})
					.catch((error) => {
						onFailure('CLONE', error.message, error.statusCode, undefined, error.traceId);
					});
			}
		},
		[
			setCloneFetchStatus,
			dismissFlagIfNeeded,
			showFlag,
			issueKey,
			cloneUrl,
			pollProgressAfterMs,
			onFailure,
			commit,
			cloudId,
		],
	);

	onTryAgainClicked.current = useCallback(() => {
		const payload: CloneApiPayload = payloadRef.current || {};
		makeCloneRequest(payload);
	}, [makeCloneRequest]);

	return [cloneFetchStatus, makeCloneRequest] as const;
};
