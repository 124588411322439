import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	label: {
		id: 'software.main.board-container.board.column.header.menu.item-clear-all.label',
		defaultMessage:
			'{numDoneCards, plural, one {Clear 1 Done issue} other {Clear {numDoneCards} Done issues}}',
		description:
			'Shown on a dropdown menu item that is shown on the column header of any column with Resolved issues. Remove all Resolved cards (one that is completed) from the column.',
	},
	tooltip: {
		id: 'software.main.board-container.board.column.header.menu.item-clear-all.tooltip',
		defaultMessage: 'Unavailable when grouping by subtask',
		description: 'When on the group by subtasks show a tooltip on the disabled menu item.',
	},
	labelIssueTermRefresh: {
		id: 'software.main.board-container.board.column.header.menu.item-clear-all.label-issue-term-refresh',
		defaultMessage:
			'{numDoneCards, plural, one {Clear 1 Done work item} other {Clear {numDoneCards} Done work items}}',
		description:
			'Shown on a dropdown menu item that is shown on the column header of any column with Resolved issues. Remove all Resolved cards (one that is completed) from the column.',
	},
	labelRemoveRightNow: {
		id: 'software.main.board-container.board.column.header.menu.item-clear-all.label-remove-right-now',
		defaultMessage: 'Remove right now',
		description:
			'Will be part of sub-menu of remove done items after xxx days. This button will remove all done items immediately.',
	},
});
