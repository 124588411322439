import React from 'react';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { createUseEntryPointContext } from '@atlassian/jira-entry-point/src/controllers/utils/create-use-entry-point-context/index.tsx';
import { programBoardParentSwitcherEntryPoint } from './entrypoint.tsx';

export const ProgramBoardParentSwitcherEntrypointContext =
	createUseEntryPointContext<typeof programBoardParentSwitcherEntryPoint>();

export const ProgramBoardParentSwitcherEntrypointContextProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const entryPointResult = useEntryPoint(programBoardParentSwitcherEntryPoint, {});
	return (
		<ProgramBoardParentSwitcherEntrypointContext.Provider value={entryPointResult}>
			{children}
		</ProgramBoardParentSwitcherEntrypointContext.Provider>
	);
};
