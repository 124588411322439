import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorPrimaryMessage: {
		id: 'filter-refinement.filter-popup.filter-popup-content.error-message.error-primary-message',
		defaultMessage: 'Something went wrong.',
		description: 'Message displayed when fetching data has failed, and an error has occurred',
	},
	errorSecondaryMessage: {
		id: 'filter-refinement.filter-popup.filter-popup-content.error-message.error-secondary-message',
		defaultMessage: 'Refresh and try again.',
		description:
			'Message displayed when fetching data has failed, and an error has occurred. This is a call to action prompting users to try again',
	},
	retryButtonLabel: {
		id: 'filter-refinement.filter-popup.filter-popup-content.error-message.retry-button-label',
		defaultMessage: 'Refresh',
		description:
			'Button label when fetching data has failed, and an error has occurred. Pressing this button refreshes the popup to try fetch data again',
	},
});
