export const LIGHT_MODE = 'light' as const;
export const DARK_MODE = 'dark' as const;

export const FLAGGED_AND_SELECTED_THEME_STATE = 'flaggedAndSelected' as const;
export const FLAGGED_THEME_STATE = 'flagged' as const;
export const SELECTED_THEME_STATE = 'selected' as const;
export const DISABLED_THEME_STATE = 'disabled' as const;
export const DEFAULT_THEME_STATE = 'default' as const;

export const HOVER_ENTER_THEME_STATE_OLD = 'enterOld' as const; // remove on cleanup - jira_theming_milestone_1_fg
export const HOVER_ENTER_THEME_STATE = 'enter' as const;
export const HOVER_LEAVE_THEME_STATE = 'leave' as const;

export const BACKGROUND_COLOR_THEME_TOKEN = 'backgroundColor' as const;
export const TEXT_COLOR_THEME_TOKEN = 'textColor' as const;

export const CUSTOM_FIELD_LABELS = 'labels' as const;
