import React, { useCallback, type RefObject } from 'react';
import { di } from 'react-magnetic-di';
import PageIcon from '@atlaskit/icon/core/page';
import { token } from '@atlaskit/tokens';
import { COMMAND_PALETTE_SECTIONS } from '@atlassian/jira-command-palette-common/src/common/constants.tsx';
import { COMMAND_PALETTE_QUICK_ACTIONS_PRIORITY } from '@atlassian/jira-command-palette-common/src/constants.tsx';
import { useCommandKeywords } from '@atlassian/jira-command-palette-common/src/controllers/use-command-keywords/index.tsx';
import { CommandShortcuts } from '@atlassian/jira-command-palette-common/src/ui/command-shortcuts/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

const KeyboardShortcuts = ({ searchRef }: { searchRef: RefObject<HTMLInputElement> }) => {
	di(requestAnimationFrame);

	const { formatMessage } = useIntl();
	const { getKeywords } = useCommandKeywords();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const focusSearchInputOnShortcut = useCallback(() => {
		if (searchRef?.current) {
			// This is the same way the global search input prevents
			// the shortcut from being fed into the input
			// See: src/packages/navigation-apps/atlassian-navigation/src/ui/integrations/keyboard-shortcuts/index.tsx
			requestAnimationFrame(() => {
				searchRef.current?.focus();
			});

			fireUIAnalytics(
				createAnalyticsEvent({
					action: 'focused',
					actionSubject: 'input',
				}),
				'search',
				{
					keyPressed: 'f',
					keyboardShortcut: true,
				},
			);
		}
	}, [searchRef, createAnalyticsEvent]);

	return (
		<CommandShortcuts
			keyMap={{
				f: {
					callback: focusSearchInputOnShortcut,
					registerInCommandPalette: {
						id: 'filter-refinement-search-field-focus-search-input',
						name: formatMessage(messages.searchCurrentPage),
						keywords: getKeywords('pageSearchSynonyms'),
						components: {
							LeftIcon: () => (
								<PageIcon
									label={formatMessage(messages.searchCurrentPage)}
									color={token('color.icon')}
								/>
							),
						},
						priority: COMMAND_PALETTE_QUICK_ACTIONS_PRIORITY.searchOnCurrentPage,
						section: COMMAND_PALETTE_SECTIONS.quickActions,
						analytics: {
							action: 'searchCurrentPage',
						},
					},
				},
			}}
		/>
	);
};

export default KeyboardShortcuts;
