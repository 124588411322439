import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	moveLeft: {
		id: 'software.main.board-container.board.column.header.menu.item-move-left.label',
		defaultMessage: 'Move column to the left',
		description: 'Move column on the board to the left.',
	},
	moveRight: {
		id: 'software.main.board-container.board.column.header.menu.item-move-right.label',
		defaultMessage: 'Move column to the right',
		description: 'Move column on the board to the right.',
	},
	columnMoved: {
		id: 'software.main.board-container.board.column.header.menu.item-moved.screen-reader',
		defaultMessage: 'Column moved to the {direction}',
		description: 'Screen message for column moved to the left or right.',
	},
});
