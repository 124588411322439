import React, { useMemo } from 'react';
import type { BoardToolSection, BoardToolWebItem } from '../../types.tsx';
import { BoardToolWebItemView } from './board-tool-section-view/board-tool-sub-item/index.tsx';
import { BoardToolSectionView } from './board-tool-section-view/index.tsx';

export function useConnectPluginSections(
	toolSections: (BoardToolSection | BoardToolWebItem)[],
): (BoardToolSection | BoardToolWebItem)[] | undefined {
	return useMemo(
		() => toolSections?.filter((section) => section.id !== 'board-tools-section'),
		[toolSections],
	);
}

export interface BoardToolsViewProps {
	toolSections: (BoardToolSection | BoardToolWebItem)[];
	mode?: 'button' | 'dropdown-item';
	onClickWebItem?: () => void;
	isTabNavigation?: boolean;
}

export function BoardToolsView({
	toolSections,
	mode = 'button',
	onClickWebItem,
	isTabNavigation = false,
}: BoardToolsViewProps) {
	const relevantSections = useConnectPluginSections(toolSections);

	return (
		<>
			{relevantSections?.map((section) =>
				section.type === 'webItem' ? (
					<BoardToolWebItemView
						key={section.id}
						subItem={section}
						mode={mode}
						onClickWebItem={mode === 'dropdown-item' ? onClickWebItem : undefined}
						isTabNavigation={isTabNavigation}
					/>
				) : (
					<BoardToolSectionView
						key={section.id}
						section={section}
						isMenuItem={mode === 'dropdown-item' && isTabNavigation}
						isTabNavigation={isTabNavigation}
					/>
				),
			)}
		</>
	);
}
