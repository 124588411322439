import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createHook, createStore, type Action } from '@atlassian/react-sweet-state';
import type { IssueKey } from '../../model/issue/issue-types.tsx';

type SelectedIssuesSweetState = {
	selectedIssueKeys: IssueKey[];
	selectedIssueIds: IssueId[];
};

const initialState: SelectedIssuesSweetState = {
	selectedIssueKeys: [],
	selectedIssueIds: [],
};
const actions = {
	setSelectedIssueKeys:
		(issueKeys: IssueKey[]): Action<SelectedIssuesSweetState> =>
		({ setState }) => {
			setState({ selectedIssueKeys: issueKeys });
		},
	setSelectedIssueIds:
		(issueIds: IssueId[]): Action<SelectedIssuesSweetState> =>
		({ setState }) => {
			setState({ selectedIssueIds: issueIds });
		},
};

const Store = createStore({
	initialState,
	actions,
	name: 'selected-issues-sync',
});

const useSelectedIssuesSync = createHook(Store);

export default useSelectedIssuesSync;
