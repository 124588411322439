import { memo, useCallback, type RefObject } from 'react';
import { getLongTasksMetrics } from '@atlassian/jira-common-long-task-metrics/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { softwareGenericLongTasksReporter } from '@atlassian/jira-software-browser-metrics/src/services/reporters/software-generic-long-tasks/index.tsx';
import { useScrollStateObserver } from './scroll-state-observer/index.tsx';

export type Attributes = {
	[key: string]: number | boolean | string | Attributes;
};

type BaseProps = {
	/**
	 * App name for the events.
	 * Example:
	 *
	 * appName="backlog"
	 *
	 * Events should be: 'software.backlog.long-tasks-scroll'
	 */
	appName: string;
	/**
	 * Use if there may be multiple concurrent scrolling elements being observed
	 */
	observationType: string;
	/**
	 * Extra attributes for the event
	 */
	attributes: Attributes;
	/**
	 * Additional attributes to log in the ui longTask event
	 */
	logAttributes?: Attributes;
};

export type Props =
	| (BaseProps & {
			/**
			 * Scroll element to listen to events on
			 */
			scrollEl: HTMLElement | null;
	  })
	| (BaseProps & {
			/**
			 * Scroll element to listen to events on.
			 *
			 * Either scrollElRef or scrollEl can be provided, depending on the ref API being used.
			 * If both are provided the scrollElRef is preferred.
			 */
			scrollElRef?: RefObject<HTMLElement>;
	  });

/**
 * Tracks long-tasks that happen while scrolling on a certain 'scrollEl'.
 */
const LongTasksScrollObserverBase = ({
	observationType,
	attributes,
	appName,
	logAttributes = {},
	...rest
}: Props) => {
	const onScrollStart = useCallback(() => {
		getLongTasksMetrics(observationType).start(appName, undefined, attributes);

		if (fg('jsw_refactor_long-tasks_monitoring')) {
			performance.mark(`${appName}:scrolling-task:start`);
		}
	}, [attributes, appName, observationType]);

	const onScrollEnd = useCallback(
		(lastScrollTime: number) => {
			getLongTasksMetrics(observationType).stop(
				softwareGenericLongTasksReporter('scroll', lastScrollTime, logAttributes),
			);

			if (fg('jsw_refactor_long-tasks_monitoring')) {
				performance.mark(`${appName}:scrolling-task:end`);
				performance.measure(
					`${appName}:scrolling-task`,
					`${appName}:scrolling-task:start`,
					`${appName}:scrolling-task:end`,
				);
			}
		},
		[observationType, appName, logAttributes],
	);

	useScrollStateObserver({
		...rest,
		onScrollStart,
		onScrollEnd,
	});

	return null;
};

LongTasksScrollObserverBase.defaultProps = {
	observationType: 'scroll',
	attributes: {},
};

export const LongTasksScrollObserver = memo(LongTasksScrollObserverBase);
