/** @jsx jsx */
import React, { Component, createRef, type ReactElement } from 'react';
import { styled, css, jsx } from '@compiled/react';
import isNil from 'lodash/isNil';
import { useIsActiveRoute } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { softwareBoardEmbedRoute } from '@atlassian/jira-router-routes-software-board-routes/src/softwareBoardEmbedRoute.tsx';
import { classicSoftwareBoardEmbedRoute } from '@atlassian/jira-router-routes-software-classic-board-routes/src/classicSoftwareBoardEmbedRoute.tsx';
import { UPDATE_BOARD_SCROLL_POSITION } from '../../../common/constants/index.tsx';
import { minCardHeight } from '../../../common/constants/styles/index.tsx';
import { useBoardScrollApi } from '../../../controllers/board-scroll/index.tsx';
import type {
	BoardScrollPosition,
	OnHorizontalScroll,
} from '../../../controllers/board-scroll/types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { BoardScrollPosition };

export type BoardScrollInfo = BoardScrollPosition & {
	topEdge: boolean;
	leftEdge: boolean;
	rightEdge: boolean;
};

export type BoardScrollInitData = {
	hasHorizontalScrollbar: boolean;
};

type Props = {
	children?: ReactElement | null;
	innerRef?:
		| {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				current: null | any;
		  } // eslint-disable-next-line @typescript-eslint/no-explicit-any
		| ((arg: null | any) => unknown);
	flexibleColumns?: boolean;
	isEmbedView?: boolean;
	onBoardScrollInit: (
		boardScrollPosition: BoardScrollPosition,
		onHorizontalScroll: OnHorizontalScroll,
	) => void;
	onBoardScroll: (boardScrollPosition: BoardScrollPosition) => void;
	onInit: (initDat: BoardScrollInitData) => void;
	onScroll: (scrollInfo: BoardScrollInfo) => void;
	showTransitionZones?: boolean;
};

type State = {
	scrollElementHeight: number;
	scrollElementWidth: number;
};

// eslint-disable-next-line jira/react/no-class-components
export class BoardScroll extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.scrollElRef = createRef();
	}

	// scrollElementHeight is used to calculate the height of transition zone
	// set to minCardHeight by default
	// Remove when cleaning up avoid_board_scroll_container_style_changes
	state = { scrollElementHeight: minCardHeight, scrollElementWidth: 0 };

	componentDidMount() {
		if (!isNil(this.getScrollElement())) {
			this.getScrollElement().addEventListener('scroll', this.handleScrollEvent);
		}

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.addEventListener(UPDATE_BOARD_SCROLL_POSITION, this.handleScrollEvent);
		if (this.scrollElRef.current && typeof ResizeObserver !== 'undefined') {
			this.resizeObserver = new ResizeObserver(this.scrollElementResizeCallback);
			this.resizeObserver.observe(this.scrollElRef.current);
		}

		this.props.onBoardScrollInit(this.getBoardScrollPosition(), this.onHorizontalScroll);
		this.props.onInit({
			hasHorizontalScrollbar:
				!isNil(this.getScrollElement()) &&
				this.getScrollElement().scrollWidth > this.getScrollElement().clientWidth,
		});
	}

	componentWillUnmount() {
		if (this.scrollMeasureFrameId != null) {
			cancelAnimationFrame(this.scrollMeasureFrameId);
		}

		if (!isNil(this.getScrollElement())) {
			this.getScrollElement().removeEventListener('scroll', this.handleScrollEvent);
		}

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.removeEventListener(UPDATE_BOARD_SCROLL_POSITION, this.handleScrollEvent);

		this.resizeObserver?.disconnect();
	}

	scrollElementDimensionFrameId: number | null = null;

	scrollElementResizeCallback = () => {
		this.handleScrollEvent();
		if (!fg('avoid_board_scroll_container_style_changes')) {
			this.setScrollElementDimension();
		}
	};

	// Remove when cleaning up avoid_board_scroll_container_style_changes
	setScrollElementDimension = () => {
		if (!this.props.flexibleColumns) return;
		if (this.scrollElementDimensionFrameId != null) {
			cancelAnimationFrame(this.scrollElementDimensionFrameId);
		}

		this.scrollElementDimensionFrameId = requestAnimationFrame(() => {
			const scrollElement = this.getScrollElement();
			if (
				!isNil(scrollElement) &&
				(scrollElement.clientHeight !== this.state.scrollElementHeight ||
					scrollElement.clientWidth !== this.state.scrollElementWidth)
			) {
				if (getWillShowNav4()) {
					// When using Nav4 we need to subtract 32 from the height to prevent the scroll element height from
					// infinitely growing, see https://jplat.atlassian.net/browse/BLU-3447
					this.setState({
						scrollElementHeight: scrollElement.clientHeight - 32,
						scrollElementWidth: scrollElement.clientWidth,
					});
				} else {
					this.setState({
						scrollElementHeight: scrollElement.clientHeight,
						scrollElementWidth: scrollElement.clientWidth,
					});
				}
			}
		});
	};

	resizeObserver: ResizeObserver | null = null;

	getScrollElement = (): HTMLElement =>
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage, @typescript-eslint/no-non-null-assertion
		(!__SERVER__ ? this.scrollElRef.current! : this.scrollEl) ?? window.document.body;

	onHorizontalScroll = (scrollLeft: number) => {
		requestAnimationFrame(() => {
			if (isNil(this.getScrollElement())) {
				return;
			}

			this.getScrollElement().scrollLeft = scrollLeft;
		});
	};

	setScrollEl = (scrollEl: HTMLElement | null) => {
		if (isNil(scrollEl)) {
			return;
		}

		if (!__SERVER__) {
			this.scrollElRef.current = scrollEl;
		} else {
			this.scrollEl = scrollEl;
		}

		// Only notify any other listeners if we have any
		if (isNil(this.props.innerRef)) {
			return;
		}

		const { innerRef } = this.props;

		if (typeof innerRef === 'function') {
			innerRef(this.getScrollElement()); // ref={ref => someRef = ref}
		} else {
			// WindowRef passed from BoardContainer -> Board -> This
			innerRef.current = this.getScrollElement();
		}
	};

	getBoardScrollPosition = (): BoardScrollPosition => {
		if (isNil(this.getScrollElement())) {
			return {
				scrollTop: 0,
				scrollLeft: 0,
				scrollWidth: 0,
				clientWidth: 0,
			};
		}

		const { scrollLeft, scrollTop, scrollWidth, clientWidth } = this.getScrollElement();
		return {
			scrollTop,
			scrollLeft,
			scrollWidth,
			clientWidth,
		};
	};

	getLegacyBoardScrollInfo = (): BoardScrollInfo => {
		if (isNil(this.getScrollElement())) {
			return {
				scrollTop: 0,
				scrollLeft: 0,
				scrollWidth: 0,
				clientWidth: 0,
				leftEdge: true,
				topEdge: true,
				rightEdge: false,
			};
		}

		const { scrollLeft, scrollTop, scrollWidth, clientWidth } = this.getScrollElement();
		return {
			scrollTop,
			scrollLeft,
			scrollWidth,
			clientWidth,
			leftEdge: scrollLeft === 0,
			topEdge: scrollTop === 0,
			rightEdge: scrollLeft === scrollWidth - clientWidth,
		};
	};

	handleScrollEvent = () => {
		if (this.scrollMeasureFrameId != null) {
			cancelAnimationFrame(this.scrollMeasureFrameId);
		}
		this.scrollMeasureFrameId = requestAnimationFrame(() => {
			this.props.onBoardScroll(this.getBoardScrollPosition());
			this.props.onScroll(this.getLegacyBoardScrollInfo());
		});
	};

	scrollMeasureFrameId: number | null = null;

	scrollEl: HTMLElement | null = null;

	scrollElRef: {
		current: null | HTMLElement;
	};

	hideOverflow = fg('jira_board_embed_scroll_bar_bugfix') && !!this.props.isEmbedView;

	render() {
		if (!fg('avoid_board_scroll_container_style_changes')) {
			return (
				<StyledBoard
					ref={this.setScrollEl}
					data-testid="platform-board-kit.ui.board.scroll.board-scroll"
					// eslint-disable-next-line jira/react/no-style-attribute -- Ignored via go/DSP-18766
					style={
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						{
							'--board-scroll-element-height': this.state.scrollElementHeight,
							'--board-scroll-element-width': this.state.scrollElementWidth,
							overflow: this.hideOverflow ? 'hidden' : 'auto',
						} as React.CSSProperties
					}
				>
					{this.props.children}
				</StyledBoard>
			);
		}
		if (this.props.showTransitionZones) {
			const scrollElement = this.getScrollElement();
			const adjustedScrollElementHeight = Math.max(
				getWillShowNav4() ? scrollElement.clientHeight - 32 : scrollElement.clientHeight,
				minCardHeight,
			);
			return (
				<div
					ref={this.setScrollEl}
					data-testid="platform-board-kit.ui.board.scroll.board-scroll"
					css={[scrollContainerStyles, this.hideOverflow ? hiddenScrollStyles : autoScrollStyles]}
					// eslint-disable-next-line jira/react/no-style-attribute
					style={
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						{
							'--board-scroll-element-height': adjustedScrollElementHeight,
							'--board-scroll-element-width': scrollElement.clientWidth,
						} as React.CSSProperties
					}
				>
					{this.props.children}
				</div>
			);
		}

		return (
			<div
				ref={this.setScrollEl}
				data-testid="platform-board-kit.ui.board.scroll.board-scroll"
				css={[scrollContainerStyles, this.hideOverflow ? hiddenScrollStyles : autoScrollStyles]}
			>
				{this.props.children}
			</div>
		);
	}
}

type OwnProps = {
	innerRef?:
		| {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				current: null | any;
		  } // eslint-disable-next-line @typescript-eslint/no-explicit-any
		| ((arg: null | any) => unknown);
	children?: ReactElement | null;
	flexibleColumns?: boolean;
	onInit: (initData: BoardScrollInitData) => void;
	onScroll: (scrollInfo: BoardScrollInfo) => void;
	showTransitionZones?: boolean;
};

const BoardScrollMain = (props: OwnProps) => {
	const [, { onBoardScroll, onBoardScrollInit }] = useBoardScrollApi();
	const isEmbedRoute = useIsActiveRoute(
		new Set([softwareBoardEmbedRoute.name, classicSoftwareBoardEmbedRoute.name]),
	);

	return (
		<BoardScroll
			{...props}
			isEmbedView={isEmbedRoute}
			onBoardScrollInit={onBoardScrollInit}
			onBoardScroll={onBoardScroll}
		/>
	);
};

export default BoardScrollMain;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledBoard = styled.div({
	width: '100%',
	maxHeight: '100%',
	height: '100%',
	flex: 1,
	position: 'relative',
	willChange: 'opacity',
});

const scrollContainerStyles = css({
	width: '100%',
	maxHeight: '100%',
	height: '100%',
	flex: 1,
	position: 'relative',
	willChange: 'opacity',
});

const hiddenScrollStyles = css({ overflow: 'hidden' });

const autoScrollStyles = css({ overflow: 'auto' });
