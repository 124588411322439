import { useCallback } from 'react';
import { useHasSeenIt } from '@atlassian/jira-onboarding-core/src/services/has-seen-it/index.tsx';
import type { UseDismissalState } from './types.tsx';
import {
	getDismissalLocalStorageState,
	setDismissalLocalStorageState,
	clearDismissalLocalStorageState,
} from './utils.tsx';
import { UNASSIGNED_FILTER_NUDGE_SEEN_IT_KEY } from './constants.tsx';

export const useDismissal = (): UseDismissalState => {
	const [hasSeenItKey, { setHasSeen }] = useHasSeenIt({
		hasSeenItKey: UNASSIGNED_FILTER_NUDGE_SEEN_IT_KEY,
		skipFetch: false,
	});

	const dismissalState = getDismissalLocalStorageState();

	const hide = useCallback(() => {
		const currentCount = (dismissalState.count ?? 0) + 1;
		// if the user dismisses the nudge 3 times, don't show it again
		if (currentCount > 2) {
			setHasSeen(UNASSIGNED_FILTER_NUDGE_SEEN_IT_KEY);
			clearDismissalLocalStorageState();
			return;
		}

		setDismissalLocalStorageState({
			count: currentCount,
			dismissUntil: new Date().setHours(23, 59, 59, 999),
		});
	}, [dismissalState?.count, setHasSeen]);

	const dismiss = useCallback(() => {
		setHasSeen(UNASSIGNED_FILTER_NUDGE_SEEN_IT_KEY);
	}, [setHasSeen]);

	return {
		isDismissed:
			!hasSeenItKey || hasSeenItKey.fetchStatus !== 'fetched'
				? true
				: hasSeenItKey.value === true ||
					dismissalState.count > 2 ||
					dismissalState.dismissUntil >= Date.now(),
		hide,
		dismiss,
		hideCount: dismissalState?.count ?? 0,
	};
};
