import React, { useCallback } from 'react';
import { ThemedButtonNew } from '@atlassian/jira-project-theme-components/src/ui/themed-button-new/ThemedButtonNew.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIsFullscreen } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/fullscreen/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import {
	fireUIAnalytics,
	useAnalyticsEvents,
	type AnalyticsEvent,
} from '@atlassian/jira-product-analytics-bridge';
import { View } from '@atlassian/jira-software-view-settings/src/common/types/constant.tsx';
import { useViewSettingsActions } from '@atlassian/jira-software-view-settings/src/controllers/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { StandupActions, StandupButtonRenderProps } from '../../../common/types.tsx';
import messages from './messages.tsx';

type EndStandupButtonProps = StandupActions & {
	boardId: number;
	render?: (props: StandupButtonRenderProps) => JSX.Element;
};

export const EndStandupButton = ({
	setIsActiveStandup,
	boardId,
	render,
}: EndStandupButtonProps) => {
	const { formatMessage } = useIntl();
	const { setIsFullscreen: setIsFullscreenNav3 } = useViewSettingsActions();
	const [, { setIsFullscreen }] = useIsFullscreen();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const exitFullscreen = useCallback(() => {
		getWillShowNav4()
			? setIsFullscreen(false)
			: setIsFullscreenNav3({ boardId, isFullscreen: false, view: View.BOARD });
	}, [boardId, setIsFullscreen, setIsFullscreenNav3]);

	const handleExitStandupOld = (analyticsEvent: AnalyticsEvent) => {
		fireUIAnalytics(analyticsEvent, 'exitStandup');
		exitFullscreen();
		setIsActiveStandup(false);
	};

	const handleExitStandup = () => {
		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: 'button',
				action: 'clicked',
			}),
			'exitStandup',
		);
		exitFullscreen();
		setIsActiveStandup(false);
	};

	if (render && isVisualRefreshEnabled() && fg('visual-refresh_drop_6')) {
		return render({
			isDisabled: false,
			onClick: handleExitStandup,
			label: formatMessage(messages.endStandupButton),
		});
	}

	return (
		<ThemedButtonNew onClick={(__, analyticsEvent) => handleExitStandupOld(analyticsEvent)}>
			{formatMessage(messages.endStandupButton)}
		</ThemedButtonNew>
	);
};
