import { expVal } from '@atlassian/jira-feature-experiments';
import type { FlagConfiguration } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import messages from './messages.tsx';

const UndoSuccessFlag: FlagConfiguration = {
	messageId:
		'software-board-clearing.common.ui.undo-success-flag.undo-success-flag.success.flag.success',
	messageType: 'transactional',
	title: messages.title,
	type: 'success',
	isAutoDismiss: true,
};

const UndoSuccessFlagIssueTermRefresh: FlagConfiguration = {
	messageId:
		'software-board-clearing.common.ui.undo-success-flag.undo-success-flag-issue-term-refresh.success.flag.issue-term-refresh-success',
	messageType: 'transactional',
	title: messages.titleIssueTermRefresh,
	type: 'success',
	isAutoDismiss: true,
};

const getUndoSuccessFlagConfigurations = () => {
	return expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
		? UndoSuccessFlagIssueTermRefresh
		: UndoSuccessFlag;
};

export default getUndoSuccessFlagConfigurations;
