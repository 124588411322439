import React, { useRef } from 'react';
import { uifBoardResource } from '@atlassian/jira-router-resources-uif-board/src/index.tsx';
import responseToSla from '@atlassian/jira-software-sla-tracker/src/services/response-to-sla/index.tsx';
import { useResource, type RouteResourceResponse } from '@atlassian/react-resource-router';
import {
	EXPERIENCE_TRACKER_LOCATION_BOARD,
	EXPERIENCE_TRACKER_LOCATION_CMP_BOARD,
} from '../../model/constants.tsx';

export type BoardSlaTrackerProps = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	boardData: RouteResourceResponse<any> | null;
	location?: string | null;
};

export const BoardSlaTracker = (props: BoardSlaTrackerProps) => {
	if (!props.boardData) {
		return null;
	}

	const {
		boardData: { error: boardDataError, loading: boardDataLoading },
		location,
	} = props;

	// do nothing until all endpoints are loaded
	if (boardDataLoading === true) {
		return null;
	}

	if (boardDataError) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
		return responseToSla(boardDataError as any, location ?? EXPERIENCE_TRACKER_LOCATION_BOARD);
	}

	// return null when no errors
	return null;
};

export const CmpBoardSlaTracker = () => {
	const boardData = useResource(uifBoardResource);

	const hasFired = useRef(false);

	if (hasFired.current || !boardData || !boardData.error || boardData.data || boardData.loading) {
		return null;
	}

	hasFired.current = true;
	return <BoardSlaTracker boardData={boardData} location={EXPERIENCE_TRACKER_LOCATION_CMP_BOARD} />;
};
