import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { userPreferenceAPI } from '@atlassian/jira-user-preferences-services/src/utils/index.tsx';
import type { HasSeenItKey } from '../../types.tsx';
import { createHasSeenPrefixedKey } from '../../utils.tsx';

export const performSetHasSeenRequest = async (hasSeenKey: HasSeenItKey) => {
	try {
		await userPreferenceAPI.set<boolean>(createHasSeenPrefixedKey(hasSeenKey), true);
	} catch (error: unknown) {
		if (error instanceof Error) {
			fireErrorAnalytics({
				error,
				meta: {
					id: 'performSetHasSeenRequest',
					packageName: 'jiraOnboardingCore',
					teamName: 'jlove-makkuro',
				},
				sendToPrivacyUnsafeSplunk: true,
				skipSentry: true,
				attributes: {
					hasSeenKey,
				},
			});
		}
	}
};
