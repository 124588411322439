import React, {
	type PropsWithChildren,
	type ComponentType,
	useState,
	useCallback,
	memo,
	// eslint-disable-next-line jira/restricted/react-component-props
	type ComponentProps,
	useRef,
} from 'react';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import {
	fireUIAnalytics,
	AnalyticsEventToProps,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import InvitePeopleModalAsync from '@atlassian/jira-project-invite-people-modal/async.tsx';
import { SOFTWARE } from '@atlassian/jira-shared-types/src/application-key.tsx';
import { JIRA_SOFTWARE } from '@atlassian/jira-shared-types/src/application.tsx';
import {
	type ApplicationEdition,
	FREE_EDITION,
	toEdition,
	getEdition,
} from '@atlassian/jira-shared-types/src/edition.tsx';
import type { BaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import UpgradeToAddUsersDialog from '@atlassian/jira-up-flow-upgrade-to-add-users-dialog/src/index.tsx';
import { getRemainingSeats } from '@atlassian/jira-up-flow-upgrade-to-add-users-dialog/src/services/license/index.tsx';
import { Analytics } from './analytics/index.tsx';
import StatelessAssigneeFilter, { type CommonAssigneeFilterProps } from './stateless/index.tsx';

export type Props = {
	isAddPeopleButtonEnabled: boolean;
	readonly projectId: number;
} & Omit<CommonAssigneeFilterProps, 'maxUsers'> & {
		AddPeopleModal?: ComponentType<{
			onClose: () => void;
			isOpen: boolean;
		}>;
	} & { maxUsers?: number; projectName?: string; projectKey?: string };

const StatelessAssigneeFilterWithAnalytics = AnalyticsEventToProps(
	'StatelessAssigneeFilterWithAnalytics',
	{
		onAddPeopleClick: 'clicked',
	},
)(StatelessAssigneeFilter);
StatelessAssigneeFilterWithAnalytics.displayName = 'StatelessAssigneeFilterWithAnalytics';

export const showAddPeopleOrUpgradeDialog = (
	baseUrl: BaseUrl,
	setShowUpSell: (value: boolean) => void,
	showUpSell: boolean,
	setShowAddPeopleModal: (value: boolean) => void,
	showAddPeopleModal: boolean,
	jswEdition: ApplicationEdition,
	analyticsEvent: UIAnalyticsEvent,
	screenName: string,
	fireTriggerClickEvent: () => void,
) => {
	const openAddPeopleModal = () => {
		setShowAddPeopleModal(!showAddPeopleModal);
	};
	const [expConfig] = UNSAFE_noExposureExp('jsw_allow_project_invite_past_10th_user_on_free');

	if (jswEdition === toEdition(FREE_EDITION)) {
		getRemainingSeats(baseUrl, SOFTWARE)
			.then((remainingSeats) => {
				if (remainingSeats === 0) {
					// When the JSW Free instance is at the user limit and
					// the user is enrolled in the auto-upgrade experiment, fire an exposure event
					if (expConfig.get('cohort', 'not-enrolled') !== 'not-enrolled') {
						const [, fireExposureEvent] = UNSAFE_noExposureExp(
							'jsw_allow_project_invite_past_10th_user_on_free',
						);
						fireExposureEvent();
					}
					fireTriggerClickEvent();
					// If the user is in the auto-upgrade experiment variation cohort, show the invite modal.
					// Otherwise, show the upsell dialog.
					if (expConfig.get('cohort', 'not-enrolled') === 'variation') {
						openAddPeopleModal();
					} else {
						setShowUpSell(!showUpSell);
						if (!showUpSell) {
							fireUIAnalytics(analyticsEvent, `${screenName}AssigneeFilterAddPeople`);
						}
					}
				} else {
					fireTriggerClickEvent();
					openAddPeopleModal();
				}
			})
			.catch((e) => {
				fireTriggerClickEvent();
				openAddPeopleModal();
				throw e;
			});
	} else {
		fireTriggerClickEvent();
		// If not on JSW Free, always show the invite modal
		openAddPeopleModal();
	}
};

type ConditionalUpsellDialogWrapperProps = {
	showUpsellDialog: boolean;
	setShowUpsellDialog: React.Dispatch<React.SetStateAction<boolean>>;
	screenName?: string | undefined;
};

const ConditionalUpsellDialogWrapper = (
	props: PropsWithChildren<ConditionalUpsellDialogWrapperProps>,
) => {
	const { children, showUpsellDialog, setShowUpsellDialog, screenName } = props;
	const [expConfig] = UNSAFE_noExposureExp('jsw_allow_project_invite_past_10th_user_on_free');

	if (expConfig.get('cohort', 'not-enrolled') === 'variation') {
		// When the user is in the experiment variation cohort, we won't be showing the upsell dialog.
		return <>{children}</>;
	}

	const onUpgradeDialogClose = () => {
		setShowUpsellDialog(false);
	};

	return (
		<UpgradeToAddUsersDialog
			isOpen={showUpsellDialog}
			onClose={onUpgradeDialogClose}
			touchpointId={`${screenName || ''}${screenName ? 'A' : 'a'}ssigneeFilterAddPeople`}
			onUpFlowDismiss={onUpgradeDialogClose}
		>
			{children}
		</UpgradeToAddUsersDialog>
	);
};

export const AssigneeFilter = (props: Props) => {
	const {
		users,
		maxUsers,
		AddPeopleModal,
		screenName,
		selectedUsers,
		onToggle,
		projectId,
		isAddPeopleButtonEnabled,
		projectName,
		projectKey,
		onAssigneeMenuOpen,
		shouldShowUnassignedFilterNudge,
		onClickUnassignedFilterNudge,
	} = props;
	const tenantContext = useTenantContext();
	const [showAddPeopleModal, setShowAddPeopleModal] = useState<boolean>(false);
	const [showUpSell, setShowUpSell] = useState(false);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const appEdition = getEdition(JIRA_SOFTWARE, tenantContext.appEditions);

	const onAddPeopleClick = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			const fireAddPeopleClickEvent = () => {
				// If neither the upsell dialog nor the invite modal is currently open,
				// then the user is trying to open one of them. When this happens,
				// we fire an analytics event.
				if (!showAddPeopleModal && !showUpSell) {
					const event = createAnalyticsEvent({
						action: 'clicked',
						actionSubject: 'button',
						source: screenName,
					});
					fireUIAnalytics(event, 'assigneeFilterAddPeople');
				}
			};

			showAddPeopleOrUpgradeDialog(
				tenantContext.baseUrl,
				setShowUpSell,
				showUpSell,
				setShowAddPeopleModal,
				showAddPeopleModal,
				appEdition,
				analyticsEvent,
				screenName || '',
				fireAddPeopleClickEvent,
			);
		},
		[
			createAnalyticsEvent,
			tenantContext.baseUrl,
			showUpSell,
			showAddPeopleModal,
			appEdition,
			screenName,
		],
	);

	const onAddPeopleModalClose = useCallback(() => {
		setShowAddPeopleModal(false);
	}, []);

	const showAddPeopleButton: boolean = AddPeopleModal !== undefined && isAddPeopleButtonEnabled;
	const addButtonRef: React.RefObject<HTMLButtonElement | null> = useRef(null);
	return (
		<ConditionalUpsellDialogWrapper
			showUpsellDialog={showUpSell}
			setShowUpsellDialog={setShowUpSell}
			screenName={screenName}
		>
			<StatelessAssigneeFilterWithAnalytics
				peopleRef={addButtonRef}
				users={users}
				maxUsers={maxUsers}
				selectedUsers={selectedUsers}
				onToggle={onToggle}
				onAddPeopleClick={onAddPeopleClick}
				showAddPeopleButton={showAddPeopleButton}
				screenName={screenName || ''}
				projectId={projectId}
				onAssigneeMenuOpen={onAssigneeMenuOpen}
				shouldShowUnassignedFilterNudge={shouldShowUnassignedFilterNudge}
				onClickUnassignedFilterNudge={onClickUnassignedFilterNudge}
			/>
			<Analytics
				baseUrl={tenantContext.baseUrl}
				projectId={projectId}
				screenName={screenName || ''}
				showAddPeopleButton={showAddPeopleButton}
			/>
			{AddPeopleModal && showAddPeopleModal && (
				<InvitePeopleModalAsync
					inviteRef={addButtonRef}
					appEdition={appEdition}
					cloudId={tenantContext.cloudId}
					isOpen
					onClose={onAddPeopleModalClose}
					jiraProjectName={projectName}
					projectId={projectId}
					jiraProjectKey={projectKey}
					isSiteAdmin={tenantContext.isSiteAdmin}
				/>
			)}
		</ConditionalUpsellDialogWrapper>
	);
};

type DefaultProps = Props;
AssigneeFilter.defaultProps = {
	users: [],
	selectedUsers: [],
	maxUsers: 5,
	onToggle: noop,
	onAssigneeMenuOpen: noop,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	AddPeopleModal: undefined as
		| ComponentType<{
				onClose: () => void;
				isOpen: boolean;
		  }>
		| undefined,
	isAddPeopleButtonEnabled: false,
	projectId: -1,
};

const AssigneeFilterComponent = memo<Props & DefaultProps>(AssigneeFilter);

AssigneeFilterComponent.displayName = 'AssigneeFilter';
// @ts-expect-error - TS2339 - Property 'whyDidYouRender' does not exist on type 'NamedExoticComponent<{ isAddPeopleButtonEnabled: boolean; readonly projectId: number; } & CommonAssigneeFilterProps & { AddPeopleModal: ComponentType<{ onClose: () => void; isOpen: boolean; }> | undefined; }>'.
AssigneeFilterComponent.whyDidYouRender = true;

export type AssigneeFilterProps = JSX.LibraryManagedAttributes<
	typeof AssigneeFilter,
	ComponentProps<typeof AssigneeFilter>
>;
export default AssigneeFilterComponent;
