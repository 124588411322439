import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import messages from '@atlassian/jira-issue-transition-use-show-flag/src/ui/use-show-flag/messages.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import type { FireAnalyticsFn as FireShortcutAnalyticsFn } from '@atlassian/jira-software-keyboard-shortcuts/src/utils/analytics/index.tsx';
import type { ColumnId } from '../../../../model/column/column-types.tsx';
import type { TOP_OF_CONTAINER, BOTTOM_OF_CONTAINER } from '../../../../model/constants.tsx';
import type { FlagData } from '../../../../model/flags/flag-types.tsx';
import type { IssueKey } from '../../../../model/issue/issue-types.tsx';
import type {
	OptimisticId,
	Status,
	TransitionId,
} from '../../../../model/software/software-types.tsx';
import type { SwimlaneId } from '../../../../model/swimlane/swimlane-types.tsx';
import flagMessages from '../../../../view/flags/messages.tsx';
import {
	errorFlagMeta,
	warningFlagMeta,
	successFlagMeta,
	type FlagMeta,
} from '../../meta/flag-meta.tsx';
import {
	beginOptimisticUiMeta,
	commitOptimisticUiMeta,
	revertOptimisticUiMeta,
	type OptimisticUiMeta,
} from '../../meta/optimistic-ui-meta.tsx';

export const ISSUE_MOVE_TOP_OR_BOTTOM_OF_CONTAINER =
	'state.actions.issue.rank-transition.ISSUE_MOVE_TOP_OR_BOTTOM_OF_CONTAINER';

export type IssueMoveTopOrBottomOfContainerAction = {
	type: typeof ISSUE_MOVE_TOP_OR_BOTTOM_OF_CONTAINER;
	payload: {
		placement: typeof TOP_OF_CONTAINER | typeof BOTTOM_OF_CONTAINER;
		fireShortcutAnalyticsFn: FireShortcutAnalyticsFn;
	};
	meta: {
		analyticsEvent: AnalyticsEvent;
	};
};

export const issueMoveToTopOrBottomOfContainer = (
	placement: typeof TOP_OF_CONTAINER | typeof BOTTOM_OF_CONTAINER,
	fireShortcutAnalyticsFn: FireShortcutAnalyticsFn,
	analyticsEvent: AnalyticsEvent,
): IssueMoveTopOrBottomOfContainerAction => ({
	type: ISSUE_MOVE_TOP_OR_BOTTOM_OF_CONTAINER,
	payload: {
		placement,
		fireShortcutAnalyticsFn,
	},
	meta: {
		analyticsEvent,
	},
});

export type IssueRankTransitionRequestMode = 'TRANSITION_AND_RANK' | 'TRANSITION_ONLY';

export const ISSUE_RANK_TRANSITION_REQUEST =
	'state.actions.issue.rank-transition.ISSUE_RANK_TRANSITION_REQUEST' as const;

type IssueRankTransitionRequestPayload = {
	issueIds: IssueId[];
	sourceColumnId: ColumnId;
	destinationColumnId: ColumnId;
	rankBeforeIssueId: IssueId | null | undefined;
	rankAfterIssueId: IssueId | null | undefined;
	sourceSwimlaneId: SwimlaneId | null | undefined;
	destinationSwimlaneId: SwimlaneId | null | undefined;
	isSourceDone: boolean;
	isDestinationDone: boolean;
	transitionId: TransitionId | null | undefined;
	sourceStatus: Status | null | undefined;
	destinationStatus: Status | null | undefined;
	isRankable: boolean | undefined;
	batchId?: string;
	mode: IssueRankTransitionRequestMode;
};

export type IssueRankTransitionRequestAction = {
	type: typeof ISSUE_RANK_TRANSITION_REQUEST;
	payload: IssueRankTransitionRequestPayload;
	meta: {
		analyticsEvent: AnalyticsEvent;
	} & Partial<OptimisticUiMeta>;
};

type IssueRankTransitionRequestOptions = {
	issueIds: IssueId[];
	sourceColumnId: ColumnId;
	destinationColumnId: ColumnId;
	rankBeforeIssueId: IssueId | null | undefined;
	rankAfterIssueId: IssueId | null | undefined;
	sourceSwimlaneId: SwimlaneId | null | undefined;
	destinationSwimlaneId: SwimlaneId | null | undefined;
	isSourceDone: boolean;
	isDestinationDone: boolean;
	transitionId?: TransitionId | null | undefined;
	sourceStatus?: Status | null | undefined;
	destinationStatus?: Status | null | undefined;
	isRankable?: boolean;
	analyticsEvent: AnalyticsEvent;
	batchId?: string;
	mode?: IssueRankTransitionRequestMode;
};

export const issueRankTransitionRequest = ({
	issueIds,
	sourceColumnId,
	destinationColumnId,
	rankBeforeIssueId,
	rankAfterIssueId,
	sourceSwimlaneId,
	destinationSwimlaneId,
	isSourceDone,
	isDestinationDone,
	transitionId,
	sourceStatus,
	destinationStatus,
	isRankable,
	batchId,
	mode = 'TRANSITION_AND_RANK',
	analyticsEvent,
}: IssueRankTransitionRequestOptions): IssueRankTransitionRequestAction => ({
	type: ISSUE_RANK_TRANSITION_REQUEST,
	payload: {
		issueIds,
		sourceColumnId,
		destinationColumnId,
		rankBeforeIssueId,
		rankAfterIssueId,
		sourceSwimlaneId,
		destinationSwimlaneId,
		isSourceDone,
		isDestinationDone,
		transitionId,
		sourceStatus,
		destinationStatus,
		isRankable,
		batchId,
		mode,
	},
	meta: {
		analyticsEvent,
	},
});

export const ISSUE_RANK_TRANSITION_SUCCESS =
	'state.actions.issue.rank-transition.ISSUE_RANK_TRANSITION_SUCCESS' as const;

export type IssueRankTransitionSuccessAction = {
	type: typeof ISSUE_RANK_TRANSITION_SUCCESS;
	payload: {
		issueIds: IssueId[];
		batchId?: string;
	};
	meta: {
		analyticsEvent: AnalyticsEvent;
	} & OptimisticUiMeta & {
			flag?: FlagData;
		};
};

export const issueRankTransitionSuccess = ({
	optimisticId,
	issueIds,
	batchId,
	analyticsEvent,
	issueKeys,
	destinationStatus,
}: {
	optimisticId: OptimisticId;
	issueIds: IssueId[];
	batchId?: string;
	analyticsEvent: AnalyticsEvent;
	issueKeys?: IssueKey[];
	destinationStatus?: string;
}): IssueRankTransitionSuccessAction => ({
	type: ISSUE_RANK_TRANSITION_SUCCESS,
	payload: {
		issueIds,
		batchId,
	},
	meta: {
		...commitOptimisticUiMeta(optimisticId),
		...(issueKeys &&
			issueKeys.length === 1 &&
			fg('show-success-flag-for-jsw-board-card-transition') &&
			successFlagMeta({
				id: 'issueTransitionSuccessful',
				titleMessage: messages.issueTransitionSuccessfulWithIssueKeyAndStatus,
				descriptionMessage: messages.issueTransitionSuccessfulFlagDescription,
				context: { issueKey: issueKeys[0], status: destinationStatus },
			})),
		analyticsEvent,
	},
});

export const ISSUE_RANK_TRANSITION_ABORT =
	'state.actions.issue.rank-transition.ISSUE_RANK_TRANSITION_ABORT' as const;

export type IssueRankTransitionAbortAction = {
	type: typeof ISSUE_RANK_TRANSITION_ABORT;
	meta: OptimisticUiMeta;
};

export const issueRankTransitionAbort = (
	optimisticId: OptimisticId,
): IssueRankTransitionAbortAction => ({
	type: ISSUE_RANK_TRANSITION_ABORT,
	meta: {
		...revertOptimisticUiMeta(optimisticId),
	},
});

export const ISSUE_RANK_TRANSITION_FAILURE =
	'state.actions.issue.rank-transition.ISSUE_RANK_TRANSITION_FAILURE' as const;

export type IssueRankTransitionFailureAction = {
	type: typeof ISSUE_RANK_TRANSITION_FAILURE;
	payload: { batchId?: string };
	meta: OptimisticUiMeta & FlagMeta;
};

export const issueRankTransitionFailure = (
	optimisticId: OptimisticId,
	batchId?: string,
	error?: Error,
	traceId?: string,
): IssueRankTransitionFailureAction => ({
	type: ISSUE_RANK_TRANSITION_FAILURE,
	payload: { batchId },
	meta: {
		...revertOptimisticUiMeta(optimisticId),
		...errorFlagMeta({
			titleMessage: expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? flagMessages.issueRankFailTitleIssueTermRefresh
				: flagMessages.issueRankFailTitle,
			descriptionMessage: expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? flagMessages.issueRankFailDescriptionIssueTermRefresh
				: flagMessages.issueRankFailDescription,
			...(error && traceId && fg('obsrve-2239-traceid-errorflag-issue-transition')
				? {
						context: {
							error,
							traceId,
						},
					}
				: {}),
		}),
	},
});

export const ISSUE_RANK_TRANSITION_FAILURE_FIELD_REQUIRED =
	'state.actions.issue.rank-transition.ISSUE_RANK_TRANSITION_FAILURE_FIELD_REQUIRED' as const;

export type IssueRankTransitionFailureFieldRequiredAction = {
	type: typeof ISSUE_RANK_TRANSITION_FAILURE_FIELD_REQUIRED;
	payload: { issueKey: string; errorMessages: string[] };
	meta: OptimisticUiMeta & FlagMeta;
};

export const issueRankTransitionFailureFieldRequired = (
	optimisticId: OptimisticId,
	issueKey: string,
	errorMessages: string[],
	error?: Error,
	traceId?: string,
): IssueRankTransitionFailureFieldRequiredAction => ({
	type: ISSUE_RANK_TRANSITION_FAILURE_FIELD_REQUIRED,
	payload: { issueKey, errorMessages },
	meta: {
		...revertOptimisticUiMeta(optimisticId),
		...warningFlagMeta({
			id: ISSUE_RANK_TRANSITION_FAILURE_FIELD_REQUIRED,
			titleMessage: expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? flagMessages.issueRankFailFieldRequiredTitleIssueTermRefresh
				: flagMessages.issueRankFailFieldRequiredTitle,
			descriptionMessage: flagMessages.issueRankFailFieldRequiredDescription,
			context: {
				issueKey,
				errorMessage: errorMessages.join('\n\n'),
				...(error && traceId && fg('obsrve-2239-traceid-errorflag-issue-transition')
					? {
							error,
							traceId,
						}
					: {}),
			},
		}),
	},
});

export const SET_ISSUES_RANK = 'state.actions.issue.rank-transition.SET_ISSUES_RANK' as const;

type SetIssuesRankPayload = {
	issueIds: IssueId[];
	rankId: number;
	isRankAfter: boolean;
};

export type SetIssuesRankAction = {
	type: typeof SET_ISSUES_RANK;
	payload: SetIssuesRankPayload;
};

export const setIssuesRank = ({
	issueIds,
	rankId,
	isRankAfter,
}: SetIssuesRankPayload): SetIssuesRankAction => ({
	type: SET_ISSUES_RANK,
	payload: {
		issueIds,
		rankId,
		isRankAfter,
	},
});

export const BULK_ISSUE_RANK_TRANSITION_REQUEST =
	'state.actions.issue.rank-transition.BULK_ISSUE_RANK_TRANSITION_REQUEST' as const;

export type BulkIssueRankTransitionRequestAction = {
	type: typeof BULK_ISSUE_RANK_TRANSITION_REQUEST;
	payload: Omit<IssueRankTransitionRequestPayload, 'batchId' | 'mode'>;
	meta: {
		analyticsEvent: AnalyticsEvent;
	};
};

export const bulkIssueRankTransitionRequest = ({
	issueIds,
	sourceColumnId,
	destinationColumnId,
	rankBeforeIssueId,
	rankAfterIssueId,
	sourceSwimlaneId,
	destinationSwimlaneId,
	isSourceDone,
	isDestinationDone,
	transitionId,
	sourceStatus,
	destinationStatus,
	isRankable,
	analyticsEvent,
}: Omit<
	IssueRankTransitionRequestOptions,
	'batchId' | 'isCMPBoard'
>): BulkIssueRankTransitionRequestAction => ({
	type: BULK_ISSUE_RANK_TRANSITION_REQUEST,
	payload: {
		issueIds,
		sourceColumnId,
		destinationColumnId,
		rankBeforeIssueId,
		rankAfterIssueId,
		sourceSwimlaneId,
		destinationSwimlaneId,
		isSourceDone,
		isDestinationDone,
		transitionId,
		sourceStatus,
		destinationStatus,
		isRankable,
	},
	meta: {
		analyticsEvent,
	},
});

// Bulk issue rank section - start
export const BULK_ISSUE_RANK_OPTIMISTIC =
	'state.actions.issue.rank-transition.BULK_ISSUE_RANK_OPTIMISTIC' as const;

type BulkIssueRankOptimisticPayload = {
	issueIds: IssueId[];
	rankAfterId?: IssueId | null;
	rankBeforeId?: IssueId | null;
};

export type BulkIssueRankOptimisticAction = {
	type: typeof BULK_ISSUE_RANK_OPTIMISTIC;
	payload: BulkIssueRankOptimisticPayload;
	meta: OptimisticUiMeta;
};

export const bulkIssueRankOptimistic = (
	payload: BulkIssueRankOptimisticPayload,
): BulkIssueRankOptimisticAction => ({
	type: BULK_ISSUE_RANK_OPTIMISTIC,
	payload,
	meta: {
		...beginOptimisticUiMeta(),
	},
});

export const BULK_ISSUE_RANK_SUCCESS =
	'state.actions.issue.rank-transition.BULK_ISSUE_RANK_SUCCESS' as const;

export type BulkIssueRankSuccessPayload = {
	issueIds: IssueId[];
	rankAfterId?: IssueId | null;
	rankBeforeId?: IssueId | null;
	columnId: ColumnId;
};

export type BulkIssueRankSuccessAction = {
	type: typeof BULK_ISSUE_RANK_SUCCESS;
	payload: BulkIssueRankSuccessPayload;
	meta: {
		analyticsEvent: AnalyticsEvent;
	} & OptimisticUiMeta;
};

export const bulkIssueRankSuccess = ({
	payload,
	optimisticId,
	analyticsEvent,
}: {
	payload: BulkIssueRankSuccessPayload;
	optimisticId: OptimisticId;
	analyticsEvent: AnalyticsEvent;
}): BulkIssueRankSuccessAction => ({
	type: BULK_ISSUE_RANK_SUCCESS,
	payload,
	meta: {
		...commitOptimisticUiMeta(optimisticId),
		analyticsEvent,
	},
});

export const BULK_ISSUE_RANK_FAILURE =
	'state.actions.issue.rank-transition.BULK_ISSUE_RANK_FAILURE' as const;

export type BulkIssueRankFailurePayload = {
	issueIds: IssueId[];
	issueKeys: IssueKey[];
	rankAfterId?: IssueId | null;
	rankBeforeId?: IssueId | null;
	columnId: ColumnId;
};

export type BulkIssueRankFailureAction = {
	type: typeof BULK_ISSUE_RANK_FAILURE;
	payload: BulkIssueRankFailurePayload;
	meta: {
		analyticsEvent: AnalyticsEvent;
	} & FlagMeta;
};

export const bulkIssueRankFailure = ({
	payload,
	optimisticId,
	errorMessages,
	analyticsEvent,
	error,
	traceId,
}: {
	payload: BulkIssueRankFailurePayload;
	optimisticId: OptimisticId;
	errorMessages: string[];
	analyticsEvent: AnalyticsEvent;
	error?: Error;
	traceId?: string;
}): BulkIssueRankFailureAction => ({
	type: BULK_ISSUE_RANK_FAILURE,
	payload,
	meta: {
		...revertOptimisticUiMeta(optimisticId),
		...errorFlagMeta({
			id: BULK_ISSUE_RANK_FAILURE,
			titleMessage: expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? flagMessages.bulkIssueRankFailTitleIssueTermRefresh
				: flagMessages.bulkIssueRankFailTitle,
			descriptionMessage: expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? flagMessages.issueRankFailDescriptionIssueTermRefresh
				: flagMessages.issueRankFailDescription,
			errorMessages,
			context: {
				issueKey: payload.issueKeys.join(', '),
				errorMessage: errorMessages.join('\n\n'),
				...(error && traceId && fg('obsrve-2239-traceid-errorflag-issue-transition')
					? {
							error,
							traceId,
						}
					: {}),
			},
		}),
		analyticsEvent,
	},
});

// Bulk issue rank section - end

export const ISSUE_RANK_TRANSITION_UPDATE_OPTIMISTIC =
	'state.actions.issue.rank-transition.ISSUE_RANK_TRANSITION_UPDATE_OPTIMISTIC' as const;

type IssueRankTransitionUpdateOptimisticPayload = {
	sourceColumnId: ColumnId;
	issueIds: IssueId[];
	destinationColumnId: ColumnId;
	isDestinationDone: boolean;
	destinationStatus: Status | null | undefined;
	rankBeforeIssueId: IssueId | null | undefined;
	rankAfterIssueId: IssueId | null | undefined;
	isRankable: boolean | undefined;
	mode: IssueRankTransitionRequestMode;
	isCMPBoard: boolean;
};

export type IssueRankTransitionUpdateOptimisticAction = {
	type: typeof ISSUE_RANK_TRANSITION_UPDATE_OPTIMISTIC;
	payload: IssueRankTransitionUpdateOptimisticPayload;
	meta: {
		analyticsEvent: AnalyticsEvent;
	} & OptimisticUiMeta;
};

export const issueRankTransitionUpdateOptimistic = ({
	analyticsEvent,
	...payload
}: IssueRankTransitionUpdateOptimisticPayload & {
	analyticsEvent: AnalyticsEvent;
}): IssueRankTransitionUpdateOptimisticAction => ({
	type: ISSUE_RANK_TRANSITION_UPDATE_OPTIMISTIC,
	payload,
	meta: {
		...beginOptimisticUiMeta(),
		analyticsEvent,
	},
});

export type Action =
	| IssueMoveTopOrBottomOfContainerAction
	| IssueRankTransitionRequestAction
	| IssueRankTransitionSuccessAction
	| IssueRankTransitionAbortAction
	| IssueRankTransitionFailureAction
	| SetIssuesRankAction
	| BulkIssueRankTransitionRequestAction
	| IssueRankTransitionFailureFieldRequiredAction
	| IssueRankTransitionUpdateOptimisticAction
	| BulkIssueRankOptimisticAction
	| BulkIssueRankSuccessAction
	| BulkIssueRankFailureAction;
