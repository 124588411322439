import React, {
	useMemo,
	useCallback,
	forwardRef,
	useEffect,
	useState,
	type RefObject,
} from 'react';
import { getIn } from 'icepick';
import isNil from 'lodash/isNil';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import {
	swimlaneHeaderHeight,
	columnHeaderHeight,
	unscheduledColumnHeaderHeight,
} from '@atlassian/jira-platform-board-kit/src/common/constants/styles/index.tsx';
import {
	IP_BOARD_STORY_POINTS_PLANNING_UNIT,
	SWIMLANE_TEAMLESS,
} from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/constants.tsx';
import IterationColumnHeaderInner from '@atlassian/jira-portfolio-3-plan-increment-common/src/ui/iteration-column-header/index.tsx';
import SprintColumnHeaderInner from '@atlassian/jira-portfolio-3-plan-increment-common/src/ui/sprint-column-header/index.tsx';
import { getDateRangeColumn } from '../../../../../common/utils/column/index.tsx';
import { updatePlannedCapacityRequest } from '../../../../../state/actions/sprints/update-planned-capacity/index.tsx';
import { updateIterationCapacityRequest } from '../../../../../state/actions/team/index.tsx';
import { useBoardSelector, useBoardDispatch } from '../../../../../state/index.tsx';
import { isIncrementPlanningReadOnly } from '../../../../../state/selectors/board/board-permissions-selectors.tsx';
import { getColumnById } from '../../../../../state/selectors/column/column-selectors.tsx';
import {
	getIncrementConfig,
	getBoardConfig,
} from '../../../../../state/selectors/software/software-selectors.tsx';
import {
	getIterationCapacityInfo,
	getOverlappedSprintPerTeam,
	getSprintCapacityInfo,
	getTeamById,
} from '../../../../../state/selectors/team/team-selectors.tsx';
import {
	getTeamSwimlaneColumnTotalIssuesCount,
	getTeamSwimlaneColumnSprint,
} from '../../../../../state/selectors/work/work-selectors.tsx';
import { HeaderMeasurement } from './header-measurement/index.tsx';
import messages from './messages.tsx';
import type { IterationColumnHeaderProps, SprintColumnHeaderProps, Props } from './types.tsx';

const IterationColumnHeader = ({
	swimlaneId,
	columnId,
	planningUnit,
	workingHoursPerDay,
	capacityInfo,
	hideTotalCapacity,
	isReadOnly,
	totalIssues,
	visibleIssues,
}: IterationColumnHeaderProps) => {
	const dispatch = useBoardDispatch();

	const { totalCapacity, usedCapacity, defaultCapacity } = capacityInfo;

	const onChangeCapacity = useCallback(
		(capacity: string) => {
			dispatch(
				updateIterationCapacityRequest({
					capacity,
					planningUnit,
					workingHoursPerDay,
					iterationId: columnId,
					teamId: swimlaneId,
					totalCapacity,
					defaultCapacity,
				}),
			);
		},
		[
			dispatch,
			planningUnit,
			workingHoursPerDay,
			columnId,
			swimlaneId,
			totalCapacity,
			defaultCapacity,
		],
	);

	return (
		<IterationColumnHeaderInner
			columnId={columnId}
			totalCapacity={totalCapacity}
			usedCapacity={usedCapacity}
			defaultCapacity={defaultCapacity}
			planningConfig={{
				planningUnit,
				workingHoursPerDay,
			}}
			onChangeCapacity={onChangeCapacity}
			hideTotalCapacity={hideTotalCapacity}
			isReadOnly={isReadOnly}
			visibleIssues={visibleIssues}
			totalIssues={totalIssues}
		/>
	);
};

const SprintColumnHeader = ({
	sprint,
	swimlaneId,
	columnId,
	planningUnit,
	workingHoursPerDay,
	sprintCapacity,
	isReadOnly,
}: SprintColumnHeaderProps) => {
	const dispatch = useBoardDispatch();
	const overlappedSprintPerTeam = useBoardSelector(getOverlappedSprintPerTeam);
	const overlappedSprints = getIn(overlappedSprintPerTeam, [swimlaneId, sprint.id]);
	const column = useBoardSelector((state) => getDateRangeColumn(getColumnById(state, columnId)));

	const onChangeCapacity = useCallback(
		(capacity: string) => {
			dispatch(
				updatePlannedCapacityRequest({
					capacity,
					planningUnit,
					workingHoursPerDay,
					iterationId: sprint.id,
					teamId: swimlaneId,
					totalCapacity: sprintCapacity.totalCapacity,
					defaultCapacity: sprintCapacity.defaultCapacity,
				}),
			);
		},
		[
			dispatch,
			planningUnit,
			workingHoursPerDay,
			sprint,
			swimlaneId,
			sprintCapacity.totalCapacity,
			sprintCapacity.defaultCapacity,
		],
	);
	return (
		<SprintColumnHeaderInner
			sprint={sprint}
			totalCapacity={sprintCapacity.totalCapacity}
			usedCapacity={sprintCapacity.usedCapacity}
			doneCapacity={sprintCapacity.doneCapacity}
			defaultCapacity={sprintCapacity.defaultCapacity}
			planningConfig={{
				planningUnit,
				workingHoursPerDay,
			}}
			overlappedSprints={overlappedSprints}
			columnDateRange={column?.dateRange}
			onChangeCapacity={onChangeCapacity}
			isReadOnly={isReadOnly}
		/>
	);
};

const ProgramSwimlaneColumnHeader = forwardRef<HTMLDivElement, Props>(
	({ count, columnId, swimlaneId, isUnscheduledWorkColumnPanel }, headerRef) => {
		const { formatMessage } = useIntl();
		const isReadOnly = useBoardSelector(isIncrementPlanningReadOnly);
		const isExternalTeam =
			useBoardSelector((state) => getTeamById(state)(swimlaneId)?.isPlanTeam) === false;
		const isScenarioTeam =
			useBoardSelector((state) => getTeamById(state)(swimlaneId)?.isOnlyInScenario) === true;
		const sprint = useBoardSelector((state) =>
			getTeamSwimlaneColumnSprint(state, columnId, swimlaneId),
		);
		const sprintCapacityPerTeam = useBoardSelector(getSprintCapacityInfo);
		const sprintCapacity = sprint && getIn(sprintCapacityPerTeam, [swimlaneId, sprint.id]);

		const iterationCapacityPerTeam = useBoardSelector(getIterationCapacityInfo);
		const capacity = getIn(iterationCapacityPerTeam, [swimlaneId, columnId]);

		const {
			timeTrackingOptions: { workingHoursPerDay },
		} = useBoardSelector(getBoardConfig);
		const incrementConfig = useBoardSelector(getIncrementConfig);
		const total: number = useBoardSelector((state) =>
			getTeamSwimlaneColumnTotalIssuesCount(state, columnId, swimlaneId),
		);
		const visibleIssues = useMemo(
			() =>
				count !== total
					? formatMessage(
							expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
								? messages.visibleIssueCountIssueTermRefresh
								: messages.visibleIssueCount,
							{ count, total },
						)
					: formatMessage(
							expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
								? messages.totalIssueCountIssueTermRefresh
								: messages.totalIssueCount,
							{ total },
						),
			[count, total, formatMessage],
		);
		const planningUnit = isNil(incrementConfig?.planningUnit)
			? IP_BOARD_STORY_POINTS_PLANNING_UNIT
			: incrementConfig.planningUnit;

		const [headerToMeasure, setHeaderToMeasure] = useState<RefObject<HTMLDivElement> | null>(null);

		useEffect(() => {
			if (headerRef && typeof headerRef !== 'function' && headerRef.current) {
				setHeaderToMeasure(headerRef);
			}
		}, [headerRef]);

		const renderHeaderMeasurement = () =>
			headerToMeasure !== null && (
				<HeaderMeasurement
					columnId={columnId}
					isUnscheduledWorkColumnPanel={isUnscheduledWorkColumnPanel}
					headerRef={headerToMeasure}
				/>
			);

		if (swimlaneId === SWIMLANE_TEAMLESS || (isNil(sprint) && capacity)) {
			return (
				<Box
					ref={headerRef}
					xcss={
						isUnscheduledWorkColumnPanel
							? [programSwimlaneColumnHeaderOuterStyles, unscheduledColumnHeaderOuterStyles]
							: programSwimlaneColumnHeaderOuterStyles
					}
				>
					{renderHeaderMeasurement()}
					<IterationColumnHeader
						visibleIssues={count}
						totalIssues={total}
						swimlaneId={swimlaneId}
						columnId={columnId}
						planningUnit={planningUnit}
						workingHoursPerDay={workingHoursPerDay}
						capacityInfo={capacity}
						hideTotalCapacity={isExternalTeam || swimlaneId === SWIMLANE_TEAMLESS || isScenarioTeam}
						isReadOnly={isReadOnly}
					/>
				</Box>
			);
		}

		if (sprint && sprintCapacity) {
			return (
				<Box
					xcss={
						isUnscheduledWorkColumnPanel
							? [programSwimlaneColumnHeaderOuterStyles, unscheduledColumnHeaderOuterStyles]
							: programSwimlaneColumnHeaderOuterStyles
					}
					ref={headerRef}
				>
					{renderHeaderMeasurement()}
					<SprintColumnHeader
						sprint={sprint}
						swimlaneId={swimlaneId}
						columnId={columnId}
						planningUnit={planningUnit}
						workingHoursPerDay={workingHoursPerDay}
						sprintCapacity={sprintCapacity}
						isReadOnly={isReadOnly}
					/>
				</Box>
			);
		}

		return (
			<Box
				xcss={
					isUnscheduledWorkColumnPanel
						? [programSwimlaneColumnHeaderOuterStyles, unscheduledColumnHeaderOuterStyles]
						: programSwimlaneColumnHeaderOuterStyles
				}
				ref={headerRef}
			>
				{renderHeaderMeasurement()}
				<Box xcss={[columnHeaderWrapper, storyPointCapacityAndIssueCountStyles]}>
					<Box xcss={issueCountStyles}>{visibleIssues}</Box>
				</Box>
			</Box>
		);
	},
);

// main board columns header height, plus swimlane header height,
// minus the 8px gutter which gets removed from the top of the swimlane header on scroll
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const stickyOffset = `calc(${swimlaneHeaderHeight + columnHeaderHeight}px - ${token('space.100')})`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const unscheduledColumnStickyOffset = `calc(${unscheduledColumnHeaderHeight + columnHeaderHeight}px - ${token('space.100')})`;

const programSwimlaneColumnHeaderOuterStyles = xcss({
	position: 'sticky',
	backgroundColor: 'elevation.surface',
	paddingTop: 'space.075',
	// zIndex of 1 is required to ensure the column header is above the column and below the swimlane header
	zIndex: '1',
	top: `${stickyOffset}`,
});

const unscheduledColumnHeaderOuterStyles = xcss({
	top: `${unscheduledColumnStickyOffset}`,
});

const columnHeaderWrapper = xcss({
	backgroundColor: 'elevation.surface.sunken',
	borderTopLeftRadius: 'border.radius.100',
	borderTopRightRadius: 'border.radius.100',
	paddingBlock: 'space.050',
});

const storyPointCapacityAndIssueCountStyles = xcss({
	paddingInline: 'space.200',
});

const issueCountStyles = xcss({
	color: 'color.text.subtlest',
	paddingTop: 'space.075',
	paddingBottom: 'space.075',
	height: `${token('space.400')}`,
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

export default ProgramSwimlaneColumnHeader;
