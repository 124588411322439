import { token } from '@atlaskit/tokens';

const defaultBackgroundColor = token('color.background.accent.gray.subtlest');
const successFailureBackgroundColor = token('color.background.accent.gray.subtlest');
const defaultTextColor = token('color.text.subtle');

export const SUCCESS_STYLE = {
	backgroundColor: successFailureBackgroundColor,
	textColor: defaultTextColor,
	iconColor: token('color.icon.success'),
	fontWeight: token('font.weight.bold'),
	backgroundIconColor: 'transparent',
};

export const FAILURE_STYLE = {
	backgroundColor: successFailureBackgroundColor,
	textColor: defaultTextColor,
	iconColor: token('color.icon.danger'),
	fontWeight: token('font.weight.bold'),
	backgroundIconColor: 'transparent',
};

export const PAUSED_STYLE = {
	backgroundColor: defaultBackgroundColor,
	textColor: defaultTextColor,
	iconColor: token('color.icon.subtle'),
	fontWeight: token('font.weight.semibold'),
	backgroundIconColor: 'transparent',
};

export const PAUSED_BREACHED_STYLE = {
	backgroundColor: defaultBackgroundColor,
	textColor: defaultTextColor,
	fontWeight: token('font.weight.semibold'),
	iconColor: token('color.icon.inverse'),
	backgroundIconColor: token('color.icon.danger'),
};

export const PAUSED_UNDER_THIRTY_REMAINING_STYLE = {
	backgroundColor: defaultBackgroundColor,
	textColor: defaultTextColor,
	fontWeight: token('font.weight.semibold'),
	iconColor: token('color.icon.inverse'),
	backgroundIconColor: token('color.icon.warning'),
};

export const ONGOING_STYLE = {
	backgroundColor: defaultBackgroundColor,
	textColor: defaultTextColor,
	iconColor: token('color.icon.subtle'),
	fontWeight: token('font.weight.semibold'),
	backgroundIconColor: 'transparent',
};

export const ONGOING_BREACHED_STYLE = {
	backgroundColor: token('color.background.danger.bold'),
	textColor: token('color.text.inverse'),
	iconColor: token('color.icon.inverse'),
	fontWeight: token('font.weight.bold'),
	backgroundIconColor: 'transparent',
};

export const ONGOING_UNDER_THIRTY_REMAINING_STYLE = {
	backgroundColor: token('color.background.warning.bold'),
	textColor: token('color.text.warning.inverse'),
	iconColor: token('color.text.warning.inverse'),
	fontWeight: token('font.weight.bold'),
	backgroundIconColor: 'transparent',
};
