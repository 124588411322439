import React, { useMemo } from 'react';
import { IconButton } from '@atlaskit/button/new';
import Heading from '@atlaskit/heading';
import CrossIconNew from '@atlaskit/icon/core/close';
import CrossIconOld from '@atlaskit/icon/glyph/cross';
import Link from '@atlaskit/link';
import { Text, Box, Flex, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import HelpPanelLink from '@atlassian/jira-help-panel-button/src/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useIntl } from '@atlassian/jira-intl';
import { SwimlaneColumnHeaderContainer } from '@atlassian/jira-platform-board-kit/src/ui/swimlane-column-header/index.tsx';
import SwimlaneWrapper from '@atlassian/jira-platform-board-kit/src/ui/swimlane/wrapper/index.tsx';
import {
	DOC_LINKS,
	IPH_IDS,
} from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/constants.tsx';
import {
	type MeasurementCacheEntry,
	useVirtual,
} from '@atlassian/jira-software-fast-virtual/src/services/use-virtual/index.tsx';
import { useViewSettingsActions } from '@atlassian/jira-software-view-settings/src/controllers/index.tsx';
import { useBoardSelector } from '../../../../state/index.tsx';
import {
	rapidViewIdSelector,
	getBoardScopeFilter,
} from '../../../../state/selectors/software/software-selectors.tsx';
import {
	platformSwimlaneIdsSelector,
	swimlaneHeightsSelector,
	workIssuesCountSelector,
} from '../../../../state/selectors/work/work-selectors.tsx';
import { useAutoScrollSwimlane } from '../swimlanes-container/use-auto-scroll-swimlane/index.tsx';
import VirtualizedSwimlane from '../virtualized-swimlane/index.tsx';
import messages from './messages.tsx';

const SHOW_IPH_ISSUE_LIMIT = 500;

const UnscheduledWorkSwimlanesContainer = () => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const swimlaneIds = useBoardSelector(platformSwimlaneIdsSelector);
	const swimlaneHeights = useBoardSelector(swimlaneHeightsSelector);
	const boardId = useBoardSelector(rapidViewIdSelector);
	const { setShowUnscheduledColumn } = useViewSettingsActions();
	const cacheOptions = useMemo(
		() => ({
			cache: new Map<string, MeasurementCacheEntry>(),
			getCacheKey: (index: number) => swimlaneIds[index],
		}),
		[swimlaneIds],
	);

	const { rows, totalSize, scrollTo } = useVirtual({
		rowCount: swimlaneIds.length,
		getDefaultRowSize: (index) => swimlaneHeights[swimlaneIds[index]],
		offsetTop: 0,
		cacheOptions,
	});

	useAutoScrollSwimlane(swimlaneIds, scrollTo);

	const renderSwimlanes = () => (
		<>
			{rows.map(({ index, top, forceRemeasure, measure }) => (
				<VirtualizedSwimlane
					key={swimlaneIds[index]}
					id={swimlaneIds[index]}
					index={index}
					top={top}
					measure={measure}
					forceRemeasure={forceRemeasure}
					isLastSwimlane={index === swimlaneIds.length - 1}
					isUnscheduledWorkColumnPanel
				/>
			))}
		</>
	);

	const renderButtonIcon = () => (
		<CrossIconNew
			LEGACY_fallbackIcon={CrossIconOld}
			LEGACY_size="small"
			label={fg('jpo_a11y_pb_fixes') ? '' : formatMessage(messages.closeColumnLabel)}
			color={token('color.icon')}
		/>
	);

	const closeUnscheduledWork = () => setShowUnscheduledColumn(Number(boardId), false);

	const boardScopeFilter = useBoardSelector(getBoardScopeFilter);
	const workIssuesCount = useBoardSelector(workIssuesCountSelector);
	const showHelpLink =
		!boardScopeFilter &&
		workIssuesCount > SHOW_IPH_ISSUE_LIMIT &&
		fg('program_board_misc_improvements');

	const onClickHelpLink = () => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'link',
			}),
			'programBoardScopeFilterIph',
		);
	};
	return (
		<Box xcss={UnscheduledWorkSwimlanesContainerWrapperStyles}>
			<SwimlaneColumnHeaderContainer>
				<Flex
					alignItems="center"
					justifyContent="space-between"
					xcss={UnscheduledColumnHeaderContentStyles}
				>
					{fg('increment_board_a11y_fix') ? (
						<Heading size="small" as="h2">
							{formatMessage(
								expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
									? messages.columnHeaderIssueTermRefresh
									: messages.columnHeader,
							)}
						</Heading>
					) : (
						<Text size="large" weight="semibold">
							{formatMessage(
								expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
									? messages.columnHeaderIssueTermRefresh
									: messages.columnHeader,
							)}
						</Text>
					)}
					<Box testId="software-board.board-container.board.unscheduled-work-swimlanes-container.close-button">
						<IconButton
							icon={renderButtonIcon}
							label={formatMessage(messages.closeColumnLabel)}
							appearance="subtle"
							onClick={closeUnscheduledWork}
						/>
					</Box>
				</Flex>
			</SwimlaneColumnHeaderContainer>
			{showHelpLink && (
				<Box xcss={UnscheduledColumnIPHStyles}>
					<HelpPanelLink
						articleId={IPH_IDS.PROGRAM_BOARD_RECOMMENDATIONS}
						fallbackComponent={
							<Link href={DOC_LINKS.PROGRAM_BOARD_RECOMMENDATIONS}>
								{formatMessage(
									expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
										? messages.filterIssuesHelpLinkIssueTermRefresh
										: messages.filterIssuesHelpLink,
								)}
							</Link>
						}
						appearance="link"
						spacing="none"
						onClick={onClickHelpLink}
					>
						{formatMessage(
							expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
								? messages.filterIssuesHelpLinkIssueTermRefresh
								: messages.filterIssuesHelpLink,
						)}
					</HelpPanelLink>
				</Box>
			)}
			<SwimlaneWrapper minHeight={totalSize} isVirtualised hideTickMask>
				{renderSwimlanes()}
			</SwimlaneWrapper>
		</Box>
	);
};

export default UnscheduledWorkSwimlanesContainer;

const UnscheduledWorkSwimlanesContainerWrapperStyles = xcss({
	height: '100%',
	width: '100%',
	paddingInline: 'space.050',
});

const UnscheduledColumnHeaderContentStyles = xcss({
	width: '100%',
	paddingBlock: 'space.200',
	paddingLeft: 'space.150',
	paddingRight: 'space.100',
	backgroundColor: 'elevation.surface',
});

const UnscheduledColumnIPHStyles = xcss({
	paddingBottom: 'space.150',
	paddingLeft: 'space.150',
});
