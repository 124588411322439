import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { styled, css } from '@compiled/react';
import { fg } from '@atlassian/jira-feature-gating';
import { useShouldApplyInviteAndAssign } from '@atlassian/jira-invite-and-assign/src/controllers/use-should-apply-invite-and-assign/index.tsx';
import type { UserValue } from '@atlassian/jira-issue-field-assignee/src/common/types.tsx';
import {
	IssueAssigneeFieldBoundary,
	AsyncLazyIssueAssigneeField,
} from '@atlassian/jira-issue-field-assignee/src/ui/popover/async.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { fetchAssigneeSuggestions as fetchPlanAssigneeSuggestions } from '@atlassian/jira-portfolio-3-plan-increment-common/src/services/fetch-assignee-suggestions/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { ASSIGNEE_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { AssigneeFieldStatic } from '../../../../../../common/fields/assignee-field-static/index.tsx';
import { useEditableField } from '../../../../../../common/fields/use-editable-field/index.tsx';
import type { EditableFieldProps } from '../../../../../../common/fields/use-editable-field/types.tsx';
import { PACKAGE_NAME } from '../../../../../../model/constants.tsx';
import { setInlineEditing } from '../../../../../../state/actions/card/index.tsx';
import { issueIncrementPlanningUpdate } from '../../../../../../state/actions/issue/update/index.tsx';
import { useBoardDispatch, useBoardSelector } from '../../../../../../state/index.tsx';
import { getPreventInlineEditing } from '../../../../../../state/selectors/board/board-selectors.tsx';
import { getActiveIssueKey } from '../../../../../../state/selectors/work/work-selectors.tsx';
import { useIsIncrementPlanningBoard } from '../../../../../../state/state-hooks/capabilities/index.tsx';
import { INLINE_EDITING_FIELD_ZINDEX } from '../constants.tsx';
import { getCardEditAssignee } from '../../../../../../state/selectors/card/card-selectors.tsx';
import { AsyncLazyIssueAssigneeFieldWithInviteAssign } from './assignee-field-with-invite-assign/async.tsx';
import { ASSIGNEE_BUTTON_TEST_ID } from './constants.tsx';
import type { AssigneeFieldProps } from './types.tsx';

const stopClickPropagation = (e: React.MouseEvent | React.KeyboardEvent) => {
	e.stopPropagation();
};

const fieldKey = 'assignee';

const AssigneeFieldInner = ({
	issueKey,
	onSubmit,
	onUpdate,
	onFailure,
	onOpen,
	onClose,
	onSaveField,
}: {
	issueKey: IssueKey;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onSaveField: (issueKey: IssueKey, fieldId: string, value: UserValue) => Promise<any>;
} & Partial<EditableFieldProps>) => {
	const preventInlineEditing = useBoardSelector((state) => getPreventInlineEditing(state));
	const isIncrementPlanningBoard = useIsIncrementPlanningBoard();
	const shouldApplyInviteAndAssign = useShouldApplyInviteAndAssign();

	return (
		<ContextualAnalyticsData
			attributes={{
				isInlineEditing: true,
				fieldKey,
				fieldType: 'userpicker',
				origin: 'issueCard',
			}}
		>
			<AssigneeFieldWrapper
				onClick={stopClickPropagation}
				onKeyDown={stopClickPropagation}
				data-testid={ASSIGNEE_BUTTON_TEST_ID}
				disableClick={preventInlineEditing}
			>
				{shouldApplyInviteAndAssign ? (
					<AsyncLazyIssueAssigneeFieldWithInviteAssign
						actionSubject="inlineEdit"
						issueKey={issueKey}
						shouldPreloadAssignToMe
						popupPlacement="bottom-end"
						onSubmit={onSubmit}
						onUpdate={onUpdate}
						onFailure={onFailure}
						onOpen={onOpen}
						onClose={onClose}
						customFetchUsers={
							isIncrementPlanningBoard && fg('issue_cards_in_program_board')
								? fetchPlanAssigneeSuggestions
								: undefined
						}
						{...(isIncrementPlanningBoard &&
							fg('issue_cards_in_program_board') && {
								onSaveField,
								enableAutomaticOption: false,
							})}
					/>
				) : (
					<AsyncLazyIssueAssigneeField
						actionSubject="inlineEdit"
						issueKey={issueKey}
						shouldPreloadAssignToMe
						popupPlacement="bottom-end"
						onSubmit={onSubmit}
						onUpdate={onUpdate}
						onFailure={onFailure}
						onOpen={onOpen}
						onClose={onClose}
						customFetchUsers={
							isIncrementPlanningBoard && fg('issue_cards_in_program_board')
								? fetchPlanAssigneeSuggestions
								: undefined
						}
						{...(isIncrementPlanningBoard &&
							fg('issue_cards_in_program_board') && {
								onSaveField,
								enableAutomaticOption: false,
							})}
					/>
				)}
			</AssigneeFieldWrapper>
		</ContextualAnalyticsData>
	);
};

export const AssigneeField = ({
	shouldRenderRichField,
	assignee,
	isCMPBoard,
	issueKey,
	...props
}: AssigneeFieldProps) => {
	const activeIssueKey = useBoardSelector(getActiveIssueKey);
	const isEditing = Boolean(useBoardSelector((state) => getCardEditAssignee(state)(props.issueId)));
	// Using a ref so it doesn't trigger the useEffect cleanup on every render
	const isEditingRef = useRef(isEditing);

	const [{ value: assigneeFieldConfig }] = useFieldConfig(issueKey, ASSIGNEE_TYPE, {
		skipRefresh: !isCMPBoard || activeIssueKey !== issueKey,
	});

	const shouldRenderRich = Boolean(shouldRenderRichField && assigneeFieldConfig);

	const editableField = useEditableField({
		isExperienceAvailable: shouldRenderRich,
	});
	const fallback = useMemo(() => <AssigneeFieldStatic value={assignee} />, [assignee]);
	const dispatch = useBoardDispatch();

	const onOpen = useCallback(() => {
		dispatch(setInlineEditing('assignee', props.issueId, true));
		if (fg('issue_cards_in_program_board')) {
			isEditingRef.current = true;
		}
	}, [dispatch, props.issueId]);

	const onClose = useCallback(() => {
		dispatch(setInlineEditing('assignee', props.issueId, false));
		if (fg('issue_cards_in_program_board')) {
			isEditingRef.current = false;
		}
	}, [dispatch, props.issueId]);

	const onSaveField = useCallback(
		async (_: string, fieldId: string, fieldValue: UserValue) => {
			dispatch(
				issueIncrementPlanningUpdate({
					issueId: props.issueId,
					fieldId,
					fieldValue: fieldValue ? fieldValue.accountId : null,
				}),
			);
		},
		[dispatch, props.issueId],
	);

	useEffect(() => {
		return () => {
			const isEditingInternal = isEditingRef.current;
			if (!isEditingInternal || !fg('issue_cards_in_program_board')) {
				return;
			}

			// if the inline edit state is still active after the card has been unmounted by the virtualizer,
			// a higher z-index is applied to the issue card, causing it to overlap with some swimlane headers.
			// We need to clear the inline edit state on unmount to prevent this.
			onClose();
		};
	}, [onClose]);

	if (shouldRenderRich) {
		return (
			<IssueAssigneeFieldBoundary
				packageName={PACKAGE_NAME}
				fallback={fallback}
				onError={editableField.onError}
			>
				<UFOSegment name="ng-board.inline-edit.assignee-field">
					<Placeholder name="assignee-field-inner" fallback={fallback}>
						<AssigneeFieldInner
							{...props}
							{...editableField}
							issueKey={issueKey}
							onOpen={onOpen}
							onClose={onClose}
							onSaveField={onSaveField}
						/>
					</Placeholder>
				</UFOSegment>
			</IssueAssigneeFieldBoundary>
		);
	}

	return fallback;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AssigneeFieldWrapper = styled.div<{
	disableClick?: boolean;
}>(
	{
		cursor: 'pointer',

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& > [id$="popup-select"]': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			position: 'absolute !important',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			zIndex: INLINE_EDITING_FIELD_ZINDEX,
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ disableClick }) =>
		disableClick &&
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		css({
			pointerEvents: 'none',
		}),
);
