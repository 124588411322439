import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { Filters, FilterType } from '../../../model/filter/filter-types.tsx';

export const FILTERS_SET = 'state.actions.filter.FILTERS_SET' as const;
export const FILTERS_JQL_SET = 'state.actions.filter.FILTERS_JQL_SET' as const;

export type SetFiltersAction = {
	type: typeof FILTERS_SET;
	payload: {
		filterType: FilterType;
		values: string | string[];
	};
};

export const setFilters = (
	filterType: FilterType,
	values: string | string[],
): SetFiltersAction => ({
	type: FILTERS_SET,
	payload: {
		filterType,
		values,
	},
});

export const SET_ALL_FILTERS = 'state.actions.filter.SET_ALL_FILTERS' as const;

export type SetAllFiltersAction = {
	type: typeof SET_ALL_FILTERS;
	payload: {
		filters: Filters;
	};
	meta: {
		filterType?: FilterType;
		analyticsEvent?: UIAnalyticsEvent;
	};
};

export type SetFiltersJQLAction = {
	type: typeof FILTERS_JQL_SET;
	payload: {
		filters: Filters;
	};
	meta: {
		filterType?: FilterType;
		analyticsEvent?: UIAnalyticsEvent;
	};
};
export const setAllFilters = (
	filters: Filters,
	filterType?: FilterType,
	analyticsEvent?: UIAnalyticsEvent,
): SetAllFiltersAction => ({
	type: SET_ALL_FILTERS,
	payload: {
		filters,
	},
	meta: {
		filterType,
		analyticsEvent,
	},
});

export const setFiltersFromJql = (
	filters: Filters,
	filterType?: FilterType,
	analyticsEvent?: UIAnalyticsEvent,
): SetFiltersJQLAction => ({
	type: FILTERS_JQL_SET,
	payload: {
		filters,
	},
	meta: {
		filterType,
		analyticsEvent,
	},
});

export const SEARCH_FOCUS = 'state.actions.filter.SEARCH_FOCUS' as const;

export type SearchFieldFocusAction = {
	type: typeof SEARCH_FOCUS;
};

export const searchFieldFocus = (): SearchFieldFocusAction => ({
	type: SEARCH_FOCUS,
});

export const EXTRA_AVATAR_MENU_OPEN = 'state.actions.filter.EXTRA_AVATAR_MENU_OPEN' as const;

export type ExtraAvatarMenuOpenAction = {
	type: typeof EXTRA_AVATAR_MENU_OPEN;
};

export const extraAvatarMenuOpen = (): ExtraAvatarMenuOpenAction => ({
	type: EXTRA_AVATAR_MENU_OPEN,
});

export const FILTER_PANEL_MOUNTED = 'state.actions.filter.FILTER_PANEL_MOUNTED' as const;

export type FilterPanelMountedAction = {
	type: typeof FILTER_PANEL_MOUNTED;
};

export const filterPanelMounted = (): FilterPanelMountedAction => ({
	type: FILTER_PANEL_MOUNTED,
});

export const CLEAR_ALL_FILTERS_CLICKED = 'state.actions.filter.CLEAR_ALL_FILTERS_CLICKED' as const;

export type ClearAllFiltersClickedAction = {
	type: typeof CLEAR_ALL_FILTERS_CLICKED;
};

export const clearAllFiltersClicked = (): ClearAllFiltersClickedAction => ({
	type: CLEAR_ALL_FILTERS_CLICKED,
});

export const CUSTOM_FILTER_FORCE_REFRESH_START =
	'state.actions.filter.CUSTOM_FILTER_FORCE_REFRESH_START';

export type OnCustomFilterForceRefreshStart = {
	type: typeof CUSTOM_FILTER_FORCE_REFRESH_START;
};

/**
 * On CMP we must refresh the board after clearing/setting filters on certain
 * cases. This action will update the filters' loading state.
 */
export const customFilterForceRefreshStart = (): OnCustomFilterForceRefreshStart => ({
	type: CUSTOM_FILTER_FORCE_REFRESH_START,
});

export type Action =
	| SetFiltersAction
	| SetAllFiltersAction
	| SearchFieldFocusAction
	| ExtraAvatarMenuOpenAction
	| FilterPanelMountedAction
	| ClearAllFiltersClickedAction
	| OnCustomFilterForceRefreshStart
	| SetFiltersJQLAction;
