import React from 'react';
import { styled as styled2 } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import type { Props } from './types.tsx';

export const CardIcons = (props: Props) => {
	const { children } = props;

	return <Container>{children}</Container>;
};

export default CardIcons;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- To migrate as part of go/ui-styling-standard, Ignored via go/DSP-18766
export const Container = styled2.div({
	boxSizing: 'border-box',
	display: 'flex',
	justifyContent: 'space-around',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > *': {
		marginTop: 0,
		marginRight: token('space.050'),
		marginBottom: 0,
		marginLeft: 0,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& > :last-child': {
		marginRight: 0,
	},
});
